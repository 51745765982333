import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertSaveService } from '../modal/alert-save/alert-save.service';
import { EditorPdfService } from '../../../manage/component/editor-pdf/editor-pdf.service';
import { TemplateService } from '../../../manage/service/template/template.service';
import { AlertSaveNameTemplateService } from '../modal/alert-save-name-template/alert-save-name-template.service';
import { NavbarViewService } from './navbar-view.service';

@Component({
  selector: 'app-navbar-view',
  templateUrl: './navbar-view.component.html',
  styleUrls: ['./navbar-view.component.scss']
})
export class NavbarViewComponent implements OnInit {

  constructor(
    private router: Router,
    private alertSaveService: AlertSaveService,
    private editorPdfService: EditorPdfService,
    private templateService: TemplateService,
    private alertSaveNameTemplateService: AlertSaveNameTemplateService,
    private navbarViewService: NavbarViewService ,
  ) { }

  page: {
    number: number,
    maxpage: number
  } = {
      number: 0,
      maxpage: 0
    }


  ngOnInit(): void {
    this.page.number = this.navbarViewService.getPageNumber();
    this.page.maxpage = this.navbarViewService.getPageMax();
  }

  onCancelEditors() {
    this.alertSaveService.open({
      func: () => {
        if (this.editorPdfService.getEdteMode() == "upload") {
          this.templateService.deleteTemplate(this.editorPdfService.getTemplateAID()).subscribe((rs: any) => {
            if (rs.status) {
              this.alertSaveService.close();
              this.router.navigate(['creating-electronic']);
            }
          });
        } else {
          this.alertSaveService.close();
          this.router.navigate(['creating-electronic']);
        }
      }
    }, {
      func: () => {
        this.alertSaveService.close();
        this.alertSaveNameTemplateService.open(
          {
            func: () => {
              if (this.editorPdfService.getEdteMode() == "upload") {
                this.templateService.deleteTemplate(this.editorPdfService.getTemplateAID()).subscribe((rs: any) => {
                  if (rs.status) {
                    this.alertSaveService.close();
                    this.router.navigate(['template-management']);
                  }
                });
              } else {
                this.alertSaveService.close();
                this.router.navigate(['template-management']);
              }

            }
          },
          {
            func: () => {
              let dataMark = this.editorPdfService.getMark();
              dataMark.templNm =  this.alertSaveNameTemplateService.getNameTemplate();
              this.templateService.saveMarkTemplate(dataMark).subscribe((rs: any) => {
                if (rs.status) {
                  this.alertSaveNameTemplateService.setNameTemplate("");
                  this.alertSaveNameTemplateService.close();
                  this.router.navigate(['template-management']);
                }
              });
            }
          }
        );
      }
    });
  }
}
