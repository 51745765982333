<!-- Smaller Table -->


<div class="smallerUi">


  <section class="container-fluid p-0 d-block">



    <div class="phoneContainer">
      <div class="d-flex row-hl">
        <div class="p-2 item-hl">상태</div>

        <div class="p-2 ml-auto item-hl">보낸 날짜 </div>
      </div>


      <ul class="mobTable  list-group ">
        <ng-container *ngFor="let t of td">
          <li class="list-group-item bg-transparent border-0">

            <div class="item-form sc-jWxkHr mobcellA">
              <div class="row m-0">
                <div class="field-cell check">
                  <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox" id="form_5f48cab259087664b3096102"
                      readonly=""><label for="form_5f48cab259087664b3096102">check0</label></div>
                </div>

                <div class="field-cell state form color-blue"><span> {{t.quesStatus}} </span></div>
                <div class="field-cell form-name"><span class="truncate">{{t.quesCont}} </span>
                </div>
                <div class="field-cell date last-date"><span class="truncate"> {{t.frstReginPnttm}}</span></div>
                <div class="field-cell activity"></div>

              </div>
            </div>

          </li>

        </ng-container>

      </ul>
    </div>


    <div class="tabletContainer">
      <div class="d-flex row-hl  tabletTableHead">
        <div class=" item-hl">상태</div>

        <div class=" ml-auto item-hl">보낸 날짜 </div>
      </div>
      <ul class="tabletTable">
        <ng-container *ngFor="let t of td">
          <li class="bg-white margin-b3">
            <div class="container">

              <div class="row mobTabRow1">

                <div class="d-flex w-100">
                  <div class=" ">{{t.quesStatus}} </div>
                  <div class="ml-auto">{{t.frstReginPnttm}} </div>
                </div>

              </div>
              <div class="row mobTabRow2">

                <div class="d-flex w-100">
                  <div class="item-hl"> {{t.quesCont}}</div>
                </div>

              </div>

            </div>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="d-flex justify-content-center pagiMargin">
      <app-pagination [maxDisplayPage]="5" [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
        (seclectPage)="setCurrentPage($event)"></app-pagination>
    </div>

  </section>


</div>




<!-- Larger Table  -->
<div class="largerUi">
  <div class="d-none d-lg-block">
    <div class="d-flex row-hl align-items-center">
      <div class=" ml-auto item-hl">총 {{itemTotal}}건</div>
    </div>
    <div class="row">
      <div class="col-12">

        <table class="table largeTable table-hover">
          <thead class="th-bg-001">
            <tr>
              <th class="text-center tex-tit-tab-001 " style="width: 20%;"> 등록일</th>
              <th class="text-center tex-tit-tab-001"> 문의</th>
              <th class="text-center tex-tit-tab-001 " style="width: 12%;"> 상태</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <ng-container *ngFor="let t of td">
              <tr class="text-center rowHighlight" (click)="selectItem(t)">
                <td scope="row">
                  {{t.frstReginPnttm}}
                </td>
                <td class="text-left underL">
                  {{t.quesCont}}
                </td>
                <td>
                  {{t.quesStatus}}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div class="d-flex justify-content-center " style="margin-top: 31px; margin-bottom: 94px;">
          <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
            (seclectPage)="setCurrentPage($event)"></app-pagination>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- end of org -->