import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';
import { option } from '../../shared/options-header/option';
@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(
    private http: HttpClient
  ) { }

  pathGetNoticeSystemByUser: string = API_URL.getNoticeSystemByUser;
  pathReactNoticeSystem: string = API_URL.reactNoticeSystem;

  public getNoticeSystem() {
    return this.http.post(this.pathGetNoticeSystemByUser, {});
  }

  public reactNoticeSystem(data: {
    noticActId: string,
    noticId: string,
  }) {
    let params = {
      data: data
    }

    return this.http.post(this.pathReactNoticeSystem, params);
  }
}
