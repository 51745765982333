import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Regex from '../../shared/regex/regex';
import { AlertService } from '../modal/alert/alert.service';

import { ServiceCenterService } from '../../service/service-center/service-center.service';
@Component({
  selector: 'app-contact-page-inquiry',
  templateUrl: './contact-page-inquiry.component.html',
  styleUrls: ['./contact-page-inquiry.component.scss']
})
export class ContactPageInquiryComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private serviceCenterService: ServiceCenterService,
    private alertService: AlertService,
  ) {

  }

  @Output() sendInquiry = new EventEmitter<any>();

  formInquiry: FormGroup;
  validators: any = {
    qaaContent: '',
    qaaMail: ''
  }
  prevContent = '';

  disabledBtn: boolean = true;

  ngOnInit(): void {
    this.initFromInquire();
    const qaaContent = this.formInquiry.get('qaaContent');
    qaaContent.valueChanges.subscribe(v => {
      if (this.getBytes(v) >= 5000) {
        qaaContent.patchValue(this.prevContent, {emitEvent: false});
      } else {
        this.prevContent = v;
      }
    })
  }

  initFromInquire() {
    this.formInquiry = this.fb.group({
      qaaContent: [null, Regex.Required],
      qaaMail: [null, Regex.emailRequired],
    });



    this.formInquiry.get('qaaMail').valueChanges.subscribe(selectValue => {
      this.validators.qaaEmail = Regex.validatorFormData(this.formInquiry.get('qaaMail').status);
    });
    this.formInquiry.get('qaaContent').valueChanges.subscribe(selectValue => {
      this.validators.qaaContent = Regex.validatorFormData(this.formInquiry.get('qaaContent').status);

    });

    this.formInquiry.valueChanges.subscribe(value => {
      this.checkDisabledBtn();
    });
  }

  getBytes(str) {
    const pattern = /[\u0000-\u007f]|([\u0080-\u07ff]|(.))/g;
    return str.replace(pattern, "$&$1$2").length;
  }

  checkDisabledBtn() {
    const valid = {
      VALID: false,
      INVALID: true
    }
    this.disabledBtn = valid[this.formInquiry.status];
  }

  onSubmit() {
    this.insertInquire();
    this.topFunction();
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  insertInquire() {
    this.serviceCenterService.insertInquire(this.formInquiry.value).subscribe((rs: any) => {
      if (rs.status) {
        this.formInquiry.get('qaaMail').setValue("");
        this.formInquiry.get('qaaContent').setValue("");
        this.sendInquiry.emit(true);
      }
    });
  }
}
