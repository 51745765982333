<div id="SVapp-home" class="bgroundcolor">
    <app-navbar></app-navbar>
    <section class="container-fluid bannerarea " id="herder">

        <div class="container-fluid  imagebannerpicture">
            <div class="container-md p-0">
                <div class="row align-items-start bannerimage serviceBalance mx-auto">

                    <div class="col-12 p-0 align-self-center">
                        <h1>고객센터</h1>
                    </div>

                </div>
            </div>
        </div>

        <ng-container *ngIf="page === 'main'">
            <div class="d-flex btnnav justify-content-center signinBtns">


                <button id="firstBtn" class="btn {{active.notice}}" (click)="onClickBtn('notice')">
                    공지사항
                </button>

                <button style="margin: 0px 10px;" class="btn {{active.inquire}}" (click)="onClickBtn('inquire')">
                    문의하기
                </button>

                <button class="btn {{active.qAndA}}" (click)="onClickBtn('qAndA')">
                    자주하는 질문
                </button>
            </div>
        </ng-container>
    </section>

    <ng-container *ngIf="page ==='main'">
        <section class="container-fluid" id="body">
            <app-notice-list *ngIf="display.notice" (selectItem)="selectItem($event)"></app-notice-list>

            <app-notice-detail *ngIf="display.noticeDetail" (goBack)="goBack($event)" [itemNotice]="item">
            </app-notice-detail>

            <app-contract-inquire *ngIf="display.inquire"></app-contract-inquire>

            <app-frequently-asked-questions *ngIf="display.qAndA"></app-frequently-asked-questions>
        </section>
    </ng-container>
    <app-terms-of-service *ngIf="page === 'terms'">

    </app-terms-of-service>

    <app-privacy-policy *ngIf="page === 'policy'">

    </app-privacy-policy>

</div>
<div class="serviceFooter">
    <app-footer></app-footer>
</div>