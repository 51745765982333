<section class="h-100" *ngIf="state == 'email'">

    <app-recipient-confirmation-email></app-recipient-confirmation-email>



</section>


<section class="h-100" *ngIf="state == 'phone'">

    <app-recipient-confirmation-phone></app-recipient-confirmation-phone>

</section>

<section class="h-100" *ngIf="state == 'type'">

    <app-recipient-confirmation-select-type-user></app-recipient-confirmation-select-type-user>

</section>


<section class="h-100" *ngIf="state == 'password'">

    <app-recipient-confirmation-password></app-recipient-confirmation-password>
</section>

<section class="h-100" *ngIf="state == 'write'">

    <app-recipient-confirmation-consent-write></app-recipient-confirmation-consent-write>
</section>

<app-alert></app-alert>
<app-alert-shared></app-alert-shared>
<app-loading></app-loading>