<div class="row-hl btnnav justify-content-center d-flex">
    <div class="serviceBalance">
        <h2 class="text-center centerSubject w-100">
            자주하는 질문
        </h2>

        <form class="form-inline tableForm"  [formGroup]=formSearch>
            <div class=" mx-auto serviceInputArea d-flex align-items-center">


                <div class="input-group  align-items-center">
                    <select class="form-control form-control-sm serviceDrop inp-sel-typ-001" *ngIf="false">
                        <option value="0" ng-reflect-value="0">전체</option>
                        <option value="contract_name" ng-reflect-value="contract_name">계약서 명</option>
                        <option value="sent_date" ng-reflect-value="sent_date">보낸 날짜</option>
                        <option value="last_update_date" ng-reflect-value="last_update_date">마지막 업데이트
                            날짜</option>
                    </select>
                    <input  formControlName=searchText class="form-control serviceSearch border-right-0" (keyup.enter)="SearchQuestionAnswer()" type="text" placeholder="입력해 주세요">
                    <div class="input-group-append bg-white serviceSearchIcon">
                        <button class="mb-0 btn searchService border-left-0 p-0" type="button" (click)="SearchQuestionAnswer()">
                            <img src="../../../assets/img/frontpage/searchicons.png" width="30px">
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="mx-auto notict-list-box bg-white">

            <div id="accordion">

                <div class="card" *ngFor="let qa of qaLists;let i = index">
                    <div class="card-header">
                        <h5 class="accordion-menu">
                            <a role="button" data-toggle="collapse" href="#collapse-{{i}}"
                                [attr.aria-expanded]="i == 0 ? 'true': 'false'" aria-controls="collapse-13"
                                class="collapsed">
                                <ul class="nav nav-pills align-items-center">
                                    <li class="qestionicon ">
                                        <img src="../../../assets/img/frontpage/questionicon.png">
                                    </li>
                                    <li class="qestiontxt">
                                        <p>{{qa.quesTitle}}</p>
                                    </li>
                                    <li class="qestiontopic">
                                        <p class="">{{qa.quesCont}}</p>
                                    </li>
                                </ul>
                            </a>
                        </h5>
                    </div>
                    <div id="collapse-{{i}}" class="collapse {{i == 0 ? 'show': ''}}" data-parent="#accordion">

                        <div class="card">
                            <div class="card-body p-0">
                                <h5 class="accordion-menu">

                                    <a class="d-none d-md-block">
                                        <ul class="nav nav-pills align-items-start">
                                            <li class="qestionicon">
                                                <img src="../../../assets/img/frontpage/answericon.png">
                                            </li>
                                            <li class="qestiontxt d-none d-md-block width-0">
                                                <img src="../../../assets/img/frontpage/itemAngle.png">
                                            </li>
                                            <li class="qestiontopic">
                                                <p class="padding-t10" innerHTML="{{qa.ansCont}}"></p>
                                            </li>
                                        </ul>
                                    </a>

                                    <div class="smallerUi d-block d-md-none">
                                        <div class="media">
                                            <img class="mr-3 align-self-top"
                                                src="../../../assets/img/frontpage/answericon.png" />
                                            <div class="media-body">

                                                <p class="padding-t10" innerHTML="{{qa.ansCont}}"></p>
                                            </div>
                                        </div>
                                    </div>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>