import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Regex from '../../shared/regex/regex';

import { AlertService } from '../modal/alert/alert.service';
import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';

import { CodeBookService } from '../../service/code-book/code-book.service';
import { ServiceCenterService } from '../../service/service-center/service-center.service';

@Component({
  selector: 'app-mail-registration',
  templateUrl: './mail-registration.component.html',
  styleUrls: ['./mail-registration.component.scss']
})
export class MailRegistrationComponent implements OnInit {

  tempmTypes: any;

  constructor(
    private codeBookService: CodeBookService,
    private fb: FormBuilder,
    private serviceCenterService: ServiceCenterService,
    private alertService: AlertService,
    private alertSharedService: AlertSharedService,
  ) { }

  @Output() sendInquiry = new EventEmitter<any>();

  formCreateTemplateMail: FormGroup;

  formCreateTemplateMailM: FormGroup;
  validators: any = {
    tempmContent: '',
    tempmNm: '',
    tempmTitle: '',
    tempmType: ''
  }

  disabledBtn: boolean = true;

  ngOnInit(): void {
    this.tempmTypes = [];
    this.getCodeBook();
    this.initFromCreateTemplateMail();
  }

  initFromCreateTemplateMail() {
    this.formCreateTemplateMail = this.fb.group({
      tempmContent: [null, Regex.Required],
      tempmNm: [null],
      tempmTitle: [null, Regex.Required],
      tempmType: ['N', Regex.Required]
    });

    this.formCreateTemplateMail.get('tempmContent').valueChanges.subscribe(selectValue => {
      this.validators.tempmContent = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmContent').status);
    });

    this.formCreateTemplateMail.get('tempmNm').valueChanges.subscribe(selectValue => {
      this.validators.tempmNm = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmNm').status);
    });

    this.formCreateTemplateMail.get('tempmTitle').valueChanges.subscribe(selectValue => {
      this.validators.qaaEmail = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmTitle').status);
    });

    this.formCreateTemplateMail.get('tempmType').valueChanges.subscribe(selectValue => {
      this.validators.tempmType = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmType').status);
    });

    this.formCreateTemplateMail.valueChanges.subscribe(value => {
      this.checkDisabledBtn();
    });
  }

  checkDisabledBtn() {
    const valid = {
      VALID: false,
      INVALID: true
    }
    this.disabledBtn = valid[this.formCreateTemplateMail.status];
  }

  getCodeBook() {
    this.codeBookService.getCodeBook("TEMPM_TYPE").subscribe((rs: any) => {
      if (rs.status) {
        this.tempmTypes = rs.data;
      }
    });
  }

  onSubmit() {
    this.serviceCenterService.createTemplateMail(this.formCreateTemplateMail.value).subscribe((rs: any) => {
      if (rs.status) {

        // Ex: Config Open Alert  
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "메일 템플릿이 저장되었습니다.",
            subTitle: ""
          },
          [
            {
              btnType: 'close',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {
                this.formCreateTemplateMail.get('tempmContent').setValue("");
                this.formCreateTemplateMail.get('tempmNm').setValue("");

                this.formCreateTemplateMail.get('tempmTitle').setValue("");
                this.formCreateTemplateMail.get('tempmType').setValue("");
                this.alertService.close();
                this.sendInquiry.emit(true);
              }
            }
          ]
        );

      }
    });
  }
}
