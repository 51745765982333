import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SnsService {

  pathSns: string = API_URL.sns;


  constructor(
    private http: HttpClient
  ) { }

  

  public getUserProfile(snsProvider: string, snsKey: string) {
    // Content-Type: application/vnd.openxmlformats-officedocument.presentationml.presentation
    const snsUrl = this.pathSns+"/"+snsProvider+"/"+snsKey;
    const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');

    return this.http.post(snsUrl, headers);

  }


}
