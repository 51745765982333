<div class="oddFront">

  <footer class="page-footer font-small indigo py-md-5 ">
    <!-- Footer Links -->
    <div class="container-md text-center text-md-left">
      <!-- Grid row -->
      <div class="row footerData m-0">

        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-8 mx-md-auto p-0 aCol">
          <!-- Links -->
          <a (click)="topFunction()" href="#/service-center">
            <h5 class="font-weight-bold text-uppercase text-left mt-3 mb-4">공지사항
              <a class="invisible" href="#/service-center"><img src="../../../assets/img/frontpage/plusicon.png"></a>
            </h5>
          </a>


          <ul class="squBullets list-unstyled text-left">

            <li class="my-3" *ngFor="let item of noticeLists;let i = index;">
              <h6 style="max-height: 16px;overflow: hidden;font-size: 14px;"> <a (click)="topFunction()"
                  href="#/service-center?notice={{item.noticId}}" class=" my-5">
                  {{item.noticTitle}}
                </a>
              </h6>
            </li>
          </ul>


        </div>
        <!-- Grid column -->
        <hr class="clearfix w-100 d-none">
        <!-- Grid column -->
        <div class="col-4 p-0 mx-auto bCol">
          <!-- Links -->
          <div class="d-flex mt-3 mb-4 justify-content-end  align-items-center">
            <h5 class="mb-0 mr-2 text-uppercase">더보기</h5>
            <a href="#"><img src="../../../assets/img/frontpage/plusicon.png"></a>
          </div>





          <ul class="dateCol d-block text-right list-unstyled">
            <li class="my-3" *ngFor="let item of noticeLists;let i = index;">
              <a (click)="topFunction()" href="#/service-center?notice={{item.noticId}}" class="my-5">
                {{item.registerPnttm}}
              </a>
            </li>
          </ul>

        </div>
        <!-- Grid column -->
        <hr class="clearfix w-100 d-md-none">
        <!-- Grid column -->
        <div class="col text-left mx-auto pl-0 cCol">
          <!-- Links -->

          <div class="borderFooter">
            <div class="d-flex mt-0 mb-4 justify-content-between">
              <a (click)="topFunction()" href="#/service-center/?svc=qAndA">
                <h5 class="text-uppercase">문의하기</h5>
              </a>
              <span>
                <a (click)="topFunction()" href="#/service-center/?svc=qAndA"><img
                    src="../../../assets/img/frontpage/longArrowIcon.png"></a>
              </span>
            </div>

            <ul class="list-unstyled">
              <li class="my-3">
                <a (click)="topFunction()" href="#/service-center/?svc=qAndA" class="my-5">궁금한 사항이 있으면<br>
                  문의해주세요.</a>

              </li>

            </ul>
            <a (click)="topFunction()" href="#/service-center/?svc=qAndA" class="colIcon">
              <img width="60px" src="../../../assets/img/frontpage/QAicon.png"></a>
          </div>

        </div>
        <!-- Grid column -->
        <hr class="clearfix w-100 d-none">
        <!-- Grid column -->
        <div class=" col text-left mx-auto dCol pr-0">
          <!-- Links -->

          <div class="borderFooter">
            <div class="d-flex mt-0 mb-4 justify-content-between">
              <a (click)="topFunction()" (click)="topFunction()" href="#/service-center/?svc=inquire">
                <h5 class="text-uppercase">자주하는 질문</h5>
              </a>
              <span>
                <a (click)="topFunction()" href="#/service-center/?svc=inquire"><img
                    src="../../../assets/img/frontpage/longArrowIcon.png"></a>
              </span>
            </div>

            <ul class="list-unstyled">
              <li class="my-3">
                <a (click)="topFunction()" href="#/service-center#herder?svc=inquire" class="my-5">자주 문의하는 질문과 <br/>답변을 확인해보세요.</a>
              </li>
              <a class="colIcon" (click)="topFunction()" href="#/service-center#herder?svc=inquire">
                <img width="60px" src="../../../assets/img/frontpage/FAQicon.png">
              </a>
            </ul>
          </div>


        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
    <!-- Footer Links -->
    <!-- Copyright -->

    <div class="container-md border-top">

      <div class="align-items-center row m-0">

        <div class="col-md-12 p-0">
          <div class="footer-copyright text-center d-flex bd-highlight">
            <a class="bd-highlight text-left pr-3 border-right" (click)="toTerms()">이용약관</a>
            <a class="bd-highlight text-left pl-3" (click)="toPolicy()">개인정보처리방침</a>
          </div>
          <p class="d-none d-md-block">
            (주)베리드코리아 | 대표: 김택균 | 사업자등록번호: 389-86-01018 (07294) 서울특별시 영등포구 선유로 70(문래동3가) 1301호 | 대표번호: 02-3662-7982
            <span class="ml-4">지원문의: support@berith.co / 제휴문의: business@berith.co</span><br>
            © Copyright 2020 Berith - All Rights Reserved
          </p>

          <p class="d-block d-md-none mt-4">
            (주)베리드코리아 | 대표: 김택균 | 사업자등록번호: 389-86-01018
            (07294) 서울특별시 영등포구 선유로 70(문래동3가) 1301호 | 대표번호: 02-3662-7982
            지원문의: support@berith.co/제휴문의: business@berith.co<br>
            © Copyright 2020 Berith - All Rights Reserved


          </p>

        </div>

      </div>


      <div class="row ">
        <div class="col-10">



        </div>
      </div>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->

</div>


<div class="standardFront">

  <footer class="page-footer font-small indigo mobFootpad p-md-5 ">
    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
      <!-- Grid row -->
      <div class="row footerData ">

        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-md-4 col-8 mr-md-auto p-md-0 aCol">
          <!-- Links -->
          <a (click)="topFunction()" (click)="topFunction()" href="#/service-center">
            <h5 class="font-weight-bold text-uppercase text-left mt-3 mb-4">공지사항<a class="invisible"
                href="#/service-center"><img src="../../../assets/img/frontpage/plusicon.png"></a></h5>

          </a>


          <ul class="squBullets list-unstyled text-left">
            <li class="my-3" *ngFor="let item of noticeLists;let i = index;">

              <h6 style="max-height: 16px;overflow: hidden;font-size: 14px;"> <a (click)="topFunction()"
                  href="#/service-center?notice={{item.noticId}}" class=" my-3"><span class="lecaray">▪</span>
                  <span class="noticetxtList">{{item.noticTitle}}</span>
                </a>
              </h6>
            </li>
          </ul>
        </div>
        <!-- Grid column -->
        <hr class="clearfix w-100 d-none ">
        <!-- Grid column -->
        <div class="col-md-2 col-4 p-md-0 mx-auto mx-md-0 bCol">
          <!-- Links -->
          <div class="d-flex mt-3 mb-4 justify-content-end  justify-content-md-center  align-items-center">
            <h5 class="mb-0 mr-2 font-weight-bold text-uppercase">더보기</h5>
            <a (click)="topFunction()" href="#/service-center"><img class="plusdowner"
                src="../../../assets/img/frontpage/plusicon.png"></a>
          </div>

          <ul class="dateCol d-none d-md-block  text-center list-unstyled">
            <li class="my-3" *ngFor="let item of noticeLists;let i = index;">
              <a (click)="topFunction()" href="#/service-center?notice={{item.noticId}}" class="my-5">
                <h6 style="max-height: 16px;overflow: hidden;font-size: 14px;">{{item.registerPnttm}}</h6>
              </a>
            </li>
          </ul>

          <ul class="dateCol d-block d-md-none text-right list-unstyled">
            <li class="my-3" *ngFor="let item of noticeLists;let i = index;">
              <a (click)="topFunction()" href="#/service-center?notice={{item.noticId}}" class="my-5">
                <h6 style="max-height: 16px;overflow: hidden;font-size: 14px;">{{item.registerPnttm}}</h6>
              </a>
            </li>
          </ul>



        </div>
        <!-- Grid column -->
        <hr class="clearfix w-100 d-md-none theHR">
        <!-- Grid column -->
        <div class=" col-md-2 col text-left mx-auto px-md-0 cCol">
          <!-- Links -->

          <div class="borderFooter">
            <div class="d-flex mt-md-3 mb-4 justify-content-between">
              <a (click)="topFunction()" href="#/service-center/?svc=inquire">
                <h5 class="text-uppercase mb-0">문의하기</h5>
              </a>
              <span>
                <a (click)="topFunction()" href="#/service-center/?svc=inquire"><img
                    src="../../../assets/img/frontpage/longArrowIcon.png"></a>
              </span>
            </div>

            <ul class="list-unstyled">
              <li class="my-3">
                <a (click)="topFunction()" href="#/service-center/?svc=inquire" class="my-5">궁금한 사항이 있으면<br>
                  문의해주세요.</a>
                <a (click)="topFunction()" href="#/service-center/?svc=inquire" class="colIcon d-none"><img width="60px"
                    src="../../../assets/img/frontpage/QAicon.png"></a>
              </li>
              <a (click)="topFunction()" href="#/service-center/?svc=inquire" class="colIcon d-block"><img width="60px"
                  src="../../../assets/img/frontpage/QAicon.png"></a>
            </ul>
          </div>


        </div>
        <!-- Grid column -->
        <hr class="clearfix w-100 d-none">
        <!-- Grid column -->
        <div class="col-md-auto col text-left mx-0 dCol p-md-0">
          <!-- Links -->

          <div class="borderFooter">
            <div class="d-flex mt-md-3 mb-4 justify-content-between">
              <a (click)="topFunction()" href="#/service-center/?svc=qAndA">
                <h5 class="text-uppercase mb-0">자주하는 질문</h5>
              </a>
              <span>
                <a (click)="topFunction()" href="#/service-center/?svc=qAndA"><img
                    src="../../../assets/img/frontpage/longArrowIcon.png"></a>
              </span>
            </div>

            <ul class="list-unstyled">
              <li class="my-3">
                <a (click)="topFunction()" href="#/service-center/?svc=qAndA" class="my-5">자주 문의하는 질문과 <br/>답변을 확인해보세요.</a>
                <a (click)="topFunction()" href="#/service-center/?svc=qAndA" class="colIcon d-none "><img width="60px"
                    src="../../../assets/img/frontpage/FAQicon.png"></a>
              </li>
              <a (click)="topFunction()" href="#/service-center/?svc=qAndA" class="colIcon d-block "><img width="60px"
                  src="../../../assets/img/frontpage/FAQicon.png"></a>
            </ul>
          </div>


        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
    <!-- Footer Links -->
    <!-- Copyright -->

    <div class="container-fluid border-top">

      <div class="align-items-center row ">

        <div class="col-md-12 p-md-0">
          <div class="footer-copyright text-center d-flex bd-highlight">
            <a class="bd-highlight text-left pr-3 border-right" href="javascript:void(0)" (click)="toTerms()">이용약관</a>
            <a class="bd-highlight text-left pl-3" href="javascript:void(0)" (click)="toPolicy()">개인정보처리방침</a>
          </div>
          <p class="d-none d-md-block">
            (주)베리드코리아 | 대표: 김택균 | 사업자등록번호: 389-86-01018 (07294) 서울특별시 영등포구 선유로 70(문래동3가) 1301호 | 대표번호: 02-3662-7982
            <span class="ml-4">지원문의: support@berith.co / 제휴문의: business@berith.co</span><br>
            © Copyright 2020 Berith - All Rights Reserved
          </p>

          <p class="d-block d-md-none mt-4">
            (주)베리드코리아 | 대표: 김택균 | 사업자등록번호: 389-86-01018
            (07294) 서울특별시 영등포구 선유로 70(문래동3가) 1301호 | 대표번호: 02-3662-7982
            지원문의: support@berith.co/제휴문의: business@berith.co<br>
            © Copyright 2020 Berith - All Rights Reserved


          </p>

        </div>

      </div>


      <div class="row ">
        <div class="col-10">



        </div>
      </div>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->

</div>
