<div class="standardFront">

  <section id="features" class="bg-features  pt-5 pt-md-0 px-md-0">
    <div class="container2">
      <!-- swap titles -->
      <div class="featTitle row d-none d-md-block">
        <div class="col-10 offset-1">
          <h3 class="text-center"><span><img src="/assets/img/frontpage/logo_gcont2x.png" width="150px"></span> 는 전자계약서를
            만들기 위한</h3>
          <p style="font-size: 2.4rem; " class="text-center">3가지 방법을 제공합니다.</p>
        </div>
      </div>
      <!-- end of larger -->
      <!-- start of mobile -->
      <div class="row d-block d-md-none">
        <div class="col-10 p-0 offset-1">
          <h3 class="featuredHeadingMob text-center"><span><img width="80px"
                src="/assets/img/frontpage/logo_gcont2x.png"></span>는 전자계약서를
            만들기 위한</h3>
          <p style="font-size: 1.6rem; font-weight:600; " class="text-center"><b>3가지 방법을 제공합니다.</b></p>
        </div>
      </div>
      <!-- Swap titles -->
      <div class="row py-5 py-md-0">
        <div class="col-md-6 px-5 px-md-0">
          <img src="/assets/img/frontpage/desktopGcont.png" class="img-fluid" />
        </div>
        <div
          class="col-md-6 justify-content-center d-flex text-center text-md-left color-dark    align-self-center bd-highlight ">
          <div>
            <p class="d-none d-md-block mb-0 stepNum">01</p>
            <h3 class="stepHead"><span class="mb-0 stepNumMobile d-md-none pr-2">01</span><b>문서 업로드해서 만들기</b>
            </h3>
            <p class="stepText text-center text-md-left color-dark bd-highlight">
              계약할 문서를 업로드하여 계약에 필요한<br>
              내용을 입력하고 새 계약서를 만들 수 있습니다.


            </p>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="bg-featuresB  px-md-5">
    <div class="container2">
      <div class="row bg-change featurePhoneSpacing py-md-0">
        <div class="col-md-6 order-md-last px-5 px-md-0 d-md-flex justify-content-end">
          <img src="/assets/img/frontpage/featuredForm.png" class="img-fluid" />
        </div>

        <div
          class="col-md-6 order-md-first text-center text-md-left color-dark  px-4 px-md-0  align-self-center bd-highlight">
          <p class="d-none d-md-block mb-0 stepNum ">02</p>
          <h3 class="stepHead"><span class="mb-0 stepNumMobile d-md-none pr-2">02</span><b>제공된 서식으로 만들기</b></h3>
          <p class="stepText text-center text-md-left color-dark bd-highlight">
            계약서 종류를 선택하여 계약에 필요한 내용을<br>입력하고
            새 계약서를 만들 수 있습니다


          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-featuresC bg-white  px-md-5">
    <div class="container2">


      <div class="row bg-white">
        <div class="col-md-6 px-5 px-md-0">
          <img src="/assets/img/frontpage/featuredFormB.png" class="img-fluid" />
        </div>
        <div
          class="col-md-6 justify-content-center d-flex text-center text-md-left color-dark    align-self-center bd-highlight ">
          <div>
            <p class="d-none d-md-block mb-0 stepNum">03</p>
            <h3 class="stepHead"><span class="mb-0 stepNumMobile d-md-none pr-2">03</span><b>계약서 인증</b>
            </h3>
            <p class="stepText text-center text-md-left color-dark bd-highlight">
              오프라인으로 완료된 계약서 문서를 업로드하여<br>
              전자계약을 인증할 수 있습니다.


            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="oddFront">
  <section id="features" class="bg-features  pt-5 pt-md-0 px-md-0">
    <div class="container">

      <!-- swap titles -->
      <div class="featTitle row d-none d-md-block">
        <div class="col-12">
          <h3 class="text-center"><span><img src="/assets/img/frontpage/logo_gcont2x.png" width="150px"></span> 는 전자계약서를
            만들기 위한</h3>
          <p class="text-center">3가지 방법을 제공합니다.</p>
        </div>
      </div>
      <!-- end of larger -->
      <!-- start of mobile -->
      <div class="row py-5 py-md-0">
        <div class="col-12 px-5 px-md-0 d-md-flex justify-content-center">
          <img src="/assets/img/frontpage/desktopGcont.png" class="img-fluid" />
        </div>
        <div class="col-12 justify-content-center d-flex text-center  color-dark    align-self-center bd-highlight ">
          <div>
            <p class="d-none mb-0 stepNum">01</p>
            <h3 class="stepHead"><span class="mb-0 stepNumMobile  pr-2">01</span><b>문서 업로드해서 만들기</b>
            </h3>
            <p class="stepText text-center color-dark bd-highlight">
              계약할 문서를 업로드하여 계약에 필요한<br>
              내용을 입력하고 새 계약서를 만들 수 있습니다.


            </p>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="bg-featuresB  px-md-5">
    <div class="container">
      <div class="row bg-change py-5 py-md-0">
        <div class="col-12 px-5 px-md-0 d-md-flex justify-content-center">
          <img src="/assets/img/frontpage/featuredForm.png" class="img-fluid" />
        </div>
        <div class="col-12 text-center  color-dark  px-4 px-md-0  align-self-center bd-highlight">
          <p class="d-none mb-0 stepNum ">02</p>
          <h3 class="stepHead"><span class="mb-0 stepNumMobile pr-2">02</span><b>제공된 서식으로 만들기</b></h3>
          <p class="stepText text-center  color-dark bd-highlight">
            계약서 종류를 선택하여 계약에 필요한 내용을<br>입력하고
            새 계약서를 만들 수 있습니다


          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-featuresC bg-white  px-md-5">
    <div class="container">
      <div class="row bg-white">
        <div class="col-12 px-5 px-md-0 d-md-flex justify-content-center">
          <img src="/assets/img/frontpage/featuredFormB.png" class="img-fluid" />
        </div>

        <div class="col-12 justify-content-center d-flex text-center  color-dark    align-self-center bd-highlight ">
          <div>
            <p class="d-none mb-0 stepNum">03</p>

            <h3 class="stepHead"><span class="mb-0 stepNumMobile pr-2">03</span><b>계약서 인증</b>
            </h3>
            <p class="stepText text-center color-dark bd-highlight">
              오프라인으로 완료된 계약서 문서를 업로드하여<br>
              전자계약을 인증할 수 있습니다.


            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
