<section class="p-5 bg-package">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>더 궁굼한 사한이 있으신가요? 소개자료를 다운로드하세요.</h3>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-md-6 my-2">
        <div class="p-5 bg-white w-auto d-flex justify-content-center">
          <div>
            <h5 class="color-blue m-0">CRM Business</h5>
            <p>Standard package</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 my-2">
        <div class=" p-5 bg-white w-auto d-flex justify-content-center">
          <div>
            <h5 class="color-blue m-0">CRM Call Center</h5>
            <p>Premium package</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
