import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceCenterService } from '../../manage/service/service-center/service-center.service';
import { NavbarService } from '../navbar/navbar.service';
@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.scss']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private serviceCenterService: ServiceCenterService,
    private navbarService: NavbarService,
  ) { }

  formSearch: FormGroup;

    qaLists: {
      ansCont: string,
      ansId: string,
      quesCont: string,
      quesId: string,
      quesStatus: string,
      quesTitle: string
    }[];

  ngOnInit(): void {
    this.navbarService.setNavbarTitle("고객센터");
    this.initFormSearch();
    this.getQuestionAnswerFront();
  }

  initFormSearch() {
    this.formSearch = this.fb.group({
      searchText: ['']
    });
  }

  getQuestionAnswerFront(){
    let params = {
      content: this.formSearch.get('searchText').value,
      quesTitle: '',
      type: ''
    }
    this.serviceCenterService.getQuestionAnswerFront(params).subscribe((rs: any) => {
      if(rs.status){
        this.qaLists = rs.data;
      }
    });
  }

  SearchQuestionAnswer(){
    this.getQuestionAnswerFront();
  }
}
