import { Validators } from '@angular/forms';
export default class Regex {

  public static Required = [Validators.required];
  public static userLogin = [Validators.required];
  public static passwordLogin = [Validators.required, Validators.minLength(8), Validators.maxLength(100)];
  public static numberOnlyRequired = [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/g)];
  public static numberOnlyNotRequired = [Validators.pattern(/^-?(0|[1-9]\d*)?$/g)];
  public static numberMommyRequired = [Validators.required, Validators.pattern(/^[0-9]{1,3}([,.][0-9]{1,3})+(.[0-9]{1,2})?$/g)];
  public static numberMommyNotRequired = [Validators.pattern(/^[0-9]{1,3}([,.][0-9]{1,3})+(.[0-9]{1,2})?$/g)];
  public static lettersOnlyRequired = [Validators.required, Validators.pattern(/^-?(\D+)?$/g)];
  public static lettersOnlyNotRequired = [Validators.pattern(/^-?(\D+)?$/g)];
  public static emailRequired = [Validators.required, Validators.email];
  public static emailNotRequired = [Validators.email];
  public static userNm = [Validators.required, Validators.minLength(1)];
  
  public static validatorFormData(status: any) {
    let valid = {
      INVALID: ' invalid',
      VALID: ' valid'
    }
    return valid[status];
  }
}
