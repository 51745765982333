import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ServiceCenterService } from '../../manage/service/service-center/service-center.service';
import { DashboardService } from '../../manage/component/dashboard/dashboard.service';
import { CodeBookService } from '../../manage/service/code-book/code-book.service';
import { ActivatedRoute } from '@angular/router';

import { LoadingService } from '../../manage/shared/loading/loading.service';

import { NavbarService } from '../navbar/navbar.service';
import { AuthGuard } from '../../guard/auth.guard';

@Component({
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: ['./notice-list.component.scss']
})
export class NoticeListComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private serviceCenterService: ServiceCenterService,
    private dashboardService: DashboardService,
    private codeBookService: CodeBookService,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private authGuard: AuthGuard,
  ) { }

  @Output() selectItem = new EventEmitter<any>();

  formSearch: FormGroup;

  noticeLists: {
    noticId: string,
    noticNo: number,
    noticTitle: string,
    noticeContent: string,
    registerPnttm: string
  }[];

  serachType: {
    code: string,
    codeDc: string,
    codeGrpId: string,
    codeNm: string
  }[] = [];

  noticeId: string = null;
  params = {
    data: {
      noticText: "",
      noticType: ""
    },
    pageNo: 0,
    pageSize: 10
  }
  notic: {
    notRead: {
      noticId: string,
      noticNo: number,
      noticTitle: string,
      noticeContent: string,
      registerPnttm: string
    }[]
  } = {
    notRead: []
  }
  onClickItem(item: any) {
    this.selectItem.emit(item);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  ngOnInit(): void {
    this.getCodeBook("NOTI_SEARCH");
    this.searchNoticeFront();
    this.initFormSearch();

    this.navbarService.setNavbarTitle("고객센터");
    this.route.queryParams.subscribe(
      params => {
        console.log(params)
        if (params.notice != undefined) {
          this.noticeId = params.notice
        }
      });

    if (this.authGuard.canActivateNav()) {
      this.getPersonalNotReadNotic();
    }
  }

  getPersonalNotReadNotic() {
    this.serviceCenterService.getPersonalNotReadNotic().subscribe((rs: any) => {
      if (rs.status) {
        this.notic.notRead = rs.data;
      }
    });
  }

  checkNotRead(noticId: string): string {
    let css: string = '';

    let checkNoti = this.notic.notRead.filter(res => {
      return res.noticId == noticId;
    });

    if(checkNoti.length > 0){
      css = 'barumBold'
    }

    return css
  }

  initFormSearch() {
    this.formSearch = this.fb.group({
      serachType: [null],
      searchText: [null]
    });

    this.formSearch.get('searchText').valueChanges.subscribe(selectValue => {
      this.params.data.noticText = selectValue;
    });

    this.formSearch.get("serachType").valueChanges.subscribe(selectValue => {
      this.params.data.noticType = selectValue;
    });

    this.formSearch.get("serachType").setValue("000001");
  }

  searchNoticeFront() {
    this.loadingService.openLoading();
    this.serviceCenterService.searchNoticeFront(this.params).subscribe((rs: any) => {
      if (rs.status) {
        this.noticeLists = rs.data;

        let noti = this.dashboardService.getNoticId();
        if (noti != null || this.noticeId != null) {
          let notice = this.noticeLists.filter((res: any) => {
            return (res.noticId == noti || res.noticId == this.noticeId);
          });
          console.log(notice, noti);
          this.selectItem.emit(notice[0]);
        }
      }
      this.loadingService.closeLoading();
    });
  }

  getCodeBook(type: string) {
    this.codeBookService.getCodeBook(type).subscribe((rs: any) => {
      if (rs.status) {
        this.serachType = rs.data;
      }
    });
  }


  // When the user clicks on the button, scroll to the top of the document

  setCurrentPage($event) {

  }


}
