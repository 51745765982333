import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }


  pathGetUserImformationStaff: string = API_URL.getUserImformationStaff;
  pathGetUserImformationCustommer: string = API_URL.getUserImformationCustomer;
  pathGetSignatureList: string = API_URL.getSignatureList;
  pathOpenSignatures: string = API_URL.openSignature;
  pathOpenSignaturesPeview: string = API_URL.openSignaturePeview
  pathDeleteSignature: string = API_URL.deleteSignature;
  pathUpdateSignatureFavorite: string = API_URL.updateSignatureFavorite;
  pathUpdateImforDetailsCustomer: string = API_URL.updateImforDetailsCustomer;
  pathUpdateAdditionalImfomationCustomer: string = API_URL.updateAdditionalImfomationCustomer;
  pathUpdatePassword: string = API_URL.updatePassword;
  pathUpdateProfileDetailsStaff: string = API_URL.updateProfileDetailsStaff;
  pathRegisterEcontract: string = API_URL.registerEcontract;
  pathCongratulationUser: string = API_URL.congratulationUser;
  pathActiveUserVerifi: string = API_URL.activeUserVerifi;
  pathForgotPassword: string = API_URL.forgotPassword;
  pathCreateNewPassword: string = API_URL.createNewPassword;
  pathGetUserProfile: string = API_URL.getUserProfile;


  getUserProfile(){
    return this.http.post(this.pathGetUserProfile,"");
  }

  getUserImformationStaff(data: string) {
    let params = {
      data: data
    }

    return this.http.post(this.pathGetUserImformationStaff, params);
  }

  getUserImformationCustomer(data: string) {
    let params = {
      data: data
    }

    return this.http.post(this.pathGetUserImformationCustommer, params);
  }

  getSignatureList(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathGetSignatureList, params);
  }

  openSignature(signId: String) {
    return this.pathOpenSignatures + '/' + signId;
  }

  openSignaturePeview(signId: String){
    return this.pathOpenSignaturesPeview + '/' + signId;
  }

  deleteSignature(signId: String) {
    let params = {
      data: {
        signId: signId
      }
    }
    return this.http.post(this.pathDeleteSignature, params);
  }

  updateSignatureFavorite(signId: String, favorite: boolean) {
    let params = {
      data: {
        signId: signId,
        dsigFavorite: favorite
      }
    }
    return this.http.post(this.pathUpdateSignatureFavorite, params);
  }

  updateImforDetailsCustomer(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathUpdateImforDetailsCustomer, params);
  }

  updateAdditionalImfomationCustomer(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathUpdateAdditionalImfomationCustomer, params);
  }

  updatePassword(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathUpdatePassword, params);
  }

  updateProfileDetailsStaff(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathUpdateProfileDetailsStaff, params);
  }

  register(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathRegisterEcontract, params);
  }

  congratulationUser(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathCongratulationUser, params);
  }

  activeUserVerifi(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathActiveUserVerifi, params);
  }

  forgotPassword(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathForgotPassword, params);
  }

  createNewPassword(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathCreateNewPassword, params);
  }
}
