import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  navbarTitle: string = '';
  constructor() { }

  setNavbarTitle(title: string) {
    this.navbarTitle = title;
  }

  getNavbarTitle(): string {
    return this.navbarTitle;
  }
}
