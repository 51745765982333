<section class="w-100" style="background-color: #e2e6eb;min-height: 90vh;">


  <!-- start of smaller screens -->

  <div class="container-fluid" *ngIf="linkedId == '000001'" style="background: #e2e6eb;">

    <h3 class="tit-tem-man-001 pag-tit" style="padding-bottom: 25px;">내 정보 관리 </h3>
    <div class="row formColumns">
      <div class="col-lg-6">
        <label class="flex-grow-1 bd-highlight tit-set-001">내 정보 상세</label>
        <form [formGroup]=formInfoDetails>
          <!-- Form A1 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">회원 유형</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input class="form-control bg-transparent border-0 placedark pl-0 m-0" type="text" placeholder="개인"
                  readonly />
              </div>
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">이름</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" formControlName="infoNm" pattern="^-?(\D+)?$" class="form-control rounded-sm m-0" name="infoNm"
                  placeholder="이름 입력" id="infoNm" aria-describedby="infoNm">
                <small *ngIf="valid.formInfoDetails.infoNm"
                  class="form-text text-muted text-validators {{formInfoDetails.get('infoNm').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">이메일</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" formControlName="infoMail" class="form-control rounded-sm m-0" name="infoMail"
                  placeholder="이메일 입력" id="infoMail" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                  aria-describedby="infoMail">
                <small *ngIf="valid.formInfoDetails.infoMail"
                  class="form-text text-muted text-validators {{formInfoDetails.get('infoMail').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">휴대폰 번호</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" formControlName="infoPhone" class="form-control rounded-sm m-0" name="infoPhone"
                  placeholder="휴대폰 번호 입력" minlength="10" maxlength="11" pattern="^-?([0-9]\d*)?$" id="infoPhone"
                  aria-describedby="infoPhone">
                <small *ngIf="valid.formInfoDetails.infoPhone"
                  class="form-text text-muted text-validators {{formInfoDetails.get('infoPhone').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    숫자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Col-Break -->

      <div class="col-lg-6">
        <label class="flex-grow-1 bd-highlight tit-set-001">비밀번호 변경</label>
        <form [formGroup]=fromPasswordChange>
          <!-- Form B1 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">현재 비밀번호</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <input type="password" formControlName="currentPassword" class="form-control rounded-sm m-0"
                  name="currentPassword" placeholder="현재 비밀번호 입력" id="currentPassword"
                  aria-describedby="currentPassword">
                <small *ngIf="valid.fromPasswordChange.currentPassword"
                  class="form-text text-muted text-validators {{fromPasswordChange.get('currentPassword').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
                </small>
              </div>
            </div>
          </div>

          <!-- Form B2 -->
          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">새 비밀번호</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="password" formControlName="newPassword" class="form-control rounded-sm m-0" name="newPassword"
                  placeholder="새 비밀번호 입력" id="newPassword" aria-describedby="newPassword">
                <small *ngIf="valid.fromPasswordChange.newPassword"
                  class="form-text text-muted text-validators {{fromPasswordChange.get('newPassword').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
                </small>
              </div>
            </div>
          </div>
          <!-- Form B3 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">새 비밀번호 변경</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="password" formControlName="confirmNewPassword" class="form-control rounded-sm m-0"
                  name="confirmNewPassword" placeholder="변경된 새 비밀번호 입력" id="confirmNewPassword"
                  aria-describedby="confirmNewPassword">
                <small *ngIf="valid.fromPasswordChange.confirmNewPassword"
                  class="form-text text-muted text-validators {{fromPasswordChange.get('confirmNewPassword').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-lg-6">
        <label class="flex-grow-1 bd-highlight tit-set-001">추가정보</label>
        <form [formGroup]=formAddInfo>
          <!-- Form C1 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">회원 유형</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <select class="form-control rounded-sm inp-sel-001 m-0" formControlName="userLinkedTypeCd"
                  name="userLinkedTypeCd" id="userLinkedTypeCd" aria-describedby="userLinkedTypeCd">
                  <option value="N">-</option>
                  <option *ngFor="let custType of codebook.custType; let i = index" value="{{custType.code}}">
                    {{custType.codeNm}}</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Form C2 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">기업/단체 이름</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" formControlName="custdBizNo" class="form-control rounded-sm m-0" name="custdBizNo"
                  placeholder="기업/단체 이름" id="custdBizNo" aria-describedby="custdBizNo">
                <small *ngIf="valid.formAddInfo.custdBizNo"
                  class="form-text text-muted text-validators {{formAddInfo.get('custdBizNo').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>

          <!-- Form C3 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">규모</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <select class="form-control rounded-sm inp-sel-001 m-0" formControlName="custdScale" name="custdScale"
                  id="custdScale" aria-describedby="custdScale">
                  <option value="N">-</option>
                  <option *ngFor="let scales of codebook.custScales; let i = index" value="{{scales.code}}">
                    {{scales.codeNm}}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- Form C4 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">규모</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <select class="form-control rounded-sm inp-sel-001 m-0" formControlName="custdIndustry"
                  name="custdIndustry" id="custdIndustry" aria-describedby="custdIndustry">
                  <option value="N">-</option>
                  <option *ngFor="let industry of codebook.custIndustry; let i = index" value="{{industry.code}}">
                    {{industry.codeNm}}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- Form C5 -->
          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">부서</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <input type="text" class="form-control rounded-sm m-0" formControlName="custdDepartment"
                  name="custdDepartment" placeholder="부서 입력" id="custdDepartment" aria-describedby="custdDepartment">
                <small *ngIf="valid.formAddInfo.custdDepartment"
                  class="form-text text-muted text-validators {{formAddInfo.get('custdDepartment').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form C6 -->
          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">지역</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <select class="form-control rounded-sm inp-sel-001 m-0" formControlName="custdArea" name="custdArea"
                  id="custdArea" aria-describedby="custdArea">
                  <option value="N">-</option>
                  <option *ngFor="let area of codebook.custArea; let i = index" value="{{area.code}}">{{area.codeNm}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row col-12 p-0 m-0">

      <div class="rightach w-100 text-right" (click)="openModal()"><a href="javascript:void(0); ">회원탈퇴</a></div>

    </div>
    <div class="row mt-3">
      <div class="col-12 p-0 d-flex justify-content-center">
        <button
          [disabled]='!(formDataChange.formInfoDetails || formDataChange.fromPasswordChange || formDataChange.formAddInfo)'
          (click)="onSubmitCustomer()" type="button" class="btn btn-big crimsonbg ">
          저장
        </button>
      </div>
    </div>

  </div>

  <!-- end of smaller screens  -->

  <!-- start of larger screens -->

  <div class="container-lg ml-lg-0 mr-lg-auto p-0" *ngIf="linkedId == '000002'" style="background: #e2e6eb;">

    <h3 _ngcontent-yjk-c119="" class="tit-tem-man-001 pag-tit" style="padding-bottom: 25px;">내 정보 관리 </h3>
    <div class="row formColumns">
      <div class="col-lg-6">
        <label class="flex-grow-1 bd-highlight tit-set-001">프로필 상세</label>
        <form [formGroup]=formProfileDetails>
          <!-- Form A1 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">회원 유형</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <input class="form-control bg-transparent border-0 placedark pl-0 m-0" type="text" placeholder="기업"
                  readonly />
              </div>
            </div>
          </div>
          <!-- Form A2 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">기업명</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" formControlName="compNm" class="form-control rounded-sm m-0" name="compNm"
                  placeholder="기업명 입력" id="compNm" aria-describedby="compNm">
                <small *ngIf="valid.formProfileDetails.compNm"
                  class="form-text text-muted text-validators {{formProfileDetails.get('compNm').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form A3 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">대표 이메일</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <input type="text" formControlName="compMail" class="form-control rounded-sm m-0" name="compMail"
                  placeholder="이메일 입력" id="compMail" aria-describedby="compMail"
                  pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$">
                <small *ngIf="valid.formProfileDetails.compMail"
                  class="form-text text-muted text-validators {{formProfileDetails.get('compMail').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form A4 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">대표 전화번호</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" formControlName="compPhone" class="form-control rounded-sm m-0" name="compPhone"
                  placeholder="전화번호 입력" id="compPhone" aria-describedby="compPhone" minlength="10" maxlength="11" pattern="^-?([0-9]\d*)?$">
                <small *ngIf="valid.formProfileDetails.compPhone"
                  class="form-text text-muted text-validators {{formProfileDetails.get('compPhone').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    숫자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form A5 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">주소</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <textarea style="resize:none;" rows="3" formControlName="compAddress" class="form-control rounded-sm"
                  name="compAddress" placeholder="주소 입력" id="compAddress" aria-describedby="compAddress"></textarea>
                <small *ngIf="valid.formProfileDetails.compAddress"
                  class="form-text text-muted text-validators {{formProfileDetails.get('compAddress').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Col-Break -->

      <div class="col-lg-6">
        <label class="flex-grow-1 bd-highlight tit-set-001">비밀번호 변경</label>
        <form [formGroup]=fromPasswordChange>
          <!-- Form B1 -->
          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">현재 비밀번호</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="password" formControlName="currentPassword" class="form-control rounded-sm m-0"
                  name="currentPassword" placeholder="현재 비밀번호 입력" id="currentPassword"
                  aria-describedby="currentPassword">
                <small *ngIf="valid.fromPasswordChange.currentPassword"
                  class="form-text text-muted text-validators {{fromPasswordChange.get('currentPassword').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
                </small>
              </div>
            </div>
          </div>

          <!-- Form B2 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">새 비밀번호</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <input type="password" formControlName="newPassword" class="form-control rounded-sm m-0" name="newPassword"
                  placeholder="새 비밀번호 입력" id="newPassword" aria-describedby="newPassword">
                <small *ngIf="valid.fromPasswordChange.newPassword"
                  class="form-text text-muted text-validators {{fromPasswordChange.get('newPassword').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
                </small>
              </div>
            </div>
          </div>
          <!-- Form B3 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">새 비밀번호 변경</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="password" formControlName="confirmNewPassword" class="form-control rounded-sm m-0"
                  name="confirmNewPassword" placeholder="변경된 새 비밀번호 입력" id="confirmNewPassword"
                  aria-describedby="confirmNewPassword">
                <small *ngIf="valid.fromPasswordChange.confirmNewPassword"
                  class="form-text text-muted text-validators {{fromPasswordChange.get('confirmNewPassword').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>


      <div class="col-lg-6">
        <label class="flex-grow-1 bd-highlight tit-set-001Last ">담당자 정보</label>
        <form [formGroup]=fromContractInformations>
          <!-- Form C1 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">담당자명</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" class="form-control rounded-sm m-0" formControlName="userNm" name="userNm"
                  placeholder="담당자 입력" id="userNm" aria-describedby="userNm">
                <small *ngIf="valid.fromContractInformations.userNm"
                  class="form-text text-muted text-validators {{fromContractInformations.get('userNm').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form C2 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">부서/팀</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <input type="text" class="form-control rounded-sm m-0" formControlName="depTeam" name="depTeam"
                  placeholder="부서/팀 입력" id="depTeam" aria-describedby="depTeam">
                <small *ngIf="valid.fromContractInformations.depTeam"
                  class="form-text text-muted text-validators {{fromContractInformations.get('depTeam').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form C3 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">직급</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" class="form-control rounded-sm m-0" formControlName="rank" name="rank"
                  placeholder="직급 입력" id="rank" aria-describedby="rank">
                <small *ngIf="valid.fromContractInformations.rank"
                  class="form-text text-muted text-validators {{fromContractInformations.get('rank').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form C4 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">담당자 이메일</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <!-- <label for="bPhone">전화</label> -->
                <input type="text" formControlName="userMail" class="form-control rounded-sm m-0" name="userMail"
                  placeholder="담당자 이메일 입력" id="userMail" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                  aria-describedby="userMail">
                <small *ngIf="valid.fromContractInformations.userMail"
                  class="form-text text-muted text-validators {{fromContractInformations.get('userMail').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  문자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
          <!-- Form C5 -->

          <div class="d-flex flex-row">
            <div class="input-group-prepend body-tit-from">
              <span class="input-group-text">담당자 연락처</span>
            </div>
            <div class="bg-white body-inp-from">
              <div class="form-group mb-0 " style="padding: 7px 21px;">
                <input type="text" class="form-control rounded-sm m-0" formControlName="userPhone" name="userPhone"
                  placeholder="담당자 연락저 입력" id="userPhone" aria-describedby="userPhone" minlength="10" maxlength="11"
                  pattern="^-?([0-9]\d*)?$">
                <small *ngIf="valid.fromContractInformations.userPhone"
                  class="form-text text-muted text-validators {{fromContractInformations.get('userPhone').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                  숫자만 입력해 주세요.
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row col-12 p-0 m-0">

      <div class="rightach w-100 text-right" (click)="openModal()"><a href="javascript:void(0); ">회원탈퇴</a></div>

    </div>
    <div class="row mt-3">
      <div class="col-12 p-0 d-flex justify-content-center">
        <button
          [disabled]='!(formDataChange.formProfileDetails || formDataChange.fromPasswordChange || formDataChange.fromContractInformations)'
          (click)="onSubmit()" type="button" class="btn btn-big crimsonbg ">
          저장
        </button>
      </div>
    </div>
  </div>


</section>


<app-alert></app-alert>
<app-alert-shared></app-alert-shared>
<app-loading></app-loading>



<!-- Start of popup -->

<div class="bg-modal modal popUpType1 fade show  d-flex justify-content-center" style="display: block;" *ngIf="showModal"
  id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content rounded-0" style="background: transparent">
      <div class="modal-header rounded-0 border-bottom-0  " style="background: #ffffff;color: #000000;">

        <div class="border-bottom border-dark d-flex w-100 borderspacer">
          <h5 class="modal-title barumBold" id="exampleModalLabel">회원 탈퇴</h5>
          <a (click)="closeModal()" type="button" style="color: #000000;" class="close" data-dismiss="modal"
            aria-label="Close">
            <img src="../../../../../assets/img/icon/exitbtn.png"></a>
        </div>

      </div>
      <div class="modal-body rounded-0 pb-0" style="background: #ffffff;color: #000000;padding-top: 15px;">


        <div class="col-12 bg-detail">
          <ol class="tex-detail modalUl">
            <li>탈퇴 이후에는 해당 계정으로 다시 가입할 수 없습니다.</li>
            <li>작성이 완료된 문서의 경우 법적 분쟁 및 위변조 방지를 위해 삭제되지 않습니다.</li>
            <li>유료로 구입하거나 무상으로 제공받은 서비스는 복구 및 환불되지 않습니다.</li>
            <li>일부 개인식별을 위한 개인정보 등을 제외한 모든 개인정보는 삭제됩니다.</li>
          </ol>
        </div>


        <p class="m-0"><b>탈퇴사유</b></p>
        <div>
          <textarea class="form-control modaltxtArea" [(ngModel)]="reason" name="" id="" rows="3" placeholder="탈퇴사유를 입력해주세요."></textarea>
        </div>

        <div class="col-md-12 px-0 checkCon  text-center ">

          <div class="pinkCheck form-group form-check p-0 m-0" style="line-height: 1;">
            <label class="con-che-001">
              <input type="checkbox" [(ngModel)]="allAgree" class="form-check-input" id="allAgree">
              <span class="che-mar-001"></span></label>위 안관 내용에 동의합니다</div>

        </div>

      </div>
      <div class="modal-footer rounded-0 d-flex justify-content-center border-top-0"
        style="background: #ffffff;color: #000000;">
        <button type="button" (click)="closeModal()" class="btn btn-middle-short btn-white">취소</button>
        <button type="button" [disabled]="!allAgree" (click)="withdrawal()" class="btn btn-middle-short btn-black ml-2">회원 탈퇴</button>
      </div>
    </div>
  </div>
</div>