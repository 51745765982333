<!-- Smaller Table -->

<div class="smallerUi">
  <div class="d-block ">


    <div class="phoneContainer">
      <div class="d-flex row-hl">
        <div class="p-2 item-hl">상태</div>

        <div class="p-2 ml-auto item-hl">보낸 날짜 </div>
      </div>


      <ul class="mobTable  list-group mb-5">
        <ng-container *ngFor="let t of tpTwo">
          <li class="list-group-item bg-transparent border-0">

            <div class="item-form sc-jWxkHr mobcellA">
              <div class="row m-0">
                <div class="field-cell check">
                  <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox" id="form_5f48cab259087664b3096102"
                      readonly=""><label for="form_5f48cab259087664b3096102">check0</label></div>
                </div>

                <div [ngClass]="{'field-cell state form color-blue': true, 'col-request': t.code === '000001', 'col-progress' : t.code === '000002', 'col-complete': t.code === '000003', 'col-cancel': (t.code === '000004' || t.code === '000005'), 'col-resend': t.code === '000006'}"><span>{{t.progressStatus}}</span></div>
                <div class="field-cell form-name">
                  <span class="truncate">
                    {{t.templNm}}
                    <i style="font-style: inherit;">
                      {{t.userReq}}
                    </i>
                  </span>
                </div>
                <div class="field-cell date last-date"><span class="truncate"> {{t.frstRegisterPnttm}}</span></div>
                <div class="field-cell activity"></div>

              </div>
            </div>

          </li>

        </ng-container>
      </ul>
    </div>


    <div class="tabletContainer">
      <div class="d-flex row-hl  tabletTableHead">
        <div class=" item-hl">상태</div>

        <div class=" ml-auto item-hl">보낸 날짜 </div>
      </div>
      <ul class="tabletTable">
        <ng-container *ngFor="let t of tpTwo">

          <li class="bg-white margin-b3">
            <div class="container">
              <div class="row mobTabRow1">
                <div class="d-flex w-100">
                  <div [ngClass]="{'field-cell state form': true, 'col-request': t.code === '000001', 'col-progress' : t.code === '000002', 'col-complete': t.code === '000003', 'col-cancel': (t.code === '000004' || t.code === '000005'), 'col-resend': t.code === '000006'}"><span class="truncate">{{t.progressStatus}}</span></div>
                  <div class="ml-auto"> {{t.frstRegisterPnttm}}</div>
                </div>
              </div>
              <div class="row mobTabRow2">
                  <div class="field-cell form-name relative">
                    <span class="truncate" style="width:80%;" >
                      {{t.templNm}}
                      <i class="text-r">
                        {{t.userReq}}
                      </i>
                    </span>
                  </div>
              </div>
            </div>
          </li>

        </ng-container>
      </ul>
    </div>

    <div class="d-flex justify-content-center" style="margin-top: 30px">
      <app-pagination [maxDisplayPage]="5" [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
        (seclectPage)="setCurrentPage($event)"></app-pagination>
    </div>

  </div>
</div>


<!-- Larger Table  -->
<div class="largerUi">
  <div class="d-none d-lg-block">


    <div class="row pt-lg-3">
      <div class="col-12 d-flex flex-row">
        <button class="btn btn-small-long btn-white" (click)="onClickContractCancellation()">계약 취소</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        <table class="table largeTable table-hover">
          <thead class="th-bg-001">
            <tr>
              <th class="text-center tex-tit-tab-001">
                선택
              </th>
              <th class="text-center tex-tit-tab-001">
                상태
              </th>
              <th class="text-center tex-tit-tab-001">
                계약서 명
              </th>
              <th class="text-center tex-tit-tab-001">보낸 사람</th>
              <th class="text-center tex-tit-tab-001">보낸 날짜</th>
              <th class="text-center tex-tit-tab-001">마지막 업데이트</th>
              <th class="text-center tex-tit-tab-001">액션</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <ng-container *ngFor="let t of tpTwo">
              <tr class="text-center {{checkSelectTeplate(t.contorId,t.contId)}}"
                (click)="onClickReviewContractRow($event,t.templId,t.contorNm,t.contId,t.contorId)">
                <td scope="row">
                  <div style="line-height: 1;" class="form-group form-check p-0 m-0">
                    <label class="con-che-001">
                      <input type="checkbox" [disabled]="t.code !== '000001'" (click)="checkBoxSelect(t.contorId,t.contId)">
                      <span class="che-mar-001"></span>
                    </label>
                  </div>
                </td>
                <td [ngClass]="{'text-center': true, 'col-request': t.code === '000001', 'col-progress' : t.code === '000002', 'col-complete': t.code === '000003', 'col-cancel': (t.code === '000004' || t.code === '000005'), 'col-resend': t.code === '000006'}">
                  {{t.progressStatus}}
                </td>
                <td class="tabOverflow pr-4 text-center">
                  {{t.templNm}}
                </td>
                <td class="text-center">
                  {{t.userReq}}
                </td>
                <td class="text-center">
                  {{t.frstRegisterPnttm}}
                </td>
                <td class="text-center">
                  {{t.lastUpdusrPnttm}}
                </td>
                <td>

                  <span title="계약서 상세" class="img-ico-lis-man-001 ico-man-lis-exc-001"
                    (click)="onClickExclamation(t.contId,t.contorId)"></span>
                  <span title="계약서 뷰어" class="img-ico-lis-man-001 ico-man-lis-mon-001"
                    (click)="onClickReviewContract(t.templId,t.contorNm,t.contId,t.contorId)"></span>
                  <button (click)="onClickReviewDownloadContract(t.templId,t.templNm,t.contId,t.contorId)"
                    class="img-ico-lis-man-001 ico-man-lis-loa-001" title="PDF 다운로드"  [disabled]="!(t.code === '000003')"></button>
                </td>
              </tr>



            </ng-container>
          </tbody>
        </table>
        <div class="d-flex justify-content-center" style="margin-top: 30px">
          <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
            (seclectPage)="setCurrentPage($event)"></app-pagination>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- end of org -->