import { Component, ComponentFactoryResolver, Inject, Injector, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PaymentApplyService } from './payment-apply.service';
import { loadTossPayments } from '@tosspayments/sdk';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from 'src/app/manage/service/api.constant';
import { AddCommaPipe } from '../../pipe/add-comma.pipe';
import { DOCUMENT } from '@angular/common';
import { PayingComponent } from '../../paying/paying.component';
import { DataPassService } from 'src/app/manage/service/data-pass/data-pass.service';
import { AuthLoginService } from 'src/app/manage/service/authLogin/auth-login.service';
import { CommonFunction } from 'src/app/manage/service/common-function/common-function.service';

@Component({
  selector: 'app-payment-apply',
  templateUrl: './payment-apply.component.html',
  styleUrls: ['./payment-apply.component.scss'],
  providers: [AddCommaPipe]
})
export class PaymentApplyComponent implements OnInit {
  show: boolean;
  htmlBody: string;
  state: string;
  popUp = 'req';
  clientKey;
  paymentResultData: any;
  tossPayments;
  pathUrl = environment.downloadFileAttachment;
  // pathUrl = 'http://localhost:4200/'
  form: FormGroup;
  headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');
  externalWindow;
  inputValue;
  source;
  constructor(
    private paymentApplyService: PaymentApplyService,
    private http: HttpClient,
    private _ngZone: NgZone,
    private resolver: ComponentFactoryResolver,
    private dataPass: DataPassService,
    private injector: Injector,
    private commonfuction: CommonFunction,
    @Inject(DOCUMENT) private document: Document
  ) {
    window['PaymentApplyComponentRef'] = {component: this, zone: _ngZone, loadAngularFunction: (obj) => this.changeModalPopUp(obj),};
  }

  ngOnInit(): void {
    this.clientKey = 'test_ck_7XZYkKL4MrjLgePzj4R30zJwlEWR';
    loadTossPayments(this.clientKey).then(tossPayments => {
      this.tossPayments = tossPayments;
    });
    this.form = new FormGroup({
      paymentType: new FormControl('none'),
      checkBox: new FormControl(false)
    })
  }

  ngOnDestroy() {

  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    if (this.paymentApplyService.change.show) {
      this.show = this.paymentApplyService.show;
      this.paymentApplyService.setChange('show', false);
    }
    if (this.paymentApplyService.change.html) {
      this.htmlBody = this.paymentApplyService.htmlBody;
      this.paymentApplyService.setChange('html', false);
    }
    if (this.paymentApplyService.change.state) {
      this.state = this.paymentApplyService.getState();
      this.paymentApplyService.setChange('state', false);
    }
    if (this.paymentApplyService.inputValue) {
      this.inputValue = this.paymentApplyService.inputValue;
    }
  }

  closeModal() {
    this.popUp = 'req';
    this.paymentApplyService.close();
  }

  changeModalPopUp(obj) {
    this.popUp = 'complete';
    this.paymentResultData = JSON.parse(obj);
    console.log('토스 결과 리스폰스', obj);
  }

  pay() {
    const succcess = this.pathUrl + 'JSTCRM/api/pay/tossPaymentsSuccess';
    const fail = this.pathUrl + 'JSTCRM/api/pay/tossPaymentsFail';
    const regex =  /[^0-9]/g;
    if (this.form.value.checkBox) {
      if (this.form.value.paymentType === 'CARD' || this.form.value.paymentType === 'BANK' || this.form.value.paymentType === 'VBANK') {
        this.http.get<any>(API_URL.tossPayURL).subscribe(res => {
          console.log(res);
          if (res.status) {
            const shortName = this.inputValue.m_item_name.replace(regex, '') === '' ? 0 : this.inputValue.m_item_name.replace(regex, '');
            this.source = {
              paymentType: this.form.value.paymentType,
              params: {
                amount: this.inputValue.m_item_price * this.inputValue.m_quantity,
                orderId: res.data.ORDER_ID,
                orderName: this.inputValue.m_item_name,
                itemId: this.inputValue.m_item_id,
                customerName: this.inputValue.user_name,
                successUrl: succcess + '/' + shortName + '/' + this.inputValue.m_quantity + '/' + this.inputValue.m_item_id,
                failUrl: fail,
              }
            }
            const parameters =
            'amount=' + this.inputValue.m_item_price * this.inputValue.m_quantity +
            '&orderId=' + res.data.ORDER_ID +
            '&orderName=' + this.inputValue.m_item_name +
            '&itemId=' + this.inputValue.m_item_id +
            '&customerName=' + this.inputValue.user_name +
            '&successUrl=' + succcess + '/' + shortName + '/' + this.inputValue.m_quantity + '/' + this.inputValue.m_item_id +
            '&failUrl=' + fail +
            '&paymentType=' + this.form.value.paymentType;

            const urlParameters = this.commonfuction.setFormatURLParmeters(parameters);

            let url = API_URL.BACKEND_CONTEXTPATH + 'api/pay/ipaySample?' + urlParameters;
            this.commonfuction.createOauthWindow(url, 'paymentInfo', true, 750, 750);
          }
        });
      } else if (this.form.value.paymentType === 'tossPay') {
        const data = {
          amount: this.inputValue.m_item_price * this.inputValue.m_quantity,
          amountTaxFree: 0,
          produceDesc: encodeURI(this.inputValue.m_item_name),
          produceId: this.inputValue.m_item_id
        };
        this.http.post<any>(API_URL.createTossApp, data).subscribe(res => {
          console.log('res===', res);
          window.open(JSON.parse((res.data)).checkoutPage, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

        });
      }
    }
  }

    public openPrintableReport(): void {

    // resolve and instantiate the component which you want to open in new window.
    const componentFactory = this.resolver.resolveComponentFactory(PayingComponent);
    let component = componentFactory.create(this.injector);
    component.changeDetectorRef.detectChanges();
    this.externalWindow = window.open('', '', 'width=220,height=80,left=150,top=200');

    // copy all the styles to external window.
    document.querySelectorAll('style').forEach(htmlElement => {
      this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });

    this.externalWindow.document.title = '결제 진행중 ...';

    // attach the component to external window
    this.externalWindow.document.body.appendChild(component.location.nativeElement);
    }


  exportDataTable() {
    this.paymentApplyService.close();
  }

}
