import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalTeamMemberService } from '../modal/modal-team-member/modal-team-member.service';
import { OrganizationService } from '../../service/organization/organization.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { AlertConfirmService } from '../modal/alert-confirm/alert-confirm.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AlertService } from '../modal/alert/alert.service';
import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-member-management',
  templateUrl: './team-member-management.component.html',
  styleUrls: ['./team-member-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TeamMemberManagementComponent implements OnInit {

  @Input() sendApiComplete;
  @Output() selectDepartment = new EventEmitter<any>();
  @Output() selectTeam = new EventEmitter<any>();
  @Output() selectSearch = new EventEmitter<any>();

  faSearch = faSearch;
  formSearch: FormGroup;
  empDataList: any;
  objTable: any;
  organization: any = [];
  user: any = [];
  child: any[] = [];

  treeOpen: {
    divId: string
  }[] = [];

  treeActive: {
    divId: string
  } = {
      divId: ''
    }

  cssClass: {
    tree: string,
    list: string
  } = {
      tree: 'd-block',
      list: 'd-none'
    };

  searchDatas: {
    divId: string,
    divNm: string,
    orgId: string,
    staffId: string,
    userId: string,
    userMail: string,
    userNm: string,
    userPhone: string,
    userRank: string,
  }[] = [];

  selectData: {
    type: string,
    orgId: string,
    divId: string,
    nM: string,
    userId: string,
  } = {
      type: "",
      orgId: "",
      divId: "",
      nM: "",
      userId: "",
    }

  groupSearch: any[] = [];

  organ: {
    orgNm: string,
    orgId: string
  } = {
      orgNm: "",
      orgId: ""
    }

  html: { htmlTree: any } = { htmlTree: '' }

  btnActions: {
    btnDivision: string,
    btnTeams: string
  } = {
      btnDivision: "btn-white",
      btnTeams: "btn-white"
    }

  checkBoxSearch: boolean = false;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private organizationService: OrganizationService,
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private alertSharedService: AlertSharedService,
    private alertConfirmService: AlertConfirmService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.cssClass.list = 'd-none';
    this.cssClass.tree = 'd-block';

    this.selectData.type = 'team';
    this.btnActions.btnDivision = 'btn-white';
    this.btnActions.btnTeams = 'btn-crimson';

    this.initFormSearch();
    this.initDataEmp();
  }

  ngDoCheck(): void {
    if (this.sendApiComplete.status) {
      this.sendApiComplete.status = false;
      this.checkGetCompany();
      this.getDivisionsTree();
    }
  }

  anyfunction() {
    if (this.checkBoxSearch) {
      const toggler_one = document.getElementsByClassName('cheUse');
      let en = {
        target: {
          id: this.groupSearch[0][0].divId + '-' + this.groupSearch[0][0].userId
        }
      }
      this.checkBoxUser(en, this.groupSearch[0][0]);
      this.checkBoxSearch = false
    }
  }

  initDataEmp() {
    this.objTable = [];
    this.empDataList = {
      department: [

      ],
      tames: [

      ],
      emp: [

      ]
    };
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {

    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.

  }

  getCompany() {
    this.loadingService.openLoading();
    this.organizationService.getCompany().subscribe((rs: any) => {
      if (rs.status) {
        if (rs.data.orgId != null) {
          this.organ = rs.data;
          this.getOrganization();
        }
      } else {
        this.loadingService.closeLoading();
        this.alertService.open(
          "알림",
          "선택하신 해당 팀에는 팀원이 없습니다.",
          {
            func: () => {
              this.alertService.close();
              this.router.navigate(['information-manage'])
            }
          }, {}
        );
      }
    });
  }



  checkGetCompany() {
    this.loadingService.openLoading();
    this.organizationService.getCompany().subscribe((rs: any) => {
      if (rs.status) {
        if (rs.data.orgId != null) {
          this.organ = rs.data;
          this.getOrganization();
        }
      } else {
        this.loadingService.closeLoading();
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "기업명이 설정되어 있지 않습니다.\n내 정보 관리>기업명>에서 조직에 사용할 기업명을 설정해주세요.",
            subTitle: ""
          },
          [
            {
              btnType: 'confirmed',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {
                this.router.navigate(['information-manage'])
              }
            }
          ]
        );
      }
    });
  }

  initFormSearch() {
    this.formSearch = this.fb.group({
      searchText: ['']
    });
    this.formSearch.get('searchText').valueChanges.subscribe(selectValue => {
      if (selectValue !== '') {

      } else {
      }
    });
  }

  onSearch() {
    this.groupSearch = [];
    this.cssClass.tree = 'd-none';
    this.cssClass.list = 'd-block';
    this.btnActions.btnDivision = 'btn-white';
    this.btnActions.btnTeams = 'btn-white';
    this.seachUserInOrgs();
  }

  seachUserInOrgs() {
    this.loadingService.openLoading();
    this.organizationService.seachUserInOrgs(this.formSearch.get('searchText').value).subscribe((rs: any) => {
      if (rs.status) {
        this.searchDatas = rs.data;
        let group = this.searchDatas.reduce(function (rv, x) {
          (rv[x.divNm] = rv[x.divNm] || []).push(x);
          return rv;
        }, {});
        this.checkBoxSearch = true;
        this.groupSearch = Object.values(group);
      }
      this.loadingService.closeLoading();
    });
  }

  openList($event) {
    let element = document.getElementById($event.target.id);
    element.parentElement.querySelector('.nested').classList.toggle('active');;
  }

  initCheckBoxDivision() {
    const toggler_one = document.getElementsByClassName('cheDiv');
    for (let i = 0; i < toggler_one.length; i++) {
      let elClone = toggler_one[i].cloneNode(true);
      toggler_one[i].parentNode.replaceChild(elClone, toggler_one[i]);
    }
    for (let i = 0; i < toggler_one.length; i++) {
      toggler_one[i].addEventListener('click', () => {

        this.clearCheckBox();
        this.clearSelectActions((-1));

        var _this = <HTMLInputElement>document.getElementById(toggler_one[i].getAttribute('id'));
        _this.checked = true;



        this.child = [];
        this.selectChildDivisions(toggler_one[i].getAttribute('attr-divId'));
        this.checkBoxSelectDivision(this.child.join('<>'), toggler_one[i].getAttribute('attr-orgId'), toggler_one[i].getAttribute('attr-nM'));
        this.selectData.type = 'team';
        this.selectTeam.emit(this.selectData);
        this.checkBoxSelectDivision(toggler_one[i].getAttribute('attr-divId'), toggler_one[i].getAttribute('attr-orgId'), toggler_one[i].getAttribute('attr-nM'));

      });
    }
    if (this.treeActive.divId !== '') {
      let toggler_open = document.getElementsByClassName("sel-act");

      console.log(toggler_open);
      var cheDiv = <HTMLInputElement>document.getElementById('c-' + toggler_open[0].getAttribute('attr-parDivId'));
      if (cheDiv) {
        cheDiv.checked = true;
      }
    }
  }

  selectChildDivisions(divId: string) {
    let treeParent = this.organization.filter(res => res.parentDivId == divId);
    if (treeParent.length > 0) {
      treeParent.forEach(parent => {
        if (parent.countCh != 0) {
          this.selectChildDivisions(parent.divId);
        } else {
          this.child.push(parent.divId);
        }
      });
    }
  }

  pushOpenTree(divId: string) {
    let open = this.treeOpen;

    let index = open.findIndex(res => res.divId === divId);

    if (index > -1) {
      open.splice(index, 1);
    } else {
      open.push({ divId: divId });
    }
    this.treeOpen = open;
  }

  clearCheckBox() {
    const toggler_two = document.getElementsByClassName('cheDiv');
    for (let j = 0; j < toggler_two.length; j++) {
      var cheDiv = <HTMLInputElement>document.getElementById(toggler_two[j].getAttribute('id'));
      cheDiv.checked = false;
    }
  }

  checkBoxUser($event, user: any) {
    const toggler_one = document.getElementsByClassName('cheUse');
    for (let i = 0; i < toggler_one.length; i++) {
      var cheDiv = <HTMLInputElement>document.getElementById(toggler_one[i].getAttribute('id'));
      cheDiv.checked = false;
    }
    var _this = <HTMLInputElement>document.getElementById($event.target.id);
    _this.checked = true;
    this.selectData.type = 'search'
    this.selectData.orgId = user.orgId;
    this.selectData.divId = user.divId;
    this.selectData.nM = user.divNm;
    this.selectData.userId = user.userId;

    this.selectSearch.emit(this.selectData);
  }

  checkBoxSelectDivision(divId: string, orgId: string, nM: string) {
    this.selectData.orgId = orgId;
    this.selectData.divId = divId;
    this.selectData.nM = nM;
  }

  getDivisionsTree() {
    this.organizationService.getDivisionsTree().subscribe((rs: any) => {
      if (rs.status) {
        this.user = rs.data.user;

      }
    });
  }

  getOrganization(): void {
    this.loadingService.openLoading();
    this.organizationService.getOrganization().subscribe((rs: any) => {
      if (rs.status) {
        this.organization = rs.data;
        document.getElementById('htmlTree').innerHTML = this.genHtmlTree();
        this.html.htmlTree = this.sanitizer.bypassSecurityTrustHtml(this.genHtmlTree());

        this.initTreeView();
        this.initCheckBoxDivision();
        this.loadingService.closeLoading();
      }
    });
  }


  initTreeView() {
    const toggler = document.getElementsByClassName('caret');
    for (let i = 0; i < toggler.length; i++) {
      let elClone = toggler[i].cloneNode(true);
      toggler[i].parentNode.replaceChild(elClone, toggler[i]);
    }
    for (let i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener('click', (event) => {
        if (!((<HTMLElement>event.target).className.includes('che-mar-001') || (<HTMLElement>event.target).className.includes('cheDiv')) && (!(<HTMLElement>event.target).className.includes('che-mar-002'))) {
          this.pushOpenTree((<HTMLElement>event.target).getAttribute('attr-divid'));
          if (toggler[i].parentElement.querySelector('.nested')) {
            toggler[i].parentElement.querySelector('.nested').classList.toggle('active');
          }
          if (toggler[i]) {
            toggler[i].classList.toggle('caret-down');
          }
        }
      });
    }

    const toggler_two = document.getElementsByClassName('nm-sel');
    for (let i = 0; i < toggler_two.length; i++) {
      let elClone = toggler_two[i].cloneNode(true);
      toggler_two[i].parentNode.replaceChild(elClone, toggler_two[i]);
    }
    for (let i = 0; i < toggler_two.length; i++) {
      toggler_two[i].addEventListener('click', () => {
        this.clearSelectActions(i);
        this.clearCheckBox();

        toggler_two[i].classList.toggle('sel-act');
        this.treeActive.divId = toggler_two[i].getAttribute('attr-divId');
        this.checkBoxSelectDivision(toggler_two[i].getAttribute('attr-divId'), toggler_two[i].getAttribute('attr-orgId'), toggler_two[i].getAttribute('attr-nM'));
        if (toggler_two[i].getAttribute('attr-parDivId') != null && toggler_two[i].getAttribute('attr-parDivId') != '-') {
          var cheDiv = <HTMLInputElement>document.getElementById('c-' + toggler_two[i].getAttribute('attr-parDivId'));
          cheDiv.checked = true;
        }

        this.selectData.type = 'team';
        this.selectTeam.emit(this.selectData);
        if (toggler_two[i].getAttribute('attr-parDivId') != '-') {
          var cheDiv = <HTMLInputElement>document.getElementById('c-' + toggler_two[i].getAttribute('attr-parDivId'));
          this.checkBoxSelectDivision(cheDiv.getAttribute('attr-divId'), cheDiv.getAttribute('attr-orgId'), cheDiv.getAttribute('attr-nM'));
        }
      });
    }
  }

  clearSelectActions(i: number) {
    const toggler_three = document.getElementsByClassName('nm-sel');
    for (let j = 0; j < toggler_three.length; j++) {
      if (i != j)
        toggler_three[j].classList.remove('sel-act');
    }

  }

  genHtmlTree(parentDivId: string = null) {
    let treeParent = this.organization.filter(res => res.parentDivId == parentDivId);

    let htmlTree = '';
    if (treeParent.length > 0) {
      treeParent.forEach(parent => {
        htmlTree += '<li _gcont_team_manage class="cus-che-001" id="' + parent.divId + '">';

        let usr = this.user.filter(res => {
          return res.divId == parent.divId;
        });
        var cssIcon = "ico-img-tre-div-001";
        var cssShCh = '';
        var cssNmType = 'nm-typ';
        var cssChe = '';

        if (parent.countCh == 0) {
          cssIcon = "ico-img-tre-div-002";
          cssShCh = 'd-none';
          cssNmType = 'nm-sel';
          cssChe = 'che-mar-002';
        }

        let actCss: string = '';
        if (this.treeActive.divId === parent.divId) {
          actCss = 'sel-act'
        }
        htmlTree += '<div _gcont_team_manage class="lis-sub-hea-001 ' + cssIcon + ' caret ' + this.checkOpenTree(parent.divId).cssDropDown + '">';
        htmlTree += '<span _gcont_team_manage style="margin-left: -18px;">';

        htmlTree += '<label _gcont_team_manage class="con-che-001 ' + cssShCh + '">';
        htmlTree += '<input _gcont_team_manage type="checkbox" id="c-' + parent.divId + '" class="cheDiv"  attr-nM="' + parent.divNm + '" attr-divId="' + parent.divId + '" attr-orgId="' + parent.orgId + '" value="' + parent.divId + '">'
        htmlTree += '<span _gcont_team_manage style="top: -5px;" class="che-mar-001"></span>';

        htmlTree += '</label>';

        htmlTree += '<span _gcont_team_manage class="px-2 ' + cssChe + ' ' + cssNmType + ' ' + actCss + '" attr-parDivId="' + (parent.parentDivId == null ? '-' : parent.parentDivId) + '" attr-nM="' + parent.divNm + '" attr-divId="' + parent.divId + '" attr-orgId="' + parent.orgId + '" >' + parent.divNm + '</span>';


        htmlTree += '</span>';
        htmlTree += '</div>';

        if (parent.countCh != 0) {
          htmlTree += '<ul _gcont_team_manage class="nested ' + this.checkOpenTree(parent.divId).cssActive + '">';
          htmlTree += this.genHtmlTree(parent.divId);
          htmlTree += '</ul>';
          htmlTree += '</li>';
        }
      });
    }
    return htmlTree;
  }

  checkOpenTree(divId: string): {
    cssActive: string,
    cssDropDown: string
  } {
    var cssOpen: {
      cssActive: string,
      cssDropDown: string
    } = {

      cssActive: '',
      cssDropDown: ''
    };
    let div = this.treeOpen.filter((res) => {
      return res.divId == divId;
    });

    if (div.length > 0) {
      cssOpen.cssActive = 'active';
      cssOpen.cssDropDown = 'caret-down';
    }

    return cssOpen;
  }


  onClickOrganization(orgId: string, nM: string) {
    this.clearCheckBox();
    this.clearSelectActions(-1);
    this.child = [];
    this.selectChildDivisions(null);
    this.selectData.divId = this.child.join('<>');
    this.selectTeam.emit(this.selectData);
    this.selectData.orgId = orgId;
    this.selectData.divId = null;
    this.selectData.nM = nM;
  }

  onDeleteDivision() {
    this.alertConfirmService.open(
      "선택하신",
      "\"" + this.selectData.nM + "\" 을 삭제하시겠습니까?",
      {
        func: () => {
          this.alertConfirmService.close();
        }
      },
      {
        func: () => {
          this.deleteDivision(this.selectData.divId);
          this.alertConfirmService.close();
        }
      }, "삭제"
    );
  }

  deleteDivision(divId: string): void {
    this.loadingService.openLoading();
    this.organizationService.deleteDivision(divId).subscribe((rs: any) => {
      if (rs.status) {
        this.selectData.type = 'team';
        this.selectData.nM = "";
        this.selectTeam.emit(this.selectData);
        this.getCompany();
      }
      this.loadingService.closeLoading();
    });
  }

  onClickDepartment() {
    if (this.organ.orgId != '' && this.selectData.orgId == '') {
      this.selectData.orgId = this.organ.orgId;
      this.selectData.nM = this.organ.orgNm;
    }
    this.cssClass.tree = 'd-block';
    this.cssClass.list = 'd-none';
    this.selectData.type = 'department';
    this.btnActions.btnDivision = 'btn-white';
    this.btnActions.btnTeams = 'btn-white';
    this.child = [];
    this.selectChildDivisions(this.selectData.divId);
    this.selectDepartment.emit(this.selectData);
    this.selectData.type = 'team';
  }

  onClickTeam() {
    this.cssClass.tree = 'd-block';
    this.cssClass.list = 'd-none';
    this.selectData.type = 'team';
    this.btnActions.btnTeams = 'btn-crimson';
    this.btnActions.btnDivision = 'btn-white';
    this.selectTeam.emit(this.selectData);
  }
} 