<section id="projects" class="projects-section bg-light">
  <div class="container">

    <!-- Featured Project Row -->
    <div class="row align-items-center no-gutters mb-4 mb-lg-5">
      <div class="col-xl-8 col-lg-7">
        <img class="img-fluid mb-3 mb-lg-0" src="/assets/img/frontpage/company.png" alt="">
      </div>
      <div class="col-xl-4 col-lg-5">
        <div class="featured-text text-center text-lg-left">
          <h4>조직구성 안내</h4>
          <p class="text-black-50 mb-0">전문 인력으로 구성된 (주)아이비즈 소프트웨어의 기술 및
            지원 인력은 고객을 위한 최상의 서비스를 지원하며,
            특화된 서비스 Open source Software, 정보전략컨설팅,
            통합 개발플랫폼 지원 등의 체계적인 기술 서비스를
            제공합니다.</p>
        </div>
      </div>
    </div>
  </div>
</section>