import { Component, OnInit } from '@angular/core';
import { LoadingProgressService } from './loading-progress.service';

@Component({
  selector: 'app-loading-progress',
  templateUrl: './loading-progress.component.html',
  styleUrls: ['./loading-progress.component.scss']
})
export class LoadingProgressComponent implements OnInit {
  loading: boolean = false;
  progress: number = 0;
  textProgress: string = '0%';
  constructor(
    private loadingProgressService: LoadingProgressService,
  ) { }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    this.loading = this.loadingProgressService.getLoading();
    this.progress = this.loadingProgressService.getProgress();
    this.textProgress = this.loadingProgressService.getTextProgress();
  }

}
