import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthLoginService } from '../../manage/service/authLogin/auth-login.service';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Regex from '../../manage/shared/regex/regex';
import { SnsService } from '../../manage/service/sns/sns.service';
import TokenUtils from '../../manage/shared/token/token-utils';
import { OAuthToken } from '../../manage/shared/model/token-model';
import { LoginService } from './login.service';
import { Injectable } from '@angular/core';

import { LoadingService } from '../../manage/shared/loading/loading.service';
import { AlertService } from '../../manage/component/modal/alert/alert.service';
import { SocialLoadApiService } from '../../manage/service/social-load-api/social-load-api.service';

import { RecipientService } from '../../manage/component/recipient/recipient/recipient.service';

@Component({
  selector: 'app-login-front',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  win: Window;
  formLogin: FormGroup;
  validators: any = {
    // email: '',
    password: '',
    username: '',
  }

  disabledBtn: boolean = true;
  constructor(
    private fb: FormBuilder,
    private authLoginService: AuthLoginService,
    private router: Router,
    private route: ActivatedRoute,
    private snsservice: SnsService,
    private loginService: LoginService,
    private alertService: AlertService,
    private socialLoadApiService: SocialLoadApiService,
    private loadingService: LoadingService,
    private recipientService: RecipientService,
  ) {
  }


  ngOnDestroy() {
    // location.reload();
  }

  ngOnInit(): void {
    // SNS
    this.loadingService.closeLoading();
    this.socialLoadApiService.loadGoogleAPI();
    this.socialLoadApiService.loadNaverAPI();
    this.socialLoadApiService.initKokaoApi();

    console.log(this.recipientService.getShortUrl(), this.recipientService.getState());


    this.route.queryParams.subscribe(
      params => {
        if (params.token != null || params.token != undefined) {
          window.close;
        }
      });
    this.initFormLogin();
    this.initBtnLoginsocial();
    this.authLoginService.logout();
  }

  ngAfterContentInit(): void {
    this.socialLoadApiService.googleButtonRender();
  }


  checkValidatiorsFormLogin(type: any) {
    const _calss = {
      VALID: '',
      INVALID: 'invalid'
    }
    this.validators[type] = _calss[this.formLogin.get(type).status];
  }



  initFormLogin() {
    this.formLogin = this.fb.group({
      username: [null, Regex.userLogin],
      password: [null, Regex.passwordLogin],
    });

    this.formLogin.valueChanges.subscribe(value => {
      this.checkDisabledBtn();
    });

    this.formLogin.get('username').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormLogin('username');
    });
    this.formLogin.get('password').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormLogin('password');
    });
  }

  ngDoCheck(): void {
    if (this.socialLoadApiService.getOnLoadSocail()) {
      let g_resSns = this.socialLoadApiService.getDataSocial();
      this.onLoginBySns(g_resSns.snsData.email, g_resSns.snsData.id, g_resSns.regisSocial.loginSocial, g_resSns.regisSocial.loginSocial, g_resSns.regisSocial.loginSocial);
      this.socialLoadApiService.setOnloadSocial(false);
    }
  }

  createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0) {
    if (url == null) {
      return null;
    }
    const options = `width=${width},height=${height},left=${left},top=${top}`;

    //to actually open the window..
    this.win = window.open(url, name, options);
    return this.win;
  }

  btnClick($event): void {
    const sns_type = $event.target.getAttribute('data-social');
    if (sns_type == "kakao") {
      this.socialLoadApiService.getKaKaoUserProfile();
    }
    else if (sns_type == "naver") {
      this.socialLoadApiService.getNaverUserPofile();
    }
    else if (sns_type == 'google') {
      this.socialLoadApiService.getGoogleUserPofile();
    }
  }

  // SNS Login
  initBtnLoginsocial() {

  }


  onLogin() {
    this.loadingService.openLoading();
    this.authLoginService.login(this.formLogin.get('username').value, this.formLogin.get('password').value).subscribe((res: OAuthToken) => {
      TokenUtils.setToken(res);
      this.loginService.setDataLogin(res);
      this.loadingService.closeLoading();
      console.log(this.recipientService.getShortUrl() !== null && this.recipientService.getState() === 'type');
      if (this.recipientService.getShortUrl() !== null && this.recipientService.getState() === 'type') {
        if (this.recipientService.getVerifi().pwd === "Y")
            this.recipientService.setState('password');
          else {
            this.recipientService.setState('write');
          }
        this.router.navigate(['/recipient-contract'], { queryParams: { cont: this.recipientService.getShortUrl() } });
      } else {
        this.router.navigate(['main']);
      }
    }, error => {
      this.loadingService.closeLoading();
      let msg = (error.error.error_description == "Bad credentials" ? "입력 한 사용자 이름 또는 비밀번호가 올바르지 않습니다." : error.error.error_description);
      this.alertService.open(
        "로그인",
        msg,
        {
          func: () => {
            this.alertService.close();
          }
        },
        {
          func: () => {
            this.alertService.close();
          }
        }
      );
    }
    );
  }

  onLoginBySns(p_id: string, p_pwd: string, clientId: string, clientSecret: string, type) {
    this.loadingService.openLoading();
    // Login....
    console.log('인수', p_id, p_pwd, clientId, clientSecret, type)
    this.authLoginService.login(p_id, p_pwd, clientId, clientSecret, type).subscribe((res: OAuthToken) => {
      this.loadingService.closeLoading();
      TokenUtils.setToken(res);
      this.loginService.setDataLogin(res);
      if (this.recipientService.getShortUrl() !== null && this.recipientService.getState() === 'type') {
        if (this.recipientService.getVerifi().pwd === "Y")
            this.recipientService.setState('password');
          else {
            this.recipientService.setState('write');
          }
        this.router.navigate(['/recipient-contract'], { queryParams: { cont: this.recipientService.getShortUrl() } });
      } else {
        this.router.navigate(['main']);
      }
    }, error => {
      this.loadingService.closeLoading();
      let msg = error.error.error_description;
      if (msg == "Bad credentials") {
        this.alertService.open(
          "이메일 주소 또는 패스워드가 정확하지 않습니다.",
          "사용하신 SNS 계정이 Gcont에 가입된 계정이 아닙니다. Gcont 계정을 먼저 생성해주세요.",
          {
            func: () => {
              this.socialLoadApiService.setOnloadSocial(true);
              this.router.navigate(['register']);
            }
          },
          null,
        );
      } else {
        this.alertService.open(
          "로그인",
          (error.error.error_description == "Bad credentials" ? "입력 한 사용자 이름 또는 비밀번호가 올바르지 않습니다." : error.error.error_description),
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }
      // Fail
    }
    );

  }

  checkDisabledBtn() {
    const valid = {
      VALID: false,
      INVALID: true
    }
    this.disabledBtn = valid[this.formLogin.status];
  }
}