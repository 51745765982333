import { Component, OnInit } from '@angular/core';
import { faSearch, faTimes, faTv, faRedo, faExclamationCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ElectronicContractDetailsService } from '../modal/electronic-contract-details/electronic-contract-details.service';
import { ContractCancellationService } from '../modal/contract-cancellation/contract-cancellation.service';
import { TableRequestedMeService } from './table-requested-me.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { ContractService } from '../../service/contract/contract.service';
import { NavbarService } from '../navbar/navbar.service';
import { LoginService } from '../../../frontpage/login/login.service';

import { Router } from '@angular/router';
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { LoadingService } from '../../shared/loading/loading.service';
import dateShared from '../../shared/date-shared/date-shared';
@Component({
  selector: 'app-table-requested-me',
  templateUrl: './table-requested-me.component.html',
  styleUrls: ['./table-requested-me.component.scss']
})
export class TableRequestedMeComponent implements OnInit {

  dateFormat: dateShared = new dateShared();
  faTv = faTv;
  faRedo = faRedo;
  faExclamationCircle = faExclamationCircle;
  faDownload = faDownload;
  tpTwo: any;
  tList: any = [];
  getUser: boolean = true;

  constructor(
    private router: Router,
    private contractCancellationService: ContractCancellationService,
    private electronicContractDetailsService: ElectronicContractDetailsService,
    private tableRequestedMeService: TableRequestedMeService,
    private sidebarService: SidebarService,
    private contractService: ContractService,
    private loginService: LoginService,
    private editorPdfService: EditorPdfService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
  ) { }

  paramsApi = {
    data: {
      contorId: "",
      text: "",
      serachType: ""
    },
    pageNo: 0,
    pageSize: 10
  }

  pagination = {
    maxPage: 1,
    currentPage: 1
  }

  ngOnInit(): void {
    this.navbarService.setAction('contorMe');
    this.sidebarService.setSubManuActive('requested_to_me');
  }

  ngDoCheck(): void {
    if (this.tableRequestedMeService.getState() && (this.loginService.getProfileLogin() != null)) {
      this.initParamsApi();
      this.getContractAllToMe();
      this.tableRequestedMeService.setState(false);
    }
    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.getUser = false;
      this.initParamsApi();
      this.getContractAllToMe();
    }
  }
  initParamsApi(): void {
    this.paramsApi = this.tableRequestedMeService.getData();
    this.pagination.currentPage = this.tableRequestedMeService.getPageNo() + 1;
    this.paramsApi.data.contorId = this.loginService.getProfileLogin().userIdNo;
  }

  getContractAllToMe() {
    this.loadingService.openLoading();
    this.contractService.getAllContractsRequestToMe(this.paramsApi).subscribe((rs: any) => {
      if (rs.status) {
        this.loadingService.closeLoading();
        this.tpTwo = rs.data;
        console.log('ddd', this.tpTwo);
        this.calMaxPage(rs.total, this.paramsApi.pageSize);
      }
    });
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.paramsApi.pageNo = $event - 1;

    this.tableRequestedMeService.setPageNo($event - 1);
    this.getContractAllToMe();
  }

  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));

      console.log(itemTotal % pageSize, this.pagination.currentPage, this.pagination.maxPage);
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.paramsApi.pageNo = this.pagination.currentPage - 1;
      }
      this.loadingService.openLoading();
      this.contractService.getAllContractsRequestToMe(this.paramsApi).subscribe((rs: any) => {
        if (rs.status) {
          this.tpTwo = rs.data;
          console.log('ㄹㄹ',this.tpTwo)
          this.loadingService.closeLoading();
        }
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
  }
  inDataTwo() {
    return [{
      action: "완료",
      name: "표준근로계약서 2020",
      sender: "진혜연",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      action: "진행중",
      name: "아르바이트 근로계약서",
      sender: "조아라",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      action: "진행중",
      name: "부동산 임대계약서",
      sender: "김용선",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      action: "진행중",
      name: "아르바이트 근로계약서",
      sender: "조아라",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      action: "진행중",
      name: "부동산 임대계약서",
      sender: "김용선",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      action: "진행중",
      name: "아르바이트 근로계약서",
      sender: "조아라",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      action: "진행중",
      name: "부동산 임대계약서",
      sender: "김용선",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    }]
  }

  onClickContractCancellation() {
    this.contractCancellationService.open(
      {
        func: () => {
          this.contractCancellationService.close();
        }
      }, {
      func: () => {
        this.updateCancleContract(this.contractCancellationService.getContractCancle(), this.contractCancellationService.getMessage());
        this.contractCancellationService.close();
      }
    }
    );
  }

  updateCancleContract(contractCancle: string, message: string) {
    let data = {
      contract: this.tList,
      contractCancle: contractCancle,
      message: message
    }
    this.loadingService.openLoading();
    this.contractService.updateCancleContract(data).subscribe((rs: any) => {
      this.tList = [];
      this.getContractAllToMe();
      this.loadingService.closeLoading();
    });
  }

  checkBoxSelect(contorId: string, contId: string) {
    let arr = this.tList;

    console.log(contorId, contId);
    let index = arr.findIndex(res => (res.contorId === contorId && res.contId === contId));

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push({ contorId: contorId, contId: contId });
    }
    this.tList = arr;
    console.log(this.tList);
  }

  checkSelectTeplate(contorId: string, contId: string): string {
    let classCss = "rowChecked";

    let data = this.tList.filter((res) => {
      return (res.contorId === contorId && res.contId === contId);
    });

    if (!(data.length > 0)) {
      classCss = "";
    }

    return classCss;
  }


  onClickExclamation(conId: string, contId: string) {
    this.getContractDetails(conId, contId);
  }


  getContractDetails(conId: string, contId: string) {
    let params = {
      contId: conId,
      contorId: contId
    }
    this.contractService.getContractDetails(params).subscribe((rs: any) => {
      if (rs.status) {
        this.electronicContractDetailsService.setConrtractDetails(rs.data.contDetails);
        this.electronicContractDetailsService.setContractHistory(rs.data.contHistory);
        this.electronicContractDetailsService.open();
      }
    });
  }

  onClickReviewContract(tempId: string, contNm: string, contId: string, contorId: string) {
    this.editorPdfService.setContor({ contorId: contorId, contId: contId });
    this.editorPdfService.setTemplateId(tempId);
    this.editorPdfService.setTemplNm(contNm);
    this.editorPdfService.setContorType('%');
    this.editorPdfService.setEdteMode("peview");
    this.editorPdfService.setState("init");
    this.editorPdfService.download = false;
    this.router.navigate(['review-template-contract']);
  }

  onClickReviewDownloadContract(tempId: string, contNm: string, contId: string, contorId: string) {
    this.editorPdfService.setContor({ contorId: contorId, contId: contId });
    this.editorPdfService.setTemplateId(tempId);
    this.editorPdfService.setTemplNm(contNm);
    this.editorPdfService.setContorType('%');
    this.editorPdfService.setEdteMode("peview");
    this.editorPdfService.setState("init");
    this.editorPdfService.download = true;
    this.router.navigate(['dowliad-contract']);
  }
  onClickReviewContractRow($event, tempId: string, contNm: string, contId: string, contorId: string) {
    if ($event.target.toString().includes("Table")) {
      this.editorPdfService.setContor({ contorId: contorId, contId: contId });
      this.editorPdfService.setTemplateId(tempId);
      this.editorPdfService.setTemplNm(contNm);
      this.editorPdfService.setContorType('%');
      this.editorPdfService.setEdteMode("peview");
      this.editorPdfService.setState("init");
      this.editorPdfService.download = false;
    }
  }
}
