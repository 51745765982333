<header id="home-section">

  <div class="standardFront">
    <div class="dark-overlay ">
      <div class="home-inner container-md2">
        <div class="row align-items-start">

          <div class="col-md-12 ">
            <h1 class="headingbannertxt text-white"><span class="gothicLight">
                블록체인 인증으로<br>
                투명한 전자계약 체결이 가능한
              </span>
            </h1>

            <h2 class="text-white">
              개방형 블록체인<br class="d-block d-md-none">전자계약 플랫폼
            </h2>

          </div>

        </div>
      </div>
    </div>
  </div>


  <!-- oddFront -->

  <div class="oddFront">
    <div class="dark-overlay">
      <div class="home-inner container-md">
        <div class="row m-0 align-items-start">

          <div class="col-md-12 p-0">
            <h1 class="headingbannertxt text-white"><span class="gothicLight">
                블록체인 인증으로<br>
                투명한 전자계약 체결이 가능한
              </span>
            </h1>

            <h2 class="text-white">
              개방형 블록체인<br>전자계약 플랫폼
            </h2>

          </div>

        </div>
      </div>
    </div>
  </div>

</header>