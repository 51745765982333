import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PaymentService } from '../../service/payment/payment.service';
import { LoadingService } from "../../shared/loading/loading.service";

import { ModalRefundPaymentService } from '../../component/modal/modal-refund-payment/modal-refund-payment.service';
import { CommonFunction } from '../../service/common-function/common-function.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private paymentService: PaymentService,
    private loadingService: LoadingService,
    private commonFuction: CommonFunction,
    private modalRefundPaymentService: ModalRefundPaymentService,
  ) { }

  @Output() clickItem = new EventEmitter<any>();

  paramGetPay = {
    data: "",
    pageNo: 0,
    pageSize: 5
  }
  pagination = {
    maxPage: 1,
    currentPage: 1
  }
  itemTotal: number = 0;

  td: any;
  fromSearchPaymentTwo: FormGroup;

  fromSearchPaymentThree: FormGroup;
  ngOnInit(): void {
    this.initFromSearchPayment();
    this.getPaymentHistoryList();
  }

  initFromSearchPayment() {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let hours = today.getHours();
    let minutes = today.getMinutes();
    hours = hours
    let minute = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minute
    let date = yyyy + '-' + mm + '-' + dd;

    this.fromSearchPaymentTwo = this.fb.group({
      processingStatus: [0],
      dateStart: [date],
      timeStart: [strTime],
      dateEnd: [date],
      timeEnd: [strTime],
      cardNumber: [null]
    });


    this.fromSearchPaymentThree = this.fb.group({
      processingStatus: [0],
      dateStart: [date],
      timeStart: [strTime],
      dateEnd: [date],
      timeEnd: [strTime],
      cardNumber: [null]
    });

  }

  getPaymentHistoryList() {
    this.td = [];
    this.loadingService.openLoading();
    this.paymentService.getPaymentHistoryList(this.paramGetPay).subscribe((rs: any) => {
      if (rs.status) {
        console.log(rs.data);
        this.td = rs.data
        this.itemTotal = rs.total;
        this.calMaxPage(rs.total, this.paramGetPay.pageSize);
      }
      this.loadingService.closeLoading();
    });
  }

  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.paramGetPay.pageNo = this.pagination.currentPage - 1;
      }
      this.paymentService.getPaymentHistoryList(this.paramGetPay).subscribe((rs: any) => {
        if (rs.status) {
          this.td = rs.data;
        }
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.paramGetPay.pageNo = $event - 1;
    this.getPaymentHistoryList();
  }

  inData() {
    return [
      {
        paymentDate: '2020/03/30 09:30:30',
        paymentServiceHistory: '프리미엄',
        paymentMethod: '신용카드',
        cardNumber: '1234-1234-****-5678',
        amountOfPayment: '1,000,000',
        approvalNumber: '79234598',
        approvalDate: '2020/05/02',
        purchasePoint: '1,000',
        processingStatus: '요청',
        refundOrCancellation: true
      },
      {
        paymentDate: '2020/03/30 09:30:30',
        paymentServiceHistory: '프리미엄',
        paymentMethod: '신용카드',
        cardNumber: '1234-1234-****-5678',
        amountOfPayment: '500,000',
        approvalNumber: '79234598',
        approvalDate: '2020/05/02',
        purchasePoint: '100',
        processingStatus: '진행중',
        refundOrCancellation: false
      },
      {
        paymentDate: '2020/03/30 09:30:30',
        paymentServiceHistory: '프리미엄',
        paymentMethod: '신용카드',
        cardNumber: '1234-1234-****-5678',
        amountOfPayment: '10,000',
        approvalNumber: '79234598',
        approvalDate: '2020/05/02',
        purchasePoint: '10',
        processingStatus: '환불완료',
        refundOrCancellation: true
      },
      {
        paymentDate: '2020/03/30 09:30:30',
        paymentServiceHistory: '프리미엄',
        paymentMethod: '신용카드',
        cardNumber: '1234-1234-****-5678',
        amountOfPayment: '1,000,000',
        approvalNumber: '79234598',
        approvalDate: '2020/05/02',
        purchasePoint: '1,000',
        processingStatus: '요청',
        refundOrCancellation: true
      },
      {
        paymentDate: '2020/03/30 09:30:30',
        paymentServiceHistory: '프리미엄',
        paymentMethod: '신용카드',
        cardNumber: '1234-1234-****-5678',
        amountOfPayment: '500,000',
        approvalNumber: '79234598',
        approvalDate: '2020/05/02',
        purchasePoint: '100',
        processingStatus: '진행중',
        refundOrCancellation: false
      },
      {
        paymentDate: '2020/03/30 09:30:30',
        paymentServiceHistory: '프리미엄',
        paymentMethod: '신용카드',
        cardNumber: '1234-1234-****-5678',
        amountOfPayment: '10,000',
        approvalNumber: '79234598',
        approvalDate: '2020/05/02',
        purchasePoint: '10',
        processingStatus: '환불완료',
        refundOrCancellation: true
      }
    ]
  }

  selectItem(item: any, $event) {
    console.log(!$event.target.toString().includes("Button"));
    if (!$event.target.toString().includes("Button")) {
      this.clickItem.emit(item);
    }
  }

  onRefund(aId: string, tId: string, cId: string, total: string, t: any) {
    console.log(aId, tId, cId, parseInt(total));
    this.modalRefundPaymentService.open(
      {
        func: () => {
          this.modalRefundPaymentService.close();
        }
      },
      {
        func: () => {
          this.refundPayment(t.approvalNumber,this.modalRefundPaymentService.getContent());
          this.modalRefundPaymentService.close();
        }
      },
      t
    );
  }

  refundPayment(qaaAid: string, qaaContent: string) {
    let data = {
      qaaAid: qaaAid,
      qaaContent: qaaContent
    }
    this.loadingService.openLoading();
    this.paymentService.refundPayment(data).subscribe((rs: any) => {
      if (rs.status) {
        this.loadingService.closeLoading();
        this.getPaymentHistoryList();
      }
    });
  }
}
