<section class="container-fluid" style="background: #E9ECEE;">

  <div class="container-lg2 py-5">


    <div class="row">
      <div class="col-12">
        <h1 class="tit-pag-up">
          <span>제공된 서식으로 만들기</span>
          <img src="../../../../assets/img/icon/icon-arrow-right-cr.png" class="img-fluid mx-3">
          <span class="font-weight-700 barumBold">부동산 임대계약서 </span>

        </h1>
      </div>
    </div>
    <hr>

    <form [formGroup]=formContractWord id="formContractWord">
      <div class="row">
        <div class="col-12">
          <hr style="margin:30px 0px 15px 0px; border: 2px solid #f82644; width: 25px; background: #f82644;">
          <h3 class="formHeading">
            계약서 정보 입력</h3>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="location">소재지</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control  {{valid.location}}" formControlName='{location}' maxlength="40"
              id="location" name="location" placeholder="소재지" aria-describedby="location">
              <small *ngIf="small.location" class="form-text text-muted text-validators {{formContractWord.get('{location}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
            </div>
          </div>
          <div class="col-md-6">
            
            <div class="inlineselects row no-gutters">
              <div class="col-12"> <label>임대 조건</label></div>
              <div class="">
                <div class="form-inline">
                  <div class="form-group  ">
                    <div class="input-group">
                      <div class="form-group form-check p-0 m-0 contractCheckbox" style="line-height: 1; width:10px;">
                        <label class="con-che-001">
                          <input formControlName='{cho}' type="checkbox"><span class="che-mar-001"></span></label>
                      </div>
                      <div class="input-group-append">
                        <span class="input-group-text">전세</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group  ">
                    <div class="input-group">
                      <div class="form-group form-check p-0 m-0 contractCheckbox" style="line-height: 1; width:10px;">
                        <label class="con-che-001">
                          <input formControlName='{cht}' type="checkbox"><span class="che-mar-001"></span></label>
                      </div>
                      <div class="input-group-append">
                        <span class="input-group-text">월세</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="landp">토지 (지목)
            </label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.landp}}" formControlName='{landp}' maxlength="29"
              id="landp" name="landp" placeholder="지목" aria-describedby="landp">
              <small *ngIf="small.landp" class="form-text text-muted text-validators {{formContractWord.get('{landp}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label for="areaone">면적
            </label>
            <input type="text" (change)="setFormaNumberAreaOne()" pattern="^[+]?[\d,]*(\.?\d*)$" class="form-control {{valid.areaone}}" formControlName='{areaone}'
              id="areaone" name="areaone" placeholder="면적" aria-describedby="areaone">
              <small *ngIf="small.areaone" class="form-text text-muted text-validators {{formContractWord.get('{areaone}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      숫자만 입력해 주세요.
              </small>
          </div>
        </div>

      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="build">건물 (구조 용도)

            </label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.build}}" formControlName='{build}' maxlength="29"
              id="build" name="build" placeholder="구조 용도" aria-describedby="build">
              <small *ngIf="small.build" class="form-text text-muted text-validators {{formContractWord.get('{build}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label for="areatwo">면적
            </label>
            <input type="text" (change)="setFormaNumberAreaTwo()" pattern="^[+]?[\d,]*(\.?\d*)$" class="form-control {{valid.areatwo}}" formControlName='{areatwo}'
              id="areatwo" name="areatwo" placeholder="면적" aria-describedby="areatwo">
              <small *ngIf="small.areatwo" class="form-text text-muted text-validators {{formContractWord.get('{areatwo}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      숫자만 입력해 주세요.
                      
              </small>
          </div>
        </div>

      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="part">임대할 부분</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.part}}" formControlName='{part}' maxlength="36"
              id="part" name="part" placeholder="임대할 부분" aria-describedby="part">
              <small *ngIf="small.part" class="form-text text-muted text-validators {{formContractWord.get('{part}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label for="areatree">면적</label>
            <input type="text" (change)="setFormaNumberAreaThree()" pattern="^[+]?[\d,]*(\.?\d*)$" class="form-control {{valid.areatree}}" formControlName='{areatree}'
              id="areatree" name="areatree" placeholder="면적" aria-describedby="areatree">
              <small *ngIf="small.areatree" class="form-text text-muted text-validators {{formContractWord.get('{areatree}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      숫자만 입력해 주세요.
              </small>
          </div>
        </div>

      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="depoone">보증금</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.depoone}}" formControlName='{depoone}' maxlength="30"
              id="depoone" name="depoone" placeholder="오백만" aria-describedby="depoone">
              <small *ngIf="small.depoone" class="form-text text-muted text-validators {{formContractWord.get('{depoone}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="exped" class="invisible">면적 </label>
            <div class="input-group">
              <div class="input-group-prepend ">
                <span class="input-group-text py-0 bg-transparent border-0 pl-0">원정</span>
              </div>
              <input style="border-radius: 4px;" type="text" pattern="^[+]?[\d,]*(\.?\d*)$" class="form-control {{valid.exped}}"
                formControlName='{exped}' (change)="setFormaNumberExped()" id="exped" name="exped" placeholder="₩" aria-describedby="exped">
              <div class="input-group-append">
                <span class="input-group-text bg-transparent border-0">원</span>
              </div>
            </div>
            <small *ngIf="small.exped" class="form-text text-muted text-validators {{formContractWord.get('{exped}').status}} font-16">
                <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    숫자만 입력해 주세요.
            </small>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="depotwo">계약금 </label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.depotwo}}" formControlName='{depotwo}' maxlength="20"
              id="depotwo" name="depotwo" placeholder="오십만" aria-describedby="emailHelp">
              <small *ngIf="small.depotwo" class="form-text text-muted text-validators {{formContractWord.get('{depotwo}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="pay" class="invisible">면적</label>
            <div class="input-group">
              <div class="input-group-prepend ">
                <span class="input-group-text py-0 bg-transparent border-0 pl-0">원정 계약시에 지불</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="depotree">중도금</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.depotree}}" formControlName='{depotree}' maxlength="20"
              id="depotree" name="depotree" placeholder="이백만" aria-describedby="depotree">
              <small *ngIf="small.depotree" class="form-text text-muted text-validators {{formContractWord.get('{depotree}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="inlineselects row no-gutters">
            <div class="col-12"> <label>지불일</label></div>
            <div class="">
              <div class="form-inline">
                <div class="form-group  ">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text pl-0">원정</span>
                    </div>
                    <select pattern="^-?([0-9]\d*)?$" for="poys" formControlName='{poys}' id="poys" class="form-control {{valid.poys}}">
                      <option value="N">선택</option>
                      <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">년</span>
                    </div>
                  </div>
                </div>


                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="poms" formControlName='{poms}' id="poms" class="form-control {{valid.poms}}">
                      <option value="N">선택</option>
                      <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">월</span>
                    </div>
                  </div>
                </div>

                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="pods" formControlName='{pods}' id="pods" class="form-control {{valid.pods}}">
                      <option value="N">선택</option>
                      <option *ngFor="let d of data.pods;let i = index" value={{d.value}}>{{d.day}}</option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">일</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="bala">잔금
            </label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.bala}}" formControlName='{bala}' maxlength="20"
              id="bala" name="bala" placeholder="삼백오십만" aria-describedby="emailHelp">
              <small *ngIf="small.bala" class="form-text text-muted text-validators {{formContractWord.get('{bala}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="inlineselects row no-gutters">
            <div class="col-12"> <label>지불일</label></div>
            <div class="">
              <div class="form-inline">
                <div class="form-group  ">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text pl-0">원정</span>
                    </div>
                    <select pattern="^-?([0-9]\d*)?$" for="ptys" formControlName='{ptys}' id="ptys" class="form-control {{valid.ptys}}">
                      <option value="N">선택</option>
                      <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">년</span>
                    </div>
                  </div>
                </div>


                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="ptms" formControlName='{ptms}' id="ptms" class="form-control {{valid.ptms}}">
                      <option value="N">선택</option>
                      <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">월</span>
                    </div>
                  </div>
                </div>

                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="ptds" formControlName='{ptds}' id="ptds" class="form-control {{valid.ptds}}">
                      <option value="N">선택</option>
                      <option *ngFor="let d of data.ptds;let i = index" value={{d.value}}>{{d.day}}</option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">일</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="baborrla">차입금</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.baborrla}}" formControlName='{baborrla}' maxlength="20"
              id="baborrla" name="baborrla" placeholder="차입금" aria-describedby="baborrla">
              <small *ngIf="small.baborrla" class="form-text text-muted text-validators {{formContractWord.get('{baborrla}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      문자만 입력해 주세요.
              </small>
          </div>
        </div>

        <div class="col-md-6">


          <div class="inlineselects row no-gutters">
            <div class="col-12"> <label>지불일</label></div>
            <div class="">
              <div class="form-inline">
                <div class="form-group  ">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text pl-0">원정은</span>
                    </div>
                    <div class="form-group form-check p-0 m-0 contractCheckbox" style="line-height: 1; width:10px;">
                      <label class="con-che-001"><input formControlName='{pcpr}' type="checkbox"><span class="che-mar-001"></span></label></div>
                    <div class="input-group-append">
                      <span class="input-group-text">선불</span>
                    </div>
                  </div>
                  <small *ngIf="small.pcpr" class="form-text text-muted text-validators {{formContractWord.get('{pcpr}').status}} font-16">
                      <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                          alt="alert icon">
                          필수 입력값을 정확하게 입력해 주세요
                  </small>
                </div>


                <div class="form-group  ">
                  <div class="input-group">
                    <div class="form-group form-check p-0 m-0 contractCheckbox" style="line-height: 1; width:10px;">
                      <label class="con-che-001">
                        <input formControlName='{pcpo}' type="checkbox"><span class="che-mar-001"></span></label></div>
                    <div class="input-group-append">
                      <span class="input-group-text">후불</span>
                    </div>
                    <small *ngIf="small.pcpo" class="form-text text-muted text-validators {{formContractWord.get('{pcpo}').status}} font-16">
                        <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                            alt="alert icon">
                            필수 입력값을 정확하게 입력해 주세요
                    </small>
                  </div>
                </div>

                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="pday" formControlName='{pday}' id="pday" class="form-control {{valid.pday}}">
                      <option value="N">선택</option>
                      <option *ngFor="let d of data.dpays;let i = index" value={{d.value}}>{{d.day}}</option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">일 지불</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="inlineselects row no-gutters">
        <div class="col-12"> <label>존속 기간
          </label></div>
        <div class="">
          <div class="form-inline">
            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="tdys" formControlName='{tdys}' id="tdys" class="form-control {{valid.tdys}}">
                  <option value="N">선택</option>
                  <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">년</span>
                </div>
              </div>
            </div>


            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="tdms" formControlName='{tdms}' id="tdms" class="form-control {{valid.tdms}}">
                  <option value="N">선택</option>
                  <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">월</span>
                </div>
              </div>
            </div>

            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="tdds" formControlName='{tdds}' id="tdds" class="form-control {{valid.tdds}}">
                  <option value="N">선택</option>
                  <option *ngFor="let d of data.tdds;let i = index" value={{d.value}}>{{d.day}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">일</span>
                </div>
              </div>
            </div>
          </div>




        </div>
        <div class="">
          <div class="form-inline">
            <!-- <label>~</label> -->
            <div class="form-group  ">
              <div class="input-group">
                <div class="d-none d-md-block input-group-prepend">
                  <span class="input-group-text">~</span>
                </div>
                <select pattern="^-?([0-9]\d*)?$" for="tdye" formControlName='{tdye}' id="tdye" class="form-control {{valid.tdye}}">
                  <option value="N">선택</option>
                  <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">년</span>
                </div>
              </div>
            </div>


            <div class="form-group ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="tdme" formControlName='{tdme}' id="tdme" class="form-control {{valid.tdme}}">
                  <option value="N">선택</option>
                  <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">월</span>
                </div>
              </div>
            </div>


            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="tdde" formControlName='{tdde}' id="tdde" class="form-control {{valid.tdde}}">
                  <option value="N">선택</option>
                  <option *ngFor="let d of data.tdde;let i = index" value={{d.value}}>{{d.day}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">일</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="spec">특약 사항
            </label>
            <textarea class="form-control {{valid.spec}}" style="resize: none;" maxlength="204" rows="40" wrap="'virtual'"
              formControlName='{spec}' id="spec" name="spec" placeholder="내용 입력" aria-describedby="spec"></textarea>
              <small *ngIf="small.spec" class="form-text text-muted text-validators {{formContractWord.get('{spec}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      필수 입력값을 정확하게 입력해 주세요
              </small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="inlineselects row no-gutters">
            <div class="col-12"> <label>계약일</label></div>
            <div class="">
              <div class="form-inline">
                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="yy" formControlName='{yy}' id="yy"
                      class="form-control {{valid.yy}}">
                      <option value="N">선택</option>
                      <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">년</span>
                    </div>
                  </div>
                </div>
                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="mm" formControlName='{mm}' id="mm"
                      class="form-control {{valid.mm}}">
                      <option value="N">선택</option>
                      <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">월</span>
                    </div>
                  </div>
                </div>
                <div class="form-group  ">
                  <div class="input-group">
                    <select pattern="^-?([0-9]\d*)?$" for="dd" formControlName='{dd}' id="dd"
                      class="form-control {{valid.dd}}">
                      <option value="N">선택</option>
                      <option *ngFor="let d of data.dd;let i = index" value={{d.value}}>{{d.day}}</option>
                    </select>
                    <div class="input-group-append">
                      <span class="input-group-text">일</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="addcl">임대인 주소</label>
            <input type="text" formControlName='{addcl}' id="addcl" name="addcl" class="form-control" placeholder="임대인 주소" maxlength="40" />
            <small *ngIf="small.addcl" class="form-text text-muted text-validators {{formContractWord.get('{addcl}').status}} font-16">
                <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-row">
            <div class="col">
              <label for="senuml">임대인 주민번호</label>
              <input type="text"   formControlName='{senuml}' id="senuml" name="senuml" minlength="13" maxlength="13" pattern="^-?([0-9]\d*)?$"class="form-control" placeholder="임대인 주민번호" />
              <small *ngIf="small.senuml" class="form-text text-muted text-validators {{formContractWord.get('{senuml}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      주민번호 13 자리를 입력해 주세요 (숫자만 입력)
              </small>
          </div>
          <div class="col">
              <label for="pnuml">임대인 전화번호</label>
              <input type="text" formControlName='{pnuml}' id="pnuml" name="pnuml" minlength="10" maxlength="11" pattern="^-?([0-9]\d*)?$" class="form-control" placeholder="임대인 전화번호 " />
              <small *ngIf="small.pnuml" class="form-text text-muted text-validators {{formContractWord.get('{pnuml}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      10 - 11 자리를 입력해 주세요 (숫자만 입력)
              </small>
          </div>
          <div class="col">
              <label for="lnamel">임대인 성명</label>
              <input type="text" formControlName='{lnamel}' id="lnamel" name="lnamel" class="form-control" placeholder="임대인 성명" maxlength="7" />
              <small *ngIf="small.lnamel" class="form-text text-muted text-validators {{formContractWord.get('{lnamel}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      필수 입력값을 정확하게 입력해 주세요
              </small>
            </div>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="raddcl">임대 대리인 주소</label>
            <input formControlName='{raddcl}' id="raddcl" name="raddcl" type="text" class="form-control" placeholder="임대 대리인 주소" maxlength="17" />
            <small *ngIf="small.raddcl" class="form-text text-muted text-validators {{formContractWord.get('{raddcl}').status}} font-16">
                <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-row">
            <div class="col">
              <label for="rcnuml">임대 대리인 주민번호</label>
              <input type="text" formControlName='{rcnuml}' id="rcnuml" name="rcnuml" class="form-control" placeholder="임대 대리인 주민번호"  minlength="13" maxlength="13" pattern="^-?([0-9]\d*)?$" />
              <small *ngIf="small.rcnuml" class="form-text text-muted text-validators {{formContractWord.get('{rcnuml}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      주민번호 13 자리를 입력해 주세요 (숫자만 입력)
              </small>
            </div>
            <div class="col">
              <label for="rcanl">임대 대리인 성명</label>
              <input type="text" formControlName='{rcanl}' id="rcanl" name="rcanl" class="form-control" placeholder="임대 대리인 성명" maxlength="7" />
              <small *ngIf="small.rcanl" class="form-text text-muted text-validators {{formContractWord.get('{rcanl}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      필수 입력값을 정확하게 입력해 주세요
              </small>
          </div>
          <div class="col invisible d-none d-md-block">
          </div>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="addct">임차인 주소</label>
            <input type="text" formControlName='{addct}' id="addct" name="addct" class="form-control" placeholder="임차인 주소" maxlength="40" />
            <small *ngIf="small.addct" class="form-text text-muted text-validators {{formContractWord.get('{addct}').status}} font-16">
                <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-row">
            <div class="col">
              <label for="exampleInputEmail1">임차인 주민번호</label>
              <input type="text" formControlName='{senumt}' id="senumt" name="senumt" class="form-control" minlength="13" maxlength="13" pattern="^-?([0-9]\d*)?$" placeholder="임차인 주민번호" />
              <small *ngIf="small.senumt" class="form-text text-muted text-validators {{formContractWord.get('{senumt}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      주민번호 13 자리를 입력해 주세요 (숫자만 입력)
              </small>
            </div>
            <div class="col">
              <label for="exampleInputEmail1">임차인 전화번호
              </label>
              <input type="text" formControlName='{pnumt}' id="pnumt" name="pnumt" class="form-control" minlength="10" maxlength="11"  pattern="^-?([0-9]\d*)?$" placeholder="임차인 전화번호" />
              <small *ngIf="small.pnumt" class="form-text text-muted text-validators {{formContractWord.get('{pnumt}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      10 - 11 자리를 입력해 주세요 (숫자만 입력)
              </small>
            </div>
            <div class="col">
              <label for="exampleInputEmail1">임차인 성명</label>
              <input type="text" formControlName='{lnamet}' id="lnamet" name="lnamet" class="form-control" placeholder="임차인 성명" maxlength="7" />
              <small *ngIf="small.lnamet" class="form-text text-muted text-validators {{formContractWord.get('{lnamet}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      필수 입력값을 정확하게 입력해 주세요
              </small>
            </div>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="raddct">임차 대리인 주소</label>
            <input type="text" formControlName='{raddct}' id="raddct" name="raddct" class="form-control" placeholder="임차 대리인 주소" maxlength="17" />
            <small *ngIf="small.raddct" class="form-text text-muted text-validators {{formContractWord.get('{raddct}').status}} font-16">
                <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                    alt="alert icon">
                    필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>


      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <div class="form-row">
            <div class="col">
              <label for="rcnumt">임차 대리인 주민번호</label>
              <input type="text" formControlName='{rcnumt}' id="rcnumt" name="rcnumt" class="form-control" minlength="13" maxlength="13" pattern="^-?([0-9]\d*)?$" placeholder="임차 대리인 주민번호" />
              <small *ngIf="small.rcnumt" class="form-text text-muted text-validators {{formContractWord.get('{rcnumt}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      주민번호 13 자리를 입력해 주세요 (숫자만 입력)
              </small>
            </div>
            <div class="col">
              <label for="rcant">임차 대리인 성명</label>
              <input type="text" formControlName='{rcant}' id="rcant" name="rcant" class="form-control" placeholder="임차 대리인 성명" maxlength="7" />
              <small *ngIf="small.rcant" class="form-text text-muted text-validators {{formContractWord.get('{rcant}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      필수 입력값을 정확하게 입력해 주세요
              </small>
            </div>
            <div class="col invisible d-none d-md-block"></div>
          </div>
        </div>
      </div>
    </form>
    <!-- -******- -->
    <div style="margin-top:50px;" class="row">
      <div class="col-12 d-flex justify-content-center">
        <button [disabled]="!formContractWord.valid" type="button" style="width:auto !important;"
          (click)="onClickBtnCreateContractWord()" class="btn btn-big btn-crimson">
          다음
        </button>
      </div>
    </div>
  </div>

</section>


<app-alert-shared></app-alert-shared>
<app-loading></app-loading>