import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalAttachmentFileService {

  constructor() { }

  fileId: string = '';
  fileName: string = '';

  status = {
    open: true,
    close: false
  };

  change = {
    show: false,
    state: false
  };
  show = false;

  btn: {
    btnOk: any, btnCancel: any
  } = {
      btnOk: null, btnCancel: null
    }

  open(btnOk: any, btnCancel: any = {
    func: () => {
      this.close();
    }
  }): void {
    this.btn.btnOk = btnOk;
    this.btn.btnCancel = btnCancel;
    this.change.show = true;
    this.show = this.status.open;
  }

  close() {
    this.change.show = true;
    this.show = this.status.close;
  }

  setChange(type: any, status: boolean) {
    this.change[type] = status;
  }

  setFileId(fileId: string) {
    this.fileId = fileId;
  }

  getFileId(): string {
    return this.fileId;
  }

  setFileName(fileName: string) {
    this.fileName = fileName;
  }

  getFileName(): string {
    return this.fileName;
  }
}
