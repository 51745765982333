import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertConfirmService } from '../modal/alert-confirm/alert-confirm.service';
import { TemplateManagementService } from './template-management.service';
import { SendLinkService } from '../modal/send-link/send-link.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { TemplateService } from '../../service/template/template.service';
import { LoadingService } from "../../shared/loading/loading.service";
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { DigitalsignatureService } from '../../service/digitalSignature/digitalsignature.service';
import { CodeBookService } from '../../service/code-book/code-book.service';
import { NavbarService } from '../navbar/navbar.service';
import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';

import { LoginService } from '../../../frontpage/login/login.service';
import { faSearch, faTimes, faShare, faPencilAlt, faLink, faDownload, faReply, faBriefcase } from '@fortawesome/free-solid-svg-icons';
// import { faSearch, faTimes, faShare, faPencilAlt, faLink, faDownload, faReply, faBriefcase, faS } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-management',
  templateUrl: './template-management.component.html',
  styleUrls: ['./template-management.component.scss']
})
export class TemplateManagementComponent implements OnInit {
  uploadForm: FormGroup;
  uploadfile: any;


  getUser: boolean = true;
  sidebar: any;
  faSearch = faSearch;
  faTimes = faTimes;
  faShare = faShare;
  faPencilAlt = faPencilAlt;
  faLink = faLink;
  faDownload = faDownload;
  faBriefcase = faBriefcase;
  formSearch: FormGroup;
  formCheckBox: FormGroup;
  pagination = {
    maxPage: 1,
    currentPage: 1
  }
  dataGetTmpl = {
    data: {
      frstRegisNo: "",
      serachType: "",
      text: ""
    },
    pageNo: 0,
    pageSize: 5
  }
  tp: any[] = [];
  faReply = faReply;
  tList: any[] = [];

  codeBook: {
    TEMPL_STATUS: {
      code: string,
      codeDc: string,
      codeGrpId: string,
      codeNm: string
    }[],
    SEARCH_TEMP: {
      code: string,
      codeDc: string,
      codeGrpId: string,
      codeNm: string
    }[],
    SEARCH_TEMP_MOBILE: {
      code: string,
      codeDc: string,
      codeGrpId: string,
      codeNm: string
    }[]
  } = {
      TEMPL_STATUS: [],
      SEARCH_TEMP: [],
      SEARCH_TEMP_MOBILE: []
    }

  searchTempTypes: any[] = [];

  constructor(
    private fb: FormBuilder,
    private alertConfirmService: AlertConfirmService,
    private sendLinkService: SendLinkService,
    private sidebarService: SidebarService,
    private templateService: TemplateService,
    private LoadingService: LoadingService,
    private router: Router,
    private editorPdfService: EditorPdfService,
    private digitalsignatureService: DigitalsignatureService,
    private formBuilder: FormBuilder,
    private templateManagementService: TemplateManagementService,
    private loginService: LoginService,
    private codeBookService: CodeBookService,
    private navbarService: NavbarService,
    private alertSharedService: AlertSharedService,

  ) {
    this.sidebar = 'template_management';
    this.sidebarService.setManuActive(this.sidebar);
  }

  ngOnInit(): void {
    this.navbarService.setAction('tempManage');
    this.getCodeBook("TEMPL_STATUS");
    this.getCodeBook("SEARCH_TEMP");
    this.getCodeBook('SEARCH_TEMP_MOBILE');
    this.initFormSearch();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  ngDoCheck(): void {
    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.getUser = false;
      this.initData();
      this.getTemplateAll();
    }
  }

  initData() {
    this.dataGetTmpl = this.templateManagementService.getData();
    this.dataGetTmpl.data.frstRegisNo = this.loginService.getProfileLogin().userIdNo;
    this.pagination.currentPage = this.templateManagementService.getPageNo() + 1;
  }

  getCodeBook(type: any) {
    this.codeBookService.getCodeBook(type).subscribe((rs: any) => {
      if (rs.status) {
        this.codeBook[type] = rs.data;

      }
    });
  }

  searchTemplate() {
    this.dataGetTmpl.pageNo = 0;
    this.tp = [];
    this.LoadingService.openLoading();

    this.templateService.getTemplateAll(this.dataGetTmpl).subscribe((rs: any) => {
      if (rs.status) {
        this.tp = rs.data;
        this.calMaxPage(rs.total, this.dataGetTmpl.pageSize);
      }
      this.LoadingService.closeLoading();
    });
  }

  getTemplateAll() {
    this.tp = [];
    this.LoadingService.openLoading();
    this.templateService.getTemplateAll(this.dataGetTmpl).subscribe((rs: any) => {
      if (rs.status) {
        this.tp = rs.data;
        this.LoadingService.closeLoading();
        this.calMaxPage(rs.total, this.dataGetTmpl.pageSize);
      }
    });
  }

  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.dataGetTmpl.pageNo = this.pagination.currentPage - 1;
      }
      this.LoadingService.openLoading();
      this.templateService.getTemplateAll(this.dataGetTmpl).subscribe((rs: any) => {
        if (rs.status) {
          this.tp = rs.data;
          this.LoadingService.closeLoading();
        }
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
    if(this.pagination.maxPage === 0){	
      this.pagination.maxPage = 1;	
    }
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.dataGetTmpl.pageNo = $event - 1;
    this.templateManagementService.setPageNo($event - 1);
    this.getTemplateAll();
  }

  deleteTemplate(templId: any) {
    this.templateService.deleteTemplate(templId).subscribe((rs: any) => {
      if (rs.status) {
        this.getTemplateAll();
      }
    });
  }

  onDownload(templId: any, temp_nm: string) {
    this.editorPdfService.setTemplateId(templId);	
    this.editorPdfService.setTemplNm(temp_nm);	
    this.editorPdfService.setContorType('%');	
    this.editorPdfService.setEdteMode("edit");	
    this.editorPdfService.setState("init");	
    this.editorPdfService.download = true;	
    this.router.navigate(['edit-template-contract']);
  }

  editTemp(data: any) {
    this.templateService.editTemp(data).subscribe((rs: any) => {
      if (rs.status) {
      }
    });
  }

  ngAfterViewInit(): void {
    this.sidebar = 'template_management';
    this.sidebarService.setManuActive(this.sidebar);
  }

  inData() {
    return [{
      id: 1,
      use: "Y",
      state: "완료",
      name: "표준근로계약서 2020",
      constructor: "김용선",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      id: 2,
      use: "N",
      state: "진행중",
      name: "아르바이트 근로계약서",
      constructor: "진혜연",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      id: 3,
      use: "Y",
      state: "완료",
      name: "표준근로계약서 2020",
      constructor: "김용선",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      id: 4,
      use: "N",
      state: "진행중",
      name: "아르바이트 근로계약서",
      constructor: "진혜연",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      id: 5,
      use: "Y",
      state: "완료",
      name: "표준근로계약서 2020",
      constructor: "김용선",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      id: 6,
      use: "N",
      state: "진행중",
      name: "아르바이트 근로계약서",
      constructor: "진혜연",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    },
    {
      id: 7,
      use: "Y",
      state: "완료",
      name: "표준근로계약서 2020",
      constructor: "김용선",
      creationDate: "2020/03/30 09:30:30",
      lastUpdate: "2020/03/30 09:30:30"
    }]
  }

  initFormSearch() {
    this.formSearch = this.fb.group({
      searchText: [''],
      serachType: ['000001']
    });

    this.formSearch.controls['searchText'].disable();

    this.formSearch.get('searchText').valueChanges.subscribe(selectValue => {
      console.log(selectValue);
      if (selectValue !== '') {
        if (this.formSearch.get('serachType').value == '000006') {
          let str = new Date(selectValue);
          let strDate = str.getFullYear() + "/" + ((str.getMonth() + 1) < 10 ? '0' + (str.getMonth() + 1) : (str.getMonth() + 1)) + "/" + (str.getDate() < 10 ? '0' + str.getDate() : str.getDate());
          selectValue = strDate;
        }
        this.dataGetTmpl.data.text = selectValue;
        this.templateManagementService.setText(selectValue);
      } else {
        this.dataGetTmpl.data.text = '';
      }
    });

    this.formSearch.get('serachType').valueChanges.subscribe(selectValue => {
      console.log(selectValue);
      this.dataGetTmpl.data.serachType = selectValue;
      this.templateManagementService.setSerachType(selectValue);

      if (selectValue !== '000001') {
        this.formSearch.controls['searchText'].enable();
      } else {
        if (selectValue == '000005') {
          this.formSearch.get('searchText').setValue('000001');
        } else {
          this.formSearch.get('searchText').setValue("");
        }
        this.formSearch.controls['searchText'].disable();
      }
    });
  }

  checkBoxSelect(number: number) {
    let arr = this.tList;

    let index = arr.findIndex(res => res.templId === number);

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push({ templId: number });
    }
    this.tList = arr;
  }

  checkSelectTeplate(templId: string): string {
    let classCss = "rowChecked";

    let data = this.tList.filter((rse) => {
      return rse.templId == templId;
    });

    if (!(data.length > 0)) {
      classCss = "";
    }

    return classCss;
  }

  showEditor(templ_id: string, temp_nm: string) {
    this.editorPdfService.setTemplateId(templ_id);
    this.editorPdfService.setTemplNm(temp_nm);
    this.editorPdfService.setContorType('%');
    this.editorPdfService.setEdteMode("edit");
    this.editorPdfService.setState("init");
    this.editorPdfService.download = false;
    this.router.navigate(['edit-template-contract']);
  }

  showEditorRow($event, templ_id: string, temp_nm: string) {
    if ($event.target.toString().includes("Table")) {
      this.editorPdfService.setTemplateId(templ_id);
      this.editorPdfService.setTemplNm(temp_nm);
      this.editorPdfService.setContorType('%');
      this.editorPdfService.setEdteMode("edit");
      this.editorPdfService.setState("init");
      this.editorPdfService.download = false;
    }
  }

  deleteAllSelect() {
    if (this.tList.length > 0) {
      let data = this.tp;
      this.tList.forEach(list => {
        let index = data.findIndex(res => res.templId === list.templId);
        data.splice(index, 1);
        this.tList = [];
        this.deleteTemplate(list.templId);
        return true;
      });
      this.tp = data;
    }else{
      this.alertSharedService.open(	
        {	
          title: "알림",	
          btnClose: true	
        },	
        {	
          title: "취소할 계약서를 선택해주세요.",	
          subTitle: ""	
        },	
        [	
          {	
            btnType: 'close',	
            btnNm: '확인',	
            btnStyle: 'btn-black',	
            btnFunc: () => {	
            }	
          }	
        ]	
      );
    }
  }

  availableAllSelect() {
    if (this.tList.length > 0) {
      let data = this.tp;
      this.tList.forEach(list => {
        let index = data.findIndex(res => res.templId === list.templId);
        data[index].templYn = "Y";
        this.editTemp(data[index]);
      });
      this.tp = data
    }
  }

  notAvailableAllSelect() {
    if (this.tList.length > 0) {
      let data = this.tp;
      this.tList.forEach(list => {
        let index = data.findIndex(res => res.templId === list.templId);
        data[index].templYn = "N";
        this.editTemp(data[index]);
      });
      this.tp = data;
    }
  }

  onToCreateTemplate(): void {
    this.router.navigate(['/creating-electronic']);
  }

  onClickBtnSoon() {
    this.alertConfirmService.open(
      "SOON",
      '',
      {
        func: () => {
          this.alertConfirmService.close();
        }
      },
      {
        func: () => {
          this.alertConfirmService.close();
        }
      }
    );
  }


  onClickReply(templId: string, templNm: string) {
    this.alertConfirmService.open(
      "대량 발송을 원하시는 경우<br>다음 단계에서 엑셀 양식을 사용해주세요",
      '',
      {
        func: () => {
          this.alertConfirmService.close();
        }
      },
      {
        func: () => {
          this.editorPdfService.setTemplateId(templId);
          this.editorPdfService.setTemplNm(templNm);
          this.editorPdfService.setContorType('%');
          this.editorPdfService.setEdteMode("temp_usr");
          this.editorPdfService.setState("init");
          this.editorPdfService.download = false;
          this.router.navigate(['edit-template-contract']);
          this.alertConfirmService.close();
        }
      }
    );
  }

  onClickSendLink() {
    this.sendLinkService.open(
      {
        func: () => {
          this.sendLinkService.close();
        }
      },
      {
        func: () => {
          this.sendLinkService.close();
        }
      }
    );
  }

  onFileSelected() {
    const $img: any = document.querySelector('#files');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
      };
      this.uploadfile = $img.files[0];
      reader.readAsArrayBuffer($img.files[0]);
      this.appyDigitalSignarue();
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
    this.appyDigitalSignarue();
  }



  test() {
    this.LoadingService.openLoading();
  }


  appyDigitalSignarue() {
    alert('test');
    const formData = new FormData();
    formData.append('files', this.uploadfile);
    formData.append('userId', "U000000000001");

    this.digitalsignatureService.setSignature(formData).subscribe((rs: any) => {
    });

  }
}
