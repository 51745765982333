import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from '../modal/alert-confirm/alert-confirm.service';
import { ElectronicContractDetailsService } from '../modal/electronic-contract-details/electronic-contract-details.service';
import { ContractCancellationService } from '../modal/contract-cancellation/contract-cancellation.service';
import { ContractService } from '../../service/contract/contract.service';
import { TableRequestedService } from './table-requested.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { faSearch, faTimes, faTv, faRedo, faExclamationCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

import { NavbarService } from '../navbar/navbar.service';
import { Router } from '@angular/router';
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { LoadingService } from '../../shared/loading/loading.service';
import dateShared from '../../shared/date-shared/date-shared';

import { LoginService } from '../../../frontpage/login/login.service';
@Component({
  selector: 'app-table-requested',
  templateUrl: './table-requested.component.html',
  styleUrls: ['./table-requested.component.scss']
})
export class TableRequestedComponent implements OnInit {

  dateFormat: dateShared = new dateShared();
  faTv = faTv;
  faRedo = faRedo;
  faExclamationCircle = faExclamationCircle;
  faDownload = faDownload;
  getUser: boolean = true;

  tpOne: {
    code: string,
    contId: string,
    contorEmail: string,
    contorId: string,
    contorMobile: string,
    contorNm: string,
    contorSerialno: string,
    contorType: string,
    frstRegisterPnttm: string,
    lastUpdusrPnttm: string
    progressStatus: string,
    signId: string,
    templId: string,
    templNm: string,
    userReq: string
  }[] = [];
  tList: any = [];
  paramsApi = {
    data: {
      frstRegisNo: "",
      text: "",
      serachType: ""
    },
    pageNo: 0,
    pageSize: 10
  }

  pagination = {
    maxPage: 1,
    currentPage: 1
  }

  constructor(
    private router: Router,
    private alertConfirmService: AlertConfirmService,
    private electronicContractDetailsService: ElectronicContractDetailsService,
    private contractCancellationService: ContractCancellationService,
    private contractService: ContractService,
    private tableRequestedService: TableRequestedService,
    private sidebarService: SidebarService,
    private loginService: LoginService,
    private editorPdfService: EditorPdfService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
  ) { }

  ngOnInit(): void {
    this.navbarService.setAction('contorSend');
    this.sidebarService.setSubManuActive('requested');
  }

  ngDoCheck(): void {
    if (this.tableRequestedService.getState() && (this.loginService.getProfileLogin() != null)) {
      this.initParamsApi();
      this.getContractAll();
      this.tableRequestedService.setState(false);
    }
    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.getUser = false;
      this.initParamsApi();
      this.getContractAll();
    }
  }

  initParamsApi(): void {
    this.paramsApi = this.tableRequestedService.getData();
    this.pagination.currentPage = this.tableRequestedService.getPageNo() + 1;
    this.paramsApi.data.frstRegisNo = this.loginService.getProfileLogin().userIdNo;
  }

  getContractAll() {
    this.loadingService.openLoading();
    this.contractService.getAllContractsRequest(this.paramsApi).subscribe((rs: any) => {
      if (rs.status) {
        this.loadingService.closeLoading();
        this.tpOne = rs.data;
        this.calMaxPage(rs.total, this.paramsApi.pageSize);
      }
    });
  }

  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.paramsApi.pageNo = this.pagination.currentPage - 1;
      }
      this.loadingService.openLoading();
      this.contractService.getAllContractsRequest(this.paramsApi).subscribe((rs: any) => {
        if (rs.status) {
          this.tpOne = rs.data;
          this.loadingService.closeLoading();
        }
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.paramsApi.pageNo = $event - 1;
    this.tableRequestedService.setPageNo($event - 1);
    this.getContractAll();
  }

  onClickRedo(contId: string, contorId: string) {
    this.alertConfirmService.open(
      '서명요청은 1회 가능합니다.<br>재전송을 하시겠습니까?',
      '두 명 이상의 계약자(수신자)에게 보내진 계약서의 경우, 선택된 계약자(수신자)에게만 서명요청 메세지가<br>전달됩니다.',
      {
        func: () => {
          this.alertConfirmService.close();
        }
      },
      {
        func: () => {
          this.alertConfirmService.close();
          this.resendContract(contId, contorId);
        }
      }
    );
  }

  resendContract(contId: string, contorId: string) {
    this.loadingService.openLoading();
    this.contractService.resendContract(contId, contorId).subscribe((rs: any) => {
      if (rs.status) {

      }
      this.loadingService.closeLoading();
    });
  }

  onClickExclamation(conId: string, contId: string) {
    this.getContractDetails(conId, contId);
  }


  getContractDetails(conId: string, contId: string) {
    let params = {
      contId: conId,
      contorId: contId
    }
    this.contractService.getContractDetails(params).subscribe((rs: any) => {
      if (rs.status) {
        this.electronicContractDetailsService.setConrtractDetails(rs.data.contDetails);
        this.electronicContractDetailsService.setContractHistory(rs.data.contHistory);
        this.electronicContractDetailsService.open();
      }
    });
  }

  onClickContractCancellation() {
    if (this.tList.length > 0) {
      this.contractCancellationService.open(
        {
          func: () => {
            this.contractCancellationService.close();
          }
        }, {
        func: () => {
          this.updateCancleContract(this.contractCancellationService.getContractCancle(), this.contractCancellationService.getMessage());
          this.contractCancellationService.close();
        }
      }
      );
    }
  }

  updateCancleContract(contractCancle: string, message: string) {
    let data = {
      contract: this.tList,
      contractCancle: contractCancle,
      message: message
    }
    this.loadingService.openLoading();
    this.contractService.updateCancleContract(data).subscribe((rs: any) => {
      this.tList = [];
      this.getContractAll();
      this.loadingService.closeLoading();
    });
  }

  checkBoxSelect(contorId: string, contId: string) {
    let arr = this.tList;

    let index = arr.findIndex(res => (res.contorId === contorId && res.contId === contId));

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push({ contorId: contorId, contId: contId });
    }
    this.tList = arr;
    console.log(this.tList);
  }

  checkSelectTeplate(contorId: string, contId: string): string {
    let classCss = "rowChecked";

    let data = this.tList.filter((res) => {
      return (res.contorId === contorId && res.contId === contId);
    });

    if (!(data.length > 0)) {
      classCss = "";
    }

    return classCss;
  }

  onClickReviewContract(tempId: string, contNm: string, contId: string, contorId: string) {
    this.editorPdfService.setContor({ contorId: contorId, contId: contId });
    this.editorPdfService.setTemplateId(tempId);
    this.editorPdfService.setTemplNm(contNm);
    this.editorPdfService.setContorType('%');
    this.editorPdfService.setEdteMode("peview");
    this.editorPdfService.setState("init");
    this.editorPdfService.download = false;
    this.router.navigate(['review-template-contract']);
  }

  onClickReviewDownloadContract(tempId: string, contNm: string, contId: string, contorId: string){
    this.editorPdfService.setContor({ contorId: contorId, contId: contId });
    this.editorPdfService.setTemplateId(tempId);
    this.editorPdfService.setTemplNm(contNm);
    this.editorPdfService.setContorType('%');
    this.editorPdfService.setEdteMode("peview");
    this.editorPdfService.setState("init");
    this.editorPdfService.download = true;
    this.router.navigate(['dowliad-contract']);
  }

  onClickReviewContractRow($event, tempId: string, contNm: string, contId: string, contorId: string) {
    if ($event.target.toString().includes("Table")) {
      this.editorPdfService.setContor({ contorId: contorId, contId: contId });
      this.editorPdfService.setTemplateId(tempId);
      this.editorPdfService.setTemplNm(contNm);
      this.editorPdfService.setContorType('%');
      this.editorPdfService.setEdteMode("peview");
      this.editorPdfService.setState("init");
      this.editorPdfService.download = false;
    }
  }
}
