import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { NavbarService } from '../navbar/navbar.service';
import { OrganizationService } from '../../service/organization/organization.service';

@Component({
  selector: 'app-organization-management',
  templateUrl: './organization-management.component.html',
  styleUrls: ['./organization-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationManagementComponent implements OnInit {

  constructor(
    private sidebarService: SidebarService,
    private navbarService: NavbarService
  ) {
    this.sidebarService.setManuActive(this.sidebar);
    this.sidebarService.setSubManuActive('organization-management');
  }

  selectData: {
    type: string,
    orgId: string,
    divId: string,
    nM: string,
    userId: string,
    status: boolean
  } = {
      type: "",
      orgId: "",
      divId: "",
      nM: "",
      userId: "",
      status: true
    }

  sidebar: any = 'set';

  sendApiComplete = {
    status: true
  }

  ngOnInit(): void {
    this.navbarService.setAction('orgManage');
  }

  ngAfterViewInit(): void {
    this.sidebarService.setManuActive(this.sidebar);
  }

  selectDepartment($event) {
    this.selectData.type = $event.type;
    this.selectData.orgId = $event.orgId;
    this.selectData.divId = $event.divId;
    this.selectData.nM = $event.nM;
    this.selectData.status = true;
  }

  selectSearch($event) {
    this.selectData.userId = $event.userId;
    this.selectData.type = $event.type;
    this.selectData.orgId = $event.orgId;
    this.selectData.divId = $event.divId;
    this.selectData.nM = $event.nM;
    this.selectData.status = true;
  }

  selectTeam($event) {
    this.selectData.type = $event.type;
    this.selectData.orgId = $event.orgId;
    this.selectData.divId = $event.divId;
    this.selectData.nM = $event.nM;
    this.selectData.status = true;
  }

  statusSendApiCompleted($event) {
    this.sendApiComplete.status = $event.status;
  }
}