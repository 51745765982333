<section class="container2">  
  <section _gcont_select_recipient class="container-fluid">
    <div _gcont_select_recipient class="row">
      <div _gcont_select_recipient class="col-12">
        <div _gcont_select_recipient class="form-group">
          <form _gcont_select_recipient [formGroup]=formNameTemplate id="formNameTemplate">
            <label _gcont_select_recipient for="nameTemplateInput" class="tit-inp-001">계약서 명</label>
            <input _gcont_select_recipient type="text" class="form-control inp-tmp-nam-001" formControlName='nameTemplate'
              placeholder="표준근로계약서" id="nameTemplateInput" aria-describedby="nameTemplate">
            <small _gcont_select_recipient id="nameTemplate" class="form-text text-muted"></small>
          </form>
        </div>
      </div>
    </div>
    <div _gcont_select_recipient class="body mt-4">
      <div _gcont_select_recipient class="row my-3">

        <div _gcont_select_recipient class="col-12">
          <div _gcont_select_recipient class="row">
            <div _gcont_select_recipient class="col-12 d-flex flex-column flex-lg-row">
              <div _gcont_select_recipient class="bor-tre-tre-001">
                <label _gcont_select_recipient>수신자</label>
                <div _gcont_select_recipient class="w-100">
                  <button _gcont_select_recipient class="btn btn-small-long {{classCss.tree}}" style="width: auto;"
                    (click)="btnShowViewTree()">
                    선택된 팀원 보기
                  </button>
                  <button _gcont_select_recipient class="btn btn-small-long {{classCss.list}}" style="width: auto;"
                    (click)="btnShowViewList()">
                    조직원 전체 선택
                  </button>
                </div>
                <div _gcont_select_recipient class="siz-bod-tre d-flex flex-wrap">
                  <div _gcont_select_recipient class="w-100 d-flex flex-wrap py-2 hea-tre-001">
                    <div _gcont_select_recipient class="col-3 text-right">
                      <label _gcont_select_recipient class="mb-0 oti-sel-001">이름</label>
                    </div>
                    <div _gcont_select_recipient class="col-9">
                      <form _gcont_select_recipient [formGroup]=formSearch>
                        <div _gcont_select_recipient class="input-group">
                          <input _gcont_select_recipient type="text" formControlName='searchText'
                            class="form-control inp-scr-001" (keyup.enter)="onSearch()" [placeholder]=""
                            aria-describedby="button-addon2">
                          <div _gcont_select_recipient class="input-group-append">
                            <button _gcont_select_recipient class="btn btn-outline-secondary border-0 py-0 pr-0"
                              (click)="onSearch()" style="color: #000000;" type="button">
                              <div class="img-ico-sea-002 ico-cea-001 "></div>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div _gcont_select_recipient class="col-12" style="height: 285px;overflow: auto;width: 290px;">
                    <div _gcont_select_recipient class="w-100" id="tree">
                      <div _gcont_select_recipient class="clt {{treeView.tree}}">
                        <div _gcont_select_recipient class="ico-img-tre-div-001 ico-tre-fod-001"><span
                            class="nam-com-001">{{organ.orgNm}}</span>
                        </div>
                        <ul _gcont_select_recipient class="cltp active divi-pra-001" id="htmlTree">
                        </ul>
                      </div>
                    </div>

                    <div _gcont_select_recipient class="w-100" id="list">
                      <div _gcont_select_recipient class="row nested">
                        <ul _gcont_select_recipient class="lis-hea-001" id="htmlList">
                        </ul>
                      </div>

                    </div>

                    <div _gcont_select_recipient class="w-100 {{treeView.list}}" id="listSearch">
                      <div _gcont_select_recipient class="row">
                        <ul _gcont_select_recipient class="lis-hea-001" id="htmlList">

                          <ng-container *ngIf="(this.treeView.list !== 'nested')">
                            <ng-container *ngFor="let item_one of groupSearch; let i = index;">
                              <li _gcont_select_recipient class="cus-che-001"
                                *ngIf="checkDivisionSelect(item_one[0].divId)">
                                <div _gcont_select_recipient class="lis-sub-hea-001 px-3 tit" id="d-{{item_one[0].divId}}"
                                  (click)="openList($event,'d-' + item_one[0].divId)">
                                  <label *ngIf="checkHaveUserSelect(item_one[0].divId)" _gcont_select_recipient
                                    class="con-che-001">
                                    <input _gcont_select_recipient class="che-mar-001" id="td-{{  item_one[0].divId }}"
                                      type="checkbox" [checked]="checkedDivisionSelect(item_one[0].divId)"
                                      (change)="checkBoxSelectDivisionList(item_one[0].divId,item_one[0].divNm)">
                                    <span _gcont_select_recipient style="top: -3px;" class="che-mar-001"></span>
                                  </label>
                                  <span _gcont_select_recipient class="ml-2">{{item_one[0].divNm}}</span>
                                </div>

                                <ul _gcont_select_recipient class="lis-hea-001 nested active">

                                  <ng-container *ngFor="let item_two of item_one; let j = index">

                                    <li _gcont_select_recipient class="cus-che-001"
                                      *ngIf="checkUserSelect(item_two.divId,item_two.userId)" id="u-{{item_two.staffId}}">
                                      <div _gcont_select_recipient class="lis-sub-hea-003">
                                        <label _gcont_select_recipient class="con-che-001">
                                          <input _gcont_select_recipient type="checkbox"
                                            id="ts-{{ item_two.userId + '-' + item_two.divId }}"
                                            [checked]='checDivisionSelectTree(item_two.divId,item_two.userId)'
                                            (change)="checkBoxtreeOld(item_two.userId,item_two.divId)">
                                          <span _gcont_select_recipient style="top: -3px;" class="che-mar-001"></span>
                                        </label>
                                        <span _gcont_select_recipient class="px-2 tit">{{item_two.userNm}}</span>

                                      </div>
                                    </li>
                                  </ng-container>

                                </ul>

                              </li>

                            </ng-container>

                          </ng-container>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div _gcont_select_recipient
                class="bod-arr-tre-001 d-flex flex-row flex-lg-column justify-content-center align-items-center">
                <div _gcont_select_recipient
                  class="bod-ico-push-001 d-flex flex-row flex-lg-column justify-content-center align-items-center">
                  <div _gcont_select_recipient class="img-ico-arr-tre-001 ico-tre-arr-rig-001"
                    (click)="onClickBtnPushSelect()"></div>
                  <div _gcont_select_recipient class="img-ico-arr-tre-001 ico-tre-ref-001" (click)="onClickClearData();">
                  </div>
                </div>
              </div>

              <div _gcont_select_recipient class="bod-inp-tre-001">
                <div _gcont_select_recipient class="w-100 d-flex justify-content-end">
                  <form [formGroup]=formSequence class="form-inline">
                    <ul class="radio-wrap">
                      <li>
                          <input id="radio1" class="radio" type="radio" formControlName="sequenceYn" name="sequenceYn" value="Y">
                          <label for="radio1" class="radio-label">
                            순차전송
                          </label>
                      </li>
                      <li>
                        <input id="radio2" class="radio" type="radio" formControlName="sequenceYn" name="sequenceYn" value="N">
                        <label for="radio2" class="radio-label">
                          동시전송
                        </label>
                      </li>
                    </ul>

                    
                  </form>
                  <button _gcont_select_recipient class="btn btn-small-long btn-black"
                    [disabled]="(editorPdfService.getColorSign().length > 1)" (click)="openModal()">엑셀 대량 전송</button>
                </div>
                <div _gcont_select_recipient class="w-100 d-flex justify-content-start">
                  <button class="btn btn-small-long btn-white" *ngIf="!(editorPdfService.getColorSign().length > 1)"
                    (click)="pushContact()">
                    직접 입력
                  </button>
                  <label _gcont_select_recipient *ngIf="(editorPdfService.getColorSign().length > 1)"
                    style="font-size: 15px;margin: 4px 0 4px 0;">직접입력 하실 분들은 아래의 내용에 직접 입력해 주시기 바랍니다.</label>
                </div>
                <div _gcont_select_recipient class="w-100" style="background: #ffffff;padding:0px 15px 0px 15px">
                  <div _gcont_select_recipient class="row">
                    <div _gcont_select_recipient class="col-md-12 hea-tre-001">
                      <label _gcont_select_recipient class="p-2 tex-tit-001">
                        {{genStringTotal()}}
                      </label>
                    </div>
                    <div _gcont_select_recipient class="col-md-12"
                      style="height: 289px;overflow: auto;border: 1px solid #d5d5d5;border-top:none;">
                      <form _gcont_select_recipient [formGroup]=fromEmployees>
                        <table _gcont_select_recipient style="width: 100%;">
                          <thead _gcont_select_recipient>

                          </thead>
                          <tbody>

                            <ng-container formArrayName="employees" *ngFor="let data of arrayFromDatas;let i = index">
                              <tr _gcont_select_recipient [ngStyle]="{'border-bottom' : '1px solid ' + data.color}"
                                class="table-row-group" [formGroupName]="i">
                                <td _gcont_select_recipient style="width: 5%;">
                                  <label _gcont_select_recipient class="con-che-001">
                                    <input _gcont_select_recipient type="checkbox" (change)="checkBoxRight(i)"
                                      id="rig-{{i}}">
                                    <span _gcont_select_recipient class="che-mar-001" style="top: -3px;"></span>
                                  </label>
                                </td>
                                <td _gcont_select_recipient style="width:25%;" class="text-left">
                                  <input _gcont_select_recipient type="search" class="my-1 form-control"
                                    style="font-size: 11px;" formControlName="contractNm" name="name[{{i}}]"
                                    placeholder="이름" aria-describedby="">
                                </td>
                                <td _gcont_select_recipient style="width: 25%;" class="text-left">
                                  <input _gcont_select_recipient type="search" class="my-1 form-control"
                                    style="font-size: 11px;" formControlName="contractEmail" name="name[{{i}}]"
                                    placeholder="이메일" aria-describedby="">
                                </td>
                                <td _gcont_select_recipient style="width: 25%;" class="text-left">
                                  <input _gcont_select_recipient type="search" class="my-1 form-control"
                                    style="font-size: 11px;" maxlength="11" formControlName="contractMobile"
                                    name="name[{{i}}]" placeholder="휴대폰번호 (숫자만 입력)" aria-describedby="">
                                </td>
                                <td _gcont_select_recipient style="width: 15%">
                                  <input type="password" id="pi{{i}}" placeholder="비밀번호 입력" formControlName="password"
                                    style="font-size: 11px;" class="my-1 form-control">
                                </td>
                                <td _gcont_select_recipient style="width: 5%;" class="text-center">
                                  <span *ngIf="!((editorPdfService.getColorSign().length > 1)|| (i == 0))" class=""> <img src="../../../../assets/img/icon/icon-remove-cr.png"
                                      style="cursor: pointer" (click)="removeContactEmp(i)" class="img-fluid"></span>
                                </td>
                              </tr>
                            </ng-container>

                            <ng-container *ngFor="let data of arrayDatas; let i = index">
                              <tr _gcont_select_recipient style="border-bottom: 1px solid #dddddd">
                                <td _gcont_select_recipient style="width: 5%;">
                                  <label _gcont_select_recipient class="con-che-001">
                                    <input _gcont_select_recipient type="checkbox">
                                    <span _gcont_select_recipient class="che-mar-001"
                                      (click)="checkSelectContract(data.divId,data.userId)" style="top: -3px;"></span>
                                  </label>
                                </td>
                                <td _gcont_select_recipient style="width:25%;" class="text-left">
                                  <label class="tex-itm-tab-001 my-0">{{data.contractNm}}</label>
                                </td>
                                <td _gcont_select_recipient style="width: 25%;" class="text-left">
                                  <label class="tex-itm-tab-001 my-0">{{data.contractEmail}}</label>
                                </td>
                                <td _gcont_select_recipient style="width: 25%;" class="text-left">
                                  <label class="tex-itm-tab-001 my-0">{{data.contractMobile}}</label>
                                </td>
                                <td _gcont_select_recipient style="width: 15%">
                                  <ng-container *ngIf="btnEnablePassword">
                                    <input _gcont_select_recipient type="password" id="p{{i}}" style="font-size: 11px;"
                                      class="form-control" disabled>
                                  </ng-container>
                                  <ng-container *ngIf="!btnEnablePassword">
                                    <input _gcont_select_recipient type="password" placeholder="비밀번호 입력" id="p{{i}}"
                                      style="font-size: 11px;" class="form-control">
                                  </ng-container>
                                </td>
                                <td _gcont_select_recipient style="width: 5%;" class="text-center">
                                  <img _gcont_select_recipient src="../../../../assets/img/icon/icon-remove-cr.png"
                                    (click)="removeContact(i)" style="cursor: pointer" class="img-fluid">
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div _gcont_select_recipient class="row">
      <div _gcont_select_recipient class="col-12">
        <div _gcont_select_recipient class="row my-1">
          <div _gcont_select_recipient class="col-12">
            <label _gcont_select_recipient>설정</label>
          </div>
        </div>
        <div _gcont_select_recipient class="row flex-column my-1 clickArea m-0">

          <form _gcont_select_recipient [formGroup]=formInputPassword id="formInputPassword" class="form-inline my-2">
            <button _gcont_select_recipient type="button" class="btn btn-middle-short {{btnPassword}} authbtns"
            style="width: 140px;" (click)="configPassword()">비밀번호
              인증</button>
            <input _gcont_select_recipient type="password" class="form-control recipientPassword" name="contorPass"
              id="contorPass" formControlName="contorPass" placeholder="비밀번호 입력 (4~12 문자 및 숫자)" id="contorPass">
          </form>

          <div _gcont_select_recipient class="d-flex align-items-center my-2">
            <button _gcont_select_recipient type="button" class="btn btn-middle-short {{btnOtpPhone}} authbtns"
              style="width: 140px;" (click)="configPhoneOtp()">휴대폰 본인 인증</button>
            <label _gcont_select_recipient style="font-size: 15px;" class="mb-0">휴대전화가 입력된 수신자에게만 적용됩니다.</label>
          </div>

        </div>
      </div>
    </div>

    <div _gcont_select_recipient class="d-flex justify-content-center">
      <button _gcont_select_recipient class="btn btn-big btn-gray" style="width: auto !important;" type="button" name=""
        id="" (click)="goBackEditor()">이전</button>
      <button _gcont_select_recipient class="btn btn-big btn-crimson ml-3"
        [disabled]="!(((this.fromEmployees.get('employees').valid) && (this.arrayFromDatas.length != 0)) || ((this.select.treeOld.division.length != 0 || this.select.treeOld.user.length != 0)))"
        style="width: auto !important;" type="button" (click)="checkDataForm()" name="" id="">전송</button>
    </div>

  </section>
</section>
<app-alert></app-alert>
<app-alert-shared></app-alert-shared>
<app-loading></app-loading>