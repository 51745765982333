<section style="background: #e9ecef" class="container-fluid p-0">

  <section class="container" style="min-height: 100vh;max-height: calc(100vh + 50px);">



    <div class="row d-flex justify-content-center align-items-center" style="min-height: 100vh;max-height: calc(100vh + 50px);">

      <div class="col-lg-5 py-3 d-flex justify-content-center align-items-center">
        <form [formGroup]=formForgotPassword class=" text-center" style="width: 100%; max-width: 490px; ">


          <div class="regiFields" style="margin-top:0px">
            <div class="forgotTxthead text-center ">
              <h3><b>비밀번호 찾기</b></h3>
              <h5 class="pt-5">이메일을 입력 후 <span>"이메일 전송"</span> 버튼을 클릭해주세요. <br>임시 비밀번호가 전송됩니다. </h5>

            </div>

            <div class="form-group row m-0 confirmFormInput">
              <div class="col-sm-12">
                <input type="text" class="form-control" formControlName='email' id="staticEmail"
                  placeholder="이메일 번호를 입력해 주세요.">
                <small class="form-text text-muted mt-1 text-validators {{validators.email}}">이메일 규칙을 확인해주세요.</small>
              </div>
            </div>

            <div class="form-group row d-flex justify-content-center mb-0">
              <button type="button" (click)=" sendForgotPassword()" [disabled]='disabledBtn'
                class="btn btn-crimson w-25">이메일
                전송</button>
            </div>
          </div>

        </form>


      </div>
    </div>



  </section>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Term & Condition</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <app-alert></app-alert>

</section>



<app-alert></app-alert>
<app-loading></app-loading>