import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { DataPassService } from '../../service/data-pass/data-pass.service';
import { NavbarService } from '../navbar/navbar.service';

@Component({
  selector: 'app-creating-electronic',
  templateUrl: './creating-electronic.component.html',
  styleUrls: ['./creating-electronic.component.scss']
})
export class CreatingElectronicComponent implements OnInit {
  sidebar: any = 'create_an_electronic';
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  constructor(
    private navbarService: NavbarService,
    private dataPassService: DataPassService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navbarService.setAction("creatingTemp");
  }


  howToLink(linkPage) {
    this.dataPassService.setHowToPage(linkPage);
    console.log('lffasdf', linkPage);
    this.router.navigate(['/how-to-use']);
  }

}
