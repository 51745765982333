import { Component, OnInit } from '@angular/core';
import { ModalImportUserTeamsService } from './modal-import-user-teams.service';
import * as XLSX from 'xlsx';
import GenerateExcel from '../../../shared/GenerateExcel/generate-excel';
import { OrganizationService } from '../../../service/organization/organization.service';
import { LoadingService } from '../../../shared/loading/loading.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-import-user-teams',
  templateUrl: './modal-import-user-teams.component.html',
  styleUrls: ['./modal-import-user-teams.component.scss']
})
export class ModalImportUserTeamsComponent implements OnInit {
  show: boolean = false;
  state: string = 'selectImport';

  totle_item: number = 0;
  pagination = {
    maxPage: 1,
    currentPage: 1
  }
  totle_itemSerach: number = 0;
  paginationSerach = {
    maxPage: 1,
    currentPage: 1
  }

  file: File;
  arrayBuffer: any;

  dataTables: any[][] = [];

  serachTables: any[][] = [];

  userTeams: {
    no: string,
    userNm: string,
    rank: string,
    divNm: string[],
    mail: string,
    phone: string
  }[] = [];
  arraylist: any[] = [];

  formSearch: FormGroup;

  constructor(
    private modalImportUserTeamsService: ModalImportUserTeamsService,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private loadingService: LoadingService,
  ) { }


  ngOnInit(): void {
    this.initFormSearch();
  }

  addfile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i != data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      // tslint:disable-next-line: variable-name
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.genDataTable(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
    };
  }

  genDataTable(data: {
    no: string,
    name: string,
    rank: string,
    division: string,
    mail: string,
    phone: string
  }[]) {
    this.userTeams = [];
    this.dataTables = [];
    this.arraylist = data;
    var c = 1;
    var r = 0;
    for (let i = 0; i < data.length; i++) {
      if (c == 1) {
        this.dataTables.push([]);
      }

      let divisionList = data[i].division.toString().split(" > ");
      if (data[i].division.length == 0) {
        let divisionList = data[i].division.toString().split(" > ");
      }

      this.userTeams.push({
        no: data[i].no,
        userNm: data[i].name,
        rank: data[i].rank,
        divNm: divisionList,
        mail: data[i].mail,
        phone: data[i].phone
      });
      this.dataTables[r].push(data[i]);
      c++;
      if (c == 5) {
        c = 1;
        r++;
      }
    }
    this.totle_item = data.length;
    this.pagination.maxPage = this.dataTables.length;
    this.pagination.currentPage = 1;

    console.log(this.dataTables, this.arraylist);
    this.state = 'tableData';
  }

  genSerachDataTable(data: any[]) {
    this.serachTables = [];
    var c = 1;
    var r = 0;
    for (let i = 0; i < data.length; i++) {
      if (c == 1) {
        this.serachTables.push([]);
      }
      this.serachTables[r].push(data[i]);
      c++;
      if (c == 5) {
        c = 1;
        r++;
      }
    }
    this.totle_itemSerach = data.length;
    this.paginationSerach.maxPage = this.serachTables.length;
    this.paginationSerach.currentPage = 1;

    console.log(this.serachTables, this.arraylist);
    this.state = 'tableSerachData';
  }

  ngDoCheck(): void {
    if (this.modalImportUserTeamsService.change.show) {
      if (this.modalImportUserTeamsService.show) {
        this.dataTables = [];
        this.state = 'selectImport';
      }
      this.show = this.modalImportUserTeamsService.show;
      this.modalImportUserTeamsService.setChange('show', false);
    }
    if (this.modalImportUserTeamsService.change.state) {
      this.state = this.modalImportUserTeamsService.getState();
      this.modalImportUserTeamsService.setChange('state', false);
    }
  }

  closeModalBackground($event) {
    console.log($event.target.className);
    if ($event.target.className.includes("importModal")) {
      this.modalImportUserTeamsService.close();
    }
  }

  closeModal() {
    this.modalImportUserTeamsService.close();
  }

  onUploadUserExcal() {
    console.log(this.dataTables, this.userTeams);
    this.uploadDataUserTeams();
  }

  uploadDataUserTeams() {
    this.loadingService.openLoading();
    this.organizationService.createStaffTeamsExcel(this.userTeams).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.modalImportUserTeamsService.setState("complete");
        this.modalImportUserTeamsService.setChange('state', true);
        this.modalImportUserTeamsService.onFunctionClick();
      } else {
        if (rs.errorCode = '201') {
          console.log(rs.message);
        }
      }
    });
  }

  seclectPage($event) {
    this.pagination.currentPage = $event;
  }
  seclectSerachPage($event) {
    this.paginationSerach.currentPage = $event;
  }
  removeItem(i: number) {
    let usr = this.arraylist;

    let index = usr.findIndex(res => res.no === i);

    usr.splice(index, 1);
    this.arraylist = usr;
    if (this.state == 'tableData') {
      this.genDataTable(usr);
    } else {
      this.searchMembers();
    }
  }

  onDownloadExcel() {
    let headers = [
      'no',
      'name',
      'rank',
      'division',
      'mail',
      'phone'
    ];
    GenerateExcel.linkDownloadFileExcelImportMembers();
  }

  initFormSearch() {
    this.formSearch = this.fb.group({
      "searchText": ['']
    });
  }

  searchMembers() {
    if (this.formSearch.get("searchText").value != '') {
      let members = this.arraylist.filter((res) => {
        return res.name.includes(this.formSearch.get("searchText").value)
      });
      this.genSerachDataTable(members);
      console.log(members);
    } else {
      this.genDataTable(this.arraylist);
    }

    console.log(this.formSearch.get("searchText").value)
  }
}
