import {Component, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, BaseChartDirective, Label} from 'ng2-charts';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {SidebarService} from '../sidebar/sidebar.service';

import {ContractService} from '../../service/contract/contract.service';
import {ServiceCenterService} from '../../service/service-center/service-center.service';
import {DashboardService} from './dashboard.service';

import {LoadingService} from '../../shared/loading/loading.service';
import {Router} from '@angular/router';
import {UserService} from "../../service/user/user.service";
import {LoginService} from "../../../frontpage/login/login.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


    constructor(
        private sidebarService: SidebarService,
        private contractService: ContractService,
        private loadingService: LoadingService,
        private serviceCenterService: ServiceCenterService,
        private dashboardService: DashboardService,
        private router: Router,
        private userService: UserService,
        private loginService: LoginService
    ) {
        this.sidebarService.setManuActive(this.sidebar);
    }

    @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

    dataDashBoard: {
        countCont: string,
        countSentCont: string,
        countCoufiCont: string,
        countSignCont: string,
        countCancleCont: string,
        countRejectCont: string,
        countSendToMe: string,
        chart: any[]
    } = {
        countCont: '',
        countSentCont: '',
        countCoufiCont: '',
        countSignCont: '',
        countCancleCont: '',
        countRejectCont: '',
        countSendToMe: '',
        chart: []
    }
    params = {
        data: {
            noticText: "",
            noticType: ""
        },
        pageNo: 0,
        pageSize: 3
    }

    noticeLists: {
        noticId: string,
        noticNo: number,
        noticTitle: string,
        noticeContent: string,
        registerPnttm: string
    }[];
    sidebar: any = 'home';
    faPlus = faPlus;

    lineChartData: ChartDataSets[] = [
        // { data: [], label: '근로계약', fill: false },
        // { data: [], label: '2020년 연봉계약', fill: false },
        // { data: [], label: '계약', fill: false }
        {data: [], label: '전송', fill: false},
        {data: [], label: '진행중', fill: false},
        {data: [], label: '완료', fill: false}
    ];

    lineChartLabels: Label[] = [];

    lineChartOptions = {
        pointStyle: 'circle',
        responsive: true,
        legend: {
            position: 'bottom',
            align: "end",
            rtl: false,
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Polar Area Chart'
        },
        elements: {
            line: {
                tension: 0.000001
            }
        },
        plugins: {
            filler: {
                propagate: true
            },
            'samples-filler-analyser': {
                target: 'chart-analyser'
            }
        }
    };


    lineChartOptionsSM = {
        pointStyle: 'circle',
        aspectRatio: 1,
        responsive: true,
        legend: {
            position: 'bottom',
            align: "end",
            rtl: false,
            display: false

        },
        title: {
            display: false,
            text: 'Chart.js Polar Area Chart'
        },
        elements: {
            line: {
                tension: 0.000001
            }
        },
        plugins: {
            filler: {
                propagate: true
            },
            'samples-filler-analyser': {
                target: 'chart-analyser'
            }
        }
    };
    lineChartColors: Color[] = [
        {
            backgroundColor: 'rgba(24, 144, 255,1)',
            borderColor: 'rgba(24, 144, 255,1)',
            pointBackgroundColor: 'rgba(24, 144, 255,1)',
            pointBorderColor: '#1890ff',
            pointHoverBackgroundColor: '#1890ff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        {
            backgroundColor: 'rgba(242, 133, 5,1)',
            borderColor: 'rgba(242, 133, 5,1)',
            pointBackgroundColor: 'rgba(242, 133, 5,1)',
            pointBorderColor: '#f28505',
            pointHoverBackgroundColor: '#f28505',
            pointHoverBorderColor: 'rgba(242, 133, 5,1)'
        },
        {
            backgroundColor: 'rgba(51, 176, 122,1)',
            borderColor: 'rgba(51, 176, 122,1)',
            pointBackgroundColor: 'rgba(51, 176, 122,1)',
            pointBorderColor: '#33b07a',
            pointHoverBackgroundColor: '#33b07a',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        }
    ];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';

    userId: string;


    ngOnInit(): void {
        this.getContractDataDashboard();
        this.searchNoticeFront();
        // TODO: 심사이후 수정
        this.userId = this.loginService.getProfileLogin().userIdNo;
    }

    ngAfterViewInit(): void {
        this.sidebarService.setManuActive(this.sidebar);
    }

    getContractDataDashboard() {
        this.loadingService.openLoading();
        this.contractService.getContractDataDashboard().subscribe((rs: any) => {
            if (rs.status) {
                this.dataDashBoard = rs.data;
                this.loadingService.closeLoading();

                for (let i = 0; i < rs.data.chart.length; i++) {

                    this.lineChartData[0].data.push(rs.data.chart[i].countSent);
                    this.lineChartData[1].data.push(rs.data.chart[i].countInProgress);
                    this.lineChartData[2].data.push(rs.data.chart[i].countComplate);

                    this.lineChartLabels.push(rs.data.chart[i].month + " 월");
                }

                this.chart.update();
            }
        });
    }

    searchNoticeFront() {
        this.loadingService.openLoading();
        this.serviceCenterService.searchNoticeFront(this.params).subscribe((rs: any) => {
            if (rs.status) {
                this.noticeLists = rs.data;
                this.loadingService.closeLoading();
            }
        });
    }

    goToServiceCenter(noticId: string) {
        this.dashboardService.setNoticId(noticId);
        this.router.navigate(['service-center']);
    }
}
