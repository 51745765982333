import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnsService } from '../../manage/service/sns/sns.service';
import { UserService } from '../../manage/service/user/user.service';
import { CodeBookService } from '../../manage/service/code-book/code-book.service';
import Regex from '../../manage/shared/regex/regex';
import { AlertService } from '../../manage/component/modal/alert/alert.service';
import { VerificationRegisterService } from '../verification-register/verification-register.service';
import { SocialLoadApiService } from '../../manage/service/social-load-api/social-load-api.service';


import { LoadingService } from '../../manage/shared/loading/loading.service';
import { AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {
  isSysLogin = true;
  custommerTypes: any[] = [];
  formRegister: FormGroup;
  validators: any = {
    type: '',
    name: '',
    email: '',
    password: '',
    confirmpassword: ''
  }
  disabledBtn: boolean = true;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snsservice: SnsService,
    private userService: UserService,
    private codeBookService: CodeBookService,
    private alertService: AlertService,
    private verificationRegisterService: VerificationRegisterService,
    private socialLoadApiService: SocialLoadApiService,
    private loadingService: LoadingService,
  ) {
  }

  regisSocial: {
    loginSocial: string,
    socialId: string,
    token: string,
  }

  input: {
    password: any,
    confirmpassword: any,
    email: any
  };

  ngOnInit(): void {
    this.input = {
      password: document.getElementById('password'),
      confirmpassword: document.getElementById('confirmpassword') ? document.getElementById('confirmpassword') : '',
      email: document.getElementById('email') ? document.getElementById('email') : ''
    }
    this.getCodeBook();
    this.socialLoadApiService.loadNaverAPI();
    this.socialLoadApiService.loadGoogleAPI();
    this.socialLoadApiService.initKokaoApi();
    this.initBtnLoginsocial();
    this.initFormRegister();
  }

  ngAfterViewInit() {
  }

  ngDoCheck(): void {
    if (this.socialLoadApiService.getOnLoadSocail()) {
      let g_resSns = this.socialLoadApiService.getDataSocial();
      this.formRegister.get("email").setValue(g_resSns.snsData.email ? g_resSns.snsData.email : '');
      this.formRegister.get("name").setValue(g_resSns.snsData.name);
      this.regisSocial = g_resSns.regisSocial;
      this.input.email.setAttribute('disabled', 'true');
      this.isSysLogin = false;
      this.removeControlName('password');
      this.removeControlName('confirmpassword');
      this.socialLoadApiService.setOnloadSocial(false);
    }
  }

  getCodeBook(): void {
    this.codeBookService.getCodeBook("CUST_TYPE").subscribe((rs: any) => {
      if (rs.status) {
        this.custommerTypes = rs.data;
      }
    });
  }

  ngAfterContentInit(): void {
    this.socialLoadApiService.googleButtonRender();
    this.input = {
      password: document.getElementById('password'),
      confirmpassword: document.getElementById('confirmpassword') ? document.getElementById('confirmpassword') : '',
      email: document.getElementById('email') ? document.getElementById('email') : ''
    }

  }

  ngOnDestroy() {
    // location.reload();
  }

  checkValidatiorsFormRegister(type: any) {
    const _calss = {
      VALID: '',
      INVALID: 'invalid'
    }
    this.validators[type] = _calss[this.formRegister.get(type).status];
  }

  removeControlName(name) {
    this.formRegister.removeControl(name);
    delete this.validators[name];
  }

  checkDisabledBtn() {
    const valid = {
      VALID: false,
      INVALID: true
    }
    this.disabledBtn = valid[this.formRegister.status];
  }

  initFormRegister() {
    this.formRegister = this.fb.group({
      type: ['', Regex.Required],
      name: [null, Regex.userNm],
      email: [null, Regex.emailRequired],
      password: [null, Regex.passwordLogin],
      confirmpassword: [null, Regex.passwordLogin],
      allAgree: [false],
      termsPolocy: [null, Regex.Required],
      news: [false]
    });
    this.formRegister.get('type').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormRegister('type');
    });
    this.formRegister.get('name').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormRegister('name');
      console.log(this.formRegister.get('name'), this.formRegister.get('name').status);
    });
    this.formRegister.get('email').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormRegister('email');
    });

    if (this.formRegister.get('password')) {
      this.formRegister.get('password').valueChanges.subscribe(selectValue => {
        this.checkValidatiorsFormRegister('password');
      });
    }
 
    if (this.formRegister.get('confirmpassword')) {
      this.formRegister.get('confirmpassword').valueChanges.subscribe(selectValue => {
        this.checkValidatiorsFormRegister('confirmpassword');
      });
    }

    this.formRegister.get('allAgree').valueChanges.subscribe(selectValue => {
      this.formRegister.get("termsPolocy").setValue(selectValue);
      this.formRegister.get("news").setValue(selectValue);
    });

    this.formRegister.get('termsPolocy').valueChanges.subscribe(selectValue => {
      if (!selectValue && selectValue != null) {
        this.formRegister.get("termsPolocy").setValue(null);
      }
    });

    this.formRegister.valueChanges.subscribe(value => {
      this.checkDisabledBtn();
    });
  }

  clickFuction() {
    let regisType = 'system';
    if (this.regisSocial != undefined) {
      regisType = 'social';
    } else {
      this.regisSocial = {
        loginSocial: "",
        socialId: "",
        token: ""
      }
    }
    let data = {
      costeomerType: this.formRegister.get('type').value,
      fullname: this.formRegister.get('name').value,
      email: this.formRegister.get('email').value,
      password: this.formRegister.get('password') ? this.formRegister.get('password').value : '',
      confPassword: this.formRegister.get('confirmpassword') ? this.formRegister.get('confirmpassword').value : '',
      termsPolocy: this.formRegister.get('termsPolocy').value,
      news: this.formRegister.get('news').value,
      regisType: regisType,
      loginSocial: this.regisSocial.loginSocial,
      token: this.regisSocial.token,
      socialId: this.regisSocial.socialId
    }
    this.loadingService.openLoading();
    this.userService.register(data).subscribe((rs: any) => {
      if (rs.status) {
        this.loadingService.closeLoading();
        this.verificationRegisterService.setMail(data.email);
        this.verificationRegisterService.setName(this.formRegister.get('name').value);
        this.router.navigate(['verification-register']);
      } else {
        this.loadingService.closeLoading();
        this.regisSocial = undefined;
        this.alertService.open(
          "등록",
          rs.message,
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }
    });

  }

  btnClick($event): void {
    const sns_type = $event.target.getAttribute('data-social');
    if (sns_type == "kakao") {
      this.socialLoadApiService.getKaKaoUserProfile();
    }
    else if (sns_type == "naver") {
      this.socialLoadApiService.getNaverUserPofile();
    }
    else if (sns_type == 'google') {
      this.socialLoadApiService.getGoogleUserPofile();
    }
  }

  // SNS Login
  initBtnLoginsocial() {
  }


}