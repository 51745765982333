import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecipientService } from '../recipient/recipient.service';
import { ContractService } from '../../../service/contract/contract.service';
import { EditorPdfService } from "../../editor-pdf/editor-pdf.service";

@Component({
  selector: 'app-recipient-confirmation-consent-write',
  templateUrl: './recipient-confirmation-consent-write.component.html',
  styleUrls: ['./recipient-confirmation-consent-write.component.scss']
})
export class RecipientConfirmationConsentWriteComponent implements OnInit {

  constructor(
    private router: Router,
    private recipientService: RecipientService,
    private contractService: ContractService,
    private editorPdfService: EditorPdfService,
  ) { }

  ngOnInit(): void {
  }

  onPrevious(state: any) {
    if (this.recipientService.getVerifi().pwd === "Y")
      this.recipientService.setState('password');
    else {
      this.recipientService.setState('type');
    }
  }

  onClickBtnOk() {
    let data = {
      shortUrl: this.recipientService.getShortUrl()
    }
    this.contractService.getRecipientTemplate(data).subscribe((rs: any) => {
      if (rs.status) {
        this.editorPdfService.setContor({ contorId: rs.data.contorId, contId: rs.data.contId });
        this.editorPdfService.setTemplateId(rs.data.tempId);
        this.editorPdfService.setTemplNm(rs.data.contNm);
        this.editorPdfService.setContorType('%');
        this.editorPdfService.setMarkColor(rs.data.contorType);
        this.editorPdfService.setEdteMode("contract");
        this.editorPdfService.setState("init");
        this.editorPdfService.download = false;
        this.router.navigate(['recipient-contract/e-contract']);
      }
    });
  }
}
