import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-recipient',
  templateUrl: './layout-recipient.component.html',
  styleUrls: ['./layout-recipient.component.scss']
})
export class LayoutRecipientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
