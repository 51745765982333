

<div class="d-flex" id="wrapper">
  <!-- <app-dashboard-sidebar></app-dashboard-sidebar> -->
  <app-newsidebar></app-newsidebar>
  <div id="page-content-wrapper">
    <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
    <div class="container-fluid">
      <app-dashboard-dashboard *ngIf="homeShow"></app-dashboard-dashboard>
      <app-dashboard-user *ngIf="userShow"></app-dashboard-user>
      <app-dashboard-role *ngIf="roleShow"></app-dashboard-role>
      <app-dashboard-codebook *ngIf="codebookShow"></app-dashboard-codebook>
      <app-dashboard-position *ngIf="positionShow"></app-dashboard-position>
      <app-dashboard-profile *ngIf="profileShow"></app-dashboard-profile>
    </div>
  </div>
</div>
