import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import Regex from '../../shared/regex/regex';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


import { AlertService } from '../modal/alert/alert.service';
import { AlertSharedService} from '../../shared/modal/alert-shared/alert-shared.service';

import { CodeBookService } from '../../service/code-book/code-book.service';
import { ServiceCenterService } from '../../service/service-center/service-center.service';
@Component({
  selector: 'app-mail-detail',
  templateUrl: './mail-detail.component.html',
  styleUrls: ['./mail-detail.component.scss']
})
export class MailDetailComponent implements OnInit {

  constructor(
    private codeBookService: CodeBookService,
    private fb: FormBuilder,
    private serviceCenterService: ServiceCenterService,
    private alertService: AlertService,
    private alertSharedService: AlertSharedService,
  ) { }

  viewMode: {
    edit: boolean,
    detail: boolean
  }
    =
    {
      edit: false,
      detail: true
    }

  tempmTypes: {
    code: string,
    codeDc: string,
    codeGrpId: string,
    codeNm: string
  }[] = [];

  item: {
    tempmContent: string,
    tempmId: string,
    tempmNm: string,
    tempmTitle: string,
    tempmType: string
  }

  formCreateTemplateMail: FormGroup;
  validators: any = {
    // email: '',
    tempmContent: '',
    tempmNm: '',
    tempmTitle: '',
    tempmType: ''
  }
  @Output() goBack = new EventEmitter<any>();
  @Input() selectItem: any;

  disabledBtn: boolean = true;

  ngDoCheck(): void {
    if (this.item.tempmId != this.selectItem.tempmId) {
      this.item = this.selectItem;
    }
  }

  ngOnInit(): void {
    this.getCodeBook();
    this.item = this.selectItem;
  }

  initFromCreateTemplateMail() {
    console.log(this.item,this.tempmTypes);
    let type = this.tempmTypes.filter(rs => {
      return rs.codeNm == this.item.tempmType;
    });
    console.log(type);
    this.formCreateTemplateMail = this.fb.group({
      tempmContent: [this.item.tempmContent, Regex.Required],
      tempmNm: [this.item.tempmNm],
      tempmTitle: [this.item.tempmTitle, Regex.Required],
      tempmType: [type[0].code, Regex.Required],
      tempmId: [this.item.tempmId]
    });



    this.formCreateTemplateMail.get('tempmContent').valueChanges.subscribe(selectValue => {
      this.validators.tempmContent = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmContent').status);
    });

    this.formCreateTemplateMail.get('tempmNm').valueChanges.subscribe(selectValue => {
      this.validators.tempmNm = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmNm').status);
    });

    this.formCreateTemplateMail.get('tempmTitle').valueChanges.subscribe(selectValue => {
      this.validators.qaaEmail = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmTitle').status);
    });

    this.formCreateTemplateMail.get('tempmType').valueChanges.subscribe(selectValue => {
      this.validators.tempmType = Regex.validatorFormData(this.formCreateTemplateMail.get('tempmType').status);
    });

    this.formCreateTemplateMail.valueChanges.subscribe(value => {
      this.checkDisabledBtn();
    });

    console.log(this.formCreateTemplateMail.value)
  }

  checkDisabledBtn() {
    const valid = {
      VALID: false,
      INVALID: true
    }
    this.disabledBtn = valid[this.formCreateTemplateMail.status];
  }

  clickBack() {
    this.goBack.emit(true);
  }


  onSubmit() {
    this.serviceCenterService.updateTemplateMail(this.formCreateTemplateMail.value).subscribe((rs: any) => {
      if (rs.status) {
        this.alertSharedService.open(
          {
            btnClose: true,
            title: '알림'
          },
          {
            subTitle: '',
            title: '저장되었습니다' 
          }
          ,[
            {
              btnNm: '확인',
              btnType: 'close',
              btnStyle:'btn-black',
              btnFunc: () => {
                this.formCreateTemplateMail.get('tempmContent').setValue("");
                this.formCreateTemplateMail.get('tempmNm').setValue("");
  
                this.formCreateTemplateMail.get('tempmTitle').setValue("");
                this.formCreateTemplateMail.get('tempmType').setValue("");
                this.clickBack();
              }
            }
          ]
          
        );

      }
    });
  }

  switchViewEdit() {
    this.viewMode.detail = false;
    this.viewMode.edit = true;
    this.initFromCreateTemplateMail();
  }

  getCodeBook() {
    this.codeBookService.getCodeBook("TEMPM_TYPE").subscribe((rs: any) => {
      if (rs.status) {
        this.tempmTypes = rs.data;
      }
    });
  }
}
