<div class="row">
  <div class="col-12">
    <label class="tit-ref-001"><b>질문 내용</b></label>
  </div>
  <div class="col-12">
    <table class="oddtable table-bordered  layout display responsive-table">
      <thead>
        <tr>
          <th class="pl-3 p-0 coloredCell border-bottom-0" style="width: 100px;"> 등록일</th>
          <th class="p-0 border-0" colspan="2">
            <div class="row no-gutters">
              <div class="pl-3 regCell col-6 col-lg-5"> {{item.frstReginPnttm}}</div>
              <div class="pl-3 coloredCell col-2 col-lg-2">상태
              </div>
              <div class="pl-3 regCell col-4 col-lg-5">{{item.quesStatus}}</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left pl-md-3 text-center text-md-left p-0 coloredCell organisationnumber"> 문의 내용
          </td>
          <td class="p-3 organisationname" innerHTML="{{item.quesCont}}">
          </td>
        </tr>

        <tr>
          <td class="p-0 pl-3 text-left coloredCell organisationnumber">답변 내용
          </td>
          <td class="p-3 organisationname">
            <textarea readonly innerHTML="{{answ.answCont}}">

            </textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="margin-top: 60px; " class="col-12 d-flex justify-content-center">
    <button (click)="onBack()" class="btn btn-big crimsonbg">
      확인
    </button>
  </div>
</div>