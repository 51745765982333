<section class="w-100 inquiry-management-page" style="background-color: #e2e6eb;min-height: 90vh;">
  <div class="container-lg ml-lg-0 mr-lg-auto p-0 ">
    <h3 class="tit-tem-man-001 barumBold pag-tit" style="padding-bottom: 25px;">내 문의 관리 </h3>
    <app-inquiry (clickItem)="clickItem($event)" [api]="apiGet" (getSuccess)="sendInquiry($event)"
      *ngIf="state.inquiry"></app-inquiry>
    <app-contact-page-inquiry *ngIf="state.question" (sendInquiry)="sendInquiry($event)"></app-contact-page-inquiry>
    <app-inquiry-detail [selectItem]="item" (goBack)="goBack($event)" *ngIf="state.inquiryDetail"></app-inquiry-detail>
  </div>
</section>

<app-alert></app-alert>
<app-loading></app-loading>