<section style="background: #e9ecef" class="container-fluid p-0">

  <section class="container">



    <div class="h-100 align-items-center row d-flex justify-content-center">

      <div class="col-lg-5 py-3 d-flex justify-content-center">
        <form [formGroup]=formRegister class="text-center" style="width: 100%; max-width: 490px; ">


          <div class="regiFields">



            <div class="form-group row">
              <div class="col-sm-12">
                <div class="mb-3 text-left">
                  <label for="staticTYPE">회원유형</label>
                  <select type="text" class="form-control" formControlName='type' id="staticTYPE">
                    <option value="" disabled selected>
                      선택
                    </option>
                    <option *ngFor="let type of custommerTypes;let i = index" value="{{type.code}}">{{type.codeNm}}
                    </option>
                  </select>
                  <small class="form-text text-muted mt-1 text-validators {{validators.type}}">고객 타입을 선택해주세요.</small>


                </div>


              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <div class="mb-3 text-left"><label for="staticname" class="reglab">이름</label>
                  <input type="text" class="form-control" pattern="^-?(\D+)?$" formControlName='name' id="staticname"
                    placeholder="이름 입력">
                  <small class="form-text text-muted mt-1 text-validators {{validators.name}}">이름을 입력해주세요.</small>
                </div>


              </div>
            </div>



            <div class="form-group row">
              <div class="col-sm-12">
                <div class="mb-3 text-left">
                  <label for="staticEmail">아이디(이메일)
                  </label>
                  <input type="text" id="email" class="form-control" formControlName='email'
                    placeholder="아이디(이메일)을 입력해주세요">
                  <small class="form-text text-muted mt-1 text-validators {{validators.email}}">이메일 규칙을 확인해주세요.</small>
                </div>


              </div>
            </div>

            <ng-container *ngIf="isSysLogin">
              <div class="form-group row">
                <div class="col-sm-12">
                  <div class="mb-3 text-left">

                    <label for="staticpassword">비밀번호</label>
                    <input type="password" class="form-control" formControlName='password' id="password"
                      placeholder="영어, 숫자 또는 특수문자 조합 8 ~ 20자 이내">

                    <small class="form-text text-muted mt-1 text-validators {{validators.password}}">비밀번호 규칙을
                      확인해주세요</small>
                  </div>


                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <div class="mb-3 text-left">

                    <label for="staticConfirmPassword">비밀번호 확인</label>
                    <input type="password" class="form-control" formControlName='confirmpassword'
                      id="staticConfirmPassword" id="confirmpassword" placeholder="영어, 숫자 또는 특수문자 조합 8 ~ 20자 이내">

                    <small class="form-text text-muted mt-1 text-validators {{validators.confirmpassword}}">비밀번호 규칙을
                      확인해주세요</small>
                  </div>


                </div>
              </div>
            </ng-container>


          </div>

          <div class="col-md-12 px-0 checkCon  text-left my-2">

            <div class="pinkCheck form-group form-check p-0 m-0" style="line-height: 1;"><label
                class="con-che-001"><input type="checkbox" formControlName='allAgree' class="form-check-input"
                  id="allAgree"><span class="che-mar-001"></span></label>모두 동의하기</div>


            <div class="pinkCheck checkCenter form-group form-check p-0 " style="line-height: 1;"><label
                class="con-che-001"><input type="checkbox" formControlName='termsPolocy' class="form-check-input"
                  id="termsPolocy"><span class="che-mar-001"></span></label>이용약관, 개인정보처리방침에 동의합니다. (필수)</div>



            <div class="pinkCheck form-group form-check p-0 m-0" style="line-height: 1;"><label
                class="con-che-001"><input type="checkbox" formControlName='news' class="form-check-input"
                  id="news"><span class="che-mar-001"></span></label>소식 및 정보 수신에 동의합니다. (선택)</div>



          </div>



          <div class="form-group mb-0 row d-flex justify-content-center">
            <div class="col-12">

              <button type="button" [disabled]="disabledBtn" class="btn btn-block btn-crimson py-2"
                (click)="clickFuction()">
                회원가입

              </button>
            </div>
          </div>





          <div class="signupwiths form-group row">
            <div class="col-sm-12">

              <p class="w-100 text-left">소셜 계정으로 가입하기</p>
              <div class="d-flex justify-content-between signinBtns">
                
                <button style="width:33.3%;" id="naverIdLogin_loginButton" class="btn " type="button"
                  data-social="naver" (click)="btnClick($event)">
                  <img src="../../../assets/img/icon/naver.png"> 네이버</button>

                <button style="width:33.3%;" id="googleIdLogin_loginButton" class="btn " type="button"
                  data-social="google" (click)="btnClick($event)">
                  <i class="fa fa-user"><img src="../../../assets/img/icon/google.png"></i> 구글</button>
                
                <button style="width:33.3%;" id="kakaoIdLogin_loginButton" class="btn " type="button"
                  data-social="kakao" (click)="btnClick($event)">
                  <i class="fa fa-user"><img src="../../../assets/img/icon/TALK.png"></i> 카카오</button>
              </div>
            </div>
          </div>


        </form>


      </div>
    </div>



  </section>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Term & Condition</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <app-alert></app-alert>

</section>

<app-loading></app-loading>