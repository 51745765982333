import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Regex from '../../manage/shared/regex/regex';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../manage/service/user/user.service';
import { LoadingService } from '../../manage/shared/loading/loading.service';
import { AlertService } from '../../manage/component/modal/alert/alert.service';

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss']
})
export class CreateNewPasswordComponent implements OnInit {
  key:string = '';
  formForgotCreateeNewPassword: FormGroup;

  validators: any = {
    password: '',
    confPassword: ''
  }

  disabledBtn: boolean = true;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private userService: UserService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.initFormCreateeNewPassword();
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.key = params.key.replace(/#/g, "");;
      });
  }

  checkValidatiorsFormForgotPassword(type: any) {
    const _calss = {
      VALID: '',
      INVALID: 'invalid'
    }
    this.validators[type] = _calss[this.formForgotCreateeNewPassword.get(type).status];
  }

  initFormCreateeNewPassword() {
    this.formForgotCreateeNewPassword = this.fb.group({
      password: [null, Regex.passwordLogin],
      confPassword: [null,  Regex.passwordLogin]
    });
    this.formForgotCreateeNewPassword.get('password').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormForgotPassword('password');
    });

    this.formForgotCreateeNewPassword.get('confPassword').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormForgotPassword('confPassword');
    });

    this.formForgotCreateeNewPassword.valueChanges.subscribe(value => {
      this.checkDisabledBtn();
    });
  }

  checkDisabledBtn() {
    const valid = {
      VALID: false,
      INVALID: true
    }
    this.disabledBtn = valid[this.formForgotCreateeNewPassword.status];
  }

  createNewPassword(){
    this.loadingService.openLoading();
    let data = {
      password: this.formForgotCreateeNewPassword.get("password").value,
      confPassword:  this.formForgotCreateeNewPassword.get("confPassword").value,
      key: this.key
    }
    this.userService.createNewPassword(data).subscribe((rs: any) => {
      if (rs.status) {
        this.alertService.open(
          "알림",
          rs.data,
          {
            func: () => {
              this.alertService.close();
              this.router.navigate(['login']);
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }else{
        this.alertService.open(
          "알림",
          rs.message,
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }
      this.loadingService.closeLoading();
    });
  }
}
