import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LoadingService } from '../../manage/shared/loading/loading.service';
import { AlertService } from '../../manage/component/modal/alert/alert.service';
import { ServiceCenterService } from '../../manage/service/service-center/service-center.service';
import { AlertSharedService } from '../../manage/shared/modal/alert-shared/alert-shared.service';

import { NavbarService } from '../navbar/navbar.service';
import Regex from '../../manage/shared/regex/regex';
@Component({
  selector: 'app-contract-inquire',
  templateUrl: './contract-inquire.component.html',
  styleUrls: ['./contract-inquire.component.scss']
})
export class ContractInquireComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private serviceCenterService: ServiceCenterService,
    private alertSharedService: AlertSharedService,
    private navbarService: NavbarService,
  ) { }

  formContractInquire: FormGroup;

  btnSubmit: boolean = true;

  validators: {
    qaaContent: string,
    qaaMail: string,
    qaaNm: string,
    qaaPhone: string,
    agreeall: string
  } = {
      qaaContent: "",
      qaaMail: "",
      qaaNm: "",
      qaaPhone: "",
      agreeall: ""
    }


  ngOnInit(): void {
    this.navbarService.setNavbarTitle("고객센터");
    this.initFormContractInquire();
  }

  ngAfterViewInit(): void {
  }



  initFormContractInquire() {
    this.formContractInquire = this.fb.group({
      qaaContent: ['', Regex.Required],
      qaaMail: ['', Regex.emailRequired],
      qaaNm: ['', Regex.Required],
      qaaPhone: ['', Regex.Required],
      agreeall: [null, Regex.Required],
    });

    this.formContractInquire.get('qaaContent').valueChanges.subscribe(valueChanges => {
      this.validators.qaaContent = Regex.validatorFormData(this.formContractInquire.get('qaaContent').status);
    });

    this.formContractInquire.get('qaaMail').valueChanges.subscribe(valueChanges => {
      this.validators.qaaMail = Regex.validatorFormData(this.formContractInquire.get('qaaMail').status);
    });

    this.formContractInquire.get('qaaNm').valueChanges.subscribe(valueChanges => {
      this.validators.qaaNm = Regex.validatorFormData(this.formContractInquire.get('qaaNm').status);
    });

    this.formContractInquire.get('qaaPhone').valueChanges.subscribe(valueChanges => {
      this.validators.qaaPhone = Regex.validatorFormData(this.formContractInquire.get('qaaPhone').status);

    });

    this.formContractInquire.get('agreeall').valueChanges.subscribe(valueChanges => {
      if (valueChanges === false) {
        this.formContractInquire.get('agreeall').setValue(null);
      }
      this.validators.agreeall = Regex.validatorFormData(this.formContractInquire.get('agreeall').status);

    });
    this.formContractInquire.valueChanges.subscribe(valueChanges => {
      this.btnSubmit = !this.formContractInquire.valid;
    });
  }

  onSend() {
    this.loadingService.openLoading();
    if (this.formContractInquire.status === 'VALID') {
      console.log('o')
      this.insertInquireFront();
    } else {
      console.log('x')
      this.validatorAlert();
    }
  }

  clearData() {
    this.formContractInquire.get('qaaContent').setValue(null);
    this.formContractInquire.get('qaaMail').setValue(null);
    this.formContractInquire.get('qaaNm').setValue(null);
    this.formContractInquire.get('qaaPhone').setValue(null);
    this.formContractInquire.get('agreeall').setValue(null);
    this.validators = {
      qaaContent: "",
      qaaMail: "",
      qaaNm: "",
      qaaPhone: "",
      agreeall: ""
    }
  }

  validatorAlert() {
    const name:any = { controls: this.formContractInquire.get('qaaNm'), contents: '이름을 입력하세요'}; 
    const mail:any = { controls: this.formContractInquire.get('qaaMail'), contents: '정확한 이메일을 입력하세요'};
    const phone:any = { controls: this.formContractInquire.get('qaaPhone'), contents: '정확한 핸드폰 번호를 입력하세요'};
    const contents:any = { controls: this.formContractInquire.get('qaaContent'), contents: '문의 내용을 입력하세요'};
    const agree:any = { controls: this.formContractInquire.get('agreeall'), contents: '개인정보 수집 및 이용에 동의해주세요'};
    const array:any = [agree, contents, phone, mail, name];
    let title;
    array.forEach(v => {
      if (!v.controls.valid) {
        title = v.contents;
      }
    });


    this.alertSharedService.open(
      {
        title: "알림",
        btnClose: true
      },
      {
        title: title,
        subTitle: ''
      },
      [
        {
          btnNm: '확인',
          btnStyle: 'btn-white',
          btnType: 'close',
          btnFunc: () => {
          }
        }
      ]
    );
    this.loadingService.closeLoading();
  }
 
  insertInquireFront() {
    this.serviceCenterService.insertInquireFront(this.formContractInquire.value).subscribe((rs: any) => {
      if (rs.status) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: '문의 내용이 등록되었습니다.',
            subTitle: ''
          },
          [
            {
              btnNm: '확인',
              btnStyle: 'btn-white',
              btnType: 'close',
              btnFunc: () => {
                this.clearData();
              }
            }
          ]
        );
      }
      this.loadingService.closeLoading();
    });
  }
}
