<section>
  <h5 class="text-center tit-sel-001 margin-t20">
    엑셀 대량 전송
  </h5>
  <div class="row mb-2">
      <div class="d-flex justify-content-center w-100 importBtns margin-t30">
          <div class="btnboxA d-flex align-items-center justify-items-center text-center item"
              (click)="exportTableToExcel()">
              <div class="text-center w-100">
                  <p class="fz22">수신자 리스트.xlsx<br /><span>다운로드</span></p>
              </div>
          </div>

          <div class="ml-2 margin-l20 d-flex align-items-center justify-items-center text-center item btnboxB"
              (click)="file.click()">
              <input style="display: none;" (change)="addfile($event)"
                  accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type="file" #file>
              <div class="text-center w-100">
                  <p class="fz22">파일<br />업로드</p>
              </div>
          </div>

      </div>
  </div>
  <div class="row ">
      <div class="col-12 margin-t40">
          <ol class="bg-detail tex-detail">
              <li>1. 아래 엑셀 파일 양식을 다운로드 받습니다.</li>
              <li>2. 다운로드 받으신 엑셀 파일을 열어서 컬럼 순서에 맞게 계약 대상자들을 입력해주세요. (컬럼 정보가 비어 있어서는 안됩니다.)</li>
              <li>3. 입력이 완료 되시면 해당 파일을 다시 업로드해주세요.</li>
              <li>4. 엑셀 파일을 업로드하시면 등록된 수신자 리스트를 확인하실 수 있습니다.</li>
          </ol>
      </div>
  </div>
</section>