import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { NavbarService } from '../navbar/navbar.service';
declare var $: any;
@Component({
  selector: 'app-page-register',
  templateUrl: './page-register.component.html',
  styleUrls: ['./page-register.component.scss']
})
export class PageRegisterComponent implements OnInit {

  constructor(
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {

    this.navbarService.setNavbarTitle('');
  }

}
