import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingProgressService {
  loading: boolean = false;
  progress: number = 0;
  textProgress: string = '0%';
  constructor() { }

  openLoading(): void {
    this.loading = true;
  }

  closeLoading(): void {
    this.loading = false;
  }

  getLoading(): boolean {
    return this.loading;
  }

  setProgress(progress: number){
    this.progress = progress;
  }

  getProgress(): number{
    return this.progress;
  }

  setTextProgress(text: string){
    this.textProgress = text;
  }

  getTextProgress(): string{
    return this.textProgress;
  }
}
