<div class="bg-modal" *ngIf="show">
  <!-- Modal -->




  <div class="modal popUpType1 fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
    <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">
        <div class="modal-header rounded-0 border-bottom-0  " style="background: #ffffff;color: #000000;">

          <div class="border-bottom border-dark d-flex w-100 borderspacer">
            <h5 class="modal-title barumBold" id="exampleModalLabel">저장</h5>
            <a type="button" style="color: #000000;" class="close" (click)="closeModal()" data-dismiss="modal"
              aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>

        </div>
        <div class="modal-body rounded-0 pb-0" style="background: #ffffff;color: #000000;">
          <p class="text-center mb-0 font-weight-light">작성중인 계약서를 임시저장<br>
            하시겠습니까?

          </p>
        </div>
        <div class="modal-footer rounded-0 d-flex justify-content-center border-top-0"
          style="background: #ffffff;color: #000000;">
          <button type="button" class="btn btn-middle-short btn-white" (click)="onClickBtn('btnNo')">아니오</button>
          <button type="button" class="btn btn-middle-short btn-black ml-2" (click)="onClickBtn('btnYes')">예</button>
        </div>
      </div>
    </div>
  </div>
</div>
