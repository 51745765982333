import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataPassService {
  public paymentData: any;
  public page = 'main';
  public howToPage: string;
  source: BehaviorSubject<any> = new BehaviorSubject(false);

  
  constructor() { }

  setHowToPage(page) {
    this.howToPage = page;
  }

  getHowToPage() {
    return this.howToPage;
  }

  setData(data) {
    this.paymentData.next(data)
  }

  setPage(page) {
    this.page = page;
  }

  getPage() {
    return this.page;
  }

  getData() {
    return this.paymentData;
  }
}
