import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecipientService {
  shortUrl: string = null;
  state: string = 'email';
  mail: string;
  type: string = 'member';
  onState: boolean = true;
  contNm: string = '';
  emailEnter: boolean;
  verifi: {
    pwd: string,
    phone: string
  } =
    {
      pwd: "N",
      phone: "N"
    }

  constructor() { }

  setState(state?: 'email' | 'phone' | 'type' | 'password' | 'write') {
    this.state = state;
    this.setOnState(true);
  }

  setContNm(contNm: string){
    this.contNm = contNm;
  }

  setEmailEnter(value) {
    this.emailEnter = value;
  }

  getEmailEnter() {
    return this.emailEnter;
  }

  getContNm(): string {
    return this.contNm;
  }

  setMember(type: string) {
    this.type = type;
  }

  getType(type: string) {
    return this.type;
  }

  getState(): string {
    return this.state;
  }

  setOnState(state: boolean) {
    this.onState = state;
  }

  getOnState(): boolean {
    return this.onState;
  }

  setShortUrl(shortUrl: string) {
    this.shortUrl = shortUrl;
  }

  getShortUrl(): string {
    return this.shortUrl;
  }

  setMail(mail: string) {
    this.mail = mail;
  }

  getMail(): string {
    return this.mail;
  }

  setVerifi(pwd: string, phone: string) {
    this.verifi.pwd = pwd;
    this.verifi.phone = phone;
  }

  getVerifi(): {
    pwd: string,
    phone: string
  } {
    return this.verifi;
  }
}
