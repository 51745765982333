<section class="bg-white text-center p-md-5">




  <div class="standardFront">
    <div class="container-md2">
      <!-- this is contianer start  -->



      <!-- This is large content  -->
      <div class="d-none d-md-block">


        <div class="row">

          <div class="col-lg-12">
            <h3 style="font-size: 28px;" class="mb-0 `text-black text-center">
              근로계약서, 부동산임대차계약서, 차용증 등의 계약서를 간편하게 전자계약으로 작성 및 관리하고, <br/>블록체인 스마트 컨트랙트 연동을 통한 전자계약정보의 무결성을 보장합니다.
            </h3>
          </div>

        </div>

        <div class="row py-5">

          <div class="col-lg-12 p-0">

            <div class="card-deck pt-5">

              <div class="card bg-primary">
                <div class="card-header text-left"><img src="/assets/img/frontpage/homeIC1.png"></div>
                <div class="card-body text-left">
                  <h3 class="py-3">다수 계약자 간 서명
                  </h3>
                  <p class="pb-1">
                    계약 본인 및 이해 당사자들은<br>
                    각각의 인증키가 발급되어 서명

                  </p>
                </div>
              </div>

              <div class="card bg-warning">
                <div class="card-header text-left"><img src="/assets/img/frontpage/homeIC2.png"></div>
                <div class="card-body text-left">
                  <h3 class="py-3">간편한 전자계약서 작성</h3>
                  <p class="pb-1">
                    간편한 UI 로 계약내용 및 조건을<br>
                    입력하고 설정
                  </p>
                </div>
              </div>

              <div class="card bg-warning">
                <div class="card-header text-left"><img src="/assets/img/frontpage/homeIC3.png"></div>
                <div class="card-body text-left">
                  <h3 class="py-3">전자계약 정보 블록체인 저장 관리</h3>
                  <p class="pb-1">
                    전자 계약 내용과 서명 정보는<br>
                    스마트 컨트랙트와 블록체인에 저장 및 관리
                  </p>
                </div>
              </div>

              <div class="card bg-warning">
                <div class="card-header text-left"><img src="/assets/img/frontpage/homeIC4.png"></div>
                <div class="card-body text-left">
                  <h3 class="py-3">감사 추적 및 알림 서비스 제공</h3>
                  <p class="pb-1">
                    계약 체결부터 만료시점까지 진행 과정의
                    감사 추적 및 면책사항, 필수조건 및 특약,
                    관련 법 개정 알림 서비스 제공
                  </p>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
      <!-- This is large content end -->

      <!-- Mobile content  -->

      <div class="d-block d-md-none ">


        <div class="row">

          <div class="col-12">
            <h3 class=" mobileServiceHeading text-black text-center">근로계약서, 부동산임대차계약서,
              차용증 등의<br>
              계약서를 간편하게 전자계약으로 작성 및 관리하고,<br>
              <span>
                블록체인 스마트 컨트랙트 연동을 통한<br>
                전자계약정보의 무결성을 보장합니다.
              </span>

            </h3>

          </div>

        </div>

        <div style="margin-bottom: 48px;" class="row">
          <div class="col pr-0">

            <div class="card bg-primary">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC1.png">
              </div>
              <div class="card-body text-center">
                <h3 class="mobServeh3">다수 계약자 간 서명
                </h3>
                <p class="pb-1 px-1">
                  계약 본인 및 이해 당사자들은<br>
                  각각의 인증키가 발급되어 서명

                </p>
              </div>
            </div>

          </div>
          <div class="col pl-0">

            <div class="card bg-warning">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC2.png"></div>
              <div class="card-body text-center">
                <h3 class="mobServeh3">간편한 전자계약서 작성</h3>
                <p class="pb-1 px-1">
                  간편한 UI 로 계약내용<br>및 조건을
                  입력하고 설정


                </p>
              </div>
            </div>

          </div>
          <div class="w-100 pt-2"></div>
          <div class="col  pr-0">

            <div class="card bg-warning">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC3.png"></div>
              <div class="card-body text-center">
                <h3 class="mobServeh3">전자계약 정보<br>블록체인 저장 관리</h3>
                <p class="pb-1 px-1">
                  전자 계약 내용과 서명 정보는<br>
                  스마트 컨트랙트와 블록체인에<br>저장 및 관리
                </p>
              </div>
            </div>

          </div>

          <div class="col pl-0">

            <div class="card bg-warning">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC4.png"></div>
              <div class="card-body text-center">
                <h3 class="mobServeh3">감사 추적 및 알림<br>서비스 제공</h3>
                <p class="pb-1 px-1">
                  계약 체결부터 만료시점까지<br>진행 과정의
                  감사 추적 및 면책사항,<br>필수조건 및 특약,
                  관련 법 개정<br>알림 서비스 제공

                </p>
              </div>
            </div>

          </div>

        </div>

      </div>
      <!-- Mobile content end -->


      <!-- this is contianer end  -->
    </div>
  </div>

  <div class="oddFront">
    <div class="container-md">
      <!-- this is contianer start  -->



      <!-- This is large content  -->

      <!-- This is large content end -->

      <!-- Mobile content  -->

      <div class=" ">


        <div class="row">

          <div class="col-lg-12">
            <h3 class=" homeservicetxt text-black text-center">근로계약서, 부동산임대차계약서, 차용증 등의
              계약서를
              간편하게 전자계약으로
              작성
              및
              관리하고,
              블록체인 스마트 컨트랙트 연동을 통한 전자계약정보의 무결성을 보장합니다.
            </h3>
          </div>

        </div>

        <div class="row pt-5">
          <div class="col pr-0">

            <div class="card bg-primary">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC1.png">
              </div>
              <div class="card-body text-center">
                <h3 class="py-3">다수 계약자 간 서명
                </h3>
                <p class="pb-1 px-1">
                  계약 본인 및 이해 당사자들은
                  각각의 인증키가 발급되어 서명

                </p>
              </div>
            </div>

          </div>
          <div class="col pl-0">

            <div class="card bg-warning">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC2.png"></div>
              <div class="card-body text-center">
                <h3 class="py-3">간편한 전자계약서 작성</h3>
                <p class="pb-1 px-1">
                  간편한 UI 로 계약내용 및<br> 조건을
                  입력하고 설정


                </p>
              </div>
            </div>

          </div>
          <div class="w-100 serSpacing"></div>
          <div class="col  pr-0">

            <div class="card bg-warning">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC3.png"></div>
              <div class="card-body text-center">
                <h3 class="py-3">전자계약 정보<br>블록체인 저장 관리</h3>
                <p class="pb-1 px-1">
                  전자 계약 내용과 서명 정보는<br>
                  스마트 컨트랙트와 블록체인에<br> 저장 및 관리
                </p>
              </div>
            </div>

          </div>

          <div class="col pl-0">

            <div class="card bg-warning">
              <div class="card-header text-center"><img class="img-thumbnail border-0"
                  src="/assets/img/frontpage/homeIC4.png"></div>
              <div class="card-body text-center">
                <h3 class="py-3">감사 추적 및 알림<br>서비스 제공</h3>
                <p class="pb-1 px-1">
                  계약 체결부터 만료시<br>점까지
                  진행 과정의 감사 추적 및 면책사항,
                  필수조건 및 특약, 관련 법 개정
                  알림 서비스 제공


                </p>
              </div>
            </div>

          </div>

        </div>

      </div>
      <!-- Mobile content end -->


      <!-- this is contianer end  -->
    </div>
  </div>

</section>