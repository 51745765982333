import { Injectable } from '@angular/core';
import { SnsService } from '../../service/sns/sns.service';
import { API_URL } from '../api.constant';
import { environment } from '../../../../environments/environment';

let snsTocken: any;
let snsMail: any;


declare let Kakao: any;
declare let gapi: any;
declare let naver: any;


var gauth;
var naverLogin;





@Injectable({
  providedIn: 'root'
})


export class SocialLoadApiService {

  onInit: {
    google: boolean,
    naver: boolean,
    kakao: boolean
  } = {
      google: false,
      naver: false,
      kakao: false
    }

  constructor(
    private snsService: SnsService,
  ) {
  }

  onLoadSocial: boolean = false;

  g_resSns: any = {
    token: '',
    email: '',
    name: '',
    id: '',
    pwd: '',
  }

  regisSocial: {
    loginSocial: string,
    socialId: string,
    token: string,
  }

  setOnloadSocial(load: boolean) {
    this.onLoadSocial = load;
  }

  getOnLoadSocail() {
    return this.onLoadSocial;
  }

  getDataSocial() {
    let social = {
      snsData: this.g_resSns,
      regisSocial: this.regisSocial
    }

    return social;
  }

  getKakao() {
    return Kakao;
  }

  getGoogle() {
    return gapi;
  }

  getNaver() {
    return naver;
  }


  //================================================================================================================>>> KaKao

  setSnsData(p_token: string, p_id: string, p_email: string, p_name: string) {
    this.g_resSns.token = p_token;
    this.g_resSns.id = p_id;
    this.g_resSns.email = p_email;
    this.g_resSns.name = p_name;

  }

  async initKokaoApi() {
    if (!this.onInit.kakao) {
      Kakao.init('5ca5a0b2c94572d826f5cf65e97954c5');
      this.onInit.kakao = true;
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  timeoutNaver(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async loadKaKaoAPI() {

    const res = await Kakao.Auth.loginForm({

      success: (authObj) => {
        Kakao.API.request({

          url: '/v2/user/me',

          success: (res) => {

            this.g_resSns.token = authObj.access_token;
            this.g_resSns.id = res.id;
            this.g_resSns.email = res.kakao_account['email'];
            this.g_resSns.name = res.properties && res.properties['nickname'] ? res.properties['nickname'] : '';

            this.regisSocial = {
              loginSocial: "kakao",
              socialId: this.g_resSns.id,
              token: this.g_resSns.token
            }

            this.setOnloadSocial(true);

          }
        })
      },
      fail: function (err) {

      }
    })
  }


  async getKaKaoUserProfile() {

    this.setSnsData('', '', '', '');
    this.loadKaKaoAPI();
    await this.getResOfKaKaoUserProfile();

  }

  async getResOfKaKaoUserProfile() {

    return new Promise(async (resolve) => {

      while (this.g_resSns.token == '' || this.g_resSns.token == 'undefined' || this.g_resSns.token == null) {
        await this.timeout(1000);
      }
      resolve(true);
    });
  }

  kakaoLogoutAPI() {
    Kakao.Auth.logout(function (response) {
      alert(response + 'logout');
    });
  }

  //<<<================================================================================================================ KaKao



  //================================================================================================================>>> NAVER


  loadNaverAPI() {

    if (!this.onInit.naver) {
      naverLogin = new naver.LoginWithNaverId({
        clientId: "SnEgAjTmfJIQ9ksqEl9G",
        //callbackUrl: environment.downloadFileAttachment + "/#/callback",
        callbackUrl:"https://www.gcont.co.kr/#/callback",
        isPopup: true, /* 팝업을 통한 연동처리 여부 */
        //loginButton: {color: "green", type: 3, height: 50} /* 로그인 버튼의 타입을 지정 */,
      });

      /* 설정정보를 초기화하고 연동을 준비 */
      naverLogin.init();
      this.onInit.naver = true;
    }


  }




  getNaverUserPofile() {

    this.setSnsData('', '', '', '');
    var token = localStorage.getItem('wtw-token1');

    new Promise(async (resolve) => {
      while (token == '' || token == 'undefined' || token == null) {
        await this.timeoutNaver(1000);

      }
      resolve(true);
    });


    this.snsService.getUserProfile('naver', token).subscribe((res: any) => {
      if (res.status) {

        this.setSnsData(token, res.data.id, res.data.email, res.data.name);

        this.regisSocial = {
          loginSocial: "naver",
          socialId: this.g_resSns.id,
          token: this.g_resSns.token
        }


        this.setOnloadSocial(true);

      }
    });
  }
  //<<<================================================================================================================ NAVER




  //<<<================================================================================================================ Google

  loadGoogleAPI() {
    if (!this.onInit.google) {

      gapi.load('auth2', function () {
        gauth = gapi.auth2.init({
          client_id: '475318934329-s820j9llrvfelapljdfbdqdlfamn9sb8.apps.googleusercontent.com',
          scope: 'email profile openid',
          response_type: 'id_token permission'
        });

        gauth.then(function () {
        }, function () {
          console.error('init fail');
        });

      });

      this.onInit.google = true;
    }
  }


  googleButtonRender() {
    gapi.signin2.render('my-signin2', {
      'scope': 'profile email',
      'width': 240,
      'height': 50,
      'longtitle': true,
      'theme': 'dark'
    });
  }


  async getGoogleUserPofile() {
    this.setSnsData('', '', '', '');
    await this.getResOfGoogleUserPofile();

  }


  async getResOfGoogleUserPofile() {

    return new Promise(async (resolve) => {

      gauth.signIn().then((response) => {

        this.g_resSns.id = gauth.currentUser.get().getBasicProfile().getId(); // Do not send to your backend! Use an ID token instead.
        this.g_resSns.email = gauth.currentUser.get().getBasicProfile().getEmail();
        this.g_resSns.name = gauth.currentUser.get().getBasicProfile().getName();

        this.regisSocial = {
          loginSocial: "google",
          socialId: this.g_resSns.id,
          token: this.g_resSns.id
        }

        this.setOnloadSocial(true);
      });

      while (this.g_resSns.id == '' || this.g_resSns.id == 'undefined' || this.g_resSns.id == null) {
        await this.timeout(1000);
      }
      resolve(true);
    });
  }



  ignOut() {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
    });
  }

  //<<<================================================================================================================ Google

}
