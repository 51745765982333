<section class="bg-white about-section text-center p-md-5">


  <div class="standardFront">
    <div class="container-md2">
      <div class="row">

        <div class="aboutHead d-none d-md-block my-5 col-lg-12">
          <h3 class=" text-black"><b>블록체인 인증을 활용한 전자계약 서비스</b>
          </h3>
        </div>

        <div class="d-block d-md-none  abtWordpadd col-12">
          <h3 class="abtWord font-weight-bold text-black"><b>블록체인 인증을 활용한<br>전자계약 서비스</b>
          </h3>
        </div>

        <div class="container d-none d-md-block">
          <div class="justify-content-between d-flex h-100">
            <div class="cardA">
              <div class="d-flex  align-items-end cardContainer">

                <div class="cardContent text-left">
                  <div class="cardOverlap">
                    <h3 class="py-3">안전하고 투명한 계약 체결</h3>
                    <p class="pb-1">
                      전자서명을 통한 안전한 계약체결 및
                      블록체인 적용으로 투명한 계약관리
                    </p>
                  </div>
                  <div class="square_corner"></div>
                </div>


              </div>
            </div>
            <div class=" cardB">
              <div class="d-flex  align-items-end cardContainer">

                <div class="cardContent text-left">
                  <div class="cardOverlap">
                    <h3 class="py-3">법적 효력의 계약서</h3>
                    <p class="pb-1">
                      리카르디안 계약으로 법적 유효성 보장 및 계약 이력관리 기능 제공
                    </p>
                  </div>
                  <div class="square_corner"></div>
                </div>


              </div>
            </div>
            <div class="cardC">
              <div class="d-flex  align-items-end cardContainer">

                <div class="cardContent text-left">
                  <div class="cardOverlap">
                    <h3 class="py-3">계약 정보 알림서비스</h3>
                    <p class="pb-1">
                      계약관련 법률 가이드 및 계약 내용 이행여부 알림 서비스 제공
                    </p>
                  </div>
                  <div class="square_corner"></div>
                </div>


              </div>
            </div>
          </div>
        </div>




        <!-- MOBILE CARDS -->

        <div style="z-index: 1; " class="px-3 d-block d-md-none mb-3 col-12">


          <div class="mobileCard border-0 card">

            <!-- Card image -->
            <img class="img-thumbnail p-0 border-0 rounded mobileCardImg" src="/assets/img/frontpage/mobileSq1.jpg"
              alt="Card image cap">

            <!-- Card content -->
            <div class="mobileCardTxt card-body p-0 text-dark rgba-black-light">

              <div class="container-fluid">
                <div class="row align-items-end">
                  <h3 class="">안전하고 투명한 계약 체결
                  </h3>
                  <p class="">
                    전자서명을 통한 안전한 계약체결 및
                    블록체인 적용으로 투명한 계약관리


                  </p>
                  <div class="square_cornerMobile"></div>
                </div>
              </div>

            </div>

          </div>

          <!-- ODDCARD -->

          <div class="mobileCard my-5 border-0 card">

            <!-- Card image -->
            <img class="img-thumbnail p-0 border-0 rounded mobileCardImgOdd" src="/assets/img/frontpage/mobileSq2.jpg"
              alt="Card image cap">

            <!-- Card content -->
            <div class="mobileCardTxt mobileOddCarte  card-body p-0 text-dark rgba-black-light">

              <div class="container-fluid">
                <div class="row align-items-end">
                  <h3 class="text-right w-100">법적 효력의 계약서

                  </h3>
                  <p class="text-right">
                    리카르디안 계약으로 법적 유효성 보장 및
                    계약 이력관리 기능 제공


                  </p>
                  <div class="square_cornerMobileOdd"></div>
                </div>
              </div>

            </div>

          </div>

          <!-- ODDCARD -->

          <div class="mobileCard border-0 card">

            <!-- Card image -->
            <img class="img-thumbnail p-0 border-0 rounded mobileCardImg" src="/assets/img/frontpage/mobileSq3.jpg"
              alt="Card image cap">

            <!-- Card content -->
            <div class="mobileCardTxt card-body p-0 text-dark rgba-black-light">

              <div class="container-fluid">
                <div class="row align-items-end">
                  <h3 class="">계약 정보 알림서비스

                  </h3>
                  <p class="">
                    계약관련 법률 가이드 및
                    계약 내용 이행여부 알림 서비스 제공


                  </p>
                  <div class="square_cornerMobile"></div>
                </div>
              </div>

            </div>

          </div>

        </div>





      </div>
    </div>
  </div>


  <div class="oddFront">
    <div class="container-md">
      <div class="row">

        <div class="aboutHead col-lg-12">
          <h3 class=" text-black"><b>블록체인 인증을 활용한<br>전자계약 서비스</b>
          </h3>
        </div>


        <!-- MOBILE CARDS -->

        <div style="z-index: 1; " class="px-3  mb-3 col-12">


          <div class="mobileCard border-0 card">

            <!-- Card image -->
            <img class="img-thumbnail p-0 border-0 rounded mobileCardImg" src="/assets/img/frontpage/mobileSq1.jpg"
              alt="Card image cap">

            <!-- Card content -->
            <div class="mobileCardTxt card-body p-0 text-dark rgba-black-light">

              <div class="container-fluid">
                <div class="row align-items-end">
                  <h3 class="">안전하고 투명한 계약 체결
                  </h3>
                  <p style="padding-right:0px;" class="">
                    전자서명을 통한 안전한 계약체결 및
                    블록체인 적용으로 투명한 계약관리


                  </p>
                  <div class="square_cornerMobile"></div>
                </div>
              </div>

            </div>

          </div>

          <!-- ODDCARD -->

          <div class="mobileCard oddSpace border-0 card">

            <!-- Card image -->
            <img class="img-thumbnail p-0 border-0 rounded mobileCardImgOdd" src="/assets/img/frontpage/mobileSq2.jpg"
              alt="Card image cap">

            <!-- Card content -->
            <div class="mobileCardTxt mobileOddCarte  card-body p-0 text-dark rgba-black-light">

              <div class="container-fluid">
                <div class="row align-items-end">
                  <h3 class="text-right w-100">법적 효력의 계약서</h3>
                  <p class="text-right">
                    리카르디안 계약으로 법적 유효성 보장 및
                    계약 이력관리 기능 제공


                  </p>
                  <div class="square_cornerMobileOdd"></div>
                </div>
              </div>

            </div>

          </div>

          <!-- ODDCARD -->

          <div class="mobileCard border-0 card">

            <!-- Card image -->
            <img class="img-thumbnail p-0 border-0 rounded mobileCardImg" src="/assets/img/frontpage/mobileSq3.jpg"
              alt="Card image cap">

            <!-- Card content -->
            <div class="mobileCardTxt card-body p-0 text-dark rgba-black-light">

              <div class="container-fluid">
                <div class="row align-items-end">
                  <h3 class="">계약 정보 알림서비스

                  </h3>
                  <p class="">
                    계약관련 법률 가이드 및
                    계약 내용 이행여부 알림 서비스 제공



                  </p>
                  <div class="square_cornerMobile"></div>
                </div>
              </div>

            </div>

          </div>

        </div>





      </div>
    </div>
  </div>

</section>