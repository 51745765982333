<section class="w-100 creating-electronic-page" style="background-color: #e2e6eb;">
  <div class="largerUi">
    <section class="container-lg ml-lg-0 mr-lg-auto p-0 d-none d-lg-block">
      <h3 class="tit-tem-man-001 barumBold" style="padding-bottom: 35px;">전자계약서 만들기</h3>


      <div class="justify-content-between d-flex h-100">
        <div class="cardA sh">
          <div class="cardContent text-left">

            <a href="#/document-upload#v-pills-profile">
              <div class="text-center">
                <img src="../../../assets/img/icon/icon-f-text2.png" class="img-fluid">
                <h5 class=" tit-car-001"> 문서 업로드해서 만들기

                </h5>
                <hr class="hr-001">
                <p class=" det-car-001">계약할 문서를 업로드하여 계약에 <br>필요한 내용을 입력하고 새 계약서를 만듭니다.
                </p>
                <div class=" text-right pillArrow">
                  <a href="#/document-upload#v-pills-profile"><span class="img-ico-cre-001 ico-cre-001"></span></a>
                </div>
              </div>
            </a>

          </div>
        </div>

        <div class="cardB sh">
          <div class="cardContent text-left">

            <a href="#/select-contract-type">
              <div class="text-center">
                <img src="../../../assets/img/icon/icon-f-new2.png" class="img-fluid">
                <h5 class=" tit-car-001"> 제공된 서식으로 만들기

                </h5>
                <hr class="hr-001">
                <p class=" det-car-001">계약서 종류를 선택하여 계약에 <br />필요한 내용을 입력하고 새 계약서를 만듭니다.
                </p>
                <div class=" text-right pillArrow">
                  <a href="#/select-contract-type"><span class="img-ico-cre-001 ico-cre-001"></span></a>
                </div>
              </div>
            </a>


          </div>
        </div>

        <div class="cardC sh">
          <div class="cardContent text-left">
            <a href="#/certification/upload">
              <div class="text-center">
                <img src="../../../assets/img/icon/icon-f-s.png" class="img-fluid">
                <h5 class=" tit-car-001"> 계약서 인증

                </h5>
                <hr class="hr-001">
                <p class=" det-car-001">오프라인으로 완료된 계약서 문서를<br />업로드하여 전자계약 인증을 완료합니다.
                </p>
                <div class=" text-right pillArrow">
                  <a href="#/certification/upload"><span
                          class="img-ico-cre-001 ico-cre-001"></span></a>
                </div>
              </div>
            </a>
            <!--            <div class="text-center">-->
            <!--              <img src="../../../assets/img/icon/icon-f-s.png" class="img-fluid">-->
            <!--              <h5 class=" tit-car-001"> 계약서 인증-->

            <!--              </h5>-->
            <!--              <hr class="hr-001">-->
            <!--              <p class=" det-car-001">오프라인으로 완료된 계약서 문서를<br />업로드하여 전자계약 인증을 완료합니다.-->
            <!--              </p>-->
            <!--              <p class=" det-car-001 red">추후 서비스 예정</p>-->
            <!--              -->
            <!--            </div>-->

            <!--
              <a href="#/certification/upload">
              <div class="text-center">
                <img src="../../../assets/img/icon/icon-f-s.png" class="img-fluid">
                <h5 class=" tit-car-001"> 계약서 인증

                </h5>
                <hr class="hr-001">
                <p class=" det-car-001">오프라인으로 완료된 계약서 문서를<br />업로드하여 전자계약 인증을 완료합니다.
                </p>
                <div class=" text-right pillArrow">
                  <a href="#/certification/upload"><span
                      class="img-ico-cre-001 ico-cre-001"></span></a>
                </div>
              </div>
            </a>
            -->


          </div>
        </div>

      </div>




      <!-- <div class="d-xl-none d-block card-deck">


        <div _ngcontent-oni-c85="" class="card m-0 mb-3 py-3 sh w-100">
          <div _ngcontent-oni-c85="" class="card-header text-center"
            style="border-bottom: none; background-color: #fff; padding-bottom: 28px;"><img _ngcontent-oni-c85=""
              src="../../../assets/img/icon/icon-f-text.png" class="img-fluid" style="margin-top: 49px;"></div>
          <div _ngcontent-oni-c85="" class="card-body text-center">
            <h5 _ngcontent-oni-c85="" class="card-title tit-car-001"> 템플릿 만들기
              <hr _ngcontent-oni-c85="" class="hr-001">
            </h5>
            <p _ngcontent-oni-c85="" class="card-text det-car-001">계약할 문서를 업로드하여 계약에 <br _ngcontent-oni-c85="">필요한 내용을
              입력하고
              새
              템플릿을 만듭니다.</p>
          </div>
          <div _ngcontent-oni-c85="" class="card-footer text-right"
            style="border-top: none; background-color: #fff; padding-top: 16;"><a _ngcontent-oni-c85=""
              href="#/document-upload#v-pills-profile"><span _ngcontent-oni-c85=""
                class="img-ico-cre-001 ico-cre-001"></span></a></div>
        </div>

        <div class="card py-3 m-0 mb-3 sh w-100">
          <div class="card-header text-center" style="border-bottom: none;background-color: #fff;padding-bottom: 28px;">
            <img src="../../../assets/img/icon/icon-f-new.png" class="img-fluid" style="margin-top: 49px;">
          </div>
          <div class="card-body  text-center">
            <h5 class="card-title tit-car-001">
              계약서 만들기
              <hr class="hr-001" />
            </h5>
            <p class="card-text det-car-001">계약서 종류를 선택하여 계약에 <br />필요한 내용을 입력하고 새 계약서를 만듭니다.</p>
          </div>
          <div class="card-footer text-right" style="border-top: none;background-color: #fff;padding-top:16;">
            <a href="#/select-contract-type">
              <span class="img-ico-cre-001 ico-cre-001"></span>
            </a>
          </div>
        </div>

        <div class="card mr-0 py-3 m-0 mb-3 sh w-100">
          <div class="card-header text-center" style="border-bottom: none;background-color: #fff;padding-bottom: 28px;">
            <img src="../../../assets/img/icon/icon-f-s.png" class="img-fluid" style="margin-top: 49px;">
          </div>

          <div class="card-body text-center">
            <h5 class="card-title tit-car-001">계약서 인증
              <hr class="hr-001" />
            </h5>
            <p class="card-text det-car-001">오프라인으로 완료된 계약서 문서를<br />업로드하여 전자계약 인증을 완료합니다.</p>
          </div>

          <div class="card-footer text-right" style="border-top: none;background-color: #fff;padding-top:16;">
            <a href="#/electronic-contract-authentication-completed">
              <span class="img-ico-cre-001 ico-cre-001"></span>
            </a>
          </div>
        </div>

      </div> -->

      <div class="d-none d-xl-block">
        <ul class="justify-content-between card-link-btnwrap d-flex mt-4">
          <li><a href="javascript:void(0)" (click)="howToLink('first')" class="btn btn-m-gray">문서 업로드해서 만들기 이용방법</a></li>
          <li><a href="javascript:void(0)" (click)="howToLink('second')" class="btn btn-m-gray">제공된 서식으로 만들기 이용방법</a></li>
          <li><a href="javascript:void(0)" (click)="howToLink('third')" class="btn btn-m-gray">계약서 인증 이용방법</a></li>
        </ul>
      </div>

    </section>
  </div>





  <div class="smallerUi">
    <div class="optionCards">

      <div class="opCards opCards1">

        <div class="cardImgBox d-flex justify-content-center align-items-center">
          <a href="#/document-upload#v-pills-profile">
            <span class="img-ico-cre-001 ico-cre-001"></span>
          </a>
          <img src="../../../assets/img/icon/icon-f-text2.png" class="img-fluid">
        </div>

        <div class="cardTxtBox">
          <div class="text-center">
            <h5 class="card-title tit-car-001">문서 업로드해서 만들기
              <hr class="hr-001" />
            </h5>
            <p class="card-text det-car-001">계약할 문서를 업로드하여 계약에<br />필요한 내용을 입력하고 새 계약서를 만듭니다.</p>
          </div>
        </div>

      </div>
      <div class="text-center mb-4"><a href="javascript:void(0)" (click)="howToLink('first')" class="btn btn-m-gray">문서 업로드해서 만들기 이용방법</a></div>

      <div class="opCards opCards1">

        <div class="cardImgBox d-flex justify-content-center align-items-center">
          <a href="#/select-contract-type">
            <span class="img-ico-cre-001 ico-cre-001"></span>
          </a>
          <img src="../../../assets/img/icon/icon-f-new2.png" class="img-fluid">
        </div>

        <div class="cardTxtBox">
          <div class="text-center">
            <h5 class="card-title tit-car-001">제공된 서식으로 만들기
              <hr class="hr-001" />
            </h5>
            <p class="card-text det-car-001">계약서 종류를 선택하여 계약에<br />필요한 내용을 입력하고 새 계약서를 만듭니다.</p>
          </div>
        </div>

      </div>
      <div class="text-center mb-4"><a href="javascript:void(0)" (click)="howToLink('second')" class="btn btn-m-gray">제공된 서식으로 만들기 이용방법</a></div>

      <div class="opCards opCards1">

        <!--
        <div class="cardImgBox d-flex justify-content-center align-items-center">
          <a href="#/electronic-contract-authentication-completed">
            <span class="img-ico-cre-001 ico-cre-001"></span>
          </a>
          <img src="../../../assets/img/icon/icon-f-s.png" class="img-fluid">
        </div>
        -->

        <div class="cardImgBox d-flex justify-content-center align-items-center">

          <img src="../../../assets/img/icon/icon-f-s.png" class="img-fluid">
        </div>

        <div class="cardTxtBox">
          <div class="text-center">
            <h5 class="card-title tit-car-001">계약서 인증
              <hr class="hr-001" />
            </h5>
            <!--<p class="card-text det-car-001">오프라인으로 완료된 계약서 문서를<br />업로드하여 전자계약 인증을 완료합니다.</p>-->
            <p class="card-text det-car-001 red">추후 서비스 예정</p>
          </div>
        </div>

      </div>
      <div class="text-center mb-4"><a href="javascript:void(0)" (click)="howToLink('third')" class="btn btn-m-gray">계약서 인증 이용방법</a></div>


    </div>
  </div>

  <app-submit-certificate></app-submit-certificate>
