<section class="h-100" style="background: #e9ecef">
  <section class="container h-100">


    <div class="h-100 row d-flex justify-content-center align-items-center" style="height: 100vh !important;">

      <div style="border-top: 2px solid #111; " class="d-flex bg-white justify-content-center flex-column">
        <form class="confirmForm">

          <div class="forgotTxthead text-center ">
            <h3><b>{{name}} 님, 반갑습니다.</b></h3>

            <p class="tex-mail">{{mail}}</p>

            <h5 class=" m-0 ">아래 주소로 이메일 인증 요청 메일을 보냈습니다.</h5>
            <h5>회원 등록을 완료하기 위해서 메일에 표시된</h5>
            <h5> <span class="tex-wid-red">“인증”</span> 버튼을 클릭해주세요.</h5>

          </div>



          <div class="confirmBTN form-group row d-flex mb-0 justify-content-center">
            <button (click)='clickFuction()' class="btn btn-big btn-crimson ml-0" style="width: auto !important;" type="button">
              이메일 인증 재요청
            </button>

          </div>
        </form>
      </div>


    </div>
  </section>
</section>

<app-loading></app-loading>