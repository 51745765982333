<section class="container-fluid p-0">


  <div class="d-flex ">


    <div class="ml-auto">
      <button class="btn btn-small-long btn-black importBTN" (click)="onImportTeams()"> 조직 import </button>
    </div>


  </div>

  <div class="bg-white organizationPanel" *ngIf="display.userSreach">

    <div [formGroup]=formNmDivsion>
      <label class="mb-0" for="formGroupExampleInput">부서 / 팀</label>
      <input type="text" readonly placeholder="부서/팀 명 " formControlName="divNm" class="form-control organInput">
    </div>


    <div style="background: #f7f7f8;" class=" border formsect">

      <div class="d-flex border-bottom mb-3 ">
        <div class="align-self-center">팀원</div>
        <div class=" ml-auto ">
        </div>
      </div>

      <form [formGroup]=formDataUser>
        <div class="mb-2 form-row">
          <div class="col-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="p-0 pr-1 input-group-text bg-transparent border-0"></span>
              </div>
              <input class="form-control" formControlName="userNm" type="text" placeholder="이름" />
            </div>
          </div>
          <div class="col-2">
            <input class="form-control" formControlName="userRank" type="text" placeholder="직급" />
          </div>
          <div class="col in3">
            <input class="form-control" formControlName="userMail" type="email" placeholder="이메일 " />
          </div>
          <div class="col in4">
            <div class="input-group">
              <input class="form-control" formControlName="userPhone" type="text" placeholder="휴대폰 번호" />
            </div>
          </div>
          <input class="form-control" type="hidden" formControlName="divId" />
          <input class="form-control" type="hidden" formControlName="staffId" />
          <input class="form-control" type="hidden" formControlName="userId" />
        </div>

      </form>
    </div>

    <div class="form-row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <button *ngIf='!selectData.divId.includes("<>")' (click)="onUpdateUser()" type="button"
          class="btn btn-middle-short btn-black organBTN">
          확인
        </button>
      </div>
    </div>

  </div>

  <div class="bg-white organizationPanel" *ngIf="display.manageTeam">

    <div>
      <label class="mb-0 barumBold organLabeltop" for="formGroupExampleInput ">부서/팀</label>
      <input type="text" readonly placeholder="부서/팀 명 " value="{{selectData.nM}}" class="form-control organInput">
    </div>


    <div style="background: #f7f7f8;" class=" border formsect">

      <div class="d-flex border-bottom mb-3 addOrgtxtBox ">
        <div class="align-self-center addOrgtxt">팀원 추가</div>
        <div class=" ml-auto ">
          <button *ngIf="(total.division == 0)" (click)="pushTeams()" class="btn btn-plus-shorter btn-white"><img
              src="../../../../assets/img//icon/plusShape.png"> 추가하기 </button>

        </div>
      </div>

      <form [formGroup]=formDataTeam *ngIf="pushData">
        <ng-container formArrayName="teams" *ngFor="let team of arrayTeams;let i = index">
          <div class="mb-2 form-row" [formGroupName]="i">
            <div class="col-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="p-0 pr-1 input-group-text bg-transparent border-0">{{(i+1)}}.</span>
                </div>
                <input class="form-control" formControlName="userNm" type="text" placeholder="이름" />
              </div>
            </div>
            <div class="col-2">
              <input class="form-control" formControlName="userRank" type="text" placeholder="직급" />
            </div>
            <div class="col in3">
              <input class="form-control" formControlName="userMail" type="email" placeholder="이메일 " />
            </div>
            <input class="form-control" type="hidden" formControlName="divId" />
            <input class="form-control" type="hidden" formControlName="staffId" />
            <input class="form-control" type="hidden" formControlName="userId" />
            <div class="col in4">
              <div class="input-group">
                <input class="form-control" formControlName="userPhone" maxlength="11" type="text"
                  placeholder="휴대폰 번호" />
                <div class="input-group-append">
                  <span class="p-0 pl-1 input-group-text bg-transparent border-0" style="cursor: pointer"
                    (click)="removeUserTeams(team.staffId,team.divId,i)"> <img class="form-control"
                      src="../../../../assets/img/icon/icon-remove-cr.png" class="orgX img-fluid"
                      style="cursor: pointer;"></span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

      </form>
    </div>

    <div class="form-row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <button *ngIf='checkDivisionId(selectData.divId)' (click)="onCreateTeams()" type="button"
          class="btn btn-middle-short btn-black organBTN">
          확인
        </button>
      </div>
    </div>

  </div>

</section>

<app-loading></app-loading>

<div class="bg-modal" *ngIf="display.manageDepartment">
  <!-- Modal -->
  <div class="modal fade show division-modal" style="display: block;padding-right: 17px;" id="exampleModal"
    tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="onCloseEvent($event)">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">

        <div class="modal-header rounded-0 pb-0 border-0 px-4 pt-4" style="background: #ffffff;color: #000000;">

          <div class="border-bottom border-dark d-flex w-100 pb-3 ">
            <h5 class="modal-title font-weight-light" id="exampleModalLabel">하위 부서 / 팀 관리</h5>
            <a type="button" style="color: #000000;" class="close" (click)="onClose()" data-dismiss="modal"
              aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>


        </div>





        <div class="modal-body rounded-0 p-4" style="background: #ffffff;color: #000000; overflow: hidden;">
          <div class="row">
            <div class="col-12">
              <div class="bg-white organizationPanel" *ngIf="display.manageDepartment">

                <form [formGroup]=formDataDivisions id="formData" *ngIf="pushData">
                  <div class="mb-3">
                    <label class="mb-0 barumBold organLabeltop" for="formGroupExampleInput ">상위 부서/팀 명</label>
                    <input readonly formControlName="orgNm" type="text" placeholder="부서/팀 명 " value="{{selectData.nM}}"
                      class="form-control organInput">
                  </div>


                  <div style="background: #f7f7f8;" class="p-md-4 border formsect">

                    <div class="d-flex border-bottom mb-3 ">
                      <div class="align-self-center">부서/팀 추가</div>
                      <div class=" ml-auto ">

                        <button (click)="pushDivision('','')"
                          class="btn btn-plus-shorter btn-white">
                          <img src="../../../../assets/img//icon/plusShape.png"> 추가하기
                        </button>

                      </div>
                    </div>

                    <ng-container formArrayName="divisions" *ngFor="let division of arrayDivisions;let i = index">
                      <div class="mb-2 form-row" [formGroupName]="i">
                        <div class="col-12">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="p-0 pr-1 input-group-text bg-transparent border-0">{{(i+1)}}.</span>
                            </div>

                            <input class="form-control" type="hidden" formControlName="divId" />
                            <input class="form-control" type="text" formControlName="divNm" placeholder="하위 부서/팀 명" />
                            <div class="input-group-append">
                              <span class="p-0 pl-1 input-group-text bg-transparent border-0" style="cursor: pointer"
                                (click)="removeDivision(division.divId,i)"> <img class="form-control"
                                  src="../../../../assets/img/icon/icon-remove-cr.png" class="orgX img-fluid"
                                  style="cursor: pointer;"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <div class="form-row">
                      <div class="col-12 d-flex align-items-center justify-content-center">

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="w-100 text-center pt-3">
              <button type="button" class="btn btn-middle-short btn-white mx-1" (click)="onClose()">
                취소
              </button>
              <button (click)="onCreateDivision()" type="button" class="btn btn-middle-short btn-black mx-1">
                저장
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>