import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {AlertService} from "../../manage/component/modal/alert/alert.service";
import {CertificationService} from "../../manage/component/certification/certification.service";
import {LoginService} from "../login/login.service";
import {EditorPdfService} from "../../manage/component/editor-pdf/editor-pdf.service";
import {DigitalsignatureService} from "../../manage/service/digitalSignature/digitalsignature.service";
import {LoadingProgressService} from "../../manage/shared/loading-progress/loading-progress.service";
import {TemplateService} from "../../manage/service/template/template.service";
import {BlockCertService} from "../../manage/service/blockCert/block-cert.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {CommunityService} from "../../manage/service/community/community.service";
import {CommunityUploadComponent} from "../community-upload/community-upload.component";

@Component({
  selector: 'app-community-upload-form',
  templateUrl: './community-upload-form.component.html',
  styleUrls: ['./community-upload-form.component.scss']
})
export class CommunityUploadFormComponent implements OnInit {


  uploadForm: FormGroup;
  uploadProgress = 0;
  upload: boolean = true;
  constructor(
      private router: Router,
      private alertService: AlertService,
      private formBuilder: FormBuilder,
      private certificationService: CertificationService,
      private loginService: LoginService,
      private editorPdfService: EditorPdfService,
      private digitalsignatureService: DigitalsignatureService,
      private loadingProgressService: LoadingProgressService,
      private templateService: TemplateService,
      private communityService: CommunityService,
      private communityUpload: CommunityUploadComponent
  ) { }

  ngOnInit(): void {
    this.initDropZoneArea();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }


  onFileSelected(event) {
    let fileSize: number = 0;
    let file = event.target.files[0];
    // this.uploadfile = file;
    fileSize = file.size;
    // Allowing file type
    var allowedExtensions = /(\.pdf)$/i;

    // console.log(allowedExtensions.exec(file.name), file.name)
    if (!allowedExtensions.exec(file.name)) {
      this.alertService.open(
          "지원하지 않는 파일 type 입니다.",
          "",
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
      );
    } else {
      if (fileSize <= 1048576) {
        const $pdf: any = document.querySelector('#files');

        if (typeof FileReader !== 'undefined') {
          const reader = new FileReader;

          reader.onload = (e: any) => {
            this.certificationService.setFilePDF(e.target.result);
            // this.certificationService.setFilePDF(file);
            this.certificationService.setNameFile(file.name);
            // this.router.navigate(['certification/view']);
          };
          reader.readAsArrayBuffer($pdf.files[0]);
        }
        this.uploadForm.get('profile').setValue(file);
        this.uploadCommunity();

      } else {
        this.alertService.open(
            "문서 크기가 너무 큽니다.",
            "문서 크기는 1MB를 초과 할 수 없습니다.",
            {
              func: () => {
                this.alertService.close();
              }
            },
            {
              func: () => {
                this.alertService.close();
              }
            }
        );
      }
    }
    // if (typeof (FileReader) !== 'undefined') {
    //   const reader = new FileReader();

    //   reader.onload = (e: any) => {
    //     console.log(e.target);
    //     // this.uploadfile = e.target.result;
    //     // this.selectFilePDF.emit(e.target.result);
    //   };
    //   this.uploadfile = $img.files[0];
    //   // this.uploadForm.get('profile').setValue($img.files[0]);
    //   reader.readAsArrayBuffer($img.files[0]);
    //   this.createTemplate();
    // }
  }

  // Drop Zone Initial
  initDropZoneArea() {
    const dropArea = document.getElementById('drop-area');
    console.log(dropArea);
    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, (e) => {
        // console.log(e);
        e.preventDefault();
        e.stopPropagation();
      }, false)
    })

    dropArea.addEventListener('drop', (e) => {
      console.log(e);
      let dt = e.dataTransfer
      let files = dt.files;
      let ev = {
        target: {
          files
        }
      }
      this.onFileSelected(ev);
      // console.log(ev);
    }, false);
  }

  uploadCommunity() {
    this.upload = false;
    this.loadingProgressService.openLoading();
    const formData = new FormData();
    formData.append('files', this.uploadForm.get('profile').value);
    formData.append('userId', localStorage.getItem('userId'));
    this.digitalsignatureService.setSignature(formData).subscribe(rs => {
          // console.log(event.body);
          if (rs.type === HttpEventType.UploadProgress) {
            let progress = Math.round(100 * rs.loaded / rs.total);
            this.loadingProgressService.setProgress(progress);
            this.loadingProgressService.setTextProgress(progress + '%');
          } else if (rs instanceof HttpResponse) {
            if (rs.body.status) {
              // this.loadingProgressService.setTextProgress("CONVERTER FILE TO PDF FILE");
              this.communityService.setUploadStatus(true);
              console.log(this.communityService.getUploadStatus());
              this.loadingProgressService.closeLoading();
              this.communityUpload.validators.fileId = rs.body.data.fileId;
              this.communityUpload.validators.fileNm = this.certificationService.getNameFile();
              this.communityUpload.validators.thumbNailUrl = rs.body.data.thumbnailBase64;
              this.communityUpload.validators.pageNum = rs.body.data.pageNum;
              this.communityUpload.ngAfterViewInit();
            } else {
              this.upload = true;
              this.loadingProgressService.closeLoading();
              this.loadingProgressService.setProgress(0);
              this.loadingProgressService.setTextProgress("0%");
            }
          }
        },
        err => {
          this.upload = true;
          this.loadingProgressService.closeLoading();
          this.loadingProgressService.setProgress(0);
          this.loadingProgressService.setTextProgress("0%");
        });
  }
}
