import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient
  ) { }

  pathGetDivisionsTree: string = API_URL.getDivisionsTree;
  pathGetOrganization: string = API_URL.getOrganization;
  pathCreateDivision: string = API_URL.createDivision;
  pathGetDivisions: string = API_URL.getDivisions;
  pathGetUserDivisions: string = API_URL.getUserDivisions;
  pathCreateStaffTems: string = API_URL.createStaffTems;
  pathGetCompany: string = API_URL.getCompany;
  pathDeleteDivisionById: string = API_URL.deleteDivisionByDivId;
  pathUpdateStaffUseYnToN: string = API_URL.updateStaffUseYnToN;
  pathCreateStaffTeamsExcel: string = API_URL.createStaffTeamsExcel;
  pathSearchUserInOrgs: string = API_URL.searchUserInOrgs;
  pathGetUserSearch: string = API_URL.getUserSearch;

  getDivisionsTree() {
    const param = {
      data: []
    }
    return this.http.post(this.pathGetDivisionsTree, param);
  }


  getOrganization() {
    return this.http.post(this.pathGetOrganization, {});
  }

  createDivision(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathCreateDivision, params);
  }

  createStaffTems(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathCreateStaffTems, params);
  }

  getCompany() {
    return this.http.post(this.pathGetCompany, {});
  }

  getDivisions(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathGetDivisions, params);
  }

  getUserDivisions(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathGetUserDivisions, params);
  }

  deleteDivision(divId: string) {
    let params = {
      data: divId
    }

    return this.http.post(this.pathDeleteDivisionById, params);
  }

  updateStaffUseYnToN(divId: string, staffId: string) {
    let params = {
      data: {
        staffId: staffId,
        divId: divId
      }
    }

    return this.http.post(this.pathUpdateStaffUseYnToN, params);
  }

  createStaffTeamsExcel(data: any) {
    let params = {
      data: {
        userStaff: data
      }
    }

    return this.http.post(this.pathCreateStaffTeamsExcel, params);
  }

  seachUserInOrgs(data: string) {
    let params = {
      data: {
        searchText: data
      }
    }

    return this.http.post(this.pathSearchUserInOrgs, params);
  }

  getUserSearch(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathGetUserSearch, params);
  }
}
