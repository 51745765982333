<div class="row-hl btnnav justify-content-center d-flex">
    <div class="w-100">
        <h2 class="text-center centerSubject w-100">문의하기

        </h2>

        <div class="notict-list-box mx-auto ">

            <form class="text-center mx-auto" style="width: 100%; max-width: 500px; " [formGroup]=formContractInquire>


                <div class="inquire">


                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="qaaNm">이름</label>
                                <input type="text" class="form-control {{validators.qaaNm}}" pattern="^-?(\D+)?$"
                                    formControlName=qaaNm id="qaaNm" placeholder="이름 입력">
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="qaaMail">이메일</label>
                                <input type="email" class="form-control {{validators.qaaMail}}" formControlName=qaaMail
                                    id="qaaMail" placeholder="이메일 입력">

                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="qaaPhone">휴대폰 번호</label>
                                <input type="text" pattern="^-?([0-9]\d*)?$" minlength="10" maxlength="11"class="form-control {{validators.qaaPhone}}" formControlName=qaaPhone
                                    id="qaaPhone" placeholder="휴대폰 번호">
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="inquireTxtarea">문의내용</label>
                                <textarea class="form-control {{validators.qaaContent}}" formControlName=qaaContent
                                    style="max-height: 125px; min-height: 125px;"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 px-0 checkCon  text-left">

                    <div class="pinkCheck form-group form-check p-0 m-0" style="line-height: 1;"><label
                            class="con-che-001"><input type="checkbox" formControlName=agreeall class="form-check-input"
                                id="agreeall"><span class="che-mar-001"></span></label>개인정보 수집
                        및 이용에 동의합니다.
                    </div>
                </div>
                <div class="form-group mb-0 row d-flex justify-content-center">
                    <div class="col-12">
                        <button (click)="onSend()" type="button" class="btn btn-big crimsonbg" [disabled]="!formContractInquire.valid">
                            문의하기
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
  
<app-alert-shared></app-alert-shared>
<app-loading></app-loading>