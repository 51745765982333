import { Component, OnInit } from '@angular/core';
import { AlertService } from './alert.service'
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  show: boolean;
  htmlBody: string;
  state: string;

  title: string;
  subTitle: string;
  constructor(
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.title = this.alertService.text.title;
    this.subTitle = this.alertService.text.subTitle;
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    this.title = this.alertService.text.title;
    this.subTitle = this.alertService.text.subTitle;

    if (this.alertService.change.show) {
      this.show = this.alertService.show;
      this.alertService.setChange('show', false);
    }
    if (this.alertService.change.html) {
      this.htmlBody = this.alertService.htmlBody;
      this.alertService.setChange('html', false);
    }
    if (this.alertService.change.state) {
      this.state = this.alertService.getState();
      this.alertService.setChange('state', false);
    }
  }

  closeModal() {
    this.onClickBtn('btnCancel');
  }

  exportDataTable() {
    this.alertService.close();
  }

  onClickBtn(btn: any) {
    this.alertService[btn].func();
  }

  closeModalBackground($event) {
    console.log($event.target.className);
    if ($event.target.className.includes("popUpType1")) {
      this.onClickBtn('btnCancel');
    }
  }

}
