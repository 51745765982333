<section style="background:#edf0f1; " class="px-3  py-md-0 px-md-5" id="serviceEl">

  <div class="standardFront" >
    <div class="container2">
      <div class="row">
        <div class="col-md-12">
          <div class="card bg-transparent border-0 text-center" >
            <div class="card-header spacing p-0 bg-transparent border-0">

              <div class="promoCTA d-flex justify-content-center">
                <button [ngClass]="{'rounded-0 btn barumBold': true, 'btn-outline-danger crimson-outlined-white': curTab === 'tab2', 'mr-md-2  crimsonbg': curTab === 'tab1'}" type="button" (click)="changeTab('tab1')">전송 건수</button>
                <!-- <button [ngClass]="{'rounded-0  btn barumBold': true, 'btn-outline-danger crimson-outlined-white': curTab === 'tab1', 'ml-2 crimsonbg': curTab === 'tab2'}" type="button" (click)="changeTab('tab2')">요금제</button> -->
              </div>
              <ng-container *ngIf="curTab === 'tab1'">
                <h4 class="desbtntxxt d-none d-md-block card-title px-md-5 py-4 mb-4  "> 원하는 전송 건수만큼 결제하세요. 전송 건수를 입력후, 서비스 신청하기를 클릭해주세요.
                  <span class="desbtntxxt2 d-none d-md-block card-title px-md-1 py-1 mb-1  ">
                    *10만원 이상 상품 결제시 별도문의 부탁 드립니다, (메일문의: support@berith.co)
                  </span>
                </h4>

                <!-- larger txt -->

                <h4 class="mobbtntxxt d-block d-md-none card-title px-md-5">원하는 전송 건수만큼 결제하세요. <br/>전송 건수를 입력 후, 서비스 신청하기를 클릭해주세요.
                  <br/>
                  <span class="mobbtntxxt2 card-title px-md-1">
                    *10만원 이상 상품 결제시 별도문의 부탁 드립니다, (메일문의: support@berith.co)
                  </span>
                </h4>
                <!-- smaller txt -->
              </ng-container>
              <ng-container *ngIf="curTab === 'tab2'">
                <h4 class="desbtntxxt d-none d-md-block card-title px-md-5 py-4 mb-4  "> 상품별 할인율을 확인하셔서
                  원하는 결제 상품을 선택해주세요.
                  <span class="desbtntxxt2 d-none d-md-block card-title px-md-1 py-1 mb-1">
                    *10만원 이상 상품 결제시 별도문의 부탁 드립니다, (메일문의: support@berith.co)
                  </span>
                </h4>
                <!-- larger txt -->

                <h4 class="mobbtntxxt d-block d-md-none card-title px-md-5">상품별 할인율을 확인하셔서
                  원하는 결제 상품을 선택해주세요.
                  <br/>
                  <span class="mobbtntxxt2 card-title px-md-1">
                    *10만원 이상 상품 결제시 별도문의 부탁 드립니다, (메일문의: support@berith.co)
                  </span>
                </h4>
                <!-- smaller txt -->
              </ng-container>
            </div>

            <div class="card-body pt-0 px-0 promoBody">
              <div class="card-text">
                <!--전송 건수 탭 클릭 시 [s]-->
                <form class="justify-content-center" [formGroup]="formPay">
                  <div class="justify-content-center field-container row p-5" *ngIf="curTab === 'tab1'">
                    <div class="promofield">
                      <label for="singleField "><span class="NanumGothicBoldFont singleFieldLable">전송건수</span></label>
                      <div class="input-group w-75 w-md-25 singleField ">
                        <input name="singleField" type="number" step="10" class="form-control rounded-0 "
                          formControlName="purchaseId" value="0" min="0" placeholder="{{itm_price_value}}">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-transparent border-0 p-0 pl-1 NanumGothicBoldFont">건</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--전송 건수 탭 클릭 시 [e]-->

                  <!--요금제 탭 클릭 시 [s]-->
                  <div class="plan-wrap" *ngIf="curTab === 'tab2'">
                    <div class="plan-wrap-select">
                      <select class="form-control ng-pristine ng-valid ng-touched" formControlName="payType">
                        <option value="person" [selected]="formPay.value.payType === 'person'">개인</option>
                        <!-- <option value="company" [selected]="formPay.value.payType === 'company'">기업</option> -->
                      </select>
                    </div>

                    <div class="scroll" *ngIf="formPay.value.payType === 'person'">
                      <table class="payment-list"> <!--개인 테이블[s] -->
                        <colgroup>
                            <col width="*"><col width="*"><col width="*"><col width="*"><col width="*"><col width="100px">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>결제상품</th>
                                <th>계약건수</th>
                                <th>할인율</th>
                                <th>할인단가</th>
                                <th>총결제금액</th>
                                <th>요금제 선택</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paytypeList.person">
                              <td>{{item.name}}</td>
                              <td class="text-r">{{item.quantity}}</td>
                              <td class="text-r">{{item.dc}}%</td>
                              <td class="text-r">{{item.price | addComma}}</td>
                              <td class="text-r">{{item.totalAmount | addComma}}</td>
                              <td class="text-c">
                                  <span *ngIf="item.totalAmount < 100000" [ngClass]="{'btn-small-select': true, 'active': item.selected}" (click)="selectItem(item, 'B')"><a href="javascript:void(0)" [ngClass]="{'active': item.selected}">선택</a></span>
                                  <span *ngIf="item.totalAmount >= 100000" >별도문의</span>
                              </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="scroll" *ngIf="formPay.value.payType === 'company'">
                      <table class="payment-list"> <!--기업 테이블 [s]-->
                      <colgroup>
                          <col width="*"><col width="*"><col width="*"><col width="*"><col width="*"><col width="100px">
                      </colgroup>
                      <thead>
                          <tr>
                              <th>결제상품</th>
                              <th>계약건수</th>
                              <th>할인단가</th>
                              <th>월간총결제금액(할인율)</th>
                              <th>연간총결제금액(할인율)</th>
                              <th>요금제 선택</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of paytypeList.company">
                            <td>{{item.name}}</td>
                            <ng-container *ngIf="!item.text || item.text === ''">
                              <td class="text-r">{{item.quantity}}</td>
                              <td class="text-r">{{item.dc}}</td>
                              <td class="text-r">{{item.monthPay}}</td>
                              <td class="text-r">{{item.yearPay}}</td>
                            </ng-container>
                            <td class="text-c" colspan="4" *ngIf="item.text">
                              {{item.text}}
                            </td>
                            <td class="text-c">
                                <span *ngIf="item.totalAmount < 100000" [ngClass]="{'btn-small-select': true, 'active': item.selected}" (click)="selectItem(item, 'B')"><a href="javascript:void(0)" [ngClass]="{'active': item.selected}">선택</a></span>
                                <span *ngIf="item.totalAmount >= 100000">별도문의</span>
                            </td>
                          </tr>
                      </tbody>
                      </table>
                    </div>
                  </div>
                  <!--요금제 탭 클릭 시 [e]-->


                  <!-- Large area -->
                  <div *ngIf="selected" class="d-none formTXT my-5 d-md-flex align-items-center justify-content-center">
                    <div class="circle ">
                      <div class="circle__inner">
                        <div class="circle__wrapper">

                          <div class="circle__content numberfonts">{{selected.dc ? selected.dc : 0}}<span
                              class="circleSpan">%</span>
                            <fa-icon [icon]="faCaretDown" class="fa-1x icon-btn"></fa-icon>
                          </div>

                        </div>
                      </div>
                    </div>
                    <h4 class=" mb-0 promotxt "><span class="numberfonts">{{(formPay.value.purchaseId * selected.price) | addComma}}</span><span
                        class="NanumGothicLightFont">원</span><span class="smallertag NanumGothicRegularFont ">(건당
                        {{selected.price | addComma}}원)</span>
                    </h4>
                  </div>
                  <!-- Large area end -->

                  <!-- Mobile Area  -->
                  <div *ngIf="selected" class="d-flex flex-column formTXT d-md-none align-items-center justify-content-center">
                    <div class="circle mobileCircleSpacing">
                      <div class="circle__inner">
                        <div class="circle__wrapper">
                          <div class="circle__content numberfonts">{{selected.dc ? selected.dc : 0}}<span class="circleSpan">%</span>
                            <fa-icon [icon]="faCaretDown" class="fa-1x icon-btn"></fa-icon>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h4 style="font-size: 2.5rem; " class="mx-3 mb-0 numberfonts">{{(formPay.value.purchaseId * selected.price) | addComma}}<span
                        class="NanumGothicLightFont">원</span>
                    </h4>
                    <span style="font-size: 1rem; " class="NanumGothicRegularFont">(건당 {{selected.price | addComma}}원)</span>
                  </div>
                  <!-- Mobile Area end -->
                    <button *ngIf="userId != '38066' && userId != '26275'" type="submit" class=" btn promosubmit  crimsonbg" [disabled]="true" (click)="onOpen()">서비스
                        신청하기</button>
                    <button *ngIf="userId == '38066' || userId == '26275'" type="submit" class=" btn promosubmit  crimsonbg" [disabled]="false" (click)="onOpen()">서비스
                        신청하기</button>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="oddFront" > <!--size : m -->
    <div class="container">

      <div class="row">
        <div class="col-12">
          <div class="card bg-transparent border-0 text-center">
            <div class="card-header spacing p-0 bg-transparent border-0">

              <div class="promoCTA d-flex justify-content-center">
                <button [ngClass]="{'rounded-0 btn barumBold': true, 'btn-outline-danger crimson-outlined-white': curTab === 'tab2', 'mr-md-2 crimsonbg': curTab === 'tab1'}" type="button" (click)="changeTab('tab1')">전송 건수</button>
                <!-- <button [ngClass]="{'rounded-0  btn barumBold': true, 'btn-outline-danger crimson-outlined-white': curTab === 'tab1', 'ml-2 crimsonbg': curTab === 'tab2'}" type="button" (click)="changeTab('tab2')">요금제</button> -->
              </div>
            <ng-container *ngIf="curTab === 'tab1'">
              <h4 class="mobbtntxxt card-title"> 원하는 전송 건수만큼
                결제하세요. 전송 건수를
                입력 후,
                서비스 신청하기를 클릭해주세요.
                <br/>
                <span class="mobbtntxxt2 card-title">
                  *10만원 이상 상품 결제시 별도문의 부탁 드립니다, (메일문의: support@berith.co)
                </span>
              </h4>
            </ng-container>
            <ng-container *ngIf="curTab === 'tab2'">

              <h4 class="mobbtntxxt card-title"> 상품별 할인율을 확인하셔서
                원하는 결제 상품을 선택해주세요.
                <br/>
                <span class="mobbtntxxt2 card-title">
                  *10만원 이상 상품 결제시 별도문의 부탁 드립니다, (메일문의: support@berith.co)
                </span>
              </h4>
            </ng-container>

              <!-- smaller txt -->
            </div>

            <div class="card-body pt-0 px-0 promoBody">
              <div class="card-text">
               <!--전송 건수 탭 클릭 시 [s]-->
                <form class="justify-content-center" [formGroup]="formPay">
                  <div class="justify-content-center field-container row p-5" *ngIf="curTab === 'tab1'">
                    <div class="promofield">
                      <label for="singleField "><span class="NanumGothicBoldFont singleFieldLable ">전송건수</span></label>
                      <div class="input-group w-75 w-md-25 singleField ">
                        <input name="singleField" type="number" class="form-control rounded-0 "
                          formControlName="purchaseId" value="0" placeholder="{{itm_price_value}}">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-transparent border-0 p-0 pl-1 NanumGothicBoldFont">건</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--전송 건수 탭 클릭 시 [e]-->

                  <!--요금제 탭 클릭 시 [s]-->
                  <div class="plan-wrap" *ngIf="curTab === 'tab2'">
                    <div class="plan-wrap-select">
                      <select class="form-control ng-pristine ng-valid ng-touched" formControlName="payType">
                        <option value="person" [selected]="formPay.value.payType === 'person'">개인</option>
                        <!-- <option value="company" [selected]="formPay.value.payType === 'company'">기업</option> -->
                      </select>
                    </div>

                    <div class="scroll" *ngIf="formPay.value.payType === 'person'">
                      <table class="payment-list"> <!--개인 테이블[s] -->
                        <colgroup>
                            <col width="*"><col width="*"><col width="*"><col width="*"><col width="*"><col width="100px">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>결제상품</th>
                                <th>계약건수</th>
                                <th>할인율</th>
                                <th>할인단가</th>
                                <th>총결제금액</th>
                                <th>요금제 선택</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paytypeList.person">
                                <td>{{item.name}}</td>
                                <td class="text-r">{{item.quantity}}</td>
                                <td class="text-r">{{item.dc}}%</td>
                                <td class="text-r">{{item.price | addComma}}</td>
                                <td class="text-r">{{item.totalAmount | addComma}}</td>
                                <td class="text-c">
                                    <span *ngIf="item.totalAmount < 100000" [ngClass]="{'btn-small-select': true, 'active': item.selected}" (click)="selectItem(item, 'B')"><a href="javascript:void(0)" [ngClass]="{'active': item.selected}">선택</a></span>
                                    <span *ngIf="item.totalAmount >= 100000">별도문의</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </div>

                  <div class="scroll" *ngIf="formPay.value.payType ==='company'">
                    <table class="payment-list"> <!--기업 테이블 [s]-->
                      <colgroup>
                          <col width="*"><col width="*"><col width="*"><col width="*"><col width="*"><col width="100px">
                      </colgroup>
                      <thead>
                          <tr>
                              <th>결제상품</th>
                              <th>계약건수</th>
                              <th>할인단가</th>
                              <th>월간총결제금액(할인율)</th>
                              <th>연간총결제금액(할인율)</th>
                              <th>요금제 선택</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of paytypeList.company">
                            <td>{{item.name}}</td>
                            <ng-container *ngIf="!item.text || item.text === ''">
                              <td class="text-r">{{item.quantity}}</td>
                              <td class="text-r">{{item.dc}}</td>
                              <td class="text-r">{{item.monthPay}}</td>
                              <td class="text-r">{{item.yearPay}}</td>
                            </ng-container>
                            <td class="text-c" colspan="4" *ngIf="item.text">
                              {{item.text}}
                            </td>
                            <td class="text-c">
                                <span *ngIf="item.totalAmount < 100000" [ngClass]="{'btn-small-select': true, 'active': item.selected}" (click)="selectItem(item, 'B')"><a href="javascript:void(0)" [ngClass]="{'active': item.selected}">선택</a></span>
                                <span *ngIf="item.totalAmount >= 100000">별도문의</span>
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!--요금제 탭 클릭 시 [e]-->


                  <!-- Mobile Area  -->
                  <div *ngIf="selected" class="d-flex flex-column formTXT  align-items-center justify-content-center">
                    <div class="circle ">
                      <div class="circle__inner">
                        <div class="circle__wrapper">

                          <div class="circle__content numberfonts">{{selected.dc ? selected.dc : 0}}<span class="circleSpan">%</span>
                            <fa-icon [icon]="faCaretDown" class="fa-1x icon-btn"></fa-icon>
                          </div>

                        </div>
                      </div>
                    </div>

                    <h4 class="mx-3 mb-0 numberfonts">{{(formPay.value.purchaseId * selected.price) | addComma}}<span class="NanumGothicLightFont">원</span>
                    </h4>
                    <span class="NanumGothicRegularFont">(건당 {{selected.price | addComma}}원)</span>
                  </div>
                  <!-- Mobile Area end -->

                    <button *ngIf="userId != '38066'" type="submit" class=" btn promosubmit  crimsonbg" [disabled]="true" (click)="onOpen()">서비스
                        신청하기</button>
                    <button *ngIf="userId == '38066'" type="submit" class=" btn promosubmit  crimsonbg" [disabled]="false" (click)="onOpen()">서비스
                        신청하기</button>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
<app-payment-apply></app-payment-apply>
<app-modal-notice></app-modal-notice>
<app-alert></app-alert>
<app-loading></app-loading>
