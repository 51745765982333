<div class="container-lg ml-lg-0 mr-lg-auto p-0 m" style="background: #e2e6eb;">
  <div class="largerUi">
    <div class="row formColumns">
      <!-- Col-Break -->
      <div class="col-lg-12">
        <label class="flex-grow-1 bd-highlight tit-set-001 barumBold">메일 템플릿 등록</label>
        <form [formGroup]=formCreateTemplateMail>
          <!-- Form C1 -->
          <!-- Form C2 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">계약서 명</span>
            </div>

            <select formControlName='tempmType' style="border-radius: .25rem;" class="form-control pad-lef-10" placeholder="프라 타멜">
              <option value="N">-</option>
              <option *ngFor="let tempmType of tempmTypes" value="{{tempmType.code}}">{{tempmType.codeNm}}</option>
            </select>
          </div>
          <!-- Form C3 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">메일 제목</span>
            </div>
            <input formControlName='tempmTitle' style="border-radius: .25rem;" type="text" name="tempmTitle" placeholder="메일 제목 입력 "
              class="form-control pad-inp" />
          </div>
          <!-- Form C4 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text  mailInputC">메일 내용</span>
            </div>
            <textarea formControlName='tempmContent' style="resize: none;border-radius: .25rem;" row="5" name="tempmContent" placeholder="메일 내용을 입력해 주세요."
              class="form-control pad-inp"></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="smallerUi">
    <div class="mailManage">
      <div class="row formColumns">

        <!-- Col-Break -->
        <div class="col-lg-12">
          <label class="flex-grow-1 bd-highlight tit-set-001 barumBold">메일 템플릿 등록</label>
          <form class="mailForm1" [formGroup]=formCreateTemplateMail>
            <!-- Form C1 -->
            <!-- Form C2 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">계약서 명</span>
              </div>

              <select formControlName='tempmType' class="form-control pad-lef-10" placeholder="계약서 명 입력">
                <option value="N">-</option>
                <option *ngFor="let tempmType of tempmTypes" value="{{tempmType.code}}">{{tempmType.codeNm}}</option>
              </select>
            </div>
            <!-- Form C3 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">메일 제목</span>
              </div>
              <input formControlName='tempmTitle' type="text" name="tempmTitle" placeholder="메일 제목 입력  "
                class="form-control" />
            </div>
            <!-- Form C4 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text  mailInputC">메일 내용</span>
              </div>

              <textarea formControlName='tempmContent' row="5" name="tempmContent" placeholder="메일 내용을 입력해 주세요."
                class="form-control"></textarea>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="m-0 p-0 col-12 d-flex justify-content-end" *ngIf="false">
    <button disabled type="button" class="btn mailBtnMarg btn-black btn-middle-short barumReg">수정</button>
  </div>

  <div class="my-0 mt-2 col-12 d-flex justify-content-center">
    <button [disabled]='disabledBtn' (click)="onSubmit()" class="mailRed btn btn-big crimsonbg">
      등록
    </button>
  </div>

</div>



<!-- New area -->
