import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header-damo',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navbar_title: string = '';

  constructor(
    private headerService: HeaderService,
  ) { }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    this.navbar_title = this.headerService.getTitleNavber();
  }
}
