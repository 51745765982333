import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EditorPdfService {

  templ_id: string;
  templ_nm: string;
  eCert_id: string;
  eCert_nm: string;
  templStatus: string;
  contorType: string;
  mode: string;
  state: string;
  mark: any;
  markSign: any;
  colorSign: any = [];
  codeBook: {
    MARK_CONTOR_TYPE: {
      code: string,
      codeDc: string,
      codeGrpId: string,
      codeNm: string
    }[]
  } = {
    MARK_CONTOR_TYPE: []
  };

  download: boolean = false;

  markColor: string = '';
  signaGroup: string = '';
  deleteMark: {
    markId: string
  }[] = [];

  contor: {
    contId: string,
    contorId: string,
  }

  constructor() { }

  clear() {
    this.deleteMark = [];
  }

  addDeleteMark(mark: { markId: string }) {
    this.deleteMark.push(mark);
  }

  getDeleteMark() {
    return this.deleteMark;
  }

  setCodeBookMarkContor(markContor: any) {
    this.codeBook.MARK_CONTOR_TYPE = markContor;
  }

  getCodeBookMarkContor() {
    return this.codeBook.MARK_CONTOR_TYPE;
  }

  setStatusTemplate(status: string): void {
    this.templStatus = status;
  }

  getStatusTemplate(): string {
    return this.templStatus;
  }

  setMarkColor(markColor: string): void {
    this.markColor = markColor;
  }

  getMarkColor(): string {
    return this.markColor;
  }

  setTemplateId(templ_id: string) {
    this.templ_id = templ_id;
  }

  setBlockCertId(eCert_id: string) {
    this.eCert_id = eCert_id;
  }

  setSignaGroup(signaGroup: string): void {
    this.signaGroup = signaGroup;
  }

  getSignaGroup(): string {
    return this.signaGroup;
  }

  setContor(contor: {
    contId: string,
    contorId: string,
  }) {
    this.contor = contor;
  }

  getContor(): {
    contId: string,
    contorId: string,
  } {
    return this.contor;
  }
  getTemplateAID() {
    return this.templ_id;
  }

  getBlockCertId() {
    return this.eCert_id;
  }

  setEdteMode(mode: string) {
    this.mode = mode;
  }

  getEdteMode() {
    return this.mode;
  }

  setTemplNm(templ_nm: string) {
    this.templ_nm = templ_nm;
  }

  getTemplNm() {
    return this.templ_nm;
  }

  setBlockCertNm(eCert_nm: string) {
    this.eCert_nm = eCert_nm;
  }

  getBlockCertNm() {
    return this.eCert_nm;
  }

  setMark(mark: {
    filedMark: any[],
    deleteFiledMark: any[],
    templId: string,
    templNm: string,
    templStatus: string
  }) {
    mark.filedMark.sort((a, b) => {
      return parseInt(a.markContorType) - parseInt(b.markContorType);
    });
    this.mark = mark;
  }

  getMark() {
    return this.mark;
  }

  setMarkSign(mark: any[]) {
    this.markSign = mark.filter((mark) => {
      return mark.markType == 'signature'
    });
  }

  getMarkSign() {
    return this.markSign;
  }

  setColorSign(data: any) {

    this.codeBook.MARK_CONTOR_TYPE.forEach((res) => {
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i]);
        let dataC = JSON.parse(data[i].markLocation);
        if (data[i].signatureId === null && (res.codeDc == dataC.gC)) {
          let color = this.colorSign.filter((color) => {
            return color.color == dataC.gC;
          });
          if (color.length == 0) {
            this.colorSign.push({ color: dataC.gC });
          }
        }

      }
    });
  }

  checkMarkContorType(): boolean {
    let have: boolean = true;

    let markContorTypes = this.markSign.filter((mark) => {
      return mark.markContorType == "000002";
    });

    if (markContorTypes.length > 1) {
      have = false;
    }

    return have;
  }

  getColorSign() {
    return this.colorSign;
  }

  clearColorSign(): void {
    this.colorSign = [];
  }

  getContorType() {
    return this.contorType;
  }

  setContorType(contorType: any) {
    this.contorType = contorType;
  }

  setState(state: string): void {
    this.state = state;
  }

  getState(): string {
    return this.state;
  }
}
