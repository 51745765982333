import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient
  ) { }

  pathGetPaymentHistoryList: string = API_URL.getPaymentHistoryList;
  pathGetInquirePaymentList: string = API_URL.getInquirePaymentList;
  pathResendContract: string = API_URL.refundPayment;


  getPaymentHistoryList(params: any) {
    return this.http.post(this.pathGetPaymentHistoryList, params);
  }

  getInquirePaymentList(params: any){
    return this.http.post(this.pathGetInquirePaymentList,params);
  }

  refundPayment(data: any){
    let params = {
      data: data
    }

    return this.http.post(this.pathResendContract,params);
  }
}
