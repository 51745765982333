import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {NavbarService} from "../navbar/navbar.service";
import {DataPassService} from "../../manage/service/data-pass/data-pass.service";
import * as $ from  'jquery';


@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {
  navigationSubscription;
  page = 'main';
  display: {
    notice: boolean,
    noticeDetail: boolean,
    inquire: boolean,
    qAndA: boolean
  } = {
    notice: true,
    noticeDetail: false,
    inquire: false,
    qAndA: false
  }

  item: any;

  active: {
    notice: string,
    inquire: string,
    qAndA: string
  } = {
    notice: "rdbutton",
    inquire: "whbutton",
    qAndA: "whbutton"
  }


  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private navbarService: NavbarService,
      private dataPass: DataPassService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.page = this.dataPass.getPage();
        if (this.page === 'main') {
          this.navbarService.setNavbarTitle('자료실');
        } else if (this.page === 'policy') {
          this.navbarService.setNavbarTitle('개인정보처리방침');
        } else if (this.page === 'terms') {
          this.navbarService.setNavbarTitle('이용약관');
        }
      }
    });
  }

  clearDisplay() {
    this.display = {
      notice: false,
      noticeDetail: false,
      inquire: false,
      qAndA: false
    }

    this.active = {
      notice: "whbutton",
      inquire: "whbutton",
      qAndA: "whbutton"
    }
  }

  ngOnInit(): void {
    this.page = this.dataPass.getPage();
    this.addColor();

    this.route.queryParams.subscribe(
        params => {
          console.log(params)
          if (params.svc != undefined) {
            this.onClickBtn(params.svc);
          }
        });

  }

  ngOnChanges() {
    this.page = this.dataPass.getPage();
  }

  ngAfterViewInit(): void {
    this.initScoll();
  }

  initScoll() {
    if ($(window).width() < 1199 && $(window).width() > 750) {
      $('body').scrollspy({
        target: '#mainNavTwo',
        offset: 100
      });

      // Collapse Navbar

      try {
        var navbarCollapse = function () {
          // console.log($("#mainNavTwo"))
          if ($("#mainNavTwo").offset() !== undefined) {
            if ($("#mainNavTwo").offset().top > 100) {
              $("#mainNavTwo").addClass("navbar-shrink");
            } else {
              $("#mainNavTwo").removeClass("navbar-shrink");
            }
          }

        };
      } catch (e) {

      }

      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);
    } else {

      $('body').scrollspy({
        target: '#mainNav',
        offset: 100
      });

      // Collapse Navbar

      try {
        var navbarCollapse = function () {
          if ($("#mainNav").offset() !== undefined) {
            if ($("#mainNav").offset().top > 100) {
              $("#mainNav").addClass("navbar-shrink");
            } else {
              $("#mainNav").removeClass("navbar-shrink");
            }
          }

        };
      } catch (e) {

      }

      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);
    }

  }
  onClickBtn(btn: any): void {
    this.clearDisplay();
    this.display[btn] = true;

    if(btn == 'inquire'){
      this.navbarService.setNavbarTitle("자료실");
    }

    this.active[btn] = "rdbutton";
  }

  selectItem($event) {
    this.item = $event;
    this.clearDisplay();
    this.active['notice'] = "rdbutton";
    this.display.noticeDetail = true;
  }

  goBack($event) {
    this.display.noticeDetail = false;
    this.display.notice = true;
    this.router.navigate(['community']);
  }

  addColor() {
    var servicepage = document.getElementById("myBtn");

    servicepage.classList.add("active");

  }

}
