<section>
  <div class="row">
    <div class="col-md-3" >

      <ul class="pl-0">
        <li *ngFor='let department_list of empDataList.department ;let i = index'>
          <div id="div_g_node_{{i}}" class="drop_target" (click)="torgerTree('c_node_'+i)">
            <a href="javascript:;">
              <i class="fa fa-minus" style="color: black; display: none;">
              </i> {{ department_list.d_name }}</a>
          </div>
          <ul id="c_node_{{i}}" class="activate">
            <ng-container *ngFor='let tames_list of empDataList.tames;let j = index'>
              <li *ngIf='tames_list.d_id == department_list.d_id'>
                <div id="div_g_node_{{i}}_{{j}}" class="drop_target" (click)="torgerTree('c_node_'+i+'_'+j)"><a
                    href="javascript:;">
                    <i class="fa fa-minus" style="color: black; display: none;">
                    </i>{{ tames_list.t_name }}</a></div>
                <ul id="c_node_{{i}}_{{j}}" class="activate">
                  <ng-container *ngFor='let emp_list of empDataList.emp;let k = index'>
                    <li *ngIf='emp_list.t_id == tames_list.t_id'>
                      <div class="drop_target">
                        <a href="javascript:;">
                          <i class="fa fa-minus" style="color: black; display: none;">
                          </i>{{ emp_list.e_name }}</a>
                        <label class="switch mb-0" id="sw_node_{{emp_list.e_id}}">
                          <input type="checkbox" (change)="pushDataEmp(emp_list.e_id)" value="{{emp_list.e_id}}">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>

      </ul>

    </div>
    <div class="col-md-9">
      <form>
        <table class="table">
          <thead>
            <tr>
              <td class="text-center">ลำดับ</td>
              <td class="text-center">emp name</td>
              <td class="text-center">team</td>
              <td class="text-center">department</td>
              <td class="text-center">tel</td>
              <td class="text-center">e-mail</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let obj of objTable;let i = index'>
              <td class="text-center">{{(i+1)}}</td>
              <td class="text-center">{{obj.e_name}}</td>
              <td class="text-center">{{obj.t_name}}</td>
              <td class="text-center">{{obj.d_name}}</td>
              <td class="text-center">{{obj.e_tel}}</td>
              <td class="text-center">{{obj.e_mail}}</td>
            </tr>
            <tr>
              <td class="text-center">-</td>
              <td class="text-center">
                <div class="form-group">
                  <input type="text" name="name" class="form-control" id="exampleInput" [(ngModel)]="name" #nameVal="ngModel">
                </div>
              </td>
              <td class="text-center">
                <div class="form-group">
                  <input type="text" name="team" class="form-control" id="exampleInput"  [(ngModel)]="team" #teamVal="ngModel">
                </div>
              </td>
              <td class="text-center">
                <div class="form-group">
                  <input type="text" name="department" class="form-control" id="exampleInput"  [(ngModel)]="department" #departmentVal="ngModel">
                </div>
              </td>
              <td class="text-center">
                <div class="form-group">
                  <input type="text" name="tel" class="form-control" id="exampleInput"  [(ngModel)]="tel" #telVal="ngModel">
                </div>
              </td>
              <td class="text-center">
                <div class="form-group">
                  <input type="email" name="email" class="form-control" id="exampleInputEmail1"
                    aria-describedby="emailHelp"  [(ngModel)]="email" #emailVal="ngModel">
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center" colspan="6">
                <button type="button" class="btn btn-primary" (click)="onAdd()">ADD</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</section>
