import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';

declare let naver: any;
var naverLogin;

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})


export class CallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }


  ngOnInit(): void {

    this.loadNaverAPI();

  }



  async loadNaverAPI() {

    naverLogin = new naver.LoginWithNaverId({
      clientId: "SnEgAjTmfJIQ9ksqEl9G",
      callbackUrl: "https://www.gcont.co.kr/#/callback",
      isPopup: false, /* 팝업을 통한 연동처리 여부 */
      callbackHandle: true
      //loginButton: {color: "green", type: 3, height: 50} /* 로그인 버튼의 타입을 지정 */,
    });

    naverLogin.init();
    await this.getToken();
	  window.close();
  }

  async getToken() {
    return new Promise(async (resolve) => {
      window.location.href.includes('access_token') && await this.getParameter();
      resolve(true);
    });
  }

  async getParameter()
  {
    return new Promise(async (resolve) => {
      var location = window.location.href.split('=')[1];
      var loca = location.split('&')[0];
      var header = {
        Authorization: loca,
      };

      if (loca != null || loca != undefined) {
        localStorage.setItem('wtw-token1', loca)
      }

      resolve(true);
    });
  }
}
