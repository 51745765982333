import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { OrganizationService } from '../../service/organization/organization.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { LoadingService } from '../../shared/loading/loading.service';
import { AlertConfirmService } from '../modal/alert-confirm/alert-confirm.service';
import { AlertService } from '../modal/alert/alert.service';
import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';
import { ModalImportUserTeamsService } from '../modal/modal-import-user-teams/modal-import-user-teams.service';
import regex from '../../shared/regex/regex'

@Component({
  selector: 'app-import-member',
  templateUrl: './import-member.component.html',
  styleUrls: ['./import-member.component.scss']
})
export class ImportMemberComponent implements OnInit {

  @Input() select;
  @Output() statusSendApiCompleted = new EventEmitter<any>();

  empDataList: any;
  objTable: any;
  name: string;
  tel: string;
  team: string;
  department: string;
  email: string;

  pushData: boolean = false;

  total: {
    user: number,
    division: number
  }
    =
    {
      user: 0,
      division: 0
    }

  formDataDivisions: FormGroup;
  arrayDivisions: {
    divId: string,
    divNm: string,
    orgId: string,
    parentDivId: string
  }[] = [];

  formNmDivsion: FormGroup;
  formDataTeam: FormGroup;
  arrayTeams: {
    divId: string,
    staffId: string,
    userId: string,
    userMail: string,
    userNm: string,
    userPhone: string,
    userRank: string
  }[] = [];

  formDataUser: FormGroup;
  selectData: {
    type: string,
    orgId: string,
    divId: string,
    nM: string
  } = {
      type: "",
      orgId: "",
      divId: "",
      nM: ""
    }

  display: {
    manageDepartment: boolean,
    manageTeam: boolean,
    userSreach: boolean
  } = {
      manageDepartment: false,
      manageTeam: true,
      userSreach: false
    }



  constructor(
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private alertConfirmService: AlertConfirmService,
    private modalImportUserTeamsService: ModalImportUserTeamsService,
    private alertService: AlertService,
    private alertSharedService: AlertSharedService,
  ) { }

  ngOnInit(): void {
    this.objTable = [];
    this.initFormDataDivisions();
    this.initFormDataTeams();
    this.initFormNmDivsion();
  }

  ngDoCheck(): void {
    if (this.select.status && this.select.type != '') {
      console.log(this.select)
      this.pushData = false;
      this.select.status = false;
      this.selectData.type = this.select.type;
      this.selectData.orgId = this.select.orgId;
      this.selectData.divId = this.select.divId;
      this.selectData.nM = this.select.nM;
      if (this.select.type === 'department') {
        this.getDivisions(this.selectData.divId, this.selectData.orgId);
        this.getHaveUser(this.selectData.divId);
        this.initFormDataDivisions();
        this.display.manageDepartment = true;
        this.display.manageTeam = false;
        this.display.userSreach = false;
      } else if (this.select.type === 'team') {
        this.getUserDivisions(this.selectData.divId);
        this.getHaveDivision(this.selectData.divId, this.selectData.orgId);
        this.initFormDataTeams();
        this.display.manageDepartment = false;
        this.display.manageTeam = true;
        this.display.userSreach = false;
      } else if (this.select.type === 'search') {
        this.initFormUserSearch();
        this.getUserSearch(this.select.userId, this.select.divId);

        this.display.manageDepartment = false;
        this.display.manageTeam = false;
        this.display.userSreach = true;
      }
    }

    if (this.modalImportUserTeamsService.change.state) {
      if (this.modalImportUserTeamsService.getState() == 'complete') {
        this.modalImportUserTeamsService.close();
      }
    }
  }

  initFormNmDivsion() {
    this.formNmDivsion = this.fb.group({
      divNm: [this.selectData.nM]
    });
  }

  get arrayDivisionDatas() {
    return this.formDataDivisions.get('divisions') as FormArray;
  }

  initFormDataDivisions() {
    this.formDataDivisions = this.fb.group({
      orgNm: [this.selectData.nM],
      divisions: this.fb.array([])
    });
  }

  checkDivisionId(textId: string) {
    let bool = true;

    if (textId != null) {
      if (textId.includes("<>")) {
        bool = false;
      }
    }

    return bool;
  }

  createItemDataDivision(divId, divNm): FormGroup {
    return this.fb.group({
      divId: divId,
      divNm: divNm,
      orgId: this.selectData.orgId,
      parentDivId: this.selectData.divId
    });
  }

  getHaveDivision(psrent: string, orgId: string) {
    let data = {
      parentDivId: psrent,
      orgId: orgId
    }
    this.loadingService.openLoading();
    this.organizationService.getDivisions(data).subscribe((rs: any) => {
      if (rs.status) {
        this.total.division = rs.data.length;
        console.log(psrent, psrent.includes("<>"));
        if (psrent.includes("<>")) {
          this.total.division = 1;
        }
        this.loadingService.closeLoading();
      }
    });
  }

  getDivisions(psrent: string, orgId: string) {
    let data = {
      parentDivId: psrent,
      orgId: orgId
    }
    this.loadingService.openLoading();
    this.organizationService.getDivisions(data).subscribe((rs: any) => {
      if (rs.status) {
        this.arrayDivisionDatas.clear();
        this.arrayDivisions = [];
        for (let i = 0; i < rs.data.length; i++) {
          this.pushDivision(rs.data[i].divId, rs.data[i].divNm);
        }
        this.pushData = true;
        this.loadingService.closeLoading();
      }
    });
  }

  pushDivision(divId: string, divNm: string) {
    this.arrayDivisionDatas.push(this.createItemDataDivision(divId, divNm));
    this.arrayDivisions.push({ divId: divId, divNm: divNm, orgId: this.selectData.orgId, parentDivId: this.selectData.divId });
  }

  onCreateDivision() {
    this.loadingService.openLoading();
    let data = {
      orgNm: this.formDataDivisions.get("orgNm").value,
      orgId: this.selectData.orgId,
      divisions: this.formDataDivisions.get("divisions").value
    }
    this.organizationService.createDivision(data).subscribe((rs: any) => {
      if (rs.status) {
        this.statusSendApiCompleted.emit({ status: true });
        this.onClose();
        this.loadingService.closeLoading();
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: false
          },
          {
            title: "업데이트되었습니다.",
            subTitle: ""
          },
          [
            {
              btnType: 'confirmed',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {
                console.log("test");
              }
            }
          ]
        );
      }
    });
  }

  pushDataEmp(emp_id) {

    let arr = this.objTable;
    let emp_data = this.empDataList.emp.filter(res => res.e_id == emp_id)[0];
    let tames_data = this.empDataList.tames.filter(res => res.t_id == emp_data.t_id)[0];
    let department_data = this.empDataList.department.filter(res => res.d_id == tames_data.d_id)[0];

    let obj = {
      e_id: emp_data.e_id,
      e_name: emp_data.e_name,
      e_tel: emp_data.e_tel,
      e_mail: emp_data.e_mail,
      t_id: tames_data.t_id,
      t_name: tames_data.t_name,
      d_id: department_data.d_id,
      d_name: department_data.d_name
    };

    let index = arr.findIndex(res => res.e_id === obj.e_id);

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(obj);
    }
    this.objTable = arr;
  }

  getHaveUser(divId: string) {
    this.loadingService.openLoading();
    this.organizationService.getUserDivisions(divId).subscribe((rs: any) => {
      if (rs.status) {
        this.total.user = rs.data.length;
        this.loadingService.closeLoading();
      }
    });
  }

  getUserDivisions(divId: string) {
    this.loadingService.openLoading();
    this.organizationService.getUserDivisions(divId).subscribe((rs: any) => {
      if (rs.status) {
        this.arrayTeamDatas.clear();
        this.arrayTeams = [];
        for (let i = 0; i < rs.data.length; i++) {
          this.pushTeams(rs.data[i].divId, rs.data[i].staffId, rs.data[i].userId, rs.data[i].userNm, rs.data[i].userRank, rs.data[i].userPhone, rs.data[i].userMail);
        }
        this.pushData = true;
        this.loadingService.closeLoading();
      }
    });
  }

  initFormUserSearch() {
    this.formDataUser = this.fb.group({
      divId: "",
      staffId: "",
      userId: "",
      userMail: "",
      userNm: "",
      userPhone: "",
      userRank: ""
    });
  }

  getUserSearch(userId: string, divId: string) {
    let data = {
      userTemId: userId,
      divId: divId
    }
    this.loadingService.openLoading();
    this.organizationService.getUserSearch(data).subscribe((rs: any) => {
      if (rs.status) {
        this.formDataUser.get('divId').setValue(rs.data.divId);
        this.formDataUser.get('staffId').setValue(rs.data.staffId);
        this.formDataUser.get('userId').setValue(rs.data.userId);
        this.formDataUser.get('userMail').setValue(rs.data.userMail);
        this.formDataUser.get('userNm').setValue(rs.data.userNm);
        this.formDataUser.get('userPhone').setValue(rs.data.userPhone);
        this.formDataUser.get('userRank').setValue(rs.data.userRank);
      }
      this.loadingService.closeLoading();
    });
  }

  get arrayTeamDatas() {
    return this.formDataTeam.get('teams') as FormArray;
  }

  initFormDataTeams() {
    this.formDataTeam = this.fb.group({
      teams: this.fb.array([])
    });
  }

  createItemDataTeam(divId, staffId, userId, userNm, userRank, userPhone, userMail): FormGroup {
    return this.fb.group({
      divId: divId,
      staffId: staffId,
      userId: userId,
      userMail: [userMail, [Validators.required, Validators.email]],
      userNm: [userNm, regex.Required],
      userPhone: [userPhone, regex.Required],
      userRank: [userRank, regex.Required]
    });
  }

  pushTeams(divId: string = this.selectData.divId, staffId: string = null, userId: string = null, userNm: string = null, userRank: string = null, userPhone: string = null, userMail: string = null) {
    this.arrayTeamDatas.push(this.createItemDataTeam(divId, staffId, userId, userNm, userRank, userPhone, userMail));
    this.arrayTeams.push({ divId, staffId: staffId, userId: userId, userNm: userNm, userRank: userRank, userPhone: userPhone, userMail: userMail });
  }

  removeDivision(divId: string, index: number): void {
    if (divId != null) {
      this.alertSharedService.open(
        {
          title: "삭제",
          btnClose: true
        },
        {
          title: "선택한 대상자를 삭제하시겠습니까?",
          subTitle: ""
        },
        [
          {
            btnType: 'close',
            btnNm: '취소',
            btnStyle: 'btn-black',
            btnFunc: () => {
              console.log("test");
            }
          },
          {
            btnType: 'confirmed',
            btnNm: '확인',
            btnStyle: 'btn-black',
            btnFunc: () => {
              this.deleteDivision(divId, index);
            }
          }
        ]
      );
    } else {
      this.removeDivisionData(index);
    }
  }

  removeDivisionData(index: any) {
    this.arrayDivisionDatas.removeAt(index);
    this.arrayDivisions.splice(index, 1);
  }

  deleteDivision(divId: string, index: number): void {
    this.loadingService.openLoading();
    this.organizationService.deleteDivision(divId).subscribe((rs: any) => {
      if (rs.status) {
        this.statusSendApiCompleted.emit({ status: true });
        this.removeDivisionData(index);
      }
      this.loadingService.closeLoading();
    });
  }

  removeUserTeams(staffId: string, divId: string, index: number): void {
    if (staffId != null) {
      this.updateUserTeams(staffId, divId, index);
    } else {
      this.removeTeamsData(index);
    }
  }

  removeTeamsData(index: any) {
    this.arrayTeamDatas.removeAt(index);
    this.arrayTeams.splice(index, 1);
  }

  updateUserTeams(staffId: string, divId: string, index: number): void {
    this.alertSharedService.open(
      {
        title: "삭제",
        btnClose: true
      },
      {
        title: "선택한 대상자를 삭제하시겠습니까?",
        subTitle: ""
      },
      [
        {
          btnType: 'close',
          btnNm: '취소',
          btnStyle: 'btn-white',
          btnFunc: () => {
            console.log("test");
          }
        },
        {
          btnType: 'confirmed',
          btnNm: '확인',
          btnStyle: 'btn-black',
          btnFunc: () => {
            this.updateStaffUseYnToN(staffId, divId, index);
          }
        }
      ]
    );
  }

  updateStaffUseYnToN(staffId: string, divId: string, index: number): void {
    this.loadingService.openLoading();
    this.organizationService.updateStaffUseYnToN(divId, staffId).subscribe((rs: any) => {
      if (rs.status) {
        this.statusSendApiCompleted.emit({ status: true });
        this.removeTeamsData(index);
      }
      this.loadingService.closeLoading();
    });
  }

  onCreateTeams() {
    let save: boolean = true, name: boolean = true, mail: boolean = true;
    let title: string = '';
    let text: string = '이름을 입력해주세요';
    let subText: string = '이메일을 입력해주세요';
    for (let i = 0; i < this.arrayTeamDatas.length; i++) {
      if (this.formDataTeam.get("teams").get(i.toString()).get("userNm").status === "INVALID" && name) {
        save = false;
        name = false;
      }
      if (this.formDataTeam.get("teams").get(i.toString()).get("userMail").status === "INVALID" && mail) {
        save = false;
        mail = false;
      }
    }
    if (save) {
      let data = {
        teams: this.formDataTeam.get("teams").value
      }
      this.loadingService.openLoading();
      this.organizationService.createStaffTems(data).subscribe((rs: any) => {
        if (rs.status) {
          console.log(rs.data);
          this.statusSendApiCompleted.emit({ status: true });
          this.alertSharedService.open(
            {
              title: "알림",
              btnClose: true
            },
            {
              title: "업데이트되었습니다.",
              subTitle: ""
            },
            [
              {
                btnType: 'close',
                btnNm: '확인',
                btnStyle: 'btn-black',
                btnFunc: () => {
                  console.log("test");
                }
              }
            ]
          );
        }

        this.loadingService.closeLoading();
      });
    } else {
      let str = '';
      if (!name) {
        str = text;
      }
      if (!mail) {
        if (!name) {
          str = "모든 정보가 올바르게 입력되어있는지 확인해주세요.";
        } else {
          str = subText;
        }
      }
      this.alertSharedService.open({
        title: "알림",
        btnClose: true
      },
        {
          title: str,
          subTitle: ""
        },
        [
          {
            btnType: 'close',
            btnNm: '확인',
            btnStyle: 'btn-black',
            btnFunc: () => {
              console.log("test");
            }
          }
        ]);
    }
  }

  onUpdateUser() {
    let data = {
      teams: [this.formDataUser.value]
    }
    this.loadingService.openLoading();
    this.organizationService.createStaffTems(data).subscribe((rs: any) => {
      if (rs.status) {
      }

      this.loadingService.closeLoading();
    });
  }

  onImportTeams() {
    this.modalImportUserTeamsService.open({
      func: () => {
        console.log("ssss");
        this.statusSendApiCompleted.emit({ status: true });
      }
    });
  }

  onClose() {
    this.display.manageDepartment = false;
    this.display.manageTeam = true;
  }

  onCloseEvent($event) {
    if ($event.target.className.includes("division-modal")) {
      this.display.manageDepartment = false;
      this.display.manageTeam = true;
    }
  }
}