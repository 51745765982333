<section>

  <div class="largerUi">
    <div class="d-flex row-hl align-items-center">
      <div class="item-hl"> <button (click)="deleteMailTemplate()" class="btn btn-small-long btn-white">
          삭제
        </button></div>
      <div class=" ml-auto item-hl">총 {{itemTotal}}건</div>
    </div>
    <div class="row">
      <div class="col-12 ">
        <table class="table largeTable table-hover">
          <thead class="th-bg-001">
            <tr>
              <th class="tabitemA  text-left tex-tit-tab-001">
                선택
              </th>
              <th class="tabitemB text-center tex-tit-tab-001">
                계약서 명
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <ng-container *ngFor="let t of td">
              <tr class="itemsRow text-left {{checkSelectTeplate(t.tempmId)}}" (click)="selectItem($event,t)">
                <td scope="row">
                  <div style="line-height: 1;" class="form-group form-check p-0 m-0">
                    <label class="con-che-001">
                      <input type="checkbox" (click)="checkBoxSelect(t.tempmId)">
                      <span class="che-mar-001"></span>
                    </label>
                  </div>
                </td>
                <td class="text-left underL">
                  {{t.tempmType}}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div class="d-flex justify-content-center" style="margin-top: 30px; margin-bottom: 58px; ">
          <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
            (seclectPage)="setCurrentPage($event)"></app-pagination>
        </div>
      </div>
    </div>
  </div>

  <div class=" smallerUi">
    <div class="mailManage">
      <div class="d-flex cancelRow  align-items-center">
        <div class="item-hl"> <button (click)="deleteMailTemplate()" class="mailCancelBTN btn btn-small-long btn-white">
            삭제
          </button></div>
      </div>
      <div class="row">
        <div class="col-12 ">
          <table class="table largeTable table-hover">
            <thead class="th-bg-001">
              <tr>
                <th class="tabitemA barumReg text-left tex-tit-tab-001">
                  선택
                </th>
                <th class="tabitemB  barumReg text-center tex-tit-tab-001">
                  계약서 명
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <ng-container *ngFor="let t of td">
                <tr class="itemsRow text-left {{checkSelectTeplate(t.tempmId)}}" (click)="selectItem($event,t)">
                  <td scope="row">
                    <div style="line-height: 1;" class="form-group form-check p-0 m-0">
                      <label class="con-che-001">
                        <input type="checkbox" (click)="checkBoxSelect(t.tempmId)">
                        <span class="che-mar-001"></span>
                      </label>
                    </div>
                  </td>
                  <td class="text-left underL">
                    {{t.tempmType}}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>


</section>
