import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonFunction } from 'src/app/manage/service/common-function/common-function.service';
import { API_URL } from 'src/app/manage/service/api.constant';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import { NavbarService } from '../navbar/navbar.service';
import { PaymentApplyService } from '../modal/payment-apply/payment-apply.service';
import { Router } from '@angular/router';

let m_item_id;
let m_item_name;
let m_quantity;
let m_item_price;
let m_total_amount;
let m_vat;
let dc;
let user_name;



@Component({
  selector: 'app-payment-management',
  templateUrl: './payment-management.component.html',
  styleUrls: ['./payment-management.component.scss']
})
export class PaymentManagementComponent implements OnInit {

  win: Window;
  sidebar: any = "set";
  obj;
  itm_id: any = '';
  itm_nm: any = '';
  itm_price_type_nm: any = '';
  itm_price_value: any = '';
  itm_price: any = '';
  itm_total_price: any = '';
  fromSearchPaymentOne: FormGroup;
  constructor(
    private fb: FormBuilder,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private commonfuction: CommonFunction,
    private http: HttpClient,
    private router: Router,
    private navbarService: NavbarService,
    private paymentApplyService: PaymentApplyService

  ) {
    this.sidebarService.setManuActive(this.sidebar);
    this.sidebarService.setSubManuActive('payment-management');
  }
  item: {
    amountPay: string,
    approvalDate: string,
    approvalNumber: string,
    paymentDate: string,
    paymentDetails: string,
    paymentType: string,
    processingStatus: string,
    purchasePoint: string
  } = {
      amountPay: '',
      approvalDate: '',
      approvalNumber: '',
      paymentDate: '',
      paymentDetails: '',
      paymentType: '',
      processingStatus: '',
      purchasePoint: ''
    }

  inuire: {
    answCont: string,
    answId: string,
    frstReginPnttm: string,
    quesCont: string,
    quesId: string,
    quesStatus: string
  } = {
      answCont: '',
      answId: '',
      frstReginPnttm: '',
      quesCont: '',
      quesId: '',
      quesStatus: ''
    }




  ngOnInit(): void {

    this.navbarService.setAction('payManage');
    this.initFromSearchPayment();
 
  }

  initFromSearchPayment() {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let hours = today.getHours();
    let minutes = today.getMinutes();
    hours = hours
    let minute = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minute
    let date = yyyy + '-' + mm + '-' + dd;

    this.fromSearchPaymentOne = this.fb.group({
      processingStatus: [0],
      dateStart: [date],
      timeStart: [strTime],
      dateEnd: [date],
      timeEnd: [strTime],
      cardNumber: [null]
    });


  }


  ngAfterViewInit(): void {
    this.sidebarService.setManuActive(this.sidebar);
  }

  clickItem($event) {
    this.item = $event;
  }



  async getBasicInfo() {
    const snsPayUrl = API_URL.BACKEND_CONTEXTPATH + 'api/pay/iteam/ITM00000000000000004';
    const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');

    return new Promise(async (resolve) => {
      this.http.post(snsPayUrl, headers).subscribe((res: any) => {

        if (res.status) {

          console.log(res.data);
          console.log(res.data.ITM_NM);

          this.itm_id = res.data.itm_id;
          this.itm_nm = res.data.itm_nm;

          this.itm_price_type_nm = res.data.itm_price_type_nm;
          this.itm_price_value = res.data.itm_price_value;
          this.itm_price = res.data.itm_price;
          this.itm_total_price = (res.data.itm_price * res.data.itm_price_value) + "";
          this.itm_price = res.data.itm_price;

        }
        resolve(true);

      }, (error) => {
        console.log(error);

        resolve(true);
      });

    });
  }



  async pay() {
    console.log('this.itm_id    ============= >>>' + this.itm_id);
    this.router.navigate(['/']);
    setTimeout(() => {
      let el = document.getElementById('serviceEl');
      el.scrollIntoView();

    });
  }

  selectInuire($event) {
    this.inuire = $event;
  }













  testForDeductPoint() {
    let resPointID;
    this.commonfuction.deductPoint(2).subscribe((res: any) => {
      if (res.data.message == null) {
        console.log(res.data);
        console.log(res.data.itm_nm);

        resPointID = res.data.po_id;
        console.log("deducted point = " + resPointID);
      }
      else {
        console.log("res.data.message =======> " + res.data.message);
      }

    }, (error) => {
      console.log(error);

    });
  }



  testForCancelPayment() {
    this.commonfuction.cancelPayment('A2812524484840975279', 'T2812524368875994699', 'TC0ONETIME', 5500).subscribe((res: any) => {

      if (res.data.message == null) {

        console.log(res.data);
        console.log(res.data.aid);
        console.log(res.data.tid);
        console.log(res.data.cid);
        console.log(res.data.partner_order_id);
        console.log(res.data.partner_user_id);
        console.log(res.data.payment_method_type);
        console.log(res.data.status);
      }
      else {
        console.log("res.data.message =======> " + res.data.message);
      }

    }, (error) => {
      console.log(error);

    });
  }






}

