<section class="largerUi w-100" style="background-color: #e2e6eb;">

  <div class="container-lg ml-lg-0 mr-lg-auto p-0 ">

    <h3 class="ni_Label"><b>계약 현황</b></h3>
    <div class="row no-gutters ">
      <div class="d-flex flex-column justify-content-center mb-3 mb-md-0 col-sm-12  col-lg-2">
        <h5 class="mb-0 mt-3"><b>전송 가능한 계약</b>
        </h5>
        <div style="color:#a4a4a4;" class="text-muted mt-3">(건)</div>
        <div class="my-3">
          <hr style="border: 2px solid #b91414; width:25px; background: #b91414; " class="my-0 float-left">
        </div>

        <label style="line-height: 1;" class=" totAmount">
          <!--                    TODO: 심사 이후 수정 -->
          <b>Free</b>
<!--            <b *ngIf="dataDashBoard.countSendToMe == null" >0</b>-->
<!--            <b *ngIf="dataDashBoard.countSendToMe != null" >{{dataDashBoard.countSendToMe}}</b>-->
        </label>
      </div>
      <div class=" col-sm-12 col-lg-10 row no-gutters mx-0">
        <div class="col-lg col-4 px-0 px-sm-1 ">
          <div class="h-100 p-3 rounded d-flex flex-column justify-content-start align-items-center number-card">


            <div class="row align-items-center" style="height: 100%; ">

              <div class="col-12 pt-2 px-0">
                <div class="text-center">
                  <p><span style="border-bottom: solid 1px red;padding: 10px;">생성</span></p>
                </div>
              </div>

              <div class=" align-self-center text-center col-12 ">
                <span class="cardLabel">{{dataDashBoard.countCont}}</span>
              </div>

            </div>




          </div>
        </div>

        <div class="col-lg px-1 px-sm-1 col-4 ">
          <div
            class="h-100 p-3 rounded d-flex flex-wrap flex-column justify-content-center align-items-center number-card">

            <div class="row align-items-center" style="height: 100%; ">

              <div class="col-12 pt-2 px-0">
                <div class="text-center">
                  <p><span style="border-bottom: solid 1px red;padding: 10px;">전송</span></p>
                </div>
              </div>

              <div class=" align-self-center text-center col-12 ">
                <span class="cardLabel">{{dataDashBoard.countSentCont}}</span>
              </div>

            </div>

          </div>
        </div>


        <div class="col-lg pl-0 px-md-1 pl-sm-1 col-4 ">
          <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

            <div class="row align-items-center" style="height: 100%; ">

              <div class="col-12 pt-2 px-0">
                <div class="text-center">
                  <p><span style="border-bottom: solid 1px red;padding: 10px;">진행중</span></p>
                </div>
              </div>

              <div class=" align-self-center text-center col-12 ">
                <span class="cardLabel">{{dataDashBoard.countCoufiCont}}</span>
              </div>

            </div>

          </div>
        </div>

        <div class="w-100 d-block d-lg-none pt-1"></div>

        <div class="col-lg px-0 px-sm-1 col-4 ">
          <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">


            <div class="row align-items-center" style="height: 100%; ">

              <div class="col-12 pt-2 px-0">
                <div class="text-center">
                  <p><span style="border-bottom: solid 1px red;padding: 10px;">완료</span></p>
                </div>
              </div>

              <div class=" align-self-center text-center col-12 ">
                <span class="cardLabel">{{dataDashBoard.countSignCont}}</span>
              </div>

            </div>


          </div>
        </div>


        <div class="col-lg px-1 px-sm-1 col-4">
          <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

            <div class="row align-items-center" style="height: 100%; ">

              <div class="col-12 pt-2 px-0">
                <div class="text-center">
                  <p><span style="border-bottom: solid 1px red;padding: 10px;">취소</span></p>
                </div>
              </div>

              <div class=" align-self-center text-center col-12 ">
                <span class="cardLabel">{{dataDashBoard.countCancleCont}}</span>
              </div>

            </div>

          </div>
        </div>


        <div class="col-lg pl-0 pl-sm-1 col-4">
          <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

            <div class="row align-items-center" style="height: 100%; ">

              <div class="col-12 pt-2">
                <div class="text-center">
                  <p><span style="border-bottom: solid 1px red;padding: 10px;">반려</span></p>
                </div>
              </div>

              <div class=" align-self-center text-center col-12 ">
                <span class="cardLabel">{{dataDashBoard.countRejectCont}}</span>
              </div>

            </div>


          </div>
        </div>



      </div>
    </div>



    <div class="row no-gutters my-md-5 ">
      <div class="container-fluid">
        <div class="row">

          <!-- Content row 1 -->
          <div id="leftPanel" class="d-flex p-0 align-self-center h-100">
            <div class="w-100  rounded h-100  " style="background: #ffffff !important;">


              <div class=" d-flex justify-content-between align-items-center panalTit ">
                <h5 class="text-left w-100 tit-lis-001 mb-0"><b>계약별 체결 증가 추이 </b></h5>
              </div>

              <div class="d-none d-md-block chart-wrapper">
                <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                  [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
                  [plugins]="lineChartPlugins">
                </canvas>
              </div>

              <div class="d-block d-md-none chart-wrapper">
                <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptionsSM"
                  [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
                  [plugins]="lineChartPlugins">
                </canvas>
              </div>
              <div class="w-100 d-flex flex-row justify-content-end" style="padding-left: 30px;padding-right: 30px;">
                <div class="d-flex flex-row justify-content-end align-items-center">
                  <div class="lab-cha-001"></div>
                  <span class="lab-cha-tex-001">전송</span>
                </div>
                <div class="d-flex flex-row justify-content-end align-items-center">
                  <div class="lab-cha-002"></div>
                  <span class="lab-cha-tex-001">진행중</span>
                </div>
                <div class="d-flex flex-row justify-content-end align-items-center">
                  <div class="lab-cha-003"></div>
                  <span class="lab-cha-tex-001">완료</span>
                </div>
              </div>
            </div>
          </div>
          <!-- End of content row 1  -->

          <!-- Content row 2 -->
          <div class="d-flex dashboardPanel p-0 ">
            <div class="col-12 bg-white rounded h-100 p-0">

              <div class=" d-flex justify-content-between align-items-center panalTit ">
                <h5 class="tit-lis-001 m-0">공지사항</h5>
                <a href="/#/service-center"><img src="../../../assets/img/icon/leftPanelPlus.png"></a>
              </div>

              <ul class="panalList list-group list-group-flush" style="overflow: hidden;">

                <li class="list-group-item list-group-item-action item-cus"
                  *ngFor="let notice of noticeLists; let i = index" (click)="goToServiceCenter(notice.noticId)">
                  <h6 class="second-not-tit">{{notice.noticTitle}}</h6>
                  <p innerHTML="{{notice.noticeContent}}">
                  </p>
                  <small class="tit-lis-itm-dat-001">{{notice.registerPnttm}}</small>

              </ul>
            </div>
          </div>
          <!-- End of content row 2  -->

        </div>
      </div>
    </div>

  </div>


</section>

<!-- End of Larger UI -->

<section class="smallerUi w-100" style="background-color: #e2e6eb;">

  <div class="container-fluid p-0 ">

    <div class="dashHead d-flex align-items-center">
      <div class=" ">
        <h3 class=" ni_Label "><b>계약 현황</b></h3>
      </div>
      <div class="ml-auto "><a style="display: none;" href="#/creating-electronic#v-pills-home">
          <button class="btn btn-big-px btn-crimson  m-0">
            전자계약서 만들기
          </button>
        </a></div>
    </div>



    <div class="row no-gutters ">
      <div class="d-flex flex-column justify-content-center mb-md-0 col-sm-12  ">
        <h5 class="MYtxt"><b>MY 요청된 계약</b>
          <span style="color:#a4a4a4;" class="text-muted mt-4">(건)</span>
        </h5>



        <label style="line-height: 1;" class="totAmount MYnum font-40"><b>{{dataDashBoard.countSendToMe}}</b></label>
      </div>


    </div>

    <div class="container-fluid">

      <div class="row">
        <div class="wrapper dataGrid">
          <div class="box a">

            <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

              <div class="row align-items-center" style="height: 100%; ">

                <div class="col-12 pt-2">
                  <div class="text-center">
                    <p><span style="border-bottom: solid 1px red;padding: 10px;">생성</span></p>
                  </div>
                </div>

                <div class=" align-self-start text-center col-12 ">
                  <span class="cardLabel">{{dataDashBoard.countCont}}</span>
                </div>

              </div>


            </div>

          </div>

          <div class="box b">
            <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

              <div class="row align-items-center" style="height: 100%; ">

                <div class="col-12 pt-2">
                  <div class="text-center">
                    <p><span style="border-bottom: solid 1px red;padding: 10px;">전송</span></p>
                  </div>
                </div>

                <div class=" align-self-start text-center col-12 ">
                  <span class="cardLabel">{{dataDashBoard.countSentCont}}</span>
                </div>

              </div>


            </div>
          </div>
          <div class="box c">
            <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

              <div class="row align-items-center" style="height: 100%; ">

                <div class="col-12 pt-2">
                  <div class="text-center">
                    <p><span style="border-bottom: solid 1px red;padding: 10px;">진행중</span></p>
                  </div>
                </div>

                <div class=" align-self-start text-center col-12 ">
                  <span class="cardLabel">{{dataDashBoard.countCoufiCont}}</span>
                </div>

              </div>


            </div>
          </div>
          <div class="box d">
            <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

              <div class="row align-items-center" style="height: 100%; ">

                <div class="col-12 pt-2">
                  <div class="text-center">
                    <p><span style="border-bottom: solid 1px red;padding: 10px;">완료</span></p>
                  </div>
                </div>

                <div class=" align-self-start text-center col-12 ">
                  <span class="cardLabel">{{dataDashBoard.countSignCont}}</span>
                </div>

              </div>


            </div>
          </div>
          <div class="box e">
            <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

              <div class="row align-items-center" style="height: 100%; ">

                <div class="col-12 pt-2">
                  <div class="text-center">
                    <p><span style="border-bottom: solid 1px red;padding: 10px;">취소</span></p>
                  </div>
                </div>

                <div class=" align-self-start text-center col-12 ">
                  <span class="cardLabel">{{dataDashBoard.countCancleCont}}</span>
                </div>

              </div>


            </div>
          </div>
          <div class="box f">
            <div class="h-100 p-3 rounded d-flex flex-column justify-content-center align-items-center number-card">

              <div class="row align-items-center" style="height: 100%; ">

                <div class="col-12 pt-2">
                  <div class="text-center">
                    <p><span style="border-bottom: solid 1px red;padding: 10px;">반려</span></p>
                  </div>
                </div>

                <div class=" align-self-start text-center col-12 ">
                  <span class="cardLabel">{{dataDashBoard.countRejectCont}}</span>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row no-gutters my-md-3 ">
      <div class="col-sm-12 mt-2 col-sm-12  align-self-center h-100">
        <!--  d-flex flex-column justify-content-center -->
        <div class="w-100  rounded h-100  " style="background: #ffffff !important;">
          <h5 class="text-left w-100 tit-lis-001 chartTXT"><b>계약별 체결 증가 추이 </b></h5>

          <div class="d-none d-md-block chart-wrapper">
            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
              [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
              [plugins]="lineChartPlugins">
            </canvas>
          </div>

          <div class="d-block d-md-none chart-wrapper">
            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptionsSM"
              [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
              [plugins]="lineChartPlugins">
            </canvas>
          </div>

          <div class="w-100 d-flex flex-row justify-content-end" style="padding : 0 30px 30px 30px">
            <div class="d-flex flex-row justify-content-end align-items-center">
              <div class="lab-cha-001"></div>
              <span class="lab-cha-tex-001">전송</span>
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center">
              <div class="lab-cha-002"></div>
              <span class="lab-cha-tex-001">진행중</span>
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center">
              <div class="lab-cha-003"></div>
              <span class="lab-cha-tex-001">완료</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 pl-0 margin-b20">

        <div class="col-12 bg-white rounded mobPanel h-100 p-0">

          <div class=" d-flex justify-content-between align-items-center panalTit ">
            <h5 class="tit-lis-001 m-0">공지사항</h5>
            <a href="/#/service-center"><img src="../../../assets/img/icon/leftPanelPlus.png"></a>
          </div>

          <ul class="list-group list-group-flush" style="overflow: hidden;padding:0px 30px 0px 30px;">

            <li class="list-group-item list-group-item-action item-cus py-2"
              *ngFor="let notice of noticeLists; let i = index" (click)="goToServiceCenter(notice.noticId)">
              <div class="w-100 d-flex flex-row">
                <h6 class="not-tit-001 mob-tit-not-001">{{notice.noticTitle}}
                </h6>
                <small class="tit-lis-itm-dat-001">{{notice.registerPnttm}}</small>
              </div>
            </li>

          </ul>
        </div>

      </div>
    </div>

  </div>


</section>
<!-- Start of Smaller UI -->
<app-loading></app-loading>
