<section style="background: #e9ecef" class="container-fluid ">

    <section class="container">


        <div class="h-100 row d-flex justify-content-center align-items-center ">
            <div style="background: #e9ecef;height: 100vh !important;" class="h-100 align-items-center row d-flex justify-content-center">

                <div class=" py-3 d-flex justify-content-center">
                    <form class="confirmForm" style="width: 100%;">



                        <div class="gcontImg  p-0 d-flex-columns justify-content-center">

                            <div class="text-center"> <img width="180px"
                                    src="../../../../assets/img/frontpage/logo_gcont2x.png" />
                            </div>
                            <div class="bod-ite-001 d-flex align-items-center  mx-auto"
                                (click)="onClickBtnType('MEMBER')">
                                <div class="confirmChoice">
                                    <h4>
                                        회원 계약서 서명
                                    </h4>
                                    <h5 class="sub-tit-001">
                                        사용하시는 ID가 있으시다면 다양한 부가 서비스와 함께 이용하세요!

                                    </h5>
                                </div>
                                <div class="choiceArrow d-flex justify-content-end">
                                    <span class="img-ico-cre-001 ico-cre-001"></span>
                                </div>
                            </div>


                            <div class="bod-ite-001 d-flex align-items-center  mx-auto"
                                (click)="onClickBtnType('NOT_MEMBER')">
                                <div class="confirmChoice">
                                    <h4>
                                        비회원 계약서 서명
                                    </h4>
                                    <h5 class="sub-tit-001">
                                        로그인 없이 계약서를 서명하실 수 있습니다.

                                    </h5>
                                </div>
                                <div class="choiceArrow d-flex justify-content-end">
                                    <span class="img-ico-cre-001 ico-cre-001"></span>
                                </div>
                            </div>


                        </div>


                    </form>


                </div>
            </div>

        </div>

    </section>


</section>