import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import { DataPassService } from 'src/app/manage/service/data-pass/data-pass.service';
import { ServiceCenterService } from '../../manage/service/service-center/service-center.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faLongArrowAltRight = faLongArrowAltRight;
  constructor(
    private serviceCenterService: ServiceCenterService,
    private router: Router,
    private dataPass: DataPassService
  ) { }

  params = {
    data: {
      noticText: "",
      noticType: ""
    },
    pageNo: 0,
    pageSize: 3
  }

  noticeLists: {
    noticId: string,
    noticNo: number,
    noticTitle: string,
    noticeContent: string,
    registerPnttm: string
  }[];
  ngOnInit(): void {
    this.searchNoticeFront();
  }

  toPolicy() {
    this.dataPass.setPage('policy');

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.router.navigate(['/service-center'], {skipLocationChange: false});
  }

  toTerms() {
    this.dataPass.setPage('terms');

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.router.navigate(['/service-center'], {skipLocationChange: false});
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  searchNoticeFront() {
    this.serviceCenterService.searchNoticeFront(this.params).subscribe((rs: any) => {
      if (rs.status) {
        this.noticeLists = rs.data;
      }
    });
  }
}
