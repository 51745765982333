<section class="h-100" style="background: #e9ecef">
  <section class="container h-100">


    <div class="h-100 row d-flex justify-content-center align-items-center" style="height: 100vh !important;">

      <div style=" height: 450px; border-top: 2px solid #111 ;"
        class="confirmForm d-flex bg-white justify-content-center flex-column">
        <div class="forgotTxthead text-center ">
          <img src="../../../assets//img/icon/icon-join.png" class="img-fluid ">
          <h3><b>가입을 축하합니다!</b></h3>
          <h5>아래 로그인 버튼을 클릭하여 GCONT 서비스에 로그인해주세요.</h5>
        </div>

        <div class="h-100 align-items-end form-group row d-flex justify-content-center mb-0 h-100">
          <button type="button" (click)="goToLogin()" class="ml-0 btn btn-big btn-crimson "
            style="width: auto !important;">로그인 화면으로 이동
          </button>
        </div>
      </div>
    </div>
  </section>
</section>