<div style="position: sticky;" class="d-flex justify-content-between align-items-center py-2 borderlinebot ">

    <div class="d-flex justify-content-center px-2">
        <span class="">
            {{page.pagenumber}}
        </span>
        <span class="">
            &nbsp;/&nbsp;
        </span>
        <span class="">
            {{page.maxpage}} &nbsp; 페이지
        </span>
    </div>

    <div class="d-flex justify-content-center px-2">
        <div class="img-ico-edi-002 ico-edi-zoo-in-001" (click)="zoom_in()">
        </div>

        <div class="img-ico-edi-002 ico-edi-zoo-out-001" (click)="zoom_out()">
        </div>

        <div class="img-ico-edi-003 ico-edi-pri-001" (click)="printerPdf()">
        </div>
    </div>

    <div class="d-flex justify-content-center px-2">
    </div>

</div>

<div class="d-flex w-100 justify-content-center align-items-center status-edit">
    <button class="btn btn-big btn-crimson" style="width: auto !important;" *ngIf="tools.show.buttons.next"
            (click)="btnNext()">
        {{tools.show.buttons.nextText}}
    </button>
</div>

<div class="container-fluid px-0">
    <div class="d-flex" id="wrapper">
      <div id="page-content-wrapper" style="background: #E9E9E9;">
        <div class="d-flex justify-content-center align-items-start">
          <div class="view-pdf">
            <div class="pdf-view" id='view-pdf-div' (scroll)="scrollHandler($event)">
              <div class="d-flex">
                <div class="d-flex justify-content-center w-100" style="overflow:auto; padding-bottom: 50px;">
                  <pdf-viewer (after-load-complete)="afterLoadComplete($event)" [src]="pdfSrc[1].src"
                    [render-text]="false" (page-rendered)="pageRendered($event)" [zoom]="zoom_to" style="display: block;">
                  </pdf-viewer>
                </div>
              </div>
            </div>

          </div>
          <div class="page-view d-none d-md-block p-2" *ngIf="tools.show.pageView">
            <div *ngFor="let image of imagePage; let i = index" [attr.data-index]="1"
              [ngClass]='page.pagenumber == (i + 1) ? "sursor-pointer page-border action w-100 d-flex justify-content-center p-1 my-3" : "sursor-pointer page-border w-100 d-flex justify-content-center p-2 my-2"'
              (click)="clickPage(i)">
              <span class="num-pag-001">{{(i+1)}}</span>
              <img src='{{image}}' width="75%" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-alert></app-alert>
    <app-alert-confirm></app-alert-confirm>
    <app-alert-shared></app-alert-shared>
  </div>

