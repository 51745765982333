import { Component, OnInit } from '@angular/core';
import { RecipientService } from './recipient.service';
import { ActivatedRoute } from '@angular/router';
import { AuthLoginService } from '../../../service/authLogin/auth-login.service';

@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.scss']
})
export class RecipientComponent implements OnInit {
  state: string;
  shortUrl: string;
  constructor(
    private recipientService: RecipientService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthLoginService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      params => {

        this.recipientService.setShortUrl(params.cont.replace(/#/g, ""));
      });
  }

  ngDoCheck(): void {
    if (this.recipientService.getOnState()) {
      this.state = this.recipientService.getState();
      this.recipientService.setOnState(false);
    }
  }
}
