import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { RecipientService } from '../recipient/recipient.service';
import { ContractService } from '../../../service/contract/contract.service';
import { LoadingService } from '../../../shared/loading/loading.service';
import { AlertService } from '../../../component/modal/alert/alert.service';
import { AlertSharedService } from '../../../shared/modal/alert-shared/alert-shared.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-recipient-confirmation-email',
  templateUrl: './recipient-confirmation-email.component.html',
  styleUrls: ['./recipient-confirmation-email.component.scss', '../../../../frontpage/forgot-password/forgot-password.component.scss']
})
export class RecipientConfirmationEmailComponent implements OnInit {

  formInputMail: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private recipientService: RecipientService,
    private contractService: ContractService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private alertSharedService: AlertSharedService
  ) { }

  ngOnInit(): void {
    this.openContractRecipient();
    this.initFormInputMail();
  }

  initFormInputMail() {
    this.formInputMail = this.fb.group({
      contractMail: ['', Validators.email],
    });
  }

  onClickBtnOk() {
    let data = {
      contractMail: this.formInputMail.get('contractMail').value,
      shortUrl: this.recipientService.getShortUrl()
    }
    this.loadingService.openLoading();
    this.contractService.checkRecipientConfirmationEmail(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.recipientService.setMail(this.formInputMail.get('contractMail').value);
        this.recipientService.setVerifi(rs.data.verifiPwd, rs.data.verifiPhone);
        this.recipientService.setContNm(rs.data.nameContract);
        this.recipientService.setEmailEnter(true);
        this.recipientService.setState('phone');
      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "이메일 형식을 확인해주세요.",
            subTitle: ""
          },
          [
            {
              btnType: 'close',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {

              }
            }
          ]
        );
      }
    });

  }

  openContractRecipient(): void {
    this.loadingService.openLoading();
    this.contractService.openContractRecipient(this.recipientService.getShortUrl()).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        if(rs.data.useExpiryYn === 'Y' && rs.data.expTime > rs.data.expiryDay){
          this.alertSharedService.open(
            {
              title: "알림",
              btnClose: false
            },
            {
              title: "만료된 계약서 입니다",
              subTitle: ""
            },
            [
              {
                btnType: 'close',
                btnNm: '확인',
                btnStyle: 'btn-black',
                btnFunc: () => {
                  this.goToHome();
                }
              }
            ]
          );
        }
      }
    });
  }


  goToHome() {
    this.router.navigate(['/']);
  }
}
