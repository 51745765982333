

<section class="w-100" style="background-color: #e2e6eb;min-height: 90vh;">
  <div class="container-lg ml-lg-0 mr-lg-auto p-0">

    <div class="w-100 d-flex justify-content-start align-items-center">
      <h3 class="tit-pag-up w-100 py-1">
        문서 업로드해서 만들기
        <span><img src="../../../../assets/img/icon/icon-arrow-right-cr.png" style="vertical-align: baseline;"
            class="img-fluid mx-1"></span>
        <span class="px-2 font-weight-700">문서 업로드</span>
      </h3>
    </div>

    <div class="row">

      <div class="col-12">
        <div ng class="w-100 bd p-2">
          <div class="d-flex flex-column align-items-center justify-content-center bod-ico-up-loa-001" id="drop-area">
            <app-loading-progress></app-loading-progress>
            <div class="w-100 h-100 d-flex flex-column align-items-center" *ngIf="upload">
              <img src="../../../../assets/img//icon/upload-icon.png" class="img-fluid text-center">
              <p class="det-up-loa-001 text-center">
                파일을 드래그하거나 파일 찾기 버튼을 클릭하여 <br /> 업로드할 문서를 선택해주세요.
              </p>

              <form [formGroup]="uploadForm">
                <input type="file" name="files" accept=".pdf, .hwp, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png"
                  style="display: none;" id="files" (change)="onFileSelected($event)" #files>
              </form>
              <button class="btn btn-middle-long btn-white" (click)="files.click()">
                <img src="../../../../assets/img//icon/plusShape.png">
                파일 찾기
              </button>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center justify-content-center bod-tex-up-loa-001">
            <p class="tex-not-001">지원 파일 : pdf, hwp, docx, xls, xlsx, ppt, pptx, jpg, jpeg, png 등 / 업로드 가능 용량 : 1Mb
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>


<app-loading></app-loading>
<app-alert></app-alert>
