<section class="container2"> 
  <section class="container-fluid p-0">
      <div class="w-100 d-flex justify-content-start align-items-center">
        <h3 class="tit-pag-up w-100 py-1">
          <span class="px-2 font-weight-700">수신자 선택 및 전송 </span>
          <button class="btn d-none btn-middle-short btn-black mt-md-0 mt-3" (click)="switchView()">switch view</button>
        </h3>
      </div>

    </section>

    <section class="container-fluid p-0" *ngIf="linkedId == '000001'">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="form-group mb-0">
            <form [formGroup]=formNameTemplate id="formNameTemplate">
              <label for="nameTemplateInput" class="tit-inp-001">계약서 명</label>
              <input type="search" class="form-control inp-tmp-nam-001" formControlName='nameTemplate'
                placeholder="부동산임대차계약서" id="nameTemplateInput" aria-describedby="nameTemplate">
              <small id="nameTemplate" class="form-text text-muted"></small>
            </form>
          </div>
        </div>
      </div>

      <div style="margin-top: 24px;" class="d-flex align-items-center">
        <div><label class="mb-0">수신자</label></div>
        <div class=" ml-auto ">

    
          <form [formGroup]=formSequence  class="form-inline">

            <ul class="radio-wrap">
              <li>
                  <input id="radio1" class="radio" type="radio" formControlName="sequenceYn" name="sequenceYn" value="Y">
                  <label for="radio1" class="radio-label">
                    순차전송
                  </label>
              </li>
              <li>
                <input id="radio2" class="radio" type="radio" formControlName="sequenceYn" name="sequenceYn" value="N">
                <label for="radio2" class="radio-label">
                  동시전송
                </label>
              </li>
            </ul>
            <button class="btn btn-small-long btn-black" [disabled]="(editorPdfService.getColorSign().length > 1)"
              (click)="openModal()">
              엑셀 대량 전송
            </button>
          </form>
        </div>
      </div>

      <div class="row m-0">
        <div class="col-12 p-0" style="height: auto;max-height: 295px;overflow-y: auto;">
          <form [formGroup]=formData id="formData">
            <table style="width: 955px;">
              <tbody class="tableForm">
                <ng-container formArrayName="datas" *ngFor="let arrayData of arrayDatas;let i = index">
                  <tr class="my-1 table-row-group" [formGroupName]="i">

                    <td style="width: 35%">
                      <div class="input-group tableinputA">
                        <div class="input-group-prepend  "
                        [ngStyle]="{'border': '3px solid ' + arrayData.color,'color': arrayData.color,'border-radius':'5px'}">
                          <span class="input-group-text p-0 border-0  rowNumber">{{(i+1)}}</span>
                        </div>

                        <div class=Aholder>

                          <input type="search" class="form-control" pattern="^-?([^0-9@!#$%^&]+)?$" (change)="onChangeShowAlert(i,'contractNm')" formControlName="contractNm" name="name[{{i}}]"
                            value="{{arrayData.contractNm}}" placeholder="이름" aria-describedby="">
                          <small *ngIf="alertMessage[i].contractNm"
                          class="form-text text-muted text-validators {{formData.get('datas').get(i.toString()).get('contractNm').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                              alt="alert icon"> 문자만 입력해 주세요.
                          </small>
                        </div>
                      </div>
                    </td>
                    <td class="tableinputB" style="width: 35%">
                      <input type="search" class="form-control" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" (change)="onChangeShowAlert(i,'contractEmail')" formControlName="contractEmail" name="email[{{i}}]"
                        value="{{arrayData.contractEmail}}" placeholder="이메일" aria-describedby="">
                      <small *ngIf="alertMessage[i].contractEmail" 
                      class="form-text text-muted  text-validators {{formData.get('datas').get(i.toString()).get('contractEmail').status}} font-16">
                        <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                          alt="alert icon"> 이메일 양식에 맞게 입력해주세요.
                      </small>
                    </td>
                    <td style="width: 30%">
                      <div class="input-group tableinputC">
                        <input type="search" pattern="^-?([0-9]\d*)?$" class="form-control" formControlName="contractMobile" maxlength="11"
                          name="phone[{{i}}]" value="{{arrayData.contractMobile}}" placeholder="휴대폰번호 (숫자만 입력)"
                          aria-describedby="">
                        <div class="input-group-append  ">
                          <span *ngIf="!((editorPdfService.getColorSign().length > 1) || (i == 0))"	
                            class="input-group-text border-0  text-center"> <img
                              src="../../../../assets/img/icon/icon-remove-cr.png" style="cursor: pointer"
                              (click)="removeContact(i)" class="img-fluid"></span>
                        </div>
                      </div>
                      <small	
                      class="form-text text-muted  text-validators {{formData.get('datas').get(i.toString()).get('contractMobile').status}} font-16">	
                      <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"	
                        alt="alert icon"> 숫자만 입력해 주세요.	
                    </small>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-12 text-center p-0">
          <button style="margin-top: 20px; margin-bottom: 32px;" [disabled]="(editorPdfService.getColorSign().length > 1)"	
            (click)="onClickBtnPushCont()" class="btn btn-middle-long btn-white">
            <img src="../../../../assets/img//icon/plusShape.png"> 추가하기
          </button>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-12 p-0">
          <label style="margin-bottom: 17px;">설정</label>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-6 my-1" *ngIf="false">
          <div class="rowm-0">
            <div class="col-12">
              <button class="btn btn-middle-short btn-gray">비밀번호 인증</button>
              <input type="search" class="form-control inp-set-001" name="" placeholder="비밀번호 입력 (4~12 문자 및 숫자)" id=""
                aria-describedby="">
            </div>
          </div>
        </div>

      </div>

      <div class="clickArea">
        <form [formGroup]=formInputPassword id="formInputPassword" class="form-inline my-2">
          <button type="button" class="btn btn-middle-short {{btnPassword}} authbtns"  style="width: 140px;"  (click)="configPassword()">비밀번호
            인증</button>
          <input type="password" id="contorPass" class="form-control recipientPassword" name="contorPass"
            formControlName="contorPass" placeholder="비밀번호 입력 (4~12 문자 및 숫자)" id="contorPass">

        </form>

        <div class="d-flex align-items-center my-2">
          <button class="btn btn-middle-short {{btnOtpPhone}} authbtns" style="width: 140px;" (click)="configPhoneOtp()">휴대폰 본인 인증</button>
          <label style="font-size: 15px;" class="applyto">휴대전화가 입력된 수신자에게만 적용됩니다.</label>
        </div>
        <label style="font-size: 15px;" class="applytoM">휴대전화가 입력된 수신자에게만 적용됩니다.</label>

      </div>

      <div class="d-flex justify-content-center">
        <button class="btn btn-big btn-gray" style="width: auto !important;" type="button" name="" id=""
          (click)="goBackEditor()">이전</button>
        <button class="btn btn-big btn-crimson ml-3" [disabled]="!formData.get('datas').valid" style="width: auto !important;" type="button" (click)="checkDataForm()"
          name="" id="">전송</button>
      </div>

    </section>
</section>

<app-select-recipients *ngIf="linkedId == '000002'"></app-select-recipients>
<app-modal></app-modal>
<app-alert-confirm></app-alert-confirm>
<app-alert></app-alert>