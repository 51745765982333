<section class="container h-100">


    <div class="h-100 row d-flex justify-content-center align-items-center" style="height: 100vh !important;">

        <div style="border-top: 2px solid #111; " class=" d-flex bg-white justify-content-center">
            <form class="confirmForm">

                <div class="forgotTxthead text-center ">
                    <img width="125" src="../../../../assets/img/frontpage/logo_gcont2x.png" />
                    <h3><b>전자 계약서 작성 동의</b></h3>

                    <div class="listbox d-flex justify-content-center">

                        <ul>
                            <li>문서의 서명 및 작성 내용이 본인의 것임을 인정합니다.</li>
                            <li>온라인 계약의 서명 및 내용의 법적 효력에 동의합니다.</li>
                            <li>계약서 작성에 따른 법적 책임이 작성자에게 있음을 인정합니다.</li>
                            <li>기타 서비스 약관 및 개인정보 처리방침에 동의합니다.</li>
                        </ul>

                    </div>



                </div>

                <div class="form-group row d-flex justify-content-center mb-0 confirmFormInput">
                    <button class="btn btn-big btn-gray" style="width: auto !important;" type="button" name="" (click)="onPrevious('password')"
                        id="">이전</button>
                    <button class="btn btn-big btn-crimson " style="width: auto !important;" (click)="onClickBtnOk()"
                        type="button">
                        작성
                    </button>
                </div>
            </form>
        </div>
    </div>




</section>