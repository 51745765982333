import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { NavbarService } from '../navbar/navbar.service';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'], host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class HomeComponent implements OnInit {
  navigationSubscription;
  constructor(
    private router: Router,
    private navbarService: NavbarService
  ) { 
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.navbarService.setNavbarTitle('');
      }
    });
  }

  onResize() {
    this.initScoll();
  }

  ngOnInit(): void {
    this.initScoll();
    this.navbarService.setNavbarTitle('');
  }

  initScoll() {
    if ($(window).width() < 1199 && $(window).width() > 750) {
      
      $('body').scrollspy({
        target: '#mainNavTwo',
        offset: 100
      });

      // Collapse Navbar

      try {
        var navbarCollapse = function () {
          if ($("#mainNavTwo").offset() !== undefined) {
            if ($("#mainNavTwo").offset().top > 100) {
              $("#mainNavTwo").addClass("navbar-shrink");
            } else {
              $("#mainNavTwo").removeClass("navbar-shrink");
            }
          }

        };
      } catch (e) {

      }

      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);
    } else {

      $('body').scrollspy({
        target: '#mainNav',
        offset: 100
      });

      // Collapse Navbar

      try {
        var navbarCollapse = function () {
          if ($("#mainNav").offset() !== undefined) {
            if ($("#mainNav").offset().top > 100) {
              $("#mainNav").addClass("navbar-shrink");
            } else {
              $("#mainNav").removeClass("navbar-shrink");
            }
          }

        };
      } catch (e) {

      }

      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);
    }

  }

}
