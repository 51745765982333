import { Component, OnInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalAttachmentFileService } from './modal-attachment-file.service';
import { DigitalsignatureService } from '../../../service/digitalSignature/digitalsignature.service';

import { LoadingProgressService } from "../../../shared/loading-progress/loading-progress.service";
import { LoadingService } from '../../../shared/loading/loading.service';
import { AlertService } from '../../modal/alert/alert.service';


import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-attachment-file',
  templateUrl: './modal-attachment-file.component.html',
  styleUrls: ['./modal-attachment-file.component.scss']
})
export class ModalAttachmentFileComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private modalAttachmentFileService: ModalAttachmentFileService,
    private digitalsignatureService: DigitalsignatureService,
    private loadingProgressService: LoadingProgressService,
    private loadingService: LoadingService,
    private alertService: AlertService,
  ) { }

  show: boolean = false;
  dropZoneInit: boolean = true;
  upload: boolean = true;
  uploadForm: FormGroup;
  btnDisabled: boolean = true;

  ngOnInit(): void {

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    if (this.modalAttachmentFileService.change.show) {
      this.show = this.modalAttachmentFileService.show;
      this.modalAttachmentFileService.setChange('show', false);
      this.dropZoneInit = true;
    }

    if (this.show) {
      this.initDropZoneArea();
    }
  }

  onClickBtn(btn: any) {
    console.log(btn)
    this.modalAttachmentFileService.btn[btn].func();
  }

  closeModal() {
    this.modalAttachmentFileService.close();
  }


  onFileSelected(event) {
    let fileSize: number = 0;
    let file = event.target.files[0];
    fileSize = file.size;
    // Allowing file type
    var allowedExtensions = /(\.pdf|\.hwp|\.docx|\.xls|\.xlsx|\.ppt|\.pptx|\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(file.name)) {
      this.alertService.open(
        "지원하지 않는 파일 type 입니다.",
        "",
        {
          func: () => {
            this.alertService.close();
          }
        },
        {
          func: () => {
            this.alertService.close();
          }
        }
      );
    } else {
      if (fileSize <= 1048576) {
        this.uploadForm.get('profile').setValue(file);
        this.uploadAttachmentFile();

      } else {
        this.alertService.open(
          "문서 크기가 너무 큽니다.",
          "문서 크기는 1MB를 초과 할 수 없습니다.",
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }
    }
  }


  uploadAttachmentFile() {
    this.upload = false;
    this.loadingProgressService.openLoading();
    const formData = new FormData();
    formData.append('files', this.uploadForm.get('profile').value);
    this.digitalsignatureService.uploadAttachmentFile(formData).subscribe(rs => {
      if (rs.type === HttpEventType.UploadProgress) {
        let progress = Math.round(100 * rs.loaded / rs.total);
        this.loadingProgressService.setProgress(progress);
        this.loadingProgressService.setTextProgress(progress + '%');
      } else if (rs instanceof HttpResponse) {
        if (rs.body.status) {
          console.log(rs.body.data);
          this.modalAttachmentFileService.setFileId(rs.body.data.fileId);
          this.modalAttachmentFileService.setFileName(rs.body.data.fileName);
          this.loadingProgressService.closeLoading();
          this.upload = true;
          this.btnDisabled = false;
        }
      }
    },
      err => {
        this.loadingProgressService.closeLoading();
        this.loadingProgressService.setProgress(0);
        this.loadingProgressService.setTextProgress("0%");
      }
    );

  }

  // Drop Zone Initial
  initDropZoneArea() {
    const dropArea = document.getElementById('drop-area');
    if (this.dropZoneInit && dropArea != null) {
      this.dropZoneInit = false;
      console.log(dropArea, "initDropZoneArea");
      ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, (e) => {
          e.preventDefault();
          e.stopPropagation();
        }, false)
      })

      dropArea.addEventListener('drop', (e) => {
        let dt = e.dataTransfer
        let files = dt.files;
        let ev = {
          target: {
            files
          }
        }
        this.onFileSelected(ev);
      }, false);
    }

  }
}
