<!-- New area -->

<section class="tabgrid" *ngIf="viewMode.detail">



  <div class="largerUi">
    <div class="row formColumns">

      <!-- Col-Break -->


      <div class="col-lg-12">
        <label class="flex-grow-1 bd-highlight tit-set-001 barumBold">메일 템플릿 상세</label>
        <form>
          <!-- Form C1 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                계약서 명</span>
            </div>
            <input readonly style="border: none;" type="text" name="tempmNm" placeholder="{{item.tempmType}}"
              class="form-control" />
          </div>
          <!-- Form C2 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">메일 제목</span>
            </div>
            <input readonly style="border: none;" type="text" name="tempmTitle"
              placeholder="{{item.tempmTitle}}" class="form-control" />
          </div>
          <!-- Form C4 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text  mailInputC">메일 내용</span>
            </div>
            <div class="text-box">{{item.tempmContent}}</div>
          </div>

        </form>
        <div class="my-3 col-12 d-flex justify-content-end">
          <button (click)="switchViewEdit()" type="button" class="btn ml-2 btn-black btn-middle-short">수정</button>
        </div>
        <div class="my-0 col-12 d-flex justify-content-center">
          <button (click)="clickBack()" class="btn btn-big crimsonbg">
            확인
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="smallerUi">
    <div class="mailManage">
      <div class="row formColumns">

        <!-- Col-Break -->


        <div class="col-lg-12">
          <label class="flex-grow-1 bd-highlight tit-set-001 barumBold">메일 템플릿 상세</label>
          <form class="mailForm1">
            <!-- Form C1 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">계약서 명</span>
              </div>
              <input  style="border: none;" type="text" name="tempmNm" placeholder="{{item.tempmNm}}"
              class="form-control" />
            </div>
            <!-- Form C2 -->

            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">메일 제목</span>
              </div>
            <input  style="border: none;" type="text" name="tempmTitle"
            placeholder="{{item.tempmTitle}}" class="form-control" />
            </div>
            <!-- Form C4 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text  mailInputC">메일 내용</span>
              </div>

              <div class="text-box">{{item.tempmContent}}</div>
            </div>

          </form>
          <div class=" p-0 col-12 d-flex justify-content-end">
            <button type="button" (click)="switchViewEdit()" class="btn mailBtnMarg btn-black btn-middle-short barumReg"> 수정 </button>
          </div>

          <div class="my-0 col-12 d-flex justify-content-center">
            <button (click)="clickBack()" class="btn btn-big crimsonbg">
              확인
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>


</section>

<div class="container-lg ml-lg-0 mr-lg-auto p-0 m" style="background: #e2e6eb;"  *ngIf="viewMode.edit">
  <div class="largerUi">
    <div class="row formColumns">
      <!-- Col-Break -->
      <div class="col-lg-12">
        <label class="flex-grow-1 bd-highlight tit-set-001 barumBold">메일 템플릿 상세</label>
        <form [formGroup]=formCreateTemplateMail>
          <!-- Form C1 -->
          <!-- Form C2 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">계약서 명</span>
            </div>

            <select formControlName='tempmType' style="font-size: 13px;" class="form-control pad-lef-10" placeholder="계약서 명">
              <option value="N">-</option>
              <option *ngFor="let tempmType of tempmTypes" value="{{tempmType.code}}">{{tempmType.codeNm}}</option>
            </select>
          </div>
          <!-- Form C3 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">메일 제목</span>
            </div>
            <input formControlName='tempmTitle' type="text" name="tempmTitle" placeholder="메일 제목"
              class="form-control pad-inp" />
          </div>
          <!-- Form C4 -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text  mailInputC">메일 내용</span>
            </div>

            <textarea formControlName='tempmContent' row="5" name="tempmContent"
            placeholder="메일 내용" class="form-control pad-inp"></textarea>

          </div>

        </form>
      </div>
    </div>
  </div>

  <div class="smallerUi">
    <div class="mailManage">
      <div class="row formColumns">

        <!-- Col-Break -->
        <div class="col-lg-12">
          <label class="flex-grow-1 bd-highlight tit-set-001 barumBold">메일 템플릿 상세</label>
          <form class="mailForm1" [formGroup]=formCreateTemplateMail>
            <!-- Form C1 -->

            <!-- Form C2 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">계약서 명</span>
              </div>

              <select formControlName='tempmType' class="form-control pad-lef-10" placeholder="계약서명 입력">
                <option value="N">-</option>
                <option *ngFor="let tempmType of tempmTypes" value="{{tempmType.code}}">{{tempmType.codeNm}}</option>
              </select>
            </div>
            <!-- Form C3 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">메일 제목</span>
              </div>
              <input formControlName='tempmTitle' type="text" name="tempmTitle" placeholder="메일 제목 "
                class="form-control pad-inp" />
            </div>
            <!-- Form C4 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text  mailInputC">메일 내용</span>
              </div>

              <textarea formControlName='tempmContent' name="tempmContent"
                placeholder="메일 내용" class="form-control pad-inp"></textarea>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="m-0 p-0 col-12 d-flex justify-content-end">
    <button disabled type="button" class="btn mailBtnMarg btn-black btn-middle-short barumReg">수정</button>
  </div>

  <div class="my-0 col-12 d-flex justify-content-center">
    <button (click)="onSubmit()" class="mailRed btn btn-big crimsonbg">
      등록
    </button>
  </div>

</div>
<app-alert-shared></app-alert-shared>
