import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-refund-details',
  templateUrl: './refund-details.component.html',
  styleUrls: ['./refund-details.component.scss']
})
export class RefundDetailsComponent implements OnInit {

  constructor() { }

  @Input() itemInquie: any = {
    answCont: '',
    answId: '',
    frstReginPnttm: '',
    quesCont: '',
    quesId: '',
    quesStatus: ''
  };

  inuire: {
    answCont: string,
    answId: string,
    frstReginPnttm: string,
    quesCont: string,
    quesId: string,
    quesStatus: string
  } = {
      answCont: '',
      answId: '',
      frstReginPnttm: '',
      quesCont: '',
      quesId: '',
      quesStatus: ''
    }


  ngOnInit(): void {
  }

  ngDoCheck(): void {
    if (this.inuire.quesId != this.itemInquie.quesId) {
      this.inuire = this.itemInquie;
    }
  }

}
