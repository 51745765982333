<!-- New Continaer  -->

<section class="w-100" style="background-color: #e2e6eb;min-height: 90vh;">



  <div class="container-lg ml-lg-0 mr-lg-auto p-0 ">
    <div class="largerUi">
      <h3 _ngcontent-yjk-c119="" class="tit-tem-man-001" style="padding-bottom: 25px;">메일 템플릿 관리</h3>
    </div>


    <app-mail-management *ngIf="state.tempm" (clickItem)="clickItem($event)" [api]="apiGet"
      (getSuccess)="sendInquiry($event)"></app-mail-management>
    <app-mail-registration (sendInquiry)="sendInquiry($event)" *ngIf="state.create"></app-mail-registration>
    <app-mail-detail *ngIf="state.tempmDetail" [selectItem]="item" (goBack)="goBack($event)"></app-mail-detail>
  </div>


</section>

<app-alert-shared></app-alert-shared>
<app-alert></app-alert>
<app-loading></app-loading>