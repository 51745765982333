<div class="bg-modal" *ngIf="show">
  <!-- Modal -->
  <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">
        <div class="modal-body rounded-0" style="background: #ffffff;color: #000000;" [formGroup]="form">
            <div class="payment-pop" *ngIf="popUp === 'req'">
                <div class="payment-title">
                    <h1>서비스 신청하기</h1>
                    <span class="close">
                        <a href="javascript:void(0)" (click)="closeModal()">
                            <img src="./assets/img/frontpage/close-pop.png" alt="닫기" >
                        </a>
                    </span>
                </div>

                <section>
                    <div class="payment-con">
                        <section>
                            <h3>구입 상품 확인</h3>
                            <div class="pc">
                                <table class="payment-table">
                                    <colgroup>
                                        <col width="15%"><col width="35%"><col width="15%"><col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>결제상품</th>
                                            <td>{{inputValue.m_item_name}}</td>
                                            <th>할인율</th>
                                            <td>{{inputValue.dc}}%</td>
                                        </tr>
                                        <tr>
                                            <th>전송 건수</th>
                                            <td>{{inputValue.m_quantity}}건</td>
                                            <th>총 결제 금액</th>
                                            <td>{{inputValue.m_total_amount | addComma}}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mobile">
                                <table class="payment-table">
                                    <colgroup>
                                        <col width="120px"><col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>결제상품</th>
                                            <td>{{inputValue.m_item_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>할인율</th>
                                            <td>{{inputValue.dc}}%</td>
                                        </tr>
                                        <tr>
                                            <th>전송 건수</th>
                                            <td>{{inputValue.m_quantity}}건</td>
                                        </tr>
                                        <tr>
                                            <th>총 결제 금액</th>
                                            <td>{{inputValue.m_total_amount | addComma}}원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>

                        <section class="margin-t20">
                            <h3>최종 결제 금액</h3>
                            <div class="total-wrap">
                                <div class="total"><span class="num">{{inputValue.m_total_amount | addComma}}</span>원</div>
                                <div class="vat">
                                    <span class="red">선택 상품 요금 :</span> {{((inputValue.m_item_price) * (inputValue.m_quantity)) | addComma}}원/ <span class="red">부가세</span> {{inputValue.m_vat | addComma}}원 포함
                                </div>
                            </div>
                        </section>

                        <section class="margin-t20">
                            <h3>결제 수단 선택</h3>
                            <div class="payment-box">
                                <ul class="radio-wrap">
                                    <li>
                                        <input id="radio1" class="radio" name="paymentType" type="radio" [value]="'CARD'" formControlName="paymentType">
                                        <label for="radio1" class="radio-label">
                                            신용카드
                                        </label>
                                    </li>
                                    <li>
                                        <input id="radio2" class="radio" name="paymentType" type="radio" [value]="'BANK'" formControlName="paymentType">
                                        <label for="radio2" class="radio-label">
                                            실시간 계좌이체
                                        </label>
                                    </li>
                                    <li>
                                        <input id="radio3" class="radio" name="paymentType" type="radio" [value]="'VBANK'" formControlName="paymentType">
                                        <label for="radio3" class="radio-label">
                                            가상계좌
                                        </label>
                                    </li>
                                    <li>
                                        <input id="radio4" class="radio disabled" name="paymentType" type="radio" [value]="'휴대폰'" formControlName="paymentType">
                                        <label for="radio4" class="radio-label disabled">
                                            휴대폰 결제
                                        </label>
                                    </li>
                                    <li>
                                        <input id="radio7" class="radio disabled" name="paymentType" [value]="'virtualCurrency'" type="radio" formControlName="paymentType">
                                        <label for="radio7" class="radio-label disabled">
                                            암호화폐
                                        </label>
                                    </li>
                                    <li>
                                        <span class="txt">휴대폰 결제, 암호화폐 결제는 추후 서비스 예정</span>
                                    </li>
                                </ul>
                            </div>
                        </section>

                        <section class="margin-t20">
                            <h4>유의사항</h4>
                            <ul class="sm-notice">
                                <li>- 구입하신 상품을 사용하신 이후에는 환불이 불가합니다.</li>
                                <li>- 다른 상품을 선택하시려면 아래 “취소” 버튼을 선택해주세요.</li>
                            </ul>
                        </section>

                        <section class="margin-t20">
                            <input type="checkbox" name="" id="checkbox1" class="checkbox checkbox-form" formControlName="checkBox">
                            <label for="checkbox1" class="checkbox-label checkbox-form-label">개인(신용)정보 수집, 이용 및 제공에 동의합니다.</label>
                        </section>

                        <section class="margin-t30">
                            <div class="btn-wrap">
                                <span class="btn-gray2" (click)="closeModal()"><a href="javascript:void(0)">취소</a></span>
                                <span class="btn-red2" (click)="pay()"><a href="javascript:void(0)" >결제하기</a></span>
                            </div>
                        </section>
                    </div>
                </section>
            </div>

            <!-- 완료 [s] -->
            <div class="payment-pop" *ngIf="popUp === 'complete'">
                <div class="payment-title">
                    <h1>서비스 신청하기</h1>
                    <span class="close">
                        <a href="javascript:void(0)" (click)="closeModal()">
                            <img src="./assets/img/frontpage/close-pop.png" alt="닫기" >
                        </a>
                    </span>
                </div>

                <section>
                    <div class="payment-con">
                        <section class="margin-t30">
                            <div class="pop_complete">
                                결제가 정상적으로 완료 되었습니다.
                            </div>
                        </section>

                        <section class="margin-t20">
                            <h3>주문 상품</h3>
                            <table class="payment-table">
                                <colgroup>
                                    <col width="130px"><col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>주문상품</th>
                                        <td>{{inputValue.m_item_name}}</td>
                                    </tr>
                                    <tr>
                                        <th>전송 건수</th>
                                        <td>{{inputValue.m_quantity}}건</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>

                        <section class="margin-t20">
                            <h3>결제정보</h3>
                            <table class="payment-table">
                                <colgroup>
                                    <col width="130px"><col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th class="bold">최종 결제 내역</th>
                                        <td class="text-r bold fz18">{{inputValue.m_total_amount | addComma}}원</td>
                                    </tr>
                                    </tbody>
                            </table>
                        </section>

                        <section class="margin-t30">
                            <div class="btn-wrap">
                                <span class="btn-basic2" (click)="closeModal()"><a href="javascript:void(0)">확인</a></span>
                            </div>
                        </section>

                    </div>
                </section>
            </div>
            <!-- 완료 [e]-->

        </div>
      </div>
    </div>
  </div>
</div>
