<div class="bg-modal" *ngIf="show">
  <!-- Modal -->
  <div class="modal fade show" style="display: block;" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">

        <div class="modal-header rounded-0 pb-0 border-0 px-4 pt-4" style="background: #ffffff;color: #000000;">

          <div class="border-bottom border-dark d-flex w-100 pb-3 ">
            <h5 class="modal-title font-weight-light" id="exampleModalLabel">전자계약서 상세정보</h5>
            <a style="color: #000000;" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>

        </div>





        <div class="modal-body rounded-0 p-4" style="background: #ffffff;color: #000000; overflow: auto;">
          <div class="row">
            <div class="col-md-6">
              <div class="card my-2 rounded-0">
                <div class="card-header">
                  <span class="tex-tit-bod-001">계약서 정보</span>
                </div>
                <div class="card-body bod-con-inf-001 py-2">
                  <div class="card-text">
                    <span class="tex-tit-001">Form name:&nbsp;</span><span
                      class="tex-det-001">{{contracDetails.tempNm}}</span>
                  </div>
                  <div class="card-text">
                    <span class="tex-tit-001">Form ID:&nbsp;</span><span
                      class="tex-det-001">{{contracDetails.tempId}}</span>
                  </div>
                  <div class="card-text">
                    <span class="tex-tit-001">Sending method:&nbsp;</span><span class="tex-det-001">One-click URL</span>
                  </div>
                  <div class="card-text">
                    <span class="tex-tit-001">Sent: &nbsp;</span><span
                      class="tex-det-001">{{contracDetails.sendDate}}</span>
                  </div>
                  <div class="card-text">
                    <span class="tex-tit-001">Location:&nbsp;</span><span class="tex-det-001">No location
                      information</span>
                  </div>
                  <div class="card-text">
                    <span class="tex-tit-001"> Time:&nbsp;</span><span class="tex-det-001">KST(UTC+9)</span>
                  </div>
                </div>
              </div>
              <div class=" card my-2 rounded-0">
                <div class="card-header">
                  <span class="tex-tit-bod-001">계약서 보낸 사람</span>
                </div>
                <div class=" card-body py-2">
                  <div class="card-text">
                    <span class="tex-tit-001">Name:&nbsp;</span><span
                      class="tex-det-001">{{contracDetails.contSendNm}}&nbsp;({{contracDetails.contSendMail}})</span>
                  </div>
                  <div class="card-text">
                    <span class="tex-tit-001">Verification:&nbsp;</span><span class="tex-det-001"> Verified by
                      email</span>
                  </div>
                </div>
              </div>
              <div class=" card my-2 rounded-0">
                <div class="card-header">
                  <span class="tex-tit-bod-001">계약서 받은 사람</span>
                </div>
                <div class=" card-body py-2">
                  <div class="card-text">
                    <span class="tex-tit-001">Name:&nbsp;</span><span
                      class="tex-det-001">{{contracDetails.contReceNm}}&nbsp;({{contracDetails.contReceMail}})</span>
                  </div>
                  <div class="card-text">
                    <span class="tex-tit-001">Verification:&nbsp;</span><span class="tex-det-001"> Verified by
                      email</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class=" card my-2 rounded-0">
                <div class="card-header">
                  <span class="tex-tit-bod-001">계약서 작성 히스토리</span>
                </div>
                <div class="py-0 card-body bod-con-his-001">
                  <ul class="list-group list-group-flush ">
                    <ng-container *ngFor="let history of contHistory">
                      <li class="list-group-item px-0">
                        <div class="card-text">
                          <span class="tex-det-001">'{{history.userNm}}'</span><span
                            class="tex-tit-001">&nbsp;{{history.histProgress}}&nbsp;</span>
                        </div>
                        <div class="card-text">
                          <span class="tex-tit-001">{{history.histDate}}&nbsp;/&nbsp;</span><span
                            class="tex-det-001">({{history.histIp}})</span>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>

            <div class="w-100 text-center pt-3">

              <button class="btn btn-middle-short btn-white" (click)="closeModal()">닫기</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>