import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { RecipientService } from '../recipient/recipient.service';
import { ContractService } from '../../../service/contract/contract.service';
import { AlertService } from '../../modal/alert/alert.service';
import { AlertSharedService } from '../../../shared/modal/alert-shared/alert-shared.service';
import { LoadingService } from '../../../shared/loading/loading.service';
import StringFormat from '../../../shared/string-format/string-format';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/app/manage/service/api.constant';

@Component({
  selector: 'app-recipient-confirmation-phone',
  templateUrl: './recipient-confirmation-phone.component.html',
  styleUrls: ['./recipient-confirmation-phone.component.scss', '../../../../frontpage/forgot-password/forgot-password.component.scss']
})
export class RecipientConfirmationPhoneComponent implements OnInit {
  stringFormat: StringFormat
  formInputPhone: FormGroup;
  formInputOtpCode: FormGroup;


  objOtp: {
    uuid: string,
    refCode: string
  }
    =
    {
      uuid: "",
      refCode: ""
    }

  show: {
    phoneNumber: string,
    otpCode: string
  }
    =
    {
      phoneNumber: "d-flex",
      otpCode: "d-none"
    };

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private recipientService: RecipientService,
    private contractService: ContractService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private alertSharedService: AlertSharedService,
    private http: HttpClient,
    private _ngZone: NgZone,
  ) {
    this.stringFormat = new StringFormat();
    window['RecipientConfirmationPhoneComponentRef'] = {component: this, zone: _ngZone, loadAngularFunction: () => this.nextPage(),};
  }

  ngOnInit(): void {

    this.initFormInputPhone();
    this.initFormInputOtp();
  }

  initFormInputPhone() {
    this.formInputPhone = this.fb.group({
      contractMobile: ['', [Validators.required]]
    });
  }

  initFormInputOtp(): void {
    this.formInputOtpCode = this.fb.group({
      otpCode: ['', [Validators.required]]
    });
  }

  onClickBtnOk() {
    let data = {
      contractMobile: this.formInputPhone.get('contractMobile').value,
      shortUrl: this.recipientService.getShortUrl(),
      uuid: this.stringFormat.genUUIDv4()
    }
    this.loadingService.openLoading();
    this.contractService.checkRecipientConfirmationPhone(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        if (this.recipientService.getVerifi().phone === 'Y') {
          this.http.get<any>(API_URL.mobileVerification).subscribe(res => {
            console.log('res', res);
            let sEncData = res.data.encData;
            setTimeout(() => {
            window.open(`https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb?m=checkplusService&EncodeData=${sEncData}`, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            });
          });
          // this.recipientService.setState('type');

        } else {
            this.recipientService.setState('type');
        }

      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "유효하지 않은 전화 번호.",
            subTitle: ""
          },
          [
            {
              btnType: 'close',
              btnNm: '닫기',
              btnStyle: 'btn-black',
              btnFunc: () => {
              }
            }
          ]
        );
      }
    });
  }

  onSendOtp() {
    this.sendCheckOtp();
  }

  sendCheckOtp() {
    let data = {
      codeOtp: this.formInputOtpCode.get("otpCode").value,
      refCode: this.objOtp.refCode,
      uuid: this.objOtp.uuid
    };
    this.loadingService.openLoading();
    this.contractService.checkOtpCode(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.recipientService.setState('type');
      } else {
        this.alertService.open(
          "잘못된 정보.",
          "",
          {
            func: () => {
              this.formInputPhone.get('contractMobile').setValue('');
              this.show.otpCode = "d-none";
              this.show.phoneNumber = "d-flex";
              this.alertService.close();
            }
          },
          {}
        );
      }
    })
  }

  nextPage() {
    this.recipientService.setState('type');
  }

  goToHome() {
    this.router.navigate(['/']);
  }
}
