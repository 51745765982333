import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    Directive,
    Renderer2,
    ElementRef,
    Input,
    HostListener
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {FormBuilder, FormGroup} from "@angular/forms";
import {LoadingService} from "../../manage/shared/loading/loading.service";
import {ServiceCenterService} from "../../manage/service/service-center/service-center.service";
import {DashboardService} from "../../manage/component/dashboard/dashboard.service";
import {CodeBookService} from "../../manage/service/code-book/code-book.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NavbarService} from "../navbar/navbar.service";
import {AuthGuard} from "../../guard/auth.guard";
import {CommunityService} from "../../manage/service/community/community.service";
import {EditorPdfComponent} from "../../manage/component/editor-pdf/editor-pdf.component";
import {EditorPdfService} from "../../manage/component/editor-pdf/editor-pdf.service";
import * as $ from 'jquery';
import {AppModule} from "../../app.module";
import {APP_BOOTSTRAP_LISTENER} from "@angular/core";
import * as bootstrap from "bootstrap";
import {TableRequestedBlockCertService} from "../../manage/component/table-requested-block-cert/table-requested-block-cert.service";
import TokenUtils from "../../manage/shared/token/token-utils";
import {JwtHelperService} from "@auth0/angular-jwt";
import {CommonFunction} from "../../manage/service/common-function/common-function.service";


// declare var $:any;

@Component({
    selector: 'app-community-list',
    templateUrl: './community-list.component.html',
    styleUrls: ['./community-list.component.scss']
})

export class CommunityListComponent implements OnInit {

    constructor(
        private fb: FormBuilder,
        private loadingService: LoadingService,
        private serviceCenterService: ServiceCenterService,
        private communityService: CommunityService,
        private dashboardService: DashboardService,
        private codeBookService: CodeBookService,
        private route: ActivatedRoute,
        private navbarService: NavbarService,
        private authGuard: AuthGuard,
        private editorPdfService: EditorPdfService,
        private router: Router,
        private appModule: AppModule,
        private el: ElementRef,
        private renderer: Renderer2,
        private domSanitizer: DomSanitizer,
        private jwtHelper: JwtHelperService,
        private commonfuction: CommonFunction,
        private tableRequestedBlockCertService: TableRequestedBlockCertService
    ) {
    }

    @Output() selectItem = new EventEmitter<any>();

    formSearch: FormGroup;

    page = 'main';

    trustUrl: string = '';
    config: {
        showTools : false;
    }

    mouseOver: boolean = false;

    display: {
        main: boolean,
        upload: boolean,
    } = {
        main: true,
        upload: false,
    }

    item: any;
    canvas: any[];
    pdfPage: any;
    imagePage: any[];
    pdfcanvas: any;
    thispage: any;

    contentText: string = '';
    active: {
        main: string,
        upload: string,
    } = {
        main: "rdbutton",
        upload: "whbutton",
    }


    communityLists: {
        no: number,
        postId: string,
        postNo: number,
        postNm: string,
        postType: string,
        postTypeNm: string,
        postContent: string,
        postContentPre: string,
        fileId: string,
        postView: number,
        writer: string,
        thumbnail: any,
        pageNum: number
    }[];

    contentLayout = {
        toolps: '',
    };

    categoryList: {
        code: string,
        codeDc: string,
        codeGrpId: string,
        codeNm: string
    }[];

    serachType: {
        code: string,
        codeDc: string,
        codeGrpId: string,
        codeNm: string
    }[] = [];

    postId: string = null;
    params = {
        data: {
            noticText: "",
            noticType: "",
            cateType: "",
            sort: ""
        },
        pageNo: 0,
        pageSize: 10
    }

    pagination = {
        maxPage: 1,
        currentPage: 1
    }

    notic: {
        notRead: {
            no: number,
            postId: string,
            postNo: number,
            postNm: string,
            postType: string,
            postTypeNm: string,
            postContent: string,
            fileId: string,
            postView: number,
            writer: string
        }[]
    } = {
        notRead: []
    }


    onClickItem(item: any) {
        this.selectItem.emit(item);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    onClickBtn(btn: any): void {
        if (this.checkLogin() == true) {

            this.clearDisplay();
            this.display[btn] = true;

            if (btn == 'upload') {
                this.navbarService.setNavbarTitle("계약서 올리기");
            }
        } else {
            this.commonfuction.popupMsg('안내', '로그인[계정 생성]을 해주세요!');
            this.router.navigate(['/login']);
        }
    }


    clearDisplay() {
        this.display = {
            main: false,
            upload: false,
        }

        this.active = {
            main: "whbutton",
            upload: "whbutton",
        }
    }

    initValue() {
        this.canvas = [];
        this.imagePage = [];
        this.pdfPage = {
            maxpage: 1,
            pagenumber: 1
        };
        this.thispage = 1;
    }

    initParamsApi(): void {
        this.pagination.currentPage = this.communityService.getPageNo() + 1;
    }
    ngOnInit(): void {

        this.getCodeBook("NOTI_SEARCH", "COMMUNITY_TYPE");
        this.searchNoticeFront();
        this.initFormSearch();

        this.navbarService.setNavbarTitle("자료실");
        this.route.queryParams.subscribe(
            params => {
                console.log(params)
                // if (params.notice != undefined) {
                //   this.noticeId = params.notice
                // }
            });

        if (this.authGuard.canActivateNav()) {
            this.getPersonalNotReadNotic();
        }
    }

    ngAfterViewInit(): void {
        $(".tooltiptext").html(this.contentText);
    }

    mouseEnter(postId : string, postNm: string){
        this.mouseOver = true;
        this.editorPdfService.setBlockCertId(postId);
        this.editorPdfService.setBlockCertNm(postNm);
        this.editorPdfService.setContorType('%');
        this.editorPdfService.setEdteMode("peview");
        this.editorPdfService.setState("init");
        this.editorPdfService.download = false;
    }

    mouseOut(){
        this.mouseOver = false;
        this.editorPdfService.setBlockCertId('');
        this.editorPdfService.setBlockCertNm('');
    }

    getPersonalNotReadNotic() {
        this.serviceCenterService.getPersonalNotReadNotic().subscribe((rs: any) => {
            if (rs.status) {
                this.notic.notRead = rs.data;
            }
        });
    }

    checkNotRead(noticId: string): string {
        let css: string = '';

        // let checkNoti = this.notic.notRead.filter(res => {
        //   return res.noticId == noticId;
        // });

        // if(checkNoti.length > 0){
        //   css = 'barumBold'
        // }

        return css
    }

    initFormSearch() {
        this.formSearch = this.fb.group({
            serachType: [null],
            searchText: [null]
        });

        this.formSearch.get('searchText').valueChanges.subscribe(selectValue => {
            this.params.data.noticText = selectValue;
        });

        this.formSearch.get("serachType").valueChanges.subscribe(selectValue => {
            this.params.data.noticType = selectValue;
        });

        this.formSearch.get("serachType").setValue("000001");
    }

    searchNoticeFront() {
        this.loadingService.openLoading();
        this.communityService.searchCommunity(this.params).subscribe((rs: any) => {
            if (rs.status) {
                this.communityLists = rs.data;
                for(var i = 0; i < rs.data.length; i++) {
                    this.trustUrl = 'data:image/png;base64,' + rs.data[i].thumbnail
                    this.communityLists[i].thumbnail = this.domSanitizer.bypassSecurityTrustUrl(this.trustUrl);
                    this.loadingService.closeLoading();
                    this.calMaxPage(rs.total, this.params.pageSize);
                }
            }
        });
    }

    calMaxPage(itemTotal, pageSize) {
        if (itemTotal % pageSize == 0) {
            this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
            if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
                this.pagination.currentPage = this.pagination.maxPage;
                this.params.pageNo = this.pagination.currentPage - 1;
            }
            this.loadingService.openLoading();

            this.communityService.searchCommunity(this.params).subscribe((rs: any) => {
                if (rs.status) {
                    this.communityLists = rs.data;
                    console.log(rs.data);
                    for(var i = 0; i < rs.data.length; i++) {
                        this.trustUrl = 'data:image/png;base64,' + rs.data[i].thumbnail
                        this.communityLists[i].thumbnail = this.domSanitizer.bypassSecurityTrustUrl(this.trustUrl);
                        console.log(this.communityLists[i].thumbnail);
                    }
                }

                this.loadingService.closeLoading();

            });
        } else {
            this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
        }
    }

    searchCategory(code: string, index: any) {

        for (var i = 0; i <= this.categoryList.length + 1; i++) {
            if (i == index) {
                $("#category" + i).addClass("active");
                this.params.data.cateType = code;
                this.searchNoticeFront();
            } else {
                $("#category" + i).removeClass("active");
            }
        }
    }

    //   this.serviceCenterService.searchNoticeFront(this.params).subscribe((rs: any) => {
    //     if (rs.status) {
    //       this.communityLists = rs.data;
    //
    //       let noti = this.dashboardService.getNoticId();
    //       if (noti != null || this.noticeId != null) {
    //         let notice = this.communityLists.filter((res: any) => {
    //           return (res.noticId == noti || res.noticId == this.noticeId);
    //         });
    //         console.log(notice, noti);
    //         this.selectItem.emit(notice[0]);
    //       }
    //     }
    //     this.loadingService.closeLoading();
    //   });
    // }

    getCodeBook(type: string, type1: string) {
        this.codeBookService.getCodeBook(type).subscribe((rs: any) => {
            if (rs.status) {
                this.serachType = rs.data;
            }
        });

        this.codeBookService.getCodeBook(type1).subscribe((rs: any) => {
            if (rs.status) {
                this.categoryList = rs.data;
            }
        });
    }


    // When the user clicks on the button, scroll to the top of the document

    setCurrentPage($event) {
        this.pagination.currentPage = $event;
        this.params.pageNo = $event - 1;
        this.tableRequestedBlockCertService.setPageNo($event - 1);
        this.searchNoticeFront();
    }

    checkLogin() {
        const accessToken = TokenUtils.getToken();
        if (accessToken && !this.jwtHelper.isTokenExpired(accessToken)) {
            return true;
        } else {
            return false;
        }
    }


    downLoadClick(postId: string, postNm: string) {
        if (this.checkLogin() == true) {
            this.editorPdfService.setBlockCertId(postId);
            this.editorPdfService.setBlockCertNm(postNm);
            this.editorPdfService.setContorType('%');
            this.editorPdfService.setEdteMode("peview");
            this.editorPdfService.setState("init");
            this.editorPdfService.download = true;
            this.router.navigate(['certification/view']);
        } else {
            this.commonfuction.popupMsg('안내', '로그인[계정 생성]을 해주세요!');
            this.router.navigate(['/login']);
        }

    }

    onClickReview(postId: string, postNm: string) {
        this.editorPdfService.setBlockCertId(postId);
        this.editorPdfService.setBlockCertNm(postNm);
        this.editorPdfService.setContorType('%');
        this.editorPdfService.setEdteMode("peview");
        this.editorPdfService.setState("init");
        this.editorPdfService.download = false;
        this.router.navigate(['certification/view']);
    }

    onClickSort(data: string) {
        if (data == 'order') {
            if (this.params.data.sort == data) {
                this.params.data.sort = 'order1';

                $("#sortOrder").addClass('active');
                $("#downloadOrder").removeClass('active');
                this.searchNoticeFront();
            } else {
                this.params.data.sort = data;
                $("#sortOrder").addClass('active');
                $("#downloadOrder").removeClass('active');
                this.searchNoticeFront();
            }
        } else if (data == 'download') {
            if (this.params.data.sort == data) {
                this.params.data.sort = 'download1';
                $("#sortOrder").removeClass('active');
                $("#downloadOrder").addClass('active');
                this.searchNoticeFront();
            } else {
                this.params.data.sort = data;
                $("#sortOrder").removeClass('active');
                $("#downloadOrder").addClass('active');
                this.searchNoticeFront();
            }
        }

    }
    addFabric($event) {
        // tslint:disable-next-line: radix

        $event.p = (parseInt(this.pdfPage.pagenumber) - 1);
        $event.i = this.canvas[(parseInt(this.pdfPage.pagenumber) - 1)].getObjects().length;
        $event.top = this.canvas[(parseInt(this.pdfPage.pagenumber) - 1)].height / 2;
        $event.left = this.canvas[(parseInt(this.pdfPage.pagenumber) - 1)].width / 2;
        this.canvas[(parseInt(this.pdfPage.pagenumber) - 1)].add($event);
    }

    evnentRequestRender($event) {
        for (let i = 0; i < this.canvas.length; i++) {
            this.canvas[i].requestRenderAll();
        }
    }


}

