import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { ModalService } from '../modal/modal.service';
import { TableDataService } from '../table-data/table-data.service';
import { EditorPdfService } from '../../../manage/component/editor-pdf/editor-pdf.service';
import { AlertConfirmService } from '../modal/alert-confirm/alert-confirm.service';
import { TemplateService } from '../../../manage/service/template/template.service';
import { ContractService } from '../../../manage/service/contract/contract.service';
import { AlertSaveNameTemplateService } from './../../../manage/component/modal/alert-save-name-template/alert-save-name-template.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../frontpage/login/login.service';
import { CommonFunction } from '../../service/common-function/common-function.service';

import { AlertService } from '../../component/modal/alert/alert.service';
import Regex from '../../shared/regex/regex';
import { LoadingService } from '../../shared/loading/loading.service';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-sending-recipients',
  templateUrl: './sending-recipients.component.html',
  styleUrls: ['./sending-recipients.component.scss']
})

export class SendingRecipientsComponent implements OnInit, OnDestroy {
  contractService$: any;
  dataDashBoard: {
    countCont: string,
    countSentCont: string,
    countCoufiCont: string,
    countSignCont: string,
    countCancleCont: string,
    countRejectCont: string,
    countSendToMe: string,
    chart: any[]
  } = {
      countCont: '',
      countSentCont: '',
      countCoufiCont: '',
      countSignCont: '',
      countCancleCont: '',
      countRejectCont: '',
      countSendToMe: '',
      chart: []
    }
  btnEnableExpiry: boolean = false;
  btnExpiry: string = 'btn-gray';

  btnEnablePassword: boolean = false;
  btnPassword: string = 'btn-gray';

  btnEnableOtpPhone: boolean = false;
  btnOtpPhone: string = 'btn-gray';
  verifiPhoneYn: string = "N";
  verifiPwdYn: string = "N";

  btnSendContract: boolean = true;

  faPlus = faPlus;
  faTimes = faTimes;
  sidebar: any = '';
  formData: FormGroup;
  formInputPassword: FormGroup;
  formSequence: FormGroup;
  formExpiry: FormGroup;

  alertMessage: {
    contractNm: boolean,
    contractEmail: boolean
  }[] = [];

  arrayDatas: {
    contractNm: string, contractEmail: string, contractMobile: string, color: string
  }[] = [];
  view: boolean = true;
  formNameTemplate: FormGroup;
  formSetting: FormGroup;
  linkedId: string = '';

  getUser: boolean = true;
  checkConAgain: boolean = false;


  inputs: {
    inputPassword: any
  } = {
      inputPassword: null
    };


  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private tableDataService: TableDataService,
    public editorPdfService: EditorPdfService,
    private router: Router,
    private alertConfirmService: AlertConfirmService,
    private tmplateService: TemplateService,
    private contractService: ContractService,
    private alertSaveNameTemplateService: AlertSaveNameTemplateService,
    private loginService: LoginService,
    private commonFuction: CommonFunction,
    private alertService: AlertService,
    private loadingService: LoadingService,
  ) {
  }


  ngOnInit(): void {
    this.initFormSequence();
    this.checkTemplateId();
    this.loadTemplateData();
    this.arrayDatas = [];
    this.init_data();
    this.initFormData();
    this.initFormNameTemplate();
    this.initFromInputPassword();
    this.initFromInputExpiry();
    this.initDataFromStart();
    this.formSetting = this.fb.group(FormGroup);

    this.loadingService.openLoading();
    this.contractService$ = this.contractService.getContractDataDashboard().subscribe((rs: any) => {
      if (rs.status) {
        this.dataDashBoard = rs.data;
        this.loadingService.closeLoading();

      }
    })
  }

  ngOnDestroy() {
    this.contractService$.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (document.getElementById('contorPass') != null && this.linkedId == '000001') {
      this.inputs.inputPassword = document.getElementById('contorPass');
      this.inputs.inputPassword.setAttribute('disabled', 'true');
    }
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    if (this.tableDataService.change.exportData && this.linkedId == '000001') {
      this.setDataImport(this.tableDataService.getDataTable());
      this.tableDataService.setChange('exportData', false);
    }
    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.getUser = false;
      this.linkedId = this.loginService.getProfileLogin().linkedId;
    }
  }

  checkTemplateId(): void {
    if (this.editorPdfService.getTemplateAID() == undefined) {
      this.router.navigate(['template-management']);
    }
  }

  initDataFromStart() {
    let dataMarkSign = this.editorPdfService.getColorSign();
    if (dataMarkSign.length > 0) {
      for (let i = 0; i < dataMarkSign.length; i++) {
        this.pushContact(dataMarkSign[i].color);
      }
    } else {
      this.pushContact('rgb(0,0,0)');
    }

  }

  initFormData() {
    this.formData = this.fb.group({
      datas: this.fb.array([])
    });
  }

  initFormSequence() {
    this.formSequence = this.fb.group({
      sequenceYn: ['N', Regex.Required]
    });
    let dataMarkSign = this.editorPdfService.getColorSign();
    if(dataMarkSign.length == 1){
      this.formSequence.get("sequenceYn").disable();
    }
  }

  onChangeShowAlert(index: number, attr: any){
    this.alertMessage[index][attr] = true;
  }

  initFromInputPassword() {
    this.formInputPassword = this.fb.group({
      contorPass: [null, Regex.Required],
    });
  }

  initFromInputExpiry() {
    this.formExpiry = this.fb.group({
      expiryDay: [{ value: "", disabled: true }],
      useExpiryYn: ["N"]
    });
  }

  loadTemplateData() {
    if (this.editorPdfService.getTemplateAID() != null) {
      this.editorPdfService.getTemplNm();
    } else {
      this.router.navigate(['template-management']);
    }
  }

  createItemDataContract(contorType: string): FormGroup {
    return this.fb.group({
      contractNm: ["", Regex.Required], contractEmail: ["", Regex.emailRequired], contractMobile: [""], contorType: contorType, dataType: 'input'
    });
  }

  switchView() {
    this.view = !this.view;
  }

  createItemDataContractData(conuNm, contractEmail, contractMobile, contorType): FormGroup {
    return this.fb.group({
      contractNm: [conuNm, Regex.Required], contractEmail: [contractEmail, Regex.emailRequired], contractMobile: [contractMobile], contorType: contorType, dataType: 'import'
    });
  }

  initFormNameTemplate() {
    this.formNameTemplate = this.fb.group({
      nameTemplate: [this.editorPdfService.getTemplNm()],
    });
  }

  goBackEditor() {
    this.editorPdfService.setState("back");
    this.router.navigate(['create-template-contract']);
  }

  init_data() {
  }

  get arrayData() {
    return this.formData.get('datas') as FormArray;
  }

  pushContact(color: string) {
    this.arrayDatas.push({ contractNm: '', contractEmail: '', contractMobile: '', color: color });
    this.alertMessage.push({ contractEmail: false, contractNm: false });
    this.arrayData.push(this.createItemDataContract(color));
  }

  onClickBtnPushCont() {
    let dataMarkSign = this.editorPdfService.getColorSign();
    let colorL: string = "rgb(255,0,0)"
    if (dataMarkSign.length > 0) {
      colorL = dataMarkSign[dataMarkSign.length - 1].color
    }
    this.arrayDatas.push({ contractNm: '', contractEmail: '', contractMobile: '', color: colorL });
    this.alertMessage.push({ contractEmail: false, contractNm: false });
    this.arrayData.push(this.createItemDataContract(colorL));
    this.clearColorUsers();
  }

  removeContact(index: any) {
    this.arrayDatas.splice(index, 1);
    this.alertMessage.splice(index, 1);
    this.arrayData.removeAt(index);

    this.clearColorUsers();
  }

  clearColorUsers() {
    let dataMarkSign = this.editorPdfService.getColorSign();
    for (let i = 0; i < this.arrayDatas.length; i++) {
      let color = dataMarkSign[dataMarkSign.length - 1].color;
      if (i < dataMarkSign.length) {
        color = dataMarkSign[i].color;
      }
      this.arrayDatas[i].color = color;
      this.arrayData.get(i.toString()).get('contorType').setValue(color);
    }

  }

  setDataImport(data: any[]) {
    data.forEach(element => {
      let dataMarkSign = this.editorPdfService.getColorSign();
      let colorL: string = "rgb(255,0,0)"
      if (dataMarkSign.length > 0) {
        colorL = dataMarkSign[dataMarkSign.length - 1].color
      }
      this.arrayDatas.push({ contractNm: element.contractNm, contractEmail: element.contractEmail, contractMobile: element.contractMobile, color: colorL });
      this.alertMessage.push({ contractEmail: false, contractNm: false });
      this.arrayData.push(this.createItemDataContractData(element.contractNm, element.contractEmail, element.contractMobile, colorL));
    });
  }

  openModal() {
    this.modalService.setHtmlBody('<app-excel-transfer></app-excel-transfer>');
    this.modalService.setState('excelTransfer');
    this.modalService.open();
  }

  configPassword() {
    if (this.btnEnablePassword) {
      this.verifiPwdYn = "N";
      this.btnEnablePassword = false;
      this.btnPassword = 'btn-gray';
      this.inputs.inputPassword.setAttribute('disabled', 'true');
    } else {
      this.verifiPwdYn = "Y";
      this.btnEnablePassword = true;
      this.btnPassword = 'btn-black';
      this.inputs.inputPassword.removeAttribute("disabled");
    }
  }

  configExpiry() {
    if (this.btnEnableExpiry) {
      this.formExpiry.get("useExpiryYn").setValue("N")
      this.btnEnableExpiry = false;
      this.btnExpiry = 'btn-gray';
      this.formExpiry.get('expiryDay').disable();
    } else {
      this.formExpiry.get("useExpiryYn").setValue("Y")
      this.btnEnableExpiry = true;
      this.btnExpiry = 'btn-black';
      this.formExpiry.get('expiryDay').enable();
    }
  }

  configPhoneOtp() {
    if (this.btnEnableOtpPhone) {
      this.verifiPhoneYn = "N";
      this.btnEnableOtpPhone = false;
      this.btnOtpPhone = 'btn-gray';
    } else {
      this.verifiPhoneYn = "Y";
      this.btnEnableOtpPhone = true;
      this.btnOtpPhone = 'btn-black';

    }
  }

  checkDataForm() {
      if ((!(this.btnEnablePassword))) {
        if (this.formData.get('datas').valid) {
          this.alertConfirmService.open(
            "해당 계약서를 전송하시겠습니까?",
            "수신자 정보가 정확하게 입력되었는지 확인해주세요.",
            {
              func: () => {
                this.alertConfirmService.close();
              }
            },
            {
              func: () => {
                this.savaContractData();
                this.alertConfirmService.close();
              }
            });
        } else {
          this.alertService.open(
            "모든 정보를 입력하십시오.",
            "",
            {
              func: () => {
                this.alertService.close();
              }
            },
            {}
          );
        }
      } else {
        console.log(this.formInputPassword.get('contorPass').valid);
        if (this.formInputPassword.get('contorPass').valid) {
          if (this.formData.get('datas').valid) {
            this.alertConfirmService.open(
              "해당 계약서를 전송하시겠습니까?",
              "수신자 정보가 정확하게 입력되었는지 확인해주세요.",
              {
                func: () => {
                  this.alertConfirmService.close();
                }
              },
              {
                func: () => {
                  this.savaContractData();
                  this.alertConfirmService.close();
                }
              });

          } else {
            this.alertService.open(
              "모든 정보를 입력하십시오.",
              "",
              {
                func: () => {
                  this.alertService.close();
                }
              },
              {}
            );
          }
        } else {
          this.alertService.open(
            "타입을",
            "비밀번호를 입력해주세요.",
            {
              func: () => {
                this.alertService.close();
              }
            },
            {}
          );
        }

        return

        this.alertConfirmService.open(
          "보안 코드를 입력하고 싶지 않습니다.",
          "사용합니까?",
          {
            func: () => {
              this.alertConfirmService.close();
            }
          }, {
          func: () => {
            this.alertConfirmService.open("해당 계약서를 전송하시겠습니까?",
              "수신자 정보가 정확하게 입력되었는지 확인해주세요.",
              {
                func: () => {
                  this.alertConfirmService.close();
                }
              },
              {
                func: () => {
                  this.savaContractData();
                }
              });
          }
        });
      }

  }

  savaContractData() {
    let data = {
      contactor: this.genDataFromContract(),
      templId: this.editorPdfService.getTemplateAID(),
      templNm: this.formNameTemplate.get('nameTemplate').value,
      excelListNm: this.tableDataService.getFileName(),
      contorPass: this.formInputPassword.get('contorPass').value,
      frstRegisNo: this.loginService.getProfileLogin().userIdNo,
      sequenceYn: this.formSequence.get('sequenceYn').value,
      verifiPhoneYn: this.verifiPhoneYn,
      verifiPwdYn: this.verifiPwdYn,
      useExpiryYn: this.formExpiry.get('useExpiryYn').value,
      expiryDays: this.formExpiry.get("expiryDay").value
    };
    this.tmplateService.saveMarkTemplate(this.editorPdfService.getMark()).subscribe((rs: any) => {
      if (rs.status) {
        this.alertSaveNameTemplateService.setNameTemplate("");

        this.contractService.insertContract(data).subscribe((rs: any) => {
          if (rs.status) {
            this.commonFuction.deductPoint(this.arrayData.length);
            this.alertConfirmService.close();
            this.router.navigate(['contract-management']);
          }
        });
      }
    });
  }

  genDataFromContract(): {
    contNm: string,
    contorType: string,
    contractEmail: string,
    contractMobile: string,
    contractNm: string,
    dataType: string,
    contorPass: string
  }[] {
    let contract: {
      contNm: string,
      contorType: string,
      contractEmail: string,
      contractMobile: string,
      contractNm: string,
      dataType: string,
      contorPass: string
    }[] = [];

    let contorType = this.editorPdfService.getColorSign();
    let c = 0;

    for (let i = 0; i < this.formData.get('datas').value.length; i++) {

      let color = 'rgb(0,0,0)'
      if(contorType.length > 0){
        color = this.formData.get('datas').get(i.toString()).get("contorType").value
      }

      contract.push({
        contNm: "",
        contorType: color,
        contractEmail: this.formData.get('datas').get(i.toString()).get("contractEmail").value,
        contractMobile: this.formData.get('datas').get(i.toString()).get("contractMobile").value,
        contractNm: this.formData.get('datas').get(i.toString()).get("contractNm").value,
        dataType: this.formData.get('datas').get(i.toString()).get("dataType").value,
        contorPass: this.formInputPassword.get('contorPass').value
      });
    }

    return contract
  }
}
