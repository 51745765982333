import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContractInformationService {


  dataFromContract: {
    '{owner}': string,
    '{worker}': string,
    '{ys}': string,
    '{ms}': string,
    '{ds}': string,
    '{ye}': string,
    '{me}': string,
    '{de}': string,
    '{wplace}': string,
    '{contentOfWork}': string,
    '{ths}': string,
    '{tms}': string,
    '{the}': string,
    '{tme}': string,
    '{bhs}': string,
    '{bms}': string,
    '{bhe}': string,
    '{bme}': string,
    '{wDays}': string,
    '{weday}': string,
    '{salay}': string,
    '{cb}': boolean,
    '{cbx}': boolean,
    '{wBonus1}': string,
    '{cbo}': boolean,
    '{oSalary}': string,
    '{cbn}': boolean,
    '{nSalary}': string,
    '{pDay}': string,
    '{cbdi}': boolean,
    '{cbdp}': boolean,
    '{sYY}': string,
    '{sMM}': string,
    '{sDD}': string,
    '{bName}': string,
    '{bPhone}': string,
    '{bAddr}': string,
    '{bRep}': string,
    '{sAddr}': string,
    '{sPhone}': string,
    '{sName}': string
  } = {
      '{owner}': '',
      '{worker}': '',
      '{ys}': 'N',
      '{ms}': 'N',
      '{ds}': 'N',
      '{ye}': 'N',
      '{me}': 'N',
      '{de}': 'N',
      '{wplace}': '',
      '{contentOfWork}': '',
      '{ths}': 'N',
      '{tms}': 'N',
      '{the}': 'N',
      '{tme}': 'N',
      '{bhs}': 'N',
      '{bms}': 'N',
      '{bhe}': 'N',
      '{bme}': 'N',
      '{wDays}': 'N',
      '{weday}': '0',
      '{salay}': '',
      '{cb}': false,
      '{cbx}': false,
      '{wBonus1}': '',
      '{cbo}': false,
      '{oSalary}': '',
      '{cbn}': false,
      '{nSalary}': '',
      '{pDay}': 'N',
      '{cbdi}': false,
      '{cbdp}': false,
      '{sYY}': 'N',
      '{sMM}': 'N',
      '{sDD}': 'N',
      '{bName}': '',
      '{bPhone}': '',
      '{bAddr}': '',
      '{bRep}': '',
      '{sAddr}': '',
      '{sPhone}': '',
      '{sName}': ''
    }


  constructor() { }

  setDataFromContract(key: any, value: string) {
    this.dataFromContract[key] = value;
  }

  getDataFromContract(key: any) {
    return this.dataFromContract[key];
  }

  clearDataFromContract() {
    this.dataFromContract = {
      '{owner}': '',
      '{worker}': '',
      '{ys}': 'N',
      '{ms}': 'N',
      '{ds}': 'N',
      '{ye}': 'N',
      '{me}': 'N',
      '{de}': 'N',
      '{wplace}': '',
      '{contentOfWork}': '',
      '{ths}': 'N',
      '{tms}': 'N',
      '{the}': 'N',
      '{tme}': 'N',
      '{bhs}': 'N',
      '{bms}': 'N',
      '{bhe}': 'N',
      '{bme}': 'N',
      '{wDays}': 'N',
      '{weday}': '0',
      '{salay}': '',
      '{cb}': false,
      '{cbx}': false,
      '{wBonus1}': '',
      '{cbo}': true,
      '{oSalary}': '',
      '{cbn}': false,
      '{nSalary}': '',
      '{pDay}': 'N',
      '{cbdi}': false,
      '{cbdp}': false,
      '{sYY}': 'N',
      '{sMM}': 'N',
      '{sDD}': 'N',
      '{bName}': '',
      '{bPhone}': '',
      '{bAddr}': '',
      '{bRep}': '',
      '{sAddr}': '',
      '{sPhone}': '',
      '{sName}': ''
    };
  }
}
