<section class="bg-testimonial p-md-5">

  <div id="about" style="font-weight: 300;" class="anchPoint1 gothicLight ">P1
  </div>

  <div id="service" style="font-weight: 300;" class="anchPoint2 gothicLight ">P2

  </div>



  <div class="standardFront">
    <div class="container2">

      <div class="row">
        <div class="col-10 offset-1">

          <h3 style="font-weight: 300;" class="text-light gothicLight text-center pt-5 pb-3  pb-md-5 p-md-0">이용방법
          </h3>
        </div>

        <div class="col-md-6 align-self-center">

          <div id="slideImg1">
            <img src="/assets/img/frontpage/tabletImg.png" class="img-fluid" />
          </div>

          <div id="slideImg2">
            <img src="/assets/img/frontpage/placeholders/howto2.png" class="img-fluid" />
          </div>

          <div id="slideImg3">
            <img src="/assets/img/frontpage/placeholders/howto3.png" class="img-fluid" />
          </div>

          <div id="slideImg4">
            <img src="/assets/img/frontpage/placeholders/howto4.png" class="img-fluid" />
          </div>

          <div id="slideImg5">
            <img src="/assets/img/frontpage/placeholders/howto5.png" class="img-fluid" />
          </div>


        </div>
        <div class="col-md-6 text-left color-dark  align-self-center  ">

          <!-- Vertical Steps -->
          <div class="d-none d-md-block holder">
            <ul class="SteppedProgress Vertical">
              <li (click)="howto1();" id="how1" class="complete listOn"><span>전자계약서 만들기
                </span></li>
              <li (click)="howto2();" id="how2" class="complete"><span>계약서 내용 입력 및 서명

                </span></li>
              <li (click)="howto3();" id="how3" class="complete"><span>수신자 선택 및 계약서 전송

                </span></li>
              <li (click)="howto4();" id="how4" class="complete"><span>계약대상자
                  작성 및 서명



                </span></li>
              <li (click)="howto5();" id="how5" class="complete"><span>전자 계약 완료



                </span></li>

            </ul>

          </div>
          <!-- Vertical steps end -->

          <!-- Horizontal steps  -->
          <div class="d-block d-md-none mobileSteps mt-3 mb-4 pt-4 pb-5">


            <ul class="SteppedProgress">
              <li (click)="howto1();" id="how1M" class=" complete listOn"><span>전자계약서<br>
                  만들기
                </span></li>

              <li (click)="howto2();" id="how2M" class=" complete"><span>계약서 내용
                  입력 및 서명
                </span></li>
              <li (click)="howto3();" id="how3M" class=" complete"><span>수신자 선택 및
                  계약서 전송

                </span></li>
            </ul>

            <ul class="SteppedProgress pt-4 mobile45">
              <li (click)="howto4();" id="how4M" class=" complete"><span>계약대상자<br>
                  작성 및 서명

                </span></li>
              <li (click)="howto5();" id="how5M" class=" complete"><span>전자 계약 완료

                </span></li>
            </ul>


          </div>
          <!-- Horizontal steps end -->

        </div>

      </div>




    </div>
  </div>

  <div class="oddFront">
    <div class="container">

      <div class="row">
        <div class="col-10 offset-1">
          <h3 style="font-weight: 300;" class="text-light gothicLight text-center testiheadingtxt">이용방법
          </h3>
        </div>

        <div class="col-12 align-self-center">

          <div id="slideImg1T">
            <img src="/assets/img/frontpage/tabletImg.png" class="img-fluid" />
          </div>

          <div id="slideImg2T">
            <img src="/assets/img/frontpage/placeholders/howto2.png" class="img-fluid" />
          </div>

          <div id="slideImg3T">
            <img src="/assets/img/frontpage/placeholders/howto3.png" class="img-fluid" />
          </div>

          <div id="slideImg4T">
            <img src="/assets/img/frontpage/placeholders/howto4.png" class="img-fluid" />
          </div>

          <div id="slideImg5T">
            <img src="/assets/img/frontpage/placeholders/howto5.png" class="img-fluid" />
          </div>


        </div>
        <div class="col-12 text-left color-dark  align-self-center  ">



          <!-- Horizontal steps  -->
          <div class=" mobileSteps">


            <ul class="SteppedProgress">
              <li (click)="howto1T();" id="how1T" class=" complete listOn"><span>전자계약서<br>
                  만들기
                </span></li>

              <li (click)="howto2T();" id="how2T" class=" complete"><span>계약서 내용<br>
                  입력 및 서명
                </span></li>
              <li (click)="howto3T();" id="how3T" class=" complete"><span>수신자 선택 및<br>
                  계약서 전송

                </span></li>
            </ul>

            <ul class="SteppedProgress mobile45">
              <li (click)="howto4T();" id="how4T" class=" complete"><span>계약대상자<br>
                  작성 및 서명

                </span></li>
              <li (click)="howto5T();" id="how5T" class=" complete"><span>전자 계약 완료

                </span></li>
            </ul>


          </div>
          <!-- Horizontal steps end -->

        </div>

      </div>




    </div>
  </div>


</section>


<div class="row">
  <div class="col-12">



  </div>
</div>