<section class="createNewContainer">
    <section class="container h-100">
    
    
        <div class="h-100 row d-flex justify-content-center align-items-center ">
      
          <div style="border-top: 2px solid #111; " class="col-md-5 p-5 d-flex bg-white justify-content-center">
            <form [formGroup]=formForgotCreateeNewPassword style="width: 100%;">
      
              <div class="forgotTxthead text-center ">
                <h3><b>새 비밀번호 생성</b></h3>
                <h5 class="pt-5">변경을 원하는 새로운 비밀번호를 입력하시고 <span>"비밀번호 변경"</span> 버튼을 클릭해주세요.</h5>
      
              </div>
      
              <div class="form-group row m-0 py-5">
                <div class="col-sm-12">
                  <input type="password" class="form-control" formControlName='password' id="password"
                    placeholder="원하는 새 비밀번호를 입력하십시오.">
                  <small class="form-text text-muted mt-1 text-validators {{validators.password}}">비밀번호 규칙을 확인해주세요</small>
                </div>
                <div class="col-sm-12 mt-3">
                    <input type="password" class="form-control" formControlName='confPassword' id="confPassword"
                      placeholder="새 비밀번호를 다시 입력하십시오.">
                    <small class="form-text text-muted mt-1 text-validators {{validators.confPassword}}">비밀번호 규칙을 확인해주세요</small>
                  </div>
              </div>
      
              <div class="form-group row d-flex justify-content-center">
                <button type="button" (click)="createNewPassword()" [disabled]='disabledBtn' class="btn btn-crimson w-25">비밀번호 변경</button>
              </div>
            </form>
          </div>
        </div>
      
      
      
      
      </section>

</section>


<app-alert></app-alert>
<app-loading></app-loading>