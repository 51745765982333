<div class="row" *ngIf="td.length > 0">
  <div class="col-12 ">
    <label class="tit-ref-001 labelTable"><b>환불/취소 요청 문의</b></label>
  </div>
</div>
<!-- START OF LARGER -->

<div class="largerUi" *ngIf="td.length > 0">
  <table class="table largeTable table-hover">
    <thead class="th-bg-001">
      <tr>
        <th class="text-center tex-tit-tab-001 "> 등록일</th>
        <th class="text-center tex-tit-tab-001"> 문의</th>
        <th class="text-center tex-tit-tab-001">상태</th>
      </tr>
    </thead>
    <tbody class="bg-white">
      <ng-container *ngFor="let t of td">
        <tr class="text-center" (click)="selectInuire(t)">
          <td scope="row">
            {{t.frstReginPnttm}}
          </td>
          <td class="centerCell text-left">
            {{t.quesCont}}
          </td>
          <td>
            {{t.quesStatus}}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="d-flex justify-content-center" style="margin-top: 30px; margin-bottom: 34px;">
    <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
      (seclectPage)="setCurrentPage($event)"></app-pagination>
  </div>
</div>

<!-- END OF LARGER -->


<!-- START OF SMALLER UI -->

<div class="smallerUi" *ngIf="td.length > 0">
  <div class="d-flex row-hl justify-content-between">
    <div class="p-2 item-hl">등록일</div>
    <div class="p-2 item-hl">문의</div>
    <div class="p-2  item-hl">상태 </div>
  </div>
  <ul class="mobTable  list-group mb-5">
    <ng-container *ngFor="let t of td">
      <li class="list-group-item bg-transparent border-0" (click)="selectInuire(t)">
        <div class="item-form sc-jWxkHr mobcellA">
          <div class="row m-0">
            <div class="field-cell check">
              <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox" id="form_5f48cab259087664b3096102"
                  readonly=""><label for="form_5f48cab259087664b3096102">check0</label></div>
            </div>
            <div class="field-cell state form color-blue"><span>{{t.quesStatus}} </span></div>
            <div class="field-cell form-name"><span class="truncate"> {{t.quesCont}}<a class="tableButtonMob"
                  *ngIf="t.refundOrCancellation">요청</a></span>
            </div>
            <div class="field-cell date last-date"><span class="truncate">
                {{t.frstReginPnttm}}
              </span></div>
            <div class="field-cell activity"></div>
          </div>
        </div>

      </li>

    </ng-container>
  </ul>
  <div class="d-flex justify-content-center" style="margin-top: 30px">
    <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
      (seclectPage)="setCurrentPage($event)"></app-pagination>
  </div>
</div>