import { OnDestroy } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as $ from  'jquery';
import { DataPassService } from 'src/app/manage/service/data-pass/data-pass.service';

import { NavbarService } from '../navbar/navbar.service';
declare var $: any;
@Component({
  selector: 'app-how-to-use',
    templateUrl: './how-to-use.component.html',
    styleUrls: ['./how-to-use.component.scss']
})
export class HowToUseComponent implements OnInit, OnChanges, OnDestroy {
  navigationSubscription;
  curTab = 'first';
  constructor(
    private router: Router,
    private navbarService: NavbarService,
    private dataPassService: DataPassService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.navbarService.setNavbarTitle('이용방법');
      }
    });

    if (this.dataPassService.getHowToPage()) {
      this.curTab = this.dataPassService.getHowToPage();
    }
  }


  ngOnInit(): void {
    this.addColor();

  }

  ngOnDestroy() {
    this.dataPassService.setHowToPage('first');
  }  

  ngOnChanges() {
  }

  ngAfterViewInit(): void {
    this.initScoll();
  }

  initScoll() {
    if ($(window).width() < 1199 && $(window).width() > 750) {
      $('body').scrollspy({
        target: '#mainNavTwo',
        offset: 100
      });

      // Collapse Navbar

      try {
        var navbarCollapse = function () {
          if ($("#mainNavTwo").offset() !== undefined) {
            if ($("#mainNavTwo").offset().top > 100) {
              $("#mainNavTwo").addClass("navbar-shrink");
            } else {
              $("#mainNavTwo").removeClass("navbar-shrink");
            }
          }

        };
      } catch (e) {

      }

      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);
    } else {

      $('body').scrollspy({
        target: '#mainNav',
        offset: 100
      });

      // Collapse Navbar

      try {
        var navbarCollapse = function () {
          if ($("#mainNav").offset() !== undefined) {
            if ($("#mainNav").offset().top > 100) {
              $("#mainNav").addClass("navbar-shrink");
            } else {
              $("#mainNav").removeClass("navbar-shrink");
            }
          }

        };
      } catch (e) {

      }

      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);
    }

  }

  changeTab(tab) {
    this.curTab = tab;
  }

  addColor() {
    var servicepage = document.getElementById("howBtn");

    servicepage.classList.add("active");

  }

}
