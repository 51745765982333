<section>


  <div class="largerUi">
    <div class="row">



      <div class="linedForm col-12">

        <form [formGroup]=fromSearchPaymentTwo>
          <div class="input-group">


            <div class="input-group-prepend">
              <span class="spanA input-group-text px-0 bg-transparent border-0 text-left">처리상태</span>
            </div>
            <select formControlName='processingStatus' class="form-control ">
              <option value="0">전체</option>
            </select>


            <div class="input-group-prepend">
              <span class="spanB input-group-text px-0 bg-transparent border-0"> 결제 일시</span>
            </div>
            <input formControlName='dateStart' type="date" name="dateStart" class=" datespacing form-control">
            <input formControlName='timeStart' type="time" name="timeStart" class=" form-control">

            <div class="input-group-prepend">
              <span class="spanC input-group-text px-0 bg-transparent border-0"> ~ </span>
            </div>
            <input formControlName='dateEnd' type="date" name="dateEnd" class=" datespacing form-control">
            <input formControlName='timeEnd' type="time" name="timeEnd" class="form-control">



            <div class="input-group-prepend">
              <span class="spanD input-group-text  px-0 bg-transparent border-0">
                승인번호 </span>
            </div>
            <input formControlName='cardNumber' type="text" name="cardNumber" placeholder="승인번호 입력"
              class="numSpace form-control">
            <button class="btn btn-middle-short btn-black my-0 mx-0">검색</button>

          </div>
        </form>

      </div>






      <div class="fillForm col-12 d-flex justify-content-end">
        <span class="">총 {{itemTotal}}건</span>
      </div>
    </div>
    <div class="row ">
      <div class="col-12">


        <table class="table largeTable table-hover">
          <thead class="th-bg-001">
            <tr>
              <th class="text-center tex-tit-tab-001">
                결제 일시
              </th>
              <th class="text-center tex-tit-tab-001">
                결제 서비스 내역
              </th>
              <th class="text-center tex-tit-tab-001">
                결제 방법
              </th>
              <th class="text-center tex-tit-tab-001">결제 금액</th>
              <th class="text-center tex-tit-tab-001">승인 번호</th>
              <th class="text-center tex-tit-tab-001">승인일</th>

              <th class="text-center tex-tit-tab-001">구매 포인트
              </th>
              <th class="text-center tex-tit-tab-001">처리상태</th>
              <th class="text-center tex-tit-tab-001">환불/취소</th>

            </tr>
          </thead>
          <tbody class="bg-white">
            <ng-container *ngFor="let t of td">
              <tr class="text-center" (click)="selectItem(t,$event)">
                <td scope="row">
                  {{t.paymentDate}}
                </td>
                <td>
                  {{t.paymentDetails}}
                </td>
                <td>
                  {{t.paymentType}}
                </td>
                <td class="tabOverflowMicro">
                  {{t.amountPay}}
                </td>
                <td>
                  {{t.approvalNumber}}
                </td>
                <td>
                  {{t.approvalDate}}
                </td>
                <td>
                  {{t.purchasePoint}}
                </td>
                <td>
                  <span *ngIf="t.processingStatusCode !== '000000'">{{t.processingStatus}}</span>
                </td>
                <td class="buttoncell">
                  <button [disabled]="(t.processingStatusCode !== '000000')" (click)="onRefund(t.approvalNumber,t.tid,t.cid,t.amountPay,t)" class="tableButton">요청</button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div class="d-flex justify-content-center" style="margin-top: 30px; margin-bottom: 44px;">
          <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
            (seclectPage)="setCurrentPage($event)"></app-pagination>
        </div>
      </div>



    </div>


  </div>

  <!-- end of LargerUI -->

  <!-- Start of SmallerUi -->



  <div class="smallerUi">


    <div class="d-block">

      <form class="largerUi" [formGroup]=fromSearchPaymentThree action="">


        <div class="input-group pb-1">
          <div class="input-group-prepend">
            <div class="bg-transparent pl-0 border-0 text-left input-group-text">
              처리상태
            </div>
          </div>
          <select formControlName='processingStatus' class="form-control " id="processingStatus">
            <option>전체</option>
          </select>

        </div>



        <div class="form-row pb-1">
          <div class="col">

            <div class="input-group">
              <div class="input-group-prepend">
                <div class="bg-transparent pl-0 border-0 text-left input-group-text">
                  결제 일시
                </div>
              </div>
              <input formControlName='dateStart' type="date" name="dateStart" class=" form-control">


            </div>

          </div>
          <div class="col">
            <input formControlName='timeStart' type="time" name="timeStart" class="mr-1 form-control">
          </div>
        </div>


        <div class="form-row pb-1">
          <div class="col">

            <div class="input-group">
              <div class="input-group-prepend">
                <div class="bg-transparent pl-0 border-0 text-left input-group-text">
                  ~
                </div>
              </div>
              <input formControlName='dateEnd' style="width:145px; " type="date" name="dateEnd" class="form-control">


            </div>

          </div>
          <div class="col">

            <input formControlName='timeEnd' type="time" name="timeEnd" class=" form-control">
          </div>
        </div>

        <div class="input-group pb-1">
          <div class="input-group-prepend">
            <div class="bg-transparent pl-0 border-0 text-left input-group-text">
              카드번호
            </div>
          </div>
          <input formControlName='cardNumber' type="text" name="cardNumber" placeholder="카드번호 입력" class=" form-control">

        </div>

        <div class="col-12 text-center">
          <button class="btn btn-middle-short btn-black my-3 mx-0">검색</button>
        </div>

      </form>


      <div class="d-flex row-hl">
        <div class="p-2 item-hl">상태</div>

        <div class="p-2 ml-auto item-hl">생성 날짜 </div>
      </div>


      <ul class="mobTable list-group mb-5">
        <ng-container *ngFor="let t of td">
          <li class="list-group-item bg-transparent border-0"  (click)="selectItem(t,$event)">

            <div class="item-form sc-jWxkHr mobcellA">
              <div class="row m-0">
                <div class="field-cell check">
                  <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox" id="form_5f48cab259087664b3096102"
                      readonly=""><label for="form_5f48cab259087664b3096102">check0</label></div>
                </div>

                <div class="field-cell state form color-blue"><span>결제 금액 : {{t.amountPay}}</span></div>
                <div class="field-cell form-name"><span class="truncate">{{t.approvalNumber}} / {{t.processingStatus}}<a class="tableButtonMob"
                      *ngIf="t.refundOrCancellation">요청</a></span>
                </div>
                <div class="field-cell date last-date"><span class="truncate">{{t.paymentDate}}</span></div>
                <div class="field-cell activity"></div>

              </div>
            </div>

          </li>

        </ng-container>
      </ul>
      <div class="d-flex justify-content-center" style="margin-top: 30px">
        <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
          (seclectPage)="setCurrentPage($event)"></app-pagination>
      </div>


    </div>

  </div>

</section>
<app-loading></app-loading>
<app-modal-refund-payment></app-modal-refund-payment>