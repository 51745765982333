import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ServiceCenterService } from '../../manage/service/service-center/service-center.service';

import { AuthGuard } from '../../guard/auth.guard';

@Component({
  selector: 'app-notice-detail',
  templateUrl: './notice-detail.component.html',
  styleUrls: ['./notice-detail.component.scss']
})
export class NoticeDetailComponent implements OnInit {

  @Input() itemNotice;
  @Output() goBack = new EventEmitter<any>();
  constructor(
    private authGuard: AuthGuard,
    private serviceCenterService: ServiceCenterService,
  ) { }
  item;

  ngOnInit(): void {
    this.item = this.itemNotice;
    if(this.authGuard.canActivateNav()){
      this.insertPersonalNotic();
    }
  }

  insertPersonalNotic(){
    let params = {
      notiId: this.item.noticId
    }
    this.serviceCenterService.insertPersonalNotic(params).subscribe((rs: any) => {
    });
  }

  onBack() {
    this.goBack.emit(true);
  }
}
