import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalImportUserTeamsService {
  status = {
    open: true,
    close: false
  };

  change = {
    show: false,
    state: false
  };

  user: {
    name: string,
    rank: string,
    division: string,
    mail: string,
    phone: string,
  }[] = [];

  func:{
    func: () => {

    }
  };

  show = false;
  state: string = '';

  constructor() { }

  setChange(type: any, status: boolean) {
    this.change[type] = status;
  }
  
  setState(state: string) {
    this.state = state;
  }

  getState() {
    return this.state;
  }

  setDataUser(user: any) {
    this.user = user;
  }

  getDataUser() {
    return this.user;
  }

  open(func: any) {
    this.change.show = true;
    this.show = this.status.open;
    this.func = func; 
  }

  onFunctionClick() {
    this.func.func();
  }

  close() {
    this.change.show = true;
    this.show = this.status.close;
  }
}
