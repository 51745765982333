import { Component, OnInit } from '@angular/core';
import { ModalGallerySignatureService } from './modal-gallery-signature.service';

@Component({
  selector: 'app-modal-gallery-signature',
  templateUrl: './modal-gallery-signature.component.html',
  styleUrls: ['./modal-gallery-signature.component.scss']
})
export class ModalGallerySignatureComponent implements OnInit {

  constructor(
    private modalGallerySignatureService: ModalGallerySignatureService
  ) { }
  configSign: {
    title: boolean,
    height: string,
    backgroundColor: string,
    padding: string,
    grid: string,
    icon: {
      favorite: boolean,
      download: boolean,
      delete: boolean
    },
    select: boolean,
    status: string
  } = {
      title: false,
      height: "300px",
      backgroundColor: "#e2e6eb",
      grid: "wrapperSignatures-004",
      padding: "7.5px 7.5px",
      icon: {
        favorite: false,
        download: false,
        delete: false
      },
      select: true,
      status: 'favorite'
    }
  show: boolean = false;
  btnDisabled: boolean = true;
  signId: string = "";
  ngOnInit(): void {
  }


  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    if (this.modalGallerySignatureService.change.show) {
      this.show = this.modalGallerySignatureService.show;
      this.modalGallerySignatureService.setChange('show', false);
    }
  }

  onClickBtn(btn: any) {
    console.log(btn)
    this.modalGallerySignatureService.btn[btn].func();
  }

  closeModal() {
    this.modalGallerySignatureService.close();
  }

  selectSignat($event) {
    this.signId = $event;
    this.modalGallerySignatureService.setSignId($event);
    this.btnDisabled = false;
  }
}
