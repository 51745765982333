export default class StringFormat {

    public socialNumberFormat(sn: string): string {
        // 1) 123456-1234567
        let snf: string = '';

        snf = sn.substring(0, 6) + '-' + sn.substring(6, 13);

        return snf;
    }

    public phoneNumberFormat(pn: string): string {
        // 1) 010-1234-1234
        // 2) 02-1234-1234
        let pnf: string = '';

        if (pn.length == 10) {
            pnf = pn.substring(0, 2) + '-' + pn.substring(2, 6) + '-' + pn.substring(6, 10);
        } else if (pn.length == 11) {
            pnf = pn.substring(0, 3) + '-' + pn.substring(3, 7) + '-' + pn.substring(7, 11);
        }

        return pnf
    }

    public dateFormat(year: string, month: string, day: string): string {
        // 1) 2020/03/01

        return (year + '/' + month + '/' + day);
    }

    public genUUIDv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
}
