import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarViewService {
  page: {
    number: number, 
    maxpage: number
  } = {
    number: 0, 
    maxpage: 0
  }
  constructor() { }

  setPageNumber(number: number){
    this.page.number = number;
  }

  getPageNumber(){
    return this.page.number;
  }

  setPageMax(maxpage: number){
    this.page.maxpage = maxpage;
  }

  getPageMax(){
    return this.page.maxpage;
  }
}
