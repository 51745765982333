<!-- This is smaller nav -->
<div class="mobNav">

  <nav class="navbar navbar-light ">
    <div class="container-fluid p-0">


      <div class="backhome">

        <a class="{{logo.main}}" href="#/main"><img src="../../../assets/img/admin/logo_gcont2x.png" id="bkImg"
            class="img-fluid" width="125" /></a>
        <a class="{{logo.other}}" (click)="myToggleBack()" (click)="closeNav()" href="#/main"><img
            src="../../../assets/img/icon/backMain.png" width="60%" class="img-fluid" /></a>
      </div>




      <div class="{{logo.other}} text-center thePageTitle barumBold">
        {{navbar_title}}
      </div>





      <button (click)="myToggle()" id="tognav"
        class="px-0 hamburger hamburger--3dy-r navbar-toggler navbar-toggler-right" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">


        <span class="hamburger-box">
          <div class="hamburger-inner"></div>
        </span>
      </button>



      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav">
        </ul>
        

        <div class=" align-items-center d-flex row-hl w-100" [formGroup]="form">  
          <div class="d-flex py-2 align-items-center item-hl cur-poi" (click)='toPageSetting()'>
            <div class="">
              <img style="margin-right: 6px;" width="20px" src="../../../../assets/img/icon/icon-user.png">
            </div>
            <p style="padding-top: 2px;" class="my-0 logOut cur-poi">{{userNm}} </p>
          </div>
          <select class="nav-droplist d-none" name="pageLink" formControlName="pageLink">
            <option [value]="'main'" selected="">G CONT</option>
            <option [value]="''">홈페이지</option>
            <option [value]="'how-to-use'">이용방법</option>
          </select>
          
          <div class="py-2 ml-auto item-hl logOut"><span class="m-logout" style="cursor:pointer;" (click)="logout()">로그아웃</span></div>
        </div>


      </div>


    </div>
  </nav>
</div>

<!-- This is Larger Nav -->
<div class="largerNav">

  <nav class=" navbar navbar-expand-lg navbar-light ">
    <div class="container-fluid px-0">

      <a class="navbar-brand" href="#/main"><img src="../../../assets/img/admin/logo_gcont2x.png" class="img-fluid"
          width="125" /></a>

      <button (click)="closeNav()" class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="d-none d-lg-block nav-item dropdown d-lg-flex nav-item justify-content-start align-items-center px-2">
            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              class="img-ico-nav-001 ico-nav-alr-001 m-0">

            </div>
            <a class="bellclick nav-link dropdown-toggle p-0 ml-2" href="#" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>{{notic.notRead.length + this.notic.noticSys.length}}</span>건
            </a>
            <div class="dropdown-menu not-box-001" aria-labelledby="navbarDropdown">

              <ng-container *ngIf="((notic.notRead.length + this.notic.noticSys.length) == 0)">
                <div class="dropdown-item ite-noti-001">
                  <div class="dropdown-divider"></div>
                  <div class="tex-not-001 text-center">
                    <span class="text-center"><b>알림이 없습니다.</b></span>
                  </div>
                  <div class="det-not-002">
                  </div>
                  <div class="dropdown-divider"></div>
                </div>
              </ng-container>

              <ng-container *ngFor="let noti of notic.notRead;let i = index;let lastcall = last">
                <div class="dropdown-item ite-noti-001" (click)="goToServiceCenter(noti.noticId)">
                  <div class="tex-not-001" innerHTML="{{noti.noticTitle}}">

                  </div>
                  <div class="det-not-002" innerHTML="{{noti.noticeContent}}">
                  </div>
                </div>
                <div *ngIf="!lastcall" class="dropdown-divider"></div>
              </ng-container>

              <ng-container *ngFor="let noti of notic.noticSys;let i = index;let lastcall = last">
                <div class="dropdown-item px-3 ite-noti-001">
                  <div class="tex-not-001" innerHTML="{{noti.notiMsg}}" (click)="onReactNotice(noti.noticActId,noti.noticId,noti)">

                  </div>
                </div>
                <div *ngIf="!lastcall" class="dropdown-divider"></div>
              </ng-container>
            </div>
          </li>
          <li class="d-none d-lg-flex nav-item justify-content-start align-items-center px-2" (click)='toPageSetting()'>
            <div class="img-ico-nav-001 ico-nav-usr-001">
            </div>

            <div>
              <p class="my-0 ml-2 cur-poi">{{userNm}}</p>
            </div>
          </li>

          <li>            
            <div class="nav-droplist" (click)="openList()" #selectList>
              <div class="nav-droplist-up d-none">{{curPageName}}</div> 
              <!--리스트 펼침 [s]-->
              <ul class="nav-droplist-down" *ngIf="open"> 
                <li><a href="javascript:void(0)" (click)="toLink('main')">G CONT</a></li>
                <li><a href="javascript:void(0)" (click)="toLink('')">홈페이지</a></li>
                <li><a href="javascript:void(0)" (click)="toLink('how-to-use')">이용방법</a></li>
              </ul>  
              <!--리스트 펼침 [e]]-->              
            </div>          
          </li>
        </ul>

        <div _ngcontent-iqf-c117="" class="d-lg-none  d-flex row-hl">
          <div _ngcontent-iqf-c117="" class="d-flex py-2 item-hl" (click)='toPageSetting()'>
            <div class="img-ico-nav-001 ico-nav-usr-001 cur-poi">

            </div>
            <p class="my-0 cur-poi">{{userNm}}</p>

          </div>
          <div _ngcontent-iqf-c117="" class="py-2 ml-auto item-hl"><span style="cursor:pointer;"
              (click)="openNav()">로그아웃</span></div>
        </div>

        <form class="form-inline my-2 ml-3 my-lg-0 d-none d-lg-block">
          <button class="btn btn-middle-short-logout  btn-gray my-2 my-sm-0" type="button" (click)="logout()">로그아웃
          </button>
        </form>

      </div>


    </div>
  </nav>

</div>

<app-alert-shared></app-alert-shared>