import { Injectable } from '@angular/core';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';

import { fabric } from 'fabric/dist/fabric.js';

@Injectable({
  providedIn: 'root'
})
export class ModalAddSignatureService {
  status = {
    open: true,
    close: false
  };
  show = false;
  change = {
    show: false,
    html: false,
    state: false
  };
  htmlBody: string;
  state: string;
  signature: any;
  btn: {
    btnOk: any, btnCancel: any
  } = {
      btnOk: null, btnCancel: null
    }

  constructor() {

  }

  setState(state?: 'excelTransfer' | 'tableData') {
    this.state = state;
    this.change.state = true;
  }

  getState() {
    return this.state;
  }

  open() {
    this.change.show = true;
    this.show = this.status.open;
  }

  openModal(btnOk: any, btnCancel: any) {
    this.btn.btnOk = btnOk;
    this.btn.btnCancel = btnCancel;
    this.change.show = true;
    this.show = this.status.open;
  }

  close() {
    this.change.show = true;
    this.show = this.status.close;
  }

  setChange(type: any, status: boolean) {
    this.change[type] = status;
  }

  setHtmlBody(html: string) {
    this.change.html = true;
    this.htmlBody = html;
  }

  setSignature(signature: any) {
    this.signature = signature;
  }

  getSignature() {
    return this.signature;
  }

  genSigntureToFabric(): fabric {
    // event call back on add item
    const img = this.signature;
    const imgElement = document.createElement('img');
    imgElement.src = img;
    const imgInstance = new fabric.Image(imgElement, {
      left: 100,
      top: 100,
      angle: 0,
      opacity: 1,
      width: 660,
      height: 220,
      markType: 'signture'
    });
    imgInstance.setControlVisible('tl', false);
    imgInstance.setControlVisible('tr', false);
    imgInstance.setControlVisible('bl', false);
    imgInstance.setControlVisible('br', true);
    imgInstance.setControlVisible('mb', false);
    imgInstance.setControlVisible('mt', false);
    imgInstance.setControlVisible('ml', false);
    imgInstance.setControlVisible('mr', false);

    return imgInstance;
  }

  dataURItoBlob(dataURI, fileName = this.getFileName()) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    console.log(fileName + '.' + mimeString.split('/')[1]);
    var file = new File([ia], fileName + '.' + mimeString.split('/')[1], { type: mimeString });
    return file;
  }

  getFileName(): string {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let hours = today.getHours();
    let minutes = today.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minute = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + '' + minute + ampm;
    let filename = mm + dd + yyyy + strTime;

    return filename;
  }
}
