<section class="w-100 signaturepageheight"
  [ngStyle]="{'background': conf.backgroundColor,'min-height': conf.height,'padding':conf.padding}">

  <div class="container-lg ml-lg-0 mr-lg-auto p-0">

<!--    <h3 class="tit-tem-man-001 pag-tit" style="padding-bottom: 25px;">서명/도장 관리 </h3>-->
    <h3 class="tit-tem-man-001 py-0 barumBold" style="margin-bottom: 47px;" *ngIf="conf.title">서명/도장 관리 </h3>
    <div class='row m-0'>

      <div class="smallerUi w-100">

        <div class=" p-0 ">
          <div class=" d-flex flex-column justify-content-center align-items-center wh-bod-sig-001Up"
            style="border: dotted 2px #a6a6a6; background-color: transparent;">



            <button style="width: 72px;
            height: 31px;" (click)="modalSaignatureOpen()" class="btn btn-plus-shorter btn-white uploadBTNsm"><img
                src="../../../../assets/img//icon/plusShape.png"> 추가 </button>



            <p class="uploadTxt text-center my-2">
              서명/ 도장을 추가하여 사용하세요.
            </p>
          </div>
        </div>



        <ng-container *ngFor="let item of signatureList; let i = index">
          <div [ngClass]="(item.signId == selectSignature ? ' select-sign' : '')"
            (click)="onSelectSignature(item.signId)" class="d-flex-row align-items-end boxSign">

            <div>

              <div style=" background-image: url('{{signatueImage(item.signId)}}');" class="signCanvas d-flex w-100">
              </div>
            </div>
            <div class="iconRow d-flex w-100">
              <div class=" item-hl">
                <a class="bd-highlight cursor-pointer mr-2" (click)="onFavorite(item.signId,item.dsigFavorite,i)"
                  *ngIf="conf.icon.favorite">
                  <img src="../../../../assets/img/icon/star.png" *ngIf="!item.dsigFavorite">
                  <img src="../../../../assets/img/icon/staryellow.png" *ngIf="item.dsigFavorite">
                </a>
                <a class="bd-highlight cursor-pointer mx-2" (click)="downloadSignatureFilePng(item.signId)"
                  *ngIf="conf.icon.download"><img src="../../../../assets/img/icon/download.png"></a>
              </div>
              <div class="ml-auto item-hl"><a class="ml-auto bd-highlight mr-0 cursor-pointer"
                  (click)="deleteSignature(item.signId,i)" *ngIf="conf.icon.delete"><img
                    src="../../../../assets/img/icon/Xcircle.png"></a></div>
            </div>
          </div>
        </ng-container>
      </div>



      <div class="largerUi">
        <div class="{{conf.grid}} justify-content-center justify-content-md-start">
          <div class=" p-0 ">
            <div class=" d-flex flex-column justify-content-center align-items-center wh-bod-sig-001"
              style="border: dotted 2px #a6a6a6; background-color: transparent;">

              <button (click)="modalSaignatureOpen()" class="btn btn-plus-shorter btn-white uploadBTN"><img
                  src="../../../../assets/img//icon/plusShape.png"> 추가 </button>



              <p class="uploadTxt text-center my-2">
                서명/ 도장을<br>추가하여 사용하세요.
              </p>
            </div>
          </div>


          <ng-container *ngFor="let item of signatureList; let i = index">
            <div [ngClass]="(item.signId == selectSignature ? 'p-0 select-sign' : 'p-0')" style="cursor: pointer;"
              class="p-0 " (click)="onSelectSignature(item.signId)">
              <div class="imgbox p-0 d-flex flex-column justify-content-center align-items-center wh-bod-sig-001">

                <div class=" signatureFrame " style="background-image: url('{{signatueImage(item.signId)}}');">

                  <img src="{{signatueImage(item.signId)}}" style="visibility: hidden;" class="img-thumbnail">
                </div>

                <div class="d-flex bd-highlight w-100 mt-auto px-3 py-2">
                  <a class="bd-highlight cursor-pointer mr-2" (click)="onFavorite(item.signId,item.dsigFavorite,i)"
                    *ngIf="conf.icon.favorite">
                    <img src="../../../../assets/img/icon/star.png" *ngIf="!item.dsigFavorite">
                    <img src="../../../../assets/img/icon/staryellow.png" *ngIf="item.dsigFavorite">
                  </a>
                  <a class="bd-highlight cursor-pointer mx-2" (click)="downloadSignatureFilePng(item.signId)"
                    *ngIf="conf.icon.download"><img src="../../../../assets/img/icon/download.png"></a>
                  <a class="ml-auto bd-highlight mr-0 cursor-pointer" (click)="deleteSignature(item.signId,i)"
                    *ngIf="conf.icon.delete"><img src="../../../../assets/img/icon/Xcircle.png"></a>
                </div>
              </div>
            </div>
          </ng-container>

        </div>

      </div>

    </div>



  </div>



</section>

<app-modal-add-signature></app-modal-add-signature>

<app-loading></app-loading>
