<section style="background: #e9ecef" class="container-fluid ">
    <section class="container">
        <div style="background: #e9ecef;height: 100vh !important;" class="h-100 align-items-center row d-flex justify-content-center">
            <div class="py-3 d-flex justify-content-center">
                <form [formGroup]=formInputMail class="confirmForm bg-white"
                    style="border-top: 2px solid #111; width: 100%;">
                    <div class="forgotTxthead text-center ">
                        <h3><b>수신자 확인</b></h3>
                        <h5 class="m-0 ">전자 계약의 진행을 위하여 요청 받았던</h5>
                        <h5><span>본인의 이메일 주소</span> 를 입력해주세요. </h5>

                    </div>
                    <div class="form-group row m-0 confirmFormInput">
                        <div class="col-sm-12 p-0">
                            <input type="mail" class="form-control inp-tmp-nam-001" formControlName='contractMail'
                                placeholder="이메일 주소를 입력해주세요" />
                            <small class="form-text text-muted mt-1 text-validators ">이메일 번호를 입력해 주세요.</small>
                        </div>
                    </div>
                    <div class="form-group row d-flex justify-content-center mb-0">
                        <button class="btn btn-big btn-gray" style="width: auto !important;" (click)="goToHome()"
                            type="button" name="" id=""> 취소
                        </button>
                        <button class="btn btn-big btn-crimson ml-3" (click)="onClickBtnOk()"
                            style="width: auto !important;" type="button">
                            확인
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</section>

<app-alert-shared></app-alert-shared>
<app-loading></app-loading>