import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InquiryService } from './inquiry.service';
import { ServiceCenterService } from '../../service/service-center/service-center.service';
import { LoadingService } from '../../shared/loading/loading.service';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {

  @Output() clickItem = new EventEmitter<any>();
  @Output() getSuccess = new EventEmitter<any>();
  @Input() api;
  td: any;
  constructor(
    private inquiryService: InquiryService,
    private serviceCenter: ServiceCenterService,
    private loadingService: LoadingService,
  ) { }

  apiGet: boolean = true;

  itemTotal: number = 0;
  paramsApi = {
    data: "",
    pageNo: 0,
    pageSize: 5
  }

  pagination = {
    maxPage: 1,
    currentPage: 1
  }

  ngOnInit(): void {
    this.initParamsApi();
  }

  ngDoCheck(): void {
    this.apiGet = this.api;
    if (this.apiGet) {
      this.getAllInquiry();
      this.apiGet = false;
    }
  }

  inData() {
    return [
      {
        frstReginPnttm: '2020/04/23 13:14',
        quesCont: '일번 조항에 나와 있는 내용에 문제가 있습니다…',
        quesStatus: '답변완료'
      },
      {
        frstReginPnttm: '2020/04/23 13:14',
        quesCont: '내용 확인 후 처리될 수 있도록…',
        quesStatus: '답변완료'
      },
      {
        frstReginPnttm: '2020/04/23 13:14',
        quesCont: '결제 취소가 가능할까요?',
        quesStatus: '문의'
      }
    ]
  }

  selectItem(item: any) {
    this.clickItem.emit(item);
  }

  initParamsApi(): void {
    this.paramsApi = this.inquiryService.getData();
    this.pagination.currentPage = this.inquiryService.getPageNo() + 1;
  }

  getAllInquiry() {
    this.loadingService.openLoading();
    this.serviceCenter.getAllInquiry(this.paramsApi).subscribe((rs: any) => {
      if (rs.status) {
        this.getSuccess.emit(false);
        this.td = rs.data;
        this.itemTotal = rs.total;
        this.calMaxPage(rs.total, this.paramsApi.pageSize);
      }
      this.loadingService.closeLoading();
    });
  }

  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.paramsApi.pageNo = this.pagination.currentPage - 1;
      }
      this.loadingService.openLoading();
      this.serviceCenter.getAllInquiry(this.paramsApi).subscribe((rs: any) => {
        if (rs.status) {
          this.td = rs.data;
        }
        this.loadingService.closeLoading();
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.paramsApi.pageNo = $event - 1;
    this.getAllInquiry();
  }
}
