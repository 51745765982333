<div id="sidebar-wrapper" [ngClass]="sidebar ? 'sidebar left' : 'sidebar left fliph'">

  <div class="sidebar-heading d-flex justify-content-between p-2">
    <img class="logo_sidebar" src="/assets/img/frontpage/logo.png" alt="">
    <div style="cursor: pointer;width: 32px;height: 32px !important;" class="btn btn-outline-info p-2"
      (click)="toggleSideBar()">
      <span aria-hidden="true" class="p-0">
        <fa-icon [icon]="faBars" class="fa-1x p-0" style="color:#C5C5C5;"></fa-icon>
      </span>
    </div>
  </div>

  <div class="profile py-2">
    <div class="row" style="text-align: center;">
      <div class="col-6">
        <fa-icon [icon]="faUserCircle" class="fa-3x" style="color:#C5C5C5;"></fa-icon>
      </div>
      <div class="col-6" style="text-align: left;">Test</div>
    </div>
  </div>

  <ul class="soctal_media list-unstyled">
    <li>
      <a href="#">
        <fa-icon [icon]="faFacebookSquare" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon>
      </a>
    </li>
    <li>
      <a href="#">
        <fa-icon [icon]="faLinkedin" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon>
      </a>
    </li>
    <li>
      <a href="#">
        <fa-icon [icon]="faSkype" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon>
      </a>
    </li>
    <li>
      <a href="#">
        <fa-icon [icon]="faYoutubeSquare" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon>
      </a>
    </li>
  </ul>

  <ul>
    <li>
      <a href="#">
        <fa-icon [icon]="faBell" class="fa-lg" style="color:yellow;"></fa-icon>
      </a>
    </li>
  </ul>


  <ul class="list-sidebar my-2">
    <li>
      <a href="dashboard/home">
        <fa-icon [icon]="faHome" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon>
        <span class="nav-label"> Dashboards</span>
      </a>
    </li>
    <li> <a style="cursor: pointer;" (click)="changePage('role')">
        <fa-icon [icon]="faFilePdf" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon><span
          class="nav-label">&nbsp;Template Management</span>
      </a>
    </li>
    <li> <a style="cursor: pointer;" (click)="changePage('position')">
        <fa-icon [icon]="faFileAlt" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon><span
          class="nav-label">&nbsp;Contract Management</span>
      </a>
    </li>

    <li>
      <a data-toggle="collapse" data-target="#setting" class="collapsed active" style="cursor: pointer;">
        <fa-icon [icon]="faCog" class="fa-lg text-center" style="color:#C5C5C5;"></fa-icon><span
          class="nav-label">&nbsp;Setting</span>
      </a>
      <ul class="sub-menu collapse" id="setting">
        <li class="active"><a style="cursor: pointer;" (click)="changePage('profile')">
            <fa-icon [icon]="faChevronRight" class="fa-lg" style="color:#C5C5C5;"></fa-icon>
            프로필
          </a></li>
        <li><a href="#">
            <fa-icon [icon]="faChevronRight" class="fa-lg" style="color:#C5C5C5;"></fa-icon>
            구매 / 내역
          </a>
        </li>
        <li><a href="#">
            <fa-icon [icon]="faChevronRight" class="fa-lg" style="color:#C5C5C5;"></fa-icon>
            메일 템플릿
          </a>
        </li>
        <li><a href="#">
            <fa-icon [icon]="faChevronRight" class="fa-lg" style="color:#C5C5C5;"></fa-icon>
            조직 및 사용자 관리
          </a>
        </li>
      </ul>
    </li>
    <li *ngIf=(!sidebar)><a (click)="toggleSideBar()" style="cursor: pointer;"><i class="fa fa-files-o">></i></a></li>
  </ul>





</div>
