import { Component, OnInit, Input } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons/faFolderOpen';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { SidebarService } from './sidebar.service';
import { LoginService } from '../../../frontpage/login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-damo',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  @Input() action: any;


  getUser: boolean = true;
  menu_action: any;
  manu: any;
  subManu: any;
  faHome = faHome;
  faFolderOpen = faFolderOpen;
  faCopy = faCopy;
  faCog = faCog;
  faChevronRight = faChevronRight;

  linkedId: string = '';
  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.menu_action = this.action;
    this.manu = {
      create_an_electronic: { c: ' active', b: true },
      home: { c: '', b: false },
      template_management: { c: '', b: false },
      contract_management: { c: '', b: false },
      set: { c: '', b: false },
      contract_information: { c: '', b: false },
      document_upload: { c: '', b: false },
      editor: { c: '', b: false },
    };
  }

  ngDoCheck(): void {
    if (this.sidebarService.getState()) {
      this.menu_action = this.sidebarService.getManuActive();
      this.setActionMenu();
      this.sidebarService.setState(false);
    }
    if (this.sidebarService.getStateSub()) {
      this.subManu = this.sidebarService.getSubManuActive();
      this.sidebarService.setStateSub(false);
    }

    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.linkedId = this.loginService.getProfileLogin().linkedId;
      this.getUser = false;
    }



  }

  setActionMenu() {
    const key = Object.keys(this.manu);
    for (let i = 0; i < key.length; i++) {
      if (key[i] === this.menu_action) {
        this.manu[key[i]].b = true;
        this.manu[key[i]].c = ' active';
      } else {
        this.manu[key[i]].b = false;
        this.manu[key[i]].c = '';
      }
    }
  }

  toLink(link) {
    console.log('링크', link)
    this.router.navigate(['/' + link], {skipLocationChange: true});
  
  }

  visibleIC() {
    document.getElementById("bkImg").style.visibility = "visible";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0px";
    document.getElementById("mySubnav").style.width = "0px";
    document.getElementById("mySubnav2").style.width = "0px";
  }

  openSub() {
    document.getElementById("mySubnav").style.width = "55%";
    document.getElementById("mySubnav2").style.width = "0px";
  }

  openSub2() {
    document.getElementById("mySubnav2").style.width = "55%";
    document.getElementById("mySubnav").style.width = "0px";
  }

  closeSubs() {

    var downnav = document.getElementById("navbarSupportedContent");
    var burgerX = document.getElementById("tognav");

    downnav.classList.remove("show");
    burgerX.classList.remove("is-active");
    document.getElementsByClassName("body-container")[0].setAttribute("style", 'overflow-y:auto;');

    document.getElementById("mySidenav").style.width = "0px";
    document.getElementById("mySubnav").style.width = "0px";
    document.getElementById("mySubnav2").style.width = "0px";

    document.getElementById("mySubnav2").style.width = "0px";
    document.getElementById("mySubnav").style.width = "0px";
  }

}
