<section class="w-100" style="background-color: #e2e6eb;min-height: 90vh;">


  <div class="container-lg ml-lg-0 mr-lg-auto p-0">

    <!-- <h3 _ngcontent-yjk-c119="" class="tit-tem-man-001" > 계약서 만들기 계약서 종류 선택 </h3> -->
    <div class="w-100 d-flex justify-content-start align-items-center">
      <h3 style="padding-bottom: 50px;" class="tit-pag-up w-100">
        제공된 서식으로 만들기
        <span><img src="../../../../assets/img/icon/icon-arrow-right-cr.png" style="vertical-align: baseline;"
            class="img-fluid mx-1"></span>
        <span class="px-2 font-weight-700 barumBold">계약서 종류 선택 </span>

      </h3>
    </div>


    <div class="largerUi">
      <div class="row pb-5">
        <div class="col-md-3 pb-md-0 pb-2">
          <div [className]="typeSelect.work ? 
          'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
          'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('work')" style="
            height: 250px;">
            <div>
              <span class="ico ico-work"></span>
            </div>
            <span class="margin-t20">표준근로 계약서 </span>
          </div>
        </div>

        <div class="col-md-3 pb-md-0 pb-2">
          <div [className]="typeSelect.jobContract ? 
        'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
        'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('jobContract')" style=" 
            height: 250px;">
            <div>
              <span class="ico ico-job"></span>
            </div>
            <span class="margin-t20">아르바이트 근로계약서 </span>
          </div>
        </div>

        <div class="col-md-3 pb-md-0 pb-2">
          <div [className]="typeSelect.lease ? 
        'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
        'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('lease')" style=" 
            height: 250px;">
            <div>
              <span class="ico ico-lea"></span>
            </div>
            <span class="margin-t20">부동산 임대계약서 </span>
          </div>
        </div>

        <div class="col-md-3 pb-md-0 pb-2">
          <div [className]="typeSelect.borrower ? 
        'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
        'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('borrower')" style=" 
            height: 250px;
          ">
            <div>
              <span class="ico ico-bor"></span>
            </div>
            <span class="margin-t20">차용증 </span>
          </div>
        </div>
      </div>
    </div>


    <div class="smallerUi">
      <div class="row pb-5">
        <div class="col-lg-12 pb-2">
          <div [className]="typeSelect.work ? 
          'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
          'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('work')" style=" 
            height: 186px;">
            <div>
              <span class="ico ico-work"></span>
            </div>
            <span class="margin-t10">표준근로 계약서 </span>
          </div>
        </div>

        <div class="col-lg-12  pb-2">
          <div [className]="typeSelect.jobContract ? 
        'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
        'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('jobContract')" style=" 
            height: 186px;">
            <div>
              <span class="ico ico-job"></span>
            </div>
            <span class="margin-t10"> 아르바이트 근로계약서 </span>
          </div>
        </div>

        <div class="col-lg-12  pb-2">
          <div [className]="typeSelect.lease ? 
        'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
        'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('lease')" style=" 
            height: 186px;">
            <div>
              <span class="ico ico-lea"></span>
            </div>
            <span class="margin-t10">부동산 임대계약서 </span>
          </div>
        </div>

        <div class="col-lg-12  pb-2">
          <div [className]="typeSelect.borrower ? 
        'w-100 d-flex flex-column justify-content-center align-items-center rounded typeActions' :
        'w-100 d-flex flex-column justify-content-center align-items-center rounded type'"
            (click)="selectType('borrower')" style=" 
            height: 186px;
          ">
            <div>
              <span class="ico ico-bor"></span>
            </div>
            <span class="margin-t10"> 차용증 </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row py-2">
      <div class="col-12 d-flex justify-content-center">
        <button [disabled]="btn.next" class="btn btn-big btn-crimson" style="width:auto !important;" type="button"
          (click)="selectBtnType()">다음</button>
      </div>
    </div>

  </div>

</section>