import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ContractInformationRealestateService {

  dataFromContract: {
    '{location}': string,
    '{landp}': string,
    '{areaone}': string,
    '{build}': string,
    '{areatwo}': string,
    '{part}': string,
    '{areatree}': string,
    '{depoone}': string,
    '{exped}': string,
    '{depotwo}': string,
    '{pay}': string,
    '{depotree}': string,
    '{poys}': string,
    '{poms}': string,
    '{pods}': string,
    '{bala}': string,
    '{ptys}': string,
    '{ptms}': string,
    '{ptds}': string,
    '{baborrla}': string,
    '{pcpr}': boolean,
    '{pcpo}': boolean,
    '{pday}': string,
    '{tdys}': string,
    '{tdms}': string,
    '{tdds}': string,
    '{tdye}': string,
    '{tdme}': string,
    '{tdde}': string,
    '{spec}': string,
    '{yy}': string,
    '{mm}': string,
    '{dd}': string,
    '{addcl}': string,
    '{senuml}': string,
    '{pnuml}': string,
    '{lnamel}': string,
    '{raddcl}': string,
    '{rcnuml}': string,
    '{rcanl}': string,
    '{addct}': string,
    '{senumt}': string,
    '{pnumt}': string,
    '{lnamet}': string,
    '{raddct}': string,
    '{rcnumt}': string,
    '{rcant}': string,
    '{cho}': boolean,
    '{cht}': boolean
  } = {
      '{location}': '',
      '{landp}': '',
      '{areaone}': '',
      '{build}': '',
      '{areatwo}': '',
      '{part}': '',
      '{areatree}': '',
      '{depoone}': '',
      '{exped}': '',
      '{depotwo}': '',
      '{pay}': '',
      '{depotree}': '',
      '{poys}': 'N',
      '{poms}': 'N',
      '{pods}': 'N',
      '{bala}': '',
      '{ptys}': 'N',
      '{ptms}': 'N',
      '{ptds}': 'N',
      '{baborrla}': '',
      '{pcpr}': false,
      '{pcpo}': false,
      '{pday}': 'N',
      '{tdys}': 'N',
      '{tdms}': 'N',
      '{tdds}': 'N',
      '{tdye}': 'N',
      '{tdme}': 'N',
      '{tdde}': 'N',
      '{spec}': '',
      '{yy}': 'N',
      '{mm}': 'N',
      '{dd}': 'N',
      '{addcl}': '',
      '{senuml}': '',
      '{pnuml}': '',
      '{lnamel}': '',
      '{raddcl}': '',
      '{rcnuml}': '',
      '{rcanl}': '',
      '{addct}': '',
      '{senumt}': '',
      '{pnumt}': '',
      '{lnamet}': '',
      '{raddct}': '',
      '{rcnumt}': '',
      '{rcant}': '',
      '{cho}': true,
      '{cht}': false
    }

  constructor() { }

  setDataFromContract(key: any, value: string) {
    this.dataFromContract[key] = value;
  }

  getDataFromContract(key: any) {
    return this.dataFromContract[key];
  }

  clearDataFromContract() {
    this.dataFromContract = {
      '{location}': '',
      '{landp}': '',
      '{areaone}': '',
      '{build}': '',
      '{areatwo}': '',
      '{part}': '',
      '{areatree}': '',
      '{depoone}': '',
      '{exped}': '',
      '{depotwo}': '',
      '{pay}': '',
      '{depotree}': '',
      '{poys}': 'N',
      '{poms}': 'N',
      '{pods}': 'N',
      '{bala}': '',
      '{ptys}': 'N',
      '{ptms}': 'N',
      '{ptds}': 'N',
      '{baborrla}': '',
      '{pcpr}': false,
      '{pcpo}': false,
      '{pday}': 'N',
      '{tdys}': 'N',
      '{tdms}': 'N',
      '{tdds}': 'N',
      '{tdye}': 'N',
      '{tdme}': 'N',
      '{tdde}': 'N',
      '{spec}': '',
      '{yy}': 'N',
      '{mm}': 'N',
      '{dd}': 'N',
      '{addcl}': '',
      '{senuml}': '',
      '{pnuml}': '',
      '{lnamel}': '',
      '{raddcl}': '',
      '{rcnuml}': '',
      '{rcanl}': '',
      '{addct}': '',
      '{senumt}': '',
      '{pnumt}': '',
      '{lnamet}': '',
      '{raddct}': '',
      '{rcnumt}': '',
      '{rcant}': '',
      '{cho}': true,
      '{cht}': false
    }
  }
}
