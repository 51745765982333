import { environment } from '../../../environments/environment';


const BASE_URL = environment.nodeEndPoint.url;
const BASE_URL_OAUTH = environment.backend.endpoint;

const CONTEXTPATH = environment.backend.endpoint;


export const API_URL = {


    // OAuth Config All
    clientId: environment.backend.clientId,
    clientSecret: environment.backend.clientSecret,
    grantType: environment.backend.grantType,

    // open file back end
    openPdfdil: environment.openFileBackEnd.pdfUrl,

    // Login oAuth And get token
    apiLogin: BASE_URL_OAUTH + 'oauth/token',

    // Withdrawal

    withdrawal: BASE_URL + 'user/deleteUser/',

    // Template
    getTemplateAll: BASE_URL + 'template/getAllTemplate',
    deleteTemplate: BASE_URL + 'template/deleteTemplate',
    editTemp: BASE_URL + 'template/updateTemplate',
    getFildMark: BASE_URL + 'fildmark',
    uploadTemplate: BASE_URL + 'template/uploadFileTemplate',
    openFileTemplate: BASE_URL + 'digitalsigin/openFileTemplate',
    saveMarkTemplate: BASE_URL + 'template/saveMarkTemplate',
    getMarkAllTemplate: BASE_URL + 'template/getMarkTemplate',
    createTemplate: BASE_URL + 'template/createTemplate',
    createTemplateSystem: BASE_URL + 'template/creacteTemplateSystem',
    deleteMarkByMarkId: BASE_URL + 'template/deleteMarkTemplateByMarkId',
    getSignatureContract: BASE_URL + 'template/getSignatureContract',

    // Contract API
    insertContract: BASE_URL + 'contract/saveContractor',
    getAllContractsRequest: BASE_URL + 'contract/getAllContractsRequest',
    getAllContractsRequestToMe: BASE_URL + 'contract/getAllContractsRequestToMe',
    getContractDetails: BASE_URL + 'contract/ContractDetails',
    getContractDataDashboard: BASE_URL + 'contract/getContractDataDashboard',
    updateCancleContract: BASE_URL + 'contract/cancleContract',
    getPriorityContractSignatures: BASE_URL + 'contract/getPriorityContractSignature',

    // Recipient Confirmation
    openContractRecipient: BASE_URL + 'contract/openContractRecipient',
    checkRecipientConfirmationEmail: BASE_URL + 'contract/checkRecipientConfirmationEmail',
    checkRecipientConfirmationPhone: BASE_URL + 'contract/checkRecipientConfirmationPhone',
    checkRecipientConfirmationPassword: BASE_URL + 'contract/checkRecipientConfirmationPassword',
    getUserContractNotMember: BASE_URL + 'contract/getUserContractNotMember',
    getRecipientTemplate: BASE_URL + 'contract/getRecipientTemplate',
    resendContract: BASE_URL + 'contract/resendContract',
    checkOtpCode: BASE_URL + 'contract/checkOtpCode',

    // Signature Managemant
    getSignatureList: BASE_URL + 'setting/getSignatureList',
    openSignature: BASE_URL + 'digitalsigin/openSignature',
    openSignaturePeview: BASE_URL + 'digitalsigin/openSignaturePeview',
    deleteSignature: BASE_URL + 'setting/deleteSignature',
    updateSignatureFavorite: BASE_URL + 'setting/updateSignatureFavorite',
    updateImforDetailsCustomer: BASE_URL + 'setting/updateImforDetailsCustomer',
    updateAdditionalImfomationCustomer: BASE_URL + 'setting/updateAdditionalImfomationCustomer',
    updatePassword: BASE_URL + 'setting/updatePassword',
    updateProfileDetailsStaff: BASE_URL + 'setting/updateProfileDetailsStaff',
    deleteDivisionByDivId: BASE_URL + 'setting/deleteDivisionByDivId',
    updateStaffUseYnToN: BASE_URL + 'setting/updateStaffUseYnToN',
    createStaffTeamsExcel: BASE_URL + 'setting/createStaffTeamsExcel',
    searchUserInOrgs: BASE_URL + 'setting/searchUserInOrgs',
    getUserSearch: BASE_URL + 'setting/getUserSearch',


    // organization api
    getCompany: BASE_URL + 'setting/getCompany',
    getDivisionsTree: BASE_URL + 'contract/getDivisionTree',
    getOrganization: BASE_URL + 'setting/getOrganization',
    createDivision: BASE_URL + 'setting/createDivision',
    getDivisions: BASE_URL + 'setting/getDivisions',
    getUserDivisions: BASE_URL + 'setting/getUserDivisions',
    createStaffTems: BASE_URL + 'setting/createStaffTeams',

    // Digital Signature API
    setDigitalSignature: BASE_URL + 'digitalsigin/encryptRSA',
    uploadSignatureUser: BASE_URL + 'digitalsigin/uploadSignatureUser',
    uploadSignatureUserNotMember: BASE_URL + 'digitalsigin/uploadSignatureUserNotMember',
    uploadAttachmentFile: BASE_URL + 'digitalsigin/uploadAttachmentFile',

    // User Profile
    getUserImformationStaff: BASE_URL + 'setting/getUserImformationStaff',
    getUserImformationCustomer: BASE_URL + 'setting/getUserImformationCustomer',
    getUserProfile: BASE_URL + 'user/profileUser',
    registerEcontract: BASE_URL + 'user/registerEcontract',
    createNewPassword: BASE_URL + 'user/createNewPassword',
    requestDeleteUser: BASE_URL + 'user/requestDeleteUser', // 회원 탈퇴용으로 써주세요

    // Send Mail
    congratulationUser: BASE_URL + 'user/congratulationUser',
    forgotPassword: BASE_URL + 'user/forgotPassword',

    // Active User
    activeUserVerifi: BASE_URL + 'user/activeUserVerifi',

    // Service Center
    insertInquire: BASE_URL + 'service/insertInquire',
    getAllInquiry: BASE_URL + 'service/getAllInquiry',
    getInquiryAnswer: BASE_URL + 'service/getInquiryAnswer',
    getTemplateMail: BASE_URL + 'service/getTemplateMail',
    updateTemplateMail: BASE_URL + 'service/updateTemplateMail',
    createTemplateMail: BASE_URL + 'service/createTemplateMail',
    deleteTemplateMail: BASE_URL + 'service/deleteTemplateMail',
    searchNoticeFront: BASE_URL + 'service/searchNoticeFront',
    getQuestionAnswerFront: BASE_URL + 'service/getQuestionAndAnswerFront',
    insertInquireFront: BASE_URL + 'service/insertInquireFront',
    getPersonalNotReadNotic: BASE_URL + 'service/getPersonalNotReadNotic',
    insertPersonalNotic: BASE_URL + 'service/insertPersonalNotic',

    // Notice System
    getNoticeSystemByUser: BASE_URL + 'notice/getNoticeSystemByUser',
    reactNoticeSystem: BASE_URL + 'notice/reactNoticeSystem',


    // Payment Management
    getPaymentHistoryList: BASE_URL + 'payment/getPaymentHistoryList',
    getInquirePaymentList: BASE_URL + 'payment/getInquirePaymentList',
    refundPayment: BASE_URL + 'payment/refundPayment',

    // Payment Api
    deductionPoint: BASE_URL + 'pay/point',
    tossPayURL: BASE_URL + 'pay/nextOrderId',
    tossSuccess: BASE_URL + 'pay/tossPaymentsSuccess',
    tossFail: BASE_URL + 'pay/tossPaymentsFail',
    createTossApp: BASE_URL + 'pay/createTossApp',

    //
    getCodeBookType: BASE_URL + 'codebook/getCodebookType',

    // SNS API
    sns: BASE_URL + 'sns',

    // SNS PAY API
    snsPay: 'pay',

    // BLOCKCERT(전자계약 인증 관리) API
    createBlockCert: BASE_URL + 'template/createBlockCert',
    blockCert: BASE_URL + 'blockCert/elecCont',
    pathGetBlockCert: BASE_URL + 'blockCert/getBlockCert',
    getContractInfo: BASE_URL + 'contract/getContCertOnlineInfo',
    resendBlockCert: BASE_URL + 'blockCert/resendBlockChain',
    blockCertDetails: BASE_URL + 'blockCert/blockCertDetail',

    // Mobile Verification
    mobileVerification: BASE_URL + 'pay/getEncDataNiceAuth',

    // Naver Callback URL
    //CONTEXTROOT_URL_WITH_PROTOCOL: window.location.protocol+'://'+window.location.hostname+'/'+window.location.port+'/'+window.location.pathname,
    BACKEND_PROTOCOL_HOST_PORT: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port,
    BACKEND_PROTOCOL_HOST_PORT_CONTEXTPATH: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + CONTEXTPATH,
    BACKEND_PROTOCOL_HOST_CONTEXTPATH: window.location.protocol + '//' + window.location.hostname + CONTEXTPATH,

    BACKEND_HOST_PORT: window.location.hostname + ':' + window.location.port,
    BACKEND_HOST_PORT_CONTEXTPATH: window.location.hostname + ':' + window.location.port + CONTEXTPATH,
    BACKEND_HOST_CONTEXTPATH: window.location.hostname + CONTEXTPATH,

    BACKEND_CONTEXTPATH: CONTEXTPATH,

    //Community
    createCommunity: BASE_URL + 'community/createCommunity',
    searchCommunity: BASE_URL + 'community/searchCommunity',
}
