import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectContractTypeService {

  contractType: string = '';

  state = 'init';

  dataTemplate: {
    tempId: string, tempNm: string, templType: string, path: string
  } = {
      tempId: '', tempNm: '', templType: '', path: ''
    }
  constructor() { }


  setState(state: 'init' | 'back') {
    this.state = state;
  }

  getState() {
    return this.state;
  }

  checkState(): boolean {
    let bool = false;
    if (this.state == 'init') {
      bool = true;
    }
    return bool;
  }

  setContractType(contractType: string) {
    this.contractType = contractType;
  }

  getContractType(): string {
    return this.contractType;
  }

  setTempId(tempId: string) {
    this.dataTemplate.tempId = tempId;
  }

  getTempId(): string {
    return this.dataTemplate.tempId;
  }

  setTempNm(tempNm: string) {
    this.dataTemplate.tempNm = tempNm;
  }

  getTempNm(): string {
    return this.dataTemplate.tempNm;
  }

  setTemplType(templType: string) {
    this.dataTemplate.templType = templType;
  }

  getTemplType(): string {
    return this.dataTemplate.templType;
  }

  setPath(path: string) {
    this.dataTemplate.path = path;
  }

  getPath(): string {
    return this.dataTemplate.path;
  }
}