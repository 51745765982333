import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertSharedService {
  show: boolean = false;
  state: string;

  modal: {
    herder: {
      title: string,
      btnClose: boolean
    },
    body: {
      title: string,
      subTitle: string
    },
    footer: {
      btn: {
        btnType: any
        btnNm: string,
        btnStyle: string,
        btnFunc: any
      }[]
    }
  }
    =
    {
      herder: {
        title: null,
        btnClose: true
      },
      body: {
        title: null,
        subTitle: null
      },
      footer: {
        btn: []
      }
    }

  constructor() { }

  open(
    herder: {
      title: string,
      btnClose: boolean
    },
    body: {
      title: string,
      subTitle: string
    },
    btn: {
      btnType: any
      btnNm: string,
      btnStyle: 'btn-black' | 'btn-white' | 'btn-gray' | 'btn-crimson',
      btnFunc: any
    }[]) {
    this.modal.herder = herder;
    this.modal.body = body;
    this.modal.footer.btn = btn;
    this.show = true;
  }

  getHeaderModal(): {
    title: string,
    btnClose: boolean
  } {
    return this.modal.herder;
  }

  getBodyModal(): {
    title: string,
    subTitle: string
  } {
    return this.modal.body;
  }

  getFooterModal(): {
    btn: {
      btnType: any
      btnNm: string,
      btnStyle: string,
      btnFunc: any
    }[]
  } {
    return this.modal.footer;
  }

  getStatusShowModal(): boolean {
    return this.show;
  }

  close() {
    this.show = false
  }
}
