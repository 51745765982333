import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../manage/service/user/user.service';

@Component({
  selector: 'app-congratulations-joining',
  templateUrl: './congratulations-joining.component.html',
  styleUrls: ['./congratulations-joining.component.scss']
})
export class CongratulationsJoiningComponent implements OnInit {
  verifiKey: string = "";
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.verifiKey = params.verifi.replace(/#/g, "");
      });
  }
  
  ngAfterViewInit(): void {
    this.activeUserVerifi();
  }

  activeUserVerifi(){
    this.userService.activeUserVerifi(this.verifiKey).subscribe((rs: any) => {
      if(rs.status){
      }
    });
  }

  goToLogin(){
    this.router.navigate(['login']);
  }
}
