import { Injectable } from '@angular/core';
import {API_URL} from "../api.constant";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  data = {
    data: {
      frstRegisNo: "",
      serachType: "000001",
      text: ""
    },
    pageNo: 0,
    pageSize: 5
  }

  pathCreateCommunity: string = API_URL.createCommunity;
  pathBlockCert: string = API_URL.blockCert;
  pathGetContractInfo: string = API_URL.getContractInfo;
  pathResendBlockCert: string = API_URL.resendBlockCert;
  pathBlockCertDetails: string = API_URL.blockCertDetails;
  pathSearchCommunity: string = API_URL.searchCommunity;

  uploadStatus: boolean = false;

  constructor(
      private http: HttpClient
  ) {
  }
  public createCommunity(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathCreateCommunity, params);
  }

  public blockCert(eCertId: string) {
    const param = {
      data: {
        ecertId: eCertId,
      }
    }
    return this.http.post(this.pathBlockCert, param);
  }

  public getContractInfo(eCertId: string, frstRegisterNo: string) {
    const param = {
      data: {
        contId: eCertId,
        contorId: frstRegisterNo
      }
    }
    return this.http.post(this.pathGetContractInfo, param);
  }

  public resendBlockCert(contId: string, contorId: string, eCertId: string) {
    let param = {
      data: {
        contId: contId,
        contorId: contorId,
        ecertId: eCertId
      }
    }
    return this.http.post(this.pathResendBlockCert, param)
  }

  public blockCertDetails(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathBlockCertDetails, param);
  }

  getUploadStatus(): boolean {
    return this.uploadStatus;
  }

  setUploadStatus(uploadStatus: boolean): void{
    this.uploadStatus = uploadStatus;
  }

  public searchCommunity(params: any) {
    return this.http.post(this.pathSearchCommunity, params);
  }

  setPageNo(pageNo: number) {
    this.data.pageNo = pageNo;
  }

  getPageNo() {
    return this.data.pageNo;
  }

  setPageSize(pageSize: number) {
    this.data.pageSize = pageSize;
  }

  getPageSize() {
    return this.data.pageSize;
  }

}

