import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { NavbarService } from '../navbar/navbar.service';
@Component({
  selector: 'app-inquiry-managemant',
  templateUrl: './inquiry-managemant.component.html',
  styleUrls: ['./inquiry-managemant.component.scss']
})
export class InquiryManagemantComponent implements OnInit {
  sidebar: any = "set";
  constructor(
    private sidebarService: SidebarService,
    private navbarService: NavbarService,
  ) {
    this.sidebarService.setManuActive(this.sidebar);
    this.sidebarService.setSubManuActive('inquiry-managemant');
  }

  apiGet: boolean = true;
  item: any;

  state: {
    inquiry: boolean,
    inquiryDetail: boolean,
    question: boolean
  } = {
      inquiry: true,
      inquiryDetail: false,
      question: true
    }

  ngOnInit(): void {
    this.navbarService.setAction('inquiryManage');
  }

  ngAfterViewInit(): void {
    this.sidebarService.setManuActive(this.sidebar);
  }

  clickItem($event) {
    this.state.question = false;
    this.state.inquiryDetail = true;
    this.item = $event;
  }

  goBack($event) {
    if ($event) {
      this.state.question = true;
      this.state.inquiryDetail = false;
    }
  }

  sendInquiry($event) {

    this.apiGet = $event;
  }
}
