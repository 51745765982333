<div class="bg-modal" *ngIf="show">
    <!-- Modal -->
    <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content rounded-0" style="background: transparent">
          <div class="modal-header rounded-0" style="background: #ffffff;color: #000000;">
            <h5 class="modal-title" id="exampleModalLabel">삭제</h5>
            <button type="button" style="color: #000000;" class="close" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body rounded-0" style="background: #ffffff;color: #000000;">
            <p class="text-center">해당 떼이지를 삭제 하시겠습니까</p>
          </div>
          <div class="modal-footer rounded-0 d-flex justify-content-center" style="background: #ffffff;color: #000000;">
            <button type="button" class="btn btn-middle-short btn-white" >취소</button>
            <button type="button" class="btn btn-middle-short btn-black">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  