<div class="bg-modal" *ngIf="show">
  <!-- Modal -->
  <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">
        <div class="modal-header border-0 rounded-0" style="background: #ffffff;color: #000000;">

          <div class="border-bottom border-dark d-flex w-100 pb-2 ">
            <h5 class="modal-title font-weight-light" id="exampleModalLabel">계약 취소</h5>
            <a type="button" style="color: #000000;" class="close" (click)="closeModal()" data-dismiss="modal"
              aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>


        </div>
        <div class="modal-body rounded-0 pb-0" style="background: #ffffff;color: #000000;">

          <div class="row">
            <div class="col-12">
              <form [formGroup]=fromCancleContract >
                <div class="row mx-0 " style="border: 1px solid #ccc; background: #fff;">
                  
                  <div class="col-3 d-flex align-items-center"
                    style="background: #f7f7f7; border-right: solid 1px #cccccc;border-bottom: solid 1px #cccccc;">
                    <label class="mb-0">
                      취소 Type
                    </label>
                  </div>
                  <div style="border-bottom: solid 1px #cccccc;" class="col-9">
                    <div class="form-group mb-0 py-3">
                      <select  formControlName='contorCancle' class="form-control calSelect">
                        <option value="0"></option>
                        <option *ngFor="let type of cancleTypes;let i = index" value="{{type.code}}">{{type.codeNm}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3 d-flex align-items-center"
                    style="background: #f7f7f7;  border-right: solid 1px #cccccc;">
                    <label class="mb-0">
                      취소 유형
                    </label>
                  </div>
                  <div class="col-9">
                    <div class="form-group py-3 mb-0">
                      <textarea rows="3" formControlName='message' placeholder="사유를 입력해 주세요."
                        class="form-control mb-0  w-100 caltextAre"></textarea>
                    </div>
                  </div>
                </div>
                  
              </form>
            </div>

          </div>


        </div>

        <div class="modal-footer rounded-0 d-flex justify-content-center border-0 py-4"
          style="background: #ffffff;color: #000000;">

          <button type="button" class="btn btn-white btn-middle-short" (click)="onClickBtn('btnNo')">취소</button>
          <button type="button" class="btn ml-2 btn-black btn-middle-short" (click)="onClickBtn('btnYes')">확인</button>


        </div>

      </div>
    </div>
  </div>
</div>