<div class="row largerUi" *ngIf="inuire.quesId != ''">

  <div class="col-12 ">
    <label class="tit-ref-001 labelTable"><b>환불/취소 요청 문의 상세</b></label>
  </div>

  <div class="col-12">


    <table class="oddtable table-bordered  layout display responsive-table">
      <thead>
        <tr>
          <th class="pl-3 p-0 coloredCell border-bottom-0" style="width: 100px;"> 등록일</th>
          <th class="p-0 border-0" colspan="2">

            <div class="row no-gutters">

              <div class="pl-3 regCell col-6 col-lg-5"> {{inuire.frstReginPnttm}}</div>
              <div class="pl-3 coloredCell col-2 col-lg-2">상태
              </div>
              <div class="pl-3 regCell col-4 col-lg-5">{{inuire.quesStatus}}</div>

            </div>

          </th>
        </tr>
      </thead>
      <tbody>


        <tr>
          <td class="text-left pl-3 p-0 coloredCell organisationnumber"> 문의 내용


          </td>
          <td class="p-3 organisationname" innerHTML="{{inuire.quesCont}}">
          </td>

        </tr>

        <tr>
          <td class="p-0 pl-3 text-left coloredCell organisationnumber">답변 내용


          </td>
          <td class="p-3 organisationname" innerHTML="{{inuire.answCont}}">
          </td>

        </tr>


      </tbody>
    </table>



  </div>

</div>

<section class="container-fluid smallerUi" *ngIf="inuire.quesId != ''">

  <div class="row " *ngIf="inuire.quesId != ''">
    <div class="col-12 ">
      <label class="tit-ref-001 labelTable"><b>환불/취소 요청 문의 상세</b></label>
    </div>
  </div>

  <div class="row">
    <div class="col-4 coloredCell">
      등록일
    </div>
    <div class="col-8 regCell">
      {{inuire.frstReginPnttm}}
    </div> 
  </div>
 

  <div class="row">
    <div class="col-4 coloredCell">
      상태
    </div>
    <div class="col-8 regCell">
      {{inuire.quesStatus}}
    </div>
  </div>

    
  <div class="row">
    <div class="col-4 coloredCell">
      문의 내용
    </div>
    <div class="col-8 regCell">
      {{inuire.quesCont}}
    </div>
  </div>

    
  <div class="row">
    <div class="col-4 coloredCell">
      답변 내용
    </div>
    <div class="col-8 regCell">
      {{inuire.answCont}}
    </div>
  </div>


</section>