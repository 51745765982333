import {Component, HostListener, OnInit} from '@angular/core';
import ImageManagement from "../../../shared/ImageManagement/image-management";
import {FormBuilder, FormGroup} from "@angular/forms";
import {fabric} from 'fabric';
import 'fabric-customise-controls';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {AlertSaveService} from "../../modal/alert-save/alert-save.service";
import {AlertSaveNameTemplateService} from "../../modal/alert-save-name-template/alert-save-name-template.service";
import {EditorPdfService} from "../../editor-pdf/editor-pdf.service";
import {TemplateService} from "../../../service/template/template.service";
import {LoadingService} from "../../../shared/loading/loading.service";
import {CertificationService} from "../../certification/certification.service";
import {SubmitCertificateService} from "../../modal/submit-certificate/submit-certificate.service";
import {NavbarViewService} from "../../navbar-view/navbar-view.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-tooltip-pdf-preview',
  templateUrl: './tooltip-pdf-preview.component.html',
  styleUrls: ['./tooltip-pdf-preview.component.scss']
})
export class TooltipPdfPreviewComponent implements OnInit {

  imageManagement: ImageManagement;

  pdfSrc = [{
    src: '../../../../assets/file/DigitalOcean Invoice 2020 Feb (2558051-414145077).pdf'
  }, {
    src: '../../../../assets/file/Sample Service Contractor Agreement.pdf'
  }];
  deleteIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATBAMAAACAfiv/AAAAAXNSR0IB2cksfwAAADBQTFRFAAAAm5ubmJiYmZmZmZmZmZmZmZmZmZmZmZmZm5ubmZmZnZ2d/Pz81dXV////7u7uUdt6qAAAABB0Uk5TACmL0fn/D57+HNr//////4UFpCwAAAB1SURBVHicY2BgYFR2dVZkAAG2itDQ0IgyEHNWKAhETQAKLgUzQ5cxMLBDWKHhDAwSoaFbQShCgEE1NPpsaOzp0FAFBtPQ0HtX710NDTVgcA0NjX3/FqjYgQGk5d9XIBGALIqkFskEJHORbENyA5LLkN0L9wUAylw/JXmURJ8AAAAASUVORK5CYII=";
  img = document.createElement('img').src = this.deleteIcon;


  markData: any[];
  maxPage: number;

  editor: {
    useComm: boolean
  } = {
    useComm: false
  }

  creacteMark: boolean = true;


  timer: any;

  constructor(
      private fb: FormBuilder,
      private http: HttpClient,
      private alertSaveService: AlertSaveService,
      private alertSaveNameTemplateService: AlertSaveNameTemplateService,
      private editorPdfService: EditorPdfService,
      private templateService: TemplateService,
      private loadingService: LoadingService,
      private certificationService: CertificationService,
      private submitCertificateService: SubmitCertificateService,
      private navbarViewService: NavbarViewService,
      private router: Router,
      private route: ActivatedRoute,
  ) {
    this.imageManagement = new ImageManagement();
    this.editorPdfService.clear();
  }

  @HostListener('scroll', ['$event'])
  scrollHandler(event) {
  }


  ngOnInit(): void {
    this.checkParametersOpen();
    this.loadingService.openLoading();
    this.loadFilTemplate();

  }

  checkParametersOpen() {
    this.route.queryParams.subscribe(
        params => {
          console.log(params);
          if (params.contor != undefined &&
              params.cont != undefined &&
              params.temp != undefined &&
              params.contNm != undefined &&
              params.sg != undefined) {

            this.editorPdfService.setTemplateId(params.temp);
            this.editorPdfService.setTemplNm(params.contNm);
            this.editorPdfService.setContorType('%');
            this.editorPdfService.setEdteMode("peview");
            this.editorPdfService.setState("init");
            this.editorPdfService.download = true;

          }
        });
  }


  ngDoCheck(): void {
      if (!this.editorPdfService.download) {
        this.loadingService.closeLoading();
      }
  }

  loadFilTemplate() {
    // console.log(this.editorPdfService.getBlockCertId());
    if (this.editorPdfService.getBlockCertId() != null) {
        this.pdfSrc[1].src = this.templateService.openFileTemplate(this.editorPdfService.getBlockCertId(), "Community");
      }
  }


  afterLoadComplete($event) {
    this.maxPage = $event._pdfInfo.numPages;
  }

}
