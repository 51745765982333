import { Component, HostListener } from '@angular/core';
import { AuthLoginService } from './manage/service/authLogin/auth-login.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';


declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'e-contract';

  constructor(
    private router: Router,
    private authService: AuthLoginService,
  ) { }

  ngOnInit(): void {
    // this.sizealert();
  }

  @HostListener('window:beforeunload', ['$event'])


  doSomething($event) {
  }

  // sizealert() {
  //   window.alert("Width is" + window.screen.width);
  //   window.alert("height is" + window.screen.height);
  // }

}
