<div style="position: sticky;" class="d-flex justify-content-between align-items-center py-2 shadow-sm">

    <div class="d-flex justify-content-center px-2">
      <span class="">
        {{page.number}}
      </span>
      <span class="">
        &nbsp;/&nbsp;
      </span>
      <span class="">
        {{page.maxpage}} &nbsp; 페이지
      </span>
    </div>

    <div class="d-flex justify-content-center px-2">
      <button class="btn btn-small-long btn-white" (click)="onCancelEditors()">
        취소
      </button>
    </div>
  
  </div>