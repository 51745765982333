import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.howto1();
    this.howto1T();
  }


  howto1() {
    var img1 = document.getElementById("slideImg1");
    var img2 = document.getElementById("slideImg2");
    var img3 = document.getElementById("slideImg3");
    var img4 = document.getElementById("slideImg4");
    var img5 = document.getElementById("slideImg5");

    var how1 = document.getElementById("how1");
    var how2 = document.getElementById("how2");
    var how3 = document.getElementById("how3");
    var how4 = document.getElementById("how4");
    var how5 = document.getElementById("how5");

    var how1M = document.getElementById("how1M");
    var how2M = document.getElementById("how2M");
    var how3M = document.getElementById("how3M");
    var how4M = document.getElementById("how4M");
    var how5M = document.getElementById("how5M");

    if (img1.style.display === "none") {
      img1.style.display = "block";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "none";


      how1.classList.remove("complete");
      how2.classList.add("complete");
      how3.classList.add("complete");
      how4.classList.add("complete");
      how5.classList.add("complete");

      how1M.classList.remove("complete");
      how2M.classList.add("complete");
      how3M.classList.add("complete");
      how4M.classList.add("complete");
      how5M.classList.add("complete");

    } else {
      img1.style.display = "block";


      how1.classList.remove("complete");
      how2.classList.add("complete");
      how3.classList.add("complete");
      how4.classList.add("complete");
      how5.classList.add("complete");

      how1M.classList.remove("complete");
      how2M.classList.add("complete");
      how3M.classList.add("complete");
      how4M.classList.add("complete");
      how5M.classList.add("complete");

    }
  }

  howto2() {
    var img1 = document.getElementById("slideImg1");
    var img2 = document.getElementById("slideImg2");
    var img3 = document.getElementById("slideImg3");
    var img4 = document.getElementById("slideImg4");
    var img5 = document.getElementById("slideImg5");

    var how1 = document.getElementById("how1");
    var how2 = document.getElementById("how2");
    var how3 = document.getElementById("how3");
    var how4 = document.getElementById("how4");
    var how5 = document.getElementById("how5");


    var how1M = document.getElementById("how1M");
    var how2M = document.getElementById("how2M");
    var how3M = document.getElementById("how3M");
    var how4M = document.getElementById("how4M");
    var how5M = document.getElementById("how5M");



    if (img2.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "block";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "none";

      how1.classList.add("complete");
      how2.classList.remove("complete");
      how3.classList.add("complete");
      how4.classList.add("complete");
      how5.classList.add("complete");

      how1M.classList.add("complete");
      how2M.classList.remove("complete");
      how3M.classList.add("complete");
      how4M.classList.add("complete");
      how5M.classList.add("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "block";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "none";

      how1.classList.add("complete");
      how2.classList.remove("complete");
      how3.classList.add("complete");
      how4.classList.add("complete");
      how5.classList.add("complete");

      how1M.classList.add("complete");
      how2M.classList.remove("complete");
      how3M.classList.add("complete");
      how4M.classList.add("complete");
      how5M.classList.add("complete");
    }
  }


  howto3() {
    var img1 = document.getElementById("slideImg1");
    var img2 = document.getElementById("slideImg2");
    var img3 = document.getElementById("slideImg3");
    var img4 = document.getElementById("slideImg4");
    var img5 = document.getElementById("slideImg5");

    var how1 = document.getElementById("how1");
    var how2 = document.getElementById("how2");
    var how3 = document.getElementById("how3");
    var how4 = document.getElementById("how4");
    var how5 = document.getElementById("how5");


    var how1M = document.getElementById("how1M");
    var how2M = document.getElementById("how2M");
    var how3M = document.getElementById("how3M");
    var how4M = document.getElementById("how4M");
    var how5M = document.getElementById("how5M");


    if (img3.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "block";
      img4.style.display = "none";
      img5.style.display = "none";

      how1.classList.add("complete");
      how2.classList.add("complete");
      how3.classList.remove("complete");
      how4.classList.add("complete");
      how5.classList.add("complete");

      how1M.classList.add("complete");
      how2M.classList.add("complete");
      how3M.classList.remove("complete");
      how4M.classList.add("complete");
      how5M.classList.add("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "block";
      img4.style.display = "none";
      img5.style.display = "none";

      how1.classList.add("complete");
      how2.classList.add("complete");
      how3.classList.remove("complete");
      how4.classList.add("complete");
      how5.classList.add("complete");

      how1M.classList.add("complete");
      how2M.classList.add("complete");
      how3M.classList.remove("complete");
      how4M.classList.add("complete");
      how5M.classList.add("complete");

    }
  }


  howto4() {
    var img1 = document.getElementById("slideImg1");
    var img2 = document.getElementById("slideImg2");
    var img3 = document.getElementById("slideImg3");
    var img4 = document.getElementById("slideImg4");
    var img5 = document.getElementById("slideImg5");

    var how1 = document.getElementById("how1");
    var how2 = document.getElementById("how2");
    var how3 = document.getElementById("how3");
    var how4 = document.getElementById("how4");
    var how5 = document.getElementById("how5");

    var how1M = document.getElementById("how1M");
    var how2M = document.getElementById("how2M");
    var how3M = document.getElementById("how3M");
    var how4M = document.getElementById("how4M");
    var how5M = document.getElementById("how5M");


    if (img4.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "block";
      img5.style.display = "none";

      how1.classList.add("complete");
      how2.classList.add("complete");
      how3.classList.add("complete");
      how4.classList.remove("complete");
      how5.classList.add("complete");

      how1M.classList.add("complete");
      how2M.classList.add("complete");
      how3M.classList.add("complete");
      how4M.classList.remove("complete");
      how5M.classList.add("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "block";
      img5.style.display = "none";

      how1.classList.add("complete");
      how2.classList.add("complete");
      how3.classList.add("complete");
      how4.classList.remove("complete");
      how5.classList.add("complete");

      how1M.classList.add("complete");
      how2M.classList.add("complete");
      how3M.classList.add("complete");
      how4M.classList.remove("complete");
      how5M.classList.add("complete");


    }
  }

  howto5() {
    var img1 = document.getElementById("slideImg1");
    var img2 = document.getElementById("slideImg2");
    var img3 = document.getElementById("slideImg3");
    var img4 = document.getElementById("slideImg4");
    var img5 = document.getElementById("slideImg5");

    var how1 = document.getElementById("how1");
    var how2 = document.getElementById("how2");
    var how3 = document.getElementById("how3");
    var how4 = document.getElementById("how4");
    var how5 = document.getElementById("how5");


    var how1M = document.getElementById("how1M");
    var how2M = document.getElementById("how2M");
    var how3M = document.getElementById("how3M");
    var how4M = document.getElementById("how4M");
    var how5M = document.getElementById("how5M");


    if (img5.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "block";

      how1.classList.add("complete");
      how2.classList.add("complete");
      how3.classList.add("complete");
      how4.classList.add("complete");
      how5.classList.remove("complete");

      how1M.classList.add("complete");
      how2M.classList.add("complete");
      how3M.classList.add("complete");
      how4M.classList.add("complete");
      how5M.classList.remove("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "block";

      how1.classList.add("complete");
      how2.classList.add("complete");
      how3.classList.add("complete");
      how4.classList.add("complete");
      how5.classList.remove("complete");

      how1M.classList.add("complete");
      how2M.classList.add("complete");
      how3M.classList.add("complete");
      how4M.classList.add("complete");
      how5M.classList.remove("complete");

    }
  }

  // Tablets 
  howto1T() {
    var img1 = document.getElementById("slideImg1T");
    var img2 = document.getElementById("slideImg2T");
    var img3 = document.getElementById("slideImg3T");
    var img4 = document.getElementById("slideImg4T");
    var img5 = document.getElementById("slideImg5T");


    var how1T = document.getElementById("how1T");
    var how2T = document.getElementById("how2T");
    var how3T = document.getElementById("how3T");
    var how4T = document.getElementById("how4T");
    var how5T = document.getElementById("how5T");

    if (img1.style.display === "none") {
      img1.style.display = "block";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "none";




      how1T.classList.remove("complete");
      how2T.classList.add("complete");
      how3T.classList.add("complete");
      how4T.classList.add("complete");
      how5T.classList.add("complete");


    } else {
      img1.style.display = "block";



      how1T.classList.remove("complete");
      how2T.classList.add("complete");
      how3T.classList.add("complete");
      how4T.classList.add("complete");
      how5T.classList.add("complete");

    }
  }

  howto2T() {
    var img1 = document.getElementById("slideImg1T");
    var img2 = document.getElementById("slideImg2T");
    var img3 = document.getElementById("slideImg3T");
    var img4 = document.getElementById("slideImg4T");
    var img5 = document.getElementById("slideImg5T");



    var how1T = document.getElementById("how1T");
    var how2T = document.getElementById("how2T");
    var how3T = document.getElementById("how3T");
    var how4T = document.getElementById("how4T");
    var how5T = document.getElementById("how5T");



    if (img2.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "block";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "none";


      how1T.classList.add("complete");
      how2T.classList.remove("complete");
      how3T.classList.add("complete");
      how4T.classList.add("complete");
      how5T.classList.add("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "block";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "none";


      how1T.classList.add("complete");
      how2T.classList.remove("complete");
      how3T.classList.add("complete");
      how4T.classList.add("complete");
      how5T.classList.add("complete");
    }
  }


  howto3T() {
    var img1 = document.getElementById("slideImg1T");
    var img2 = document.getElementById("slideImg2T");
    var img3 = document.getElementById("slideImg3T");
    var img4 = document.getElementById("slideImg4T");
    var img5 = document.getElementById("slideImg5T");


    var how1T = document.getElementById("how1T");
    var how2T = document.getElementById("how2T");
    var how3T = document.getElementById("how3T");
    var how4T = document.getElementById("how4T");
    var how5T = document.getElementById("how5T");

    if (img3.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "block";
      img4.style.display = "none";
      img5.style.display = "none";


      how1T.classList.add("complete");
      how2T.classList.add("complete");
      how3T.classList.remove("complete");
      how4T.classList.add("complete");
      how5T.classList.add("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "block";
      img4.style.display = "none";
      img5.style.display = "none";



      how1T.classList.add("complete");
      how2T.classList.add("complete");
      how3T.classList.remove("complete");
      how4T.classList.add("complete");
      how5T.classList.add("complete");
    }
  }


  howto4T() {
    var img1 = document.getElementById("slideImg1T");
    var img2 = document.getElementById("slideImg2T");
    var img3 = document.getElementById("slideImg3T");
    var img4 = document.getElementById("slideImg4T");
    var img5 = document.getElementById("slideImg5T");



    var how1T = document.getElementById("how1T");
    var how2T = document.getElementById("how2T");
    var how3T = document.getElementById("how3T");
    var how4T = document.getElementById("how4T");
    var how5T = document.getElementById("how5T");

    if (img4.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "block";
      img5.style.display = "none";



      how1T.classList.add("complete");
      how2T.classList.add("complete");
      how3T.classList.add("complete");
      how4T.classList.remove("complete");
      how5T.classList.add("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "block";
      img5.style.display = "none";


      how1T.classList.add("complete");
      how2T.classList.add("complete");
      how3T.classList.add("complete");
      how4T.classList.remove("complete");
      how5T.classList.add("complete");

    }
  }

  howto5T() {
    var img1 = document.getElementById("slideImg1T");
    var img2 = document.getElementById("slideImg2T");
    var img3 = document.getElementById("slideImg3T");
    var img4 = document.getElementById("slideImg4T");
    var img5 = document.getElementById("slideImg5T");


    var how1T = document.getElementById("how1T");
    var how2T = document.getElementById("how2T");
    var how3T = document.getElementById("how3T");
    var how4T = document.getElementById("how4T");
    var how5T = document.getElementById("how5T");

    if (img5.style.display === "none") {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "block";


      how1T.classList.add("complete");
      how2T.classList.add("complete");
      how3T.classList.add("complete");
      how4T.classList.add("complete");
      how5T.classList.remove("complete");

    } else {
      img1.style.display = "none";
      img2.style.display = "none";
      img3.style.display = "none";
      img4.style.display = "none";
      img5.style.display = "block";



      how1T.classList.add("complete");
      how2T.classList.add("complete");
      how3T.classList.add("complete");
      how4T.classList.add("complete");
      how5T.classList.remove("complete");

    }
  }


}
