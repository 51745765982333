import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';
import { option } from '../../shared/options-header/option';
@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(
    private http: HttpClient
  ) { }

  pathInsertContract: string = API_URL.insertContract;
  pathGetAllContractsRequest: string = API_URL.getAllContractsRequest;
  pathGetAllContractsRequestToMe: string = API_URL.getAllContractsRequestToMe;
  pathGetBlockCert: string = API_URL.pathGetBlockCert;
  pathCheckRecipientConfirmationEmail: string = API_URL.checkRecipientConfirmationEmail;
  pathCheckRecipientConfirmationPhone: string = API_URL.checkRecipientConfirmationPhone;
  pathGetUserContractNotMember: string = API_URL.getUserContractNotMember;
  pathCheckRecipientConfirmationPassword: string = API_URL.checkRecipientConfirmationPassword;
  pathGetRecipientTemplate: string = API_URL.getRecipientTemplate;
  pathGetContractDetails: string = API_URL.getContractDetails;
  pathGetContractDataDashboard: string = API_URL.getContractDataDashboard;
  pathOpenContractRecipient: string = API_URL.openContractRecipient;
  pathUpdateCancleContract: string = API_URL.updateCancleContract;
  pathResendContract: string = API_URL.resendContract;
  pathCheckOtpCode: string = API_URL.checkOtpCode;
  pathGetPriorityContractSignatures: string = API_URL.getPriorityContractSignatures;



  insertContract(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathInsertContract, param);
  }

  public getAllContractsRequest(data: any) {
    return this.http.post(this.pathGetAllContractsRequest, data);
  }

  public getAllContractsRequestToMe(data: any) {
    return this.http.post(this.pathGetAllContractsRequestToMe, data);
  }

  public getAllBlockCert(data: any) {
    return this.http.post(this.pathGetBlockCert, data);
  }
  public openContractRecipient(shortUrl: string) {
    const param = {
      data: shortUrl
    }
    return this.http.post(this.pathOpenContractRecipient, param);
  }

  public checkRecipientConfirmationEmail(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathCheckRecipientConfirmationEmail, param, option());
  }

  public checkRecipientConfirmationPhone(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathCheckRecipientConfirmationPhone, param);
  }

  public checkRecipientConfirmationPassword(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathCheckRecipientConfirmationPassword, param);
  }

  public getUserContractNotMember(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathGetUserContractNotMember, param);
  }

  public getRecipientTemplate(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathGetRecipientTemplate, param);
  }

  public getContractDetails(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathGetContractDetails, param);
  }

  public getContractDataDashboard() {
    const param = {
      data: ""
    }
    return this.http.post(this.pathGetContractDataDashboard, param);
  }

  updateCancleContract(data: any) {
    let param = {
      data: data
    }

    return this.http.post(this.pathUpdateCancleContract, param);
  }

  resendContract(contId: string, contorId: string) {
    let params = {
      data: {
        contId: contId,
        contorId: contorId
      }
    }

    return this.http.post(this.pathResendContract, params);
  }

  checkOtpCode(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathCheckOtpCode, params);
  }

  getPriorityContractSignatures(data: { contorId: string, contId: string }) {
    let params = {
      data: data
    }

    return this.http.post(this.pathGetPriorityContractSignatures, params);
  }
}
