import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-dashboard',
  templateUrl: './dashboard-dashboard.component.html',
  styleUrls: ['./dashboard-dashboard.component.scss']
})
export class DashboardDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
