import { Component, OnInit } from '@angular/core';
import { CertificationService } from '../certification/certification.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-electronic-contract-authentication-completed',
  templateUrl: './electronic-contract-authentication-completed.component.html',
  styleUrls: ['./electronic-contract-authentication-completed.component.scss']
})
export class ElectronicContractAuthenticationCompletedComponent implements OnInit {

  src: any = null;
  filename:string = ''
  constructor(
      private router: Router,
      private certificationService: CertificationService
  ) { }


  ngOnInit(): void {
    // if (this.certificationService.getImagePDF() == null) {
    //   this.router.navigate(['certification/upload'])
    // }
    this.src = this.certificationService.getImagePDF();
    this.filename = this.certificationService.getNameFile();
  }

  goTo(){
    this.router.navigate(['contract-management']);
  }
}
