import { OnChanges, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DataPassService } from '../../service/data-pass/data-pass.service';
import { loadTossPayments } from '@tosspayments/sdk';

@Component({
  selector: 'app-paying',
  templateUrl: './paying.component.html',
  styleUrls: ['./paying.component.scss']
})
export class PayingComponent implements OnInit {
  data;
  clientKey;
  tossPayments;
  constructor(
    private dataPass: DataPassService
  ) { 
  }

  ngOnInit(): void {
    this.clientKey = 'test_ck_7XZYkKL4MrjLgePzj4R30zJwlEWR';
    loadTossPayments(this.clientKey).then(tossPayments => {
      this.tossPayments = tossPayments;
      this.dataPass.source.subscribe(res => {
        if (res) {
          setTimeout(() => {
            this.tossPayments.requestPayment(res.paymentType, res.params);
          }, 1000);
        }
      })
    });
  }
}
