<section class="w-100" style="background-color: #e2e6eb;min-height: 750px;">
  <div class="largerUi">
    <section class="container-lg ml-lg-0 mr-lg-auto p-0 d-none d-lg-block">


      <h3 style="margin-bottom:41px;" class="tit-tem-man-001 ">결제 관리
      </h3>

      <div class="row">
        <div class="col-12 d-flex flex-row">
          <h5 class=" align-self-center useServices ">이용 서비스
          </h5>
          <button style="padding-top: 3px; padding-bottom: 3px;" class="btn btn-pinkish rounded-pill"
            (click)="pay()">결제하기</button>
        </div>
      </div>

      <div class="p-0 col-12">
        <h5 class="serviceInclude ">서비스 내용이 들어갑니다.


        </h5>
      </div>

      <div class="p-0 mt-3 col-12">
        <h4 class="formHeading">결제 내역 및 환불/취소
        </h4>
      </div>

      <app-payment-history (clickItem)="clickItem($event)"></app-payment-history>
      <app-request-refund (selectInuireItem)="selectInuire($event)" [selectItem]="item"></app-request-refund>
      <app-refund-details [itemInquie]="inuire"></app-refund-details>




    </section>


  </div>
  <!-- end of Large -->

  <!-- start of small -->
  <div class="smallerUi">
    <section class="container-fluid p-0 ">


      <h3 style="padding-bottom:25px;" class="d-none tit-tem-man-001 ">결제 관리
      </h3>

      <div class="row">
        <div class="col-12 d-flex flex-row">
          <h5 class="useServices align-self-center  ">이용 서비스
          </h5>

        </div>
      </div>

      <button class="btn btn-pinkish rounded-pill px-4 " (click)="pay()">결제하기</button>

      <div class="p-0 col-12">
        <h5 class="serviceInclude">서비스 내용이 들어갑니다.


        </h5>
      </div>

      <div class="p-0 col-12">
        <h4 class="formHeading">결제 내역 및 환불/취소
        </h4>
      </div>

      <form class="calendarFormSm" [formGroup]=fromSearchPaymentOne>
        <label>처리상태</label>
        <div class="form-group statusdiv">

          <select formControlName='processingStatus' class="form-control" id="status">
            <option value="0">전체</option>
          </select>
        </div>

        <label>결제일시</label>
        <div class="form-group caldiv ">

          <div class="input-group">

            <input formControlName='dateStart' type="date" name="dateStart" class=" datespacing form-control">
            <input formControlName='timeStart' type="time" name="timeStart" class=" form-control">

            <div class="input-group-prepend">
              <span class="spanC input-group-text px-0 bg-transparent border-0"> ~ </span>
            </div>
            <input formControlName='dateEnd' type="date" name="dateEnd" class=" datespacing form-control">
            <input formControlName='timeEnd' type="time" name="timeEnd" class="form-control">


          </div>
        </div>

        <label>승인번호</label>
        <div class="form-group numberdiv">

          <input formControlName='cardNumber' type="text" class="form-control" placeholder="승인번호 입력">
        </div>

        <div class="mb-3 d-flex justify-content-center">
          <button class="btn btn-middle-short btn-black my-0 mx-0">검색</button>

        </div>

      </form>


      <app-payment-history (clickItem)="clickItem($event)"></app-payment-history>
      <app-request-refund (selectInuireItem)="selectInuire($event)" [selectItem]="item"></app-request-refund>
      <app-refund-details [itemInquie]="inuire"></app-refund-details>







    </section>


  </div>
  <button style="padding-top: 3px; padding-bottom: 3px; visibility: hidden;" class="btn btn-pinkish rounded-pill"
    (click)="testForDeductPoint()">Test Deduct Point</button>
  <button style="padding-top: 3px; padding-bottom: 3px; visibility: hidden;" class="btn btn-pinkish rounded-pill"
    (click)="testForCancelPayment()">Test Cancel Payment</button>
</section>

<app-payment-apply></app-payment-apply>
<app-loading></app-loading>