import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PaymentService } from '../../service/payment/payment.service';
import { LoadingService } from "../../shared/loading/loading.service";
@Component({
  selector: 'app-request-refund',
  templateUrl: './request-refund.component.html',
  styleUrls: ['./request-refund.component.scss']
})
export class RequestRefundComponent implements OnInit {
  td: any[] = [];
  constructor(
    private paymentService: PaymentService,
    private LoadingService: LoadingService,
  ) { }

  paramGetInquiry = {
    data: {
      qaaAid: '',
      userNoId: ''
    },
    pageNo: 0,
    pageSize: 5
  }
  pagination = {
    maxPage: 1,
    currentPage: 1
  }
  itemTotal: number = 0;

  item: {
    amountPay: string,
    approvalDate: string,
    approvalNumber: string,
    paymentDate: string,
    paymentDetails: string,
    paymentType: string,
    processingStatus: string,
    purchasePoint: string
  } = {
      amountPay: '',
      approvalDate: '',
      approvalNumber: '',
      paymentDate: '',
      paymentDetails: '',
      paymentType: '',
      processingStatus: '',
      purchasePoint: ''
    }

  @Output() selectInuireItem = new EventEmitter<any>();

  @Input() selectItem: any = {
    amountPay: '',
    approvalDate: '',
    approvalNumber: '',
    paymentDate: '',
    paymentDetails: '',
    paymentType: '',
    processingStatus: '',
    purchasePoint: ''
  };

  ngDoCheck(): void {
    if (this.item.approvalNumber != this.selectItem.approvalNumber) {
      this.item = this.selectItem;
      this.paramGetInquiry.data.qaaAid = this.item.approvalNumber;
      this.getInquirePaymentList();
    }
  }

  ngOnInit(): void {
    this.item = this.selectItem;
  }


  getInquirePaymentList() {
    this.td = [];
    this.LoadingService.openLoading();
    this.paymentService.getInquirePaymentList(this.paramGetInquiry).subscribe((rs: any) => {
      if (rs.status) {
        console.log(rs.data);
        this.td = rs.data
        this.itemTotal = rs.total;
        this.calMaxPage(rs.total, this.paramGetInquiry.pageSize);
      }
      this.LoadingService.closeLoading();
    });
  }

  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.paramGetInquiry.pageNo = this.pagination.currentPage - 1;
      }
      this.paymentService.getInquirePaymentList(this.paramGetInquiry).subscribe((rs: any) => {
        if (rs.status) {
          this.td = rs.data;
        }
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.paramGetInquiry.pageNo = $event - 1;
    this.getInquirePaymentList();
  }

  inData() {
    this.td.length
    return [
      {
        registrationDate: '2020/04/23 13:14',
        question: '일번 조항에 나와 있는 내용에 문제가 있습니다…',
        state: '답변완료'
      },
      {
        registrationDate: '2020/04/23 13:14',
        question: '내용 확인 후 처리될 수 있도록…',
        state: '답변완료'
      },
      {
        registrationDate: '2020/04/23 13:14',
        question: '결제 취소가 가능할까요?',
        state: '문의'
      }
    ]
  }

  selectInuire(t: any) {
    this.selectInuireItem.emit(t);
  }

}
