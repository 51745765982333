  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="mr-2">
        <button class="btn-pag-pre-001 d-flex align-items-center justify-content-center" href="#" aria-label="Previous" (click)="changerPagePrevious(pagination.firstPage)" [disabled]="(pagination.firstPage == pagination.currentPage) ? true : false">
          <span class="img-ico-pag-dd-001 ico-ico-pag-lef-dd-001" aria-hidden="true"></span>
        </button>
      </li>
      <li class="mr-1"  style="cursor: pointer;" >
        <button class="btn-pag-pre-001" aria-label="Previous" (click)="changerPagePrevious(pagination.previousPage)" [disabled]="(pagination.firstPage == pagination.currentPage) ? true : false">
          <span class="img-ico-pag-001 ico-ico-pag-lef-001" aria-hidden="true"></span>
        </button>
      </li>
      <ng-container *ngFor="let number of pageNumber">
        <li [ngClass]="(number == pagination.currentPage)? 'btn-pag-num-act-001' : ''" ><button class="btn-pag-num-001" (click)="changerPage(number)" style="cursor: pointer;line-height: 26px;" >
          <span>{{number}}</span>
        </button></li>
      </ng-container>
      <li class="ml-1" >
        <button class="btn-pag-nex-001" aria-label="Next" (click)="changerPageNext(pagination.nextPage)" [disabled]="(pagination.lastPage == pagination.currentPage) ? true : false" style="cursor: pointer;" >
          <span class="img-ico-pag-001 ico-ico-pag-rig-001" aria-hidden="true"></span>
        </button>
      </li>
      <li class="ml-2">
        <button class="btn-pag-nex-001" href="#" aria-label="Next"  (click)="changerPageNext(pagination.lastPage)" [disabled]="(pagination.lastPage == pagination.currentPage) ? true : false">
          <span class="img-ico-pag-dd-001 ico-ico-pag-rig-dd-001" aria-hidden="true"></span>
        </button>
      </li>
    </ul>
  </nav>