import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CertificationService {

  tools = {
    show: {
      toolsEdit: true,
      pageView: true,
      pageSetting: false,
      buttons: {
        nextText: '다음',
        backText: '이전',
        next: true,
        back: false,
        close: true,
        printer: false,
        download: false
      },
    }
  }

  createMark: boolean = false;
  editText: boolean = true;

  colors: {
    index: number,
    color: string,
    status: boolean
  }[] = [
    {
      index: 0,
      color: 'rgb(255, 0, 0)',
      status: true
    }, {
      index: 1,
      color: 'rgb(255, 128, 0)',
      status: true
    }, {
      index: 2,
      color: 'rgb(255, 255, 0)',
      status: true
    }, {
      index: 3,
      color: 'rgb(191, 255, 0)',
      status: true
    }, {
      index: 4,
      color: 'rgb(64, 255, 0)',
      status: true
    }, {
      index: 5,
      color: 'rgb(0, 255, 64)',
      status: true
    }, {
      index: 6,
      color: 'rgb(0, 255, 128)',
      status: true
    }, {
      index: 7,
      color: 'rgb(0, 255, 191)',
      status: true
    }, {
      index: 8,
      color: 'rgb(0, 255, 255)',
      status: true
    }, {
      index: 9,
      color: 'rgb(0, 128, 255)',
      status: true
    }, {
      index: 10,
      color: 'rgb(0, 0, 255)',
      status: true
    }, {
      index: 11,
      color: 'rgb(128, 0, 255)',
      status: true
    }, {
      index: 12,
      color: 'rgb(255, 0, 255)',
      status: true
    }, {
      index: 13,
      color: 'rgb(255, 0, 128)',
      status: true
    }, {
      index: 14,
      color: 'rgb(255, 0, 0)',
      status: true
    }, {
      index: 15,
      color: 'rgb(255, 128, 0)',
      status: true
    }, {
      index: 16,
      color: '(255,222,173)',
      status: true
    }, {
      index: 17,
      color: 'rgb(255,218,185)',
      status: true
    }, {
      index: 18,
      color: 'rgb(119,136,153)',
      status: true
    }, {
      index: 19,
      color: 'rgb(176,196,222)',
      status: true
    }, {
      index: 20,
      color: 'rgb(32,178,170)',
      status: true
    }, {
      index: 21,
      color: 'rgb(128,0,128)',
      status: true
    }, {
      index: 22,
      color: 'rgb(219,112,147)',
      status: true
    }, {
      index: 23,
      color: 'rgb(255,105,180)',
      status: true
    }, {
      index: 24,
      color: 'rgb(240,255,240)',
      status: true
    }, {
      index: 25,
      color: 'rgb(100,149,237)',
      status: true
    }, {
      index: 26,
      color: 'rgb(123,104,238)',
      status: true
    }, {
      index: 27,
      color: 'rgb(255, 150, 128)',
      status: true
    }
  ];

  colorGroups: {
    color: string
  }[] = [];

  state: number = 1;

  filePdf: any = null;
  imagePdf: any = null;
  nameFile: any = null;

  constructor() {
  }

  setEditText(status: boolean) {
    this.editText = status;
  }

  getEditText(): boolean {
    return this.editText;
  }

  clearGroupColors() {
    this.colorGroups = [];
  }

  createGroupColor(): string {
    let r, g, b = 0;
    let color = '';
    let colors
    do {
      r = Math.floor(Math.random() * (255 - 0)) + 0;
      g = Math.floor(Math.random() * (255 - 0)) + 0;
      b = Math.floor(Math.random() * (255 - 0)) + 0;

      color = 'rgb(' + r + ',' + g + ',' + b + ')';

      colors = this.colorGroups.filter(colors => {
        return colors.color == color;
      });
    }
    while (colors.length > 0);
    this.pushGroupColors(color);
    return color;
  }

  pushGroupColors(color: string): void {
    this.colorGroups.push({ color: color });
  }


  setCreateMark(cre: boolean): void {
    this.createMark = cre;
  }

  getCreateMark(): boolean {
    return this.createMark;
  }
  // setDefaultColor() {
  //   for (let i = 0; i < this.colors.length; i++) {
  //     this.colors[i].status = true;
  //   }
  // }

  // setStateColor(index: number, state: boolean) {
  //   this.colors[index].status = state;
  // }

  // setStateColorByColor(color: string) {
  //   let cl = this.colors.filter((colors) => {
  //     return colors.color == color;
  //   });
  //   if (cl.length > 0) {
  //     this.setStateColor(cl[0].index, false);
  //   }
  // }

  // getColor(index: number) {
  //   return this.colors[index];
  // }

  // getColors() {
  //   return this.colors;
  // }

  setState(state: number) {
    this.state = state;
  }

  getState(): number {
    return this.state;
  }

  setShowToolsEditor(show: boolean) {
    this.tools.show.toolsEdit = show;
  }

  getShowToolsEditor(): boolean {
    return this.tools.show.toolsEdit;
  }

  setButton(attr: any, show: boolean) {
    this.tools.show.buttons[attr] = show;
  }

  getButton(attr: any): boolean {
    return this.tools.show.buttons[attr];
  }

  getTools() {
    return this.tools;
  }

  setShowPageView(show: boolean) {
    this.tools.show.pageView = show;
  }

  getShowPageView(): boolean {
    return this.tools.show.pageView;
  }

  setShowPageSetting(show: boolean) {
    this.tools.show.pageSetting = show;
  }

  getShowPageSetting(): boolean {
    return this.tools.show.pageSetting;
  }

  setFilePDF(file: any) {
    this.filePdf = file;
  }

  getFilePDF() {
    return this.filePdf
  }

  setImagePDF(file: any) {
    this.imagePdf = file;
  }

  getImagePDF() {
    return this.imagePdf;
  }

  setNameFile(Filename: string) {
    this.nameFile = Filename;
  }

  getNameFile() {
    return this.nameFile;
  }
}
