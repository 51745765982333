<div class="bg-modal" *ngIf="show">




  <div class="modal popUpType1 fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
    <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">
        <div class="modal-header rounded-0 border-bottom-0 pb-0 px-4 pt-4" style="background: #ffffff;color: #000000;">

          <div class="border-bottom border-dark d-flex w-100 pb-3 ">
            <h5 class="modal-title barumBold" id="exampleModalLabel ">저장</h5>
            <a type="button" style="color: #000000;" class="close" (click)="closeModal()" data-dismiss="modal"
              aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>

        </div>
        <div class="modal-body rounded-0 pb-0" style="background: #ffffff;color: #000000;">
          <form [formGroup]=formNameTemplate style="width: 100%;">
            <p class="text-left font-weight-light mb-0">계약서 이름
            </p>
            <input class="form-control" formControlName='nameTemplate' style="margin-top: 16px;"
              placeholder="이름 부동산 임대 자 계약서">
          </form>
        </div>
        <div class="modal-footer rounded-0 d-flex justify-content-center border-top-0"
          style="background: #ffffff;color: #000000;">
          <button type="button" style="width:80px; " class="btn  btn-white"
            (click)="onClickBtn('btnCancel')">취소</button>
          <button type="button" style="width:80px; " class="btn ml-2 btn-black"
            (click)="onClickBtn('btnConfirm')">확인</button>
        </div>
      </div>
    </div>
  </div>


</div>
