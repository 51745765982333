<div class="row-hl btnnav justify-content-center d-flex">
    <div class="serviceBalance">
        <h2 class="text-center centerSubject w-100">
            자료실
        </h2>

        <ng-container *ngIf="display.main">
            <section>

                <div class="category-wrap m-auto">
                    <button class="category-item active" id="category{{categoryList.length+1}}"
                            (click)="searchCategory('', categoryList.length+1)">
                        전체
                    </button>
                    <button class="category-item" *ngFor="let category of categoryList; let i = index"
                            id="category{{i}}" (click)="searchCategory(category.code, i)">
                        {{category.codeNm}}
                    </button>
                </div>

                <form class="form-inline tableForm" [formGroup]=formSearch>
                    <div class=" mx-auto serviceInputArea d-flex align-items-center">
                        <div class="input-group  align-items-center">
                            <select formControlName=serachType
                                    class="form-control form-control-sm serviceDrop inp-sel-typ-001">
                                <option *ngFor="let type of serachType" value={{type.code}}>{{type.codeNm}}</option>
                            </select>
                            <input formControlName=searchText class="form-control serviceSearch border-right-0"
                                   (keyup.enter)="searchNoticeFront()" type="text" placeholder="입력해 주세요">
                            <div class="input-group-append bg-white serviceSearchIcon">
                                <button class="mb-0 btn searchService border-left-0 p-0" (click)="searchNoticeFront()"
                                        type="button">
                                    <img src="../../../assets/img/frontpage/searchicons.png" width="30px">
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="notict-list-btngr mx-auto">
                    <div class="row">
                        <div class="col-xl-4 mt-auto">
                            <ul class="sort-order-list pb-3">
                                <li class="active" id="sortOrder" (click)="onClickSort('order')"><span>등록순</span></li>
                                <li id="downloadOrder" (click)="onClickSort('download')"><span>다운로드순</span></li>
                            </ul>
                        </div>
                        <div class="col-xl-8">
                            <div class="d-flex list-top-btngr pb-3">
                                <span class="txt-table-desc mt-auto mr-lg-2">계약서를 올리는 분들께 1Point를 지급해 드립니다. </span>
                                <input type="button" class="btn btn-upload-lg" value="계약서올리기"
                                       (click)="onClickBtn('upload')">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="notict-list-box  mx-auto">
                <!-- Smaller Table -->
                <div class="smallerUi">
                    <div class="d-block ">
                        <ul class="mobTable  list-group " *ngFor="let communityList of communityLists; let i = index"
                            (click)="onClickItem(communityList)">
                            <li class="list-group-item bg-transparent border-0">
                                <div class="item-form sc-jWxkHr mobcellA">
                                    <div class="row m-0">
                                        <div class="field-cell check">
                                            <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox"
                                                                                         id="form_5f48cab259087664b3096102"
                                                                                         readonly=""><label
                                                    for="form_5f48cab259087664b3096102">check0</label></div>
                                        </div>
                                        <div class="field-cell state form color-blue"><span>
                                            {{communityList.no > 9 ? communityList.no : '0' + communityList.no }}</span>
                                        </div>
                                        <div class="field-cell form-name"><span class="truncate tabOverflow">
                                            <a>{{communityList.postNm}}</a></span>
                                        </div>
                                        <div class="field-cell date last-date"><span class="truncate">
                                            {{communityList.postTypeNm}}</span></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="d-flex justify-content-center" style="margin: 60px 0px 46px 0px">
                            <app-pagination [currentPage]='1' [maxPage]='1' (seclectPage)="setCurrentPage($event)">
                            </app-pagination>
                        </div>
                    </div>
                </div>

                <div class="largerUi">
                    <div class="d-none d-lg-block">
                        <div class="d-none  row-hl align-items-center">
                            <div class=" ml-auto item-hl">총 40건</div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-middle"> <!-- largeTable 삭제  table-middle 추가 -->
                                    <thead class="th-bg-001">
                                    <tr>
                                        <th class="text-center tex-tit-tab-001 serviceCenterTableA">
                                            NO
                                        </th>
                                        <th class="text-center tex-tit-tab-001">
                                            분류
                                        </th>
                                        <th class="text-center tex-tit-tab-001">
                                            계약서 명
                                        </th>
                                        <th class="text-center tex-tit-tab-001">
                                            다운로드
                                        </th>
                                        <th class="text-center tex-tit-tab-001">
                                            작성자
                                        </th>
                                        <th class="text-center tex-tit-tab-001">
                                            다운로드 수
                                        </th>
                                        <th class="text-center tex-tit-tab-001">
                                            미리보기
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody class="">
                                    <tr class="text-center lis-not-001"
                                        *ngFor="let communityList of communityLists; let i = index">
                                        <td class="noticeNum" scope="row">
                                            {{communityList.no > 9 ? communityList.no : '0' + communityList.no }}
                                        </td>
                                        <td>{{communityList.postTypeNm}}</td>
                                        <td class="clearfix">
                                            <div class="tooltipA"
                                                 (mouseenter)="mouseEnter(communityList.postId, communityList.postNm)"
                                                 (mouseleave)="mouseOut()">{{communityList.postNm}}
                                                <div *ngIf="mouseOver" class="tooltiptext d-flex">
                                                    <div class="tooltip-img">
                                                    <img [src]="communityList.thumbnail" alt="미리보기 이미지">
                                                    </div>
                                                    <div class="tooltip-desc">
                                                        <strong>{{communityList.postNm}}</strong>
                                                        <span>{{communityList.pageNum}} page</span>
                                                        <p>{{communityList.postContent}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <button class="btn btn-middle-long btn-white"
                                                    (click)="downLoadClick(communityList.postId, communityList.postNm)">
                                                <img src="../../../../assets/img//icon/plusShape.png">
                                                다운로드
                                            </button>
                                        </td>
                                        <td>{{communityList.writer}}</td>
                                        <td>{{communityList.postView}}</td>
                                        <td>
                                            <span title="계약서 뷰어" class="img-ico-lis-man-001 ico-man-lis-mon-001" (click)="onClickReview(communityList.postId, communityList.postNm)"></span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-center" style="margin: 60px 0px 46px 0px">
                                    <app-pagination [maxDisplayPage]="10" [currentPage]='pagination.currentPage'
                                                    [maxPage]='pagination.maxPage'
                                                    (seclectPage)="setCurrentPage($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end of org -->
            </div>
            <!-- Larger Table  -->
        </ng-container>
        <app-community-upload *ngIf="display.upload"></app-community-upload>
    </div>
</div>
