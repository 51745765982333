export default class DateShared {

    public date: Date;
    public month: number;
    public year: number;



    constructor() {
        this.date = new Date();
        this.year = this.date.getFullYear();
    }

    public getDataNextFiveYearBeforeFive() {
        let data = [];

        for (let i = 5; i > 0; i--) {
            data.push({ year: this.year - i });
        }
        data.push({ year: this.year });
        for (let i = 1; i < 6; i++) {
            data.push({ year: this.year + i });
        }

        return data;
    }

    public getDataMonthsForYear() {
        let data = [];

        for (let i = 1; i <= 12; i++) {
            data.push({ month: i < 10 ? '0' + i : i, value: i < 10 ? '0' + i : i });
        }

        return data;
    }

    public daysInMonth(month, year) {
        let days = new Date(year, month, 0).getDate();
        let data = [];

        for (let i = 1; i <= days; i++) {
            data.push({ day: i < 10 ? '0' + i : i, value: i < 10 ? '0' + i : i });
        }

        return data;
    }

    public getHoursFull() {
        let hours = [];

        for (let i = 0; i <= 23; i++) {
            hours.push({ hours: i < 10 ? '0' + i : i, value: i < 10 ? '0' + i : i });
        }

        return hours;
    }
    
    public getDays():{
        day: string,
        value: string
    }[]{
        let dataDays:{
            day: string,
            value: string  
        }[] = [
            {
                day: '월',
                value: '월'
            },{
                day: '화',
                value: '화'
            },{
                day: '수',
                value: '수'
            },{
                day: '목',
                value: '목'
            },{
                day: '금',
                value: '금'
            },{
                day: '토',
                value: '토'
            },{
                day: '일',
                value: '일'
            },{
                day: '주말',
                value: '주말'
            },{
                day: '평일',
                value: '평일'
            }
        ];

        return dataDays;
    }

    public getMinutesFull() {
        let minutes = [];

        for (let i = 0; i <= 59; i++) {
            minutes.push({ minutes: i < 10 ? '0' + i : i, value: i < 10 ? '0' + i : i });
        }

        return minutes;
    }

    public getSecondsFull() {
        let seconds = [];

        for (let i = 0; i <= 59; i++) {
            seconds.push({ seconds: i < 10 ? '0' + i : i, value: i < 10 ? '0' + i : i });
        }

        return seconds;
    }

    public dateToLocalKorean(datetime: any): string {
        console.log(datetime);
        const event = new Date(datetime);
        console.log(event);
        return event.toLocaleString('en-US', { timeZone: 'UTC', hour12: false });
    }

    public checkDataStartEnd(
        start: {
            ys: string,
            ms: string,
            ds: string
        }, end: {
            ye: string,
            me: string,
            de: string
        }): boolean {
        let dateStart = new Date(start.ys + '-' + start.ms + '-' + start.ds);
        let dateEnd = new Date(end.ye + '-' + end.me + '-' + end.de);

        return ((dateEnd > dateStart));
    }

    public checkTimeStartEnd(
        start: {
            hs: number,
            ms: number
        }, end: {
            he: number,
            me: number
        }): boolean {
        let timeStart = new Date();
        timeStart.setHours(start.hs);
        timeStart.setMinutes(start.ms);
        timeStart.setSeconds(0);

        let timeEnd = new Date();
        timeEnd.setHours(end.he);
        timeEnd.setMinutes(end.me);
        timeEnd.setSeconds(0);
        return (timeEnd > timeStart);
    }

    public booleanToInt(bool: boolean): number {
        return (bool ? 1 : 0);
    }
}