import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import TokenUtils from '../manage/shared/token/token-utils';
import { UserService } from '../manage/service/user/user.service';
import { LoginService } from '../frontpage/login/login.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private userService: UserService,
    private loginService: LoginService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accessToken = TokenUtils.getToken();

    if (accessToken && !this.jwtHelper.isTokenExpired(accessToken)) {
      this.getProfileUser(state);
      return true;
    }

    TokenUtils.removeToken();

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  getProfileUser(state: RouterStateSnapshot): void {
    if (this.loginService.getProfileLogin() === null) {
      this.userService.getUserProfile().subscribe((rs: any) => {
        if (rs.status) {
          this.loginService.setProfile(rs.data);
        } else {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
      }, (err) => {
        TokenUtils.removeToken();
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      });
    }
  }

  canActivateNav(): boolean {
    const accessToken = TokenUtils.getToken();

    if (accessToken && !this.jwtHelper.isTokenExpired(accessToken)) {
      this.getProfileUserNav();
      return true;
    }

    TokenUtils.removeToken();
    // not logged in so redirect to login page with the return url
    return false;
  }

  getProfileUserNav() {
    if (this.loginService.getProfileLogin() === null) {
      if (this.loginService.getProfileLogin() === null) {
        this.userService.getUserProfile().subscribe((rs: any) => {
          if (rs.status) {
            this.loginService.setProfile(rs.data);
            return true;
          } else {
            TokenUtils.removeToken();
            return false;
          }
        }, (err) => {
          TokenUtils.removeToken();
          this.router.navigate(['/']);
        });
      }
    }
  }
}
