<div style="position: sticky;" class="d-flex justify-content-between align-items-center py-2 borderlinebot ">

  <div class="d-flex justify-content-center px-2">
    <span class="">

    </span>
    <span class="">

    </span>
    <span class="">

    </span>
  </div>

  <div class="d-flex justify-content-center px-2">

  </div>

  <div class="d-flex justify-content-center px-2">
    <button (click)="goToMainPage()" class="btn btn-small-long btn-white">
      취소
    </button>
  </div>

</div>

<section class="container-fluid" style="background: #E9ECEE;">

  <div class="container-lg2 py-5">


    <div class="row">
      <div class="col-12">
        <h1 class="tit-pag-up">
          <span>제공된 서식으로 만들기</span>
          <img src="../../../../assets/img/icon/icon-arrow-right-cr.png" class="img-fluid mx-3">
          <span class="font-weight-700 barumBold">표준근로 계약서 </span>

        </h1>
      </div>
    </div>
    <hr>

    <form [formGroup]=formContractWord id="formContractWord">
      <div class="row">
        <div class="col-12">
          <hr style="margin:30px 0px 15px 0px; border: 2px solid #f82644; width: 25px; background: #f82644;">
          <h3 class="formHeading">
            계약서 정보 입력</h3>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="owner">사업주명</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.owner}}" formControlName='{owner}' maxlength="8"
              id="owner" name="owner" placeholder="사업주명" id="owner" aria-describedby="owner">
            <small *ngIf="small.owner"
              class="form-text text-muted text-validators {{formContractWord.get('{owner}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              문자만 입력해 주세요.
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="worker">근로자명</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.worker}}" formControlName='{worker}' maxlength="8"
              id="worker" name="worker" placeholder="근로자명" id="worker" aria-describedby="worker">
            <small *ngIf="small.worker"
              class="form-text text-muted text-validators {{formContractWord.get('{worker}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              문자만 입력해 주세요.
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="inlineselects row no-gutters">
        <div class="col-12"> <label>근로계약기간</label></div>
        <div class="">

          <div class="form-inline">
            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="ys" formControlName='{ys}' id="ys" class="form-control {{valid.ys}}">
                  <option value="N">선택</option>
                  <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">년</span>
                </div>
              </div>
            </div>


            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="ms" formControlName='{ms}' id="ms" class="form-control {{valid.ms}}">
                  <option value="N">선택</option>
                  <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">월</span>
                </div>
              </div>
            </div>

            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="ds" formControlName='{ds}' id="ds" class="form-control {{valid.ds}}">
                  <option value="N">선택</option>
                  <option *ngFor="let d of data.ds;let i = index" value={{d.value}}>{{d.day}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">일</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="">
          <div class="form-inline">
            <div class="form-group  ">
              <div class="input-group">
                <div class="d-none d-md-block input-group-prepend">
                  <span class="input-group-text">~</span>
                </div>
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{ye}' id="ye" class="form-control {{valid.ye}}">
                  <option value="N">선택</option>
                  <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">년</span>
                </div>
              </div>
            </div>

            <div class="form-group ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{me}' id="me" class="form-control {{valid.me}}">
                  <option value="N">선택</option>
                  <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">월</span>
                </div>
              </div>
            </div>

            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{de}' id="de" class="form-control {{valid.de}}">
                  <option value="N">선택</option>
                  <option *ngFor="let d of data.de;let i = index" value={{d.value}}>{{d.day}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">일</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="exampleInputEmail1">근무장소</label>
            <textarea class="form-control {{valid.wplace}}" rows="1" style="resize: none;" formControlName='{wplace}' maxlength="35"
              id="wplace" name="wplace" placeholder="근무장소" id="wplace" aria-describedby="wplace"></textarea>
            <small *ngIf="small.wplace"
              class="form-text text-muted text-validators {{formContractWord.get('{wplace}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="exampleInputEmail1">업무내용</label>
            <textarea class="form-control {{valid.contentOfWork}}" rows="1" style="resize: none;" maxlength="35"
              formControlName='{contentOfWork}' id="contentOfWork" name="contentOfWork" placeholder="업무내용"
              id="contentOfWork" aria-describedby="contentOfWork"></textarea>
            <small *ngIf="small.contentOfWork"
              class="form-text text-muted text-validators {{formContractWord.get('{contentOfWork}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>

      <!-- B2 -->

      <!-- -******- -->
      <div class="row">
        <div class="col-12">
          <label>소정근로시간</label>
          <div class="form-inline">
            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{ths}' id="ths" class="form-control {{valid.ths}}">
                <option value="N">시</option>
                <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
              </select>
              <label for="inputselect0" class="mx-1">시</label>
            </div>
            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{tms}' id="tms" class="form-control {{valid.tms}}">
                <option value="N">분</option>
                <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
              </select>
              <label for="inputselect0" class="mx-1">분</label>
            </div>
            <div class="form-group mx-sm-3 mb-2 mx-4">
              <label>~</label>
            </div>
            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{the}' id="the" class="form-control {{valid.the}}">
                <option value="N">시</option>
                <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
              </select>
              <label for="inputselect0" class="mx-1">시</label>
            </div>

            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{tme}' id="tme" class="form-control {{valid.tme}}">
                <option value="N">분</option>
                <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
              </select>
              <label for="inputselect0" class="mx-1">분</label>
            </div>
          </div>
        </div>

        <div class="col-12">
          <label>휴게시간 : </label>
          <div class="form-inline">
            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{bhs}' id="bhs" class="form-control {{valid.bhs}}">
                <option value="N">시</option>
                <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
              </select>
              <label for="inputselect0" class="mx-1">시</label>
            </div>

            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{bms}' id="bms" class="form-control {{valid.bms}}">
                <option value="N">분</option>
                <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
              </select>
              <label for="inputselect0" class="mx-1">분</label>
            </div>

            <div class="form-group mx-sm-3 mb-2 mx-4">
              <label>~</label>
            </div>

            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{bhe}' id="bhe" class="form-control {{valid.bhe}}">
                <option value="N">시</option>
                <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
              </select>
              <label for="inputselect0" class="mx-1">시</label>
            </div>

            <div class="form-group mb-2 mx-1">
              <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{bme}' id="bme" class="form-control {{valid.bme}}">

                <option value="N">분</option>
                <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
              </select>
              <label for="inputselect0" class="mx-1">분</label>
            </div>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row mt-1">
        <div class="col-2">
          <label> 근무일/휴일 매주 </label>
          <div class="form-group  ">
            <div class="input-group">
              <select for="wDays" formControlName='{wDays}' id="wDays" class="form-control {{valid.wDays}}">
                <option value="N">선택</option>
                <option *ngFor="let d of data.dpays.slice(0, 7);let i = index" value={{d.value}}>{{d.day}}</option>
              </select>
              <div class="input-group-append">
                <span class="input-group-text">일</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-1">
          <label>&nbsp;</label>
          <div class="form-group  ">
            <div class="input-group">
              <input type="hidden" class="form-control" name="">
              <div class="input-group-append border-0">
                <span class="input-group-text border-0">또는</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <label> 주휴일 매주 </label>
          <div class="form-group  ">
            <div class="input-group">
              <select for="weday" formControlName='{weday}' id="weday" class="form-control {{valid.weday}}">
                <option value="0">선택</option>
                <option *ngFor="let d of data.days;let i = index" value={{d.value}}>{{d.day}}</option>
              </select>
              <div class="input-group-append">
                <span class="input-group-text">요일</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row mt-1">
        <div class="col-6">
          <label for="exampleInputEmail1">임금</label>
          <div class="input-group {{valid.salay}}">
            <input style="border-radius: 4px !important;" type="text"
              pattern="^[0-9]{1,3}([,.][0-9]{1,3})+(.[0-9]{1,2})?$" class="form-control border-rounded "
              (change)='setFormaNumber()' formControlName='{salay}' name="salay" placeholder="0" id="salay"
              aria-describedby="salay">
            <div class="input-group-append salaryinput">
              <span class="bg-transparent text-dark border-0 input-group-text">원</span>
            </div>
          </div>
          <small *ngIf="small.salay"
            class="form-text text-muted text-validators {{formContractWord.get('{salay}').status}} font-16">
            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
            숫자만 입력해 주세요.
          </small>
        </div>
      </div>

      <!-- -******- -->
      <div class="row mt-2">
        <div class="col-6">
          <label for="exampleInputEmail1">상여금 </label>
          <div class="input-group {{valid.wBonus1}}">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <label class="con-che-001">
                  <input formControlName='{cb}' name='cb' type="checkbox">
                  <span class="che-mar-001" style="margin-top: -3px;"></span>
                </label>
              </div>
            </div>
            <input style="border-radius: 4px !important;" type="text"
              pattern="^[0-9]{1,3}([,.][0-9]{1,3})+(.[0-9]{1,2})?$" class="form-control border-rounded "
              (change)='setFormaNumberwBonus1()' formControlName='{wBonus1}' name="wBonus1" placeholder="0" id="wBonus1" aria-describedby="wBonus1">
            <div class="input-group-append salaryinput">
              <span class="bg-transparent text-dark border-0 input-group-text">원</span>
            </div>
          </div>
          <small *ngIf="small.wBonus1"
            class="form-text text-muted text-validators {{formContractWord.get('{wBonus1}').status}} font-16">
            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
            숫자만 입력해 주세요.
          </small>
        </div>

        <div class="col-6">
          <label for="exampleInputEmail1">상여금 없음 </label>
          <div class="input-group {{valid.nBonus1}}">
            <div class="input-group-prepend">
              <div class="input-group-text" style="width: 36px;height: 35px;">
                <label class="con-che-001">
                  <input formControlName='{cbx}' name='cbx' type="checkbox">
                  <span class="che-mar-001" style="margin-top: -3px;"></span>
                </label>
              </div>
            </div>

          </div>

        </div>

      </div>

      <!-- -******- -->
      <div class="row mt-2">
        <div class="col-6">
          <label for="exampleInputEmail1">기타급여 있음 </label>
          <div class="input-group {{valid.oSalary}}">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <label class="con-che-001">
                  <input formControlName='{cbo}' name='cbo' type="checkbox">
                  <span class="che-mar-001" style="margin-top: -3px;"></span>
                </label>
              </div>
            </div>
            <input style="border-radius: 4px !important;" type="text"
              pattern="^[0-9]{1,3}([,.][0-9]{1,3})+(.[0-9]{1,2})?$" class="form-control border-rounded "
              (change)='setFormaNumberoSalary()' formControlName='{oSalary}' name="oSalary" placeholder="0" id="oSalary" aria-describedby="oSalary">
            <div class="input-group-append salaryinput">
              <span class="bg-transparent text-dark border-0 input-group-text">원</span>
            </div>
          </div>
          <small *ngIf="small.oSalary"
            class="form-text text-muted text-validators {{formContractWord.get('{oSalary}').status}} font-16">
            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
            숫자만 입력해 주세요.
          </small>
        </div>

        <div class="col-6">
          <label for="exampleInputEmail1">기타급여 없음 </label>
          <div class="input-group {{valid.nSalary}}">
            <div class="input-group-prepend">
              <div class="input-group-text" style="width: 36px;height: 35px;">
                <label class="con-che-001">
                  <input formControlName='{cbn}' name='cbn' type="checkbox">
                  <span class="che-mar-001" style="margin-top: -3px;"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row mt-3">
        <div class="col-6">
          <label> 임금지급일 </label>
          <div class="form-group  ">
            <div class="input-group">
              <select pattern="^-?([0-9]\d*)?$" for="pDay" formControlName='{pDay}' id="pDay" class="form-control {{valid.pDay}}">
                <option value="N">일</option>
                <option *ngFor="let d of data.dpays;let i = index" value={{d.value}}>{{d.day}}</option>
              </select>
              <div class="input-group-append">
                <span class="input-group-text">일</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label for="exampleInputEmail1">지급방법</label>
          <div class="form-inline">
            <div class="form-group  ">
              <div class="input-group">
                <div class="form-group form-check p-0 m-0 contractCheckbox" style="line-height: 1; width:10px;">
                  <label class="con-che-001"><input formControlName='{cbdi}' type="checkbox"><span class="che-mar-001"
                      style="margin-top: -8px;"></span></label></div>
                <div class="input-group-append">
                  <span class="input-group-text border-0">직접 지급</span>
                </div>
              </div>
              <small *ngIf="small.cbdi"
                class="form-text text-muted text-validators {{formContractWord.get('{cbdi}').status}} font-16">
                <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                  alt="alert icon">
                필수 입력값을 정확하게 입력해 주세요
              </small>
            </div>
            <div class="form-group  ">
              <div class="input-group">
                <div class="form-group form-check p-0 m-0 contractCheckbox" style="line-height: 1; width:10px;">
                  <label class="con-che-001"><input formControlName='{cbdp}' type="checkbox"><span class="che-mar-001"
                      style="margin-top: -8px;"></span></label></div>
                <div class="input-group-append">
                  <span class="input-group-text border-0">통장입금</span>
                </div>
              </div>
              <small *ngIf="small.cbdi"
                class="form-text text-muted text-validators {{formContractWord.get('{cbdp}').status}} font-16">
                <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                  alt="alert icon">
                필수 입력값을 정확하게 입력해 주세요
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- -******- -->
      <div class="inlineselects row no-gutters">
        <div class="col-12"> <label>기타 (계약 날짜)</label></div>
        <div class="">

          <div class="form-inline">
            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="sYY" formControlName='{sYY}' id="sYY" class="form-control {{valid.sYY}}">
                  <option value="N">선택</option>
                  <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">년</span>
                </div>
              </div>
            </div>


            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="sMM" formControlName='{sMM}' id="sMM" class="form-control {{valid.sMM}}">
                  <option value="N">선택</option>
                  <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">월</span>
                </div>
              </div>
            </div>

            <div class="form-group  ">
              <div class="input-group">
                <select pattern="^-?([0-9]\d*)?$" for="sDD" formControlName='{sDD}' id="sDD" class="form-control {{valid.sDD}}">
                  <option value="N">선택</option>
                  <option *ngFor="let d of data.sDD;let i = index" value={{d.value}}>{{d.day}}</option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text">일</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="bName">사업체명</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.bName}}" formControlName='{bName}' maxlength="14"
              id="bName" name="bName" placeholder="사업체명" id="bName" aria-describedby="bName">
            <small *ngIf="small.bName"
              class="form-text text-muted text-validators {{formContractWord.get('{bName}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              문자만 입력해 주세요.
            </small>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="bPhone">사업체 전화번호</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.bPhone}}" minlength="10" maxlength="11"
              pattern="^-?([0-9]\d*)?$" formControlName='{bPhone}' id="bPhone" name="bPhone" placeholder="사업체 전화번호"
              id="bPhone" aria-describedby="bPhone">
            <small *ngIf="small.bPhone"
              class="form-text text-muted text-validators {{formContractWord.get('{bPhone}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              숫자만 입력해 주세요.
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="bAddr">사업체 주소</label>
            <input type="text" class="form-control {{valid.bAddr}}" formControlName='{bAddr}' maxlength="38"
              id="bAddr" name="bAddr" placeholder="사업체 주소" id="bAddr" aria-describedby="bAddr">
            <small *ngIf="small.bAddr"
              class="form-text text-muted text-validators {{formContractWord.get('{bAddr}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="bRep">사업체 대표자명</label>
            <input type="text"class="form-control {{valid.bRep}}" formControlName='{bRep}' maxlength="14"
              id="bRep" name="bRep" placeholder="사업체 대표자명" id="bRep" aria-describedby="bRep">
            <small *ngIf="small.bRep"
              class="form-text text-muted text-validators {{formContractWord.get('{bRep}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              문자만 입력해 주세요.
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="sName">근로자 성명</label>
            <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.sName}}" formControlName='{sName}' maxlength="14"
              id="sName" name="sName" placeholder="근로자 성명" id="sName" aria-describedby="sName">
            <small *ngIf="small.sName"
              class="form-text text-muted text-validators {{formContractWord.get('{sName}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              문자만 입력해 주세요.
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="sPhone">근로자 연락처</label>
            <input type="text" class="form-control {{valid.sPhone}}" formControlName='{sPhone}' id="sPhone"
              name="sPhone" placeholder="근로자 연락처" id="sPhone" minlength="10" maxlength="11" pattern="^-?([0-9]\d*)?$"
              aria-describedby="sPhone">
            <small *ngIf="small.sPhone"
              class="form-text text-muted text-validators {{formContractWord.get('{sPhone}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              숫자만 입력해 주세요.
            </small>
          </div>
        </div>
      </div>

      <!-- -******- -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="sAddr">근로자 주소</label>
            <input type="text" class="form-control {{valid.sAddr}}" formControlName='{sAddr}' maxlength="38"
              id="sAddr" name="sAddr" placeholder="근로자 주소" id="sAddr" aria-describedby="sAddr">
            <small *ngIf="small.sAddr"
              class="form-text text-muted text-validators {{formContractWord.get('{sAddr}').status}} font-16">
              <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16" alt="alert icon">
              필수 입력값을 정확하게 입력해 주세요
            </small>
          </div>
        </div>
      </div>

    </form>
    <!-- -******- -->
    <div style="margin-top:50px;" class="row">
      <div class="col-12 d-flex justify-content-center">
        <button [disabled]="!formContractWord.valid" type="button" style="width:auto !important;"
          (click)="onClickBtnCreateContractWord()" class="btn btn-big btn-crimson">
          다음
        </button>
      </div>
    </div>
  </div>

</section>

<app-alert-shared></app-alert-shared>
<app-alert></app-alert>
<app-loading></app-loading>