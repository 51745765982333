import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-mail-template-management',
  templateUrl: './mail-template-management.component.html',
  styleUrls: ['./mail-template-management.component.scss']
})
export class MailTemplateManagementComponent implements OnInit {
  sidebar: any = "set";
  constructor(
    private sidebarService: SidebarService,
  ) {
    this.sidebarService.setManuActive(this.sidebar);
    this.sidebarService.setSubManuActive('mail-managemant');
  }

  apiGet: boolean = true;
  item: any;

  state: {
    tempm: boolean,
    tempmDetail: boolean,
    create: boolean
  } = {
    tempm: true,
    tempmDetail: false,
    create: true
    }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.sidebarService.setManuActive(this.sidebar);
  }

  clickItem($event){
    this.state.create = false;
    this.state.tempmDetail = true;
    this.item = $event
    console.log($event)
  }

  sendInquiry($event){
    this.apiGet = $event
  }

  goBack($event){
    this.state.create = true;
    this.state.tempmDetail = false;
    this.apiGet = true;
  }
}
