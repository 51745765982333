import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContractInformationParttimeService {

  constructor() { }

  dataFromContract: {
    '{employer}': string,
    '{student}': string,
    '{lcys}': string,
    '{lcms}': string,
    '{lcds}': string,
    '{lcye}': string,
    '{lcme}': string,
    '{lcde}': string,
    '{whs}': string,
    '{wms}': string,
    '{whe}': string,
    '{wme}': string,
    '{sahs}': string,
    '{sams}': string,
    '{sahe}': string,
    '{same}': string,
    '{suhs}': string,
    '{sums}': string,
    '{suhe}': string,
    '{sume}': string,
    '{salay}': string,
    '{pday}': string,
    '{cys}': string,
    '{cms}': string,
    '{cds}': string,
    '{cye}': string,
    '{cme}': string,
    '{cde}': string,
    '{addcone}': string,
    '{mut}': string,
    '{ceo}': string,
    '{addctwo}': string,
    '{name}': string
  } = {
    '{employer}': '',
    '{student}': '',
    '{lcys}': 'N',
    '{lcms}': 'N',
    '{lcds}': 'N',
    '{lcye}': 'N',
    '{lcme}': 'N',
    '{lcde}': 'N',
    '{whs}': 'N',
    '{wms}': 'N',
    '{whe}': 'N',
    '{wme}': 'N',
    '{sahs}': 'N',
    '{sams}': 'N',
    '{sahe}': 'N',
    '{same}': 'N',
    '{suhs}': 'N',
    '{sums}': 'N',
    '{suhe}': 'N',
    '{sume}': 'N',
    '{salay}': '',
    '{pday}': 'N',
    '{cys}': 'N',
    '{cms}': 'N',
    '{cds}': 'N',
    '{cye}': '',
    '{cme}': '',
    '{cde}': '',
    '{addcone}': '',
    '{mut}': '',
    '{ceo}': '',
    '{addctwo}': '',
    '{name}': ''
    }

  setDataFromContract(key: any, value: string) {
    this.dataFromContract[key] = value;
  }

  getDataFromContract(key: any) {
    return this.dataFromContract[key];
  }
    
  clearDataFromContract() {
    this.dataFromContract = {
      '{employer}': '',
      '{student}': '',
      '{lcys}': 'N',
      '{lcms}': 'N',
      '{lcds}': 'N',
      '{lcye}': 'N',
      '{lcme}': 'N',
      '{lcde}': 'N',
      '{whs}': 'N',
      '{wms}': 'N',
      '{whe}': 'N',
      '{wme}': 'N',
      '{sahs}': 'N',
      '{sams}': 'N',
      '{sahe}': 'N',
      '{same}': 'N',
      '{suhs}': 'N',
      '{sums}': 'N',
      '{suhe}': 'N',
      '{sume}': 'N',
      '{salay}': '',
      '{pday}': 'N',
      '{cys}': 'N',
      '{cms}': 'N',
      '{cds}': 'N',
      '{cye}': '',
      '{cme}': '',
      '{cde}': '',
      '{addcone}': '',
      '{mut}': '',
      '{ceo}': '',
      '{addctwo}': '',
      '{name}': ''
    }
  }
}
