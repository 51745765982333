// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production:false,
  debugMode:true,
  downloadFileAttachment: "http://localhost:8129/",
  // downloadFileAttachment: "http://social.test.com:4200/",
  //whitelistedDomains: ['localhost:8080'],
   //whitelistedDomains: ['http://127.0.0.1:4200'],
  nodeEndPoint: {
    //url: 'http://127.0.0.1:8080/JSTCRM/api/',
    url:'/JSTCRM/api/',
    //port:'4000/api',
  },
  openFileBackEnd: {
    //pdfUrl: 'http://127.0.0.1:8080/pdf/'
    pdfUrl:'/JSTCRM/pdf/'
  },
  backend: {
    //endpoint: 'http://127.0.0.1:8080/JSTCRM/',
    endpoint:'/JSTCRM/',
    clientId:'gcont',
    clientSecret:'gcont2020',
    grantType:'password'
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
