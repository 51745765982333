<section class="w-100" style="background-color: #e2e6eb;">

    <div class="smallerUi">
        <section class="container-fluid p-0 d-block">
            <h3 class="text-center tit-tem-man-001 barumBold d-none">통합 계약 관리</h3>

            <div class="">


                <!--        <div class="d-flex btnnav justify-content-center signinBtns">-->
                <div class="d-flex btnnav justify-content-center signinBtns">
                    <button (click)="btnSetState('requested','requested_to_me', 'requested_blockCert')"
                            class="{{btnState.requested.class}} btn rdbutton">요청한
                        전자계약
                    </button>
                    <button (click)="btnSetState('requested_to_me','requested', 'requested_blockCert')" style="margin: 0px 0px 0px 10px;"
                            class="btn whbutton {{btnState.requested_to_me.class}}"> 나에게
                        요청된 전자계약
                    </button>
                    <button (click)="btnSetState('requested_blockCert','requested', 'requested_to_me')" style="margin: 0px 0px 0px 10px;"
                            class="btn whbutton {{btnState.requested_blockCert.class}}">
                        계약서 인증 관리
                    </button>
                </div>

                <div class="d-flex  justify-content-start ">
                </div>


                <div class="tableInputBox justify-content-center d-flex flex-row order-1">
                    <form [formGroup]=formSearch class="justify-content-center d-flex flex-row order-1 w-100">
                        <div class="form-group tableInput1 mb-0">
                            <select formControlName='serachType' class="form-control form-control-sm inp-sel-typ-001">
                                <option *ngFor="let type of codeBook.SEARCH_CONT"
                                        value="{{type.code}}">{{type.codeNm}}</option>
                            </select>
                        </div>

                        <div class="input-group tableInput2"
                             *ngIf="( (formSearch.get('serachType').value === '000002') || (formSearch.get('serachType').value === '000001') )">
                            <input type="text" formControlName='searchText'
                                   class="form-control form-control-sm shadow-none inp-sea-typ-001"
                                   placeholder="계약서 명, 보낸 사람, 받는 사람 "
                                   style="box-shadow: none !important;" aria-describedby="button-addon2"
                                   (keyup.enter)="onSerach()">
                            <div class="input-group-append">
                                <button (click)="onSerach()" class="btn btn-outline-secondary btn-search btn-sm"
                                        style="color: #000;"
                                        type="button">
                                    <img src="../../../assets/img/frontpage/searchicons.png" width="16px">
                                </button>
                            </div>
                        </div>

                        <div class="input-group tableInput2"
                             *ngIf="((formSearch.get('serachType').value === '000005'))">
                            <select formControlName='searchText' (change)="onSerach()"
                                    class="form-control form-control-sm inp-sel-typ-001">
                                <option *ngFor="let code of codeBook.CONTOR_PROGRESS"
                                        value="{{code.code}}">{{code.codeNm}}</option>
                            </select>
                        </div>

                        <div class="input-group tableInput2 tableSearchgroup"
                             *ngIf="( (formSearch.get('serachType').value === '000003') || (formSearch.get('serachType').value === '000004') )">
                            <input type="date" formControlName='searchText'
                                   class="form-control form-control-sm shadow-none inp-sea-typ-001"
                                   (change)="onSerach()" placeholder="계약서 명, 보낸 사람, 받는 사람 "
                                   style="box-shadow: none !important;" aria-describedby="button-addon2">
                        </div>

                    </form>

                </div>
            </div>


        </section>
    </div>

    <div class="largerUi">
        <section class="container-lg ml-lg-0 mr-lg-auto p-0 d-none d-lg-block">
            <h3 style="padding-bottom:25px;" class="tit-tem-man-001 barumBold ">통합 계약 관리
            </h3>
            <div class="row">
                <div class="col-md-8 d-flex flex-row">
                    <button class="btnwidth bgclass btn my-1 {{btnState.requested.class}}"
                            (click)="btnSetState('requested','requested_to_me', 'requested_blockCert')">요청한 전자계약
                    </button>
                    <button class="btnwidth bgclass btn my-1 {{btnState.requested_to_me.class}}" style="margin-left: 10px;"
                            (click)="btnSetState('requested_to_me','requested', 'requested_blockCert')">나에게 요청된 전자계약
                    </button>
                    <button class="btnwidth bgclass btn my-1 {{btnState.requested_blockCert.class}}"
                            (click)="btnSetState('requested_blockCert','requested', 'requested_to_me')">계약서 인증 관리
                    </button>
                </div>

                <div class="col-md-4 d-flex flex-row-reverse align-items-end">
                    <form [formGroup]=formSearch class="d-flex flex-row-reverse align-items-end">
                        <div class="input-group tableSearchgroup"
                             *ngIf="( (formSearch.get('serachType').value === '000002') || (formSearch.get('serachType').value === '000001') )">
                            <input type="text" formControlName='searchText'
                                   class="form-control form-control-sm shadow-none inp-sea-typ-001"
                                   placeholder="계약서 명, 보낸 사람, 받는 사람 "
                                   style="box-shadow: none !important;" aria-describedby="button-addon2"
                                   (keyup.enter)="onSerach()">
                            <div class="input-group-append">
                                <button (click)="onSerach()" class="btn btn-outline-secondary btn-search btn-sm"
                                        style="color: #000;"
                                        type="button">
                                    <img src="../../../assets/img/frontpage/searchicons.png" width="16px">
                                </button>
                            </div>
                        </div>

                        <div class="input-group tableInput2"
                             *ngIf="((formSearch.get('serachType').value === '000005'))">
                            <select formControlName='searchText' (change)="onSerach()"
                                    class="form-control form-control-sm inp-sel-typ-001">
                                <option *ngFor="let code of codeBook.CONTOR_PROGRESS"
                                        value="{{code.code}}">{{code.codeNm}}</option>
                            </select>
                        </div>

                        <div class="input-group tableSearchgroup" (change)="onSerach()"
                             *ngIf="( (formSearch.get('serachType').value === '000003') || (formSearch.get('serachType').value === '000004') )">
                            <input type="date" formControlName='searchText'
                                   class="form-control form-control-sm shadow-none inp-sea-typ-001"
                                   placeholder="계약서 명, 보낸 사람, 받는 사람 "
                                   style="box-shadow: none !important;" aria-describedby="button-addon2">
                        </div>

                        <div class="form-group mb-0">
                            <div class="form-group tableInput1 mb-0">
                                <select formControlName='serachType'
                                        class="form-control form-control-sm inp-sel-typ-001">
                                    <option *ngFor="let type of codeBook.SEARCH_CONT"
                                            value="{{type.code}}">{{type.codeNm}}</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </section>

    </div>
    <section class="container-lg ml-lg-0 mr-lg-auto p-0 p-lg-3" *ngIf="btnState.requested.display">
        <app-table-requested></app-table-requested>
    </section>
    <section class="container-lg ml-lg-0 mr-lg-auto  p-0 " *ngIf="btnState.requested_to_me.display">
        <app-table-requested-me></app-table-requested-me>
    </section>
    <section class="container-lg ml-lg-0 mr-lg-auto  p-0 " *ngIf="btnState.requested_blockCert.display">
        <app-table-requested-block-cert></app-table-requested-block-cert>
    </section>


</section>


<app-loading></app-loading>
<app-modal></app-modal>
<app-alert-confirm></app-alert-confirm>
<app-electronic-contract-details></app-electronic-contract-details>
<app-block-cert-details></app-block-cert-details>
<app-contract-cancellation></app-contract-cancellation>
