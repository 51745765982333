import {ChangeDetectorRef, Component, OnInit, AfterViewChecked} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {FormGroup} from '@angular/forms';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown';
import Regex from 'src/app/manage/shared/regex/regex';
import TokenUtils from 'src/app/manage/shared/token/token-utils';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonFunction} from 'src/app/manage/service/common-function/common-function.service';
import {API_URL} from 'src/app/manage/service/api.constant';
import {HttpClient, HttpRequest, HttpHeaders, HttpEvent} from '@angular/common/http';
import {AddCommaPipe} from 'src/app/manage/component/pipe/add-comma.pipe';
import {PaymentApplyService} from 'src/app/manage/component/modal/payment-apply/payment-apply.service';
import { ModalNoticeService } from 'src/app/manage/component/modal/modal-notice/modal-notice.service';
import {LoginService} from "../login/login.service";

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
    providers: [AddCommaPipe]
})
export class CustomerComponent implements OnInit, AfterViewChecked {

    userId: string;
    win: Window;
    faCaretDown = faCaretDown;
    formPay: FormGroup
    validators: any = {
        purchaseId: '',
    }

    itm_id: any = '';
    itm_nm: any = '';
    itm_price_type_nm: any = '';
    itm_price_value: any = '';
    itm_price: any = '';
    itm_total_price: any = '';
    curTab = 'tab1';
    user_name: any;
    paytypeList = {
        person: [ //quantity 순서로 배열
            // {name: 'Point(DC 없음)', quantity: 1, dc: 0, price: 1500, totalAmount: 1500, text:'', id: '', selected: false},
            // {name: 'Point(DC 27%)', quantity: 100, dc: 27, price: 1100, totalAmount: 110000, text:'', id: '', selected: false},
            // {name: 'Point(DC 40%)', quantity: 300, dc: 40, price: 900, totalAmount: 270000, text:'', id: '', selected: false},
            // {name: 'Point(DC 47%)', quantity: 500, dc: 47, price: 800, totalAmount: 400000, text: '', id: '', selected: false},
            // {name: 'Point(DC 57%)', quantity: 1200, dc: 57, price: 650, totalAmount: 780000, text:'', id: '', selected: false},
            // {name: 'Point(DC 60%)', quantity: 3000, dc: 60, price: 600, totalAmount: 1800000, text:'', id: '', selected: false},
            // {name: 'Point(DC 63%)', quantity: 5000, dc: 63, price: 550, totalAmount: 2750000, text:'', id: '', selected: false},
            // {name: 'Point(DC 67%)', quantity: 6000, dc: 67, price: 500, totalAmount: 3000000, text:'', id: '', selected: false},
        ],
        company: [
            {
                name: 'Standard',
                quantity: 100,
                dc: 1500,
                monthPay: '150,000(12%)',
                yearPay: '1,680,000(18%)',
                text: '',
                id: '',
                selected: false
            },
            {
                name: 'Business',
                quantity: 1000,
                dc: 110000,
                monthPay: '1,300,000(24%)',
                yearPay: '14,400,000(29%)',
                text: '',
                id: '',
                selected: false
            },
            {
                name: 'Premium',
                quantity: 10000,
                dc: 270000,
                monthPay: '11,000,000(35%)',
                yearPay: '120,000,000(41%)',
                text: '',
                id: '',
                selected: false
            },
            {
                name: 'Enterprise',
                quantity: 0,
                dc: 0,
                monthPay: 0,
                yearPay: 0,
                text: '별도문의(전화문의 : 02-0000-0000, 메일문의 : 000@ibizsoftware.net)',
                id: '',
                selected: false
            },
            {
                name: '충전권(기업형)',
                quantity: 17000,
                dc: 1700,
                monthPay: '1700(0%)',
                yearPay: '20,400(0%)',
                stext: '',
                id: '',
                selected: false
            },
        ],
    }
    selected;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private jwtHelper: JwtHelperService,
        private commonfuction: CommonFunction,
        private http: HttpClient,
        private paymentApplyService: PaymentApplyService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private modalNoticeService: ModalNoticeService,
        private loginService: LoginService
    ) {
    }


    ngOnInit(): void {
        const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');

        this.formPay = this.fb.group({
            purchaseId: [66, Regex.numberOnlyRequired],
            payType: 'person',
        });
        this.getItemInfo();

        const purchaseId = this.formPay.get('purchaseId');
        purchaseId.valueChanges.subscribe(v => {
            this.paytypeList.person.forEach(item => {
                if (v >= item.quantity) {
                    this.selectItem(item, 'A');
                }
                if (v >= 67) {
                    purchaseId.patchValue(66, {eventEmit: false});
                }
            });
        })

        this.http.post<any>(API_URL.getUserImformationCustomer, headers).subscribe(res => {
            console.log('res', res);
            ;
            if (res && res.data) {
                this.user_name = res.data.infoNm;
            }
        });

        this.initBtnLoginsocial();
        this.openNotice();
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
        // TODO: 심사 이후 수정
        if(this.loginService.getProfileLogin() != null && this.loginService.getProfileLogin() != undefined){
            this.userId = this.loginService.getProfileLogin().userIdNo;
        }
    }


    selectItem(item, type) {
        this.selected = item;
        this.paytypeList.person.forEach(v => {
            if (v.id !== item.id) {
                v.selected = false;
            } else {
                v.selected = true;
            }
        });

        if (type === 'B') {
            this.formPay.patchValue({
                purchaseId: this.selected.quantity
            })
        }
    }

    initBtnLoginsocial() {

        this.formPay.get('purchaseId').valueChanges.subscribe(selectValue => {
            console.log(this.formPay.get('purchaseId'), this.formPay.get('purchaseId').status);
            console.log('----------- 1 ' + this.formPay.get('purchaseId').value);
        });

    }


    getItemInfo() {
        const snsPayUrl = API_URL.BACKEND_CONTEXTPATH + 'api/pay/iteam/';
        const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');


        this.http.post(snsPayUrl, headers).subscribe((res: any) => {

            if (res.status) {
                const regex = /[^0-9]/g;
                this.paytypeList.person = res.data.map(item => {
                    const newItem = {
                        name: item.ITM_NM,
                        quantity: Number(item.ITM_PRICE_VALUE ? item.ITM_PRICE_VALUE : 0),
                        dc: item.ITM_TYPE.replace(regex, '') ? Number(item.ITM_TYPE.replace(regex, '')) : 0,
                        price: Number(item.ITM_PRICE),
                        totalAmount: Number(item.ITM_PRICE) * Number(item.ITM_PRICE_VALUE),
                        text: item.ITM_TEXT ? item.ITM_TEXT : '',
                        id: item.ITM_ID,
                        selected: false
                    }
                    return newItem;
                });

                this.paytypeList.person.sort((i1, i2) => i1.quantity - i2.quantity);
                this.paytypeList.person.forEach(item => {
                    if (this.formPay.value.purchaseId >= item.quantity) {
                        this.selectItem(item, 'A');
                    }
                });
            }

        }, (error) => {
            console.log(error);

        });
    }

    onOpen() {
        let m_item_id = this.selected.id;
        let m_item_name = this.selected.name;
        let m_item_price = this.selected.price;
        let m_quantity = this.formPay.get('purchaseId').value;
        let dc = this.selected.dc;
        let user_name = this.user_name;
        let m_total_amount = m_quantity * m_item_price;
        let m_vat = (m_total_amount - ((m_total_amount) / 1.1)).toFixed(0);
        let obj = {m_item_name, m_quantity, m_total_amount, m_vat, m_item_price, dc, user_name, m_item_id};
        console.log('obuj', obj);
        if (m_quantity == null) {
            this.commonfuction.popupMsg('구매', '전송 건수를 입력해주세요!');
        } else {
            if (this.checkLogin() == true) {
                if (obj) {
                    console.log('obj', obj)
                    this.paymentApplyService.open(obj);
                }
            } else {
                this.commonfuction.popupMsg('안내', '로그인[계정 생성]을 해주세요!');
                this.router.navigate(['app-register']);
            }
        }
    }

    checkLogin() {
        const accessToken = TokenUtils.getToken();
        if (accessToken && !this.jwtHelper.isTokenExpired(accessToken)) {
            return true;
        } else {
            return false;
        }
    }

    changeTab(tab) {
        this.curTab = tab;
    }

    openNotice() {
        if (this.getCookie('24hour') !== 'Y') {
            this.modalNoticeService.open();
        }
        // let url = API_URL.BACKEND_CONTEXTPATH + 'api/notice/noticePopUp?';
        // let url1 = API_URL.BACKEND_CONTEXTPATH + 'api/notice/noticePopUp01?';
        // this.commonfuction.noticePopOpen(url, 'noticePopUp', true, 420, 560, "left");
        // this.commonfuction.noticePopOpen(url1, 'noticePopUp01', true, 420, 560, "right");
    }

    // getCookie(name) {
    //     var cookie = document.cookie;
    //     if (document.cookie != "") {
    //         var cookie_array = cookie.split("; ");
    //         for (var index in cookie_array) {
    //             var cookie_name = cookie_array[index].split("=");
    //             if (cookie_name[0] == "popupYN") {
    //                 return cookie_name[1];
    //             }
    //         }
    //     }
    //     return;
    // }

    getCookie(name) {
        let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value? value[2] : null;
    }
}
