import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalRefundPaymentService {

  status = {
    open: true,
    close: false
  };

  show = false;

  change = {
    show: false,
    html: false,
    state: false
  };

  btnNo: any;
  btnYes: any;

  state: string;

  item: any

  content: string;

  constructor(

  ) { }


  open(btnNo: any, btnYes: any, item: any) {
    this.change.show = true;
    this.show = this.status.open;
    this.btnNo = btnNo;
    this.btnYes = btnYes;
    this.item = item;
  }

  close() {
    this.change.show = true;
    this.show = this.status.close;
  }

  setChange(type: any, status: boolean) {
    this.change[type] = status;
  }

  getItem(): any {
    return this.item;
  }

  setContent(content: string) {
    this.content = content;
  }

  getContent(): string {
    return this.content;
  }
}
