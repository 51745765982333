<div class="bg-modal" *ngIf="show">
  <!-- Modal -->
  <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">
        <div class="modal-header rounded-0" style="background: #ffffff;color: #000000;">
          <h5 class="modal-title" id="exampleModalLabel">팀원 관리</h5>
          <button type="button" style="color: #000000;" (click)="closeModal()" class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body rounded-0" style="background: #ffffff;color: #000000;">
          <div class="row my-2 py-3 w-100" style="background: #fff;height: 463px;">
            <div class="col-md-12">
              <div class="form-group"><label for="formGroupExampleInput">부서/팀 추가</label><input type="text"
                  placeholder="부서/팀 명 " class="form-control"></div>
            </div>
            <div class="col-12" style="height: 285px;overflow-y: auto;">
              <div class="col-12 d-flex justify-content-between">
                <div>
                  <label>팀원 추가</label>
                </div>
                <button class="btn btn-middle-short btn-white">
                  추가하기
                </button>
              </div>
              <form>
                <table class="table">
                  <tbody>
                    <tr *ngFor='let obj of objTable;let i = index'>
                      <td class="text-center">{{(i+1)}}</td>
                      <td class="text-center">{{obj.e_name}}</td>
                      <td class="text-center">{{obj.t_name}}</td>
                      <td class="text-center">{{obj.d_name}}</td>
                      <td class="text-center">{{obj.e_tel}}</td>
                    </tr>
                    <tr>
                      <td class="text-center">-</td>
                      <td class="text-center">
                        <div class="form-group">
                          <input type="text" name="name" class="form-control" id="exampleInput" [(ngModel)]="name"
                            #nameVal="ngModel">
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="form-group">
                          <input type="text" name="team" class="form-control" id="exampleInput" [(ngModel)]="team"
                            #teamVal="ngModel">
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="form-group">
                          <input type="text" name="department" class="form-control" id="exampleInput"
                            [(ngModel)]="department" #departmentVal="ngModel">
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="form-group">
                          <input type="email" name="email" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" [(ngModel)]="email" #emailVal="ngModel">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer rounded-0 d-flex justify-content-center" style="background: #ffffff;color: #000000;">
          <button type="button" class="btn btn-middle-short btn-white" (click)="closeModal()">취소</button>
          <button type="button" class="btn btn-middle-short btn-black">확인</button>
        </div>
      </div>
    </div>
  </div>
</div>