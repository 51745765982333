<!-- Smaller Screens -->

<div class="smallerUi">

    <div class="d-block">

        <div class="phoneContainer">

            <div class="d-flex row-hl">
                <div class="p-2 item-hl">상태</div>

                <div class="p-2 ml-auto item-hl">보낸 날짜 </div>
            </div>


            <ul class="mobTable  list-group mb-5">
                <ng-container *ngFor="let t of tpOne">
                    <li class="list-group-item bg-transparent border-0">

                        <div class="item-form sc-jWxkHr mobcellA">
                            <div class="row m-0">
                                <div class="field-cell check">
                                    <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox" id="form_5f48cab259087664b3096102"
                                                                                 readonly=""><label for="form_5f48cab259087664b3096102">check0</label></div>
                                </div>

                                <div [ngClass]="{'field-cell state form color-blue': true, 'col-cancel': t.pldgBlockIfResCode === '실패', 'col-progress' : t.pldgBlockIfResCode === '진행중', 'col-complete': t.pldgBlockIfResCode === '성공'}"><span> {{t.pldgBlockIfResCode}}</span></div>
                                <div class="field-cell form-name">
                  <span class="truncate">
                    {{t.ecertNm}}
                      <i style="font-style: inherit;">
                      {{t.userNm}}
                    </i>
                      <!-- <i class="img-ico-lis-man-001 ico-man-lis-exc-001" (click)="onClickExclamation(t.contId,t.contorId)">
                      </i> -->
                  </span>
                                </div>
                                <div class="field-cell date last-date"><span class="truncate">{{t.pldgHashDate}}</span></div>
                                <div class="field-cell activity"></div>

                            </div>
                        </div>

                    </li>

                </ng-container>
            </ul>
        </div>


        <div class="tabletContainer">
            <div class="d-flex row-hl  tabletTableHead">
                <div class=" item-hl">상태</div>

                <div class=" ml-auto item-hl">보낸 날짜 </div>
            </div>
            <ul class="tabletTable">
                <ng-container *ngFor="let t of tpOne">
                    <li class="bg-white margin-b3">
                        <div class="container">
                            <div class="row mobTabRow1">
                                <div class="d-flex w-100">
                                    <div [ngClass]="{'field-cell state form': true, 'col-cancel': t.pldgBlockIfResCode === '실패', 'col-progress' : t.pldgBlockIfResCode === '진행중', 'col-complete': t.pldgBlockIfResCode === '성공'}"><span class="truncate">{{t.pldgBlockIfResCode}}</span></div>
                                    <div class="ml-auto"> {{t.pldgHashDate}}</div>
                                </div>
                            </div>
                            <div class="row mobTabRow2">
                                <div class="field-cell form-name relative">
                  <span class="truncate" style="width:80%;" >
                    {{t.ecertNm}}
                      <i class="text-r">
                      {{t.userNm}}
                    </i>
                  </span>
                                </div>
                            </div>
                        </div>
                    </li>

                </ng-container>
            </ul>
        </div>


        <div class="d-flex justify-content-center" style="margin-top: 30px">
            <app-pagination [maxDisplayPage]="5" [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
                            (seclectPage)="setCurrentPage($event)"></app-pagination>
        </div>


    </div>

</div>

<!-- Larger Screens -->
<div class="largerUi">
    <div class="d-none d-lg-block">

        <div class="row">
            <div class="col-12 p-0 d-flex flex-row" style="height: 30px">

            </div>
        </div>
        <div class="row">
            <div class="col-12 p-0">



                <table class="table largeTable table-hover">
                    <thead class="th-bg-001">
                    <tr>
                        <th class="text-center tex-tit-tab-001">
                            상태
                        </th>
                        <th class="text-center tex-tit-tab-001">
                            계약서 명
                        </th>
                        <th class="text-center tex-tit-tab-001">신청자</th>
                        <th class="text-center tex-tit-tab-001">구분</th>
                        <th class="text-center tex-tit-tab-001">전송 일시</th>
                        <th class="text-center tex-tit-tab-001">완료 일시</th>
                        <th class="text-center tex-tit-tab-001">액션</th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <ng-container *ngFor="let t of tpOne">
                        <tr class="text-center">
                            <td [ngClass]="{'tabOverflowMicro text-center': true, 'col-cancel': t.pldgBlockIfResCode === '실패', 'col-progress' : t.pldgBlockIfResCode === '진행중', 'col-complete': t.pldgBlockIfResCode === '성공'}">
                                {{t.pldgBlockIfResCode}}
                            </td>
                            <td class="tabOverflow pr-4 text-center">
                                {{t.ecertNm}}
                            </td>
                            <td class="text-center">
                                {{t.userNm}}
                            </td>
                            <td class="text-center">
                                {{t.ecertType}}
                            </td>
                            <td class="text-center">
                                {{t.pldgDate}}
                            </td>
                            <td class="text-center">
                                {{t.pldgHashDate}}
                            </td>
                            <td>

                                <button class=" img-ico-lis-man-001 ico-man-lis-rel-001" title="계약서 재전송"
                                        [disabled]="((t.pldgBlockIfResCode == '성공'))"
                                        (click)="onClickRedo(t.ecertType,t.ecertId, t.frstRegisterNo, t.ecertNm, t.contorId)"></button>
                                <span title="계약서 상세" class="img-ico-lis-man-001 ico-man-lis-exc-001"
                                      (click)="onClickExclamation(t.ecertType,t.ecertId, t.frstRegisterNo, t.ecertNm, t.contorId)"></span>
                                <span title="계약서 뷰어" class="img-ico-lis-man-001 ico-man-lis-mon-001"
                                      (click)="onClickReviewContract(t.ecertType,t.ecertId, t.frstRegisterNo, t.ecertNm, t.contorId)"></span>
                                <button (click)="onClickReviewDownloadContract(t.ecertType,t.ecertId, t.frstRegisterNo, t.ecertNm, t.contorId)" title="PDF 다운로드"
                                        class="img-ico-lis-man-001 ico-man-lis-loa-001" [disabled]="!(t.pldgBlockIfResCode !== '진행중')"></button>

                            </td>

                        </tr>



                    </ng-container>
                    </tbody>
                </table>

                <div class="d-flex justify-content-center" style="margin-top: 30px">
                    <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
                                    (seclectPage)="setCurrentPage($event)"></app-pagination>
                </div>
            </div>
        </div>

    </div>
</div>
