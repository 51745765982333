import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  pathGetTemplateAll: string        = API_URL.getTemplateAll;
  pathDeleteTemplateByid: string    = API_URL.deleteTemplate;
  pathEditTemp: string              = API_URL.editTemp;
  pathFildMark: string              = API_URL.getFildMark;
  pathUploadTemplate: string        = API_URL.createTemplate;
  pathOpenFileTemplate: string      = API_URL.openFileTemplate;
  pathSaveMarkTemplate: string      = API_URL.saveMarkTemplate;
  pathGetMarkTemplate: string       = API_URL.getMarkAllTemplate;
  pathGetSignatureContract: string  = API_URL.getSignatureContract;
  pathCreateTemplate: string        = API_URL.createTemplate;
  pathDeleteMarkByMarkId: string    = API_URL.deleteMarkByMarkId;
  pathCreateTemplateSystem: string  = API_URL.createTemplateSystem;

  constructor(
    private http: HttpClient
  ) { }

  public setFormData(data: { [x: string]: string | Blob; }) {
    var formData = new FormData();
    let key = Object.keys(data);
    for (let index = 0; index < key.length; index++) {
      formData.append(key[index], data[key[index]]);
    }
    return formData;
  }

  public getTemplateAll(data: any) {
    return this.http.post(this.pathGetTemplateAll, data);
  }

  public deleteTemplate(templId) {
    const param = {
      data: {
        templId: templId
      }
    }
    return this.http.post(this.pathDeleteTemplateByid, param);
  }

  public deleteMarkTemplateByMarkId(markId) {
    const param = {
      data: {
        markId: markId
      }
    }
    return this.http.post(this.pathDeleteMarkByMarkId, param);
  }

  public getFildMark(templId) {
    return this.http.get(this.pathFildMark + '/' + templId)
  }

  public openPdfFile(nameFile: String) {
    return API_URL.openPdfdil + nameFile;
  }

  public openFileTemplate(nameFile: String, gubun: String) {
    return this.pathOpenFileTemplate + '/' + nameFile +'?gubun='+gubun;
  }

  public editTemp(data) {
    const param = {
      data: data
    }
    return this.http.post(this.pathEditTemp, param);
  }

  public uploadTemplate(formData: FormData) {
    return this.http.post(this.pathCreateTemplate, formData);
  }

  public createTemplateSystem(data: any) {
    let param = {
      data: data
    }

    return this.http.post(this.pathCreateTemplateSystem, param);
  }

  public createTemplate(fileId: string, fileName: string, frstRegNo: string) {
    const param = {
      data: {
        fileId: fileId,
        fileName: fileName,
        frstRegNo: frstRegNo
      }
    }
    return this.http.post(this.pathCreateTemplate, param);
  }

  public saveMarkTemplate(data: any) {
    const param = {
      data: data
    }
    return this.http.post(this.pathSaveMarkTemplate, param)
  }

  public getMarkTemplate(templId: any, markContor: any = "%") {
    const param = {
      data: {
        templId: templId,
        markContor: markContor
      }
    }
    return this.http.post(this.pathGetMarkTemplate, param);
  }

  public getSignatureContract(data: any){
    let param = {
      data: data
    }

    return this.http.post(this.pathGetSignatureContract, param);
  }
}
