import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ModalGallerySignatureService {

  constructor() { }

  status = {
    open: true,
    close: false
  };

  change = {
    show: false,
    state: false
  };
  signId: string = "";
  show = false;

  btn: {
    btnOk: any, btnCancel: any
  } = {
      btnOk: null, btnCancel: null
    }

  open(btnOk: any, btnCancel: any = {
    func: () => {
      this.close();
    }
  }): void {
    this.btn.btnOk = btnOk;
    this.btn.btnCancel = btnCancel;
    this.change.show = true;
    this.show = this.status.open;
  }

  close() {
    this.change.show = true;
    this.show = this.status.close;
  }

  setChange(type: any, status: boolean) {
    this.change[type] = status;
  }

  setSignId(signId: string) {
    this.signId = signId;
  }

  getSignId(): string {
    return this.signId;
  }
}
