<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  signature demo modal
</button>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Signature Pad</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-auto d-flex align-items-end">
            <p class="m-0">Please Sign here :</p>
          </div>
          <div class="col-auto text-center">
            <div class="canvas">
              <signature-pad class="signature-pad" [options]="options" (onBeginEvent)="drawBegin($event)"
                (onEndEvent)="drawComplete($event)">
              </signature-pad>
            </div>
          </div>
          <div class="col-auto d-flex align-items-end">
            <div class="btn btn-outline-warning" (click)='clear()'>clear</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)='done()'>Done</button>
      </div>
    </div>
  </div>
</div>
