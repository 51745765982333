<section style="background: #e9ecef" class="container-fluid ">

    <section class="container">



        <div style="background: #e9ecef;height: 100vh !important;" class="h-100 align-items-center row d-flex justify-content-center">

            <div class="topLandspace py-3 d-flex justify-content-center">

                <div class="{{show.phoneNumber}} bg-white justify-content-center">
                    <form [formGroup]=formInputPhone class="confirmForm bg-white"
                        style="margin-top: 115px; border-top: 2px solid #111; width: 100%;">



                        <div class="forgotTxthead text-center ">
                            <h3><b>수신자 확인</b></h3>
                            <h5 class=" m-0 ">전자 계약의 본안 및 진행을 위해서</h5>
                            <h5>본 계약 건에 대해 요청 받았던<span> 전화번호 </span>를 입력해주세요. </h5>

                        </div>

                        <div class="form-group row m-0 confirmFormInput">
                            <div class="col-sm-12 p-0">
                                <input type="text" class="form-control inp-tmp-nam-001" formControlName='contractMobile'
                                    maxlength="11" placeholder="전화번호를 입력해주세요" />
                            </div>
                        </div>

                        <div class="form-group row d-flex justify-content-center mb-0">
                            <button class="btn btn-big btn-gray" style="width: auto !important;" type="button" name=""
                                id=""> 취소
                            </button>
                            <button class="btn btn-big btn-crimson ml-3" style="width: auto !important;"
                                (click)="onClickBtnOk()" type="button">
                                확인

                            </button>
                        </div>



                    </form>
                </div>

                <div class="{{show.otpCode}} bg-white justify-content-center">
                    <form [formGroup]=formInputOtpCode class="confirmForm bg-white"
                        style="margin-top: 115px; border-top: 2px solid #111; width: 100%;">



                        <div class="forgotTxthead text-center ">
                            <h3><b>수신자 확인</b></h3>
                            <h5 class=" m-0 ">전자 계약의 본안 및 진행을 위해서</h5>
                            <h5>본 계약 건에 대해 요청 받았던<span> OTP 코드 </span>를 입력해주세요. </h5>
                        </div>

                        <div class="form-group row m-0 confirmFormInput">
                            <div class="col-sm-12 p-0">
                                <input type="text" class="form-control inp-tmp-nam-001" formControlName='otpCode'
                                    maxlength="6" placeholder="받은 코드 번호를 입력하십시오." />
                            </div>
                        </div>

                        <div class="form-group row d-flex justify-content-center mb-0">
                            <button class="btn btn-big btn-gray" style="width: auto !important;" (click)="goToHome()"
                                type="button" name="" id=""> 취소
                            </button>
                            <button class="btn btn-big btn-crimson ml-3" style="width: auto !important;"
                                (click)="onSendOtp()" type="button">
                                확인

                            </button>
                        </div>



                    </form>
                </div>

            </div>
        </div>



    </section>


</section>