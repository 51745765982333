<div class="bg-modal" *ngIf="show">
  <!-- Modal -->
  <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">
        <div class="modal-header rounded-0" style="background: #ffffff;color: #000000;">
          <h5 class="modal-title" id="exampleModalLabel">엑셀 대량 전송</h5>
          <button type="button" style="color: #000000;" (click)="closeModal()" class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body rounded-0" style="background: #ffffff;color: #000000;">
          <router-outlet></router-outlet>
          <app-excel-transfer *ngIf="state == 'excelTransfer'"></app-excel-transfer>
          <app-table-data *ngIf="state == 'tableData'"></app-table-data>
        </div>
        <div class="modal-footer rounded-0 d-flex justify-content-center" style="background: #ffffff;color: #000000;">
          <button type="button" class="btn btn-middle-short btn-white" (click)="closeModal()">취소</button>
          <button type="button" class="btn btn-middle-short btn-black" (click)="exportDataTable()"
            *ngIf="state == 'tableData'">확인</button>
        </div>
      </div>
    </div>
  </div>
</div>