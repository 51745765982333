import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SelectContractTypeService } from '../select-contract-type/select-contract-type.service';
import { ContractInformationRealestateService } from './contract-information-realestate.service';
import { Router } from '@angular/router';
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { AlertService } from '../modal/alert/alert.service';
import { TemplateService } from '../../service/template/template.service';
import { LoadingService } from '../../shared/loading/loading.service';

import Regex from '../../shared/regex/regex';
import DateShared from '../../shared/date-shared/date-shared';
import StringFormat from '../../shared/string-format/string-format';

import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';
@Component({
  selector: 'app-contract-information-realestate',
  templateUrl: './contract-information-realestate.component.html',
  styleUrls: ['./contract-information-realestate.component.scss']
})
export class ContractInformationRealestateComponent implements OnInit {
  stringFormat: StringFormat;
  dateShared: DateShared;
  formContractWord: FormGroup;

  valid: any = {
    location: '',
    landp: '',
    areaone: '',
    build: '',
    areatwo: '',
    part: '',
    areatree: '',
    depoone: '',
    exped: '',
    depotwo: '',
    pay: '',
    depotree: '',
    poys: '',
    poms: '',
    pods: '',
    ptys: '',
    ptms: '',
    ptds: '',
    bala: '',
    borr: '',
    pcpr: '',
    pcpo: '',
    pday: '',
    tdys: '',
    tdms: '',
    tdds: '',
    tdye: '',
    tdme: '',
    tdde: '',
    spec: '',
    yy: '',
    mm: '',
    dd: '',
    addcl: '',
    senuml: '',
    pnuml: '',
    lnamel: '',
    raddcl: '',
    rcnuml: '',
    rcanl: '',
    addct: '',
    senumt: '',
    pnumt: '',
    lnamet: '',
    raddct: '',
    rcnumt: '',
    rcant: ''
  };

  small: {
    location: boolean,
    landp: boolean,
    areaone: boolean,
    build: boolean,
    areatwo: boolean,
    part: boolean,
    areatree: boolean,
    depoone: boolean,
    exped: boolean,
    depotwo: boolean,
    pay: boolean,
    depotree: boolean,
    baborrla: boolean,
    poys: boolean,
    poms: boolean,
    pods: boolean,
    ptys: boolean,
    ptms: boolean,
    ptds: boolean,
    bala: boolean,
    borr: boolean,
    pcpr: boolean,
    pcpo: boolean,
    pday: boolean,
    tdys: boolean,
    tdms: boolean,
    tdds: boolean,
    tdye: boolean,
    tdme: boolean,
    tdde: boolean,
    spec: boolean,
    yy: boolean,
    mm: boolean,
    dd: boolean,
    addcl: boolean,
    senuml: boolean,
    pnuml: boolean,
    lnamel: boolean,
    raddcl: boolean,
    rcnuml: boolean,
    rcanl: boolean,
    addct: boolean,
    senumt: boolean,
    pnumt: boolean,
    lnamet: boolean,
    raddct: boolean,
    rcnumt: boolean,
    rcant: boolean
  } = {
      location: false,
      landp: false,
      areaone: false,
      build: false,
      areatwo: false,
      part: false,
      areatree: false,
      baborrla: false,
      depoone: false,
      exped: false,
      depotwo: false,
      pay: false,
      depotree: false,
      poys: false,
      poms: false,
      pods: false,
      ptys: false,
      ptms: false,
      ptds: false,
      bala: false,
      borr: false,
      pcpr: false,
      pcpo: false,
      pday: false,
      tdys: false,
      tdms: false,
      tdds: false,
      tdye: false,
      tdme: false,
      tdde: false,
      spec: false,
      yy: false,
      mm: false,
      dd: false,
      addcl: false,
      senuml: false,
      pnuml: false,
      lnamel: false,
      raddcl: false,
      rcnuml: false,
      rcanl: false,
      addct: false,
      senumt: false,
      pnumt: false,
      lnamet: false,
      raddct: false,
      rcnumt: false,
      rcant: false
    }

  inputs: {
    location: any,
    landp: any,
    areaone: any,
    build: any,
    areatwo: any,
    part: any,
    areatree: any,
    depoone: any,
    exped: any,
    depotwo: any,
    pay: any,
    depotree: any,
    poys: any,
    poms: any,
    pods: any,
    ptys: any,
    ptms: any,
    ptds: any,
    bala: any,
    borr: any,
    pcpr: any,
    pcpo: any,
    pday: any,
    tdys: any,
    tdms: any,
    tdds: any,
    tdye: any,
    tdme: any,
    tdde: any,
    spec: any,
    yy: any,
    mm: any,
    dd: any,
    addcl: any,
    senuml: any,
    pnuml: any,
    lnamel: any,
    raddcl: any,
    rcnuml: any,
    rcanl: any,
    addct: any,
    senumt: any,
    pnumt: any,
    lnamet: any,
    raddct: any,
    rcnumt: any,
    rcant: any,
    btn: {
      next: any,
    }
  } = {
      location: '',
      landp: '',
      areaone: '',
      build: '',
      areatwo: '',
      part: '',
      areatree: '',
      depoone: '',
      exped: '',
      depotwo: '',
      pay: '',
      depotree: '',
      poys: '',
      poms: '',
      pods: '',
      ptys: '',
      ptms: '',
      ptds: '',
      bala: '',
      borr: '',
      pcpr: '',
      pcpo: '',
      pday: '',
      tdys: '',
      tdms: '',
      tdds: '',
      tdye: '',
      tdme: '',
      tdde: '',
      spec: '',
      yy: '',
      mm: '',
      dd: '',
      addcl: '',
      senuml: '',
      pnuml: '',
      lnamel: '',
      raddcl: '',
      rcnuml: '',
      rcanl: '',
      addct: '',
      senumt: '',
      pnumt: '',
      lnamet: '',
      raddct: '',
      rcnumt: '',
      rcant: '',
      btn: {
        next: true
      }
    };

  data: {
    fiveYears: any[],
    months: any[],
    pods: any[],
    ptds: any[],
    tdds: any[],
    tdde: any[],
    dd: any[],
    hours: any[],
    minutes: any[],
    dpays: any[],
  } = {
      fiveYears: [],
      months: [],
      pods: [],
      ptds: [],
      tdds: [],
      tdde: [],
      dd: [],
      hours: [],
      minutes: [],
      dpays: [],
    }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private contractInformationRealestateService: ContractInformationRealestateService,
    private editorPdfService: EditorPdfService,
    private selectContractTypeService: SelectContractTypeService,
    private alertService: AlertService,
    private templateService: TemplateService,
    private loadingService: LoadingService,
    private alertSharedService: AlertSharedService,
  ) {
    this.dateShared = new DateShared();
    this.stringFormat = new StringFormat();
  }

  ngOnInit(): void {
    this.checkTemplateId();
    this.initFormContractWord();
    this.data.fiveYears = this.dateShared.getDataNextFiveYearBeforeFive();
    this.data.months = this.dateShared.getDataMonthsForYear();
    this.data.hours = this.dateShared.getHoursFull();
    this.data.minutes = this.dateShared.getMinutesFull();
    this.data.dpays = this.dateShared.daysInMonth('01', '2020');
    this.setDisabledMonthAndDay();
  }

  ngAfterViewInit(): void {
    this.intiElementInput();
  }

  intiDataForService(key) {
    if (!this.selectContractTypeService.checkState()) {
      if (key === '{rcnuml}' || key === '{senuml}' || key === '{pnuml}' || key === '{senumt}' || key === '{pnumt}' || key === '{rcnumt}') {
        this.formContractWord.get(key).setValue(this.contractInformationRealestateService.getDataFromContract(key).replace(/-/gi, ""));
      } else if (key === '{exped}') {
        this.formContractWord.get(key).setValue(this.contractInformationRealestateService.getDataFromContract(key).toString().replace(/,/g, '').split('.'));
      } else {
        this.formContractWord.get(key).setValue(this.contractInformationRealestateService.getDataFromContract(key));
      }
    }
  }

  initFormContractWord() {
    this.formContractWord = this.fb.group({
      '{location}': [null, Regex.Required],
      '{landp}': [null, Regex.Required],
      '{areaone}': [null, Regex.Required],
      '{build}': [null, Regex.Required],
      '{areatwo}': [null, Regex.Required],
      '{part}': [null, Regex.Required],
      '{areatree}': [null, Regex.Required],
      '{depoone}': [null, Regex.Required],
      '{exped}': [null, Regex.Required],
      '{depotwo}': [null, Regex.Required],
      '{depotree}': [null, Regex.Required],
      '{poys}': ['N', Regex.Required],
      '{poms}': ['N', Regex.Required],
      '{pods}': ['N', Regex.Required],
      '{bala}': [null, Regex.Required],
      '{ptys}': ['N', Regex.Required],
      '{ptms}': ['N', Regex.Required],
      '{ptds}': ['N', Regex.Required],
      '{baborrla}': [null, Regex.Required],
      '{pcpr}': [false],
      '{pcpo}': [false],
      '{pday}': ['N', Regex.Required],
      '{tdys}': ['N', Regex.Required],
      '{tdms}': ['N', Regex.Required],
      '{tdds}': ['N', Regex.Required],
      '{tdye}': ['N', Regex.Required],
      '{tdme}': ['N', Regex.Required],
      '{tdde}': ['N', Regex.Required],
      '{spec}': [null, Regex.Required],
      '{yy}': ['N', Regex.Required],
      '{mm}': ['N', Regex.Required],
      '{dd}': ['N', Regex.Required],
      '{addcl}': [null, Regex.Required],
      '{senuml}': [null, Regex.Required],
      '{pnuml}': [null, Regex.Required],
      '{lnamel}': [null, Regex.Required],
      '{raddcl}': [null, Regex.Required],
      '{rcnuml}': [null, Regex.Required],
      '{rcanl}': [null, Regex.Required],
      '{addct}': [null, Regex.Required],
      '{senumt}': [null, Regex.Required],
      '{pnumt}': [null, Regex.Required],
      '{lnamet}': [null, Regex.Required],
      '{raddct}': [null, Regex.Required],
      '{rcnumt}': [null, Regex.Required],
      '{rcant}': [null, Regex.Required],
      '{cho}': [true, Regex.Required],
      '{cht}': [false, Regex.Required]
    });

    // ================================================================= {cho} =================================================================
    this.formContractWord.get('{cho}').valueChanges.subscribe(valueChanges => {
      if (valueChanges) {
        this.formContractWord.get('{cht}').setValue(false);
      }
      this.contractInformationRealestateService.setDataFromContract('{cho}', valueChanges);
    });
    // ================================================================= {cht} =================================================================
    this.formContractWord.get('{cht}').valueChanges.subscribe(valueChanges => {
      if (valueChanges) {
        this.formContractWord.get('{cho}').setValue(false);
      }
      this.contractInformationRealestateService.setDataFromContract('{cht}', valueChanges);
    });


    // ================================================================= {location} =================================================================

    this.formContractWord.get('{location}').valueChanges.subscribe(valueChanges => {
      this.valid.location = Regex.validatorFormData(this.formContractWord.get('{location}').status);
      this.small.location = true;
      this.contractInformationRealestateService.setDataFromContract('{location}', valueChanges);
    });

    this.intiDataForService('{location}');
    // ================================================================= {landp} =================================================================

    this.formContractWord.get('{landp}').valueChanges.subscribe(valueChanges => {
      this.valid.landp = Regex.validatorFormData(this.formContractWord.get('{landp}').status);
      this.small.landp = true;
      this.contractInformationRealestateService.setDataFromContract('{landp}', valueChanges);
    });

    this.intiDataForService('{landp}');
    // ================================================================= {areaone} =================================================================

    this.formContractWord.get('{areaone}').valueChanges.subscribe(valueChanges => {
      this.valid.areaone = Regex.validatorFormData(this.formContractWord.get('{areaone}').status);
      this.small.areaone = true;
      this.contractInformationRealestateService.setDataFromContract('{areaone}', valueChanges);
    });

    this.intiDataForService('{areaone}');
    // ================================================================= {build} =================================================================

    this.formContractWord.get('{build}').valueChanges.subscribe(valueChanges => {
      this.valid.build = Regex.validatorFormData(this.formContractWord.get('{build}').status);
      this.small.build = true;
      this.contractInformationRealestateService.setDataFromContract('{build}', valueChanges);
    });

    this.intiDataForService('{build}');
    // ================================================================= {areatwo} =================================================================

    this.formContractWord.get('{areatwo}').valueChanges.subscribe(valueChanges => {
      this.valid.areatwo = Regex.validatorFormData(this.formContractWord.get('{areatwo}').status);
      this.small.areatwo = true;
      this.contractInformationRealestateService.setDataFromContract('{areatwo}', valueChanges);
    });

    this.intiDataForService('{areatwo}');
    // ================================================================= {part} =================================================================

    this.formContractWord.get('{part}').valueChanges.subscribe(valueChanges => {
      this.valid.part = Regex.validatorFormData(this.formContractWord.get('{part}').status);
      this.contractInformationRealestateService.setDataFromContract('{part}', valueChanges);
    });

    this.intiDataForService('{part}');
    // ================================================================= {areatree} =================================================================

    this.formContractWord.get('{areatree}').valueChanges.subscribe(valueChanges => {
      this.valid.areatree = Regex.validatorFormData(this.formContractWord.get('{areatree}').status);
      this.small.areatree = true;
      this.contractInformationRealestateService.setDataFromContract('{areatree}', valueChanges);
    });

    this.intiDataForService('{areatree}');
    // ================================================================= {depoone} =================================================================

    this.formContractWord.get('{depoone}').valueChanges.subscribe(valueChanges => {
      this.valid.depoone = Regex.validatorFormData(this.formContractWord.get('{depoone}').status);
      this.small.depoone = true;
      this.contractInformationRealestateService.setDataFromContract('{depoone}', valueChanges);
    });

    this.intiDataForService('{depoone}');
    // ================================================================= {exped} =================================================================

    this.formContractWord.get('{exped}').valueChanges.subscribe(valueChanges => {
      this.valid.exped = Regex.validatorFormData(this.formContractWord.get('{exped}').status);
      this.small.exped = true;
      // this.contractInformationRealestateService.setDataFromContract('{exped}', valueChanges); //제거?
    });

    this.intiDataForService('{exped}');
    // ================================================================= {depotwo} =================================================================

    this.formContractWord.get('{depotwo}').valueChanges.subscribe(valueChanges => {
      this.valid.depotwo = Regex.validatorFormData(this.formContractWord.get('{depotwo}').status);
      this.small.depotwo = true;
      this.contractInformationRealestateService.setDataFromContract('{depotwo}', valueChanges);
    });

    this.intiDataForService('{depotwo}');

    // ================================================================= {depotree} =================================================================

    this.formContractWord.get('{depotree}').valueChanges.subscribe(valueChanges => {
      this.valid.depotree = Regex.validatorFormData(this.formContractWord.get('{depotree}').status);
      this.small.depotree = true;
      this.contractInformationRealestateService.setDataFromContract('{depotree}', valueChanges);
    });

    this.intiDataForService('{depotree}');
    // ================================================================= {poys} =================================================================

    this.formContractWord.get('{poys}').valueChanges.subscribe(valueChanges => {
      this.valid.poys = Regex.validatorFormData(this.formContractWord.get('{poys}').status);
      this.contractInformationRealestateService.setDataFromContract('{poys}', valueChanges);
      if (!this.formContractWord.get('{poys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{poys}').setValue(valueChanges);
        this.formContractWord.get('{poms}').setValue('N');
        this.formContractWord.get('{poms}').enable();
      } else if (this.formContractWord.get('{poys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{poms}').setValue('N');
        this.formContractWord.get('{poms}').enable();
      } else {
        this.formContractWord.get('{poms}').disable();
      }
    });

    this.intiDataForService('{poys}');
    // ================================================================= {poms} =================================================================

    this.formContractWord.get('{poms}').valueChanges.subscribe(valueChanges => {
      this.valid.poms = Regex.validatorFormData(this.formContractWord.get('{poms}').status);
      this.contractInformationRealestateService.setDataFromContract('{poms}', valueChanges);
      if (!this.formContractWord.get('{poms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{pods}').setValue('N');
        this.formContractWord.get('{poms}').setValue(valueChanges);
        this.data.pods = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{poys}').value);
        this.formContractWord.get('{pods}').enable();
      } else if (this.formContractWord.get('{poms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{pods}').setValue('N');
        this.data.pods = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{poys}').value);
        this.formContractWord.get('{pods}').enable();
      } else {
        this.formContractWord.get('{pods}').disable();
      }
    });

    this.intiDataForService('{poms}');
    // ================================================================= {pods} =================================================================

    this.formContractWord.get('{pods}').valueChanges.subscribe(valueChanges => {
      this.valid.pods = Regex.validatorFormData(this.formContractWord.get('{pods}').status);
      this.contractInformationRealestateService.setDataFromContract('{pods}', valueChanges);
      if (!this.formContractWord.get('{pods}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{pods}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{pods}');
    // ================================================================= {bala} =================================================================

    this.formContractWord.get('{bala}').valueChanges.subscribe(valueChanges => {
      this.valid.bala = Regex.validatorFormData(this.formContractWord.get('{bala}').status);
      this.small.bala = true;
      this.contractInformationRealestateService.setDataFromContract('{bala}', valueChanges);
    });

    this.intiDataForService('{bala}');
    // ================================================================= {ptys} =================================================================

    this.formContractWord.get('{ptys}').valueChanges.subscribe(valueChanges => {
      this.valid.ptys = Regex.validatorFormData(this.formContractWord.get('{ptys}').status);
      this.contractInformationRealestateService.setDataFromContract('{ptys}', valueChanges);
      if (!this.formContractWord.get('{ptys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ptys}').setValue(valueChanges);
        this.formContractWord.get('{ptms}').setValue('N');
        this.formContractWord.get('{ptms}').enable();
      } else if (this.formContractWord.get('{ptys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ptms}').setValue('N');
        this.formContractWord.get('{ptms}').enable();
      } else {
        this.formContractWord.get('{ptms}').disable();
      }
    });

    this.intiDataForService('{ptys}');
    // ================================================================= {ptms} =================================================================

    this.formContractWord.get('{ptms}').valueChanges.subscribe(valueChanges => {
      this.valid.ptms = Regex.validatorFormData(this.formContractWord.get('{ptms}').status);
      this.contractInformationRealestateService.setDataFromContract('{ptms}', valueChanges);
      if (!this.formContractWord.get('{ptms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ptds}').setValue('N');
        this.formContractWord.get('{ptms}').setValue(valueChanges);
        this.data.ptds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ptys}').value);
        this.formContractWord.get('{ptds}').enable();
      } else if (this.formContractWord.get('{ptms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ptds}').setValue('N');
        this.data.ptds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ptys}').value);
        this.formContractWord.get('{ptds}').enable();
      } else {
        this.formContractWord.get('{ptds}').disable();
      }
    });

    this.intiDataForService('{ptms}');
    // ================================================================= {ptds} =================================================================

    this.formContractWord.get('{ptds}').valueChanges.subscribe(valueChanges => {
      this.valid.ptds = Regex.validatorFormData(this.formContractWord.get('{ptds}').status);
      this.contractInformationRealestateService.setDataFromContract('{ptds}', valueChanges);
      if (!this.formContractWord.get('{ptds}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ptds}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{ptds}');
    // ================================================================= {baborrla} =================================================================

    this.formContractWord.get('{baborrla}').valueChanges.subscribe(valueChanges => {
      this.valid.baborrla = Regex.validatorFormData(this.formContractWord.get('{baborrla}').status);
      this.small.baborrla = true;
      this.contractInformationRealestateService.setDataFromContract('{baborrla}', valueChanges);
    });

    this.intiDataForService('{baborrla}');
    // ================================================================= {pcpr} =================================================================

    this.formContractWord.get('{pcpr}').valueChanges.subscribe(valueChanges => {
      this.valid.pcpr = Regex.validatorFormData(this.formContractWord.get('{pcpr}').status);
      this.contractInformationRealestateService.setDataFromContract('{pcpr}', valueChanges);
      if (valueChanges) {
        this.formContractWord.get('{pcpo}').setValue(false);
      }
    });

    this.intiDataForService('{pcpr}');
    // ================================================================= {pcpo} =================================================================

    this.formContractWord.get('{pcpo}').valueChanges.subscribe(valueChanges => {
      this.valid.pcpo = Regex.validatorFormData(this.formContractWord.get('{pcpo}').status);
      this.contractInformationRealestateService.setDataFromContract('{pcpo}', valueChanges);
      if (valueChanges) {
        this.formContractWord.get('{pcpr}').setValue(false);
      }
    });

    this.intiDataForService('{pcpo}');
    // ================================================================= {pday} =================================================================

    this.formContractWord.get('{pday}').valueChanges.subscribe(valueChanges => {
      this.valid.pday = Regex.validatorFormData(this.formContractWord.get('{pday}').status);
      this.contractInformationRealestateService.setDataFromContract('{pday}', valueChanges);
      if (!this.formContractWord.get('{pday}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{pday}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{pday}');
    // ================================================================= {tdys} =================================================================

    this.formContractWord.get('{tdys}').valueChanges.subscribe(valueChanges => {
      this.valid.tdys = Regex.validatorFormData(this.formContractWord.get('{tdys}').status);
      this.contractInformationRealestateService.setDataFromContract('{tdys}', valueChanges);
      if (!this.formContractWord.get('{tdys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdys}').setValue(valueChanges);
        this.formContractWord.get('{tdms}').setValue('N');
        this.formContractWord.get('{tdms}').enable();
      } else if (this.formContractWord.get('{tdys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdms}').setValue('N');
        this.formContractWord.get('{tdms}').enable();
      } else {
        this.formContractWord.get('{tdms}').disable();
      }
    });

    this.intiDataForService('{tdys}');
    // ================================================================= {tdms} =================================================================

    this.formContractWord.get('{tdms}').valueChanges.subscribe(valueChanges => {
      this.valid.tdms = Regex.validatorFormData(this.formContractWord.get('{tdms}').status);
      this.contractInformationRealestateService.setDataFromContract('{tdms}', valueChanges);
      if (!this.formContractWord.get('{tdms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdds}').setValue('N');
        this.formContractWord.get('{tdms}').setValue(valueChanges);
        this.data.tdds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{tdys}').value);
        this.formContractWord.get('{tdds}').enable();
      } else if (this.formContractWord.get('{tdms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdds}').setValue('N');
        this.data.tdds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{tdys}').value);
        this.formContractWord.get('{tdds}').enable();
      } else {
        this.formContractWord.get('{tdds}').disable();
      }
    });

    this.intiDataForService('{tdms}');
    // ================================================================= {tdds} =================================================================

    this.formContractWord.get('{tdds}').valueChanges.subscribe(valueChanges => {
      this.valid.tdds = Regex.validatorFormData(this.formContractWord.get('{tdds}').status);
      this.contractInformationRealestateService.setDataFromContract('{tdds}', valueChanges);
      if (!this.formContractWord.get('{tdds}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdds}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{tdds}');
    // ================================================================= {tdye} =================================================================

    this.formContractWord.get('{tdye}').valueChanges.subscribe(valueChanges => {
      this.valid.tdye = Regex.validatorFormData(this.formContractWord.get('{tdye}').status);
      this.contractInformationRealestateService.setDataFromContract('{tdye}', valueChanges);
      if (!this.formContractWord.get('{tdye}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdye}').setValue(valueChanges);
        this.formContractWord.get('{tdme}').setValue('N');
        this.formContractWord.get('{tdme}').enable();
      } else if (this.formContractWord.get('{tdye}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdme}').setValue('N');
        this.formContractWord.get('{tdme}').enable();
      } else {
        this.formContractWord.get('{tdme}').disable();
      }
    });

    this.intiDataForService('{tdye}');
    // ================================================================= {tdme} =================================================================

    this.formContractWord.get('{tdme}').valueChanges.subscribe(valueChanges => {
      this.valid.tdme = Regex.validatorFormData(this.formContractWord.get('{tdme}').status);
      this.contractInformationRealestateService.setDataFromContract('{tdme}', valueChanges);
      if (!this.formContractWord.get('{tdme}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdde}').setValue('N');
        this.formContractWord.get('{tdme}').setValue(valueChanges);
        this.data.tdde = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{tdye}').value);
        this.formContractWord.get('{tdde}').enable();
      } else if (this.formContractWord.get('{tdme}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdde}').setValue('N');
        this.data.tdde = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{tdye}').value);
        this.formContractWord.get('{tdde}').enable();
      } else {
        this.formContractWord.get('{tdde}').disable();
      }
    });

    this.intiDataForService('{tdme}');
    // ================================================================= {tdde} =================================================================

    this.formContractWord.get('{tdde}').valueChanges.subscribe(valueChanges => {
      this.valid.tdde = Regex.validatorFormData(this.formContractWord.get('{tdde}').status);
      this.contractInformationRealestateService.setDataFromContract('{tdde}', valueChanges);
      if (!this.formContractWord.get('{tdde}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tdde}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{tdde}');
    // ================================================================= {spec} =================================================================

    this.formContractWord.get('{spec}').valueChanges.subscribe(valueChanges => {
      this.valid.spec = Regex.validatorFormData(this.formContractWord.get('{spec}').status);
      this.contractInformationRealestateService.setDataFromContract('{spec}', valueChanges);
      let newText = '';
      const arrValue = valueChanges.split('\n').join('').split('');
      arrValue.forEach((v, i) => {
        if (i !== 0 && (i % 40 === 0)) {
          newText += '\n';
          newText += v;
        } else {
          newText += v;
        }
      });
      this.formContractWord.get('{spec}').patchValue(newText, {emitEvent: false});
    });

    this.intiDataForService('{spec}');


    	    // ================================================================= {yy} =================================================================
          this.formContractWord.get('{yy}').valueChanges.subscribe(valueChanges => {
            this.valid.yy = Regex.validatorFormData(this.formContractWord.get('{yy}').status);
            this.contractInformationRealestateService.setDataFromContract('{yy}', valueChanges);
            if (!this.formContractWord.get('{yy}').valid && valueChanges !== 'N') {
              this.formContractWord.get('{yy}').setValue(valueChanges);
              this.formContractWord.get('{mm}').setValue('N');
              this.formContractWord.get('{mm}').enable();
            } else if (this.formContractWord.get('{yy}').valid && valueChanges !== 'N') {
              this.formContractWord.get('{mm}').setValue('N');
              this.formContractWord.get('{mm}').enable();
            } else {
              this.formContractWord.get('{mm}').disable();
            }
          });
          this.intiDataForService('{yy}');
          // ================================================================= {mm} =================================================================
          this.formContractWord.get('{mm}').valueChanges.subscribe(valueChanges => {
            this.valid.mm = Regex.validatorFormData(this.formContractWord.get('{mm}').status);
            this.contractInformationRealestateService.setDataFromContract('{mm}', valueChanges);
            if (!this.formContractWord.get('{mm}').valid && valueChanges !== 'N') {
              this.formContractWord.get('{dd}').setValue('N');
              this.formContractWord.get('{mm}').setValue(valueChanges);
              this.data.dd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{yy}').value);
              this.formContractWord.get('{dd}').enable();
            } else if (this.formContractWord.get('{mm}').valid && valueChanges !== 'N') {
              this.formContractWord.get('{dd}').setValue('N');
              this.data.dd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{yy}').value);
              this.formContractWord.get('{dd}').enable();
            } else {
              this.formContractWord.get('{dd}').disable();
            }
          });
          this.intiDataForService('{mm}');
          // ================================================================= {dd} =================================================================
          this.formContractWord.get('{dd}').valueChanges.subscribe(valueChanges => {
            this.valid.dd = Regex.validatorFormData(this.formContractWord.get('{dd}').status);
            this.contractInformationRealestateService.setDataFromContract('{dd}', valueChanges);
            if (!this.formContractWord.get('{dd}').valid && valueChanges !== 'N') {
              this.formContractWord.get('{dd}').setValue(valueChanges);
            }
          });
          this.intiDataForService('{dd}');
      
    // ================================================================= {addcl} =================================================================

    this.formContractWord.get('{addcl}').valueChanges.subscribe(valueChanges => {
      this.valid.addcl = Regex.validatorFormData(this.formContractWord.get('{addcl}').status);
      this.small.addcl = true;
      this.contractInformationRealestateService.setDataFromContract('{addcl}', valueChanges);
    });

    this.intiDataForService('{addcl}');
    // ================================================================= {senuml} =================================================================

    this.formContractWord.get('{senuml}').valueChanges.subscribe(valueChanges => {
      this.valid.senuml = Regex.validatorFormData(this.formContractWord.get('{senuml}').status);
      this.small.senuml = true;
      this.contractInformationRealestateService.setDataFromContract('{senuml}', this.stringFormat.socialNumberFormat(valueChanges));
    });

    this.intiDataForService('{senuml}');
    // ================================================================= {pnuml} =================================================================

    this.formContractWord.get('{pnuml}').valueChanges.subscribe(valueChanges => {
      this.valid.pnuml = Regex.validatorFormData(this.formContractWord.get('{pnuml}').status);
      this.small.pnuml = true;
      this.contractInformationRealestateService.setDataFromContract('{pnuml}', this.stringFormat.phoneNumberFormat(valueChanges));
    });

    this.intiDataForService('{pnuml}');
    // ================================================================= {lnamel} =================================================================

    this.formContractWord.get('{lnamel}').valueChanges.subscribe(valueChanges => {
      this.valid.lnamel = Regex.validatorFormData(this.formContractWord.get('{lnamel}').status);
      this.small.lnamel = true;
      this.contractInformationRealestateService.setDataFromContract('{lnamel}', valueChanges);
    });

    this.intiDataForService('{lnamel}');
    // ================================================================= {raddcl} =================================================================

    this.formContractWord.get('{raddcl}').valueChanges.subscribe(valueChanges => {
      this.valid.raddcl = Regex.validatorFormData(this.formContractWord.get('{raddcl}').status);
      this.small.raddcl = true;
      this.contractInformationRealestateService.setDataFromContract('{raddcl}', valueChanges);
    });

    this.intiDataForService('{raddcl}');
    // ================================================================= {rcnuml} =================================================================

    this.formContractWord.get('{rcnuml}').valueChanges.subscribe(valueChanges => {
      this.valid.rcnuml = Regex.validatorFormData(this.formContractWord.get('{rcnuml}').status);
      this.small.rcnuml = true;
      this.contractInformationRealestateService.setDataFromContract('{rcnuml}', this.stringFormat.socialNumberFormat(valueChanges));
    });

    this.intiDataForService('{rcnuml}');
    // ================================================================= {rcanl} =================================================================

    this.formContractWord.get('{rcanl}').valueChanges.subscribe(valueChanges => {
      this.valid.rcanl = Regex.validatorFormData(this.formContractWord.get('{rcanl}').status);
      this.small.rcanl = true;
      this.contractInformationRealestateService.setDataFromContract('{rcanl}', valueChanges);
    });

    this.intiDataForService('{rcanl}');
    // ================================================================= {addct} =================================================================

    this.formContractWord.get('{addct}').valueChanges.subscribe(valueChanges => {
      this.valid.addct = Regex.validatorFormData(this.formContractWord.get('{addct}').status);
      this.small.addct = true;
      this.contractInformationRealestateService.setDataFromContract('{addct}', valueChanges);
    });

    this.intiDataForService('{addct}');
    // ================================================================= {senumt} =================================================================

    this.formContractWord.get('{senumt}').valueChanges.subscribe(valueChanges => {
      this.valid.senumt = Regex.validatorFormData(this.formContractWord.get('{senumt}').status);
      this.small.senumt = true;
      this.contractInformationRealestateService.setDataFromContract('{senumt}', this.stringFormat.socialNumberFormat(valueChanges));
    });

    this.intiDataForService('{senumt}');
    // ================================================================= {pnumt} =================================================================

    this.formContractWord.get('{pnumt}').valueChanges.subscribe(valueChanges => {
      this.valid.pnumt = Regex.validatorFormData(this.formContractWord.get('{pnumt}').status);
      this.small.pnumt = true;
      this.contractInformationRealestateService.setDataFromContract('{pnumt}', this.stringFormat.phoneNumberFormat(valueChanges));
    });

    this.intiDataForService('{pnumt}');
    // ================================================================= {lnamet} =================================================================

    this.formContractWord.get('{lnamet}').valueChanges.subscribe(valueChanges => {
      this.valid.lnamet = Regex.validatorFormData(this.formContractWord.get('{lnamet}').status);
      this.contractInformationRealestateService.setDataFromContract('{lnamet}', valueChanges);
    });

    this.intiDataForService('{lnamet}');
    // ================================================================= {raddct} =================================================================

    this.formContractWord.get('{raddct}').valueChanges.subscribe(valueChanges => {
      this.valid.raddct = Regex.validatorFormData(this.formContractWord.get('{raddct}').status);
      this.small.raddct = true;
      this.contractInformationRealestateService.setDataFromContract('{raddct}', valueChanges);
    });

    this.intiDataForService('{raddct}');
    // ================================================================= {rcnumt} =================================================================

    this.formContractWord.get('{rcnumt}').valueChanges.subscribe(valueChanges => {
      this.valid.rcnumt = Regex.validatorFormData(this.formContractWord.get('{rcnumt}').status);
      this.small.rcnumt = true;
      this.contractInformationRealestateService.setDataFromContract('{rcnumt}', this.stringFormat.socialNumberFormat(valueChanges));
    });

    this.intiDataForService('{rcnumt}');
    // ================================================================= {rcant} =================================================================

    this.formContractWord.get('{rcant}').valueChanges.subscribe(valueChanges => {
      this.valid.rcant = Regex.validatorFormData(this.formContractWord.get('{rcant}').status);
      this.small.rcant = true;
      this.contractInformationRealestateService.setDataFromContract('{rcant}', valueChanges);
    });

    this.intiDataForService('{rcant}');
  }


  setFormaNumberExped() {
    var parts = this.formContractWord.get('{exped}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.contractInformationRealestateService.setDataFromContract('{exped}', Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }


  setFormaNumberAreaOne() {
    var parts = this.formContractWord.get('{areaone}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{areaone}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  setFormaNumberAreaTwo() {
    var parts = this.formContractWord.get('{areatwo}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{areatwo}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  setFormaNumberAreaThree() {
    var parts = this.formContractWord.get('{areatree}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{areatree}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  setDisabledMonthAndDay() {
    if (this.selectContractTypeService.checkState()) {

      this.formContractWord.get('{poms}').disable();
      this.formContractWord.get('{pods}').disable();

      this.formContractWord.get('{ptms}').disable();
      this.formContractWord.get('{ptds}').disable();

      this.formContractWord.get('{tdms}').disable();
      this.formContractWord.get('{tdds}').disable();

      this.formContractWord.get('{tdme}').disable();
      this.formContractWord.get('{tdde}').disable();

      this.formContractWord.get('{mm}').disable();
      this.formContractWord.get('{dd}').disable();
    }
  }

  checkTemplateId(): void {
    if (this.selectContractTypeService.getTempId() == '') {
      this.router.navigate(['select-contract-type']);
    }
  }

  intiElementInput() {
    Object.keys(this.inputs).forEach(key => {
      if (key !== 'btn') {
        this.inputs[key] = document.getElementById(key);
      }
    });
    this.inputs.btn.next = true;
  }

  goToMainPage() {
    this.router.navigate(['main']);
  }

  onClickBtnCreateContractWord() {
    let team_date = this.dateShared.checkDataStartEnd(
      {
        ys: this.formContractWord.get('{tdys}').value,
        ms: this.formContractWord.get('{tdms}').value,
        ds: this.formContractWord.get('{tdds}').value
      },
      {
        ye: this.formContractWord.get('{tdye}').value,
        me: this.formContractWord.get('{tdme}').value,
        de: this.formContractWord.get('{tdde}').value
      }
    );
    if (!team_date) {
      this.alertSharedService.open(
        {
          title: "알림",
          btnClose: true
        },
        {
          title: "존속 기간 종료일은 시작일 이후여야 합니다.",
          subTitle: ""
        },
        [
          {
            btnType: 'ok',
            btnNm: '확인',
            btnStyle: 'btn-black',
            btnFunc: () => {
            }
          }
        ]
      );
    }

    if (team_date) {
      this.createTemplateSystem();
    }
  }

  createTemplateSystem() {
    let params = {
      tempType: this.selectContractTypeService.getTemplType(),
      templId: this.selectContractTypeService.getTempId()
    }
    this.loadingService.openLoading();
    this.templateService.createTemplateSystem(params).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.editorPdfService.setTemplateId(rs.data.templId);
        this.editorPdfService.setTemplNm(rs.data.templNm);
        this.editorPdfService.setContorType('%');
        this.editorPdfService.setEdteMode("temp_sys");
        this.editorPdfService.setState("init");
        this.editorPdfService.download = false;
        this.router.navigate(['create-template-contract']);
      }
    });
  }
}
