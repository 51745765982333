<section class="container-fluid">
  <div class="row">
    <div class="col-md-12 p-3">
      <p class="m-0">공지사항</p>
      <p class="m-0">2020.03.27 [긴급] 카카오톡 알림톡 서비스 재개 안내</p>
      <p class="m-0">2020.03.26 [긴급] 카카오톡 알림톡 서비스 문제 -> 문자 메세지로 전환합니다.</p>
    </div>
  </div>
</section>

<section class="container-fluid">
  <div class="row my-3">
    <div class="col-md-6">
      <div style="border-bottom: solid 2px #005AF5;">
        <h4 class="p-2">프로필 상세</h4>
      </div>
      <div class="row my-2">
        <div class="col-4">
          <img src="https://picsum.photos/210/250" class="img-fluid">
        </div>
        <div class="col-8">
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            메일&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ktg@ibizsoftware.net</p>
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            이름&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;김태균</p>
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            모바일&nbsp;&nbsp;&nbsp;&nbsp;010-2222-2222</p>
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            직급&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;대리</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex bd-highlight align-items-center" style="border-bottom: solid 2px #005AF5;">
        <h4 class="flex-grow-1 bd-highlight p-2">사용자 구분</h4>
        <button class="btn btn-danger btn-sm bd-highlight px-4">
          비밀번호 변경
        </button>
      </div>
      <div class="row my-2">
        <div class="col-12">
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            현재 비밀번호&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*******************</p>
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            새로운비밀번호&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*************
          </p>
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            비밀번호 확인&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;***********</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div style="border-bottom: solid 2px #005AF5;">
        <h4 class="p-2">비밀번호</h4>
      </div>
      <div class="row my-2">
        <div class="col-12">
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            사용자 구분&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;파트너</p>
          <p class="py-2 px-3 my-1" style="background-color: #F9F9F9;">
            쿼터량&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>10</span>&nbsp;/&nbsp;<span>90</span>
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-6">

    </div>
  </div>
</section>
