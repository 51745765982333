<section class="container h-100 p-0">



  <div class="h-100 align-items-center row d-flex justify-content-center">

    <div style="background-color: #e9ecee;" class="col-lg-5 py-3 d-flex justify-content-center">
      <form class="loginBoxArea text-center" [formGroup]=formLogin>
        <img src="../../../assets/img/admin/logo_gcont2x.png" class="img-fluid mb-4" width="180px" />
        <div class="form-group row">
          <div class="col-sm-12">
            <input type="text" class="form-control" formControlName='username' id="staticEmail" placeholder="아이디 (이메일)을 입력해 주세요.
              ">
            <small class="form-text text-muted mt-1 text-validators {{validators.username}}">이메일 규칙을 확인해주세요.</small>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <input type="password" class="form-control" (keyup.enter)="onLogin()" maxlength='20' minlength='8'
              formControlName='password' id="staticpassword" placeholder="비밀번호를 입력해주세요.

              ">
            <small class="form-text text-muted mt-1 text-validators {{validators.password}}">패스워드 규칙을 확인해주세요.</small>
          </div>
        </div>

        <div class="form-group mb-0 row d-flex justify-content-center">
          <div class="col-12">
            <button type="button" (click)="onLogin()" [disabled]='disabledBtn'
              class="btn btn-block btn-crimson barumBold py-2">
              로그인
            </button>
          </div>
        </div>

        <div style=" border-bottom: 1px solid #dddddd; " class="forgotpass form-group row mx-0 mb-0 ">
          <div class="col-sm-12 text-center">
            <small class="form-text text-muted "><a href="#/register">회원 가입 </a> <span>|</span> <a
                href="#/forgotpassword"> 비밀번호
                찿기</a></small>
          </div>
        </div>
        <div>
          <p class="w-100 snsTxt text-left">소셜 계정으로 로그인하기</p>

          <div class="d-flex justify-content-between signinBtns">
            
            <button id="naverIdLogin_loginButton" class="btn snsBtns" type="button" data-social="naver"
              (click)="btnClick($event)">
              <img src="../../../assets/img/icon/naver.png"> 네이버</button>
            
            <button id="googleIdLogin_loginButton" class="btn snsBtns" type="button" data-social="google"
              (click)="btnClick($event)">
              <i class="fa fa-user"><img src="../../../assets/img/icon/google.png"></i> 구글</button>

            <button id=" kakaoIdLogin_loginButton" class="btn snsBtns" type="button" data-social="kakao"
              (click)="btnClick($event)">
              <i class="fa fa-user"><img src="../../../assets/img/icon/TALK.png"></i> 카카오</button>

          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<app-loading></app-loading>
<app-alert></app-alert>