import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SelectContractTypeService } from '../select-contract-type/select-contract-type.service';
import { ContractInformationService } from './contract-information.service';
import { Router } from '@angular/router';
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { AlertService } from '../modal/alert/alert.service';
import { TemplateService } from '../../service/template/template.service';
import { LoadingService } from '../../shared/loading/loading.service';

import StringFormat from '../../shared/string-format/string-format';
import Regex from '../../shared/regex/regex';
import DateShared from '../../shared/date-shared/date-shared';

import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';

@Component({
  selector: 'app-contract-information',
  templateUrl: './contract-information.component.html',
  styleUrls: ['./contract-information.component.scss']
})
export class ContractInformationComponent implements OnInit {
  dateShared: DateShared;
  formContractWord: FormGroup;
  stringFormat: StringFormat;


  wordDatys: {
    wDays: boolean,
    weday: boolean
  } = {
      wDays: false,
      weday: false
    }


  valid: any = {
    owner: '',
    worker: '',
    ys: '',
    ms: '',
    ds: '',
    ye: '',
    me: '',
    de: '',
    wplace: '',
    contentOfWork: '',
    ths: '',
    tms: '',
    the: '',
    tme: '',
    bhs: '',
    bms: '',
    bhe: '',
    bme: '',
    wDays: '',
    weday: '',
    salay: '',
    cb: '',
    wBonus1: '',
    cbo: '',
    oSalary: '',
    cbn: '',
    // nSalary: '',
    pDay: '',
    cbdi: '',
    cbdp: '',
    sYY: '',
    sMM: '',
    sDD: '',
    bName: '',
    bPhone: '',
    bAddr: '',
    bRep: '',
    sAddr: '',
    sPhone: '',
    sName: ''
  };

  small: {
    owner: boolean,
    worker: boolean,
    ys: boolean,
    ms: boolean,
    ds: boolean,
    ye: boolean,
    me: boolean,
    de: boolean,
    wplace: boolean,
    contentOfWork: boolean,
    ths: boolean,
    tms: boolean,
    the: boolean,
    tme: boolean,
    bhs: boolean,
    bms: boolean,
    bhe: boolean,
    bme: boolean,
    wDays: boolean,
    weday: boolean,
    salay: boolean,
    cb: boolean,
    wBonus1: boolean,
    cbx: boolean,
    cbo: boolean,
    oSalary: boolean,
    cbn: boolean,
    nSalary: boolean,
    nBonus1: boolean,
    pDay: boolean,
    cbdi: boolean,
    cbdp: boolean,
    sYY: boolean,
    sMM: boolean,
    sDD: boolean,
    bName: boolean,
    bPhone: boolean,
    bAddr: boolean,
    bRep: boolean,
    sAddr: boolean,
    sPhone: boolean,
    sName: boolean
  } = {
      owner: false,
      worker: false,
      ys: false,
      ms: false,
      ds: false,
      ye: false,
      me: false,
      de: false,
      wplace: false,
      contentOfWork: false,
      ths: false,
      tms: false,
      the: false,
      tme: false,
      bhs: false,
      bms: false,
      bhe: false,
      bme: false,
      wDays: false,
      weday: false,
      salay: false,
      cb: false,
      wBonus1: false,
      cbx: false,
      cbo: false,
      oSalary: false,
      cbn: false,
      nSalary: false,
      nBonus1: false,
      pDay: false,
      cbdi: false,
      cbdp: false,
      sYY: false,
      sMM: false,
      sDD: false,
      bName: false,
      bPhone: false,
      bAddr: false,
      bRep: false,
      sAddr: false,
      sPhone: false,
      sName: false
    }

  inputs: {
    owner: any,
    worker: any,
    ys: any,
    ms: any,
    ds: any,
    ye: any,
    me: any,
    de: any,
    wplace: any,
    contentOfWork: any,
    ths: any,
    tms: any,
    the: any,
    tme: any,
    bhs: any,
    bms: any,
    bhe: any,
    bme: any,
    wDays: any,
    weday: any,
    salay: any,
    cb: any,
    wBonus1: any,
    cbx: any,
    cbo: any,
    oSalary: any,
    cbn: any,
    nSalary: any,
    nBonus1: any,
    pDay: any,
    cbdi: any,
    cbdp: any,
    sYY: any,
    sMM: any,
    sDD: any,
    bName: any,
    bPhone: any,
    bAddr: any,
    bRep: any,
    sAddr: any,
    sPhone: any,
    sName: any,
    btn: {
      next: any,
    }
  } = {
      owner: '',
      worker: '',
      ys: '',
      ms: '',
      ds: '',
      ye: '',
      me: '',
      de: '',
      wplace: '',
      contentOfWork: '',
      ths: '',
      tms: '',
      the: '',
      tme: '',
      bhs: '',
      bms: '',
      bhe: '',
      bme: '',
      wDays: '',
      weday: '',
      salay: '',
      cb: '',
      wBonus1: '',
      cbx: '',
      cbo: '',
      oSalary: '',
      cbn: '',
      nSalary: '',
      nBonus1: '',
      pDay: '',
      cbdi: '',
      cbdp: '',
      sYY: '',
      sMM: '',
      sDD: '',
      bName: '',
      bPhone: '',
      bAddr: '',
      bRep: '',
      sAddr: '',
      sPhone: '',
      sName: '',
      btn: {
        next: true,
      }
    };

  data: {
    fiveYears: any[],
    months: any[],
    days: any[],
    ds: any,
    de: any,
    sDD: any,
    hours: any,
    dpays: any,
    minutes: any
  } = {
      fiveYears: [],
      months: [],
      days: [],
      ds: [],
      de: [],
      sDD: [],
      hours: [],
      dpays: [],
      minutes: []
    }
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private contractInformationService: ContractInformationService,
    private editorPdfService: EditorPdfService,
    private selectContractTypeService: SelectContractTypeService,
    private alertService: AlertService,
    private templateService: TemplateService,
    private loadingService: LoadingService,
    private alertSharedService: AlertSharedService
  ) {
    this.dateShared = new DateShared();
    this.stringFormat = new StringFormat();
  }

  ngOnInit(): void {
    this.checkTemplateId();
    this.initFormContractWord();
    this.data.days = this.dateShared.getDays();
    console.log(this.data.days);
    this.data.fiveYears = this.dateShared.getDataNextFiveYearBeforeFive();
    this.data.months = this.dateShared.getDataMonthsForYear();
    this.data.hours = this.dateShared.getHoursFull();
    this.data.minutes = this.dateShared.getMinutesFull();
    this.data.dpays = this.dateShared.daysInMonth('01', '2020');
    this.setDisabledMonthAndDay();
  }

  ngAfterViewInit(): void {
    this.intiElementInput();
  }

  checkTemplateId(): void {
    if (this.selectContractTypeService.getTempId() == '') {
      this.router.navigate(['select-contract-type']);
    }
  }

  intiElementInput() {
    Object.keys(this.inputs).forEach(key => {
      if (key !== 'btn') {
        this.inputs[key] = document.getElementById(key);
      }
    });
    this.inputs.btn.next = true;
  }

  intiDataForService(key) {
    if (!this.selectContractTypeService.checkState()) {
      if (key === '{bPhone}' || key === '{sPhone}') {
        this.formContractWord.get(key).setValue(this.contractInformationService.getDataFromContract(key).replace(/-/gi, ""));
      } else if (key === '{wDays}' && this.contractInformationService.getDataFromContract(key) != 'N') {
        this.formContractWord.get('{weday}').setValidators([]);
        this.formContractWord.get(key).setValue(this.contractInformationService.getDataFromContract(key));
      } else if (key === '{weday}' && this.contractInformationService.getDataFromContract(key) != '0') {
        this.formContractWord.get('{wDays}').setValidators([]);
        this.formContractWord.get(key).setValue(this.contractInformationService.getDataFromContract(key));
      } else if (key === '{wDays}' && this.contractInformationService.getDataFromContract(key) == 'N') {
        this.formContractWord.get('{weday}').setValidators([]);
      } else if (key === '{weday}' && this.contractInformationService.getDataFromContract(key) == '0') {
        this.formContractWord.get('{wDays}').setValidators([]);
      } else {
        this.formContractWord.get(key).setValue(this.contractInformationService.getDataFromContract(key));
      }
    }
  }

  setDisabledMonthAndDay() {
    if (this.selectContractTypeService.checkState()) {
      this.formContractWord.get('{ms}').disable();
      this.formContractWord.get('{ds}').disable();

      this.formContractWord.get('{me}').disable();
      this.formContractWord.get('{de}').disable();

      this.formContractWord.get('{sMM}').disable();
      this.formContractWord.get('{sDD}').disable();

      this.formContractWord.get('{wBonus1}').disable();
      this.formContractWord.get('{oSalary}').disable();
    }
  }

  initFormContractWord() {
    this.formContractWord = this.fb.group({
      '{owner}': [null, Regex.Required],
      '{worker}': [null, Regex.Required],
      '{ys}': ['N', Regex.Required],
      '{ms}': ['N', Regex.Required],
      '{ds}': ['N', Regex.Required],
      '{ye}': ['N', Regex.Required],
      '{me}': ['N', Regex.Required],
      '{de}': ['N', Regex.Required],
      '{wplace}': [null, Regex.Required],
      '{contentOfWork}': [null, Regex.Required],
      '{ths}': ['N', Regex.Required],
      '{tms}': ['N', Regex.Required],
      '{the}': ['N', Regex.Required],
      '{tme}': ['N', Regex.Required],
      '{bhs}': ['N', Regex.Required],
      '{bms}': ['N', Regex.Required],
      '{bhe}': ['N', Regex.Required],
      '{bme}': ['N', Regex.Required],
      '{wDays}': ['N', Regex.Required],
      '{weday}': ['0', Regex.Required],
      '{salay}': [null, Regex.Required],
      '{cb}': [false],
      '{wBonus1}': ['0', Regex.Required],
      '{cbx}': [false],
      '{cbo}': [false],
      '{oSalary}': [null, Regex.Required],
      '{cbn}': [false],
      '{nSalary}': [null],
      '{nBonus1}': [null],
      '{pDay}': ['N', Regex.Required],
      '{cbdi}': [false],
      '{cbdp}': [false],
      '{sYY}': ['N', Regex.Required],
      '{sMM}': ['N', Regex.Required],
      '{sDD}': ['N', Regex.Required],
      '{bName}': [null, Regex.Required],
      '{bPhone}': [null, Regex.Required],
      '{bAddr}': [null, Regex.Required],
      '{bRep}': [null, Regex.Required],
      '{sAddr}': [null, Regex.Required],
      '{sPhone}': [null, Regex.Required],
      '{sName}': [null, Regex.Required]
    });


    this.formContractWord.valueChanges.subscribe((value) => {
      if (this.formContractWord.valid) {
        this.inputs.btn.next = false
      }
    })

    // ================================================================= {owner} =================================================================

    this.formContractWord.get('{owner}').valueChanges.subscribe(valueChanges => {
      this.valid.owner = Regex.validatorFormData(this.formContractWord.get('{owner}').status);
      this.small.owner = true;
      this.contractInformationService.setDataFromContract('{owner}', valueChanges);
    });

    this.intiDataForService('{owner}');
    // ================================================================= {worker} =================================================================

    this.formContractWord.get('{worker}').valueChanges.subscribe(valueChanges => {
      this.valid.worker = Regex.validatorFormData(this.formContractWord.get('{worker}').status);
      this.small.worker = true;
      this.contractInformationService.setDataFromContract('{worker}', valueChanges);
    });

    this.intiDataForService('{worker}');
    // ================================================================= {ys} =================================================================

    this.formContractWord.get('{ys}').valueChanges.subscribe(valueChanges => {
      this.valid.ys = Regex.validatorFormData(this.formContractWord.get('{ys}').status);
      this.contractInformationService.setDataFromContract('{ys}', valueChanges);
      if (!this.formContractWord.get('{ys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ys}').setValue(valueChanges);
        this.formContractWord.get('{ms}').setValue('N');
        this.formContractWord.get('{ms}').enable();
      } else if (this.formContractWord.get('{ys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ms}').setValue('N');
        this.formContractWord.get('{ms}').enable();
      } else {
        this.formContractWord.get('{ms}').disable();
      }
    });

    this.intiDataForService('{ys}');
    // ================================================================= {ms} =================================================================

    this.formContractWord.get('{ms}').valueChanges.subscribe(valueChanges => {
      this.valid.ms = Regex.validatorFormData(this.formContractWord.get('{ms}').status);
      this.contractInformationService.setDataFromContract('{ms}', valueChanges);
      if (!this.formContractWord.get('{ms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ds}').setValue('N');
        this.formContractWord.get('{ms}').setValue(valueChanges);
        this.data.ds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ys}').value);
        this.formContractWord.get('{ds}').enable();
      } else if (this.formContractWord.get('{ms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ds}').setValue('N');
        this.data.ds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ys}').value);
        this.formContractWord.get('{ds}').enable();
      } else {
        this.formContractWord.get('{ds}').disable();
      }
    });

    this.intiDataForService('{ms}');
    // ================================================================= {ds} =================================================================

    this.formContractWord.get('{ds}').valueChanges.subscribe(valueChanges => {
      this.valid.ds = Regex.validatorFormData(this.formContractWord.get('{ds}').status);
      this.contractInformationService.setDataFromContract('{ds}', valueChanges);
      if (!this.formContractWord.get('{ds}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ds}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{ds}');
    // ================================================================= {ye} =================================================================

    this.formContractWord.get('{ye}').valueChanges.subscribe(valueChanges => {
      this.valid.ye = Regex.validatorFormData(this.formContractWord.get('{ye}').status);
      this.contractInformationService.setDataFromContract('{ye}', valueChanges);
      if (!this.formContractWord.get('{ye}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{me}').setValue('N');
        this.formContractWord.get('{ye}').setValue(valueChanges);
        this.formContractWord.get('{me}').enable();
      } else if (this.formContractWord.get('{ye}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{me}').setValue('N');
        this.formContractWord.get('{me}').enable();
      } else {
        this.formContractWord.get('{me}').disable();
      }
    });

    this.intiDataForService('{ye}');
    // ================================================================= {me} =================================================================

    this.formContractWord.get('{me}').valueChanges.subscribe(valueChanges => {
      this.valid.me = Regex.validatorFormData(this.formContractWord.get('{me}').status);
      this.contractInformationService.setDataFromContract('{me}', valueChanges);
      if (!this.formContractWord.get('{me}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{de}').setValue('N');
        this.formContractWord.get('{me}').setValue(valueChanges);
        this.data.de = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ye}').value);
        this.formContractWord.get('{de}').enable();
      } else if (this.formContractWord.get('{me}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{de}').setValue('N');
        this.data.de = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ye}').value);
        this.formContractWord.get('{de}').enable();
      } else {
        this.formContractWord.get('{de}').disable();
      }
    });

    this.intiDataForService('{me}');
    // ================================================================= {de} =================================================================

    this.formContractWord.get('{de}').valueChanges.subscribe(valueChanges => {
      this.valid.de = Regex.validatorFormData(this.formContractWord.get('{de}').status);
      this.contractInformationService.setDataFromContract('{de}', valueChanges);
      if (!this.formContractWord.get('{de}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{de}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{de}');
    // ================================================================= {wplace} =================================================================

    this.formContractWord.get('{wplace}').valueChanges.subscribe(valueChanges => {
      this.valid.wplace = Regex.validatorFormData(this.formContractWord.get('{wplace}').status);
      this.small.wplace = true;
      this.contractInformationService.setDataFromContract('{wplace}', valueChanges);
    });

    this.intiDataForService('{wplace}');
    // ================================================================= {contentOfWork} =================================================================

    this.formContractWord.get('{contentOfWork}').valueChanges.subscribe(valueChanges => {
      this.valid.contentOfWork = Regex.validatorFormData(this.formContractWord.get('{contentOfWork}').status);
      this.small.contentOfWork = true;
      this.contractInformationService.setDataFromContract('{contentOfWork}', valueChanges);
    });

    this.intiDataForService('{contentOfWork}');
    // ================================================================= {ths} =================================================================

    this.formContractWord.get('{ths}').valueChanges.subscribe(valueChanges => {
      this.valid.ths = Regex.validatorFormData(this.formContractWord.get('{ths}').status);
      if (!this.formContractWord.get('{ths}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ths}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{ths}', valueChanges);
    });

    this.intiDataForService('{ths}');
    // ================================================================= {tms} =================================================================

    this.formContractWord.get('{tms}').valueChanges.subscribe(valueChanges => {
      this.valid.tms = Regex.validatorFormData(this.formContractWord.get('{tms}').status);
      if (!this.formContractWord.get('{tms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tms}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{tms}', valueChanges);
    });

    this.intiDataForService('{tms}');
    // ================================================================= {the} =================================================================

    this.formContractWord.get('{the}').valueChanges.subscribe(valueChanges => {
      this.valid.the = Regex.validatorFormData(this.formContractWord.get('{the}').status);
      if (!this.formContractWord.get('{the}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{the}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{the}', valueChanges);
    });

    this.intiDataForService('{the}');
    // ================================================================= {tme} =================================================================

    this.formContractWord.get('{tme}').valueChanges.subscribe(valueChanges => {
      this.valid.tme = Regex.validatorFormData(this.formContractWord.get('{tme}').status);
      if (!this.formContractWord.get('{tme}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{tme}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{tme}', valueChanges);
    });

    this.intiDataForService('{tme}');
    // ================================================================= {bhs} =================================================================

    this.formContractWord.get('{bhs}').valueChanges.subscribe(valueChanges => {
      this.valid.bhs = Regex.validatorFormData(this.formContractWord.get('{bhs}').status);
      if (!this.formContractWord.get('{bhs}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bhs}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{bhs}', valueChanges);
    });

    this.intiDataForService('{bhs}');
    // ================================================================= {bms} =================================================================

    this.formContractWord.get('{bms}').valueChanges.subscribe(valueChanges => {
      this.valid.bms = Regex.validatorFormData(this.formContractWord.get('{bms}').status);
      if (!this.formContractWord.get('{bms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bms}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{bms}', valueChanges);
    });

    this.intiDataForService('{bms}');
    // ================================================================= {bhe} =================================================================

    this.formContractWord.get('{bhe}').valueChanges.subscribe(valueChanges => {
      this.valid.bhe = Regex.validatorFormData(this.formContractWord.get('{bhe}').status);
      if (!this.formContractWord.get('{bhe}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bhe}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{bhe}', valueChanges);
    });

    this.intiDataForService('{bhe}');
    // ================================================================= {bme} =================================================================

    this.formContractWord.get('{bme}').valueChanges.subscribe(valueChanges => {
      this.valid.bme = Regex.validatorFormData(this.formContractWord.get('{bme}').status);
      if (!this.formContractWord.get('{bme}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bme}').setValue(valueChanges);
      }
      this.contractInformationService.setDataFromContract('{bme}', valueChanges);
    });

    this.intiDataForService('{bme}');
    // ================================================================= {wDays} =================================================================

    this.formContractWord.get('{wDays}').valueChanges.subscribe(valueChanges => {
      this.valid.wDays = Regex.validatorFormData(this.formContractWord.get('{wDays}').status);
      this.contractInformationService.setDataFromContract('{wDays}', valueChanges);
      console.log(valueChanges, (valueChanges !== 'N'), "wDays", this.formContractWord.get('{wDays}').valid);
      if (valueChanges !== 'N') {
      }

      if (!this.formContractWord.get('{wDays}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{wDays}').setValue(valueChanges);
        this.formContractWord.get('{wDays}').setValidators(Regex.Required);
      }
    });

    this.intiDataForService('{wDays}');
    // ================================================================= {weday} =================================================================

    this.formContractWord.get('{weday}').valueChanges.subscribe(valueChanges => {
      this.valid.weday = Regex.validatorFormData(this.formContractWord.get('{weday}').status);
      this.contractInformationService.setDataFromContract('{weday}', valueChanges);
      console.log(valueChanges, (valueChanges !== '0'), "weday", this.formContractWord.get('{weday}').valid);
      if (valueChanges !== '0') {
      }

      if (!this.formContractWord.get('{weday}').valid && valueChanges !== '0') {
        this.formContractWord.get('{weday}').setValue(valueChanges);
        this.formContractWord.get('{weday}').setValidators(Regex.Required);
      }
    });

    this.intiDataForService('{weday}');
    // ================================================================= {salay} =================================================================

    this.formContractWord.get('{salay}').valueChanges.subscribe(valueChanges => {
      this.valid.salay = Regex.validatorFormData(this.formContractWord.get('{salay}').status);
      this.small.salay = true;
      this.contractInformationService.setDataFromContract('{salay}', valueChanges);
    });

    this.intiDataForService('{salay}');
    // ================================================================= {cb} =================================================================

    this.formContractWord.get('{cb}').valueChanges.subscribe(valueChanges => {
      this.valid.cb = Regex.validatorFormData(this.formContractWord.get('{cb}').status);
      this.small.cb = true;
      this.contractInformationService.setDataFromContract('{cb}', valueChanges);
      if (!valueChanges) {
        this.contractInformationService.setDataFromContract('{wBonus1}', '');
        this.formContractWord.get('{wBonus1}').setValue('');
        this.formContractWord.get('{wBonus1}').disable();
      } else {
        this.contractInformationService.setDataFromContract('{nBonus1}', '');
        this.formContractWord.get('{cbx}').setValue(false);
        this.formContractWord.get('{wBonus1}').enable();
      }
    });

    this.intiDataForService('{cb}');
    // ================================================================= {wBonus1} =================================================================

    this.formContractWord.get('{wBonus1}').valueChanges.subscribe(valueChanges => {
      this.valid.wBonus1 = Regex.validatorFormData(this.formContractWord.get('{wBonus1}').status);
      this.small.wBonus1 = true;
      this.contractInformationService.setDataFromContract('{wBonus1}', valueChanges);
      this.contractInformationService.setDataFromContract('{nBonus1}', '');
    });

    this.intiDataForService('{wBonus1}');
    // ================================================================= {cbx} =================================================================

    this.formContractWord.get('{nBonus1}').disable();
    this.formContractWord.get('{cbx}').valueChanges.subscribe(valueChanges => {
      this.valid.cbx = Regex.validatorFormData(this.formContractWord.get('{cbx}').status);
      this.small.cbx = true;
      this.contractInformationService.setDataFromContract('{cbx}', valueChanges);
      if (!valueChanges) {
        this.contractInformationService.setDataFromContract('{nBonus1}', '');
        this.formContractWord.get('{nBonus1}').setValue('');
        this.formContractWord.get('{nBonus1}').disable();
      } else {
        this.contractInformationService.setDataFromContract('{wBonus1}', '');
        this.formContractWord.get('{cb}').setValue(false);
        this.formContractWord.get('{nBonus1}').enable();
      }
    });

    this.intiDataForService('{cbx}');
    // ================================================================= {nBonus1} =================================================================

      this.formContractWord.get('{nBonus1}').valueChanges.subscribe(valueChanges => {
      this.valid.nBonus1 = Regex.validatorFormData(this.formContractWord.get('{nBonus1}').status);
      this.small.nBonus1 = true;
      this.contractInformationService.setDataFromContract('{nBonus1}', valueChanges);
      this.contractInformationService.setDataFromContract('{wBonus1}', '');
    });

    this.intiDataForService('{nBonus1}');

    // ================================================================= {cbo} =================================================================

    this.formContractWord.get('{cbo}').valueChanges.subscribe(valueChanges => {
      this.valid.cbo = Regex.validatorFormData(this.formContractWord.get('{cbo}').status);
      this.small.cbo = true;
      this.contractInformationService.setDataFromContract('{cbo}', valueChanges);
      if (!valueChanges) {
        this.contractInformationService.setDataFromContract('{oSalary}', '');
        this.formContractWord.get('{oSalary}').setValue('');
        this.formContractWord.get('{oSalary}').disable();
      } else {
        this.contractInformationService.setDataFromContract('{nSalary}', '');
        this.formContractWord.get('{cbn}').setValue(false);
        this.formContractWord.get('{oSalary}').enable();
      }
    });

    this.intiDataForService('{cbo}');
    // ================================================================= {oSalary} =================================================================

    this.formContractWord.get('{oSalary}').valueChanges.subscribe(valueChanges => {
      this.valid.oSalary = Regex.validatorFormData(this.formContractWord.get('{oSalary}').status);
      this.small.oSalary = true;
      this.contractInformationService.setDataFromContract('{oSalary}', valueChanges);
      this.contractInformationService.setDataFromContract('{nSalary}', '');
    });

    this.intiDataForService('{oSalary}');
    // ================================================================= {cbn} =================================================================

    this.formContractWord.get('{nSalary}').disable();
    this.formContractWord.get('{cbn}').valueChanges.subscribe(valueChanges => {
      this.valid.cbn = Regex.validatorFormData(this.formContractWord.get('{cbn}').status);
      this.small.cbn = true;
      this.contractInformationService.setDataFromContract('{cbn}', valueChanges);
      if (!valueChanges) {
        this.contractInformationService.setDataFromContract('{nSalary}', '');
        this.formContractWord.get('{nSalary}').setValue('');
        this.formContractWord.get('{nSalary}').disable();
      } else {
        this.contractInformationService.setDataFromContract('{oSalary}', '');
        this.formContractWord.get('{cbo}').setValue(false);
        this.formContractWord.get('{nSalary}').enable();
      }
    });

    this.intiDataForService('{cbn}');
    // ================================================================= {nSalary} =================================================================

    this.formContractWord.get('{nSalary}').valueChanges.subscribe(valueChanges => {
      this.valid.nSalary = Regex.validatorFormData(this.formContractWord.get('{nSalary}').status);
      this.small.nSalary = true;
      this.contractInformationService.setDataFromContract('{nSalary}', valueChanges);
      this.contractInformationService.setDataFromContract('{oSalary}', '');
    });

    this.intiDataForService('{nSalary}');
    // ================================================================= {pDay} =================================================================

    this.formContractWord.get('{pDay}').valueChanges.subscribe(valueChanges => {
      this.contractInformationService.setDataFromContract('{pDay}', valueChanges);
      if (!this.formContractWord.get('{pDay}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{pDay}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{pDay}');
    // ================================================================= {cbdi} =================================================================

    this.formContractWord.get('{cbdi}').valueChanges.subscribe(valueChanges => {
      this.contractInformationService.setDataFromContract('{cbdi}', valueChanges);
      if (valueChanges) {
        this.formContractWord.get('{cbdp}').setValue(false);
      }
    });

    this.intiDataForService('{cbdi}');
    // ================================================================= {cbdp} =================================================================

    this.formContractWord.get('{cbdp}').valueChanges.subscribe(valueChanges => {
      this.contractInformationService.setDataFromContract('{cbdp}', valueChanges);
      if (valueChanges) {
        this.formContractWord.get('{cbdi}').setValue(false);
      }
    });

    this.intiDataForService('{cbdp}');
    // ================================================================= {sYY} =================================================================

    this.formContractWord.get('{sYY}').valueChanges.subscribe(valueChanges => {
      this.valid.sYY = Regex.validatorFormData(this.formContractWord.get('{sYY}').status);
      this.contractInformationService.setDataFromContract('{sYY}', valueChanges);
      if (!this.formContractWord.get('{sYY}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sYY}').setValue(valueChanges);
        this.formContractWord.get('{sMM}').setValue('N');
        this.formContractWord.get('{sMM}').enable();
      } else if (this.formContractWord.get('{sYY}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sMM}').setValue('N');
        this.formContractWord.get('{sMM}').enable();
      } else {
        this.formContractWord.get('{sMM}').disable();
      }
    });

    this.intiDataForService('{sYY}');
    // ================================================================= {sMM} =================================================================

    this.formContractWord.get('{sMM}').valueChanges.subscribe(valueChanges => {
      this.valid.sMM = Regex.validatorFormData(this.formContractWord.get('{sMM}').status);
      this.contractInformationService.setDataFromContract('{sMM}', valueChanges);
      if (!this.formContractWord.get('{sMM}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sDD}').setValue('N');
        this.formContractWord.get('{sMM}').setValue(valueChanges);
        this.data.sDD = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{sYY}').value);
        this.formContractWord.get('{sDD}').enable();
      } else if (this.formContractWord.get('{sMM}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sDD}').setValue('N');
        this.data.sDD = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{sYY}').value);
        this.formContractWord.get('{sDD}').enable();
      } else {
        this.formContractWord.get('{sDD}').disable();
      }
    });

    this.intiDataForService('{sMM}');
    // ================================================================= {sDD} =================================================================

    this.formContractWord.get('{sDD}').valueChanges.subscribe(valueChanges => {
      this.valid.sDD = Regex.validatorFormData(this.formContractWord.get('{sDD}').status);
      this.contractInformationService.setDataFromContract('{sDD}', valueChanges);
      if (!this.formContractWord.get('{sDD}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sDD}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{sDD}');
    // ================================================================= {bName} =================================================================

    this.formContractWord.get('{bName}').valueChanges.subscribe(valueChanges => {
      this.valid.bName = Regex.validatorFormData(this.formContractWord.get('{bName}').status);
      this.small.bName = true;
      this.contractInformationService.setDataFromContract('{bName}', valueChanges);
    });

    this.intiDataForService('{bName}');
    // ================================================================= {bPhone} =================================================================

    this.formContractWord.get('{bPhone}').valueChanges.subscribe(valueChanges => {
      this.valid.bPhone = Regex.validatorFormData(this.formContractWord.get('{bPhone}').status);
      this.small.bPhone = true;
      this.contractInformationService.setDataFromContract('{bPhone}', this.stringFormat.phoneNumberFormat(valueChanges));
    });

    this.intiDataForService('{bPhone}');
    // ================================================================= {bAddr} =================================================================

    this.formContractWord.get('{bAddr}').valueChanges.subscribe(valueChanges => {
      this.valid.bAddr = Regex.validatorFormData(this.formContractWord.get('{bAddr}').status);
      this.small.bAddr = true;
      this.contractInformationService.setDataFromContract('{bAddr}', valueChanges);
    });

    this.intiDataForService('{bAddr}');
    // ================================================================= {bRep} =================================================================

    this.formContractWord.get('{bRep}').valueChanges.subscribe(valueChanges => {
      this.valid.bRep = Regex.validatorFormData(this.formContractWord.get('{bRep}').status);
      this.small.bRep = true;
      this.contractInformationService.setDataFromContract('{bRep}', valueChanges);
    });

    this.intiDataForService('{bRep}');
    // ================================================================= {sAddr} =================================================================

    this.formContractWord.get('{sAddr}').valueChanges.subscribe(valueChanges => {
      this.valid.sAddr = Regex.validatorFormData(this.formContractWord.get('{sAddr}').status);
      this.small.sAddr = true;
      this.contractInformationService.setDataFromContract('{sAddr}', valueChanges);
    });

    this.intiDataForService('{sAddr}');
    // ================================================================= {sPhone} =================================================================

    this.formContractWord.get('{sPhone}').valueChanges.subscribe(valueChanges => {
      this.valid.sPhone = Regex.validatorFormData(this.formContractWord.get('{sPhone}').status);
      this.small.sPhone = true;
      this.contractInformationService.setDataFromContract('{sPhone}', this.stringFormat.phoneNumberFormat(valueChanges));
    });

    this.intiDataForService('{sPhone}');
    // ================================================================= {sName} =================================================================

    this.formContractWord.get('{sName}').valueChanges.subscribe(valueChanges => {
      this.valid.sName = Regex.validatorFormData(this.formContractWord.get('{sName}').status);
      this.small.sName = true;
      this.contractInformationService.setDataFromContract('{sName}', valueChanges);
    });

    this.intiDataForService('{sName}');
  }

  setFormaNumber() {
    var parts = this.formContractWord.get('{salay}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{salay}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }


  setFormaNumberwBonus1() {
    var parts = this.formContractWord.get('{wBonus1}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{wBonus1}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }


  setFormaNumberoSalary() {
    var parts = this.formContractWord.get('{oSalary}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{oSalary}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  setFormaNumbernBonus1() {
    var parts = this.formContractWord.get('{nBonus1}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{nBonus1}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  setFormaNumbernSalary() {
    var parts = this.formContractWord.get('{nSalary}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{nSalary}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  onClickBtnCreateContractWord() {
    if (this.formContractWord.valid) {
      let date_contract = this.dateShared.checkDataStartEnd(
        {
          ys: this.formContractWord.get('{ys}').value,
          ms: this.formContractWord.get('{ms}').value,
          ds: this.formContractWord.get('{ds}').value
        },
        {
          ye: this.formContractWord.get('{ye}').value,
          me: this.formContractWord.get('{me}').value,
          de: this.formContractWord.get('{de}').value
        }
      );

      let time_full = this.dateShared.checkTimeStartEnd(
        {
          hs: this.formContractWord.get('{ths}').value,
          ms: this.formContractWord.get('{tms}').value
        },
        {
          he: this.formContractWord.get('{the}').value,
          me: this.formContractWord.get('{tme}').value
        }
      );

      let time_break = this.dateShared.checkTimeStartEnd(
        {
          hs: this.formContractWord.get('{bhs}').value,
          ms: this.formContractWord.get('{bms}').value
        },
        {
          he: this.formContractWord.get('{bhe}').value,
          me: this.formContractWord.get('{bme}').value
        }
      );
      if (!date_contract) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "근로 계약 종료일은 시작일 이후여야 합니다.",
            subTitle: ""
          },
          [
            {
              btnType: 'close',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {
              }
            }
          ]
        );
      } else {
        if (!time_full) {

          this.alertSharedService.open(
            {
              title: "알림",
              btnClose: true
            },
            {
              title: "작업 종료 시간은 작업 시작 시간 이후여야 합니다.",
              subTitle: ""
            },
            [
              {
                btnType: 'close',
                btnNm: '확인',
                btnStyle: 'btn-black',
                btnFunc: () => {
                }
              }
            ]
          );

        } else {
          if (!time_break) {


            this.alertSharedService.open(
              {
                title: "알림",
                btnClose: true
              },
              {
                title: "휴식 종료 시간은 휴식 시작 시간 이후여야 합니다.",
                subTitle: ""
              },
              [
                {
                  btnType: 'close',
                  btnNm: '확인',
                  btnStyle: 'btn-black',
                  btnFunc: () => {
                  }
                }
              ]
            );

          }
        }
      }

      if (date_contract && time_full && time_break) {
        console.log(this.contractInformationService.dataFromContract);

        this.createTemplateSystem();
      }
    } else {


      this.alertSharedService.open(
        {
          title: "알림",
          btnClose: true
        },
        {
          title: "정보를 입력하세요.",
          subTitle: "당신의 완료."
        },
        [
          {
            btnType: 'close',
            btnNm: '확인',
            btnStyle: 'btn-black',
            btnFunc: () => {
            }
          }
        ]
      );

    }
  }

  createTemplateSystem() {
    let params = {
      tempType: this.selectContractTypeService.getTemplType(),
      templId: this.selectContractTypeService.getTempId()
    }
    this.loadingService.openLoading();
    this.templateService.createTemplateSystem(params).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.editorPdfService.setTemplateId(rs.data.templId);
        this.editorPdfService.setTemplNm(rs.data.templNm);
        this.editorPdfService.setContorType('%');
        this.editorPdfService.setEdteMode("temp_sys");
        this.editorPdfService.setState("init");
        this.editorPdfService.download = false;
        this.router.navigate(['create-template-contract']);
      }
    });
  }

  goToMainPage() {
    this.router.navigate(['main']);
  }
}
