import { Component, OnInit } from '@angular/core';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faSign } from '@fortawesome/free-solid-svg-icons/faSign';
import { faBlender } from '@fortawesome/free-solid-svg-icons/faBlender';
import { Router } from '@angular/router';
import { SelectContractTypeService } from './select-contract-type.service';
import { NavbarService } from '../navbar/navbar.service';

import { ContractInformationService } from '../contract-information/contract-information.service';
import { ContractInformationBorrowingService } from '../contract-information-borrowing/contract-information-borrowing.service';
import { ContractInformationParttimeService } from '../contract-information-parttime/contract-information-parttime.service';
import { ContractInformationRealestateService } from '../contract-information-realestate/contract-information-realestate.service';

@Component({
  selector: 'app-select-contract-type',
  templateUrl: './select-contract-type.component.html',
  styleUrls: ['./select-contract-type.component.scss']
})

export class SelectContractTypeComponent implements OnInit {
  sidebar: any = '';
  faHandshake = faHandshake;
  faFileSignature = faFileSignature;
  faSign = faSign;
  faBlender = faBlender;
  btn: any = {
    next: true
  }
  typeSelect = {
    work: false,
    jobContract: false,
    lease: false,
    borrower: false,
  }

  dataTemplate: {
    work: {
      tempId: string, tempNm: string, templType: string, path: string
    },
    jobContract: {
      tempId: string, tempNm: string, templType: string, path: string
    },
    lease: {
      tempId: string, tempNm: string, templType: string, path: string
    },
    borrower: {
      tempId: string, tempNm: string, templType: string, path: string
    }
  } = {
      work: {
        tempId: 'TP000000000000000049', tempNm: '01_표준근로계약서_FILE_SYS', templType: '000001', path: 'contract-standard'
      },
      jobContract: {
        tempId: 'TP000000000000000369', tempNm: 'Parttime_Template_SYS', templType: '000002', path: 'contract-parttime'
        // tempId: 'TP000000000000001827', tempNm: 'Parttime_Template_SYS', templType: '000002', path: 'contract-parttime'
      },
      lease: {
        tempId: 'TP000000000000000374', tempNm: '03_부동산임대차계약서양식(직거래)_FILE_SYS', templType: '000003', path: 'contract-realestate'
      },
      borrower: {
        tempId: 'TP000000000000000380', tempNm: '04_차용증양식_FILE_SYS', templType: '000004', path: 'contract-borrowing'
      }
    }

  constructor(
    private router: Router,
    private selectContractTypeService: SelectContractTypeService,
    private navbarService: NavbarService,

    private contractInformationService: ContractInformationService,
    private contractInformationBorrowingService: ContractInformationBorrowingService,
    private contractInformationParttimeService: ContractInformationParttimeService,
    private contractInformationRealestateService: ContractInformationRealestateService,
  ) { }

  ngOnInit(): void {
    this.contractInformationService.clearDataFromContract();
    this.contractInformationBorrowingService.clearDataFromContract();
    this.contractInformationParttimeService.clearDataFromContract();
    this.contractInformationRealestateService.clearDataFromContract();
    this.navbarService.setAction('selectTemp');
  }

  selectBtnType() {
    this.router.navigate([this.selectContractTypeService.getPath()]);
  }

  selectType(type: any) {
    this.crlSelect();
    this.selectContractTypeService.setContractType(type);
    this.selectContractTypeService.setTempId(this.dataTemplate[type].tempId);
    this.selectContractTypeService.setTempNm(this.dataTemplate[type].tempNm);
    this.selectContractTypeService.setTemplType(this.dataTemplate[type].templType);
    this.selectContractTypeService.setPath(this.dataTemplate[type].path);

    this.typeSelect[type] = true;
    this.btn.next = false;
  }

  crlSelect() {
    this.typeSelect = {
      work: false,
      jobContract: false,
      lease: false,
      borrower: false,
    }
  }
}
