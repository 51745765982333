import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElectronicContractDetailsService {

  contracDetails: {
    contReceMail: string,
    contReceNm: string,
    contSendMail: string,
    contSendNm: string,
    sendDate: string
    shortUrl: string,
    tempId: string,
    tempNm: string
  } = {
      contReceMail: '',
      contReceNm: '',
      contSendMail: '',
      contSendNm: '',
      sendDate: '',
      shortUrl: '',
      tempId: '',
      tempNm: ''
    };

  contHistory: {
    histDate: string,
    histIp: string,
    histProgress: string,
    userNm: string
  }[] = [];


  status = {
    open: true,
    close: false
  };
  show = false;
  change = {
    show: false,
    html: false,
    state: false
  };
  text = {
    title: '',
    subTitle: '',
  }
  htmlBody: string;
  state: string;
  constructor() { }

  setState(state?: 'excelTransfer' | 'tableData') {
    this.state = state;
    this.change.state = true;
  }

  getState() {
    return this.state;
  }

  open() {
    this.change.show = true;
    this.show = this.status.open;
  }

  close() {
    this.change.show = true;
    this.show = this.status.close;
  }

  setChange(type: any, status: boolean) {
    this.change[type] = status;
  }

  setHtmlBody(html: string) {
    this.change.html = true;
    this.htmlBody = html;
  }

  setConrtractDetails(details: any) {
    this.contracDetails = details;
    console.log(this.contracDetails);
    this.change.state = true;
  }

  getContractDetails(): {
    contReceMail: string,
    contReceNm: string,
    contSendMail: string,
    contSendNm: string,
    sendDate: string,
    shortUrl: string,
    tempId: string,
    tempNm: string
  } {

    console.log(this.contracDetails, "getContractDetails");
    return this.contracDetails
  }

  setContractHistory(history: any): void {
    this.contHistory = history;
  }

  getContractHistory(): {
    histDate: string,
    histIp: string,
    histProgress: string,
    userNm: string
  }[] {
    return this.contHistory;
  }
}
