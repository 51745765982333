<div _gcont_team_manage class="d-flex row-hl">

  <div _gcont_team_manage>
    <button _gcont_team_manage class="btn btn-small-long btn-white btnMargs" (click)="onDeleteDivision()">
      삭제
    </button>
    <button _gcont_team_manage class="btn btn-small-long btn-white btnMargs" style="width: auto !important;" (click)="onClickDepartment()">
      하위 부서/팀 관리
    </button>
    <button _gcont_team_manage class="btn btn-small-long btn-white btnMargs" style="display: none;width: auto !important;"
      (click)="onClickTeam()">
      팀원 관리
    </button>
  </div>
</div>
<div _gcont_team_manage class="row">
  <div _gcont_team_manage class="col-12 d-flex flex-column flex-lg-row ">
    <div _gcont_team_manage class="bor-tre-tre-001">
      <div  _gcont_team_manage class="siz-bod-tre d-flex flex-wrap" style="height:auto!important;">
        <div _gcont_team_manage class="w-100 d-flex flex-wrap py-2 hea-tre-001 align-items-center">
          <div _gcont_team_manage class="col-3 p-0">
            <div _gcont_team_manage class="w-100 d-flex py-2 hea-tre-001 border-0 justify-content-center align-items-center">
              <label _gcont_team_manage class="mb-0 oti-sel-001">이름</label>
            </div>
          </div>
          <div _gcont_team_manage class="col-9 pl-0">
            <form _gcont_team_manage [formGroup]=formSearch>
              <div _gcont_team_manage class="input-group">
                <input _gcont_team_manage type="text" formControlName='searchText' class="form-control inp-scr-001"
                  (keyup.enter)="onSearch()" [placeholder]="" aria-describedby="button-addon2">
                <div _gcont_team_manage class="input-group-append">
                  <button _gcont_team_manage class="btn btn-outline-secondary border-0 searchBTN" (click)="onSearch()"
                    style="color: #000000;" type="button" id="button-addon2">
                    <div class="img-ico-sea-002 ico-cea-001"></div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div _gcont_team_manage class="col-12 p-0" style="height: 509px;overflow: auto;">
          <div _gcont_team_manage _gcont_team_manage class="w-100 {{cssClass.tree}}" id="tree">
            <div _gcont_team_manage class="clt ">
              <div _gcont_team_manage class="ico-img-tre-div-001 ico-tre-fod-001" style="cursor: pointer;"
                (click)="onClickOrganization(organ.orgId,organ.orgNm)" [attr.orgId]="organ.orgId"><span
                  class="nam-com-001">{{organ.orgNm}}</span></div>
              <ul _gcont_team_manage class="clt active divi-pra-001" id="htmlTree">
              </ul>
            </div>
          </div>
          <div _gcont_team_manage class="w-100 {{cssClass.list}}" id="list">
            <ul _gcont_team_manage class="lis-hea-001" id="htmlList">
              <ng-container *ngFor="let item_one of groupSearch; let i = index;let lastcall=last">
                <li _gcont_team_manage class="cus-che-001">
                  <div _gcont_team_manage class="lis-sub-hea-001 px-3 tit" id="d-{{item_one[0].divId}}" (click)="openList($event)">
                    {{item_one[0].divNm}}
                  </div>
                  <ul _gcont_team_manage class="lis-hea-001 nested active">
                    <ng-container *ngFor="let item_two of item_one; let j = index">
                      <li _gcont_team_manage class="cus-che-001" id="u-{{item_two.staffId}}">
                        <div _gcont_team_manage class="lis-sub-hea-003">
                          <label _gcont_team_manage class="con-che-001">
                            <input _gcont_team_manage type="checkbox" class="cheUse" id="{{item_two.divId}}-{{item_two.userId}}"
                              (change)="checkBoxUser($event,item_two)">
                              <span style="top: -3px;" class="che-mar-001"></span>
                          </label>
                          <span _gcont_team_manage class="px-2 tit">{{item_two.userNm}}</span>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </li>
                <span _gcont_team_manage *ngIf="lastcall">{{anyfunction()}} </span>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>