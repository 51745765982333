import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalNoticeService } from './modal-notice.service';

@Component({
  selector: 'app-modal-notice',
  templateUrl: './modal-notice.component.html',
  styleUrls: ['./modal-notice.component.scss']
})
export class ModalNoticeComponent implements OnInit {
  show: boolean;
  htmlBody: string;
  state: string;
  popUp = 'req';
  form: any;
  constructor(
    private modalNoticeService: ModalNoticeService,
  ) { 
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      cookieCheck: new FormControl(false)
    })

    const cookieCheck = this.form.get('cookieCheck');
    cookieCheck.valueChanges.subscribe(v => {
      if (v) {
        this.setCookie('24hour', 'Y', 1);
        this.closeModal();
      }
    })
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    if (this.modalNoticeService.change.show) {
      this.show = this.modalNoticeService.show;
      this.modalNoticeService.setChange('show', false);
    }
    if (this.modalNoticeService.change.html) {
      this.htmlBody = this.modalNoticeService.htmlBody;
      this.modalNoticeService.setChange('html', false);
    }
    if (this.modalNoticeService.change.state) {
      this.state = this.modalNoticeService.getState();
      this.modalNoticeService.setChange('state', false);
    }
  }

  closeModal() {
    this.popUp = 'req';
    this.modalNoticeService.close();
  }

  setCookie(name, value, exp) {
    let date = new Date();
    date.setTime(date.getTime() + exp*24*60*60*1000);
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
  }
  getCookie(name) {
    let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value? value[2] : null;
  }
}
