<ul style="list-style: none;" class="py-3 px-0" *ngIf="config.showTools">
  <li class="sursor-pointer tools-icon text-left" id="text" draggable="true" (dragstart)="onDragStart($event)"
    (click)='addTextToPdf("text","text")'>
    <span class="img-ico-edi-001 ico-edi-tex-001"></span>
    <!-- <fa-icon [icon]="faTextHeight" class="fa-1x"></fa-icon> -->
    &nbsp;텍스트
  </li>
  <li class="sursor-pointer tools-icon text-left" id="checkbox" draggable="true" (dragstart)="onDragStart($event)"
    (click)="addCheckBox()">
    <span class="img-ico-edi-001 ico-edi-che-001"></span>
    <!-- <fa-icon [icon]="faCheckSquare" class="fa-1x"></fa-icon>  -->
    &nbsp;체크박스
  </li>
  <li class="sursor-pointer tools-icon  text-left" id="number" draggable="true" (dragstart)="onDragStart($event)"
    (click)='addTestTypeNumber("number")'>
    <span class="img-ico-edi-001 ico-edi-num-001"></span>
    <!-- <fa-icon [icon]="faSquare" class="fa-1x"></fa-icon>  -->
    &nbsp;숫자
  </li>
  <li class="sursor-pointer tools-icon  text-left" id="date" draggable="true" (dragstart)="onDragStart($event)"
    (click)='addTestTypeDate("date")'>
    <span class="img-ico-edi-001 ico-edi-cal-001"></span>
    <!-- <fa-icon [icon]="faCalendarMinus" class="fa-1x"></fa-icon> -->
    &nbsp;날짜
  </li>
  <li class="sursor-pointer tools-icon  text-left" id="signature" draggable="true" (dragstart)="onDragStart($event)"
    (click)='addTextToPdf("signature","signature s")'>
    <span class="img-ico-edi-001 ico-edi-sin-001"></span>
    <!-- <fa-icon [icon]="faVine" class="fa-1x"></fa-icon>  -->
    &nbsp;서명
  </li>
  <li class="sursor-pointer tools-icon  text-left" id="attachments" draggable="true" (dragstart)="onDragStart($event)"
   (click)="addAttachmentsType()">
    <span class="img-ico-edi-001 ico-edi-lik-001"></span>
    <!-- <fa-icon [icon]="faLink" class="fa-1x"></fa-icon>  -->
    &nbsp;첨부파일
  </li>
</ul>