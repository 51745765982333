<section>
  <div class="row">
    <div class="col-md-10 p-3">
      <p class="m-0">공지사항</p>
      <p class="m-0">2020.03.27 [긴급] 카카오톡 알림톡 서비스 재개 안내</p>
      <p class="m-0">2020.03.26 [긴급] 카카오톡 알림톡 서비스 문제 -> 문자 메세지로 전환합니다.</p>
    </div>
    <div class="col-md-2 p-3">
      <a href="dashboard/viewpdf">
        <div class="btn btn-info">
          Demo Pdf Editor
        </div>
      </a>
    </div>
  </div>
</section>

<section>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive-md">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center" scope="col">담당</th>
              <th class="text-center" scope="col">크레딧</th>
              <th class="text-center" scope="col">보냄</th>
              <th class="text-center" scope="col">진행중</th>
              <th class="text-center" scope="col">완료</th>
              <th class="text-center" scope="col">취소</th>
              <th class="text-center" scope="col">반려</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <div class="media">
                  <img src="../../../assets/img/admin/profile_image_not_found.png" width="50" class="mr-3" alt="">
                  <div class="media-body">
                    <p class="mb-2 font-10"><span class="badge badge-info">&nbsp;&nbsp;</span>&nbsp;아이소프트 > BackOffice >
                      HR</p>
                    <p class="m-0 font-16">이진주 (과장)</p>
                  </div>
                </div>
              </th>
              <td class="text-center">783</td>
              <td class="text-center">30</td>
              <td class="text-center">24</td>
              <td class="text-center">3</td>
              <td class="text-center">1</td>
              <td class="text-center">2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div style="border-bottom: solid 2px black;">
            나에게 요청된 계약 (3개)
          </div>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div style="border-bottom: solid 2px black;">
            완료한 계약 (11개)
          </div>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
        <div class="col-md-3 py-3">
          <img src="../../../assets/img/admin/Group 38314@2x.png" class="img-fluid">
          <p class="font-10 my-1">2020 연봉 계약...2020.03.28</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div style="border-bottom: solid 2px black;">
            계약별 증가 추이
          </div>
          <img src="../../../assets/img/admin/Image 12@2x.png" class="img-fluid py-3">
        </div>
      </div>
    </div>
  </div>
</section>