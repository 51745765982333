<div class="bg-modal" *ngIf="show">
    <!-- Modal -->
    <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content rounded-0" style="background: transparent">
                <div class="modal-header rounded-0 pb-0 border-0" style="background: #ffffff;color: #000000;">
                    <div class="border-bottom border-dark d-flex w-100 pb-2 ">
                        <h5 class="modal-title font-weight-bold" id="exampleModalLabel">링크 보내기</h5>
                        <a type="button" style="color: #000000;" class="close" (click)="closeModal()"
                            data-dismiss="modal" aria-label="Close">
                            <img src="../../../../../assets/img/icon/exitbtn.png"></a>
                    </div>

                </div>
                <div class="modal-body pt-4 pb-0 rounded-0" style="background: #ffffff;color: #000000;">
                    <div style="border:1px solid  #e4e4e4;" class="row m-0">
                        <div class="col-md-3 tit-mod-sen-lin-001">
                            Short URL
                        </div>
                        <div class="col-md-9 del-mod-sen-lin-001 d-flex align-items-center">
                            <label class="tex-det-mod-sen-lin-001">https://www.ibizsoftware.com/Nexxc</label>
                            <button class="ml-2 btn btn-small-long btn-white"> 복사 </button>
                        </div>
                        <div class="col-md-3 tit-mod-sen-lin-001">
                            계약서 명
                        </div>
                        <div class="col-md-9 del-mod-sen-lin-001 d-flex align-items-center">
                            <label class="tex-det-mod-sen-lin-001">표준근로계약서 2020_CP</label>
                        </div>
                        <div class="col-md-3 tit-mod-sen-lin-001">
                            만료일
                        </div>
                        <div class="col-md-9 del-mod-sen-lin-001 d-flex align-items-center">
                            <label class="tex-det-mod-sen-lin-001">2020/04/30</label>
                        </div>
                        <div class="col-md-3 tit-mod-sen-lin-001">
                            비밀번호
                        </div>
                        <div class="col-md-9 d-flex del-mod-sen-lin-001 d-flex align-items-center">
                            <select class="form-control sel-mod-sen-lin-001">
                                <option>
                                    사용
                                </option>
                            </select>
                            <input type="text" class="ml-2 inp-mod-sen-lin-001 form-control" placeholder="비밀번호">
                        </div>
                        <div class="col-md-3 border-0 tit-mod-sen-lin-001">
                            모바일 인증
                        </div>
                        <div class="col-md-9 del-mod-sen-lin-001 d-flex align-items-center">
                            <select class=" form-control sel-mod-sen-lin-001">
                                <option>
                                    미사용
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer rounded-0 border-0 py-4 d-flex justify-content-center"
                    style="background: #ffffff;color: #000000;">
                    <button type="button" class="btn btn-middle-short btn-white"
                        (click)="onClickBtn('btnCancel')">취소</button>
                    <button type="button" class="btn btn-middle-short btn-black"
                        (click)="onClickBtn('btnConfirm')">확인</button>
                </div>
            </div>
        </div>
    </div>
</div>