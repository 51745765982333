<section class="container-fluid" style="background: #E9ECEE;">

    <div class="container-lg2 py-5">


        <div class="row">
            <div class="col-12">
                <h1 class="tit-pag-up">
                    <span>제공된 서식으로 만들기</span>
                    <img src="../../../../assets/img/icon/icon-arrow-right-cr.png" class="img-fluid mx-3">
                    <span class="font-weight-700 barumBold">차용증 </span>

                </h1>
            </div>
        </div>
        <hr>

        <form [formGroup]=formContractWord id="formContractWord">
            <div class="row">
                <div class="col-12">
                    <hr style="margin:30px 0px 15px 0px; border: 2px solid #f82644; width: 25px; background: #f82644;">
                    <h3 class="formHeading">
                        계약서 정보 입력</h3>
                </div>
            </div>

            <!-- -******- -->
            <div class="row">
                <div class="col-5">
                    <div class="inlineselects row no-gutters">
                        <div class="col-12"> <label>차용일자</label></div>
                        <div class="">
                            <div class="form-inline">
                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="bdy" formControlName='{bdy}' id="bdy"
                                            class="form-control {{valid.bdy}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let year of data.fiveYears;let i = index"
                                                value={{year.year}}>{{year.year}}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">년</span>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="bdm" formControlName='{bdm}' id="bdm"
                                            class="form-control {{valid.bdm}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let month of data.months;let i = index"
                                                value={{month.value}}>{{month.month}}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">월</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="bdd" formControlName='{bdd}' id="bdd"
                                            class="form-control {{valid.bdd}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let d of data.bdd;let i = index" value={{d.value}}>
                                                {{d.day}}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">일</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="borra">차용금액</label>
                        <div class="input-group">
                            <input style="border-radius: 4px;" type="text" pattern="^-?(\D+)?$" maxlength="9"
                                class="form-control {{valid.borra}}" formControlName='{borra}' id="borra" name="borra"
                                placeholder="차용금액" aria-describedby="borra">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent border-0">원정</span>
                            </div>
                        </div>
                        <small *ngIf="small.borra" class="form-text text-muted text-validators {{formContractWord.get('{borra}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                문자만 입력해 주세요.
                        </small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="pborra">&nbsp;</label>
                        <div class="input-group">
                            <input style="border-radius: 4px;" type="text" pattern="^[0-9]{1,3}([,.][0-9]{1,3})+(.[0-9]{1,2})?$"
                                class="form-control {{valid.pborra}}" formControlName='{pborra}' id="pborra"
                                (change)='setFormaNumber()' name="pborra" placeholder="₩" aria-describedby="pborra">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent border-0">원</span>
                            </div>
                        </div>
                        <small *ngIf="small.pborra" class="form-text text-muted text-validators {{formContractWord.get('{pborra}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                숫자만 입력해 주세요.
                        </small>
                    </div>
                </div>
            </div>

            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="pob">차용목적
                        </label>
                        <input type="text" pattern="^-?(\D+)?$" class="form-control  {{valid.pob}}" maxlength="35"
                            formControlName='{pob}' id="pob" name="pob" placeholder="예) 생활비" aria-describedby="pob">
                        <small *ngIf="small.pob" class="form-text text-muted text-validators {{formContractWord.get('{pob}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                문자만 입력해 주세요.
                        </small>
                    </div>
                </div>
            </div>

            <!-- -******- -->
            <div class="row">
                <div class="col-5">
                    <div class="inlineselects row no-gutters">
                        <div class="col-12"> <label>원금변제기일</label></div>
                        <div class="">
                            <div class="form-inline">
                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="ppdy" formControlName='{ppdy}' id="ppdy"
                                            class="form-control {{valid.ppdy}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let year of data.fiveYears;let i = index"
                                                value={{year.year}}>{{year.year}}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">년</span>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="ppdm" formControlName='{ppdm}' id="ppdm"
                                            class="form-control {{valid.ppdm}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let month of data.months;let i = index"
                                                value={{month.value}}>{{month.month}}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">월</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="ppdd" formControlName='{ppdd}' id="ppdd"
                                            class="form-control {{valid.ppdd}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let d of data.ppdd;let i = index" value={{d.value}}>
                                                {{d.day}}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">일</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="inte">이자율 </label>
                        <div class="input-group">
                            <input style="border-radius: 4px;" type="text" pattern="^-?([0-9]\d*)?$" maxlength="7"
                                class="form-control {{valid.inte}}" formControlName='{inte}' id="inte" name="inte"
                                placeholder="0" aria-describedby="inte">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent border-0">%</span>
                            </div>
                        </div>
                        <small *ngIf="small.inte" class="form-text text-muted text-validators {{formContractWord.get('{inte}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                숫자만 입력해 주세요.
                        </small>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="dpay">이자 지급일</label>
                        <div class="input-group">
                            <select pattern="^-?([0-9]\d*)?$" for="dpay" formControlName='{dpay}' id="dpay" class="form-control {{valid.dpay}}">
                                <option value="N">선택</option>
                                <option *ngFor="let d of data.dpays;let i = index" value={{d.value}}>{{d.day}}</option>
                            </select>
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent border-0">일</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- -******- -->
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="dsb">채무변제 은행</label>
                        <input type="text" pattern="^-?(\D+)?$" class="form-control  {{valid.dsb}}" maxlength="7"
                            formControlName='{dsb}' id="dsb" name="dsb" placeholder="입금 은행명" id="dsb"
                            aria-describedby="dsb">
                        <small *ngIf="small.dsb" class="form-text text-muted text-validators {{formContractWord.get('{dsb}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                문자만 입력해 주세요.
                        </small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="an">계좌번호</label>
                        <input type="text" pattern="^-?([0-9]\d*)?$" class="form-control  {{valid.an}}" maxlength="14"
                            formControlName='{an}' id="an" name="an" placeholder="입금 계좌번호" id="an" aria-describedby="an">
                        <small *ngIf="small.an" class="form-text text-muted text-validators {{formContractWord.get('{an}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                숫자만 입력해 주세요.
                                
                        </small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="ah">예금주</label>
                        <input type="text" pattern="^-?(\D+)?$" class="form-control  {{valid.ah}}" maxlength="7"
                            formControlName='{ah}' id="ah" name="ah" placeholder="예금주" id="ah" aria-describedby="ah">
                        <small *ngIf="small.ah" class="form-text text-muted text-validators {{formContractWord.get('{ah}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                문자만 입력해 주세요.
                        </small>
                    </div>
                </div>
            </div>

            <!-- -******- -->
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="lad">지체 이자/(일)</label>
                        <div class="input-group">
                            <input type="text" pattern="^-?([0-9]\d*)?$" class="form-control  {{valid.lad}}" maxlength="3"
                                formControlName='{lad}' id="lad" name="lad" placeholder="0" id="lad"
                                aria-describedby="lad">
                            <div class="input-group-append">
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <small *ngIf="small.lad" class="form-text text-muted text-validators {{formContractWord.get('{lad}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                숫자만 입력해 주세요.
                        </small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="lad">지체 횟수</label>
                        <div class="input-group">
                            <input type="text" pattern="^-?([0-9]\d*)?$" class="form-control  {{valid.nod}}" maxlength="10"
                                formControlName='{nod}' id="nod" name="nod" placeholder="0" id="nod"
                                aria-describedby="nod">
                            <div class="input-group-append">
                                <span class="input-group-text">회</span>
                            </div>
                        </div>
                        <small *ngIf="small.nod" class="form-text text-muted text-validators {{formContractWord.get('{nod}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                숫자만 입력해 주세요.
                        </small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-5">
                    <div class="inlineselects row no-gutters">
                        <div class="col-12"> <label>계약일</label></div>
                        <div class="">
                            <div class="form-inline">
                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="cdy" formControlName='{cdy}' id="cdy"
                                            class="form-control {{valid.cdy}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let year of data.fiveYears;let i = index"
                                                value={{year.year}}>{{year.year}}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">년</span>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="cdm" formControlName='{cdm}' id="cdm"
                                            class="form-control {{valid.cdm}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let month of data.months;let i = index"
                                                value={{month.value}}>{{month.month}}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">월</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group  ">
                                    <div class="input-group">
                                        <select pattern="^-?([0-9]\d*)?$" for="cdd" formControlName='{cdd}' id="cdd"
                                            class="form-control {{valid.cdd}}">
                                            <option value="N">선택</option>
                                            <option *ngFor="let d of data.cdd;let i = index" value={{d.value}}>
                                                {{d.day}}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">일</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="cnm">채권자 성명</label>
                        <input type="text" pattern="^-?(\D+)?$" class="form-control  {{valid.cnm}}" maxlength="10"
                            formControlName='{cnm}' id="cnm" name="cnm" placeholder="성명" aria-describedby="cnm">
                        <small *ngIf="small.cnm" class="form-text text-muted text-validators {{formContractWord.get('{cnm}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                문자만 입력해 주세요.
                        </small>
                    </div>
                </div>
            </div>



            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="caddr">채권자 주소</label>
                        <input type="text" class="form-control  {{valid.caddr}}" maxlength="40"
                            formControlName='{caddr}' id="caddr" name="caddr" placeholder="주소" aria-describedby="caddr">
                        <small *ngIf="small.caddr" class="form-text text-muted text-validators {{formContractWord.get('{caddr}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                필수 입력값을 정확하게 입력해 주세요
                        </small>
                    </div>
                </div>
            </div>


            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="cssn">채권자 주민번호</label>
                        <input type="text" pattern="^-?([0-9]\d*)?$" class="form-control  {{valid.cssn}}"
                            formControlName='{cssn}' id="cssn" name="cssn" minlength="13" maxlength="13"
                            placeholder="주민번호" aria-describedby="cssn">
                        <small *ngIf="small.cssn" class="form-text text-muted text-validators {{formContractWord.get('{cssn}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                주민번호 13 자리를 입력해 주세요 (숫자만 입력)
                        </small>
                    </div>
                </div>
            </div>


            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="cci">채권자 연락처</label>
                        <input type="text" pattern="^-?([0-9]\d*)?$" class="form-control  {{valid.cci}}"
                            formControlName='{cci}' id="cci" name="cci" minlength="10" maxlength="11" placeholder="연락처"
                            aria-describedby="cci">
                        <small *ngIf="small.cci" class="form-text text-muted text-validators {{formContractWord.get('{cci}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                10 - 11 자리를 입력해 주세요 (숫자만 입력)
                        </small>
                    </div>
                </div>
            </div>


            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="dsn">채무자 성명</label>
                        <input type="text" pattern="^-?(\D+)?$" class="form-control  {{valid.dsn}}" maxlength="10"
                            formControlName='{dsn}' id="dsn" name="dsn" placeholder="성명" aria-describedby="dsn">
                        <small *ngIf="small.dsn" class="form-text text-muted text-validators {{formContractWord.get('{dsn}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                문자만 입력해 주세요.
                        </small>
                    </div>
                </div>
            </div>


            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="dadd">채무자 주소</label>
                        <input type="text" class="form-control  {{valid.dadd}}" maxlength="40"
                            formControlName='{dadd}' id="dadd" name="dadd" placeholder="주소" aria-describedby="dadd">
                        <small *ngIf="small.dadd" class="form-text text-muted text-validators {{formContractWord.get('{dadd}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                필수 입력값을 정확하게 입력해 주세요
                        </small>
                    </div>
                </div>
            </div>


            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="dssn">채무자 주민번호</label>
                        <input type="text" pattern="^-?([0-9]\d*)?$" class="form-control  {{valid.dssn}}"
                            formControlName='{dssn}' id="dssn" name="dssn" placeholder="주민번호" minlength="13"
                            maxlength="13" aria-describedby="dssn">
                        <small *ngIf="small.dssn" class="form-text text-muted text-validators {{formContractWord.get('{dssn}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                주민번호 13 자리를 입력해 주세요 (숫자만 입력)
                        </small>
                    </div>
                </div>
            </div>


            <!-- -******- -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="dci">채무자 연락처</label>
                        <input type="text" pattern="^-?([0-9]\d*)?$" class="form-control  {{valid.dci}}"
                            formControlName='{dci}' id="dci" name="dci" placeholder="연락처" minlength="10" maxlength="11"
                            aria-describedby="dci">
                        <small *ngIf="small.dci" class="form-text text-muted text-validators {{formContractWord.get('{dci}').status}} font-16">
                            <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                                alt="alert icon">
                                10 - 11 자리를 입력해 주세요 (숫자만 입력)
                        </small>
                    </div>
                </div>
            </div>

            <!-- end of form -->
        </form>
        <!-- -******- -->
        <div style="margin-top:50px;" class="row">
            <div class="col-12 d-flex justify-content-center">
                <button [disabled]="!formContractWord.valid" type="button" style="width:auto !important;"
                    (click)="onClickBtnCreateContractWord()" class="btn btn-big btn-crimson">
                    다음
                </button>
            </div>
        </div>
    </div>

</section>

<app-alert-shared></app-alert-shared>