import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateManagementService {
  data = {
    data: {
      frstRegisNo: "",
      serachType: "",
      text: ""
    },
    pageNo: 0,
    pageSize: 5
  }
  constructor() { }

  setSerachType(type: string) {
    this.data.data.serachType = type;
  }

  getSerachType() {
    return this.data.data.serachType;
  }

  setText(text: string) {
    this.data.data.text = text;
  }

  getText() {
    return this.data.data.text;
  }

  setPageNo(pageNo: number) {
    this.data.pageNo = pageNo;
  }

  getPageNo() {
    return this.data.pageNo;
  }

  setPageSize(pageSize: number) {
    this.data.pageSize = pageSize;
  }

  getPageSize() {
    return this.data.pageSize;
  }

  getData() {
    return this.data;
  }
}
