<div class="row-hl btnnav justify-content-center d-flex">
    <div class="w-100">

        <div class="notict-list-box mx-auto ">

            <form class="text-center mx-auto" style="width: 100%; max-width: 500px; " [formGroup]=formCommunityUpload>


                <div class="inquire">


                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="postType">분류</label>
                                <select class="form-control {{validators.postType}}" formControlName=postType id="postType" placeholder="분류 입력">
                                    <option value="" selected="selected">선택</option>
                                    <option *ngFor="let type of postType" value="{{type.code}}">{{type.codeNm}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="postNm">계약서명</label>
                                <input type="postNm" class="form-control {{validators.postNm}}" formControlName=postNm
                                       id="postNm" placeholder="계약서명 입력">

                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="postContent">상세내용</label>
                                <textarea id="textArea" class="form-control {{validators.postContent}}" formControlName=postContent
                                          style="max-height: 125px; min-height: 125px;" placeholder="200자 이내 입력" cols="30" rows="10" (keyup)="checkCharacters()"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="mb-3 text-left">
                                <label for="inquireTxtarea">첨부파일</label>
                                <app-community-upload-form *ngIf="upload == false"></app-community-upload-form>
                                <div class="input-group"  *ngIf="upload">
                                    <input id="searchinput" type="text" class="form-control" formControlName="fileNm" value="{{validators.fileNm}}"/>
                                    <input type="hidden" class="form-control" formControlName="fileId" value="{{validators.fileId}}"/>
                                    <input type="hidden" class="form-control" formControlName="thumbNailUrl" value="{{validators.thumbNailUrl}}"/>
                                    <input type="hidden" class="form-control" formControlName="pageNum" value="{{validators.pageNum}}"/>
                                    <span id="searchclear" (click)="btnFileCancel()">X</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group mb-0 row d-flex justify-content-center">
                    <div class="col-12">
                        <button (click)="onSend()" type="button" class="btn btn-big crimsonbg" [disabled]="!formCommunityUpload.valid">
                            올리기
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-alert-shared></app-alert-shared>
<app-loading></app-loading>
