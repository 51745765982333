
<div class="organizepageheight container-lg ml-lg-0 mr-lg-auto p-0">
  <h3 _ngcontent-yjk-c119="" class="tit-tem-man-001 barumBold pag-tit" style="margin-bottom: 47px;">조직 관리</h3>

  <div class="row">

    <div class="col-xl-4">
      <app-team-member-management [sendApiComplete]="sendApiComplete" (selectDepartment)="selectDepartment($event)"
        (selectTeam)="selectTeam($event)" (selectSearch)="selectSearch($event)"></app-team-member-management>
    </div>

    <div class="col-xl-8">
      <app-import-member [select]="selectData" (statusSendApiCompleted)="statusSendApiCompleted($event)">
      </app-import-member>
    </div>

  </div>
</div>

<app-modal-import-user-teams></app-modal-import-user-teams>
<app-alert></app-alert>
<app-alert-shared></app-alert-shared>
<app-loading></app-loading>
<app-alert-confirm></app-alert-confirm>