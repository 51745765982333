import { Component, OnInit } from '@angular/core';
import { AlertSharedService } from './alert-shared.service';

@Component({
  selector: 'app-alert-shared',
  templateUrl: './alert-shared.component.html',
  styleUrls: ['./alert-shared.component.scss']
})
export class AlertSharedComponent implements OnInit {
  show: boolean = false;
  state: string;

  modal: {
    herder: {
      title: string,
      btnClose: boolean
    },
    body: {
      title: string,
      subTitle: string
    },
    footer: {
      btn: {
        btnType: any
        btnNm: string,
        btnStyle: string,
        btnFunc: any
      }[]
    }
  } =
    {
      herder: {
        title: null,
        btnClose: true
      },
      body: {
        title: null,
        subTitle: null
      },
      footer: {
        btn: []
      }
    }

  constructor(
    private alertSharedService: AlertSharedService,
  ) { }

  ngOnInit(): void {
    this.show = this.alertSharedService.getStatusShowModal();
  }

  ngDoCheck() {
    this.show = this.alertSharedService.getStatusShowModal();
    this.modal.herder = this.alertSharedService.getHeaderModal();
    this.modal.body = this.alertSharedService.getBodyModal();
    this.modal.footer = this.alertSharedService.getFooterModal();
  }

  closeModalBackground($event) {
    if ($event.target.className.includes("popUpType1") && this.modal.herder.btnClose) {
      this.alertSharedService.close();
    }
  }

  handleAccept(btnType: any) {
    this.modal.footer.btn.find(items => items['btnType'] === btnType).btnFunc();
    this.alertSharedService.close();
  }

  closeModal() {
    this.alertSharedService.close();
  }
}
