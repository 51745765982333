import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
//->jerrick import { NgxPageScrollModule } from 'ngx-page-scroll';
//->jerrick { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarModule } from 'ng-sidebar';
import { environment } from '../environments/environment';
// Import angular-cropperjs
import { AngularCropperjsModule } from 'angular-cropperjs';

import { AppComponent } from './app.component';
import { NavbarComponent } from './frontpage/navbar/navbar.component';
import { HeaderComponent } from './frontpage/header/header.component';
import { FooterComponent } from './frontpage/footer/footer.component';
import { AboutComponent } from './frontpage/about/about.component';
import { ProjectsComponent } from './frontpage/projects/projects.component';
import { ContactComponent } from './frontpage/contact/contact.component';
import { SignupComponent } from './frontpage/signup/signup.component';
import { HomeComponent } from './frontpage/home/home.component';
import { LoginComponent } from './dashboard/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { DashboardDashboardComponent } from './dashboard/dashboard-dashboard/dashboard-dashboard.component';
import { DashboardSidebarComponent } from './dashboard/dashboard-sidebar/dashboard-sidebar.component';
import { DashboardNavbarComponent } from './dashboard/dashboard-navbar/dashboard-navbar.component';
import { DashboardCodebookComponent } from './dashboard/dashboard-codebook/dashboard-codebook.component';
import { DashboardUserComponent } from './dashboard/dashboard-user/dashboard-user.component';
import { DashboardRoleComponent } from './dashboard/dashboard-role/dashboard-role.component';
import { DashboardPositionComponent } from './dashboard/dashboard-position/dashboard-position.component';
import { TreeCustomerComponent } from './test/tree-customer/tree-customer.component';
import { ServiceComponent } from './frontpage/service/service.component';
import { FeaturesComponent } from './frontpage/features/features.component';
import { TestimonialComponent } from './frontpage/testimonial/testimonial.component';
import { CustomerComponent } from './frontpage/customer/customer.component';
import { PackageComponent } from './frontpage/package/package.component';
import { NewsidebarComponent } from './dashboard/newsidebar/newsidebar.component';
import { DashboardProfileComponent } from './dashboard/dashboard-profile/dashboard-profile.component';
import { ToolsEditorPdfComponent } from './dashboard/tools/tools-editor-pdf/tools-editor-pdf.component';
import { ToolsSignaturePadComponent } from './dashboard/tools/tools-signature-pad/tools-signature-pad.component';
import { ToolsViewPdfComponent } from './dashboard/tools/tools-view-pdf/tools-view-pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HttpClientModule } from '@angular/common/http';
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { SidebarComponent } from './manage/component/sidebar/sidebar.component';
import { BasicDescriptionComponent } from './manage/component/basic-description/basic-description.component';
import { UploadFilePdfComponent } from './dashboard/tools/upload-file-pdf/upload-file-pdf/upload-file-pdf.component';
import { PageComponent } from './manage/component/page/page.component';
import { HeaderComponent as HeaderComponentmanage } from '../app/manage/component/header/header.component';
import { SidebarComponent as SidebarComponentmanage } from '../app/manage/component/sidebar/sidebar.component';
import { CreatingElectronicComponent } from './manage/component/creating-electronic/creating-electronic.component';
import { DocumentUploadComponent } from './manage/component/document-upload/document-upload.component';
import { NavberComponent as NavbarComponentDemo } from './manage/component/navbar/navber.component';
import { EditorPdfComponent } from './manage/component/editor-pdf/editor-pdf.component';
import { SendingRecipientsComponent } from './manage/component/sending-recipients/sending-recipients.component';
import { ContractInformationComponent } from './manage/component/contract-information/contract-information.component';
import { ModalIdentityCertificateComponent } from './manage/component/modal/modal-identity-certificate/modal-identity-certificate.component';
import { RegisterComponent } from './frontpage/register/register.component';
import { PageRegisterComponent } from './frontpage/page-register/page-register.component';
import { PageLoginComponent } from './frontpage/page-login/page-login.component';
import { LoginComponent as LoginComponentFront } from './frontpage/login/login.component';
import { ForgotPasswordComponent } from './frontpage/forgot-password/forgot-password.component';
import { PageForgotPasswordComponent } from './frontpage/page-forgot-password/page-forgot-password.component';
import { PageVerificationRegisterComponent } from './frontpage/page-verification-register/page-verification-register.component';
import { VerificationRegisterComponent } from './frontpage/verification-register/verification-register.component';
import { CongratulationsJoiningComponent } from './frontpage/congratulations-joining/congratulations-joining.component';
import { PageCongratulationsJoiningComponent } from './frontpage/page-congratulations-joining/page-congratulations-joining.component';
import { ElementItemComponent } from './element-item/element-item.component';
import { DashboardComponent } from './manage/component/dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { ModalComponent } from './manage/component/modal/modal.component';
import { ExcelTransferComponent } from './manage/component/excel-transfer/excel-transfer.component';
import { TableDataComponent } from './manage/component/table-data/table-data.component';
import { SelectContractTypeComponent } from './manage/component/select-contract-type/select-contract-type.component';
import { SelectRecipientsComponent } from './manage/component/select-recipients/select-recipients.component';
import { TemplateManagementComponent } from './manage/component/template-management/template-management.component';
import { ContractManagementComponent } from './manage/component/contract-management/contract-management.component';
import { TableRequestedComponent } from './manage/component/table-requested/table-requested.component';
import { TableRequestedMeComponent } from './manage/component/table-requested-me/table-requested-me.component';
import { InformationManageComponent } from './manage/component/information-manage/information-manage.component';
import { SignatureManagementComponent } from './manage/component/signature-management/signature-management.component';
import { ModalAddSignatureComponent } from './manage/component/modal/modal-add-signature/modal-add-signature.component';
import { SignatureComponent } from './manage/component/signature/signature.component';
import { PaymentManagementComponent } from './manage/component/payment-management/payment-management.component';
import { PaymentHistoryComponent } from './manage/component/payment-history/payment-history.component';
import { RequestRefundComponent } from './manage/component/request-refund/request-refund.component';
import { RefundDetailsComponent } from './manage/component/refund-details/refund-details.component';
import { InquiryManagemantComponent } from './manage/component/inquiry-managemant/inquiry-managemant.component';
import { InquiryComponent } from './manage/component/inquiry/inquiry.component';
import { ContactPageInquiryComponent } from './manage/component/contact-page-inquiry/contact-page-inquiry.component';
import { MailTemplateManagementComponent } from './manage/component/mail-template-management/mail-template-management.component';
import { MailManagementComponent } from './manage/component/mail-management/mail-management.component';
import { MailRegistrationComponent } from './manage/component/mail-registration/mail-registration.component';
import { OrganizationManagementComponent } from './manage/component/organization-management/organization-management.component';
import { TeamMemberManagementComponent } from './manage/component/team-member-management/team-member-management.component';
import { ImportMemberComponent } from './manage/component/import-member/import-member.component';
import { ModalTeamMemberComponent } from './manage/component/modal/modal-team-member/modal-team-member.component';
import { PaginationComponent } from './manage/component/pagination/pagination.component';
import { AlertDeleteComponent } from './manage/component/modal/alert-delete/alert-delete.component';
import { AlertConfirmComponent } from './manage/component/modal/alert-confirm/alert-confirm.component';
import { AlertSaveComponent } from './manage/component/modal/alert-save/alert-save.component';
import { AlertSaveNameTemplateComponent } from './manage/component/modal/alert-save-name-template/alert-save-name-template.component';
import { SendLinkComponent } from './manage/component/modal/send-link/send-link.component';
import { ElectronicContractDetailsComponent } from './manage/component/modal/electronic-contract-details/electronic-contract-details.component';
import { ContractCancellationComponent } from './manage/component/modal/contract-cancellation/contract-cancellation.component';
import { LayoutDashboardComponent } from './manage/layout/layout-dashboard/layout-dashboard.component';
import { ElectronicContractAuthenticationCompletedComponent } from './manage/component/electronic-contract-authentication-completed/electronic-contract-authentication-completed.component';
import { SubmitCertificateComponent } from './manage/component/modal/submit-certificate/submit-certificate.component';
import { LayoutEditorComponent } from './manage/layout/layout-editor/layout-editor.component';
import { LayoutMainContractComponent } from './manage/layout/layout-main-contract/layout-main-contract.component';
import { LoadingComponent } from './manage/shared/loading/loading.component';

import { JwtModule } from '@auth0/angular-jwt';
import TokenUtils from './manage/shared/token/token-utils';
import { NavbarViewComponent } from './manage/component/navbar-view/navbar-view.component';
import { LayoutViewComponent } from './manage/layout/layout-view/layout-view.component';
import { LoadingProgressComponent } from './manage/shared/loading-progress/loading-progress.component';
import { AlertComponent } from './manage/component/modal/alert/alert.component';
import { RecipientComponent } from './manage/component/recipient/recipient/recipient.component';
import { RecipientConfirmationEmailComponent } from './manage/component/recipient/recipient-confirmation-email/recipient-confirmation-email.component';
import { RecipientConfirmationPhoneComponent } from './manage/component/recipient/recipient-confirmation-phone/recipient-confirmation-phone.component';
import { RecipientConfirmationPasswordComponent } from './manage/component/recipient/recipient-confirmation-password/recipient-confirmation-password.component';
import { RecipientConfirmationSelectTypeUserComponent } from './manage/component/recipient/recipient-confirmation-select-type-user/recipient-confirmation-select-type-user.component';
import { RecipientConfirmationConsentWriteComponent } from './manage/component/recipient/recipient-confirmation-consent-write/recipient-confirmation-consent-write.component';
import { LayoutRecipientComponent } from './manage/layout/layout-recipient/layout-recipient.component';
import { CallbackComponent } from './frontpage/login-callback/callback/callback.component';
import { CreateNewPasswordComponent } from './frontpage/create-new-password/create-new-password.component';
import { PageServiceCenterComponent } from './frontpage/page-service-center/page-service-center.component';
import { NoticeListComponent } from './frontpage/notice-list/notice-list.component';
import { NoticeDetailComponent } from './frontpage/notice-detail/notice-detail.component';
import { ContractInquireComponent } from './frontpage/contract-inquire/contract-inquire.component';
import { FrequentlyAskedQuestionsComponent } from './frontpage/frequently-asked-questions/frequently-asked-questions.component';
import { InquiryDetailComponent } from './manage/component/inquiry-detail/inquiry-detail.component';
import { MailDetailComponent } from './manage/component/mail-detail/mail-detail.component';
import { ModalGallerySignatureComponent } from './manage/component/modal/modal-gallery-signature/modal-gallery-signature.component';
import { ModalAttachmentFileComponent } from './manage/component/modal/modal-attachment-file/modal-attachment-file.component';
import { ModalImportUserTeamsComponent } from './manage/component/modal/modal-import-user-teams/modal-import-user-teams.component';
import { ContractInformationParttimeComponent } from './manage/component/contract-information-parttime/contract-information-parttime.component';
import { ContractInformationRealestateComponent } from './manage/component/contract-information-realestate/contract-information-realestate.component';
import { ContractInformationBorrowingComponent } from './manage/component/contract-information-borrowing/contract-information-borrowing.component';
import { AlertSharedComponent } from './manage/shared/modal/alert-shared/alert-shared.component';
import { ModalRefundPaymentComponent } from './manage/component/modal/modal-refund-payment/modal-refund-payment.component';
import { UploadCertificationComponent } from './manage/component/certification/upload-certification/upload-certification.component';
import { EditorCertificationComponent } from './manage/component/certification/editor-certification/editor-certification.component';
import { PaymentApplyComponent } from './manage/component/modal/payment-apply/payment-apply.component';
import { AddCommaPipe } from './manage/component/pipe/add-comma.pipe';
import { PayingComponent } from './manage/component/paying/paying.component';
import { TermsOfServiceComponent } from './frontpage/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './frontpage/privacy-policy/privacy-policy.component';
import { HowToUseComponent } from './frontpage/how-to-use/how-to-use.component';
import { TableRequestedBlockCertComponent } from './manage/component/table-requested-block-cert/table-requested-block-cert.component';
import { BlockCertDetailsComponent } from './manage/component/modal/block-cert-details/block-cert-details.component';
import { ModalNoticeComponent } from './manage/component/modal/modal-notice/modal-notice.component';
import { CommunityComponent } from './frontpage/community/community.component';
import { CommunityListComponent } from './frontpage/community-list/community-list.component';
import { CommunityUploadComponent } from './frontpage/community-upload/community-upload.component';
import { CommunityUploadFormComponent } from './frontpage/community-upload-form/community-upload-form.component';
import { TooltipPdfPreviewComponent } from './manage/component/tooltip-Pdf-Preview/tooltip-pdf-preview/tooltip-pdf-preview.component';


export function jwtTokenGetter() {
  return TokenUtils.getToken();
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ProjectsComponent,
    ContactComponent,
    SignupComponent,
    HomeComponent,
    LoginComponent,
    DashboardHomeComponent,
    DashboardDashboardComponent,
    DashboardSidebarComponent,
    DashboardNavbarComponent,
    DashboardCodebookComponent,
    DashboardUserComponent,
    DashboardRoleComponent,
    DashboardPositionComponent,
    TreeCustomerComponent,
    ServiceComponent,
    FeaturesComponent,
    TestimonialComponent,
    CustomerComponent,
    PackageComponent,
    NewsidebarComponent,
    DashboardProfileComponent,
    ToolsEditorPdfComponent,
    ToolsSignaturePadComponent,
    ToolsViewPdfComponent,
    SidebarComponent,
    BasicDescriptionComponent,
    UploadFilePdfComponent,
    PageComponent,
    HeaderComponentmanage,
    SidebarComponentmanage,
    CreatingElectronicComponent,
    DocumentUploadComponent,
    NavbarComponentDemo,
    EditorPdfComponent,
    SendingRecipientsComponent,
    ContractInformationComponent,
    ModalIdentityCertificateComponent,
    RegisterComponent,
    PageRegisterComponent,
    PageLoginComponent,
    LoginComponentFront,
    ForgotPasswordComponent,
    PageForgotPasswordComponent,
    PageVerificationRegisterComponent,
    VerificationRegisterComponent,
    CongratulationsJoiningComponent,
    PageCongratulationsJoiningComponent,
    ElementItemComponent,
    DashboardComponent,
    ModalComponent,
    ExcelTransferComponent,
    TableDataComponent,
    SelectContractTypeComponent,
    SelectRecipientsComponent,
    TemplateManagementComponent,
    ContractManagementComponent,
    TableRequestedComponent,
    TableRequestedMeComponent,
    InformationManageComponent,
    SignatureManagementComponent,
    ModalAddSignatureComponent,
    SignatureComponent,
    PaymentManagementComponent,
    PaymentHistoryComponent,
    RequestRefundComponent,
    RefundDetailsComponent,
    InquiryManagemantComponent,
    InquiryComponent,
    ContactPageInquiryComponent,
    MailTemplateManagementComponent,
    MailManagementComponent,
    MailRegistrationComponent,
    OrganizationManagementComponent,
    TeamMemberManagementComponent,
    ImportMemberComponent,
    ModalTeamMemberComponent,
    PaginationComponent,
    AlertDeleteComponent,
    AlertConfirmComponent,
    AlertSaveComponent,
    AlertSaveNameTemplateComponent,
    SendLinkComponent,
    ElectronicContractDetailsComponent,
    ContractCancellationComponent,
    LayoutDashboardComponent,
    ElectronicContractAuthenticationCompletedComponent,
    SubmitCertificateComponent,
    LayoutEditorComponent,
    LayoutMainContractComponent,
    LoadingComponent,
    NavbarViewComponent,
    LayoutViewComponent,
    LoadingProgressComponent,
    AlertComponent,
    RecipientComponent,
    RecipientConfirmationEmailComponent,
    RecipientConfirmationPhoneComponent,
    RecipientConfirmationPasswordComponent,
    RecipientConfirmationSelectTypeUserComponent,
    RecipientConfirmationConsentWriteComponent,
    LayoutRecipientComponent,
    CallbackComponent,
    CreateNewPasswordComponent,
    PageServiceCenterComponent,
    NoticeListComponent,
    NoticeDetailComponent,
    ContractInquireComponent,
    FrequentlyAskedQuestionsComponent,
    InquiryDetailComponent,
    MailDetailComponent,
    ModalGallerySignatureComponent,
    ModalAttachmentFileComponent,
    ModalImportUserTeamsComponent,
    ContractInformationParttimeComponent,
    ContractInformationRealestateComponent,
    ContractInformationBorrowingComponent,
    AlertSharedComponent,
    ModalRefundPaymentComponent,
    UploadCertificationComponent,
    EditorCertificationComponent,
    PaymentApplyComponent,
    AddCommaPipe,
    PayingComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    HowToUseComponent,
    TableRequestedBlockCertComponent,
    BlockCertDetailsComponent,
    ModalNoticeComponent,
    CommunityComponent,
    CommunityListComponent,
    CommunityUploadComponent,
    CommunityUploadFormComponent,
    TooltipPdfPreviewComponent
  ],
  imports: [

    ChartsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    Angular2SignaturepadModule,
    AppRoutingModule,
   //->jerrick  NgxPageScrollModule,
   //->jerrick  NgxPageScrollCoreModule.forRoot({ duration: 800 }),
    FontAwesomeModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    SidebarModule.forRoot(),
    HttpClientModule,
    AngularCropperjsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        //whitelistedDomains: environment.whitelistedDomains,
        blacklistedRoutes: [new RegExp('.+\/oauth\/token')]
      }
    }),
  ],
  exports: [
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ProjectsComponent,
    ContactComponent,
    SignupComponent,
    HomeComponent,
    LoginComponent,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
