import { Component, OnInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TemplateService } from '../../service/template/template.service';
import { DigitalsignatureService } from '../../service/digitalSignature/digitalsignature.service';
import { Router } from '@angular/router';
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { LoadingProgressService } from "../../shared/loading-progress/loading-progress.service";
import { LoadingService } from '../../shared/loading/loading.service';
import { Observable } from 'rxjs';
import { AlertService } from '../modal/alert/alert.service';
import { LoginService } from '../../../frontpage/login/login.service';
import { NavbarService } from '../navbar/navbar.service';
@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {

  faSearch = faSearch;
  sidebar: any = 'document_upload';
  uploadForm: FormGroup;
  uploadfile: any;
  fileInfos: Observable<any>;
  uploadProgress = 0;
  upload: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private templateService: TemplateService,
    private router: Router,
    private editorPdfService: EditorPdfService,
    private digitalsignatureService: DigitalsignatureService,
    private loadingProgressService: LoadingProgressService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private loginService: LoginService,
    private navbarService: NavbarService,
  ) { }

  ngOnInit(): void {
    this.navbarService.setAction("uploadTemp");
    this.initDropZoneArea();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  onFileSelected(event) {
    let fileSize: number = 0;
    let file = event.target.files[0];
    fileSize = file.size;
    // Allowing file type
    var allowedExtensions = /(\.pdf|\.hwp|\.docx|\.xls|\.xlsx|\.ppt|\.pptx|\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(file.name)) {
      this.alertService.open(
        "지원하지 않는 파일 type 입니다.",
        "",
        {
          func: () => {
            this.alertService.close();
          }
        },
        {
          func: () => {
            this.alertService.close();
          }
        }
      );
    } else {
      console.log(fileSize)
      if (fileSize <= 1048576) {
        this.uploadForm.get('profile').setValue(file);
        this.createTemplate();
      } else {
        this.alertService.open(
          "문서 크기가 너무 큽니다.",
          "문서 크기는 1MB를 초과 할 수 없습니다.",
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }
    }
  }

  onFileSelect(event) {
    let fileSize: number = 0;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      fileSize = file.size;
      console.log(fileSize);

    }
    if (fileSize <= 1048576) {
    }
  }

  createTemplate() {
    this.upload = false;
    this.loadingProgressService.openLoading();
    const formData = new FormData();
    formData.append('files', this.uploadForm.get('profile').value);
    formData.append('userId', localStorage.getItem('userId'));
    this.digitalsignatureService.setSignature(formData).subscribe(rs => {
      if (rs.type === HttpEventType.UploadProgress) {
        let progress = Math.round(100 * rs.loaded / rs.total);
        this.loadingProgressService.setProgress(progress);
        this.loadingProgressService.setTextProgress(progress + '%');
      } else if (rs instanceof HttpResponse) {
        if (rs.body.status) {
          this.templateService.createTemplate(rs.body.data.fileId, rs.body.data.fileName, this.loginService.getProfileLogin().userIdNo).subscribe((rs: any) => {
            if (rs.status) {
              this.loadingProgressService.closeLoading();
              this.loadingProgressService.setProgress(0);
              this.loadingProgressService.setTextProgress("0%");
              this.editorPdfService.setTemplateId(rs.data.templId);
              this.editorPdfService.setTemplNm(rs.data.templNm);
              this.editorPdfService.setContorType('%');
              this.editorPdfService.setEdteMode("upload");
              this.editorPdfService.setState("init");
              this.editorPdfService.download = false;
              this.router.navigate(['create-template-contract']);
            }
          });
        } else {
          this.upload = true;
          this.loadingProgressService.closeLoading();
          this.loadingProgressService.setProgress(0);
          this.loadingProgressService.setTextProgress("0%");
        }
      }
    },
      err => {
        this.upload = true;
        this.loadingProgressService.closeLoading();
        this.loadingProgressService.setProgress(0);
        this.loadingProgressService.setTextProgress("0%");
      }
    );

  }


  // Drop Zone Initial
  initDropZoneArea() {
    const dropArea = document.getElementById('drop-area');
    console.log(dropArea);
    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, (e) => {
        // console.log(e);
        e.preventDefault();
        e.stopPropagation();
      }, false)
    })

    dropArea.addEventListener('drop', (e) => {
      console.log(e);
      let dt = e.dataTransfer
      let files = dt.files;
      let ev = {
        target: {
          files
        }
      }
      this.onFileSelected(ev);
    }, false);
  }
}
