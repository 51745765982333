import { Component, OnInit } from '@angular/core';
import { ContractCancellationService } from './contract-cancellation.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CodeBookService } from '../../../service/code-book/code-book.service';
@Component({
  selector: 'app-contract-cancellation',
  templateUrl: './contract-cancellation.component.html',
  styleUrls: ['./contract-cancellation.component.scss']
})
export class ContractCancellationComponent implements OnInit {
  show: boolean;
  htmlBody: string;
  state: string;

  constructor(
    private fb: FormBuilder,
    private contractCancellationService: ContractCancellationService,
    private codeBookService: CodeBookService,
  ) { }

  cancleTypes: {
    code: string,
    codeDc: string,
    codeGrpId: string,
    codeNm: string
  }[] = [];

  fromCancleContract: FormGroup;

  ngOnInit(): void {
    this.getCodeBook();
    this.initFromCancleContract();
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    if (this.contractCancellationService.change.show) {
      this.show = this.contractCancellationService.show;
      this.contractCancellationService.setChange('show', false);
    }
    if (this.contractCancellationService.change.html) {
      this.htmlBody = this.contractCancellationService.htmlBody;
      this.contractCancellationService.setChange('html', false);
    }
    if (this.contractCancellationService.change.state) {
      this.state = this.contractCancellationService.getState();
      this.fromCancleContract.get("contorCancle").setValue(0);
      this.fromCancleContract.get("message").setValue('');
      this.contractCancellationService.setChange('state', false);
    }
  }

  initFromCancleContract(): void {
    this.fromCancleContract = this.fb.group({
      contorCancle: [0],
      message: [null]
    });

    this.fromCancleContract.valueChanges.subscribe((valueChanges) => {
      this.contractCancellationService.setContractCancle(valueChanges.contorCancle);
      this.contractCancellationService.setMessage(valueChanges.message);
    });
  }

  getCodeBook(): void {
    this.codeBookService.getCodeBook("CONTOR_CANCLE").subscribe((rs: any) => {
      if (rs.status) {
        this.cancleTypes = rs.data;
      }
    });
  }


  closeModal() {
    this.contractCancellationService.close();
  }

  exportDataTable() {
    this.contractCancellationService.close();
  }

  onClickBtn(btn: any) {
    this.contractCancellationService[btn].func();
  }

  closeModalBackground($event) {
    console.log($event.target.className);
    if ($event.target.className.includes("modal fade show")) {
      this.contractCancellationService.close();
    }
  }

}
