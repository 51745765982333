<div class="row-hl btnnav justify-content-center d-flex">

    <div class="serviceBalance">
        <h2 class="text-center centerSubject w-100">공지사항
        </h2>

        <div class="notict-list-box bg-white mx-auto">

            <div class="subjectHeadtxt">
                <h4 class="">
                    {{item.noticTitle}}
                </h4>

                <p class="">등록일 : {{item.registerPnttm}} | 조회수 : {{item.noticNo}}</p>

            </div>

            <div class="infocontent">
                <p innerHTML="{{item.noticeContent}}">
                </p>
            </div>

        </div>



        <div class="row ">
            <div class="col-12 p-0 d-flex justify-content-center">
                <button (click)="onBack()" class="btn btn-big crimsonbg ">
                    목록
                </button>
            </div>
        </div>

    </div>

</div>