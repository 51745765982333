import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SelectContractTypeService } from '../select-contract-type/select-contract-type.service';
import { ContractInformationBorrowingService } from './contract-information-borrowing.service';
import { Router } from '@angular/router';
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { AlertService } from '../modal/alert/alert.service';
import { TemplateService } from '../../service/template/template.service';
import { LoadingService } from '../../shared/loading/loading.service';

import Regex from '../../shared/regex/regex';
import DateShared from '../../shared/date-shared/date-shared';
import StringFormat from '../../shared/string-format/string-format';

import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';

@Component({
  selector: 'app-contract-information-borrowing',
  templateUrl: './contract-information-borrowing.component.html',
  styleUrls: ['./contract-information-borrowing.component.scss']
})
export class ContractInformationBorrowingComponent implements OnInit {
  stringFormat: StringFormat;
  dateShared: DateShared;
  formContractWord: FormGroup;

  valid: any = {
    bdy: '',
    bdm: '',
    bdd: '',
    borra: '',
    pborra: '',
    pob: '',
    ppdy: '',
    ppdm: '',
    ppdd: '',
    inte: '',
    dpay: '',
    dsb: '',
    an: '',
    ah: '',
    lad: '',
    nod: '',
    cdy: '',
    cdm: '',
    cdd: '',
    cnm: '',
    caddr: '',
    cssn: '',
    cci: '',
    dsn: '',
    dadd: '',
    dssn: '',
    dci: ''
  };

  small: {
    bdy: boolean,
    bdm: boolean,
    bdd: boolean,
    borra: boolean,
    pborra: boolean,
    pob: boolean,
    ppdy: boolean,
    ppdm: boolean,
    ppdd: boolean,
    inte: boolean,
    dpay: boolean,
    dsb: boolean,
    an: boolean,
    ah: boolean,
    lad: boolean,
    nod: boolean,
    cdy: boolean,
    cdm: boolean,
    cdd: boolean,
    cnm: boolean,
    caddr: boolean,
    cssn: boolean,
    cci: boolean,
    dsn: boolean,
    dadd: boolean,
    dssn: boolean,
    dci: boolean
  } = {
      bdy: false,
      bdm: false,
      bdd: false,
      borra: false,
      pborra: false,
      pob: false,
      ppdy: false,
      ppdm: false,
      ppdd: false,
      inte: false,
      dpay: false,
      dsb: false,
      an: false,
      ah: false,
      lad: false,
      nod: false,
      cdy: false,
      cdm: false,
      cdd: false,
      cnm: false,
      caddr: false,
      cssn: false,
      cci: false,
      dsn: false,
      dadd: false,
      dssn: false,
      dci: false
    }

  inputs: {
    bdy: any,
    bdm: any,
    bdd: any,
    borra: any,
    pborra: any,
    pob: any,
    ppdy: any,
    ppdm: any,
    ppdd: any,
    inte: any,
    dpay: any,
    dsb: any,
    an: any,
    ah: any,
    lad: any,
    nod: any,
    cdy: any,
    cdm: any,
    cdd: any,
    cnm: any,
    caddr: any,
    cssn: any,
    cci: any,
    dsn: any,
    dadd: any,
    dssn: any,
    dci: any,
    btn: {
      next: any,
    }
  } = {
      bdy: '',
      bdm: '',
      bdd: '',
      borra: '',
      pborra: '',
      pob: '',
      ppdy: '',
      ppdm: '',
      ppdd: '',
      inte: '',
      dpay: '',
      dsb: '',
      an: '',
      ah: '',
      lad: '',
      nod: '',
      cdy: '',
      cdm: '',
      cdd: '',
      cnm: '',
      caddr: '',
      cssn: '',
      cci: '',
      dsn: '',
      dadd: '',
      dssn: '',
      dci: '',
      btn: {
        next: true
      }
    };

  data: {
    fiveYears: any[],
    months: any[],
    ppdd: any[],
    cdd: any[],
    bdd: any[],
    tdde: any[],
    hours: any[],
    minutes: any[],
    dpays: any[],
  } = {
      fiveYears: [],
      months: [],
      ppdd: [],
      cdd: [],
      bdd: [],
      tdde: [],
      hours: [],
      minutes: [],
      dpays: [],
    }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private contractInformationBorrowingService: ContractInformationBorrowingService,
    private editorPdfService: EditorPdfService,
    private selectContractTypeService: SelectContractTypeService,
    private alertService: AlertService,
    private templateService: TemplateService,
    private loadingService: LoadingService,
    private alertSharedService: AlertSharedService,
  ) {
    this.dateShared = new DateShared();
    this.stringFormat = new StringFormat();
  }

  ngOnInit(): void {
    this.checkTemplateId();
    this.initFormContractWord();
    this.data.fiveYears = this.dateShared.getDataNextFiveYearBeforeFive();
    this.data.months = this.dateShared.getDataMonthsForYear();
    this.data.hours = this.dateShared.getHoursFull();
    this.data.minutes = this.dateShared.getMinutesFull();
    this.data.dpays = this.dateShared.daysInMonth('01', '2020');
    this.setDisabledMonthAndDay();
  }

  ngAfterViewInit(): void {
    this.intiElementInput();
  }

  intiDataForService(key) {
    if (!this.selectContractTypeService.checkState()) {
      if (key === '{cssn}' || key === '{cci}' || key === '{dssn}' || key === '{dci}') {
        this.formContractWord.get(key).setValue(this.contractInformationBorrowingService.getDataFromContract(key).replace(/-/gi, ""));
      } else {
        this.formContractWord.get(key).setValue(this.contractInformationBorrowingService.getDataFromContract(key));
      }
    }
  }

  initFormContractWord() {
    this.formContractWord = this.fb.group({
      '{bdy}': ['N', Regex.Required],
      '{bdm}': ['N', Regex.Required],
      '{bdd}': ['N', Regex.Required],
      '{borra}': [null, Regex.Required],
      '{pborra}': [null, Regex.Required],
      '{pob}': [null, Regex.Required],
      '{ppdy}': ['N', Regex.Required],
      '{ppdm}': ['N', Regex.Required],
      '{ppdd}': ['N', Regex.Required],
      '{inte}': [null, Regex.Required],
      '{dpay}': ['N', Regex.Required],
      '{dsb}': [null, Regex.Required],
      '{an}': [null, Regex.Required],
      '{ah}': [null, Regex.Required],
      '{lad}': [null, Regex.Required],
      '{nod}': [null, Regex.Required],
      '{cdy}': ['N', Regex.Required],
      '{cdm}': ['N', Regex.Required],
      '{cdd}': ['N', Regex.Required],
      '{cnm}': [null, Regex.Required],
      '{caddr}': [null, Regex.Required],
      '{cssn}': [null, Regex.Required],
      '{cci}': [null, Regex.Required],
      '{dsn}': [null, Regex.Required],
      '{dadd}': [null, Regex.Required],
      '{dssn}': [null, Regex.Required],
      '{dci}': [null, Regex.Required]
    });

    // ================================================================= {bdy} =================================================================

    this.formContractWord.get('{bdy}').valueChanges.subscribe(valueChanges => {
      this.valid.bdy = Regex.validatorFormData(this.formContractWord.get('{bdy}').status);
      this.contractInformationBorrowingService.setDataFromContract('{bdy}', valueChanges);
      if (!this.formContractWord.get('{bdy}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bdy}').setValue(valueChanges);
        this.formContractWord.get('{bdm}').setValue('N');
        this.formContractWord.get('{bdm}').enable();
      } else if (this.formContractWord.get('{bdy}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bdm}').setValue('N');
        this.formContractWord.get('{bdm}').enable();
      } else {
        this.formContractWord.get('{bdm}').disable();
      }
    });

    this.intiDataForService('{bdy}');
    // ================================================================= {bdm} =================================================================

    this.formContractWord.get('{bdm}').valueChanges.subscribe(valueChanges => {
      this.valid.bdm = Regex.validatorFormData(this.formContractWord.get('{bdm}').status);
      this.contractInformationBorrowingService.setDataFromContract('{bdm}', valueChanges);
      if (!this.formContractWord.get('{bdm}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bdd}').setValue('N');
        this.formContractWord.get('{bdm}').setValue(valueChanges);
        this.data.bdd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{bdy}').value);
        this.formContractWord.get('{bdd}').enable();
      } else if (this.formContractWord.get('{bdm}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bdd}').setValue('N');
        this.data.bdd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{bdy}').value);
        this.formContractWord.get('{bdd}').enable();
      } else {
        this.formContractWord.get('{bdd}').disable();
      }
    });

    this.intiDataForService('{bdm}');
    // ================================================================= {bdd} =================================================================

    this.formContractWord.get('{bdd}').valueChanges.subscribe(valueChanges => {
      this.valid.bdd = Regex.validatorFormData(this.formContractWord.get('{bdd}').status);
      this.contractInformationBorrowingService.setDataFromContract('{bdd}', valueChanges);
      if (!this.formContractWord.get('{bdd}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{bdd}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{bdd}');
    // ================================================================= {borra} =================================================================

    this.formContractWord.get('{borra}').valueChanges.subscribe(valueChanges => {
      this.valid.borra = Regex.validatorFormData(this.formContractWord.get('{borra}').status);
      this.small.borra = true;
      this.contractInformationBorrowingService.setDataFromContract('{borra}', valueChanges);
    });

    this.intiDataForService('{borra}');
    // ================================================================= {pborra} =================================================================

    this.formContractWord.get('{pborra}').valueChanges.subscribe(valueChanges => {
      this.valid.pborra = Regex.validatorFormData(this.formContractWord.get('{pborra}').status);
      this.small.pborra = true;
      this.contractInformationBorrowingService.setDataFromContract('{pborra}', valueChanges);
    });

    this.intiDataForService('{pborra}');

    // ================================================================= {pob} =================================================================

    this.formContractWord.get('{pob}').valueChanges.subscribe(valueChanges => {
      this.valid.pob = Regex.validatorFormData(this.formContractWord.get('{pob}').status);
      this.small.pob = true;
      this.contractInformationBorrowingService.setDataFromContract('{pob}', valueChanges);
    });

    this.intiDataForService('{pob}');
    // ================================================================= {ppdy} =================================================================

    this.formContractWord.get('{ppdy}').valueChanges.subscribe(valueChanges => {
      this.valid.ppdy = Regex.validatorFormData(this.formContractWord.get('{ppdy}').status);
      this.contractInformationBorrowingService.setDataFromContract('{ppdy}', valueChanges);
      if (!this.formContractWord.get('{ppdy}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ppdy}').setValue(valueChanges);
        this.formContractWord.get('{ppdm}').setValue('N');
        this.formContractWord.get('{ppdm}').enable();
      } else if (this.formContractWord.get('{ppdy}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ppdm}').setValue('N');
        this.formContractWord.get('{ppdm}').enable();
      } else {
        this.formContractWord.get('{ppdm}').disable();
      }
    });

    this.intiDataForService('{ppdy}');
    // ================================================================= {ppdm} =================================================================

    this.formContractWord.get('{ppdm}').valueChanges.subscribe(valueChanges => {
      this.valid.ppdm = Regex.validatorFormData(this.formContractWord.get('{ppdm}').status);
      this.contractInformationBorrowingService.setDataFromContract('{ppdm}', valueChanges);
      if (!this.formContractWord.get('{ppdm}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ppdd}').setValue('N');
        this.formContractWord.get('{ppdm}').setValue(valueChanges);
        this.data.ppdd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ppdy}').value);
        this.formContractWord.get('{ppdd}').enable();
      } else if (this.formContractWord.get('{ppdm}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ppdd}').setValue('N');
        this.data.ppdd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{ppdy}').value);
        this.formContractWord.get('{ppdd}').enable();
      } else {
        this.formContractWord.get('{ppdd}').disable();
      }
    });

    this.intiDataForService('{ppdm}');

    // ================================================================= {ppdd} =================================================================

    this.formContractWord.get('{ppdd}').valueChanges.subscribe(valueChanges => {
      this.valid.ppdd = Regex.validatorFormData(this.formContractWord.get('{ppdd}').status);
      this.contractInformationBorrowingService.setDataFromContract('{ppdd}', valueChanges);
      this.contractInformationBorrowingService.setDataFromContract('{ppdyymmd}', this.stringFormat.dateFormat(this.formContractWord.get('{ppdy}').value, this.formContractWord.get('{ppdm}').value, valueChanges));

      if (!this.formContractWord.get('{ppdd}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{ppdd}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{ppdd}');
    // ================================================================= {inte} =================================================================

    this.formContractWord.get('{inte}').valueChanges.subscribe(valueChanges => {
      this.valid.inte = Regex.validatorFormData(this.formContractWord.get('{inte}').status);
      this.small.inte = true;
      this.contractInformationBorrowingService.setDataFromContract('{inte}', valueChanges);
    });

    this.intiDataForService('{inte}');
    // ================================================================= {dpay} =================================================================

    this.formContractWord.get('{dpay}').valueChanges.subscribe(valueChanges => {
      this.valid.dpay = Regex.validatorFormData(this.formContractWord.get('{dpay}').status);
      this.contractInformationBorrowingService.setDataFromContract('{dpay}', valueChanges);
      if (!this.formContractWord.get('{dpay}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{dpay}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{dpay}');
    // ================================================================= {dsb} =================================================================

    this.formContractWord.get('{dsb}').valueChanges.subscribe(valueChanges => {
      this.valid.dsb = Regex.validatorFormData(this.formContractWord.get('{dsb}').status);
      this.small.dsb = true;
      this.contractInformationBorrowingService.setDataFromContract('{dsb}', valueChanges);
    });

    this.intiDataForService('{dsb}');
    // ================================================================= {an} =================================================================

    this.formContractWord.get('{an}').valueChanges.subscribe(valueChanges => {
      this.valid.an = Regex.validatorFormData(this.formContractWord.get('{an}').status);
      this.small.an = true;
      this.contractInformationBorrowingService.setDataFromContract('{an}', valueChanges);
    });

    this.intiDataForService('{an}');
    // ================================================================= {ah} =================================================================

    this.formContractWord.get('{ah}').valueChanges.subscribe(valueChanges => {
      this.valid.ah = Regex.validatorFormData(this.formContractWord.get('{ah}').status);
      this.small.ah = true;
      this.contractInformationBorrowingService.setDataFromContract('{ah}', valueChanges);
    });

    this.intiDataForService('{ah}');
    // ================================================================= {lad} =================================================================

    this.formContractWord.get('{lad}').valueChanges.subscribe(valueChanges => {
      this.valid.lad = Regex.validatorFormData(this.formContractWord.get('{lad}').status);
      this.small.lad = true;
      this.contractInformationBorrowingService.setDataFromContract('{lad}', valueChanges);
    });

    this.intiDataForService('{lad}');
    // ================================================================= {nod} =================================================================

    this.formContractWord.get('{nod}').valueChanges.subscribe(valueChanges => {
      this.valid.nod = Regex.validatorFormData(this.formContractWord.get('{nod}').status);
      this.small.nod = true;
      this.contractInformationBorrowingService.setDataFromContract('{nod}', valueChanges);
    });

    this.intiDataForService('{nod}');
    // ================================================================= {cdy} =================================================================

    this.formContractWord.get('{cdy}').valueChanges.subscribe(valueChanges => {
      this.valid.cdy = Regex.validatorFormData(this.formContractWord.get('{cdy}').status);
      this.contractInformationBorrowingService.setDataFromContract('{cdy}', valueChanges);
      if (!this.formContractWord.get('{cdy}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cdy}').setValue(valueChanges);
        this.formContractWord.get('{cdm}').setValue('N');
        this.formContractWord.get('{cdm}').enable();
      } else if (this.formContractWord.get('{cdy}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cdm}').setValue('N');
        this.formContractWord.get('{cdm}').enable();
      } else {
        this.formContractWord.get('{cdm}').disable();
      }
    });

    this.intiDataForService('{cdy}');
    // ================================================================= {cdm} =================================================================

    this.formContractWord.get('{cdm}').valueChanges.subscribe(valueChanges => {
      this.valid.cdm = Regex.validatorFormData(this.formContractWord.get('{cdm}').status);
      this.contractInformationBorrowingService.setDataFromContract('{cdm}', valueChanges);
      if (!this.formContractWord.get('{cdm}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cdm}').setValue('N');
        this.formContractWord.get('{cdm}').setValue(valueChanges);
        this.data.cdd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{cdy}').value);
        this.formContractWord.get('{cdd}').enable();
      } else if (this.formContractWord.get('{cdm}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cdd}').setValue('N');
        this.data.cdd = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{cdy}').value);
        this.formContractWord.get('{cdd}').enable();
      } else {
        this.formContractWord.get('{cdd}').disable();
      }
    });

    this.intiDataForService('{cdm}');

    // ================================================================= {cdd} =================================================================

    this.formContractWord.get('{cdd}').valueChanges.subscribe(valueChanges => {
      this.valid.cdd = Regex.validatorFormData(this.formContractWord.get('{cdd}').status);
      this.contractInformationBorrowingService.setDataFromContract('{cdd}', valueChanges);
      if (!this.formContractWord.get('{cdd}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cdd}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{cdd}');
    // ================================================================= {cnm} =================================================================

    this.formContractWord.get('{cnm}').valueChanges.subscribe(valueChanges => {
      this.valid.cnm = Regex.validatorFormData(this.formContractWord.get('{cnm}').status);
      this.small.cnm = true;
      this.contractInformationBorrowingService.setDataFromContract('{cnm}', valueChanges);
    });

    this.intiDataForService('{cnm}');
    // ================================================================= {caddr} =================================================================

    this.formContractWord.get('{caddr}').valueChanges.subscribe(valueChanges => {
      this.valid.caddr = Regex.validatorFormData(this.formContractWord.get('{caddr}').status);
      this.small.caddr = true;
      this.contractInformationBorrowingService.setDataFromContract('{caddr}', valueChanges);
    });

    this.intiDataForService('{caddr}');
    // ================================================================= {cssn} =================================================================

    this.formContractWord.get('{cssn}').valueChanges.subscribe(valueChanges => {
      this.valid.cssn = Regex.validatorFormData(this.formContractWord.get('{cssn}').status);
      this.small.cssn = true;
      this.contractInformationBorrowingService.setDataFromContract('{cssn}', this.stringFormat.socialNumberFormat(valueChanges));
    });

    this.intiDataForService('{cssn}');
    // ================================================================= {cci} =================================================================

    this.formContractWord.get('{cci}').valueChanges.subscribe(valueChanges => {
      this.valid.cci = Regex.validatorFormData(this.formContractWord.get('{cci}').status);
      this.small.cci = true;
      this.contractInformationBorrowingService.setDataFromContract('{cci}', this.stringFormat.phoneNumberFormat(valueChanges));
    });

    this.intiDataForService('{cci}');
    // ================================================================= {dsn} =================================================================

    this.formContractWord.get('{dsn}').valueChanges.subscribe(valueChanges => {
      this.valid.dsn = Regex.validatorFormData(this.formContractWord.get('{dsn}').status);
      this.small.dsn = true;
      this.contractInformationBorrowingService.setDataFromContract('{dsn}', valueChanges);
    });

    this.intiDataForService('{dsn}');
    // ================================================================= {dadd} =================================================================

    this.formContractWord.get('{dadd}').valueChanges.subscribe(valueChanges => {
      this.valid.dadd = Regex.validatorFormData(this.formContractWord.get('{dadd}').status);
      this.small.dadd = true;
      this.contractInformationBorrowingService.setDataFromContract('{dadd}', valueChanges);
    });

    this.intiDataForService('{dadd}');
    // ================================================================= {dssn} =================================================================

    this.formContractWord.get('{dssn}').valueChanges.subscribe(valueChanges => {
      this.valid.dssn = Regex.validatorFormData(this.formContractWord.get('{dssn}').status);
      this.small.dssn = true;
      this.contractInformationBorrowingService.setDataFromContract('{dssn}', this.stringFormat.socialNumberFormat(valueChanges));
    });

    this.intiDataForService('{dssn}');
    // ================================================================= {dci} =================================================================

    this.formContractWord.get('{dci}').valueChanges.subscribe(valueChanges => {
      this.valid.dci = Regex.validatorFormData(this.formContractWord.get('{dci}').status);
      this.small.dci = true;
      this.contractInformationBorrowingService.setDataFromContract('{dci}', this.stringFormat.phoneNumberFormat(valueChanges));
    });

    this.intiDataForService('{dci}');
  }

  setDisabledMonthAndDay() {
    if (this.selectContractTypeService.checkState()) {
      this.formContractWord.get('{ppdm}').disable();
      this.formContractWord.get('{ppdd}').disable();

      this.formContractWord.get('{cdm}').disable();
      this.formContractWord.get('{cdd}').disable();

      this.formContractWord.get('{bdm}').disable();
      this.formContractWord.get('{bdd}').disable();
    }
  }

  checkTemplateId(): void {
    if (this.selectContractTypeService.getTempId() == '') {
      this.router.navigate(['select-contract-type']);
    }
  }

  intiElementInput() {
    Object.keys(this.inputs).forEach(key => {
      if (key !== 'btn') {
        this.inputs[key] = document.getElementById(key);
      }
    });
    this.inputs.btn.next = true;
  }

  onClickBtnCreateContractWord() {
    this.createTemplateSystem();
  }

  setFormaNumber() {
    var parts = this.formContractWord.get('{pborra}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{pborra}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  createTemplateSystem() {
    let params = {
      tempType: this.selectContractTypeService.getTemplType(),
      templId: this.selectContractTypeService.getTempId()
    }
    this.loadingService.openLoading();
    this.templateService.createTemplateSystem(params).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.editorPdfService.setTemplateId(rs.data.templId);
        this.editorPdfService.setTemplNm(rs.data.templNm);
        this.editorPdfService.setContorType('%');
        this.editorPdfService.setEdteMode("temp_sys");
        this.editorPdfService.setState("init");
        this.editorPdfService.download = false;
        this.router.navigate(['create-template-contract']);
      }
    });
  }
}
