<div class="row-hl btnnav justify-content-center d-flex">
  <div class="gcont-terms">
    <h2>개인정보처리방침</h2>

    <div class="gcont-terms-box">
      <h3>제1장 총칙</h3>

      <h4>제1조 [목적]</h4>
      <p>주식회사 베리드코리아(이하 ‘회사’)는 지콘트(이하, “서비스”라 함)을 운영하며 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며,
         관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.</p>
      <p>본 개인정보처리방침은 서비스에 회원 가입한 이용자에 대하여 적용되며, 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 회사가 개인정보 보호를 위하여 어떠한 조치를 취하고 있는지 알리기 위한 목적으로 작성되었습니다.</p>

      <h4>제2조 [개인정보의 수집 및 이용목적, 수집항목, 이용 및 보유기간]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> <p>회원관리</p>
          <p>회원제 서비스 제공 및 개선, 개인식별, 이용약관 위반 회원에 대한 이용제한 조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재, 가입의사 확인, 가입 및 가입횟수 제한, 
            만14세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원탈퇴 의사의 확인 등</p>
        </li>
        <li><span>(2)</span> <p>신규 서비스 개발 및 마케팅·광고에의 활용</p>
          <p>신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 자사 및 제휴 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계 분석 등</p>
        </li>
        <li><span>(3)</span> <p>서비스 제공에 관한 계약 이행 및 유료서비스 제공에 따른 요금정산</p>
          <p>전자서명 서비스 제공, 콘텐츠 제공, 특정 맞춤 서비스 제공, 유료서비스 이용에 대한 과금, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구서 등 발송, 요금 추심 등</p>
        </li>
        <li><span>(4)</span> <p>법적 증거로 활용</p>
          <p>법적 분쟁시 증거자료 제출</p>
        </li>  
        <li><span>(5)</span> <p>당사는 서비스의 제공을 위하여 회원들에 대한 다음과 같은 최소한의 개인정보를 수집하여 이용 및 보유하고 있습니다.</p> 
          <table class="terms-table">
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>수집 및 이용 목적</th>
                <th>수집 항목</th>
                <th>이용 및 보유기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3" >회원가입</td>
                <td rowspan="2" >필수</td>
                <td>회원가입 시 수집하는 최소한의 정보</td>
                <td>이름, 이메일주소</td>
                <td rowspan="6">회원 <br/>탈퇴시까지</td>
              </tr>
              <tr>
                <td>소셜계정을 통한 회원 가입 시</td>
                <td>① Naver : email<br/>② Google : email, 이름, 프로필 사진,로케일<br/>③ Kakao : email, 이름, 프로필 사진, 최종접속시간</td>
              </tr>
              <tr>
                <td >선택</td>
        
                <td>
                    회원 가입 시 마케팅 및 광고 활용 선택항목 동의 시
                    <br/>
                    -  신규 서비스 개발 및 맞춤 서비스 제공
                    <br/>
                    - 서비스 이용과 관련한 자료 제공
                    <br/>
                    - 뉴스레터 발송, 새로운 서비스(제품)의 안내
                    <br/>
                    -  경품 행사, 이벤트 등 광고성 정보 제공
                </td>

                <td>이름, 이메일주소</td>
              </tr>
              <tr>
                <td rowspan="3" >서비스 이용</td>
                <td rowspan="3" >필수</td>
                <td>작성시 수신자 본인 확인</td>
                <td>이메일주소 또는 휴대폰번호</td>
              </tr>
              <tr>
                <td>작성시 작성자 정보 우선 기입</td>
                <td>이름, 이메일주소 또는 휴대폰번호(택1)</td>
              </tr>
              <tr>
                <td>서비스 사용 시 자동생성되는 정보로써 폼 작성자와 수신자의 일치여부 확인, 분쟁 발생시 분쟁 조정의 자료로 활용</td>
                <td>IP Address, 쿠키, 서비스 이용 기록, 기기정보(PC/모바일), 작성시 첨부한 이미지, 첨부파일
                </td>
              </tr>
              <tr>
                <td >결제</td>
                <td >필수</td>
                <td>결제에 필요한 결제수단 정보</td>
                <td>간편결제 : 생년월일 6자리, 성별, 휴대폰번호<br>일반결제 : 카드사명, 카드번호16자리, 유효기간, 비밀번호 앞 두 자리, CVV2/CVC2</td>
                <td>결제 완료 후<br/> 30일 까지</td>
              </tr>
              <tr>
                <td >고객지원<br>(카카오톡,전화,이메일)</td>
                <td >선택</td>
                <td>고충해결, 회신 용도로 고객 동의 하에 수집<br>분쟁 발생시 분쟁 조정의 자료로 활용</td>
                <td>이름, 회신연락처, 이메일주소, 회사명</td>
                <td>고충 해결 완료 시까지</td>
              </tr>
            </tbody>
          </table>
        </li>
         
        <li><span>(6)</span> <p>회원의 개인정보는 원칙적으로 회원정보 수집 및 이용목적이 달성된 후 해당 정보를 파기하며, 특히 탈퇴 회원의 개인정보는 7일 이내에 파기합니다.
          단, 다음 각 호의 항목에 대해서는 보존 근거를 기반으로 명시한 기간 동안 보존합니다.</p>
          <table class="terms-table">
            <thead>
              <tr>
                <th>보존항목</th>
                <th>보존기간</th>
                <th>보존근거</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-c">부정이용에 관한 기록</td>
                <td class="text-c">1년</td>
                <td class="text-c">부정이용방지</td>
              </tr>
              <tr>
                <td class="text-c">계약 또는 청약철회 등에 관한 기록</td>
                <td class="text-c">5년</td>
                <td class="text-c">전자상거래 등에서의 소비자보호에 관한 법률</td>
              </tr>
              <tr>
                <td class="text-c">대금결제 및 재화 등의 공급에 관한 기록</td>
                <td class="text-c">5년</td>
                <td class="text-c">전자상거래 등에서의 소비자보호에 관한 법률</td>
              </tr>
              <tr>
                <td class="text-c">소비자의 불만 또는 분쟁처리에 관한 기록</td>
                <td class="text-c">3년</td>
                <td class="text-c">전자상거래 등에서의 소비자보호에 관한 법률</td>
              </tr>
              <tr>
                <td class="text-c">웹사이트 방문기록</td>
                <td class="text-c">3개월</td>
                <td class="text-c">통신비밀보호법</td>
              </tr>
              <tr>
                <td class="text-c">전자금융 거래에 관한 기록</td>
                <td class="text-c">5년</td>
                <td class="text-c">전자금융거래법</td>
              </tr>
                          
            </tbody>
          </table>
        </li> 
      </ul>

      <h4>제3조 [개인정보의 보호 및 관리]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> 
          <p>회사는 수집된 회원정보가 분실, 도난, 누출, 변조 또는 훼손되지 아니하도록 보안 및 안정성 확보에 필요한 기술적 조치 등을 취합니다.</p>
        </li>
        <li><span>(2)</span> 
          <p>회사는 서비스와 관련하여 취득한 회원의 개인정보를 본인의 사전 승낙 없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스 관련 업무 이외의 상업적 목적으로 사용할 수 없습니다.</p>
        </li>
        <li><span>(3)</span> 
          <p>회사는 회원이 제공한 회원정보를 서비스 이용 기간 동안 보유하며, 회원탈퇴 시에는 제2조에 명시된 바와 같이 관련법규에 따라 보존할 근거가 없을 경우 회원정보를 즉시 파기합니다.</p>
        </li>
        <li><span>(4)</span> 
          <p>회원정보를 관리하는 담당자를 지정하고 정기적인 개인정보보호 교육을 실시하고 있습니다.</p>
        </li>
        <li><span>(5)</span> 
          <p>회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보 보호 및 사용에 대해서는 회사가 별도로 고지하는 개인정보 처리방침에 따릅니다.</p>
        </li>
        <li><span>(6)</span> 
          <p>회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서는 일체의 책임을 지지 않습니다.</p>
        </li>
      </ul>

      <h4>제4조 [개인정보의 제공]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> 
          <p>회사는 회원의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 다음 각 호의 경우에는 예외로 합니다.</p>
          <ul class="terms-2depth">
            <li><span>①</span><p>회원이 사전에 동의한 경우</p></li>
            <li><span>②</span><p>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</p></li>
            <li><span>③</span><p>서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우</p></li>
            <li><span>④</span><p>통계작성/학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주/협력사나 연구단체 등에 제공하는 경우</p></li>
          </ul>
        </li>        
      </ul>

      <h4>제5조 [개인정보의 위탁]</h4>
      <p>회사는 원활하고 안전한 서비스 운영을 위해 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.</p>

      <h4>제6조 [개인정보의 파기]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> 
          <p>회사는 회원의 개인정보 수집, 이용목적이 달성되거나 그 보유, 이용기간이 종료되는 경우 고객의 동의, 이용약관, 관련 법령에 따라 보관이 필요한 경우를 제외하고 해당 정보를 지체 없이 파기합니다.</p>
        </li>
        <li><span>(2)</span> 
          <p>회사는 서면에 기재된 개인정보의 경우에는 분쇄기로 분쇄하거나 소각하며 전자적 방법으로 저장된 개인정보의 경우에는 그 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
        </li>
        <li><span>(3)</span> 
          <p>회사는 회원 가입 후 1년 동안 서비스를 이용하지 않은 회원을 휴면계정으로 전환하고, 개인정보를 별도로 분리 보관합니다.</p>
          <p>[관련 법령 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조제2항]</p>
        </li>
        <li><span>(4)</span> 
          <p>회사는 휴면계정 이용자에게 휴면계정 전환되기 30일 전까지 이용자에게 통지하여야 하고, 통지 내용은 아래와 같습니다.</p>
          <ul class="terms-2depth">
            <li><span>-</span><p>개인정보 분리, 보관되는 사실</p></li>
            <li><span>-</span><p>휴면계정 전환 예정일</p></li>
            <li><span>-</span><p>분리되는 개인정보 항목</p></li>
            <li><span>-</span><p>휴면계정으로 전환된 이용자는 휴면계정을 해제하기 전까지 서비스 이용을 제한합니다.</p></li>
            <li><span>-</span><p>휴면계정이 서비스에 로그인하여 휴면계정을 해제하면, 서비스 이용이 가능합니다.</p></li>
          </ul>
        </li>
        <li><span>(5)</span> 
          <p>고지의 의무</p>
          <p>현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 이메일을 통해 고지할 것입니다. 다만 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.</p>
        </li>        
      </ul>

      <h4>제7조 [회원 및 법정대리인의 권리와 그 행사방법]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> 
          <p>회원 및 그 법정 대리인은 언제든지 등록되어 있는 회원의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요할 수 있습니다.</p>
        </li>
        <li><span>(2)</span> 
          <p>개인정보 조회/수정/탈퇴를 위해서는 웹사이트에서 로그인 후 [내 프로필 - 내 정보] 메뉴에서 처리할 수 있습니다.</p>
        </li>
        <li><span>(3)</span> 
          <p>회원이 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 회원정보를 이용 또는 제공하지 않습니다. 또한 잘못된 회원정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지합니다.</p>
        </li>
        <li><span>(4)</span> 
          <p>회사는 회원 또는 그 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보를 본 개인정보처리방침의 "[개인정보의 수집 및 이용목적, 수집항목, 이용 및 보유기간]"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용하지 않습니다.</p>
        </li>
      </ul>

      <h4>제8조 [정책 변경에 따른 고지의무]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> 
          <p>법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 웹사이트 초기화면 또는 초기화면 내 링크를 통해 변경이유 및 내용 등을 고지할 것입니다.</p>
        </li>
      </ul>

      <h4>제9조 [개인정보 자동 수집장치의 설치/운영 및 거부에 관한 사항]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> 
          <p>법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 웹사이트 초기화면 또는 초기화면 내 링크를 통해 변경이유 및 내용 등을 고지할 것입니다.</p>
        </li>
      </ul>

      <h4>제10조 [개인정보에 관한 민원서비스]</h4>
      <ul class="terms-num">
        <li><span>(1)</span> 
          <p>귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
          <ul class="terms-2depth">
            <li class="bold">[개인정보 담당부서]</li>
            <li>부서: berith.co 서비스팀</li>
            <li>메일: support@berith.co</li>
          </ul>
        </li>
        <li><span>(2)</span> 
          <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
          <ul class="terms-2depth">
            <li><span>-</span><p>개인정보침해신고센터 (<a href="privacy.kisa.or.kr"  target="_blank" title="개인정보침해신고센터"> privacy.kisa.or.kr</a> / 국번없이 118)</p></li>
            <li><span>-</span><p>대검찰청 사이버범죄수사단 (<a href="www.spo.go.kr"  target="_blank" title="대검찰청 사이버범죄수사단"> www.spo.go.kr</a> / 02-3480-3571)</p></li>
            <li><span>-</span><p>경찰청 사이버안전국 (<a href="www.cyber.go.kr"  target="_blank" title="경찰청 사이버안전국">www.cyber.go.kr</a> / 국번없이 182)</p></li>
          </ul>
        </li>
      </ul>

     
    </div>
  </div>
</div>