<div class="bg-modal" *ngIf="show">
    <!-- Modal -->
    <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content rounded-0" style="background: transparent">
                <div class="modal-header rounded-0" style="background: #ffffff;color: #000000;">
                    <h5 class="modal-title text-center" id="exampleModalLabel">본인인증서 제출</h5>
                    <button type="button" style="color: #000000;" (click)="closeModal()" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body rounded-0" style="background: #ffffff;color: #000000;">
                    <div class="row py-3" style="background: #fff;">
                        <div class="col-md-12">
                            <h5  class="tex-tit-bod-001">인증정보</h5>
                        </div>

                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <button type="button" class="btn btn-small-long btn-white">
                                            필수
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-1">
                                        <fa-icon [icon]="faCheck" class="fa-1x"></fa-icon>
                                    </div>
                                    <div class="col-md-3 text-left">
                                       <span class="tex-det-001">내/외국인 </span>
                                    </div>
                                    <div class="col-md-8 text-left">
                                        
                                        <span class="tex-det-001">내국인</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-1">
                                        <fa-icon [icon]="faCheck" class="fa-1x"></fa-icon>
                                    </div>
                                    <div class="col-md-3 text-left">
                                        <span class="tex-det-001">생년월일 </span>
                                        
                                    </div>
                                    <div class="col-md-8 text-left">
                                        <span class="tex-det-001">19900101</span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-1">
                                        <fa-icon [icon]="faCheck" class="fa-1x"></fa-icon>
                                    </div>
                                    <div class="col-md-3 text-left">
                                        <span class="tex-det-001">이름 </span>
                                        
                                    </div>
                                    <div class="col-md-8 text-left">
                                        <span class="tex-det-001">홍길동</span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-1">
                                        <fa-icon [icon]="faCheck" class="fa-1x"></fa-icon>
                                    </div>
                                    <div class="col-md-3 text-left">
                                        <span class="tex-det-001"> 휴대폰번호  </span>
                                       
                                    </div>
                                    <div class="col-md-8 text-left">
                                        <span class="tex-det-001">01012345678 </span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-1">
                                        <fa-icon [icon]="faCheck" class="fa-1x"></fa-icon>
                                    </div>
                                    <div class="col-md-3 text-left">
                                        <span class="tex-det-001">성별 </span>
                                        
                                    </div>
                                    <div class="col-md-8 text-left">
                                        <span class="tex-det-001">M</span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <button type="button" class="btn btn-small-long btn-white">
                                            필수
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-1">
                            <div class="bg-gray w-100 px-2 py-2">
                                <div class="row">
                                    <div class="col-md-1">
                                        <fa-icon [icon]="faCheck" class="fa-1x"></fa-icon>
                                    </div>
                                    <div class="col-md-3 text-left">
                                        <span class="tex-det-001">만료일 </span>
                                        
                                    </div>
                                    <div class="col-md-8 text-left">
                                        <span class="tex-det-001">20210423</span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer rounded-0 d-flex justify-content-center"
                    style="background: #ffffff;color: #000000;">
                    <button type="button" class="btn btn-middle-short btn-white" (click)="closeModal()">취소</button>
                    <button type="button" class="btn btn-middle-short btn-black" (click)="onClickBtn('btnConfirm')">제출</button>
                </div>
            </div>
        </div>
    </div>
</div>