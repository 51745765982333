import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlockCertDetailsService {

  blockCertDetails: {
    contorEmail: string,
    contorNm: string,
    userId: string,
    userNm: string,
    pldgDate: string,
    pldgHash: string,
    ecertNm: string
  } = {
    contorEmail: '',
    contorNm: '',
    userId: '',
    userNm: '',
    pldgDate: '',
    pldgHash: '',
    ecertNm: ''
  };

  blockCertHistory: {
    pldgHashDate: string,
    histIp: string,
    pldgBlockIfResCode: string,
    userNm: string,
    pldgHash: string
  }[] = [];


  status = {
    open: true,
    close: false
  };
  show = false;
  change = {
    show: false,
    html: false,
    state: false
  };
  text = {
    title: '',
    subTitle: '',
  }
  htmlBody: string;
  state: string;
  constructor() { }

  setState(state?: 'excelTransfer' | 'tableData') {
    this.state = state;
    this.change.state = true;
  }

  getState() {
    return this.state;
  }

  open() {
    this.change.show = true;
    this.show = this.status.open;
  }

  close() {
    this.change.show = true;
    this.show = this.status.close;
  }

  setChange(type: any, status: boolean) {
    this.change[type] = status;
  }

  setHtmlBody(html: string) {
    this.change.html = true;
    this.htmlBody = html;
  }

  setBlockCertDetails(details: any) {
    this.blockCertDetails = details;
    console.log(this.blockCertDetails);
    this.change.state = true;
  }

  getBlockCertDetails(): {
    contorEmail: string,
    contorNm: string,
    userId: string,
    userNm: string,
    pldgDate: string,
    pldgHash: string,
    ecertNm: string
  } {

    console.log(this.blockCertDetails, "getBlockCertDetails");
    return this.blockCertDetails
  }

  setBlockCertHistory(history: any): void {
    this.blockCertHistory = history;
  }

  getBlockCertHistory(): {
    pldgHashDate: string,
    histIp: string,
    pldgBlockIfResCode: string,
    userNm: string,
    pldgHash: string
  }[] {
    return this.blockCertHistory;
  }
}
