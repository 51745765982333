  <div class="row-hl btnnav justify-content-center d-flex">
    <div class="gcont-terms">
      <h2>서비스 이용약관</h2>

      <div class="gcont-terms-box">
        <h3>제1장 총칙</h3>

        <h4>제1조 [목적]</h4>
        <p>본 약관은 ㈜베리트코리아(이하 ‘회사’)가 운영하는 Gcont(이하 ‘서비스’)을 이용자 이용함에 있어 회사와 고객 간의 권리, 의무 및 책임사항을 규정하고 기타 필요한 사항을 규정함을 목적으로 합니다.
        </p>

        <h4>제2조 [용어의 정의]</h4>
        <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <ul class="terms-num">
          <li><span>(1)</span> <p>“서비스”란 블록체인 기반 전자계약 서비스 지콘트(Gcont)를 말합니다. (URL : http://gcont.co.kr)</p></li>
          <li><span>(2)</span> <p>“유료서비스”란 회사가 제공하는 서비스 중 회원이 회사에 일정 금액을 결제하거나, 회원이 회사 또는 제3자와의 거래 내지 약정 조건을 수락하는 경우에 이용할 수 있는
            회사의 서비스를
            말합니다.</p></li>
          <li><span>(3)</span> <p>“이용자”란 서비스를 이용하는 회원 또는 비회원을 말합니다.</p></li>
          <li><span>(4)</span> <p>“회원”이란 서비스에 개인정보를 제공하여 회원등록을 한 자로서 서비스의 정보를 지속적으로 제공 받으며 서비스가 제공하는 서비스를 계속적으로 이용할 수 있는 자를
            말합니다.</p></li>
          <li><span>(5)</span> <p>“비회원”이란 회원으로 가입하지 않고 서비스가 제공하는 정보 및 서비스를 이용하는 자를 말합니다.</p></li>
          <li><span>(6)</span> <p>“고객”이란 회사로부터 합법적인 절차에 따라 서비스를 구매하여 사용하는 이용자를 말합니다.</p></li>
          <li><span>(7)</span> <p>“계정”이란 회원이 서비스(Gcont)에 접속하여 서비스를 이용할 수 있도록 하는 고유한 정보를 말합니다.</p></li>
          <li><span>(8)</span> <p>“게시물”이란 회원이 등록한 폼 템플릿, 폼(계약서, 동의서 등) 또는 회원, 비회원이 작성 및 서명한 폼을 말합니다.</p></li>
          <li><span>(9)</span> <p>“개인정보”란 서비스에 가입 또는 서비스를 이용하기 하기 위해 입력(또는 제공하는) 이메일 주소,이름 등 회원의 개인정보를 말합니다.</p></li>
          <li><span>(10)</span> <p>“발신자”란 서명이 필요한 참여자(수신자)에게 전자 서명(electronic Signature)를 요청한 이용자를 말합니다. 최초의 발신자가 여기에 속하고, 순차
            전송의 경우 중간 서명자가 여기에 속합니다.</p></li>
          <li><span>(11)</span> <p>“수신자”란 발신자로부터 전자서명을 요청 받아 서명을 해야 하는 이용자를 말합니다.</p></li>
          <li><span>(12)</span> <p>“사본”이란 발신자와 수신자 사이에서 전자서명이 완료되어 PDF로 변환된 파일을 말합니다.</p></li>
        </ul>

        <h4>제3조 [약관의 게시와 개정]</h4>
        <p>본 약관은 서비스(gcont.co.kr)에 게시하거나 다른 합당한 수단으로 사용할 수 있습니다.</p>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회사는 이 약관의 내용과 상호, 대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록 서비스 초기화면에 게시하거나 기타의 방법으로 회원에게 공지합니다.  </p></li>
          <li><span>(2)</span> <p>회사는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p></li>
          <li><span>(3)</span> <p>회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관의 적용일자 7일전부터 적용일자 전일까지 회원의 이메일 주소로 공지합니다.
            단 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 합니다.
            회원의 이메일 주소 변경 등으로 인하여 개별 통지가 어려운 경우, 회원의 이메일 주소로 공지를 하였음에도 반송된 경우 이 약관에 의한 공지를 함으로써 개별 통지한 것으로 간주합니다.</p></li>
          <li><span>(4)</span> <p>회원은 변경된 약관에 대해 거부할 권리가 있습니다.
            회사가 제3항의 공지 또는 통보를 하면서 개정 약관의 적용/시행일까지 회원이 거부 의사를 표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는 내용을 고지하였으나, 회원이 명시적으로 약관 변경에
            대한 거부의사를 표시하지 아니하면, 회사는 회원이 적용/시행일자 부로 변경 약관에 동의한 것으로 간주합니다.
            개정/변경 약관에 대하여 거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수 있습니다.</p></li>
        </ul>

        <h4>제4조 [이용약관의 성립 및 체결]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>정상적인 방법으로 회원가입을 완료하여 회원의 자격을 부여받은 자(회원)는 본 서비스의 약관 및 개인정보처리방침에 대해 확인하고 동의한 것으로 간주합니다.</p></li>
          <li><span>(2)</span> <p>고객은 회사가 정한 구매절차에 따라 구매를 하여야 하며 구매절차에서 요구하는 본인 명의의 정보를 기재하여야 하며, 타인 명의의 정보를 도용하는 경우 법적인 보호를 받을
            수 없을 뿐만 아니라 발생하는 모든 책임이 고객에게 있음을 인정합니다.</p></li>
          <li><span>(3)</span> <p>회사는 (2)항의 경우가 발생하였을 때 서비스를 중지하거나 제한할 수 있습니다.</p></li>
          <li><span>(4)</span> <p>회사는 제공하는 서비스에 따라 필요한 경우, 고객에게 추가정보를 요청할 수 있습니다.</p></li>
          <li><span>(5)</span> <p>고객은 회사에 제공한 개인정보의 내용이 변경된 경우, 즉시 이를 정정하여야 하고, 정정의 지체로 인하여 발생한 손해에 대하여 회사가 아무런 책임을 지지 않음을
            인정합니다.</p></li>
        </ul>

        <h3>제2장 서비스 이용계약</h3>
        <h4>제5조 [회원가입]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>이용자는 서비스가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</p></li>
          <li><span>(2)</span> <p>이용자는 이메일,이름 등을 입력(제공)하고 이메일을 통해 인증 또는 소셜 인증(카카오,네이버,구글)을 하여야 합니다. 소셜 인증을 통해 회원에 가입하는 이용자는 서비스
            이용 목적의 다음 항목 수집에 동의하는 것으로 간주합니다.</p></li>
          <li><span>(3)</span> <p>이용자가 제2항에서 정한 인증을 거치지 않은 경우 서비스 이용이 제한될 수 있으며, 실명으로 등록하지 않은 이용자 또는 회원은 일체의 권리를 주장할 수 없습니다.
          </p></li>
          <li><span>(4)</span> <p>타인의 명의를 이용 또는 도용하여 회원가입 신청한 이용자의 모든 ID는 해당 사실이 확인되는 즉시 임의로 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.</p></li>
          <li><span>(5)</span> <p>서비스는 (1)항과 같이 회원으로 가입을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.</p>
            <ul class="terms-2depth">
              <li><span>①</span> <p>가입신청자가 이 약관 제6조 (4)항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제6조 (4)항에 의한 회원자격 상실 후 3년이 경과한 자로서
                서비스의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.</p></li>
              <li><span>②</span> <p>등록내용에 허위, 기재누락, 오기가 있는 경우</p></li>
              <li><span>③</span> <p>기타 회원으로 등록하는 것이 서비스의 기술상 현저히 지장이 있다고 판단되는 경우</p></li>
            </ul>
          </li>
          <li><span>(6)</span> <p>회원가입 계약의 성립시기는 서비스의 승낙 내용이 회원에게 도달한 시점으로 합니다.</p></li>
          <li><span>(7)</span> <p>회원은 등록사항에 변경이 있는 경우 즉시 전자우편 및 기타 방법으로 서비스에 대하여 그 변경사항을 알려야 합니다.</p></li>
        </ul>

        <h4>제6조 [회원탈퇴 및 자격상실 등]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회원이 서비스 가입을 해지하고자 하는 경우 로그인 후 [내 계정] 메뉴에서 회원 탈퇴가 가능합니다.</p></li>
          <li><span>(2)</span> <p>본 서비스 이용 중 다음 각 호에 해당하는 사유가 발생하는 경우, 회사는 회원의 자격을 제한 및 정지시킬 수 있습니다.</p>
            <ul class="terms-2depth">
              <li><span>①</span> <p>가입 신청 시 허위 내용을 등록한 경우</p></li>
              <li><span>②</span> <p>본 서비스 서비스를 이용하여 구입한 재화, 용역 등의 대금, 기타 서비스 이용과 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</p></li>
              <li><span>③ </span> <p>다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래 질서를 위협하는 경우</p></li>
              <li><span>④ </span> <p>서비스를 이용하여 법령과 이 약관을 금지하거나 공서양속(공공의 질서와 선량한 풍속, 법률 사상의 지도적 이념으로 법률행위를 판단하는 기준이 되는 사회적 타당성)에
                반하는 행위를 하는 경우</p></li>
              <li><span>⑤ </span> <p>허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</p></li>
              <li><span>⑥</span> <p>만 14세 미만 아동이 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’에서 정한 개인정보 입력 시 법정대리인의 동의를 얻지 아니한 경우</p></li>
            </ul>
          </li>
          <li><span>(3)</span> <p>회사가 회원 자격을 제한, 정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원 자격을 상실 시킬 수 있습니다.
          </p></li>
          <li><span>(4)</span> <p>회사가 회원 자격을 상실시키는 경우에는 회원등록을 말소할 수 있습니다. 이 경우 회원에게 이를 통지하고 회원등록 말소 전에 소명할 기회를 부여합니다. 
            단 회원에게 이 통지가 도달한 날로부터 7일 이내에 전자우편 등을 통해 서비스에 소명해야 합니다.</p></li>
          <li><span>(5)</span> <p>회원탈퇴한 계정은 재가입이 불가하며 해당 계정으로 작성한 모든 게시물은 삭제됩니다.</p></li>
        </ul>

        <h3>제3장 서비스 제공 및 이용</h3>
        <h4>제7조 [서비스의 내용 및 이용]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회사가 제공하는 서비스의 내용은 다음의 각 호와 같습니다.</p>
            <ul class="terms-2depth">
              <li><span>①</span> <p>회원가입 완료 시: 최초 1회 5건의 이용권을 무료 제공</p></li>
              <li><span>②</span> <p>요금제 신청 및 결제 완료 시: 신청 요금제(이용권, 정기권)에 해당하는 서비스를 제공</p></li>
            </ul>
          </li>
          <li><span>(2)</span> <p>고객은 회사가 제공하는 서비스 이용 시 본 약관과 모든 관련 법률을 준수해야 합니다. 또한, 다음 각 호의 사항을 준수해야 합니다.</p>
            <ul class="terms-2depth">
              <li><span>①</span> <p>제공되는 서비스를 손상시키거나, 사용할 수 없게 만들거나, 악화시켜서는 안됩니다.</p></li>
              <li><span>②</span> <p>제공되는 모든 서비스 또는 서비스의 일부를 양도하거나 재 판매할 수 없습니다.</p></li>
              <li><span>③ </span> <p>불법적인 수단을 이용하여 서비스를 수정하거나 삭제하거나 그렇게 하려고 시도해서도 안됩니다.</p></li>
              <li><span> ④</span> <p>정상적으로 서비스를 구매하지 않고 모든 서비스 또는 일부 서비스를 이용하거나 그렇게 하려고 시도해서는 안됩니다.</p></li>
            </ul>
          </li>
          <li><span>(3)</span> <p>준수사항을 준수하지 않는 경우 관련 법에 의하여 처벌을 받을 수 있고 또한 준수사항을 준수하지 않아 발생한 모든 결과에 대하여 회사가 아무런 책임을 지지 않음을 인정합니다.</p></li>
        </ul>

        <h4>제8조 [서비스 제공시간]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>서비스의 제공은 연중무휴 1일 24시간을 원칙으로 합니다. 다만 회사의 업무상이나 기술상의 이유로 서비스가 일시 중지 또는 중단될 수 있으며, 또한 운영상의 목적을
            위하여 회사가 정한 기간에는 서비스가 일시 중지 또는 중단될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.</p></li>
          <li><span>(2)</span> <p>회사는 서비스를 일정 범위로 분할하여 각 범위 별로 제공 가능한 시간을 별도로 정할 수 있으며, 이 경우 그 내용을 사전에 공지합니다.</p></li>
        </ul>

        <h4>제9조 [정보의 제공 및 광고의 게재]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>“회사“는 “이용자“가 서비스 이용 중 필요하다고 인정되는 다양한 정보를 홈페이지에 게재하거나 공지사항 또는 전자우편(eMail) 등의 방법으로 "회원"에게 제공할 수 있습니다.
            다만, "회원"은 언제든지 전자우편(eMail) 등을 통하여 수신 거절을 할 수 있습니다.</p></li>
          <li><span>(2)</span> <p>회사는 본 서비스를 운영/유지하기 위하여 광고를 게재할 수 있으며, 회원은 서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.</p></li>
          <li><span>(3)</span> <p>제1항의 정보를 전화 및 FAX와 같은 모사전송기기에 의하여 전송하려고 하는 경우에는 “회원“의 사전 동의를 받아서 전송합니다.</p></li>
          <li><span>(4)</span> <p>“회사“는 이폼 서비스 제공과 관련하여 회원 가입 및 적법한 수단을 통해 수집한 회원의 개인정보를 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편(eMail) 등을 수신한 “회원“은 수신거절을 “회사“에게 할 수 있습니다.</p></li>
        </ul>

        <h4>제10조 [서비스 이용의 일시 중지 또는 중단]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회사는 다음 각 호의 사유 또는 업무상 및 기술상의 문제가 발생하는 경우 그 해결을 위하여 필요한 기간 동안, 서비스의 운영 목적상으로 필요한 경우에는 회사가 정한 기간 동안 서비스의 전부 또는 일부를 일시 중지 또는 중단할 수 있습니다.</p>
            <ul class="terms-2depth">
              <li><span>① </span> <p>서비스용 설비의 보수 등으로 인한 부득이한 경우</p></li>
              <li><span>② </span> <p>고객이 회사의 영업활동을 방해하는 경우</p></li>
              <li><span>③</span> <p>정전, 제반 설비의 장애 또는 이용 량의 폭주 등으로 정상적인 ‘서비스’ 제공에 지장이 있는 경우</p></li>
              <li><span>④ </span> <p>기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</p></li>
            </ul>
          </li>
          <li><span>(2)</span> <p>제1항에 의한 ‘서비스’의 일시 중지 또는 중단의 경우 회사는 고객에게 사전 공지합니다. 다만, 회사가 통제할 수 없는 사유로 인하여 사전 공지가 불가능한 경우에는 사후에 공지합니다.</p></li>
          <li><span>(3)</span> <p>회사는 본 조에 의한 ‘서비스’ 중단으로 발생하는 문제에 대해서는 어떠한 책임도 지지 않습니다.</p></li>
        </ul>

        <h4> 제11조 [사용자 계정 사용 및 관리]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회원은 오직 회원 본인의 계정을 사용할 수 있습니다.</p></li>
          <li><span>(2) </span> <p>회원은 고객이 사용하는 모든 계정 및 암호를 기밀로 유지해야 하며, 이를 지키지 않을 경우 발생하는 모든 사항은 회원에게 책임이 있습니다.</p></li>
          <li><span>(3) </span> <p>회원은 서비스 계정을 통해 이루어지는 모든 행위에 대한 책임이 있습니다. 더불어 회사는 보안 등을 이유로 회원에게 정기적 또는 비정기적으로 비밀번호의 변경을 권고할 수 있습니다.</p></li>
          <li><span>(4)</span> <p>회원이 모든 서비스 계정에 대해 완전한 통제권을 갖는다는 것을 인정합니다.</p></li>
        </ul>

        <h4>제12조 [서비스 이용 책임]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>서비스를 구매하거나 사용하기 위해 동의해야 할 라이선스 조건이 있을 경우 이 조건이 우선적용 됩니다. 라이선스 조건이 없을 경우 본 약관이 적용됩니다. 회사는 서비스에 대한 모든 권리를 보유합니다.</p></li>
          <li><span>(2)</span> <p>회사는 고객에게 제공되는 서비스 버전을 자동으로 확인할 수 있으며 서비스 업데이트, 개선을 위하여 업그레이드를 진행할 수 있습니다.</p></li>
          <li><span>(3)</span> <p>고객은 서비스에 적용되는 모든 국내 법규를 준수해야 합니다.</p></li>
        </ul>

        <h4>제13조 [저작권의 귀속 및 이용제한]</h4>
        <ul class="terms-num">
          <li><span> (1) </span> <p>서비스에 게시된 저작물에 대한 저작권 기타 지적 재산권은 서비스에 귀속됩니다.</p></li>
          <li><span>(2)</span> <p>이용자는 서비스를 이용함으로써 얻은 정보를 서비스 담당자의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</p></li>
        </ul>

        <h4>제14조 [게시물의 관리]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회사는 회원이 게시하거나 등록한 내용을 이용자에게 사전 통지나 동의 없이 삭제할 수 없습니다.</p></li>
          <li><span>(2)</span> <p>회원이 탈퇴할 경우 기본 회원정보 및 모든 게시물은 삭제되며 복구되지 않습니다.</p></li>
          <li><span>(3)</span> <p>단, 회원이 게시물을 삭제 또는 회원 탈퇴시 기본적으로 모든 게시물이 삭제되나, “작성완료” 상태의 게시물의 경우 회원이 삭제하더라도 법적 근거자료로써 작성 완료일 기준 5년동안 보관됩니다.</p></li>
        </ul>

        <h4> 제15조 [서비스 이용요금]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>서비스의 이용요금은 다음의 각 호와 같습니다.</p>
            <ul class="terms-2depth">
              <li><span> ①</span> <p>전송건수 : 사용 기한 없이 소진 때까지 사용 가능한 전송건수(개인형 : 기본 1,000원/건, 기업형 : 기본 1,700원/건)</p></li>
            </ul>
          </li>
          <li><span>(2)</span> <p>서비스 요금제 외의 서비스는 별도 문의를 통해 제공 받으실 수 있습니다.</p>
            <ul class="terms-2depth">
              <li><span> ①</span> <p>OPEN API를 통한 서비스</p></li>
              <li><span> ②</span> <p>고객 맞춤형 개발을 통한 서비스</p></li>
            </ul>
          </li>
        </ul>

        <h4>제16조 [이용요금 반환(환불)]</h4>
        <ul class="terms-num">
          <li><span> (1)</span> <p>요금제 신청 및 결제 후 요금제에서 제공된 서비스(전송 건수)를 이용하지 않은 상태에서 7일 이내에 환불 요청을 할 경우 전액 환불해 드립니다.</p>
            <ul class="terms-2depth">
              <li><span>①</span> <p>환불 방식에 따라 관련 수수료가 공제될 수 있습니다.</p></li>
              <li><span>②</span> <p>무료로 제공된 서비스가 있을 경우 공제될 수 있습니다.</p></li>
            </ul>
          </li>
          <li><span> (2) </span> <p>환불 시 회원이 결제한 수단과 동일한 방식으로 환불하는 것을 원칙으로 합니다</p></li>
          <li><span>(3)</span> <p>유료 서비스를 사용하는 회원이 유료서비스 공급일 이후 제공되는 서명 요청 건을 사용하지 않았더라도 청약 철회가 가능한 기간(유료서비스 공급일로부터 7일 이내)을 경과하여 청약 철회를 신청하면 환불은 불가능합니다.
          </p></li>
          <li><span>(4)</span> <p>회사는 「콘텐츠산업 진흥법」제28조에 따라 제정된 「콘텐츠이용자보호지침」 등에 따라, 회사가 부담하였거나 부담할 부대비용, 수수료를 차감하여 환불할 수 있습니다.</p></li>
          <li><span>(5)</span> <p>회사가 이벤트 및 보상으로 고객에게 무상으로 제공한 전송크레딧(전송건수)은 환불이 불가합니다.</p></li>
          <li><span>(6)</span> <p>유료서비스를 이용 중인 회원이 탈퇴하는 경우 유료서비스는 즉시 해지되며 환불은 불가능합니다. 이 경우 회원의 정보와 서비스 이용 내용은 관련 법령이 정하는 경우를 제외하고는 이 약관에 따라 처리됩니다.</p></li>
        </ul>

        <h3>제4장 책임</h3>
        <h4> 제17조 [회사의 의무]</h4>
        <ul class="terms-num">
          <li><span>(1) </span> <p>회사는 이 약관이 정하는 바에 따라 서비스를 제공하기 위해 최선을 다합니다.</p></li>
          <li><span>(2)</span> <p>회사는 고객으로부터 제기되는 의견이나 불만이 정당하다고 인정되는 경우에는 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 고객에게 그 사유와 처리일정을 전자형식으로 통보하여야 합니다.</p></li>
          <li><span>(3)</span> <p>회사는 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며, 서비스에 장애가 생기거나 멸실된 때에는 지체 없이 이를 복구합니다. 다만, 천재지변, 비상사태 또는 그 밖에 부득이한 경우에는
            그 서비스를 일시 중단하거나 중지할 수 있습니다. 단, 무료 서비스에는 본 의무사항을 적용하지 않습니다.</p></li>
          <li><span>(4)</span> <p>서비스의 이용은 회사가 업무상 또는 기술상 특별한 경우를 제외하고는 연중무휴 1일 24시간 동안 이용 가능한 것을 원칙으로 합니다.
            다만, 회사의 업무상이나 기술상의 이유로 서비스가 일시 중지되거나, 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이런 경우 회사는 전자형식으로 사전 또는 사후 이를 공지합니다.</p></li>
          <li><span>(5)</span> <p>회사는 서비스 관련 정보의 제공을 위하여 고객의 휴대전화번호로 SMS를 전송하거나 이메일, 우편물 등을 보낼 수 있습니다.</p></li>
          <li><span>(6)</span> <p>고객지원은 회사가 정한 정상적인 구매 절차에 따라 서비스를 구매한 고객을 대상으로 구매한 서비스에 대하여 제공됩니다. 지원방법은 회사에서 정한 고객지원 정책에 따라
            제공됩니다.</p></li>
          <li><span>(7)</span> <p>고객지원은 고객이 구매한 서비스 사용 기간이 유효한 경우에 제공됩니다. 서비스 사용 기간이 만료되었을 경우 고객에게 고객지원을 해야 할 책임이 없습니다.
            고객지원은 제11조, 제12조에 명시된 준수사항을 모두 준수하는 경우에만 제공됩니다. 서비스의 일부만을 구매하여 구매하지 않은 서비스를 사용하여 발생한 문제와 이에 대한 지원요청에 대하여 고객에게
            고객지원을 해야 할 책임이 없습니다.</p></li>
          <li><span> (8) </span> <p>회사는 비회원이 전자서명이 완료된 문서(사본)을 분실 했거나 다운로드 링크의 유효기간이 만료되었을 경우 재교부(제공) 요청에 응할 의무가 없습니다.
            다만 회원의 경우 로그인 후 폼관리 화면(기능)을 통해 본인의 서명이 완료된 문서를 조회 또는 다운로드 할 수 있습니다.</p></li>
        </ul>

        <h4>제18조 [고객의 의무]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>고객은 이 약관이 정하는 바에 따라 서비스를 이용하며, 유료 서비스 이용 시 부과된 요금을 납부할 의무가 있습니다.</p></li>
          <li><span>(2)</span> <p>고객은 본 약관 및 관계법령에서 규정하는 사항과 서비스 이용안내 등을 통하여 공지되는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
          </p></li>
          <li><span>(3)</span> <p>고객이 다음 각호에 해당하는 경우에는 고객의 자료를 공지 없이 삭제하거나, 서비스의 전체 또는 일부의 이용을 제한할 수 있습니다.</p>
            <ul class="terms-2depth">
              <li><span>①</span> <p>다른 고객의 명의를 도용하는 행위</p></li>
              <li><span>② </span><p>본 서비스를 통하여 얻은 정보를 회사의 사전승낙 없이 서비스 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위</p></li>
              <li><span>③ </span><p>다량의 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 지속적으로 전송하여 서비스의 안정적 운영을 저해한 경우</p></li>
              <li><span> ④ </span><p>서비스 오류 또는 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</p></li>
              <li><span>⑤ </span><p>정보통신윤리위원회의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우</p></li>
              <li><span> ⑥ </span><p>모욕적이거나 위협적이어서 타인의 프라이버시를 침해할 수 있는 내용을 전송, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위</p></li>
              <li><span> ⑦ </span><p>범죄와 결부된다고 객관적으로 판단되는 행위</p></li>
              <li><span>⑧ </span><p>회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위</p></li>
              <li><span>⑨</span> <p>고객은 법률에 따라 특정 권리를 가질 수 있으며, 그러한 권리가 적용되는 경우 본 어떠한 내용도 그러한 권리에 영향을 주지 않습니다.</p></li>
            </ul>
          </li>
          <li><span>(4)</span> <p>서명 발신자는 서명 수신자의 실명, 본인 소유 이메일, 휴대폰 전화번호 일치 등의 본인 여부를 확인 하고 서명을 요청해야 합니다.</p></li>
          <li><span>(5) </span><p>서명 발신자는 발신 전 이메일, 휴대폰 본인 인증, 접근 암호 인증 등과 같은 본인인증 수단 설정을 통해 서명 수신자 본인 확인을 요구할 수 있습니다.</p></li>
        </ul>

        <h4>제19조 [무 보증]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회사는 서비스를 '있는 그대로', '모든 오류와 함께', '제공된 대로' 제공합니다.</p></li>
          <li><span>(2)</span> <p>고객은 고객의 컴퓨터 및 네트워크, 통신 시스템에 장애가 발생할 수 있고 때때로 작동이 중단될 수 있다는 것을 인정합니다.</p></li>
          <li><span>(3)</span> <p>회사는 서비스가 중단되지 않고 적시에 안전하게 오류 없이 제공되며 데이터 손실이 발생하지 않는다는 것을 보증하지 않습니다.</p></li>
          <li><span>(4)</span> <p>회사는 서비스의 상업성, 품질의 만족도, 특정 목적에의 적합성, 전문적 노력 및 비 침해에 대한 보증을 포함해 모든 묵시적 보증을 배제합니다.</p></li>
          <li><span>(5)</span> <p>고객은 법률에 따라 특정 권리를 가질 수 있으며, 그러한 권리가 적용되는 경우 본 어떠한 내용도 그러한 권리에 영향을 주지 않습니다.</p></li>
        </ul>

        <h4>제20조 [책임 제한]</h4>
        <ul class="terms-num">
          <li><span>(1) </span><p>고객은 회사로부터 구매한 서비스에 해당하고, 구매한 서비스의 사용기간이 유효한 경우 구매 금액 한도 내에서 회사의 귀책사유로 발생한 직접적 손해에 대하여만 배상을 받을
            수 있습니다.</p></li>
          <li><span>(2)</span> <p>고객은 일실 이익, 간접적, 우발적 또는 징벌적 손해 등을 포함하는 기타 손해에 대하여는 배상을 받을 수 없습니다.</p></li>
          <li><span> (8) </span> <p>회사는 비회원이 전자서명이 완료된 문서(사본)을 분실 했거나 다운로드 링크의 유효기간이 만료되었을 경우 재교부(제공) 요청에 응할 의무가 없습니다.
          <li><span>(3) </span><p>본 조가 고객의 손해를 완전히 배상하지 못하거나 중요한 목적을 달성하지 못하는 경우 또는 회사가 그러한 손해의 가능성을 알고 있었거나 알아야 하는 경우에도 이러한
            제한이 적용됩니다.</p></li>
        </ul>

        <h4> 제21조 [서비스 해지]</h4>
        <ul class="terms-num">
          <li><span>(1) </span><p>본 서비스 이용 중 다음 각 호에 해당하는 사유가 발생하는 경우, 회사 또는 고객은 상대방에게 해당 내용을 통지함으로써 본 서비스를 해지할 수 있습니다.</p>
            <ul class="terms-2depth">
              <li><span> ①</span> <p>상대방이 본 약관사항을 위반하거나 불이행하는 경우</p></li>
              <li><span>② </span><p>상대방이 부도, 파산, 회생절차 기타 이와 유사한 절차가 개시되는 경우</p></li>
              <li><span> ③ </span><p>상대방이 발행하거나 배서한 수표 또는 어음 기타 유가증권이 지급 거절되거나 부도 발생하는 경우</p></li>
              <li><span> ④ </span><p>상대방이 감독관청으로부터 영업정지 또는 영업취소 처분을 받는 경우</p></li>
            </ul>
          </li>
        </ul>

        <h3>제5장 기타</h3>
        <h4>제22조 [손해배상]</h4>
        <ul class="terms-num">
          <li><span>(1)</span><p>회사가 제공하는 서비스로 인하여 고객에게 손해가 발생한 경우 그러한 손해가 회사의 고의 또는 과실로 인한 것일 때, 회사는 그에 대한 책임을 부담하며, 그 책임의
            범위는 서비스 이용과 관련된
            통상손해로 합니다. 다만, 서비스 사용 불가 사실을 회사에 통보하여 회사가 접수한 후 48시간 이내에 서비스가 정상화된 경우는 제외합니다.</p></li>
          <li><span>(2)</span> <p> 손해배상 대상에는 회사와 구매계약이 체결된 서비스에 한합니다. 다만, 회사와 고객이 별도의 손해배상 범위를 합의한 경우에는 그 합의한 내용을 따릅니다.</p></li>
          <li><span>(3)</span> <p>본 약관을 불이행 또는 위반한 고객은 회사에게 발생한 손해에 대한 배상책임을 부담할 수 있으며, 손해배상의 규모는 현재 이용 중인 서비스 요금제의 결제액을 상회할
            수 없습니다.</p></li>
          <li><span>(4)</span> <p>회사는 고객의 손해배상 청구 접수 후 7일 이내에 그 결과를 고객에게 회신합니다.</p></li>
          <li><span>(5)</span><p>위 (4)항과 관련하여 소요 기간이 7일을 초과할 경우, 사전에 회신 가능 일자를 고객에게 통지하여야 하며, 그 일자는 청구 접수일을 기준으로 30일을 넘지 않아야
            합니다. 다만, 고객과 별도로 합의한 경우에는 합의된 내용에 따릅니다.</p></li>
          <li><span>(6)</span> <p>고객이 이 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 고객은 회사에 발생하는 손해를 배상하여야 합니다.</p></li>
          <li><span>(7)</span> <p>고객이 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로 인하여 회사가 당해 고객 이외의 제3자로부터 손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는
            경우 당해 고객은 자신의 책임과
            비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 고객은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</p></li>
        </ul>


        <h4>제23조 [면책사항]</h4>
        <ul class="terms-num">
          <li><span>(1)</span> <p>회사가 제공하는 서비스를 무료로 사용하는 동안 발생한 사항에 대해서는 손해배상을 하지 않습니다.</p></li>
          <li><span>(2)</span> <p>회사는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
          </p></li>
          <li><span>(3)</span> <p>회사는 고객의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다. 또한 서비스의 성능 개선과 유지보수를 위해 사전 고지된 서비스 중지는 서비스
            장애로 간주하지 않습니다. 단, 사전
            고지된 예정 시간을 초과하여 서비스가 제공되지 않을 경우에는 초과된 시간만큼을 장애시간으로 간주합니다.</p></li>
          <li><span>(4)</span> <p>회사는 고객이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</p></li>
          <li><span>(5)</span> <p>회사는 서비스를 통하여 제공된 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</p></li>
          <li><span> (6)</span> <p>회사는 서비스 이용과 관련하여 고객에게 발생한 손해 가운데 고객의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</p></li>
          <li><span>(7)</span> <p>회사는 고객의 컴퓨터 환경이나 회사의 관리 범위 밖의 상황으로 인하여 발생하는 제반 문제 또는 현재의 보안기술 수준으로는 방어가 곤란한 네트워크 해킹 등에 의해
            발생하는 문제에 대해서 책임을 지지 않습니다.</p></li>
          <li><span> (8) </span><p>회사는 고객 상호간 또는 고객과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임이 없습니다.</p></li>
          <li><span> (9)</span> <p>회사는 서명 발신자가 서명 권한을 가지지 않는 수신자에게 서명을 요청함으로써 발생하는 모든 문제에 대해 책임지지 않습니다.</p></li>
        </ul>

        <h4>제24조 [비밀유지]</h4>
        <ul class="terms-num">
          <li><span> (1)</span> <p>회사와 고객은 서비스 이용과정에서 취득한 상대방의 업무상(또는 영업상) 비밀 등에 대하여 서비스 사용기간 및 서비스 만료(또는 해지) 이후에도 상대방의 사전
            서면동의 없이 본 약관 이외의 목적으로 사용하거나 제3자에게 누설 또는 공개하여서는 안됩니다.</p></li>
          <li><span> (2)</span> <p>전항의 경우에 이미 공개된 정보, 관계 법령에 의하여 공개가 강제되는 경우, 법원 기타 감독기관의 요청에 의하여 부득이하게 공개를 해야 하는 경우에는 예외로
            합니다.</p></li>
        </ul>

        <h4>제25조 [분쟁해결 및 관할법원]</h4>
        <ul class="terms-num">
          <li><span> (1) </span><p>서비스 이용과 관련하여 회사와 고객 사이에 분쟁이 발생한 경우, 회사와 고객은 분쟁의 해결을 위해 성실히 협의합니다.</p></li>
          <li><span> (2)</span> <p>본 약관에 대하여 분쟁이 발생할 경우, 분쟁의 해결은 회사의 소재지 법원을 그 전속관할로 합니다.</p></li>
        </ul>

        <h4>제26조 [약관 외 준칙]</h4>
        <ul class="terms-num">
          <li><span> (1)</span> <p>본 약관에 명시되지 아니한 사항에 대해서는 관계 법령, 본 약관의 취지 및 동종업계의 관행에 따라서 해석 적용됩니다.</p></li>
        </ul>

        <div class="terms-date">시행일 : 2021년 02월 01일</div>
        <div>G CONT 서비스 약관 v1.1</div>
      
      </div>

    </div>
  </div>
