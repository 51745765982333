import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertConfirmService } from '../modal/alert-confirm/alert-confirm.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { TableRequestedService } from '../table-requested/table-requested.service';
import { TableRequestedMeService } from '../table-requested-me/table-requested-me.service';

import { CodeBookService } from '../../service/code-book/code-book.service';
import { faSearch, faTimes, faTv, faRedo, faExclamationCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import {TableRequestedBlockCertService} from "../table-requested-block-cert/table-requested-block-cert.service";

@Component({
  selector: 'app-contract-management',
  templateUrl: './contract-management.component.html',
  styleUrls: ['./contract-management.component.scss']
})

export class ContractManagementComponent implements OnInit {
  sidebar: any = 'contract_management';
  faSearch = faSearch;
  faTimes = faTimes;
  faTv = faTv;
  faRedo = faRedo;
  faExclamationCircle = faExclamationCircle;
  faDownload = faDownload;
  formSearch: FormGroup;
  tpTwo: any;


  codeBook: {
    SEARCH_CONT: {
      code: string,
      codeDc: string,
      codeGrpId: string,
      codeNm: string
    }[],
    CONTOR_PROGRESS: {
      code: string,
      codeDc: string,
      codeGrpId: string,
      codeNm: string
    }[]
  } = {
      SEARCH_CONT: [],
      CONTOR_PROGRESS: []
    }

  searchTypes: {
  }[] = []


  progressStates: {
    code: string,
    codeDc: string,
    codeGrpId: string,
    codeNm: string
  }[] = []
  btnState = {
    requested: {
      class: 'btn-bt-arrow-red',
      display: true
    },
    requested_to_me: {
      class: 'btn-bt-arrow',
      display: false
    },
    requested_blockCert: {
      class: 'btn-bt-arrow',
      display: false
    }

  };
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private tableRequestedService: TableRequestedService,
    private tableRequestedMeService: TableRequestedMeService,
    private alertConfirmService: AlertConfirmService,
    private codeBookService: CodeBookService,
    private tableRequestedBlockCertService : TableRequestedBlockCertService,
  ) {
    this.sidebarService.setManuActive(this.sidebar);
  }

  ngOnInit(): void {
    this.tableRequestedMeService.setState(true);
    this.tableRequestedService.setState(true);
    this.tableRequestedBlockCertService.setState(true);
    this.getCodeBook("SEARCH_CONT");
    this.getCodeBook("CONTOR_PROGRESS");
    this.initFormLogin();
    this.route.queryParams.subscribe(
      params => {
        if (params.requested != undefined) {
          if (params.requested === 'requested_to_me') {
            this.btnState[params.requested].class = 'btn-bt-arrow-red';
            this.btnState[params.requested].display = true;
            this.tableRequestedService.component.display = true;
            this.tableRequestedBlockCertService.component.display = true;

            this.btnState.requested.class = 'btn-bt-arrow';
            this.btnState.requested.display = false;

            this.btnState.requested_blockCert.class = 'btn-bt-arrow';
            this.btnState.requested_blockCert.display = false;

            this.tableRequestedService.component.display = false;
            this.tableRequestedMeService.component.display = false;

          } else if (params.requested === 'requested_blockCert') {

            this.btnState[params.requested].class = 'btn-bt-arrow-red';
            this.btnState[params.requested].display = true;

            this.tableRequestedService.component.display = true;
            this.tableRequestedMeService.component.display = true;

            this.btnState.requested.class = 'btn-bt-arrow';
            this.btnState.requested.display = false;

            this.btnState.requested_to_me.class = 'btn-bt-arrow';
            this.btnState.requested_to_me.display = false;

            this.tableRequestedService.component.display = false;
            this.tableRequestedMeService.component.display = false;

          } else {
            this.btnState.requested_to_me.class = 'btn-bt-arrow';
            this.btnState.requested_to_me.display = false;

            this.btnState.requested_blockCert.class = 'btn-bt-arrow';
            this.btnState.requested_blockCert.display = false;

            this.tableRequestedService.component.display = false;

            this.btnState.requested.class = 'btn-bt-arrow-red';
            this.btnState.requested.display = true;

            this.tableRequestedMeService.component.display = true;
            this.tableRequestedBlockCertService.component.display = true;
          }
        }

      });
  }

  ngAfterViewInit(): void {
    this.sidebarService.setManuActive(this.sidebar);
  }

  setDataSerach(type: string, value: any): void {
    if (this.btnState.requested.display) {
      if (type === 'searchText') {
        this.tableRequestedService.setText(value);
      } else {
        this.tableRequestedService.setSerachType(value);
      }
    }
    if (this.btnState.requested_to_me) {
      if (type === 'searchText') {
        this.tableRequestedMeService.setText(value);
      } else {
        this.tableRequestedMeService.setSerachType(value);
      }
    }
    if (this.btnState.requested_blockCert) {
      if (type === 'searchText') {
        this.tableRequestedBlockCertService.setText(value);
      } else {
        this.tableRequestedBlockCertService.setSerachType(value);
      }
    }
  }

  onSerach() {
    if (this.btnState.requested.display) {
      this.tableRequestedService.setPageNo(0);
      this.tableRequestedService.setState(true);
    }

    if (this.btnState.requested_to_me) {
      this.tableRequestedMeService.setPageNo(0);
      this.tableRequestedMeService.setState(true);
    }

    if (this.btnState.requested_blockCert) {
      this.tableRequestedBlockCertService.setPageNo(0);
      this.tableRequestedBlockCertService.setState(true);
    }
  }

  initFormLogin() {
    this.formSearch = this.fb.group({
      serachType: ['000001'],
      searchText: [null]
    });

    this.formSearch.controls['searchText'].disable();

    this.formSearch.get('searchText').valueChanges.subscribe(selectValue => {
      if (selectValue !== '') {
        if (this.formSearch.get('serachType').value == '000003' || this.formSearch.get('serachType').value == '000004') {
          let str = new Date(selectValue);
          let strDate = str.getFullYear() + "/" + ((str.getMonth() + 1) < 10 ? '0' + (str.getMonth() + 1) : (str.getMonth() + 1)) + "/" + (str.getDate() < 10 ? '0' + str.getDate() : str.getDate());
          selectValue = strDate;
        }
        this.setDataSerach('searchText', selectValue);
      } else {
        this.setDataSerach('searchText', selectValue);
      }
    });

    this.formSearch.get("serachType").valueChanges.subscribe(selectValue => {
      this.setDataSerach('serachType', selectValue);
      this.formSearch.get('searchText').setValue('');
      if (selectValue !== '000001') {
        this.formSearch.controls['searchText'].enable();
        this.formSearch.get('searchText').setValue("");
      } else {
        if (selectValue == '000005') {
          this.formSearch.get('searchText').setValue('000001');
        } else {
          this.formSearch.get('searchText').setValue("");
        }
        this.formSearch.controls['searchText'].disable();
      }
    });
  }

  onClickBtnSoon() {
    this.alertConfirmService.open(
      "SOON",
      '',
      {
        func: () => {
          this.alertConfirmService.close();
        }
      },
      {
        func: () => {
          this.alertConfirmService.close();
        }
      }
    );
  }

  btnSetState(keyOne: any, keyTwo: any, keyThree: any) {
    this.btnState[keyOne].class = 'btn-bt-arrow-red';
    this.btnState[keyOne].display = true;

    this.btnState[keyTwo].class = 'btn-bt-arrow';
    this.btnState[keyTwo].display = false;

    this.btnState[keyThree].class = 'btn-bt-arrow';
    this.btnState[keyThree].display = false;

    this.tableRequestedMeService.setState(true);
    this.tableRequestedService.setState(true);
    this.tableRequestedBlockCertService.setState(true);
  }

  getCodeBook(type: any): void {
    if(type === 'CONTOR_PROGRESS' && this.tableRequestedBlockCertService.component.display
        || type === 'CONTOR_PROGRESS' && this.btnState.requested_blockCert){
        this.codeBook[type] =
            [
              {codeGrpId: "CONTOR_PROGRESS", code: "S0000", codeNm: "성공", codeDc: null}
              ,{codeGrpId: "CONTOR_PROGRESS", code: "S0001", codeNm: "진행중", codeDc: null}
              ,{codeGrpId: "CONTOR_PROGRESS", code: "E0001", codeNm: "실패", codeDc: null}
            ]
    } else {
      this.codeBookService.getCodeBook(type).subscribe((rs: any) => {
        if (rs.status) {
          this.codeBook[type] = rs.data;
        }
      });
    }
  }

}
