import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addComma'
})
export class AddCommaPipe implements PipeTransform {
  transform(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
