<section class="w-100" style="background-color: #e2e6eb;min-height: 90vh;">
    <div class="container-lg ml-lg-0 mr-lg-auto p-0">

        <h3 _ngcontent-yjk-c119="" class="tit-tem-man-001" style="padding-bottom: 25px;">전자계약 인증 완료 </h3>

        <div class="row">
            <div class="p-0 col-md-12">
                <div class="col-xl-9 py-3">
                    <div class="form-group">
                        <label for="contractname">계약서 이름</label>
                        <input type="text" class="form-control" id="contractname" value="{{filename}}" placeholder="표준근로계약서"
                            aria-describedby="name">
                        <small id="name" class="form-text text-muted"></small>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row justify-content-center">
            <div class="col-md-3 py-3" style="float: left;">
                <img src="{{src}}" class="img-fluid">
            </div>

            <div class="col-md-3 text-center" style="font-size: large; padding-top: 10rem">
                [안내]
                <br>
                최종 인증 체결은 시스템 특성상
                <br>
                일정시간(최대 30분)이 소요되므로
                <br>
                [통합계약관리 > 계약서 인증 관리]에서
                <br>
                최종 결과를 확인 부탁드립니다.
            </div>

        </div> -->

        <div class="row justify-content-center">
            <div class="col-xl-3">
                <img src="{{src}}" class="img-fluid" style="border: 1px solid #ced4da;">
            </div>

            <div class="col-xl-9" style="margin-top: auto;">
                <div class="row">
                    <div class="col-xl-8">
                        <div class="p-4 mt-3" style="display: flex; background: #d5d9dd;">
                            <img class="mr-2"src="../../../../assets/img/icon/ico-info-gray.png" style="width:25px; height:25px;" alt="안내 아이콘"/>
                            <p class="p-0 m-0" style="word-break: keep-all; font-size: 13px; color: #555555;">최종 인증 체결은 시스템 특성상 일정시간(최대 30분)이 소요되므로 <br/>
                                <span class="underline">[통합계약관리 > 계약서 인증 관리]에서 최종 결과를 확인</span> 부탁드립니다.</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="row">
            <div class="col-md-12 py-3 mt-3 text-center">
                <button (click)="goTo()" class="btn btn-big crimsonbg">
                    확인
                </button>
            </div>
        </div>

    </div>
</section>
