<section style="background: #e9ecef" class="container-fluid ">

    <section class="container">



        <div style="background: #e9ecef;height: 100vh !important;" class="h-100 align-items-center row d-flex justify-content-center" id="recipient-confirm-pwd">

            <div class=" py-3 d-flex justify-content-center">
                <form [formGroup]="formInputPassword" (ngSubmit)="onSubmit()" class="confirmForm bg-white" name="form_chk" 
                    style="border-top: 2px solid #111; width: 100%;   margin-top: 115px;  padding: 70px 50px 60px 50px;">

                    <input [type]="'hidden'" type="text" [name]="'m'" [value]="'checkplusService'">
                    <input [type]="'hidden'" type="text" [name]="'EncodeData'" [value]="sEncData">

                    <div class="forgotTxthead text-center ">
                        <h3><b>{{contNm}} 님, 반갑습니다.</b></h3>
                        <h5 class=" m-0 ">비밀번호가 설정된 계약 건입니다.</h5>
                        <h5><span>“비밀번호”</span>를 입력해주세요.</h5>

                    </div>

                    <div class="form-group row m-0 confirmFormInput">
                        <div class="col-sm-12 p-0">
                            <input type="password" class="form-control inp-tmp-nam-001" formControlName='contorPass'
                                placeholder="비밀번호를 입력해주세요" />
                        </div>
                    </div>

                    <div class="form-group row d-flex justify-content-center mb-0">
                        <button class="btn btn-big btn-crimson ml-0" style="width: auto !important;"
                            type="submit">
                            확인
                        </button>

                    </div>



                </form>


            </div>
        </div>


     

    </section>


</section>