import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { RecipientService } from '../recipient/recipient.service';
import { ContractService } from '../../../service/contract/contract.service';
import { LoadingService } from '../../../shared/loading/loading.service';
import { API_URL } from 'src/app/manage/service/api.constant';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-recipient-confirmation-select-type-user',
  templateUrl: './recipient-confirmation-select-type-user.component.html',
  styleUrls: ['./recipient-confirmation-select-type-user.component.scss', '../../../../frontpage/forgot-password/forgot-password.component.scss']
})
export class RecipientConfirmationSelectTypeUserComponent implements OnInit {
  sEncData;
  constructor(
    private router: Router,
    private recipientService: RecipientService,
    private contractService: ContractService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document  
  ) { 
    window['RecipientConfirmationPhoneComponentRef'] = {component: this, zone: _ngZone, loadAngularFunction: () => this.nextPage(),};
  }

  ngOnInit(): void {
  }

  onClickBtnType(type: string) {
    this.recipientService.setMember(type);
    if (type === 'NOT_MEMBER') {
      let data = {
        contractMail: this.recipientService.getMail(),
        shortUrl: this.recipientService.getShortUrl()
      }
      this.loadingService.openLoading();
      this.contractService.getUserContractNotMember(data).subscribe((rs: any) => {
        this.loadingService.closeLoading();
        if (rs.status) {
          if (this.recipientService.getVerifi().pwd === "Y") {
            this.recipientService.setState('password');
          } 
          
          // else if (this.recipientService.getVerifi().phone === "Y") {
          //   this.http.get<any>(API_URL.mobileVerification).subscribe(res => {
          //     console.log('res', res);
          //     this.sEncData = res.data.encData;
          //     setTimeout(() => {
          //     window.open(`https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb?m=checkplusService&EncodeData=${this.sEncData}`, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
          //     });
          //   });
          // } 
          
          else {
            this.recipientService.setState('write');
          }
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  nextPage() {
    this.recipientService.setState('write');
  }

  onPrevious(state: any){
    if (this.recipientService.getVerifi().pwd === "Y")
      this.recipientService.setState('phone');
    else {
      this.recipientService.setState('email');
    }
  }

  goToHome() {
    this.router.navigate(['/']);
  }
}
