<div class="bg-modal" *ngIf="show">
    <!-- Modal -->

    <div class="modal fade show importModal" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content rounded-0" style="background: transparent">

                <div class="modal-header rounded-0 pb-0 border-0 headerPad" style="background: #ffffff;color: #000000;">

                    <div class="border-bottom border-dark d-flex w-100  ">
                        <h5 class="modal-title font-weight-light" id="exampleModalLabel">조직 import

                        </h5>
                        <a type="button" style="color: #000000;" class="close" (click)="closeModal()"
                            data-dismiss="modal" aria-label="Close">
                            <img src="../../../../../assets/img/icon/exitbtn.png"></a>
                    </div>

                </div>





                <div class="modal-body rounded-0 bodyPadd"
                    style="background: #ffffff;color: #000000; overflow: hidden;">
                    <div class="row m-0">


                        <section class="container-fluid" *ngIf="state == 'selectImport'">
                            <div class="row ">
                                <div class="col-12">
                                    <h4 class="text-center tit-sel-001">조직 Import 파일 양식 다운로드 및 업로드</h4>
                                </div>
                            </div>


                            <div class="row">
                                <div class="d-flex justify-content-center w-100 importBtns">

                                    <div class="btnboxA d-flex align-items-center justify-items-center text-center item"
                                        (click)="onDownloadExcel()">
                                        <div class="text-center w-100">
                                            <p>조직 리스트.xlsx<br /><span>다운로드</span></p>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-items-center text-center item btnboxB"
                                        (click)="file.click()">
                                        <input style="display: none;" (change)="addfile($event)"
                                            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            type="file" #file>
                                        <div class="text-center w-100">
                                            <p>파일<br />업로드</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row ">
                                <div class="col-12 bg-detail">
                                    <ol class=" tex-detail">
                                        <li>1. 아래 엑셀 파일 양식을 다운로드 받습니다.</li>
                                        <li>2. 다운로드 받으신 엑셀 파일을 열어서 컬럼 순서에 맞게 계약 대상자들을 입력해주세요. (컬럼 정보가 비어 있어서는 안됩니다.)</li>
                                        <li>3. 입력이 완료 되시면 해당 파일을 다시 업로드해주세요.</li>
                                        <li>4. 엑셀 파일을 업로드하시면 등록된 수신자 리스트를 확인하실 수 있습니다.</li>
                                    </ol>
                                </div>
                            </div>
                        </section>
                        <section class="container-fluid" *ngIf="state == 'tableData'">
                            <div class="row">
                                <div class="col-12 pt-3">
                                    <h3 class="text-center tit-tab-001">조직원 확인</h3>
                                    <h5 class="text-center del-tab-001">업로드한 조직원 목록을 확인 후, Import를 진행 하시려면 아래 <span
                                            style="color: rgb(255,0,0);">“확인”</span> 버튼을 클릭해주세요</h5>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-12 d-flex justify-content-between">
                                    <form [formGroup]=formSearch class="">
                                        <div class="input-group tableInput2">
                                            <input type="text" formControlName='searchText'
                                                class="form-control form-control-sm shadow-none inp-sea-typ-001"
                                                placeholder="이름" style="box-shadow: none !important;"
                                                aria-describedby="button-addon2" (keyup.enter)="searchMembers()">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary btn-search btn-sm"
                                                    style="color: #000;" (click)="searchMembers()">
                                                    <img src="../../../assets/img/frontpage/searchicons.png"
                                                        width="16px">
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="">
                                        <span class="">
                                            총 {{totle_item}}건
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <table class="w-100">
                                        <thead>
                                            <tr class="tab-th-001">
                                                <th class="text-center">
                                                    <span>번호</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>이름</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>직급</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>부서</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>이메일</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>휴대폰 번호</span>
                                                </th>
                                                 <th class="text-center">
                                                    <span>삭제</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let user of dataTables[(pagination.currentPage - 1)];let i = index">
                                                <td class="text-center">
                                                    {{(user.no)}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.name}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.rank}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.division}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.mail}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.phone}}
                                                </td>
                                                <td class="text-center">
                                                    <span class="rem-use-001" (click)="removeItem(user.no)">X</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-12 d-flex justify-content-center">
                                    <app-pagination [currentPage]="pagination.currentPage" [maxDisplayPage]="5"
                                        [maxPage]="pagination.maxPage" (seclectPage)="seclectPage($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </section>
                        <section class="container-fluid" *ngIf="state == 'tableSerachData'">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-center tit-tab-001 pt-3">조직원 확인</h3>
                                    <h5 class="text-center del-tab-001">업로드한 조직원 목록을 확인 후, Import를 진행 하시려면 아래 <span
                                            style="color: rgb(255,0,0);">“확인”</span> 버튼을 클릭해주세요</h5>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-12 d-flex justify-content-between">
                                    <form [formGroup]=formSearch class="">
                                        <div class="input-group tableInput2">
                                            <input type="text" formControlName='searchText'
                                                class="form-control form-control-sm shadow-none inp-sea-typ-001"
                                                placeholder="이름" style="box-shadow: none !important;"
                                                aria-describedby="button-addon2" (keyup.enter)="searchMembers()">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary btn-search btn-sm"
                                                    style="color: #000;" (click)="searchMembers()">
                                                    <img src="../../../assets/img/frontpage/searchicons.png"
                                                        width="16px">
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="">
                                        <span class="">
                                            총 {{totle_itemSerach}}건
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <table class="w-100">
                                        <thead>
                                            <tr class="tab-th-001">
                                                <th class="text-center">
                                                    <span>번호</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>이름</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>직급</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>부서</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>이메일</span>
                                                </th>
                                                <th class="text-center">
                                                    <span>휴대폰 번호</span>
                                                </th>
                                                 <th class="text-center">
                                                    <span>삭제</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let user of serachTables[(paginationSerach.currentPage - 1)];let i = index">
                                                <td class="text-center">
                                                    {{(user.no)}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.name}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.rank}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.division}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.mail}}
                                                </td>
                                                <td class="text-center">
                                                    {{user.phone}}
                                                </td>
                                                <td class="text-center">
                                                    <span class="rem-use-001" (click)="removeItem(user.no)">X</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-12 d-flex justify-content-center">
                                    <app-pagination [currentPage]="paginationSerach.currentPage" [maxDisplayPage]="5"
                                        [maxPage]="paginationSerach.maxPage" (seclectPage)="seclectSerachPage($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </section>

                        <div class="w-100 text-center ">

                            <button type="button" class="offbtn btn btn-middle-short btn-white"
                                (click)="closeModal()">닫기</button>
                            <button type="button" *ngIf="state == 'tableData'" class="btn btn-middle-short btn-black"
                                (click)="onUploadUserExcal()">확인</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>


</div>