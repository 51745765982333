import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigitalsignatureService {

  pathsetDigitalSignature: string = API_URL.setDigitalSignature;
  pathUploadSignatureUser: string = API_URL.uploadSignatureUser;
  pathUploadSignatureUserNotMember:string = API_URL.uploadSignatureUserNotMember;
  pathUploadAttachmentFile: string = API_URL.uploadAttachmentFile;

  constructor(
    private http: HttpClient
  ) { }


  public setSignature(formData: FormData): Observable<HttpEvent<any>> {
    // Content-Type: application/vnd.openxmlformats-officedocument.presentationml.presentation

    const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');
    const req = new HttpRequest('POST', this.pathsetDigitalSignature, formData, {
      reportProgress: true,
      responseType: 'json'
    },);

    return this.http.request(req);
  }

  public uploadAttachmentFile(formData: FormData): Observable<HttpEvent<any>> {

    const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');
    const req = new HttpRequest('POST', this.pathUploadAttachmentFile, formData, {
      reportProgress: true,
      responseType: 'json'
    },);

    return this.http.request(req);
  }

  public uploadSignatureUser(formData: FormData){
    return this.http.post(this.pathUploadSignatureUser,formData);
  }

  public uploadSignatureUserNotMember(formData: FormData){
    return this.http.post(this.pathUploadSignatureUserNotMember,formData);
  }
}
