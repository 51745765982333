import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-dashboard',
  templateUrl: './layout-dashboard.component.html',
  styleUrls: ['./layout-dashboard.component.scss']
})
export class LayoutDashboardComponent implements OnInit {
  sidebar = ""
  constructor() { }

  ngOnInit(): void {
  }

  closeNav() {
    var colnav = document.getElementById("navbarSupportedContent");
    var burgerX = document.getElementById("tognav");
    document.getElementById("mySidenav").style.width = "0px";
    document.getElementById("mySubnav").style.width = "0px";
    document.getElementById("mySubnav2").style.width = "0px";
    document.getElementsByClassName("body-container")[0].setAttribute("style", 'overflow-y:auto;');
    colnav.classList.remove("show");
    burgerX.classList.remove("is-active");
    document.getElementById("bkImg").style.visibility = "visible";
  }

}
