<section class="container-fluid">
  <div class="row">
    <div class="col-12 btnnav px-0 d-flex justify-content-center ">
      <button class="btn btnwidth {{btnState.signaturedrawing.class}}"
        (click)="btnSetState('signaturedrawing','uploadimage')">{{textBtn}}</button>
      <button class="btn btnwidth {{btnState.uploadimage.class}}"
        (click)="btnSetState('uploadimage','signaturedrawing')">이미지 업로드 </button>
    </div>
  </div>
  <div class="row" *ngIf="btnState.signaturedrawing.display">
    <div class="col-12 px-0 d-flex flex-row border-body-modal">
      <div id="bodyCanvas" class="canvas">
        <signature-pad #signaturePad class="signature-pad" [options]="options" (onBeginEvent)="drawBegin($event)"
          (onEndEvent)="drawComplete($event)">
        </signature-pad>
      </div>
      <div class="d-flex justify-content-center">
        <ul class="signatureSquare" style=" list-style-type:none">
          <li>
            <div style="width: 32px;height: 32px;background: rgb(0,0,0);" (click)="penColor('rgb(0,0,0)')">

            </div>
          </li>
          <li>
            <div style="margin-top:20px; width: 32px;height: 32px;background: rgb(248,38,68);"
              (click)="penColor('rgb(248,38,68)')">

            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 p-0 text-right">
      <button class="btn btn-small-long btn-white m-0" (click)="clear()">
        지우기
      </button>
    </div>
  </div>


  <div class="row d-flex justify-content-center" *ngIf="btnState.uploadimage.display">
    <div class="row justify-content-center">
      <div style="margin: 29px 0px 29px 0px;" class="col-12 d-flex justify-content-center align-items-center">
        <input style="display: none;" (change)="addfile($event)" type="file" #file>
        <label class="topUpTitle " id="upTitle">서명, 도장 이미지 파일을
          업로드해주세요.(1MB 이하)
        </label>

        <div *ngIf="display.range">
          <label class="topUpTitle">업로드 파일<span style="padding: 0px 6px;">:</span> </label>
        </div>


        <label *ngIf="display.range" class="m-0 upLabel topUpTitle">
          {{filName}}
        </label>


        <button *ngIf="display.range" (click)="cancelUploadImage()"
          class="btn btn-small-long btn-white clickUpload">취소</button>

      </div>
      <div class="col-12 d-flex justify-content-center">
        <label style="min-width: 75px; color:#555555; font-size:14px; " class="m-0" *ngIf="display.range">이미지 영역을 드래그하여
          크기를 정하고 명도 대비를
          조정해주세요.
        </label>
        <button (click)="file.click()" id="upbtn" class="btn uploadSignat"><img
            src="../../../../assets/img/icon/uploadbtnicon.png">이미지
          업로드
        </button>
      </div>
    </div>

    <div class="d-flex align-items-center choptheImage">
      <angular-cropper class="w-100" #angularCropper [cropperOptions]="config"
        [imageUrl]="img_def">
      </angular-cropper>
    </div>

    <div style="margin-bottom: 47px;" class=" d-flex justify-content-center align-items-center" *ngIf="display.range">

      <label for="rangesl" style="font-size: 14px;" class="m-0 rangeLabel">명도 대비 </label>
      <div class="sliderCont">
        <input type="text" id="slider" name="rangesl" class="slider" />
      </div>
    </div>
  </div>
</section>

