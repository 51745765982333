<div id="sidebar-wrapper">
  <div class="nav flex-column nav-pills bd-highlight">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a style="padding:20px;display: block;border-bottom:1px solid #eeeeee"
          href="#/creating-electronic#v-pills-home">
          <button class="btn btn-big-px btn-crimson  m-0">
            전자계약서 만들기
          </button>
        </a>
      </li>
    </ul>
  </div>
  <div class="nav flex-column nav-pills bd-highlight" id="v-pills-tab" role="tablist" aria-orientation="vertical">

    <a class="nav-link {{manu.home.c}}" id="v-pills-home-tab" href="#/main" aria-controls="v-pills-home"
      aria-selected="true">홈
    </a>

    <a class="nav-link {{manu.template_management.c}}" href="#/template-management#v-pills-management"
      id="v-pills-messages-tab" aria-controls="v-pills-management" aria-selected="false">
      계약서 관리
    </a>


    <a class="nav-link {{manu.contract_management.c}}" id="v-pills-contract-tab"
      href="#/contract-management?requested=requested" aria-controls="v-pills-home" aria-selected="true">
      통합 계약 관리
    </a>
    <ul class="sub-manu-side mb-0" *ngIf="manu.contract_management.b">
      <li [ngClass]="(subManu == 'requested') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'requested') ? 'sub-act-001' : 'sub-man-001'"
          href="#/contract-management?requested=requested">요청한 전자계약</a>
      </li>
      <li [ngClass]="(subManu == 'requested_to_me') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'requested_to_me') ? 'sub-act-001' : 'sub-man-001'"
          href="#/contract-management?requested=requested_to_me">나에게 요청된 전자 계약</a>
      </li>
      <li [ngClass]="(subManu == 'requested_blockCert') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'requested_blockCert') ? 'sub-act-001' : 'sub-man-001'"
           href="#/contract-management?requested=requested_blockCert">계약서 인증 관리</a>
      </li>
    </ul>

    <a class="mb-0 nav-link {{manu.set.c}}" id="v-pills-settings-tab-1" href="#/information-manage#v-pills-settings-1"
      aria-controls="v-pills-settings-1" aria-selected="false">마이 페이지

    </a>

    <ul id="configList" class="sub-manu-side mb-0" *ngIf="manu.set.b">
      <li [ngClass]="(subManu == 'information-manage') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'information-manage') ? 'sub-act-001' : 'sub-man-001'" href="#/information-manage">내
          정보 관리</a>
      </li>
      <li [ngClass]="(subManu == 'signature-management') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'signature-management') ? 'sub-act-001' : 'sub-man-001'"
          href="#/signature-management">서명/도장 관리</a>
      </li>
      <li [ngClass]="(subManu == 'payment-management') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'payment-management') ? 'sub-act-001' : 'sub-man-001'" href="#/payment-management">결제
          관리</a>
      </li>
      <li [ngClass]="(subManu == 'inquiry-managemant') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'inquiry-managemant') ? 'sub-act-001' : 'sub-man-001'" href="#/inquiry-managemant">내
          문의 관리</a>
      </li>
      <li [ngClass]="(subManu == 'mail-managemant') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'mail-managemant') ? 'sub-act-001' : 'sub-man-001'" href="#/mail-managemant">메일 템플릿
          관리</a>
      </li>
      <li *ngIf="(linkedId == '000002')"
        [ngClass]="(subManu == 'organization-management') ? 'sub-act-001' : 'sub-man-001'">
        <a [ngClass]="(subManu == 'organization-management') ? 'sub-act-001' : 'sub-man-001'"
          href="#/organization-management">조직 관리</a>
      </li>
    </ul>


  </div>
  <!-- <div class="d-flex justify-content-center w-100 flex-row bd-highlight bod-soc-001">
    <div class="p-2 bd-highlight"><a href="https://blog.naver.com/berithco" target="_blank"><span
          class="img-ico-soc-001 ico-soc-b-001"></span></a></div>
    <div class="p-2 bd-highlight"><a href="https://open.kakao.com/o/glnDlsz" target="_blank"><span
          class="img-ico-soc-001 ico-soc-tal-001"></span></a></div>
    <div class="p-2 bd-highlight"><a href="https://www.facebook.com/berithcoin/" target="_blank"><span
          class="img-ico-soc-001 ico-soc-fac-001"></span></a></div>
  </div> -->
  <div class="d-flex justify-content-center w-100 flex-row bd-highlight bod-soc-001">
    <div class="bd-highlight"><a href="https://gcont.co.kr" target="_blank"><span
          class="img-ico-soc-002 ico-soc-home"></span></a></div>
    <div class="bd-highlight"><a href="https://blog.naver.com/berithco" target="_blank"><span
          class="img-ico-soc-002 ico-soc-blog"></span></a></div>
    <div class="bd-highlight"><a href="https://open.kakao.com/o/glnDlsz" target="_blank"><span
          class="img-ico-soc-002 ico-soc-kakao"></span></a></div>
    <div class="bd-highlight"><a href="https://www.facebook.com/berithcoin/" target="_blank"><span
          class="img-ico-soc-002 ico-soc-fb"></span></a></div>
  </div>

</div>



<div id="mySidenav" class="sidenav">
  <a class="nav-link {{manu.home.c}}" id="v-pills-home-tab" (click)="visibleIC()" (click)="closeSubs()" href="#/main"
    aria-controls="v-pills-home" aria-selected="true">홈
  </a>
  <a class="nav-link {{manu.template_management.c}}" (click)="closeSubs()"
    href="#/template-management#v-pills-management" id="v-pills-messages-tab" aria-controls="v-pills-management"
    aria-selected="false">계약서 관리
  </a>


  <a class="nav-link {{manu.contract_management.c}}" (click)="openSub()" id="v-pills-contract-tab"
    href="#/contract-management?requested=requested" aria-controls="v-pills-home" aria-selected="true">통합 계약 관리
  </a>

  <a class="nav-link {{manu.set.c}}" id="v-pills-settings-tab-1" href="#/information-manage#v-pills-settings-1"
    aria-controls="v-pills-settings-1" (click)="openSub2()" aria-selected="false"> 마이 페이지
  </a>

  <div class="mobileIcons">
<!--
    <div class="d-flex justify-content-center w-100 flex-row bd-highlight bod-soc-001">
      <div class="p-2 bd-highlight"><a href="https://blog.naver.com/berithco" target="_blank"><span
            class="img-ico-soc-001 ico-soc-b-001"></span></a></div>
      <div class="p-2 bd-highlight"><a href="https://open.kakao.com/o/glnDlsz" target="_blank"><span
            class="img-ico-soc-001 ico-soc-tal-001"></span></a></div>
      <div class="p-2 bd-highlight"><a href="https://www.facebook.com/berithcoin/" target="_blank"><span
            class="img-ico-soc-001 ico-soc-fac-001"></span></a></div>
    </div> -->

    <div class="d-flex justify-content-center w-100 flex-row bd-highlight bod-soc-001">
      <div class="bd-highlight"><a href="https://gcont.co.kr" target="_blank"><span
            class="img-ico-soc-002 ico-soc-home"></span></a></div>
      <div class="bd-highlight"><a href="https://blog.naver.com/berithco" target="_blank"><span
            class="img-ico-soc-002 ico-soc-blog"></span></a></div>
      <div class="bd-highlight"><a href="https://open.kakao.com/o/glnDlsz" target="_blank"><span
            class="img-ico-soc-002 ico-soc-kakao"></span></a></div>
      <div class="bd-highlight"><a href="https://www.facebook.com/berithcoin/" target="_blank"><span
            class="img-ico-soc-002 ico-soc-fb"></span></a></div>
    </div>

</div>
</div>


<div id="mySubnav" class="subnav">
  <ul id="configA" class="sub-manu-side configA">
    <li [ngClass]="(subManu == 'requested') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'requested') ? 'sub-act-001' : 'sub-man-001'"
        href="#/contract-management?requested=requested">요청한 전자계약</a>
    </li>
    <li [ngClass]="(subManu == 'requested_to_me') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'requested_to_me') ? 'sub-act-001' : 'sub-man-001'"
        href="#/contract-management?requested=requested_to_me">나에게 요청된 전자 계약</a>
    </li>
    <li [ngClass]="(subManu == 'requested_blockCert') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'requested_blockCert') ? 'sub-act-001' : 'sub-man-001'"
         href="#/contract-management?requested=requested_blockCert">계약서 인증 관리</a>
    </li>
  </ul>

  <ul id="configB" class="sub-manu-side configB">
    <li [ngClass]="(subManu == 'information-manage') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'information-manage') ? 'sub-act-001' : 'sub-man-001'"
        href="#/information-manage">내
        정보 관리</a>
    </li>
    <li [ngClass]="(subManu == 'signature-management') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'signature-management') ? 'sub-act-001' : 'sub-man-001'"
        href="#/signature-management">서명/도장 관리</a>
    </li>
    <li [ngClass]="(subManu == 'payment-management') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'payment-management') ? 'sub-act-001' : 'sub-man-001'"
        href="#/payment-management">결제
        관리</a>
    </li>
    <li [ngClass]="(subManu == 'inquiry-managemant') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'inquiry-managemant') ? 'sub-act-001' : 'sub-man-001'"
        href="#/inquiry-managemant">내
        문의 관리</a>
    </li>
    <li [ngClass]="(subManu == 'mail-managemant') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'mail-managemant') ? 'sub-act-001' : 'sub-man-001'"
        href="#/mail-managemant">메일 템플릿
        관리</a>
    </li>
    <li *ngIf="(linkedId == '000002')"
      [ngClass]="(subManu == 'organization-management') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'organization-management') ? 'sub-act-001' : 'sub-man-001'"
        href="#/organization-management">조직 관리</a>
    </li>
  </ul>

</div>


<div id="mySubnav2" class="subnav2">

  <ul class="sub-manu-side" *ngIf="manu.set.b">
    <li [ngClass]="(subManu == 'information-manage') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'information-manage') ? 'sub-act-001' : 'sub-man-001'"
        href="#/information-manage">내
        정보 관리</a>
    </li>
    <li [ngClass]="(subManu == 'signature-management') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'signature-management') ? 'sub-act-001' : 'sub-man-001'"
        href="#/signature-management">서명/도장 관리</a>
    </li>
    <li [ngClass]="(subManu == 'payment-management') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'payment-management') ? 'sub-act-001' : 'sub-man-001'"
        href="#/payment-management">결제
        관리</a>
    </li>
    <li [ngClass]="(subManu == 'inquiry-managemant') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'inquiry-managemant') ? 'sub-act-001' : 'sub-man-001'"
        href="#/inquiry-managemant">내
        문의 관리</a>
    </li>
    <li [ngClass]="(subManu == 'mail-managemant') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'mail-managemant') ? 'sub-act-001' : 'sub-man-001'"
        href="#/mail-managemant">메일 템플릿
        관리</a>
    </li>
    <li *ngIf="(linkedId == '000002')"
      [ngClass]="(subManu == 'organization-management') ? 'sub-act-001' : 'sub-man-001'">
      <a (click)="closeSubs()" [ngClass]="(subManu == 'organization-management') ? 'sub-act-001' : 'sub-man-001'"
        href="#/organization-management">조직 관리</a>
    </li>
  </ul>

</div>
