import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  navbar: {
    creatingTemp: string,
    uploadTemp: string,
    selectTemp: string,
    tempManage: string,
    contorSend: string,
    contorMe: string,
    profileInfo: string,
    sigatureManage: string,
    payManage: string,
    inquiryManage: string,
    tempMail: string,
    orgManage: string
  } = {
      creatingTemp: '전자계약서 만들기',
      uploadTemp: '문서 업로드',
      selectTemp: '계약서 종류 선택',
      tempManage: '계약서 관리',
      contorSend: '요청한 전자계약',
      contorMe: '나에게 요청된 전자계약',
      profileInfo: '내 정보 관리',
      sigatureManage: '',
      payManage: '결제 관리',
      inquiryManage: '내 문의 관리',
      tempMail: '메일 템플릿 관리',
      orgManage: '조직 관리'
    }

  action: any = null;
  constructor() { }

  setAction(action?:
    'creatingTemp' |
    'uploadTemp' |
    'selectTemp' |
    'tempManage' |
    'contorSend' |
    'contorMe' |
    'profileInfo' |
    'sigatureManage' |
    'payManage' |
    'inquiryManage' |
    'tempMail' |
    'orgManage'
  ) {
    this.action = action;
  }

  getTitleNavber(): string {
    return this.navbar[this.action];
  }
}
