<div class="standardFront">
  <nav class="{{iconcompany}} navbar  navbar-expand-lg navbar-light fixed-top mainNav" id="mainNav">
    <div class="container-fluid navContainer ">
      <ul id="authics" class=" navbar-nav log-reg-pos-ico-001 {{mobileMenu}}">
        <ng-container *ngIf="showbtn">
          <li class="nav-item" style="margin: 0;padding: 0;">
            <a class="nav-link js-scroll-trigger" (click)="topFunction()" href="#/main">전자계약 홈</a>
          </li>
        </ng-container>

        <ng-container *ngIf="!showbtn">
          <li class="nav-item" style="margin: 0;padding: 0;padding-right: 10px;">
            <a class="nav-link js-scroll-trigger px-0 mx-0 {{action.login}}" href="#/login">로그인</a>
          </li>
          <li class="nav-item" style="margin: 0;padding: 0;">
            <a class="nav-link js-scroll-trigger px-0 mx-0"><span style="border: 0.25px solid #c4c4c4;"></span></a>
          </li>
          <li class="nav-item" style="margin: 0;padding: 0;padding-left: 10px;">
            <a class="px-0 mx-0 nav-link js-scroll-trigger registerspace px-0 {{action.register}}"
              href="#/register">회원가입</a>
          </li>
        </ng-container>

      </ul>
      <a *ngIf="showLogo" pageScroll class="navbar-brand js-scroll-trigger" (click)="toHomepage('#page-top')"
        href="#page-top">
        <img id="goBakk" width="60%" src="./assets/img/icon/backMain.png">
        <div id="theLogos" class="logoCls">
          <img class="darklogo {{iconcompany}}" width="125" src="./assets/img/frontpage/logo_gcont2x.png">
          <img class="lightlogo {{iconcompany}}" width="125" src="./assets/img/frontpage/logo_light.png">
        </div>
      </a>


      <div class="nav-hea barumBold text-center thePageTitle backLogo navbar-brand" *ngIf='showLogo'>
        {{navbarTitle}}
      </div>

      <div (click)="myFunction()" class="hamburger hamburger--3dy-r navbar-toggler navbar-toggler-right p-0"
        data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
        aria-label="Toggle navigation">


        <span class="{{iconcompany}} hamburger-box">

          <div class="blackburger">
            <div class="hamburger-inner"></div>
          </div>

          <div class="whiteburger">
            <div class="hamburger-inner"></div>
          </div>

        </span>

      </div>

      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="homelinks navbar-nav homelinksWidth ">
          <li class="nav-item  ">
            <a pageScroll id="goHome" class=" nav-link js-scroll-trigger {{Menuiconcompany2}}"
              (click)="toHomepage('#home-section')" href="#home-section">서비스 소개 </a>
          </li>

          <li class="nav-item">
            <a pageScroll class="nav-link js-scroll-trigger" (click)="toHomepage('#about')"
              href="#about">{{manu[selectl].about}}</a>
          </li>
          <li class="nav-item">
            <a pageScroll class="nav-link js-scroll-trigger" (click)="toHomepage('#service')"
              href="#service">{{manu[selectl].service}}</a>
          </li>
          <li class="nav-item">
            <a pageScroll href="javascript:void(0)" class="nav-link js-scroll-trigger {{Menuiconcompany}}" id="myBtn" (click)="topFunction()">{{manu[selectl].features}}</a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a pageScroll href="javascript:void(0)" class="nav-link js-scroll-trigger {{Menuiconcompany4}}" id="commuBtn" (click)="topFunctionCom()">{{manu[selectl].community}}</a>-->
<!--          </li>-->
          <li class="nav-item">
            <a pageScroll href="javascript:void(0)" [ngClass]="{'nav-link js-scroll-trigger': true, 'active': Menuiconcompany3 }" id="howBtn" (click)="toHowTo()">{{manu[selectl].howTo}}</a>
          </li>
        </ul>





        <div class="desklogins">

          <ul class="navbar-nav ">
            <ng-container *ngIf="showbtn">
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" (click)="topFunction()" href="#/main">전자계약 홈</a>
              </li>
            </ng-container>

            <ng-container *ngIf="!showbtn">
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger pl-0 {{action.login}}" href="#/login">로그인</a>
              </li>

              <li class="nav-item">
                <a class="mr-0 nav-link js-scroll-trigger  registerspace px-0 {{action.register}}"
                  href="#/register">회원가입</a>
              </li>
            </ng-container>

          </ul>

        </div>

      </div>
    </div>
  </nav>
</div>

<div class="oddFront">
  <nav class="{{iconcompany}} navbar  navbar-light fixed-top mainNav" id="mainNavTwo">
    <div class="container-fluid navContainer ">
      <ul id="authics" class=" navbar-nav log-reg-pos-ico-001 {{mobileMenu}}">
        <ng-container *ngIf="showbtn">
          <li class="nav-item" style="margin: 0;padding: 0;">
            <a class="nav-link js-scroll-trigger" (click)="topFunction()" href="#/main">전자계약 홈</a>
          </li>
        </ng-container>

        <ng-container *ngIf="!showbtn">
          <li class="nav-item" style="margin: 0;padding: 0;padding-right: 10px;">
            <a class="nav-link js-scroll-trigger px-0 mx-0 {{action.login}}" href="#/login">로그인</a>
          </li>
          <li class="nav-item" style="margin: 0;padding: 0;">
            <a class="nav-link js-scroll-trigger px-0 mx-0"><span style="border: 0.25px solid #c4c4c4;"></span></a>
          </li>
          <li class="nav-item" style="margin: 0;padding: 0;padding-left: 10px;">
            <a class="px-0 mx-0 nav-link js-scroll-trigger registerspace px-0 {{action.register}}"
              href="#/register">회원가입</a>
          </li>
        </ng-container>

      </ul>

      <a *ngIf="showLogo" pageScroll class="navbar-brand js-scroll-trigger" (click)="toHomepage('#page-top')"
        href="#page-top">
        <img id="goBakk2" width="60%" src="./assets/img/icon/backMain.png">
        <div id="theLogos2" class="logoCls">
          <img class="darklogo {{iconcompany}}" width="125" src="./assets/img/frontpage/logo_gcont2x.png">
          <img class="lightlogo {{iconcompany}}" width="125" src="./assets/img/frontpage/logo_light.png">
        </div>
      </a>

      <div class="nav-hea barumBold text-center thePageTitle backLogo navbar-brand" *ngIf='showLogo'>
        {{navbarTitle}}
      </div>

      <div (click)="myFunction2()" class="p-0 hamburger hamburger--3dy-r navbar-toggler navbar-toggler-right"
        data-toggle="collapse" data-target="#navbarResponsiveMob" aria-controls="navbarResponsiveMob"
        aria-expanded="false" aria-label="Toggle navigation">


        <span class="{{iconcompany}} hamburger-box">

          <div class="blackburger">
            <div class="hamburger-inner"></div>
          </div>

          <div class="whiteburger">
            <div class="hamburger-inner"></div>
          </div>

        </span>

      </div>





      <div class="collapse navbar-collapse" id="navbarResponsiveMob">
        <ul class="homelinks navbar-nav w-100">
          <li class="nav-item  ">
            <a pageScroll class=" nav-link js-scroll-trigger" (click)="toHomepage('#home-section')"
              href="#home-section">서비스 소개 </a>
          </li>

          <li class="nav-item">
            <a pageScroll class="nav-link js-scroll-trigger" (click)="toHomepage('#about')"
              href="#about">{{manu[selectl].about}}</a>
          </li>
          <li class="nav-item">
            <a pageScroll class="nav-link js-scroll-trigger" (click)="toHomepage('#service')"
              href="#service">{{manu[selectl].service}}</a>
          </li>
          <li class="nav-item">
            <a pageScroll class="nav-link js-scroll-trigger" id="leBtn" (click)="topFunction()"
              href="#/service-center">{{manu[selectl].features}}</a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a pageScroll class="nav-link js-scroll-trigger" id="commuBtn2" (click)="topFunctionCom()"-->
<!--               href="#/community">{{manu[selectl].community}}</a>-->
<!--          </li>-->
          <li class="nav-item">
            <a pageScroll class="nav-link js-scroll-trigger" id="howBtn2" (click)="toHowTo()">{{manu[selectl].howTo}}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
