import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SelectContractTypeService } from '../select-contract-type/select-contract-type.service';
import { ContractInformationParttimeService } from './contract-information-parttime.service';
import { Router } from '@angular/router';
import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { AlertService } from '../modal/alert/alert.service';
import { TemplateService } from '../../service/template/template.service';
import { LoadingService } from '../../shared/loading/loading.service';

import Regex from '../../shared/regex/regex';
import DateShared from '../../shared/date-shared/date-shared';

import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';
@Component({
  selector: 'app-contract-information-parttime',
  templateUrl: './contract-information-parttime.component.html',
  styleUrls: ['./contract-information-parttime.component.scss']
})
export class ContractInformationParttimeComponent implements OnInit {
  // sidebar: any = 'contract_information';
  dateShared: DateShared;
  formContractWord: FormGroup;

  valid: any = {
    employer: '',
    student: '',
    lcys: '',
    lcms: '',
    lcds: '',
    lcye: '',
    lcme: '',
    lcde: '',
    whs: '',
    wms: '',
    whe: '',
    wme: '',
    sahs: '',
    sams: '',
    sahe: '',
    same: '',
    suhs: '',
    sums: '',
    suhe: '',
    sume: '',
    salay: '',
    pday: '',
    cys: '',
    cms: '',
    cds: '',
    cye: '',
    cme: '',
    cde: '',
    addcone: '',
    mut: '',
    ceo: '',
    addctwo: '',
    name: ''
  };

  small: {
    employer: boolean,
    student: boolean,
    lcys: boolean,
    lcms: boolean,
    lcds: boolean,
    lcye: boolean,
    lcme: boolean,
    lcde: boolean,
    whs: boolean,
    wms: boolean,
    whe: boolean,
    wme: boolean,
    sahs: boolean,
    sams: boolean,
    sahe: boolean,
    same: boolean,
    suhs: boolean,
    sums: boolean,
    suhe: boolean,
    sume: boolean,
    salay: boolean,
    pday: boolean,
    cys: boolean,
    cms: boolean,
    cds: boolean,
    cye: boolean,
    cme: boolean,
    cde: boolean,
    addcone: boolean,
    mut: boolean,
    ceo: boolean,
    addctwo: boolean,
    name: boolean
  } = {
      employer: false,
      student: false,
      lcys: false,
      lcms: false,
      lcds: false,
      lcye: false,
      lcme: false,
      lcde: false,
      whs: false,
      wms: false,
      whe: false,
      wme: false,
      sahs: false,
      sams: false,
      sahe: false,
      same: false,
      suhs: false,
      sums: false,
      suhe: false,
      sume: false,
      salay: false,
      pday: false,
      cys: false,
      cms: false,
      cds: false,
      cye: false,
      cme: false,
      cde: false,
      addcone: false,
      mut: false,
      ceo: false,
      addctwo: false,
      name: false
    }

  inputs: {
    employer: any,
    student: any,
    lcys: any,
    lcms: any,
    lcds: any,
    lcye: any,
    lcme: any,
    lcde: any,
    whs: any,
    wms: any,
    whe: any,
    wme: any,
    sahs: any,
    sams: any,
    sahe: any,
    same: any,
    suhs: any,
    sums: any,
    suhe: any,
    sume: any,
    salay: any,
    pday: any,
    cys: any,
    cms: any,
    cds: any,
    cye: any,
    cme: any,
    cde: any,
    addcone: any,
    mut: any,
    ceo: any,
    addctwo: any,
    name: any,
    btn: {
      next: any,
    }
  } = {
      employer: null,
      student: null,
      lcys: null,
      lcms: null,
      lcds: null,
      lcye: null,
      lcme: null,
      lcde: null,
      whs: null,
      wms: null,
      whe: null,
      wme: null,
      sahs: null,
      sams: null,
      sahe: null,
      same: null,
      suhs: null,
      sums: null,
      suhe: null,
      sume: null,
      salay: null,
      pday: null,
      cys: null,
      cms: null,
      cds: null,
      cye: null,
      cme: null,
      cde: null,
      addcone: null,
      mut: null,
      ceo: null,
      addctwo: null,
      name: null,
      btn: {
        next: true
      }
    };

  data: {
    fiveYears: any[],
    months: any[],
    lcds: any[],
    lcde: any[],
    cds: any[],
    cde: any[],
    hours: any[],
    minutes: any[],
    dpays: any[],
  } = {
      fiveYears: [],
      months: [],
      lcds: [],
      lcde: [],
      cds: [],
      cde: [],
      hours: [],
      minutes: [],
      dpays: []
    }
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private contractInformationParttimeService: ContractInformationParttimeService,
    private editorPdfService: EditorPdfService,
    private selectContractTypeService: SelectContractTypeService,
    private alertService: AlertService,
    private templateService: TemplateService,
    private loadingService: LoadingService,
    private alertSharedService: AlertSharedService,
  ) {
    this.dateShared = new DateShared();
  }

  ngOnInit(): void {
    this.checkTemplateId();
    this.initFormContractWord();
    this.data.fiveYears = this.dateShared.getDataNextFiveYearBeforeFive();
    this.data.months = this.dateShared.getDataMonthsForYear();
    this.data.hours = this.dateShared.getHoursFull();
    this.data.minutes = this.dateShared.getMinutesFull();
    this.data.dpays = this.dateShared.daysInMonth('01', '2020');
    this.setDisabledMonthAndDay();

    
    if (!this.formContractWord.get('{whs}').value) {
      this.formContractWord.patchValue({
        '{whs}': 'N',
        '{whe}': 'N'
      });
    }

    if (!this.formContractWord.get('{sahs}').value) {
      this.formContractWord.patchValue({
        '{sahs}': 'N',
        '{sahe}': 'N'
      });
    }

    if (!this.formContractWord.get('{suhs}').value) {
      this.formContractWord.patchValue({
        '{suhs}': 'N',
        '{suhe}': 'N'
      });
    }

  }

  ngAfterViewInit(): void {
    this.intiElementInput();



  }

  setDisabledMonthAndDay() {
    if (this.selectContractTypeService.checkState()) {
      this.formContractWord.get('{lcms}').disable();
      this.formContractWord.get('{lcds}').disable();

      this.formContractWord.get('{lcme}').disable();
      this.formContractWord.get('{lcde}').disable();

      this.formContractWord.get('{cms}').disable();
      this.formContractWord.get('{cds}').disable();
    }
  }

  checkTemplateId(): void {
    if (this.selectContractTypeService.getTempId() == '') {
      this.router.navigate(['select-contract-type']);
    }
  }

  intiElementInput() {
    Object.keys(this.inputs).forEach(key => {
      if (key !== 'btn') {
        this.inputs[key] = document.getElementById(key);
      }
    });
    this.inputs.btn.next = true;
  }

  intiDataForService(key) {
    if (!this.selectContractTypeService.checkState()) {
      if (key === '{whs}') {
        console.log(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
      } else if (key === '{wms}') {
        console.log(this.contractInformationParttimeService.getDataFromContract('{whs}').split(":")[1]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract('{whs}').split(":")[1]);
      } else if (key === '{whe}') {
        console.log(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
      } else if (key === '{wme}') {
        console.log(this.contractInformationParttimeService.getDataFromContract('{whe}').split(":")[1]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract('{whe}').split(":")[1]);
      } else if (key === '{sahs}') {
        console.log(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
      } else if (key === '{sams}') {
        console.log(this.contractInformationParttimeService.getDataFromContract('{sahs}').split(":")[1]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract('{sahs}').split(":")[1]);
      } else if (key === '{sahe}') {
        console.log(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
      } else if (key === '{same}') {
        console.log(this.contractInformationParttimeService.getDataFromContract('{sahe}').split(":")[1]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract('{sahe}').split(":")[1]);
      } else if (key === '{suhs}') {
        console.log(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
      } else if (key === '{sums}') {
        console.log(this.contractInformationParttimeService.getDataFromContract('{suhs}').split(":")[1]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract('{suhs}').split(":")[1]);
      } else if (key === '{suhe}') {
        console.log(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract(key).split(":")[0]);
      } else if (key === '{sume}') {
        console.log(this.contractInformationParttimeService.getDataFromContract('{suhe}').split(":")[1]);
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract('{suhe}').split(":")[1]);
      } else {
        this.formContractWord.get(key).setValue(this.contractInformationParttimeService.getDataFromContract(key));
      }
    }
  }

  initFormContractWord() {
    this.formContractWord = this.fb.group({
      '{employer}': [null, Regex.Required],
      '{student}': [null, Regex.Required],
      '{lcys}': ['N', Regex.Required],
      '{lcms}': ['N', Regex.Required],
      '{lcds}': ['N', Regex.Required],
      '{lcye}': ['N', Regex.Required],
      '{lcme}': ['N', Regex.Required],
      '{lcde}': ['N', Regex.Required],
      '{whs}': ['N'],
      '{wms}': ['N'],
      '{whe}': ['N'],
      '{wme}': ['N'],
      '{sahs}': ['N'],
      '{sams}': ['N'],
      '{sahe}': ['N'],
      '{same}': ['N'],
      '{suhs}': ['N'],
      '{sums}': ['N'],
      '{suhe}': ['N'],
      '{sume}': ['N'],
      '{salay}': [null, Regex.Required],
      '{pday}': ['N', Regex.Required],
      '{cys}': ['N', Regex.Required],
      '{cms}': ['N', Regex.Required],
      '{cds}': ['N', Regex.Required],
      '{addcone}': [null, Regex.Required],
      '{mut}': [null, Regex.Required],
      '{ceo}': [null, Regex.Required],
      '{addctwo}': [null, Regex.Required],
      '{name}': [null, Regex.Required]
    });

    // ================================================================= {employer} =================================================================

    this.formContractWord.get('{employer}').valueChanges.subscribe(valueChanges => {
      this.valid.employer = Regex.validatorFormData(this.formContractWord.get('{employer}').status);
      this.small.employer = true;
      this.contractInformationParttimeService.setDataFromContract('{employer}', valueChanges);
    });

    this.intiDataForService('{employer}');
    // ================================================================= {student} =================================================================

    this.formContractWord.get('{student}').valueChanges.subscribe(valueChanges => {
      this.valid.student = Regex.validatorFormData(this.formContractWord.get('{student}').status);
      this.small.student = true;
      this.contractInformationParttimeService.setDataFromContract('{student}', valueChanges);
    });

    this.intiDataForService('{student}');
    // ================================================================= {lcys} =================================================================

    this.formContractWord.get('{lcys}').valueChanges.subscribe(valueChanges => {
      this.valid.lcys = Regex.validatorFormData(this.formContractWord.get('{lcys}').status);
      this.contractInformationParttimeService.setDataFromContract('{lcys}', valueChanges);
      if (!this.formContractWord.get('{lcys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcys}').setValue(valueChanges);
        this.formContractWord.get('{lcms}').setValue('N');
        this.formContractWord.get('{lcms}').enable();
      } else if (this.formContractWord.get('{lcys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcms}').setValue('N');
        this.formContractWord.get('{lcms}').enable();
      } else {
        this.formContractWord.get('{lcms}').disable();
      }
    });

    this.intiDataForService('{lcys}');
    // ================================================================= {lcms} =================================================================

    this.formContractWord.get('{lcms}').valueChanges.subscribe(valueChanges => {
      this.valid.lcms = Regex.validatorFormData(this.formContractWord.get('{lcms}').status);
      this.contractInformationParttimeService.setDataFromContract('{lcms}', valueChanges);
      if (!this.formContractWord.get('{lcms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcds}').setValue('N');
        this.formContractWord.get('{lcms}').setValue(valueChanges);
        this.data.lcds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{lcys}').value);
        this.formContractWord.get('{lcds}').enable();
      } else if (this.formContractWord.get('{lcms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcds}').setValue('N');
        this.data.lcds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{lcys}').value);
        this.formContractWord.get('{lcds}').enable();
      } else {
        this.formContractWord.get('{lcds}').disable();
      }
    });

    this.intiDataForService('{lcms}');
    // ================================================================= {lcds} =================================================================

    this.formContractWord.get('{lcds}').valueChanges.subscribe(valueChanges => {
      this.valid.lcds = Regex.validatorFormData(this.formContractWord.get('{lcds}').status);
      this.contractInformationParttimeService.setDataFromContract('{lcds}', valueChanges);
      if (!this.formContractWord.get('{lcds}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcds}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{lcds}');
    // ================================================================= {lcye} =================================================================

    this.formContractWord.get('{lcye}').valueChanges.subscribe(valueChanges => {
      this.valid.lcye = Regex.validatorFormData(this.formContractWord.get('{lcye}').status);
      this.contractInformationParttimeService.setDataFromContract('{lcye}', valueChanges);
      if (!this.formContractWord.get('{lcye}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcye}').setValue(valueChanges);
        this.formContractWord.get('{lcme}').setValue('N');
        this.formContractWord.get('{lcme}').enable();
      } else if (this.formContractWord.get('{lcye}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcme}').setValue('N');
        this.formContractWord.get('{lcme}').enable();
      } else {
        this.formContractWord.get('{lcme}').disable();
      }
    });

    this.intiDataForService('{lcye}');
    // ================================================================= {lcme} =================================================================

    this.formContractWord.get('{lcme}').valueChanges.subscribe(valueChanges => {
      this.valid.lcme = Regex.validatorFormData(this.formContractWord.get('{lcme}').status);
      this.contractInformationParttimeService.setDataFromContract('{lcme}', valueChanges);
      if (!this.formContractWord.get('{lcme}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcme}').setValue('N');
        this.formContractWord.get('{lcme}').setValue(valueChanges);
        this.data.lcde = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{lcye}').value);
        this.formContractWord.get('{lcde}').enable();
      } else if (this.formContractWord.get('{lcme}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcde}').setValue('N');
        this.data.lcde = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{lcye}').value);
        this.formContractWord.get('{lcde}').enable();
      } else {
        this.formContractWord.get('{lcde}').disable();
      }
    });

    this.intiDataForService('{lcme}');
    // ================================================================= {lcde} =================================================================

    this.formContractWord.get('{lcde}').valueChanges.subscribe(valueChanges => {
      this.valid.lcde = Regex.validatorFormData(this.formContractWord.get('{lcde}').status);
      this.contractInformationParttimeService.setDataFromContract('{lcde}', valueChanges);
      if (!this.formContractWord.get('{lcde}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{lcde}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{lcde}');
    // ================================================================= {whs} =================================================================

    this.formContractWord.get('{whs}').valueChanges.subscribe(valueChanges => {
      this.valid.whs = Regex.validatorFormData(this.formContractWord.get('{whs}').status);
      if (!this.formContractWord.get('{whs}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{whs}').setValue(valueChanges);
      }
      this.formContractWord.get('{wms}').setValue('N');
    });

    this.intiDataForService('{whs}');
    // ================================================================= {wms} =================================================================

    this.formContractWord.get('{wms}').valueChanges.subscribe(valueChanges => {
      this.valid.wms = Regex.validatorFormData(this.formContractWord.get('{wms}').status);
      if (!this.formContractWord.get('{wms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{wms}').setValue(valueChanges);
      }
      this.contractInformationParttimeService.setDataFromContract('{whs}', this.formContractWord.get('{whs}').value + ':' + valueChanges);
    });

    this.intiDataForService('{wms}');
    // ================================================================= {whe} =================================================================

    this.formContractWord.get('{whe}').valueChanges.subscribe(valueChanges => {
      this.valid.whe = Regex.validatorFormData(this.formContractWord.get('{whe}').status);
      if (!this.formContractWord.get('{whe}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{whe}').setValue(valueChanges);
      }
      this.formContractWord.get('{wme}').setValue('N');
    });

    this.intiDataForService('{whe}');
    // ================================================================= {wme} =================================================================

    this.formContractWord.get('{wme}').valueChanges.subscribe(valueChanges => {
      this.valid.wme = Regex.validatorFormData(this.formContractWord.get('{wme}').status);
      if (!this.formContractWord.get('{wme}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{wme}').setValue(valueChanges);
      }
      this.contractInformationParttimeService.setDataFromContract('{whe}', this.formContractWord.get('{whe}').value + ':' + valueChanges);
    });

    this.intiDataForService('{wme}');
    // ================================================================= {sahs} =================================================================

    this.formContractWord.get('{sahs}').valueChanges.subscribe(valueChanges => {
      this.valid.sahs = Regex.validatorFormData(this.formContractWord.get('{sahs}').status);
      if (!this.formContractWord.get('{sahs}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sahs}').setValue(valueChanges);
      }
      this.formContractWord.get('{sams}').setValue('N');
    });

    this.intiDataForService('{sahs}');
    // ================================================================= {sams} =================================================================

    this.formContractWord.get('{sams}').valueChanges.subscribe(valueChanges => {
      this.valid.sams = Regex.validatorFormData(this.formContractWord.get('{sams}').status);
      if (!this.formContractWord.get('{sams}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sams}').setValue(valueChanges);
      }
      this.contractInformationParttimeService.setDataFromContract('{sahs}', this.formContractWord.get('{sahs}').value + ':' + valueChanges);
    });

    this.intiDataForService('{sams}');
    // ================================================================= {sahe} =================================================================

    this.formContractWord.get('{sahe}').valueChanges.subscribe(valueChanges => {
      this.valid.sahe = Regex.validatorFormData(this.formContractWord.get('{sahe}').status);
      if (!this.formContractWord.get('{sahe}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sahe}').setValue(valueChanges);
      }
      this.formContractWord.get('{same}').setValue('N');
    });

    this.intiDataForService('{sahe}');
    // ================================================================= {same} =================================================================

    this.formContractWord.get('{same}').valueChanges.subscribe(valueChanges => {
      this.valid.same = Regex.validatorFormData(this.formContractWord.get('{same}').status);
      if (!this.formContractWord.get('{same}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{same}').setValue(valueChanges);
      }
      this.contractInformationParttimeService.setDataFromContract('{sahe}', this.formContractWord.get('{sahe}').value + ':' + valueChanges);
    });

    this.intiDataForService('{same}');
    // ================================================================= {suhs} =================================================================

    this.formContractWord.get('{suhs}').valueChanges.subscribe(valueChanges => {
      this.valid.suhs = Regex.validatorFormData(this.formContractWord.get('{suhs}').status);
      if (!this.formContractWord.get('{suhs}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{suhs}').setValue(valueChanges);
      }
      this.formContractWord.get('{sums}').setValue('N');
    });

    this.intiDataForService('{suhs}');
    // ================================================================= {sums} =================================================================

    this.formContractWord.get('{sums}').valueChanges.subscribe(valueChanges => {
      this.valid.sums = Regex.validatorFormData(this.formContractWord.get('{sums}').status);
      if (!this.formContractWord.get('{sums}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sums}').setValue(valueChanges);
      }
      this.contractInformationParttimeService.setDataFromContract('{suhs}', this.formContractWord.get('{suhs}').value + ':' + valueChanges);
    });

    this.intiDataForService('{sums}');
    // ================================================================= {suhe} =================================================================

    this.formContractWord.get('{suhe}').valueChanges.subscribe(valueChanges => {
      this.valid.suhe = Regex.validatorFormData(this.formContractWord.get('{suhe}').status);
      if (!this.formContractWord.get('{suhe}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{suhe}').setValue(valueChanges);
      }
      this.formContractWord.get('{sume}').setValue('N');
    });

    this.intiDataForService('{suhe}');
    // ================================================================= {sume} =================================================================

    this.formContractWord.get('{sume}').valueChanges.subscribe(valueChanges => {
      this.valid.sume = Regex.validatorFormData(this.formContractWord.get('{sume}').status);
      if (!this.formContractWord.get('{sume}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{sume}').setValue(valueChanges);
      }
      this.contractInformationParttimeService.setDataFromContract('{suhe}', this.formContractWord.get('{suhe}').value + ':' + valueChanges);
    });

    this.intiDataForService('{sume}');
    // ================================================================= {salay} =================================================================

    this.formContractWord.get('{salay}').valueChanges.subscribe(valueChanges => {
      this.valid.salay = Regex.validatorFormData(this.formContractWord.get('{salay}').status);
      this.small.salay = true;
      this.contractInformationParttimeService.setDataFromContract('{salay}', valueChanges);
    });

    this.intiDataForService('{salay}');
    // ================================================================= {pday} =================================================================

    this.formContractWord.get('{pday}').valueChanges.subscribe(valueChanges => {
      this.valid.pday = Regex.validatorFormData(this.formContractWord.get('{pday}').status);
      if (!this.formContractWord.get('{pday}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{pday}').setValue(valueChanges);
      }
      this.contractInformationParttimeService.setDataFromContract('{pday}', valueChanges);
    });

    this.intiDataForService('{pday}');
    // ================================================================= {cys} =================================================================

    this.formContractWord.get('{cys}').valueChanges.subscribe(valueChanges => {
      this.valid.cys = Regex.validatorFormData(this.formContractWord.get('{cys}').status);
      this.contractInformationParttimeService.setDataFromContract('{cys}', valueChanges);
      if (!this.formContractWord.get('{cys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cys}').setValue(valueChanges);
        this.formContractWord.get('{cms}').setValue('N');
        this.formContractWord.get('{cms}').enable();
      } else if (this.formContractWord.get('{cys}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cms}').setValue('N');
        this.formContractWord.get('{cms}').enable();
      } else {
        this.formContractWord.get('{cms}').disable();
      }
    });

    this.intiDataForService('{cys}');
    // ================================================================= {cms} =================================================================

    this.formContractWord.get('{cms}').valueChanges.subscribe(valueChanges => {
      this.valid.cms = Regex.validatorFormData(this.formContractWord.get('{cms}').status);
      this.contractInformationParttimeService.setDataFromContract('{cms}', valueChanges);
      if (!this.formContractWord.get('{cms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cms}').setValue('N');
        this.formContractWord.get('{cms}').setValue(valueChanges);
        this.data.cds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{cys}').value);
        this.formContractWord.get('{cds}').enable();
      } else if (this.formContractWord.get('{cms}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cds}').setValue('N');
        this.data.cds = this.dateShared.daysInMonth(valueChanges, this.formContractWord.get('{cys}').value);
        this.formContractWord.get('{cds}').enable();
      } else {
        this.formContractWord.get('{cds}').disable();
      }
    });

    this.intiDataForService('{cms}');
    // ================================================================= {cds} =================================================================

    this.formContractWord.get('{cds}').valueChanges.subscribe(valueChanges => {
      this.valid.cds = Regex.validatorFormData(this.formContractWord.get('{cds}').status);
      this.contractInformationParttimeService.setDataFromContract('{cds}', valueChanges);
      if (!this.formContractWord.get('{cds}').valid && valueChanges !== 'N') {
        this.formContractWord.get('{cds}').setValue(valueChanges);
      }
    });

    this.intiDataForService('{cds}');

    // ================================================================= {addcone} =================================================================

    this.formContractWord.get('{addcone}').valueChanges.subscribe(valueChanges => {
      this.valid.addcone = Regex.validatorFormData(this.formContractWord.get('{addcone}').status);
      this.small.addcone = true;
      this.contractInformationParttimeService.setDataFromContract('{addcone}', valueChanges);
    });

    this.intiDataForService('{addcone}');
    // ================================================================= {mut} =================================================================

    this.formContractWord.get('{mut}').valueChanges.subscribe(valueChanges => {
      this.valid.mut = Regex.validatorFormData(this.formContractWord.get('{mut}').status);
      this.small.mut = true;
      this.contractInformationParttimeService.setDataFromContract('{mut}', valueChanges);
    });

    this.intiDataForService('{mut}');
    // ================================================================= {ceo} =================================================================

    this.formContractWord.get('{ceo}').valueChanges.subscribe(valueChanges => {
      this.valid.ceo = Regex.validatorFormData(this.formContractWord.get('{ceo}').status);
      this.small.ceo = true;
      this.contractInformationParttimeService.setDataFromContract('{ceo}', valueChanges);
    });

    this.intiDataForService('{ceo}');
    // ================================================================= {addctwo} =================================================================

    this.formContractWord.get('{addctwo}').valueChanges.subscribe(valueChanges => {
      this.valid.addctwo = Regex.validatorFormData(this.formContractWord.get('{addctwo}').status);
      this.small.addctwo = true;
      this.contractInformationParttimeService.setDataFromContract('{addctwo}', valueChanges);
    });

    this.intiDataForService('{addctwo}');
    // ================================================================= {name} =================================================================

    this.formContractWord.get('{name}').valueChanges.subscribe(valueChanges => {
      this.valid.name = Regex.validatorFormData(this.formContractWord.get('{name}').status);
      this.small.name = true;
      this.contractInformationParttimeService.setDataFromContract('{name}', valueChanges);
    });

    this.intiDataForService('{name}');
  }

  totalValidator() {
    const myControls: any = {...this.formContractWord.controls};
    myControls['{whs}'].status = 'VALID';
    myControls['{wms}'].status = 'VALID';
    myControls['{whe}'].status = 'VALID';
    myControls['{wme}'].status = 'VALID';
    myControls['{sahs}'].status = 'VALID';
    myControls['{sams}'].status = 'VALID';
    myControls['{sahe}'].status = 'VALID';
    myControls['{same}'].status = 'VALID';
    myControls['{suhs}'].status = 'VALID';
    myControls['{sums}'].status = 'VALID';
    myControls['{suhe}'].status = 'VALID';
    myControls['{sume}'].status = 'VALID';

    for(const key in myControls) {
      if (myControls[key].status === 'INVALID') {
        return false;
      } 
    }

    return true;
  }

  workValidator() {
    const whs = this.formContractWord.get('{whs}').value;
    const wms = this.formContractWord.get('{wms}').value;
    const whe = this.formContractWord.get('{whe}').value;
    const wme = this.formContractWord.get('{wme}').value;

    if (whs !== 'N' || wms !== 'N' || whe !== 'N' || wme !== 'N') {
      return true;
    } else {
      return false;
    }

  }

  satValidator() {
    const sahs = this.formContractWord.get('{sahs}').value;
    const sams = this.formContractWord.get('{sams}').value;
    const sahe = this.formContractWord.get('{sahe}').value;
    const same = this.formContractWord.get('{same}').value;

    if (sahs !== 'N'|| sams !== 'N' || sahe !== 'N' || same !== 'N') {
      return true;
    } else {
      return false;
    }
  }

  sunValidator() {
    const suhs = this.formContractWord.get('{suhs}').value;
    const sums = this.formContractWord.get('{sums}').value;
    const suhe = this.formContractWord.get('{suhe}').value;
    const sume = this.formContractWord.get('{sume}').value;

    if (suhs !== 'N' || sums !== 'N' || suhe !== 'N' || sume !== 'N') {
      return true;
    } else {
      return false;
    }
  }

  timeValidator() {
    const whs = this.formContractWord.get('{whs}').value;
    const wms = this.formContractWord.get('{wms}').value;
    const whe = this.formContractWord.get('{whe}').value;
    const wme = this.formContractWord.get('{wme}').value;
    const sahs = this.formContractWord.get('{sahs}').value;
    const sams = this.formContractWord.get('{sams}').value;
    const sahe = this.formContractWord.get('{sahe}').value;
    const same = this.formContractWord.get('{same}').value;
    const suhs = this.formContractWord.get('{suhs}').value;
    const sums = this.formContractWord.get('{sums}').value;
    const suhe = this.formContractWord.get('{suhe}').value;
    const sume = this.formContractWord.get('{sume}').value;

    if (
      suhs === 'N' && sums === 'N' && suhe === 'N' && sume === 'N' &&
      sahs === 'N'&& sams === 'N' && sahe === 'N' && same === 'N' &&
      whs === 'N' && wms === 'N' && whe === 'N' && wme === 'N'
    ) {
      return false;
    }
    return true;
  }

  goToMainPage() {
    this.router.navigate(['main']);
  }

  setFormaNumber() {
    var parts = this.formContractWord.get('{salay}').value.toString().replace(/,/g, '').split('.');
    let nan = Number(parts.join('.').toLocaleString('ko-KR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }));
    if (nan >= 0) {
      this.formContractWord.get('{salay}').setValue(Number(parts.join('.')).toLocaleString('ko-KR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }));
    }
  }

  onClickBtnCreateContractWord() {
    let contract_period = this.dateShared.checkDataStartEnd(
      {
        ys: this.formContractWord.get('{lcys}').value,
        ms: this.formContractWord.get('{lcms}').value,
        ds: this.formContractWord.get('{lcds}').value
      },
      {
        ye: this.formContractWord.get('{lcye}').value,
        me: this.formContractWord.get('{lcme}').value,
        de: this.formContractWord.get('{lcde}').value
      }
    );

    let work_hours = this.dateShared.checkTimeStartEnd(
      {
        hs: this.formContractWord.get('{whs}').value,
        ms: this.formContractWord.get('{wms}').value
      },
      {
        he: this.formContractWord.get('{whe}').value,
        me: this.formContractWord.get('{wme}').value
      }
    );

    let saturday_hours = this.dateShared.checkTimeStartEnd(
      {
        hs: this.formContractWord.get('{sahs}').value,
        ms: this.formContractWord.get('{sams}').value
      },
      {
        he: this.formContractWord.get('{sahe}').value,
        me: this.formContractWord.get('{same}').value
      }
    );

    let sunday_hours = this.dateShared.checkTimeStartEnd(
      {
        hs: this.formContractWord.get('{suhs}').value,
        ms: this.formContractWord.get('{sums}').value
      },
      {
        he: this.formContractWord.get('{suhe}').value,
        me: this.formContractWord.get('{sume}').value
      }
    );

    console.log(this.contractInformationParttimeService.dataFromContract);
    let work = false;
    let sat = false;
    let sun = false;
    if (!contract_period) {
      this.alertSharedService.open(
        {
          title: "알림",
          btnClose: true
        },
        {
          title: "근로 계약 종료일은 시작일 이후여야 합니다.",
          subTitle: ""
        },
        [
          {
            btnType: 'ok',
            btnNm: '확인',
            btnStyle: 'btn-black',
            btnFunc: () => {
            }
          }
        ]
      );
    } else {
      if (!work_hours && this.workValidator()) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "근무시간",
            subTitle: "근무 종료 시간은 시작 시간 이후여야 합니다."
          },
          [
            {
              btnType: 'ok',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {
              }
            }
          ]
        );
      } else {
        if (!saturday_hours && this.satValidator()) {
          this.alertSharedService.open(
            {
              title: "알림",
              btnClose: true
            },
            {
              title: "토요일",
              subTitle: "근무 종료 시간은 시작 시간 이후여야 합니다."
            },
            [
              {
                btnType: 'ok',
                btnNm: '확인',
                btnStyle: 'btn-black',
                btnFunc: () => {
                }
              }
            ]
          );
        } else {
          if (!sunday_hours && this.sunValidator()) {
            this.alertSharedService.open(
              {
                title: "알림",
                btnClose: true
              },
              {
                title: "일요일",
                subTitle: "근무 종료 시간은 시작 시간 이후여야 합니다."
              },
              [
                {
                  btnType: 'close',
                  btnNm: 'close',
                  btnStyle: 'btn-black',
                  btnFunc: () => {
                  }
                }
              ]
            );
          }
        }
      }
    }


  if (contract_period) {
    if (this.workValidator() && !this.satValidator() && !this.sunValidator()) {
      if (work_hours) {
        this.setNtoSpace();
        this.createTemplateSystem();
      }
    } else if (!this.workValidator() && this.satValidator() && !this.sunValidator()) {
      if (saturday_hours) {
        this.setNtoSpace();
        this.createTemplateSystem();
      }
    } else if (!this.workValidator() && !this.satValidator() && this.sunValidator()) {
      if (sunday_hours) {
        this.setNtoSpace();
        this.createTemplateSystem();
      }
    } else if (this.workValidator() && this.satValidator() && !this.sunValidator()) {
      if (work_hours && saturday_hours) {
        this.setNtoSpace();
        this.createTemplateSystem();
      }
    } else if (!this.workValidator() && this.satValidator() && this.sunValidator()) {
      if (saturday_hours && sunday_hours) {
        this.setNtoSpace();
        this.createTemplateSystem();
      }
    } else if (this.workValidator() && !this.satValidator() && this.sunValidator()) {
      if (work_hours && sunday_hours) {
        this.setNtoSpace();
        this.createTemplateSystem();
      }
    } else if (this.workValidator() && this.satValidator() && this.sunValidator()) {
      if (work_hours && saturday_hours && sunday_hours) {
        this.setNtoSpace();
        this.createTemplateSystem();
      }
    }
  }
  
  }

  setNtoSpace() {
    for(const key in this.formContractWord.controls) {
      if(this.formContractWord.controls[key].value === 'N') {
        this.contractInformationParttimeService.setDataFromContract(key, '');
      } 
    }
  }

  createTemplateSystem() {
    let params = {
      tempType: this.selectContractTypeService.getTemplType(),
      templId: this.selectContractTypeService.getTempId()
    }
    this.loadingService.openLoading();
    this.templateService.createTemplateSystem(params).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.editorPdfService.setTemplateId(rs.data.templId);
        this.editorPdfService.setTemplNm(rs.data.templNm);
        this.editorPdfService.setContorType('%');
        this.editorPdfService.setEdteMode("temp_sys");
        this.editorPdfService.setState("init");
        this.editorPdfService.download = false;
        this.router.navigate(['create-template-contract']);
      }
    });
  }
}
