<div class="container-fluid px-0">
    <div class="d-flex" id="wrapper">
        <div id="page-content-wrapper" style="background: #E9E9E9;">
            <div class="d-flex justify-content-center align-items-start" style="min-width: 300px; max-width: 300px; min-height: 300px; max-height: 300px;">
                <div class="view-pdf">
                    <div class="pdf-view" id='view-pdf-div'>
                        <div class="" >
                            <div class="" style="min-width: 200px; max-width: 200px; min-height: 300px; max-height: 300px;">
                                <pdf-viewer
                                        (after-load-complete)="afterLoadComplete($event)"
                                            [src]="pdfSrc[1].src" [render-text]="false" [original-size]="false"
                                            [autoresize]="false" [fit-to-page]="true"
                                            [show-all]="false">
                                </pdf-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

