import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthLoginService } from '../../service/authLogin/auth-login.service';
import { NavbarService } from './navbar.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../frontpage/login/login.service';
import { UserService } from '../../service/user/user.service';
import { ServiceCenterService } from '../../service/service-center/service-center.service';
import { PaymentManagementComponent } from '../payment-management/payment-management.component';
import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';
import TokenUtils from '../../shared/token/token-utils';
import { JwtHelperService } from '@auth0/angular-jwt';

import { EditorPdfService } from "../editor-pdf/editor-pdf.service";
import { DashboardService } from '../dashboard/dashboard.service';
import { NoticeService } from '../../service/notice/notice.service';
import { mainModule } from 'process';
import { FormBuilder } from '@angular/forms';
import { ViewChildren } from '@angular/core';

@Component({
  selector: 'app-navber',
  templateUrl: './navber.component.html',
  styleUrls: ['./navber.component.scss']
})
export class NavberComponent implements OnInit {
  interval:any;
  @ViewChild('selectList') ef: ElementRef;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.ef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }
  curPageName = 'G CONT'
  form;
  open = false;
  navbar_title: string = '';
  getUser: boolean = true;
  userNm: string = '';
  logo: {
    main: string,
    other: string
  }
    =
    {
      main: 'backLogo navbar-brand',
      other: 'backIcon dp-node'
    }

  notic: {
    notRead: {
      noticId: string,
      noticNo: number,
      noticTitle: string,
      noticeContent: string,
      registerPnttm: string
    }[],
    noticSys: {
      notiMsg: string,
      noticActId: string,
      noticId: string,
      noticTitle: string,
      noticStatus: string,
      noticStatusCode: string,
      notiActUrl: string,
      notiRefUrl: string,
      registerPnttm: string
    }[]
  } = {
      notRead: [],
      noticSys: []
    }

  constructor(
    private fb: FormBuilder,
    private authService: AuthLoginService,
    private router: Router,
    private navbarService: NavbarService,
    private loginService: LoginService,
    private userService: UserService,
    private serviceCenterService: ServiceCenterService,
    private dashboardService: DashboardService,
    private noticeService: NoticeService,
    private editorPdfService: EditorPdfService,
    private jwtHelper: JwtHelperService,
    private alertSharedService: AlertSharedService,
  ) { 
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      pageLink: ['main']
    });
    this.form.valueChanges.subscribe(v => {
      console.log('v', v);
      this.toLink(v.pageLink);
    });
    this.initDataUser();
    this.xMenu();
    this.myToggle();
    this.getNoticeSystemUser();
    console.log(this.jwtHelper.isTokenExpired(TokenUtils.getToken()),
    this.jwtHelper.getTokenExpirationDate(TokenUtils.getToken()),
    this.jwtHelper.decodeToken(TokenUtils.getToken()),
    "CHECK SESS");
    this.interval = setInterval(() => {
      // your code goes here...
      this.getNoticeSystemUser();
      console.log(this.jwtHelper.isTokenExpired(TokenUtils.getToken()),
      this.jwtHelper.getTokenExpirationDate(TokenUtils.getToken()),
      this.jwtHelper.decodeToken(TokenUtils.getToken()),
      "CHECK SESS");
      if (this.jwtHelper.isTokenExpired(TokenUtils.getToken())) {
        this.alertSharedService.open(
            {
               title: "세션",
               btnClose: false
            },
            {
              title: "30분 동안 서비스 ",
              subTitle: "이용이 없어로그아웃 되었습니다."
            },
            [
              {
                btnType: 'close',
                btnNm: '확인',
                btnStyle: 'btn-black',
                btnFunc: () => {
                  clearInterval(this.interval);
                  this.logout();
                }
              }
            ]
          );
      }
    }, 60 * 2000);
  }

  ngDoCheck(): void {
    if (this.router.url == '/main') {
      this.logo.main = 'backLogo navbar-brand';
      this.logo.other = 'backIcon dp-node';
    } else {
      this.logo.other = 'backLogo navbar-brand';
      this.logo.main = 'backIcon dp-node';
    }

    if (this.router.url.includes('/contract-management')) {
      document.getElementById('configA').style.display = "block";
    } else {
      document.getElementById('configA').style.display = "none";
    }


    if (this.router.url.includes('/information-manage')) {
      document.getElementById('configB').style.display = "block";
    } else if (this.router.url.includes('/signature-management')) {
      document.getElementById('configB').style.display = "block";
    } else if (this.router.url.includes('/payment-management')) {
      document.getElementById('configB').style.display = "block";
    } else if (this.router.url.includes('/inquiry-managemant')) {
      document.getElementById('configB').style.display = "block";
    } else if (this.router.url.includes('/mail-managemant')) {
      document.getElementById('configB').style.display = "block";
    } else if (this.router.url.includes('/organization-management')) {
      document.getElementById('configB').style.display = "block";
    } else {
      document.getElementById('configB').style.display = "none";
    }





    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.userNm = this.loginService.getProfileLogin().userNm;
      this.getUser = false;
    }
    this.navbar_title = this.navbarService.getTitleNavber();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
    document.getElementsByClassName("body-container")[0].setAttribute("style", 'overflow-y:auto;');
  }


  getNoticeSystemUser() {
    this.noticeService.getNoticeSystem().subscribe((res: any) => {
      if (res.status) {
        this.notic.noticSys = res.data;
      }
    });
  }

  getPersonalNotReadNotic() {
    this.serviceCenterService.getPersonalNotReadNotic().subscribe((rs: any) => {
      if (rs.status) {
        this.notic.notRead = rs.data;
      }
    });
  }
  goToServiceCenter(noticId: string) {
    this.dashboardService.setNoticId(noticId);
    this.router.navigate(['service-center']);
  }

  initDataUser() {
    if (this.navbarService.getUsetId() !== "") {
    } else {
      this.navbarService.setUsetId(localStorage.getItem('userId'));
    }
  }

  openList() {
    this.open = !this.open;
  }

  toLink(url) {
    if (url === 'how-to-use') {
      this.curPageName = '이용방법'
    } else if (url === '') {
      this.curPageName = '홈페이지'
    } else {
      this.curPageName = 'G CONT';
    }
    this.router.navigate(['/' + url], {skipLocationChange: false});
  }

  openNav() {
    document.getElementById("mySubnav").style.width = "55%";
    document.getElementById("mySidenav").style.width = "45%";
    document.getElementsByClassName("body-container")[0].setAttribute("style", 'overflow:hidden !important;');
  }

  closeNav() {
    var colnav = document.getElementById("navbarSupportedContent");
    var burgerX = document.getElementById("tognav");

    document.getElementById("bkImg").style.visibility = "visible";
    document.getElementById("mySidenav").style.width = "0px";
    document.getElementById("mySubnav").style.width = "0px";
    document.getElementById("mySubnav2").style.width = "0px";

    colnav.classList.remove("show");
    burgerX.classList.remove("is-active");

    document.getElementsByClassName("body-container")[0].setAttribute("style", 'overflow-y:auto;');
  }

  onReactNotice(noticActId: string, noticId: string, noti: any) {
    let params = {
      noticActId: noticActId,
      noticId: noticId
    }
    this.noticeService.reactNoticeSystem(params).subscribe((res: any) => {
      if (res.status) {
        console.log(res.data);
        this.getNoticeSystemUser();
        this.onClickReviewContract(noti.notiActUrl, noti.notiRefUrl);
      }
    });
  }


  onClickReviewContract(notiActUrl: string, notiRefUrl: string) {
    let act = JSON.parse(notiActUrl);
    this.editorPdfService.setContor({ contorId: act.contorId, contId: act.contId });
    this.editorPdfService.setTemplateId(act.tempId);
    this.editorPdfService.setTemplNm(act.contNm);
    this.editorPdfService.setContorType('%');
    this.editorPdfService.setEdteMode("peview");
    this.editorPdfService.setState("init");
    this.editorPdfService.download = false;
    this.router.navigate([notiRefUrl]);
  }

  myToggle() {
    var x = document.getElementById("mySidenav");
    if (x.style.width === "0px") {
      x.style.width = "45%";
      document.getElementById("mySubnav").style.width = "55%";
      document.getElementById("bkImg").style.visibility = "hidden";
      document.getElementsByClassName("body-container")[0].setAttribute("style", 'overflow:hidden !important;');

    } else {
      x.style.width = "0px";

      document.getElementById("bkImg").style.visibility = "visible";
      document.getElementById("mySidenav").style.width = "0px";
      document.getElementById("mySubnav").style.width = "0px";
      document.getElementById("mySubnav2").style.width = "0px";
      document.getElementsByClassName("body-container")[0].setAttribute("style", 'overflow-y:auto;');

    }
  }

  myToggleBack() {
    var x = document.getElementById("mySidenav");
    var burgerX = document.getElementById("tognav");

    burgerX.classList.remove("is-active");

    if (x.style.width === "0px") {
      x.style.width = "0px";

    } else {
      x.style.width = "0px";
      document.getElementById("mySidenav").style.width = "0px";
      document.getElementById("mySubnav").style.width = "0px";
      document.getElementById("mySubnav2").style.width = "0px";

    }
  }


  xMenu() {
    var hamburgers = document.querySelectorAll(".hamburger");
    if (hamburgers.length > 0) {
      hamburgers.forEach(hamburger => {
        hamburger.addEventListener("click", (e) => {
          hamburger.classList.toggle("is-active");
        })
      })
    }
  }

  toPageSetting() {
    this.router.navigate(['/information-manage']);
  }
}
