import { Component, OnInit } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import * as XLSX from 'xlsx';
import { ModalService } from '../modal/modal.service';
import { TableDataService } from '../table-data/table-data.service';
import GenerateExcel from '../../shared/GenerateExcel/generate-excel';

@Component({
  selector: 'app-excel-transfer',
  templateUrl: './excel-transfer.component.html',
  styleUrls: ['./excel-transfer.component.scss']
})
export class ExcelTransferComponent implements OnInit {
  faDownload = faDownload;
  faUpload = faUpload;
  title = 'XlsRead';
  file: File;
  arrayBuffer: any;
  filelist: any;
  titleKey = {
    number: '번호',
    contractNm: '이름',
    rank: '직급',
    department: '부서'
  };
  constructor(
    private modalService: ModalService,
    private tableDataService: TableDataService
  ) { }

  ngOnInit(): void {
  }

  addfile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    this.tableDataService.setFileName(this.file.name);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i != data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      // tslint:disable-next-line: variable-name
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = [];
      this.tableDataService.setDataTable(arraylist, this.titleKey, 'contractNm');
      this.modalService.setState('tableData');
    };
  }

  exportTableToExcel() {
    let filename = '수신자 리스트';
    let downloadLink;
    let dataType = 'application/vnd.ms-excel';
    let tableHTML = '';
    let headers = ['number', 'contractNm', 'rank', 'department', 'contractEmail', 'contractMobile'];

    GenerateExcel.linkDownloadFileExcelContract();
  }
}
