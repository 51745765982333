<div id="SVapp-home" class="bgroundcolor">
    <app-navbar></app-navbar>
    <section class="container-fluid bannerarea " id="herder">

        <div class="container-fluid  imagebannerpicture">
            <div class="container-md p-0">
                <div class="row align-items-start bannerimage serviceBalance mx-auto">

                    <div class="col-12 p-0 align-self-center">
                        <h1>자료실</h1>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <ng-container *ngIf="page ==='main'">
        <section class="container-fluid" id="body">
            <app-community-list *ngIf="display.notice" (selectItem)="selectItem($event)"></app-community-list>
        </section>
    </ng-container>
</div>
<div class="serviceFooter">
    <app-footer></app-footer>
</div>
