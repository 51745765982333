import { Injectable } from '@angular/core';
import { OAuthToken } from '../../manage/shared/model/token-model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  dataLogin: OAuthToken;
  constructor() { }
  profile: any = null;

  setDataLogin(data: OAuthToken){
    
    this.dataLogin = data;
    this.profile = data.profile
  }

  setProfile(data: any){
    this.profile = data
  }

  getProfileLogin(){
    return this.profile;
  }
}
