import { Component, OnInit } from '@angular/core';
import {BlockCertDetailsService} from "./block-cert-details.service";

@Component({
  selector: 'app-block-cert-details',
  templateUrl: './block-cert-details.component.html',
  styleUrls: ['./block-cert-details.component.scss']
})
export class BlockCertDetailsComponent implements OnInit {
  show: boolean;
  htmlBody: string;
  state: string;
  historys: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  blockCertDetails: {
    contorEmail: string,
    contorNm: string,
    userId: string,
    userNm: string,
    pldgDate: string,
    pldgHash: string,
    ecertNm: string
  }

  blockCertHistory: {
    pldgHashDate: string,
    histIp: string,
    pldgBlockIfResCode: string,
    userNm: string,
    pldgHash: string
  }[] = [];

  constructor(
      private blockCertDetailsService: BlockCertDetailsService
  ) { }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    if (this.blockCertDetailsService.change.show) {
      this.show = this.blockCertDetailsService.show;
      this.blockCertDetailsService.setChange('show', false);
    }
    if (this.blockCertDetailsService.change.html) {
      this.htmlBody = this.blockCertDetailsService.htmlBody;
      this.blockCertDetailsService.setChange('html', false);
    }
    if (this.blockCertDetailsService.change.state) {
      this.state = this.blockCertDetailsService.getState();
      this.blockCertDetails = this.blockCertDetailsService.getBlockCertDetails();
      this.blockCertHistory = this.blockCertDetailsService.getBlockCertHistory();
      this.blockCertDetailsService.setChange('state', false);
    }
  }

  closeModal() {
    this.blockCertDetailsService.close();
  }

  exportDataTable() {
    this.blockCertDetailsService.close();
  }

  onClickBtn(btn: any) {
    this.blockCertDetailsService[btn].func();
  }



  closeModalBackground($event) {
    console.log($event.target.className);
    if ($event.target.className.includes("show")) {
      this.blockCertDetailsService.close();
    }
  }

}
