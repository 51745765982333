import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VerificationRegisterService } from './verification-register.service';
import { UserService } from '../../manage/service/user/user.service';
import { LoadingService } from '../../manage/shared/loading/loading.service';

@Component({
  selector: 'app-verification-register',
  templateUrl: './verification-register.component.html',
  styleUrls: ['./verification-register.component.scss']
})
export class VerificationRegisterComponent implements OnInit {

  constructor(
    private router: Router,
    private verificationRegisterService: VerificationRegisterService,
    private userService: UserService,
    private loadingService: LoadingService,
  ) { }

  mail: string = "";
  name: string = '';

  ngOnInit(): void {
    this.mail = this.verificationRegisterService.getMail();
    this.name = this.verificationRegisterService.getName();
  }

  ngAfterViewInit(): void {
    this.sendMail();
  }

  clickFuction() {
    this.sendMail();
  }

  sendMail() {
    this.loadingService.openLoading();
    this.userService.congratulationUser(this.verificationRegisterService.getMail()).subscribe((rs: any) => {
      if (rs.status) {
        this.loadingService.closeLoading();
      }
    });
  }
}
