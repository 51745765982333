import { Component, OnInit, Inject, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { RecipientService } from '../recipient/recipient.service';
import { ContractService } from '../../../service/contract/contract.service';
import { LoadingService } from '../../../shared/loading/loading.service';
import { API_URL } from 'src/app/manage/service/api.constant';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

import { Router } from '@angular/router';
@Component({
  selector: 'app-recipient-confirmation-password',
  templateUrl: './recipient-confirmation-password.component.html',
  styleUrls: ['./recipient-confirmation-password.component.scss', '../../../../frontpage/forgot-password/forgot-password.component.scss']
})
export class RecipientConfirmationPasswordComponent implements OnInit {

  formInputPassword: FormGroup;
  sEncData;

  contNm: string = '';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private recipientService: RecipientService,
    private contractService: ContractService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document  
    ) { 
      window['RecipientConfirmationPasswordComponentRef'] = {component: this, zone: _ngZone, loadAngularFunction: () => this.nextPage(),};
    }

  ngOnInit(): void {
    this.contNm =this.recipientService.getContNm();
    this.initFormInputPassword();
  }

  initFormInputPassword() {
    this.formInputPassword = this.fb.group({
      contorPass: ['', [Validators.required]],
    });
  }

  onClickBtnOk() {
    let data = {
      contorPass: this.formInputPassword.get('contorPass').value,
      shortUrl: this.recipientService.getShortUrl()
    }
    this.loadingService.openLoading();
    this.contractService.checkRecipientConfirmationPassword(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.http.get<any>(API_URL.mobileVerification).subscribe(res => {
          console.log('res', res);
          this.sEncData = res.data.encData;
          window.open('https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

        });
      }
    });
  }

  fnPopup(){
		window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
	}

  nextPage() {
    this.recipientService.setState('write');
  }


  callbackfunction(){   
    window['angularComponent'].zone.run(() => {
      this.nextPage(); 
    });
  }

  onSubmit() {
    let data = {
      contorPass: this.formInputPassword.get('contorPass').value,
      shortUrl: this.recipientService.getShortUrl()
    }
    this.loadingService.openLoading();
    this.contractService.checkRecipientConfirmationPassword(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        if (this.recipientService.getVerifi().phone === 'Y') {
          // this.http.get<any>(API_URL.mobileVerification).subscribe(res => {
          //   console.log('res', res);
          //   this.sEncData = res.data.encData;
          //   setTimeout(() => {
          //     window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
          //     this.document.forms['form_chk'].action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
          //     this.document.forms['form_chk'].target = "popupChk";
          //     this.document.forms['form_chk'].submit();
          //   });
          // });
          this.nextPage();
        } else {
          this.nextPage();
        }

      }
    });
   
  }

  goToHome() {
    this.router.navigate(['/']); 
  }
}
