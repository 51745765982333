import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {LoadingService} from "../../manage/shared/loading/loading.service";
import {AlertService} from "../../manage/component/modal/alert/alert.service";
import {ServiceCenterService} from "../../manage/service/service-center/service-center.service";
import {AlertSharedService} from "../../manage/shared/modal/alert-shared/alert-shared.service";
import {NavbarService} from "../navbar/navbar.service";
import Regex from "../../manage/shared/regex/regex";
import {CodeBookService} from "../../manage/service/code-book/code-book.service";
import {CommunityService} from "../../manage/service/community/community.service";
import {CertificationService} from "../../manage/component/certification/certification.service";
import * as $ from 'jquery';

@Component({
    selector: 'app-community-upload',
    templateUrl: './community-upload.component.html',
    styleUrls: ['./community-upload.component.scss']
})
export class CommunityUploadComponent implements OnInit {

    constructor(
        private fb: FormBuilder,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private serviceCenterService: ServiceCenterService,
        private alertSharedService: AlertSharedService,
        private navbarService: NavbarService,
        private codeBookService: CodeBookService,
        private communityService : CommunityService,
        private certificationService : CertificationService
    ) {
    }

    formCommunityUpload: FormGroup;

    btnSubmit: boolean = true;

    upload = this.communityService.getUploadStatus();

    validators: {
        postNm: string,
        fileId: string,
        fileNm: string,
        postContent: string,
        postType: string,
        thumbNailUrl: string,
        pageNum: number;
    } = {
        postNm: "",
        fileId: "",
        fileNm: "",
        postContent: "",
        postType: "",
        thumbNailUrl: "",
        pageNum: 0
    }

    postType: {
        code: string,
        codeDc: string,
        codeGrpId: string,
        codeNm: string
    }[] = [];

    ngOnInit(): void {
        this.getCodeBook("COMMUNITY_TYPE");
        this.navbarService.setNavbarTitle("자료실");
        this.initformCommunityUpload();
    }

    ngAfterViewInit(): void {
        this.upload = this.communityService.getUploadStatus();
        this.formCommunityUpload.get('fileNm').setValue(this.validators.fileNm);
        this.formCommunityUpload.get('fileId').setValue(this.validators.fileId);
        this.formCommunityUpload.get('thumbNailUrl').setValue(this.validators.thumbNailUrl);
        this.formCommunityUpload.get('pageNum').setValue(this.validators.pageNum);

        console.log("fileNM++++++++++++" +this.validators.fileNm);
        console.log(this.upload);
        console.log("fileIDDDDDDDDD" + this.validators.fileId);
        console.log("pageNum" + this.validators.pageNum);
    }


    initformCommunityUpload() {
        this.formCommunityUpload = this.fb.group({
            postNm: ['', Regex.Required],
            postType: ['', Regex.Required],
            fileNm: ['', Regex.Required],
            fileId: ['', Regex.Required],
            postContent: ['', Regex.Required],
            thumbNailUrl: ['', Regex.Required],
            pageNum: [0, Regex.Required]
        });

        this.formCommunityUpload.get('postNm').valueChanges.subscribe(valueChanges => {
            this.validators.postNm = Regex.validatorFormData(this.formCommunityUpload.get('postNm').status);
        });

        this.formCommunityUpload.get('postType').valueChanges.subscribe(valueChanges => {
            this.validators.postType = Regex.validatorFormData(this.formCommunityUpload.get('postType').status);

        });

        this.formCommunityUpload.get('fileId').valueChanges.subscribe(valueChanges => {
            this.validators.fileId = Regex.validatorFormData(this.formCommunityUpload.get('fileId').status);
        });

        this.formCommunityUpload.get('fileNm').valueChanges.subscribe(valueChanges => {
            this.validators.fileNm = Regex.validatorFormData(this.formCommunityUpload.get('fileNm').status);
        });

        this.formCommunityUpload.get('postContent').valueChanges.subscribe(valueChanges => {
            this.validators.postContent = Regex.validatorFormData(this.formCommunityUpload.get('postContent').status);
        });

        this.formCommunityUpload.get('thumbNailUrl').valueChanges.subscribe(valueChanges => {
            this.validators.postContent = Regex.validatorFormData(this.formCommunityUpload.get('thumbNailUrl').status);
        });

        this.formCommunityUpload.get('pageNum').valueChanges.subscribe(valueChanges => {
            this.validators.postContent = Regex.validatorFormData(this.formCommunityUpload.get('pageNum').status);
        });

        this.formCommunityUpload.valueChanges.subscribe(valueChanges => {
            this.btnSubmit = !this.formCommunityUpload.valid;
        });




    }

    getCodeBook(type: string) {
        this.codeBookService.getCodeBook(type).subscribe((rs: any) => {
            if (rs.status) {
                this.postType = rs.data;
            }
        });
    }


    onSend() {
        this.loadingService.openLoading();
        if (this.formCommunityUpload.status === 'VALID') {
            console.log('o')
            this.insertInquireFront();
        } else {
            console.log('x')
            this.validatorAlert();
        }
    }

    btnFileCancel() {
        this.formCommunityUpload.get('fileNm').setValue(null);
        this.formCommunityUpload.get('fileId').setValue(null);
        this.formCommunityUpload.get('thumbNailUrl').setValue(null);
        this.formCommunityUpload.get('pageNum').setValue(null);
        this.upload = false;
    }

    clearData() {
        this.formCommunityUpload.get('postNm').setValue(null);
        this.formCommunityUpload.get('fileId').setValue(null);
        this.formCommunityUpload.get('fileNm').setValue(null);
        this.formCommunityUpload.get('postContent').setValue(null);
        this.formCommunityUpload.get('postType').setValue(null);
        this.formCommunityUpload.get('thumbNailUrl').setValue(null);
        this.formCommunityUpload.get('pageNum').setValue(null);

        this.validators = {
            postNm: "",
            fileId: "",
            fileNm: "",
            postContent: "",
            postType: "",
            thumbNailUrl: "",
            pageNum: 0
        }
    }

    validatorAlert() {
        const postNm: any = {controls: this.formCommunityUpload.get('postNm'), contents: '계약서 이름을 입력하세요'};
        const fileId: any = {controls: this.formCommunityUpload.get('fileId'), contents: '계약서 파일을 업로드하세요'};
        const fileNm: any = {controls: this.formCommunityUpload.get('fileNm'), contents: '계약서 파일을 업로드하세요'};
        const postType: any = {controls: this.formCommunityUpload.get('postType'), contents: '분류를 선택하세요'};
        const postContent: any = {controls: this.formCommunityUpload.get('postContent'), contents: '상세내용을 입력하세요'};
        const thumbNailUrl: any = {controls: this.formCommunityUpload.get('thumbNailUrl'), contents: '계약서 파일을 업로드하세요'};
        const pageNum: any = {controls: this.formCommunityUpload.get('pageNum'), contents: '계약서 파일을 업로드하세요'};
        const array: any = [postNm, fileId, fileNm, postType, postContent, thumbNailUrl, pageNum];
        let title;
        array.forEach(v => {
            if (!v.controls.valid) {
                title = v.contents;
            }
        });


        this.alertSharedService.open(
            {
                title: "알림",
                btnClose: true
            },
            {
                title: title,
                subTitle: ''
            },
            [
                {
                    btnNm: '확인',
                    btnStyle: 'btn-white',
                    btnType: 'close',
                    btnFunc: () => {
                    }
                }
            ]
        );
        this.loadingService.closeLoading();
    }

    insertInquireFront() {
        this.communityService.createCommunity(this.formCommunityUpload.value).subscribe((rs: any) => {
            if (rs.status) {
                this.alertSharedService.open(
                    {
                        title: "알림",
                        btnClose: true
                    },
                    {
                        title: '문의 내용이 등록되었습니다.',
                        subTitle: ''
                    },
                    [
                        {
                            btnNm: '확인',
                            btnStyle: 'btn-white',
                            btnType: 'close',
                            btnFunc: () => {
                                this.clearData();
                            }
                        }
                    ]
                );
            }
            this.loadingService.closeLoading();
        });
    }
    checkCharacters() {
        if($("#textArea").val().length > 200) {
            $("#textArea").val($("#textArea").val().substring(0, 200));
        }
    }

}
