<div class="bg-modal" *ngIf="show">
  <!-- Modal -->
  <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">


        <div class="modal-header border-0 rounded-0"
          style="background: #ffffff;color: #000000; padding: 20px 20px 30px 20px;">

          <div class="border-bottom border-dark d-flex w-100 pb-2 ">
            <h5 class="modal-title " id="exampleModalLabel">서명/도장 추가</h5>
            <a style="color: #000000;" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>

        </div>


        <div class="modal-body rounded-0 py-0 " class="imageBodyOverflow">
          <app-signature (signatureImageBase64)="setSignatureImageBase64($event)"></app-signature>
          <div class="border-0 rounded-0 d-flex justify-content-center"
            style="background: #ffffff;color: #000000; padding: 0px 20px 31px 20px ;">
            <button class="m-0 btn btn-white btn-middle-short" (click)="onClickBtn('btnCancel')">취소</button>
            <button style="margin:0px 0px 0px 10px;" class=" btn btn-black btn-middle-short"
              (click)="onClickBtn('btnOk')">확인</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>