import { Injectable } from '@angular/core';
import { LoadingService } from '../../../manage/shared/loading/loading.service';
import { AlertService } from '../../../manage/component/modal/alert/alert.service';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import NavigatorSystem from '../../../manage/shared/NavigatorSystem/navigator-system';
@Injectable({
  providedIn: 'root'
})


export class CommonFunction {

  onInit: {

  } = {

    }

  constructor(
    private loadingService: LoadingService,
    private alertService: AlertService,
    private http: HttpClient,
  ) {
  }

  setFormatURLParmeters(parameters: string)
  {
    const urlParams = new URLSearchParams(parameters);
    return urlParams;
  }


  createOauthWindow(url: string, name: string, monditorBase: boolean, p_width: number, p_height: number) {

    var popupWidth = p_width;
    var popupHeight = p_height;
    var popupX = 0;
    var popupY = 0;

    if(monditorBase == true)
    {
      popupX = (window.screen.width / 2) - (popupWidth / 2);
      // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음
      popupY= (window.screen.height / 2) - (popupHeight / 2);
      // 만들 팝업창 height 크기의 1/2 만큼 보정값으로 빼주었음
    }
    else
    {
      /*
      popupX = (document.body.offsetWidth / 2) - (200 / 2);
      //&nbsp;만들 팝업창 좌우 크기의 1/2 만큼 보정값으로 빼주었음
      popupY= (window.screen.height / 2) - (300 / 2);
      //&nbsp;만들 팝업창 상하 크기의 1/2 만큼 보정값으로 빼주었음
      */
      popupX = (window.screen.width / 2) - (popupWidth / 2);
      // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음
      popupY= (window.screen.height / 2) - (popupHeight / 2);
      // 만들 팝업창 height 크기의 1/2 만큼 보정값으로 빼주었음
    }

    if (url == null) {
      return null;
    }
    const options = `left=${popupX},top=${popupY},width=${popupWidth},height=${popupHeight}`;

    let checkOS = NavigatorSystem.getOS();
    if(checkOS == 101 || checkOS == 103)
    {
      window.open(url);
    }
    else
    {
      //to actually open the window..
      window.open(url, name, options);
    }

    window.focus();

  }

  noticePopOpen(url: string, name: string, monditorBase: boolean, p_width: number, p_height: number, location: string) {

    var popupWidth = p_width;
    var popupHeight = p_height;
    var popupX = 0;
    var popupY = 0;
    if(location == "left") {
      if(monditorBase == true)
      {
        popupX = (window.screen.width / 1.5) - (popupWidth / 2);
        // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음
        popupY= (window.screen.height / 2) - (popupHeight / 2);
        // 만들 팝업창 height 크기의 1/2 만큼 보정값으로 빼주었음
      }
      else
      {
        /*
        popupX = (document.body.offsetWidth / 2) - (200 / 2);
        //&nbsp;만들 팝업창 좌우 크기의 1/2 만큼 보정값으로 빼주었음
        popupY= (window.screen.height / 2) - (300 / 2);
        //&nbsp;만들 팝업창 상하 크기의 1/2 만큼 보정값으로 빼주었음
        */
        popupX = (window.screen.width / 1.5) - (popupWidth / 2);
        // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음
        popupY= (window.screen.height / 2) - (popupHeight / 2);
        // 만들 팝업창 height 크기의 1/2 만큼 보정값으로 빼주었음
      }
    } else if (location == 'right'){
      if(monditorBase == true)
      {
        popupX = (window.screen.width / 3.5) - (popupWidth / 2);
        // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음
        popupY= (window.screen.height / 2) - (popupHeight / 2);
        // 만들 팝업창 height 크기의 1/2 만큼 보정값으로 빼주었음
      }
      else
      {
        /*
        popupX = (document.body.offsetWidth / 2) - (200 / 2);
        //&nbsp;만들 팝업창 좌우 크기의 1/2 만큼 보정값으로 빼주었음
        popupY= (window.screen.height / 2) - (300 / 2);
        //&nbsp;만들 팝업창 상하 크기의 1/2 만큼 보정값으로 빼주었음
        */
        popupX = (window.screen.width / 3.5) - (popupWidth / 2);
        // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음
        popupY= (window.screen.height / 2) - (popupHeight / 2);
        // 만들 팝업창 height 크기의 1/2 만큼 보정값으로 빼주었음
      }
    }


    if (url == null) {
      return null;
    }
    const options = `left=${popupX},top=${popupY},width=${popupWidth},height=${popupHeight}`;

    let checkOS = NavigatorSystem.getOS();
    if(checkOS == 101 || checkOS == 103)
    {
      window.open(url);
    }
    else
    {
      //to actually open the window..
      window.open(url, name, options);
    }

    window.focus();

  }

  popupMsg(p_subject, p_msg)
  {
    this.loadingService.openLoading();
    if (status) {
      this.alertService.open(
        p_subject,
        p_msg,
        {
          func: () => {
            this.alertService.close();
          }
        },
        {
          func: () => {
            this.alertService.close();
          }
        }
      );
    }else{
      this.alertService.open(
        p_subject,
        p_msg,
        {
          func: () => {
            this.alertService.close();
          }
        },
        {
          func: () => {
            this.alertService.close();
          }
        }
      );
    }
    this.loadingService.closeLoading();
  }












  deductPoint(dp: number)
  {
    const snsPayUrl = '/JSTCRM/api/pay/point/'+dp;
    const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');

    return this.http.post(snsPayUrl, headers);

  }


  cancelPayment(aid: string, tid: string, cid: string, cancelAmount: number)
  {
    let res_status;
    const snsPayUrl = '/JSTCRM/api/pay/cancel/'+aid+'/'+tid+'/'+cid+'/'+cancelAmount
    const headers = new HttpHeaders().set('Accept', 'application/json, text/plain,*/*');

    return this.http.post(snsPayUrl, headers);

  }














}
