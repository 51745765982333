import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { NavbarService } from '../navbar/navbar.service';
declare var $: any;
@Component({
  selector: 'app-page-verification-register',
  templateUrl: './page-verification-register.component.html',
  styleUrls: ['./page-verification-register.component.scss']
})
export class PageVerificationRegisterComponent implements OnInit {

  constructor(
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.navbarService.setNavbarTitle('');
    $('body').scrollspy({
      target: '#mainNav',
      offset: 50
    });

    // Collapse Navbar
    try{
      var navbarCollapse = function () {
        if($("#mainNav").offset() !== undefined){
          if ($("#mainNav").offset().top > 100) {
            $("#mainNav").addClass("navbar-shrink");
          } else {
            $("#mainNav").removeClass("navbar-shrink");
          }
        }
       
      };
    }catch(e){

    }
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);
  }

}
