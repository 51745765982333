import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faPlus, faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { ModalAddSignatureService } from '../modal/modal-add-signature/modal-add-signature.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { DigitalsignatureService } from '../../service/digitalSignature/digitalsignature.service';
import { UserService } from '../../service/user/user.service';
import { LoginService } from '../../../frontpage/login/login.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { NavbarService } from '../navbar/navbar.service';

@Component({
  selector: 'app-signature-management',
  templateUrl: './signature-management.component.html',
  styleUrls: ['./signature-management.component.scss']
})
export class SignatureManagementComponent implements OnInit {

  @Input() config: {
    title: boolean,
    height: string,
    backgroundColor: string,
    grid: string,
    padding: string,
    icon: {
      favorite: boolean,
      download: boolean,
      delete: boolean
    },
    select: boolean,
    status: string
  }

  @Output() selectSignat = new EventEmitter<any>();
  selectSignature: string = '';

  conf: {
    title: boolean,
    height: string,
    backgroundColor: string,
    grid: string,
    padding: string,
    icon: {
      favorite: boolean,
      download: boolean,
      delete: boolean
    },
    select: boolean,
    status: string
  } = {
      title: true,
      height: "750px",
      backgroundColor: "#e2e6eb",
      grid: "wrapperSignatures",
      padding: "0px",
      icon: {
        favorite: true,
        download: true,
        delete: true
      },
      select: false,
      status: "all"
    }

  sidebar: any = "set";
  faPlus = faPlus;
  faDownload = faDownload;
  faStar = faStar;
  faTimesCircle = faTimesCircle;
  signatureList: any = [];
  constructor(
    private modaladdsaignatureservice: ModalAddSignatureService,
    private sidebarService: SidebarService,
    private digitalsignatureService: DigitalsignatureService,
    private userService: UserService,
    private loginService: LoginService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
  ) {
    this.sidebarService.setManuActive(this.sidebar);
    this.sidebarService.setSubManuActive('signature-management');
  }

  ngOnInit(): void {
    this.navbarService.setAction('sigatureManage');
    this.initConfig();
    this.getSignatureList();
  }

  initConfig(): void {
    if (this.config === undefined) {
      this.conf.title = true;
      this.conf.height = "90vh";
      this.conf.backgroundColor = "#e2e6eb";

      this.conf.icon.delete = true;
      this.conf.icon.download = true;
      this.conf.icon.favorite = true;
      this.conf.grid = "wrapperSignatures";
      this.conf.padding = "0px";
      this.conf.status = "all";
    } else {
      console.log(this.config);
      this.conf = this.config
      console.log(this.conf);
    }
  }

  modalSaignatureOpen() {
    this.modaladdsaignatureservice.openModal(
      {
        func: () => {
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = today.getFullYear();

          let hours = today.getHours();
          let minutes = today.getMinutes();
          let ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12;
          let minute = minutes < 10 ? '0' + minutes : minutes;
          var strTime = hours + '' + minute + ampm;
          let filename = mm + dd + yyyy + strTime;

          const formData = new FormData();
          formData.append('files', this.modaladdsaignatureservice.dataURItoBlob(this.modaladdsaignatureservice.getSignature(), filename));
          let boo = 'false';
          if (this.conf.status === 'favorite') {
            boo = 'true';
          }
          formData.append('favorite', boo);
          this.loadingService.openLoading();
          this.digitalsignatureService.uploadSignatureUser(formData).subscribe((rs: any) => {
            this.loadingService.closeLoading();
            if (rs.status) {
              this.getSignatureList();
            }
            this.modaladdsaignatureservice.close();
          });
        }
      }, {
      func: () => {
        this.modaladdsaignatureservice.close();
      }
    }
    );
  }

  modalSaignatureClose() {
    this.modaladdsaignatureservice.close();
  }
  ngAfterViewInit(): void {
    this.sidebarService.setManuActive(this.sidebar);
  }

  signatueImage(signId: string) {
    return this.userService.openSignature(signId);
  }

  getSignatureList() {
    console.log(this.conf);
    let params = {
      status: this.conf.status
    }
    this.loadingService.openLoading();
    this.userService.getSignatureList(params).subscribe((rs: any) => {
      if (rs.status) {
        this.signatureList = rs.data;
      }
      this.loadingService.closeLoading();
    });
  }

  onFavorite(signId: string, dsigFavorite: boolean, i: number) {
    this.loadingService.openLoading();
    this.userService.updateSignatureFavorite(signId, !dsigFavorite).subscribe((rs: any) => {
      if (rs.status) {
        this.signatureList[i].dsigFavorite = !dsigFavorite;
        this.getSignatureList();
      }
      this.loadingService.closeLoading();
    });
  }

  deleteSignature(signId: string, index: number) {
    this.loadingService.openLoading();
    this.userService.deleteSignature(signId).subscribe((rs: any) => {
      if (rs.status) {
        this.signatureList.splice(index, 1);
      }
      this.loadingService.closeLoading();
    })
  }

  downloadSignatureFilePng(ImageBase64: string) {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let hours = today.getHours();
    let minutes = today.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minute = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + '' + minute + ampm;
    let filename = mm + dd + yyyy + strTime;
    var a = document.createElement("a"); //Create <a>
    a.href = this.signatueImage(ImageBase64); //Image Base64 Goes here
    a.download = filename + ".png"; //File name Here
    a.click();
  }

  onSelectSignature(signId: string) {
    if (this.conf.status === 'favorite') {
      this.selectSignature = signId;
      this.selectSignat.emit(signId);
    }
  }
}
