<section class="w-100" style="background-color: #e2e6eb;">


  <div class="smallerUi">


    <section class="container-fluid p-0 d-block">
      <h3 class="tit-tem-man-001 text-center barumBold d-none">계약서 관리</h3>
      <div class="">
        <div class="col-12 p-0 manageBtns d-flex justify-content-start flex-row offtheButts">
          <button class="btn btn-small-long btn-white" (click)='deleteAllSelect()'> 삭제 </button>
          <button class="btn btn-small-long btn-white" (click)="availableAllSelect()"> 사용 </button>
          <button class="btn btn-small-long btn-white" (click)="notAvailableAllSelect()"> 비사용 </button>
          <button class="btn btn-small-long btn-white" (click)="onToCreateTemplate()"> 만들기 </button>
          <button class="btn btn-small-long btn-white" (click)="test()" *ngIf="false"> test-jerrick </button>
        </div>

        <div class="tableInputBox justify-content-center d-flex flex-row order-1">
          <form [formGroup]=formSearch class="justify-content-center d-flex flex-row order-1 w-100">
            <div class="form-group tableInput1 mb-0">
              <select formControlName='serachType' class="form-control form-control-sm inp-sel-typ-001">
                <option *ngFor="let type of codeBook.SEARCH_TEMP_MOBILE" value="{{type.code}}">{{type.codeNm}}</option>
              </select>
            </div>

            <div class="input-group tableInput2"
              *ngIf="(!(formSearch.get('serachType').value === '000006') && !(formSearch.get('serachType').value === '000005'))">
              <input type="text" formControlName='searchText'
                class="form-control form-control-sm shadow-none inp-sea-typ-001" placeholder="계약서 명, 보낸 사람, 받는 사람 "
                style="box-shadow: none !important;" aria-describedby="button-addon2" (keyup.enter)="searchTemplate()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-search btn-sm" (click)="searchTemplate()"
                  style="color: #000;" type="button">
                  <img src="../../../assets/img/frontpage/searchicons.png" width="16px">
                </button>
              </div>
            </div>

            <div class="input-group tableInput2" *ngIf="((formSearch.get('serachType').value === '000005'))">
              <select formControlName='searchText' (change)="searchTemplate()"
                class="form-control form-control-sm inp-sel-typ-001">
                <option *ngFor="let code of codeBook.TEMPL_STATUS" value="{{code.code}}">{{code.codeNm}}</option>
              </select>
            </div>

            <div class="input-group tableInput2" *ngIf="((formSearch.get('serachType').value === '000006') )">
              <input type="date" (change)="searchTemplate()" formControlName='searchText'
                class="form-control form-control-sm shadow-none inp-sea-typ-001" placeholder="계약서 명, 보낸 사람, 받는 사람 "
                style="box-shadow: none !important;" aria-describedby="button-addon2">
            </div>
          </form>

        </div>

      </div>


      <div class="phoneContainer">
        <div class="d-flex row-hl">
          <div class="p-2 item-hl">상태</div>

          <div class="p-2 ml-auto item-hl">생성 날짜 </div>
        </div>


        <ul class="mobTable  list-group mb-5">
          <ng-container *ngFor="let t of tp">
            <li class="list-group-item bg-transparent border-0">

              <div class="item-form sc-jWxkHr mobcellA">
                <div class="row m-0">
                  <div class="field-cell check">
                    <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox" id="form_5f48cab259087664b3096102"
                        readonly=""><label for="form_5f48cab259087664b3096102">check0</label></div>
                  </div>

                  <div [ngClass]="{'field-cell state form color-blue': true, 'col-complete': t.templStatus === '완료', 'col-temporary': t.templStatus === '임시'}"><span>{{t.templStatus}} </span></div>
                  <div class="field-cell form-name"><span class="truncate"> {{t.templNm}} <i
                        class="img-ico-lis-man-001 ico-man-lis-edt-001" *ngIf="false"
                        (click)="showEditor(t.templId,t.templNm)"></i></span></div>
                  <div class="field-cell date last-date"><span class="truncate"> {{t.frstRegisterPnttm}}</span></div>
                  <div class="field-cell activity"></div>
                </div>
              </div>
            </li>
          </ng-container>

          <ng-container *ngIf="(tp.length === 0)">
            <li class="list-group-item bg-transparent border-0">

              <div class="item-form sc-jWxkHr mobcellA d-flex align-items-center justify-content-center">
                <div class="row m-0">
                  <div class="field-cell check">
                    <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox" id="form_5f48cab259087664b3096102"
                        readonly=""><label for="form_5f48cab259087664b3096102">check0</label></div>
                  </div>

                  <div class="field-cell state form color-blue"><span></span></div>
                  <div class="field-cell form-name text-center"><span class="truncate p-0">계약이 없습니다
                  <i class="img-ico-lis-man-001 ico-man-lis-edt-001" *ngIf="false"></i></span></div>
                  <div class="field-cell date last-date"><span class="truncate"></span></div>
                  <div class="field-cell activity"></div>

                </div>
              </div>

            </li>
          </ng-container>


        </ul>
      </div>


      <div class="tabletContainer">
        <div class="d-flex row-hl  tabletTableHead">
          <div class=" item-hl">상태</div>

          <div class=" ml-auto item-hl">생성 날짜 </div>
        </div>
        <ul class="tabletTable">
          <ng-container *ngFor="let t of tp">
            <li class="bg-white margin-b3">
              <div class="container">
                <div class="row mobTabRow1">
                  <div class="d-flex w-100">
                    <div [ngClass]="{'col-complete': t.templStatus === '완료', 'col-temporary': t.templStatus === '임시'}">{{t.templStatus}}</div>
                    <div class="ml-auto"> {{t.frstRegisterPnttm}}</div>
                  </div>
                </div>

                <div class="row mobTabRow2">
                  <div class="d-flex w-100">
                    <div class="item-hl">{{t.templNm}}</div>
                  </div>
                </div>

              </div>
            </li>

          </ng-container>
        </ul>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 30px">
        <app-pagination [maxDisplayPage]="5" [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
          (seclectPage)="setCurrentPage($event)"></app-pagination>
      </div>

    </section>


  </div>


  <div class="largerUi">
    <section class="container-lg ml-lg-0 mr-lg-auto p-0 d-none d-lg-block">
      <h3 style="padding-bottom:35px;" class="tit-tem-man-001 barumBold">계약서 관리</h3>

      <div class="row mb-2">

        <div class="col-md-6 d-flex flex-row align-items-end offtheButts">
          <button class="btn btn-small-long btn-white" (click)='deleteAllSelect()'> 삭제 </button>
          <button class="btn btn-small-long btn-white" (click)="availableAllSelect()"> 사용 </button>
          <button class="btn btn-small-long btn-white" (click)="notAvailableAllSelect()"> 비사용 </button>
          <button class="btn btn-small-long btn-white" (click)="onToCreateTemplate()"> 만들기 </button>
        </div>

        <div class="col-md-6 d-flex flex-row-reverse">
          <form [formGroup]=formSearch class="w-100 d-flex flex-row-reverse">
            <div class="input-group tableSearchgroup" style="width: 192px;"
              *ngIf="(!(formSearch.get('serachType').value === '000006') && !(formSearch.get('serachType').value === '000005'))">
              <input type="text" formControlName='searchText'
                class="form-control form-control-sm shadow-none inp-sea-typ-001" placeholder="계약서 명, 보낸 사람, 받는 사람 "
                style="box-shadow: none !important;" aria-describedby="button-addon2" (keyup.enter)="searchTemplate()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-search btn-sm" (click)="searchTemplate()"
                  style="color: #000;" type="button">
                  <img src="../../../assets/img/frontpage/searchicons.png" width="16px">
                </button>
              </div>
            </div>

            <div class="input-group tableSearchgroup" style="width: 252px;"
              *ngIf="((formSearch.get('serachType').value === '000005'))">
              <select formControlName='searchText' (change)="searchTemplate()"
                class="form-control form-control-sm inp-sel-typ-001">
                <option *ngFor="let code of codeBook.TEMPL_STATUS" value="{{code.code}}">{{code.codeNm}}</option>
              </select>
            </div>

            <div class="input-group tableSearchgroup" *ngIf="((formSearch.get('serachType').value === '000006') )">
              <input type="date" (change)="searchTemplate()" formControlName='searchText'
                class="form-control form-control-sm shadow-none inp-sea-typ-001" placeholder="계약서 명, 보낸 사람, 받는 사람 "
                style="box-shadow: none !important;" aria-describedby="button-addon2">
            </div>

            <div class="form-group mb-0">
              <select formControlName='serachType' class="form-control form-control-sm inp-sel-typ-001">
                <option *ngFor="let type of codeBook.SEARCH_TEMP" value="{{type.code}}">{{type.codeNm}}</option>
              </select>
            </div>
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-12">

          <table class="table largeTable table-hover">
            <thead class="th-bg-001">
              <tr>
                <th class="text-center tex-tit-tab-001">
                  선택
                </th>
                <th class="text-center tex-tit-tab-001">
                  사용
                </th>
                <th class="text-center tex-tit-tab-001">
                  상태
                </th>
                <th class="text-center tex-tit-tab-001">
                  계약서 명
                </th>
                <th class="text-center tex-tit-tab-001">
                  생성자
                </th>
                <th class="text-center tex-tit-tab-001">
                  생성 날짜
                </th>
                <th class="text-center tex-tit-tab-001">
                  마지막 업데이트
                </th>
                <th class="text-center tex-tit-tab-001">
                  액션
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <ng-container *ngFor="let t of tp">
                <tr class="text-center {{checkSelectTeplate(t.templId)}}"
                  (click)="showEditorRow($event,t.templId,t.templNm)">
                  <td scope="row">

                    <div style="line-height: 1;" class="form-group form-check p-0 m-0">
                      <label class="con-che-001">
                        <input type="checkbox" (click)="checkBoxSelect(t.templId)">
                        <span class="che-mar-001"></span>
                      </label>
                    </div>

                  </td>
                  <td class="text-center">{{t.templYn}}</td>
                  <td [ngClass]="{'tabOverflowStat text-center': true, 'col-complete': t.templStatus === '완료', 'col-temporary': t.templStatus === '임시'}">{{t.templStatus}}</td>
                  <td class="tabOverflow pr-4 text-center">{{t.templNm}}</td>
                  <td class="text-center">{{t.userCre}}</td>
                  <td class="text-center">{{t.frstRegisterPnttm}}</td>
                  <td class="text-center">{{t.lastUpdusrPnttm}}</td>
                  <td class="text-center" style="min-width: 152px;">
                    <button type="button"  title="계약서 보내기"  class="img-ico-lis-man-001 ico-man-lis-rep-001"
                      [disabled]="(((t.templYn == 'N') || (t.templStatus == '임시')))" (click)="onClickReply(t.templId,t.templNm)">
                    </button>
                    <span title="계약서 편집"  class="img-ico-lis-man-001 ico-man-lis-edt-001"
                      (click)="showEditor(t.templId,t.templNm)"></span>
                    <span class="img-ico-lis-man-001 ico-man-lis-lin-001 d-none" (click)="onClickSendLink()"></span>
                    <button title="PDF 다운로드" class="img-ico-lis-man-001 ico-man-lis-loa-001" [disabled]="(((t.templYn == 'N') || (t.templStatus == '임시')))"
                      (click)="onDownload(t.templId,t.templNm)"></button>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="(tp.length === 0)">
                <tr>
                  <td class="text-center" colspan="8">
                    <b>계약이 없습니다</b>
                  </td>
                </tr>
              </ng-container>

            </tbody>
          </table>
          <div class="d-flex justify-content-center" style="margin-top: 30px">
            <app-pagination [currentPage]='pagination.currentPage' [maxPage]='pagination.maxPage'
              (seclectPage)="setCurrentPage($event)"></app-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>



</section>
<app-loading></app-loading>
<app-modal></app-modal>
<app-alert-confirm></app-alert-confirm>
<app-send-link></app-send-link>
<app-alert-shared></app-alert-shared>
