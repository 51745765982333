import { Component, OnInit } from '@angular/core';

import { ModalRefundPaymentService } from './modal-refund-payment.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-refund-payment',
  templateUrl: './modal-refund-payment.component.html',
  styleUrls: ['./modal-refund-payment.component.scss']
})

export class ModalRefundPaymentComponent implements OnInit {

  formRefund: FormGroup;

  show: boolean;
  item: {
    amountPay: string,
    approvalDate: string,
    approvalNumber: string,
    cid: string,
    paymentDate: string,
    paymentDetails: string,
    paymentType: string,
    processingStatus: string,
    purchasePoint: string,
    tid: string
  };
  constructor(
    private fb: FormBuilder,
    private modalRefundPaymentService: ModalRefundPaymentService,
  ) { }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

    if (this.modalRefundPaymentService.change.show) {
      this.show = this.modalRefundPaymentService.show;
      this.modalRefundPaymentService.setChange('show', false);
      this.item = this.modalRefundPaymentService.getItem();
      this.initFormRefund();
    }
  }

  initFormRefund() {
    this.formRefund = this.fb.group({
      qaaContrnt: [null]
    });

    this.formRefund.get('qaaContrnt').valueChanges.subscribe((value) => {
      console.log(value);
      this.modalRefundPaymentService.setContent(value);
    })
  }

  onClickBtn(btn: any) {
    this.modalRefundPaymentService[btn].func();
  }

  closeModal() {
    this.modalRefundPaymentService.close();
  }

  closeModalBackground($event) {
    if ($event.target.className.includes("modal fade show")) {
      this.modalRefundPaymentService.close();
    }
  }
}
