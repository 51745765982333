import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ServiceCenterService {

  constructor(
    private http: HttpClient
  ) { }

  pathInsertInquire: string = API_URL.insertInquire;
  pathGetAllInquiry: string = API_URL.getAllInquiry;
  pathGetInquiryAnswer: string = API_URL.getInquiryAnswer;
  pathGetTemplateMail: string = API_URL.getTemplateMail;
  pathCreateTemplateMail: string = API_URL.createTemplateMail;
  pathSearchNoticeFront: string = API_URL.searchNoticeFront;
  pathGetQuestionAnswerFront: string = API_URL.getQuestionAnswerFront;
  pathInsertInquireFront: string = API_URL.insertInquireFront;
  pathUpdateTemplateMail: string = API_URL.updateTemplateMail;
  pathDeleteTemplateMail: string = API_URL.deleteTemplateMail;
  pathGetPersonalNotReadNotic: string = API_URL.getPersonalNotReadNotic;
  pathInsertPersonalNotic: string = API_URL.insertPersonalNotic;


  insertInquire(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathInsertInquire, params);
  }

  getAllInquiry(data: any) {
    return this.http.post(this.pathGetAllInquiry, data);
  }

  getInquiryAnswer(data: any) {
    let params = {
      data: data,
    }

    return this.http.post(this.pathGetInquiryAnswer, params);
  }

  getTemplateMail(data: any) {
    return this.http.post(this.pathGetTemplateMail, data);
  }

  createTemplateMail(data: any) {
    let params = {
      data: data,
    }
    return this.http.post(this.pathCreateTemplateMail, params);
  }

  updateTemplateMail(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathUpdateTemplateMail, params);
  }

  deleteTemplateMail(data: any) {
    let params = {
      data: data
    }

    return this.http.post(this.pathDeleteTemplateMail, params);
  }

  searchNoticeFront(params: any) {
    return this.http.post(this.pathSearchNoticeFront, params);
  }

  getQuestionAnswerFront(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathGetQuestionAnswerFront, params);
  }

  insertInquireFront(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathInsertInquireFront, params);
  }

  getPersonalNotReadNotic() {
    let params = {
      data: ""
    }
    return this.http.post(this.pathGetPersonalNotReadNotic, params);
  }

  insertPersonalNotic(data: any) {
    let params = {
      data: data
    }
    return this.http.post(this.pathInsertPersonalNotic, params);
  }
}
