<div style="position: sticky;" class="d-flex justify-content-between align-items-center py-2 borderlinebot ">

  <div class="d-flex justify-content-center px-2">
    <span class="">
      {{page.pagenumber}}
    </span>
    <span class="">
      &nbsp;/&nbsp;
    </span>
    <span class="">
      {{page.maxpage}} &nbsp; 페이지
    </span>
  </div>

  <div class="d-flex justify-content-center px-2">
    <div class="img-ico-edi-002 ico-edi-zoo-in-001" (click)="zoom_in()">
    </div>

    <div class="img-ico-edi-002 ico-edi-zoo-out-001" (click)="zoom_out()">
    </div>

    <div class="img-ico-edi-003 ico-edi-pri-001" (click)="printerPdf()" *ngIf="tools.show.buttons.printer">
    </div>
  </div>

  <div class="d-flex justify-content-center px-2">
    <button class="btn btn-small-long btn-white" (click)="onCancelEditors()" *ngIf="tools.show.buttons.close">
      {{btnName}}
    </button>
  </div>

</div>


<div class="d-flex w-100 justify-content-center align-items-center status-edit">
  <button class="btn btn-big btn-gray" style="width: auto !important;" *ngIf="tools.show.buttons.back"
    (click)="btnPrevious()">
    {{tools.show.buttons.backText}}
  </button>
  <button class="btn btn-big btn-crimson" style="width: auto !important;" *ngIf="tools.show.buttons.next"
    (click)="btnNext()">
    {{tools.show.buttons.nextText}}
  </button>
</div>


<div class="container-fluid px-0">
  <div class="d-flex" id="wrapper">
    <div id="sidebar-wrapper" *ngIf="tools.show.toolsEdit" class="bg-back-tools d-flex justify-content-center">
      <app-tools-editor-pdf [configTools]='{}' (evnentAddFabric)='addFabric($event)'
        (evnentRequestRender)='evnentRequestRender($event)'>
      </app-tools-editor-pdf>
    </div>
    <div id="page-content-wrapper" style="background: #E9E9E9;">
      <div class="d-flex justify-content-center align-items-start">
        <div class="view-pdf">
          <div class="pdf-view" id='view-pdf-div' (scroll)="scrollHandler($event)">
            <div class="d-flex">
              <div class="d-flex justify-content-center w-100" style="overflow:auto; padding-bottom: 50px;">
                <pdf-viewer (after-load-complete)="afterLoadComplete($event)" [src]="pdfSrc[1].src"
                  [render-text]="false" (page-rendered)="pageRendered($event)" [zoom]="zoom_to" style="display: block;">
                </pdf-viewer>
              </div>
            </div>
          </div>

        </div>
        <div class="page-view d-none d-md-block p-2" *ngIf="tools.show.pageView">
          <div *ngFor="let image of imagePage; let i = index" [attr.data-index]="1"
            [ngClass]='page.pagenumber == (i + 1) ? "sursor-pointer page-border action w-100 d-flex justify-content-center p-1 my-3" : "sursor-pointer page-border w-100 d-flex justify-content-center p-2 my-2"'
            (click)="clickPage(i)">
            <span class="num-pag-001">{{(i+1)}}</span>
            <img src='{{image}}' width="75%" class="img-fluid">
          </div>
        </div>
        <div class="page-setting p-2" *ngIf="tools.show.pageSetting">
          <form [formGroup]=formSetting>
            <div class="form-group">
              <label for="defaultCheck1" class="tex-tit-001">필수입력</label>
              <div class="form-check">
                <label class="con-che-001">필수 입력필드로 지정
                  <input type="checkbox" formControlName='designated' type="checkbox" value="">
                  <span class="che-mar-001" style="left: -20px;"></span>
                </label>
              </div>
            </div>

            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label for="type" class="tex-tit-001">작성자 지정</label>
              </div>


              <div class="multipleSelection">
                <div class="selectBox" (click)="showCheckboxes()">
                  <select class="form-control sel-001 group" id="type" formControlName='type'>
                    <option>Select options</option>
                    <option *ngFor="let type of codeBook.MARK_CONTOR_TYPE" value="{{type.codeDc}}">
                      {{type.codeNm}}
                    </option>
                  </select>
                  <div class="overSelect"></div>
                </div>

                <div id="checkBoxes" *ngIf="selectGroup" (click)="showCheckboxes()">
                  <label class="d-flex flex-row align-items-center item-group tex-tit-001"
                    (click)="selectContorType(type)" for="group" *ngFor="let type of codeBook.MARK_CONTOR_TYPE">
                    &nbsp;&nbsp; <div class="dot-color color-{{type.code}}"></div>&nbsp;&nbsp;
                    <span>{{type.codeNm}}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label for="fontsize" class="tex-tit-001">폰트 크기</label>
                <button class="btn btn-verysmall btn-white" (click)="onSettingsFontSize()">모두적용</button>
              </div>

              <select class="form-control sel-001" id="exampleFormControlSelect1" formControlName='fontsize'>
                <ng-container *ngFor="let fontSize of fontSizes;let i = index">
                  <option value="{{fontSize.value}}">{{fontSize.value}} px</option>
                </ng-container>
              </select>
            </div>

            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label for="type" class="tex-tit-001">글자 정렬</label>
              </div>
              <select class="form-control sel-001 group" id="align" formControlName='align'>
                <option class="group" *ngFor="let type of codeBook.EDITOR_TEXT_ALIGN" value="{{type.codeDc}}">
                  {{type.codeNm}}
                </option>
              </select>
            </div>

            <div class="form-group" *ngIf="editor.useComm">
              <div class="d-flex justify-content-between align-items-center">
                <label for="type" class="tex-tit-001">자릿수 구분(,)</label>
              </div>
              <select class="form-control sel-001 group" id="useComm" formControlName='useComm'>
                <option class="group" *ngFor="let type of codeBook.EDITOR_NUMBER" value="{{type.code}}">
                  {{type.codeNm}}
                </option>
              </select>
            </div>

            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label for="color" class="tex-tit-001">폰트 색상</label>
                <button class="btn btn-verysmall btn-white" (click)="onSettingsColor()">모두적용</button>
              </div>

              <input class="form-control colorControl" formControlName='color' type="color" />
            </div>

            <div class="form-group">
              <label for="" class="tex-tit-001">설명</label>
              <textarea type="text" class="form-control tex-are-001" maxlength="300" rows="1" formControlName='comment'
                placeholder=""></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf='loading' class="d-flex justify-content-be align-items-center"
  style="position: fixed; width: 100vw;height: 100vh;z-index: 99999 !important;top: 0;background: rgba(0,0,0,0.75);">
  <div class="spinner-grow text-info" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<app-alert-shared></app-alert-shared>
<app-submit-certificate></app-submit-certificate>
<app-modal-add-signature></app-modal-add-signature>
<app-modal-gallery-signature></app-modal-gallery-signature>
<app-modal-attachment-file></app-modal-attachment-file>
<app-loading></app-loading>
