<div class="row-hl btnnav justify-content-center d-flex">
    <div class="serviceBalance">
        <h2 class="text-center centerSubject w-100">
            공지사항
        </h2>
        <form class="form-inline tableForm" [formGroup]=formSearch>
            <div class=" mx-auto serviceInputArea d-flex align-items-center">
                <div class="input-group  align-items-center">
                    <select formControlName=serachType class="form-control form-control-sm serviceDrop inp-sel-typ-001">
                        <option *ngFor="let type of serachType" value={{type.code}}>{{type.codeNm}}</option>
                    </select>
                    <input formControlName=searchText class="form-control serviceSearch border-right-0"
                        (keyup.enter)="searchNoticeFront()" type="text" placeholder="입력해 주세요">
                    <div class="input-group-append bg-white serviceSearchIcon">
                        <button class="mb-0 btn searchService border-left-0 p-0" (click)="searchNoticeFront()"
                            type="button">
                            <img src="../../../assets/img/frontpage/searchicons.png" width="30px">
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="notict-list-box  mx-auto">
            <!-- Smaller Table -->

            <div class="smallerUi">
                <div class="d-block ">
                    <ul class="mobTable  list-group " *ngFor="let noticeList of noticeLists; let i = index"
                        (click)="onClickItem(noticeList)">
                        <li class="list-group-item bg-transparent border-0">
                            <div class="item-form sc-jWxkHr mobcellA">
                                <div class="row m-0">
                                    <div class="field-cell check">
                                        <div class="reverse sc-jzJRlG bInUZT"><input type="checkbox"
                                                id="form_5f48cab259087664b3096102" readonly=""><label
                                                for="form_5f48cab259087664b3096102">check0</label></div>
                                    </div>
                                    <div class="field-cell state form color-blue"><span>
                                            {{noticeList.noticNo > 9 ? noticeList.noticNo : '0' + noticeList.noticNo }}</span>
                                    </div>
                                    <div class="field-cell form-name"><span class="truncate tabOverflow">
                                            <a>{{noticeList.noticTitle}}</a></span>
                                    </div>
                                    <div class="field-cell date last-date"><span class="truncate">
                                            {{noticeList.registerPnttm}}</span></div>
                                    <div class="field-cell activity"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="d-flex justify-content-center" style="margin: 60px 0px 46px 0px">
                        <app-pagination [currentPage]='1' [maxPage]='1' (seclectPage)="setCurrentPage($event)">
                        </app-pagination>
                    </div>
                </div>
            </div>


            <!-- Larger Table  -->
            <div class="largerUi">
                <div class="d-none d-lg-block">
                    <div class="d-none  row-hl align-items-center">
                        <div class=" ml-auto item-hl">총 40건</div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table largeTable">
                                <thead class="th-bg-001">
                                    <tr>
                                        <th class="text-center tex-tit-tab-001 serviceCenterTableA">
                                            NO
                                        </th>
                                        <th class="text-center tex-tit-tab-001  ">
                                            내용
                                        </th>
                                        <th class="text-center tex-tit-tab-001 serviceCenterTableC">
                                            등록일
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr class="text-center lis-not-001" *ngFor="let noticeList of noticeLists; let i = index"
                                        (click)="onClickItem(noticeList)">
                                        <td class="noticeNum" scope="row">
                                            {{noticeList.noticNo > 9 ? noticeList.noticNo : '0' + noticeList.noticNo }}
                                        </td>
                                        <td class="tabOverflow {{checkNotRead(noticeList.noticId)}}"> <a>{{noticeList.noticTitle}}</a></td>
                                        <td>{{noticeList.registerPnttm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center" style="margin: 60px 0px 46px 0px">
                                <app-pagination [currentPage]='1' [maxPage]='1' (seclectPage)="setCurrentPage($event)">
                                </app-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of org -->
        </div>
    </div>
</div>