import {Injectable} from '@angular/core';
import {API_URL} from "../api.constant";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class BlockCertService {

    pathCreateBlockCert: string = API_URL.createBlockCert;
    pathBlockCert: string = API_URL.blockCert;
    pathGetContractInfo: string = API_URL.getContractInfo;
    pathResendBlockCert: string = API_URL.resendBlockCert;
    pathBlockCertDetails: string = API_URL.blockCertDetails;

    constructor(
        private http: HttpClient
    ) {
    }
    public createBlockCert(fileId: string, fileName: string, frstRegNo: string) {
        const param = {
            data: {
                fileId: fileId,
                fileName: fileName,
                frstRegNo: frstRegNo
            }
        }
        return this.http.post(this.pathCreateBlockCert, param);
    }

    public blockCert(eCertId: string) {
        const param = {
            data: {
                ecertId: eCertId,
            }
        }
        return this.http.post(this.pathBlockCert, param);
    }

    public getContractInfo(eCertId: string, frstRegisterNo: string) {
        const param = {
            data: {
                contId: eCertId,
                contorId: frstRegisterNo
            }
        }
        return this.http.post(this.pathGetContractInfo, param);
    }

    public resendBlockCert(contId: string, contorId: string, eCertId: string) {
        let param = {
            data: {
                contId: contId,
                contorId: contorId,
                ecertId: eCertId
            }
        }
        return this.http.post(this.pathResendBlockCert, param)
    }

    public blockCertDetails(data: any) {
        const param = {
            data: data
        }
        return this.http.post(this.pathBlockCertDetails, param);
    }

}

