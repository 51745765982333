<div class="bg-modal" *ngIf="show">
    <!-- Modal -->
    <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content rounded-0" style="background: transparent">
                <div class="modal-header border-0 rounded-0" style="background: #ffffff;color: #000000;">
                    <div class="border-bottom border-dark d-flex w-100 pb-2 ">
                        <h5 class="modal-title font-weight-light" id="exampleModalLabel">취소 /환불 요청</h5>
                        <a type="button" style="color: #000000;" class="close" (click)="closeModal()"
                            data-dismiss="modal" aria-label="Close">
                            <img src="../../../../../assets/img/icon/exitbtn.png"></a>
                    </div>
                </div>
                <div class="modal-body rounded-0" style="background: #ffffff;color: #000000;">
                    <form [formGroup]=formRefund class="px-3">
                        <div class="row">
                            <div class="col-3 box-tit-001">
                                <label class="tit-mod-ref-001">결제 일시</label>
                            </div>
                            <div class="col-9 box-det-001">
                                <label class="tit-mod-ref-001">{{item.paymentDate}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 box-tit-001">
                                <label class="tit-mod-ref-001">결제 서비스 내역 </label>
                            </div>
                            <div class="col-9 box-det-001">
                                <label class="det-mod-ref-001">{{item.paymentType}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 box-tit-001">
                                <label class="tit-mod-ref-001">결제 금액</label>
                            </div>
                            <div class="col-9 box-det-001">
                                <label class="det-mod-ref-001">{{item.amountPay}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 box-tit-001">
                                <label class="tit-mod-ref-001">요청 문의 </label>
                            </div>
                            <div class="col-9 box-det-001">
                                <textarea class="form-control" formControlName=qaaContrnt rows="5" style="resize: none;"></textarea>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer rounded-0 d-flex justify-content-center border-0 py-4"
                    style="background: #ffffff;color: #000000;">
                    <button type="button" class="btn btn-white btn-middle-short"
                        (click)="onClickBtn('btnNo')">취소</button>
                    <button type="button" class="btn ml-2 btn-black btn-middle-short"
                        (click)="onClickBtn('btnYes')">확인</button>
                </div>
            </div>
        </div>
    </div>
</div>