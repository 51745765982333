import NavigatorSystem from '../NavigatorSystem/navigator-system';
import { environment } from '../../../../environments/environment';

export default class GenerateExcel {

    public static generateFileExcel(fileName: string, headers: string[]) {
        let codeOs = NavigatorSystem.getOS();
        // console.log(codeOs);
        switch (codeOs) {
            case 100:
                // code block
                this.generateFileExcelOsx(fileName, headers);
                break;
            case 101:
                // code block
                this.generateFileExcelOsx(fileName, headers);
                break;
            case 102:
                // code block
                this.generateFileExcelWindows(fileName, headers);
                break;
            case 103:
                // code block
                this.generateFileExcelOsx(fileName, headers);
                break;
            case 104:
                // code block
                this.generateFileExcelOsx(fileName, headers);
                break;
            default:
                this.generateFileExcelWindows(fileName, headers);
            // code block
        }
    }

    private static generateFileExcelOsx(fileName: string = '수신자 리스트', headers: string[]) {
        // Specify file name
        let filename = fileName ? fileName + '.xls' : 'excel_data.xls';

        let tableHTML = '';
        tableHTML += '<tr>';
        headers.forEach(header => {
            tableHTML += '<td>' + header + '</td>';
        });
        tableHTML += '</tr>';


        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            },
            format = function (s, c) {
                return s.replace(/{(\w+)}/g, function (m, p) {
                    return c[p];
                })
            }
        var toExcel = tableHTML;
        var ctx = {
            worksheet: name || '',
            table: toExcel
        };
        var link = document.createElement("a");
        link.download = filename;
        link.href = uri + base64(format(template, ctx))
        link.click();
    }

    private static generateFileExcelWindows(fileName: string = '수신자 리스트', headers: string[]) {
        var a = document.createElement('a');
        var data_type = 'data:application/vnd.ms-excel';
        let filename = fileName ? fileName + '.xls' : 'excel_data.xls';

        let tableHTML = '<table>';
        tableHTML += '<tr>';
        headers.forEach(header => {
            tableHTML += '<td>' + header + '</td>';
        });
        tableHTML += '</tr>';
        tableHTML += '</table>';

        a.href = data_type + ', ' + encodeURIComponent(tableHTML);
        a.download = filename;
        a.click();
    }

    public static linkDownloadFileExcelContract(): void {

        var a = document.createElement('a');
        a.href = environment.downloadFileAttachment + '/JSTCRM/s/TempExcel/excel_contor.xlsx';
        a.download = '수신자 리스트';
        a.click();
    }

    public static linkDownloadFileExcelImportMembers(): void {
        var a = document.createElement('a');
        a.href = environment.downloadFileAttachment + '/JSTCRM/s/TempExcel/excel_ImportMember.xlsx';
        a.download = '조직 리스트';
        a.click();
    }
}
