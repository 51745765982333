import { Component, OnInit } from '@angular/core';
import dateShared from "../../shared/date-shared/date-shared";
import {Router} from "@angular/router";
import {AlertConfirmService} from "../modal/alert-confirm/alert-confirm.service";
import {ContractCancellationService} from "../modal/contract-cancellation/contract-cancellation.service";
import {ContractService} from "../../service/contract/contract.service";
import {SidebarService} from "../sidebar/sidebar.service";
import {LoginService} from "../../../frontpage/login/login.service";
import {EditorPdfService} from "../editor-pdf/editor-pdf.service";
import {LoadingService} from "../../shared/loading/loading.service";
import {NavbarService} from "../navbar/navbar.service";
import { faSearch, faTimes, faTv, faRedo, faExclamationCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import {TableRequestedBlockCertService} from "./table-requested-block-cert.service";
import {BlockCertService} from "../../service/blockCert/block-cert.service";
import {BlockCertDetailsService} from "../modal/block-cert-details/block-cert-details.service";

//블록체인 인증 서비스 컴포넌트
@Component({
  selector: 'app-table-requested-block-cert',
  templateUrl: './table-requested-block-cert.component.html',
  styleUrls: ['./table-requested-block-cert.component.scss']
})
export class TableRequestedBlockCertComponent implements OnInit {

  dateFormat: dateShared = new dateShared();
  faTv = faTv;
  faRedo = faRedo;
  faExclamationCircle = faExclamationCircle;
  faDownload = faDownload;
  getUser: boolean = true;

  tpOne: {
    // code: string,
    // contId: string,
    // contorEmail: string,
    // contorId: string,
    // contorMobile: string,
    // contorNm: string,
    // contorSerialno: string,
    // contorType: string,
    // frstRegisterPnttm: string,
    // lastUpdusrPnttm: string
    // progressStatus: string,
    // signId: string,
    // templId: string,
    // templNm: string,
    // userReq: string,
    /** 블록체인 전자계약 인증 **/
    ecertId               : string,
    frstRegisterNo        : string,
    ecertNm               : string,
    contorId              : string,
    userNm                : string,
    fileId                : string,
    ecertType             : string,
    pldgDate              : string,
    pldgHash              : string,
    pldgHashDate          : string,
    pldgBlockIfResCode    : string

  }[] = [];
  tList: any = [];
  paramsApi = {
    data: {
      frstRegisNo: "",
      text: "",
      serachType: ""
    },
    pageNo: 0,
    pageSize: 10
  }

  pagination = {
    maxPage: 1,
    currentPage: 1
  }

  constructor(
      private router: Router,
      private alertConfirmService: AlertConfirmService,
      private contractCancellationService: ContractCancellationService,
      private contractService: ContractService,
      private tableRequestedBlockCertService: TableRequestedBlockCertService,
      private sidebarService: SidebarService,
      private loginService: LoginService,
      private editorPdfService: EditorPdfService,
      private loadingService: LoadingService,
      private navbarService: NavbarService,
      private blockCertService: BlockCertService,
      private blockCertDetails: BlockCertDetailsService,
  ) { }

  ngOnInit(): void {
    this.navbarService.setAction('contorSend');
    this.sidebarService.setSubManuActive('requested_blockCert');
  }

  ngDoCheck(): void {
    if (this.tableRequestedBlockCertService.getState() && (this.loginService.getProfileLogin() != null)) {
      this.initParamsApi();
      this.getContractAll();
      this.tableRequestedBlockCertService.setState(false);
    }
    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.getUser = false;
      this.initParamsApi();
      this.getContractAll();
    }
  }

  initParamsApi(): void {
    this.paramsApi = this.tableRequestedBlockCertService.getData();
    this. pagination.currentPage = this.tableRequestedBlockCertService.getPageNo() + 1;
    this. paramsApi.data.frstRegisNo = this.loginService.getProfileLogin().userIdNo;
  }

  getContractAll() {
    this.loadingService.openLoading();
    this.contractService.getAllBlockCert(this.paramsApi).subscribe((rs: any) => {
      if (rs.status) {
        this.loadingService.closeLoading();
        this.tpOne = rs.data;
        this.calMaxPage(rs.total, this.paramsApi.pageSize);
      }
    });
  }

  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.paramsApi.pageNo = this.pagination.currentPage - 1;
      }
      this.loadingService.openLoading();
      this.contractService.getAllBlockCert(this.paramsApi).subscribe((rs: any) => {
        if (rs.status) {
          this.tpOne = rs.data;
          this.loadingService.closeLoading();
        }
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.paramsApi.pageNo = $event - 1;
    this.tableRequestedBlockCertService.setPageNo($event - 1);
    this.getContractAll();
  }

  onClickRedo(ecertType: string, ecertId: string, frstRegisterNo: string, ecertNm: string, contorId: string) {
    this.alertConfirmService.modal.title = "재전송 확인";
    this.alertConfirmService.open(
        '인증 요청 시 인증까지는 최대 30분이 소요됩니다.<br>재인증을 하시겠습니까?',
        '',
        {
          func: () => {
            this.alertConfirmService.close();
          }
        },
        {
          func: () => {
            if(ecertType == '온라인') {
              this.blockCertService.getContractInfo(ecertId, contorId).subscribe((rs: any) => {
                if(rs.status) {
                  this.alertConfirmService.close();
                  this.resendContract(rs.data.contId, rs.data.contorId, null);
                }
              });
            } else {
              this.alertConfirmService.close();
              this.resendContract(null, null, ecertId);
            }
          }
        }
    );
  }

  resendContract(contId: string, contorId: string, eCertId: string) {
    this.loadingService.openLoading();
    this.blockCertService.resendBlockCert(contId, contorId, eCertId).subscribe((rs: any) => {
      if (rs) {
        window.location.reload();
      }
      this.loadingService.closeLoading();
    });
  }

  onClickExclamation(ecertType: string, ecertId: string, frstRegisterNo: string, ecertNm: string, contorId: string) {
    this.getContractDetails(ecertId);
  }


  getContractDetails(ecertId: string) {
    let params = {
        ecertId: ecertId
    }
    this.blockCertService.blockCertDetails(params).subscribe((rs: any) => {
      if (rs.status) {
        this.blockCertDetails.setBlockCertDetails(rs.data.ibContDetails);
        this.blockCertDetails.setBlockCertHistory(rs.data.ibContHistory);
        this.blockCertDetails.open();
      }
    });
  }

  onClickContractCancellation() {
    if (this.tList.length > 0) {
      this.contractCancellationService.open(
          {
            func: () => {
              this.contractCancellationService.close();
            }
          }, {
            func: () => {
              this.updateCancleContract(this.contractCancellationService.getContractCancle(), this.contractCancellationService.getMessage());
              this.contractCancellationService.close();
            }
          }
      );
    }
  }

  updateCancleContract(contractCancle: string, message: string) {
    let data = {
      contract: this.tList,
      contractCancle: contractCancle,
      message: message
    }
    this.loadingService.openLoading();
    this.contractService.updateCancleContract(data).subscribe((rs: any) => {
      this.tList = [];
      this.getContractAll();
      this.loadingService.closeLoading();
    });
  }

  checkBoxSelect(contorId: string, contId: string) {
    let arr = this.tList;

    let index = arr.findIndex(res => (res.contorId === contorId && res.contId === contId));

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push({ contorId: contorId, contId: contId });
    }
    this.tList = arr;
    console.log(this.tList);
  }

  onClickReviewContract(ecertType: string, ecertId: string, frstRegisterNo: string, ecertNm: string, contorId: string) {
    if(ecertType === '온라인') {
      this.blockCertService.getContractInfo(ecertId, contorId).subscribe((rs: any) => {
        if(rs.status) {
          this.editorPdfService.setContor({ contorId: rs.data.contorId, contId: rs.data.contId });
          this.editorPdfService.setTemplateId(rs.data.templId);
          this.editorPdfService.setTemplNm(rs.data.contNm);
          this.editorPdfService.setContorType('%');
          this.editorPdfService.setEdteMode("peview");
          this.editorPdfService.setState("init");
          this.editorPdfService.download = false;
          this.router.navigate(['review-template-contract']);
        }
      });
    } else {
      this.editorPdfService.setBlockCertId(ecertId);
      this.editorPdfService.setBlockCertNm(ecertNm);
      this.editorPdfService.setContorType('%');
      this.editorPdfService.setEdteMode("peview");
      this.editorPdfService.setState("init");
      this.editorPdfService.download = false;
      this.router.navigate(['certification/view']);
    }
  }

  onClickReviewDownloadContract(ecertType: string, ecertId: string, frstRegisterNo: string, ecertNm: string, contorId: string) {
    if(ecertType === '온라인') {
      this.blockCertService.getContractInfo(ecertId, contorId).subscribe((rs: any) => {
        if(rs.status) {
          this.editorPdfService.setContor({ contorId: rs.data.contorId, contId: rs.data.contId });
          this.editorPdfService.setTemplateId(rs.data.templId);
          this.editorPdfService.setTemplNm(rs.data.contNm);
          this.editorPdfService.setContorType('%');
          this.editorPdfService.setEdteMode("peview");
          this.editorPdfService.setState("init");
          this.editorPdfService.download = true;
          this.router.navigate(['dowliad-contract']);
        }
      });
    } else {
      this.editorPdfService.setBlockCertId(ecertId);
      this.editorPdfService.setBlockCertNm(ecertNm);
      this.editorPdfService.setContorType('%');
      this.editorPdfService.setEdteMode("peview");
      this.editorPdfService.setState("init");
      this.editorPdfService.download = true;
      this.router.navigate(['certification/view']);
    }
  }

  onClickReviewContractRow($event, tempId: string, contNm: string, contId: string, contorId: string) {
    if ($event.target.toString().includes("Table")) {
      this.editorPdfService.setContor({ contorId: contorId, contId: contId });
      this.editorPdfService.setTemplateId(tempId);
      this.editorPdfService.setTemplNm(contNm);
      this.editorPdfService.setContorType('%');
      this.editorPdfService.setEdteMode("peview");
      this.editorPdfService.setState("init");
      this.editorPdfService.download = false;
    }
  }
}
