<app-navber></app-navber>

<div class="d-flex dashwrap" id="wrapper ">
    <app-sidebar-damo [action]='sidebar' class="sidebarContainer"></app-sidebar-damo>
    <div id="page-content-wrapper" class="dashbody container-fluid">

        <div class="contentFixed" (click)="closeNav()">

            <router-outlet></router-outlet>
        </div>



    </div>
</div>