import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { UserService } from '../../service/user/user.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CodeBookService } from '../../service/code-book/code-book.service';
import { LoginService } from '../../../frontpage/login/login.service';
import { AlertService } from '../modal/alert/alert.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { NavbarService } from '../navbar/navbar.service';
import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';
import { API_URL } from 'src/app/manage/service/api.constant';
import { HttpClient, HttpHeaders, HttpEventType, HttpResponse } from '@angular/common/http';


import Regex from '../../shared/regex/regex';
import { Router } from '@angular/router';
import { AuthLoginService } from '../../service/authLogin/auth-login.service';
@Component({
  selector: 'app-information-manage',
  templateUrl: './information-manage.component.html',
  styleUrls: ['./information-manage.component.scss']
})
export class InformationManageComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private sidebarService: SidebarService,
    private userService: UserService,
    private loginService: LoginService,
    private codeBookService: CodeBookService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
    private router: Router,
    private authService: AuthLoginService,
    private alertSharedService: AlertSharedService,
  ) {
    this.sidebarService.setManuActive(this.sidebar);
  }
  allAgree;
  reason;
  getUser: boolean = true;
  showModal: boolean = false;
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
    })
  };
  input: {
    compNm: any
  } = {
      compNm: null
    }

  valid: {
    formProfileDetails: {
      compNm: boolean,
      compMail: boolean,
      compPhone: boolean,
      compAddress: boolean
    },
    fromContractInformations: {
      userNm: boolean,
      depTeam: boolean,
      rank: boolean,
      userMail: boolean,
      userPhone: boolean
    },
    fromPasswordChange: {
      currentPassword: boolean,
      newPassword: boolean,
      confirmNewPassword: boolean
    },
    formInfoDetails: {
      infoNm: boolean,
      infoMail: boolean,
      infoPhone: boolean
    },
    formAddInfo: {
      custdBizNo:boolean,
      custdDepartment: boolean
    }
  } = {
      formProfileDetails: {
        compNm: false,
        compMail: false,
        compPhone: false,
        compAddress: false
      },
      fromContractInformations: {
        userNm: false,
        depTeam: false,
        rank: false,
        userMail: false,
        userPhone: false
      },
      fromPasswordChange: {
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false
      },
      formInfoDetails: {
        infoNm: false,
        infoMail: false,
        infoPhone: false
      },
      formAddInfo: {
        custdBizNo:false,
        custdDepartment: false
      }
    }

  formDataChange: {
    formProfileDetails: boolean,
    fromContractInformations: boolean,
    fromPasswordChange: boolean,
    formInfoDetails: boolean,
    formAddInfo: boolean
  } = {
      formProfileDetails: false,
      fromContractInformations: false,
      fromPasswordChange: false,
      formInfoDetails: false,
      formAddInfo: false
    }

  codebook: {
    custType: any[],
    custScales: any[],
    custIndustry: any[],
    custArea: any[]
  } = {
      custType: [],
      custScales: [],
      custIndustry: [],
      custArea: []
    }

  sidebar: any = 'set';

  linkedId: string = '';
  userInfo;
  customer: any;
  company: any;

  formProfileDetails: FormGroup;
  fromContractInformations: FormGroup;
  fromPasswordChange: FormGroup;

  formInfoDetails: FormGroup;
  formAddInfo: FormGroup;

  pageData: {
    compNm: string,
  } = {
      compNm: '',
    }

  ngOnInit(): void {
    this.userService.getUserProfile().subscribe(res => this.userInfo = res);
    this.navbarService.setAction('profileInfo');
    if (this.linkedId == '000002') {
      this.initFromProfileDetails();
      this.initFromContractInformation();
      this.getUserImformationStaff();
      this.initFromPasswordChange();
    } else if (this.linkedId == '000001') {
      this.initFromImfoDetails();
      this.initFromAddInfo();
      this.getCodebookType('custType', 'CUST_TYPE');
      this.getCodebookType('custScales', 'CUST_SCALE');
      this.getCodebookType('custIndustry', 'CUST_INDUSTRY');
      this.getCodebookType('custArea', 'CUST_AREA');
      this.getUserImformationCustomer();
      this.initFromPasswordChange();
    }
  }

  ngDoCheck(): void {
    if (this.getUser && (this.loginService.getProfileLogin() != null)) {
      this.linkedId = this.loginService.getProfileLogin().linkedId;
      this.getUser = false;
      this.ngOnInit();
    }
  }

  ngAfterViewInit(): void {
    this.sidebarService.setManuActive(this.sidebar);
    this.sidebarService.setSubManuActive('information-manage');
    this.input.compNm = document.getElementById("compNm");
  }

  getCodebookType(type: any, codebook: string) {
    this.codeBookService.getCodeBook(codebook).subscribe((rs: any) => {
      if (rs.status) {
        this.codebook[type] = rs.data;
      }
    });
  }

  getUserImformationStaff() {
    this.loadingService.openLoading();
    this.userService.getUserImformationStaff(this.loginService.getProfileLogin().userIdNo).subscribe((rs: any) => {
      if (rs.status) {
        this.company = rs.data;
        if (rs.data.orgId != null) {
          this.ngAfterViewInit();
          this.pageData.compNm = rs.data.compNm;
          this.formProfileDetails.get('compNm').setValue(rs.data.compNm);
          this.input.compNm.setAttribute("disabled", true);
        } else {
          this.ngAfterViewInit();
          this.input.compNm.removeAttribute("disabled");
          this.input.compNm.focus();
        }
        this.formProfileDetails.get('compMail').setValue(rs.data.compMail);
        this.formProfileDetails.get('compPhone').setValue(rs.data.compPhone);
        this.formProfileDetails.get('compAddress').setValue(rs.data.compAddress);

        this.fromContractInformations.get('userNm').setValue(rs.data.userNm);
        this.fromContractInformations.get('depTeam').setValue(rs.data.depTeam);
        this.fromContractInformations.get('rank').setValue(rs.data.rank);
        this.fromContractInformations.get('userMail').setValue(rs.data.userMail);
        this.fromContractInformations.get('userPhone').setValue(rs.data.userPhone);

        console.log(this.fromContractInformations);

        this.formDataChange.formProfileDetails = false;
        this.formDataChange.fromContractInformations = false;
      }
      this.loadingService.closeLoading();
    });
  }

  getUserImformationCustomer() {
    this.loadingService.openLoading();
    this.userService.getUserImformationCustomer(this.loginService.getProfileLogin().userIdNo).subscribe((rs: any) => {
      if (rs.status) {
        this.customer = rs.data;

        this.formInfoDetails.get('infoNm').setValue(rs.data.infoNm);
        this.formInfoDetails.get('infoMail').setValue(rs.data.infoMail);
        this.formInfoDetails.get('infoPhone').setValue(rs.data.infoPhone);

        this.formAddInfo.get("userLinkedTypeCd").setValue(rs.data.userLinkedTypeCd);
        this.formAddInfo.get("custdBizNo").setValue(rs.data.custdBizNo);
        this.formAddInfo.get("custdScale").setValue(rs.data.custdScale);
        this.formAddInfo.get("custdIndustry").setValue(rs.data.custdIndustry);
        this.formAddInfo.get("custdDepartment").setValue(rs.data.custdDepartment);
        this.formAddInfo.get("custdArea").setValue(rs.data.custdArea);

        this.formDataChange.formInfoDetails = false;
        this.formDataChange.formAddInfo = false;
      }
      this.loadingService.closeLoading();
    });
  }

  initFromProfileDetails() {
    this.formProfileDetails = this.fb.group({
      compNm: ['', Regex.Required],
      compMail: ['', Regex.emailRequired],
      compPhone: ['', Regex.Required],
      compAddress: ['', Regex.Required]
    });

    this.formProfileDetails.valueChanges.subscribe(valueChanges => {
      console.log(this.formProfileDetails.valid)
      this.formDataChange.formProfileDetails = this.formProfileDetails.valid;
    })

    this.formProfileDetails.get('compNm').valueChanges.subscribe(valueChanges => {
      this.valid.formProfileDetails.compNm = true;
    });

    this.formProfileDetails.get('compMail').valueChanges.subscribe(valueChanges => {
      this.valid.formProfileDetails.compMail = true;
    });

    this.formProfileDetails.get('compPhone').valueChanges.subscribe(valueChanges => {
      this.valid.formProfileDetails.compPhone = true;
    });

    this.formProfileDetails.get('compAddress').valueChanges.subscribe(valueChanges => {
      this.valid.formProfileDetails.compAddress = true;
    });
  }

  initFromContractInformation() {
    this.fromContractInformations = this.fb.group({
      userNm: ['', Regex.Required],
      depTeam: ['', Regex.Required],
      rank: ['', Regex.Required],
      userMail: ['', Regex.emailRequired],
      userPhone: ['', Regex.Required]
    })

    this.fromContractInformations.valueChanges.subscribe(valueChanges => {
      console.log(this.fromContractInformations.valid);
      this.formDataChange.fromContractInformations = this.fromContractInformations.valid;
    });

    this.fromContractInformations.get('userNm').valueChanges.subscribe(valueChanges => {
      this.valid.fromContractInformations.userNm = true;
    });

    this.fromContractInformations.get('depTeam').valueChanges.subscribe(valueChanges => {
      this.valid.fromContractInformations.depTeam = true;
    });

    this.fromContractInformations.get('rank').valueChanges.subscribe(valueChanges => {
      this.valid.fromContractInformations.rank = true;
    });

    this.fromContractInformations.get('userMail').valueChanges.subscribe(valueChanges => {
      this.valid.fromContractInformations.userMail = true;
    });

    this.fromContractInformations.get('userPhone').valueChanges.subscribe(valueChanges => {
      this.valid.fromContractInformations.userPhone = true;
    });
  }

  initFromImfoDetails() {
    this.formInfoDetails = this.fb.group({
      infoNm: ['', Regex.Required],
      infoMail: ['', Regex.Required],
      infoPhone: ['', Regex.Required]
    })

    this.formInfoDetails.valueChanges.subscribe(valueChanges => {
      this.formDataChange.formInfoDetails = this.formInfoDetails.valid;
    });

    this.formInfoDetails.get('infoNm').valueChanges.subscribe(valueChanges => {
      this.valid.formInfoDetails.infoNm = true;
    });

    this.formInfoDetails.get('infoMail').valueChanges.subscribe(valueChanges => {
      this.valid.formInfoDetails.infoMail = true;
    });

    this.formInfoDetails.get('infoPhone').valueChanges.subscribe(valueChanges => {
      this.valid.formInfoDetails.infoPhone = true;
    });
  }

  initFromAddInfo() {
    this.formAddInfo = this.fb.group({
      userLinkedTypeCd: [""],
      custdBizNo: [""],
      custdScale: [""],
      custdIndustry: [""],
      custdDepartment: [""],
      custdArea: [""]
    });

    this.formAddInfo.valueChanges.subscribe(valueChanges => {
      this.formDataChange.formAddInfo = this.formAddInfo.valid;
    })

    this.formAddInfo.get('custdBizNo').valueChanges.subscribe(valueChanges => {
      this.valid.formAddInfo.custdBizNo = true;
    });    
    
    this.formAddInfo.get('custdDepartment').valueChanges.subscribe(valueChanges => {
      this.valid.formAddInfo.custdDepartment = true;
    });
  }

  initFromPasswordChange() {
    this.fromPasswordChange = this.fb.group({
      currentPassword: ['', Regex.Required],
      newPassword: ['', Regex.Required],
      confirmNewPassword: ['', Regex.Required]
    });

    this.fromPasswordChange.valueChanges.subscribe(valueChanges => {
      console.log(this.fromPasswordChange.valid)
      this.formDataChange.fromPasswordChange = this.fromPasswordChange.valid;
    });

    this.fromPasswordChange.get('currentPassword').valueChanges.subscribe(valueChanges => {
      this.valid.fromPasswordChange.currentPassword = true;
    });

    this.fromPasswordChange.get('newPassword').valueChanges.subscribe(valueChanges => {
      this.valid.fromPasswordChange.newPassword = true;
    });

    this.fromPasswordChange.get('confirmNewPassword').valueChanges.subscribe(valueChanges => {
      this.valid.fromPasswordChange.confirmNewPassword = true;
    });
  }

  onSubmitCustomer() {
    if (this.fromPasswordChange.valid && this.formDataChange.fromPasswordChange) {
      this.updatePassword();
    }
    if (this.formInfoDetails.valid && this.formDataChange.formInfoDetails) {
      this.updateImforDetailsCustomer();
    }
    if (this.formAddInfo.valid && this.formDataChange.formAddInfo) {
      this.updateAdditionalImfomationCustomer();
    }
  }

  updateImforDetailsCustomer() {
    let data = {
      infoNm: this.formInfoDetails.get("infoNm").value,
      infoMail: this.formInfoDetails.get("infoMail").value,
      infoPhone: this.formInfoDetails.get("infoPhone").value
    }

    this.loadingService.openLoading();
    this.userService.updateImforDetailsCustomer(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.data,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.formInfoDetails = false;
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.message,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.formInfoDetails = false;
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      }
    });
  }

  updateAdditionalImfomationCustomer() {
    let data = {
      custdIndustry: this.formAddInfo.get("custdIndustry").value,
      custdArea: this.formAddInfo.get("custdArea").value,
      custdScale: this.formAddInfo.get("custdScale").value,
      custdDepartment: this.formAddInfo.get("custdDepartment").value,
      userLinkedTypeCd: this.formAddInfo.get("userLinkedTypeCd").value,
      custdBizNo: this.formAddInfo.get("custdBizNo").value,
      custId: this.customer.custId
    }

    this.loadingService.openLoading();
    this.userService.updateAdditionalImfomationCustomer(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.data,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.fromContractInformations = false;
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.data,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.fromContractInformations = false;
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      }
    });
  }

  updatePassword() {
    let data = {
      currentPassword: this.fromPasswordChange.get("currentPassword").value,
      newPassword: this.fromPasswordChange.get("newPassword").value,
      confirmNewPassword: this.fromPasswordChange.get("confirmNewPassword").value
    }

    this.loadingService.openLoading();
    this.userService.updatePassword(data).subscribe((rs: any) => {

      this.loadingService.closeLoading();
      if (rs.status) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.data,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.fromPasswordChange = false;
                this.initFromPasswordChange();
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.message,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.fromPasswordChange = false;
                this.initFromPasswordChange();
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      }
    });

  }

  onSubmit() {
    if (this.fromPasswordChange.valid && this.formDataChange.fromPasswordChange) {
      this.updatePassword();
    }
    console.log(this.fromContractInformations.valid, this.formDataChange.fromContractInformations, this.company, "fromContractInformations");
    if (this.fromContractInformations.valid && this.formDataChange.fromContractInformations) {
      this.updateAdditionalImfomationStaff();
    }
    console.log(this.formProfileDetails.valid, this.formDataChange.formProfileDetails, this.company, "formProfileDetails");
    if (this.formProfileDetails.valid && this.formDataChange.formProfileDetails) {
      this.updateProfileDetailsStaff();
    }
  }

  updateAdditionalImfomationStaff() {
    let data = {
      infoRank: this.fromContractInformations.get("rank").value,
      infoDepTeam: this.fromContractInformations.get("depTeam").value,
      infoNm: this.fromContractInformations.get("userNm").value,
      infoMail: this.fromContractInformations.get("userMail").value,
      infoPhone: this.fromContractInformations.get("userPhone").value
    }

    this.loadingService.openLoading();
    this.userService.updateImforDetailsCustomer(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.data,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.fromContractInformations = false;
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.message,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.fromContractInformations = false;
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      }
    });
  }

  updateProfileDetailsStaff() {
    let data = {
      orgId: this.company.orgId,
      compNm: this.formProfileDetails.get('compNm').value,
      compMail: this.formProfileDetails.get("compMail").value,
      compPhone: this.formProfileDetails.get("compPhone").value,
      compAddress: this.formProfileDetails.get("compAddress").value,
      staffId: this.company.staffId
    }

    this.loadingService.openLoading();
    this.userService.updateProfileDetailsStaff(data).subscribe((rs: any) => {
      this.loadingService.closeLoading();
      if (rs.status) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.data,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.formProfileDetails = false;
                this.getUserImformationStaff();
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: rs.message,
            subTitle: ""
          },
          [
            {
              btnFunc: () => {
                this.formDataChange.formProfileDetails = false;
                this.getUserImformationStaff();
              },
              btnNm: "확인",
              btnStyle: 'btn-black',
              btnType: "confirmed"
            },
          ]
        );
      }
    });
  }

  openModal() {
    this.showModal = true;
    console.log('유저종보', this.userInfo.data.userIdNo);
  }

  closeModal() {
    this.showModal = false;
  }

  withdrawal() {
    this.closeModal();
    this.http.delete<any>(API_URL.withdrawal+this.userInfo.data.userIdNo).subscribe(res => {
      if(res.status) {
        this.authService.logout();
        this.router.navigate(['/']);
      }
    });
  }
}
