import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { NavbarService } from '../navbar/navbar.service';
declare var $: any;
@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {

  constructor(
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.navbarService.setNavbarTitle('');
  }

}
