<div class="bgroundcolor" id="SVapp-home">
	<app-navbar></app-navbar>
	<section class="container-fluid bannerarea " id="herder">
		<div class="container-fluid  imagebannerpicture">
			<div class="container-md p-0">
				<div class="row align-items-start bannerimage serviceBalance mx-auto">
					<div class="col-12 align-self-center"> <!-- P-0 클래스 삭제 -->
						<h1>이용방법</h1>
					</div>
				</div>
			</div>
		</div>
		
		<div class="d-flex btnnav justify-content-center signinBtns">
			<button id="firstBtn" [ngClass]="{'btn': true, 'rdbutton': curTab === 'first', 'whbutton': curTab !== 'first'}" (click)="changeTab('first')">문서 업로드해서 만들기</button>		
			<button style="margin: 0px 10px;" [ngClass]="{'btn': true, 'rdbutton': curTab === 'second', 'whbutton': curTab !== 'second'}" (click)="changeTab('second')" >제공된 서식으로 만들기</button>		
			<button [ngClass]="{'btn': true, 'rdbutton': curTab === 'third', 'whbutton': curTab !== 'third'}" (click)="changeTab('third')">계약서 인증</button>
		</div>
       
	</section>

	<!-- 문서 업로드해서 만들기 [s] -->
	<section class="container-fluid " id="body" *ngIf="curTab === 'first'">
		<div class="row-hl justify-content-center d-flex">
			<div class="how-to-use">
				<h2 class="text-center centerSubject">문서 업로드해서 만들기</h2>
		
				<div>
					<img src="../../../assets/img/frontpage/help_01.png" class="img-help" alt="전자계약서 만들기 버튼">
					<p>로그인 후, 좌측 상단의 [전자계약기 만들기] 버튼을 클릭하여, 원하는 계약서를 선택합니다. </p>
					<dd><span class=" red underline">* 원활한 사이트 이용을 위해 크롬 Chrome 사용을 권장합니다.</span></dd>
				</div>
		
				<h4>1. 문서업로드해서 만들기 선택하기 </h4>
				<div>
					<img src="../../../assets/img/frontpage/help_01_1.png" class="img-help" alt="문서 업로드해서 만들기 버튼" />
					<p>[문서 업로드해서 만들기]를 클릭합니다.</p>
				</div>
		
		
				<h4>2. 업로드</h4>
				<div>
					<img src="../../../assets/img/frontpage/help_02.png" class="img-help" alt="문서업로드 파일찾기 버튼">
					<p>[파일찾기] 버튼을 클릭하여 파일을 불러오거나 저장되어 있는 <span class="underline">파일을 드래그하여 폼 UI 화면에 놓아도 가능</span>합니다.</p>
					<p> '폼'을 만들 수 있는 지원 파일은 PDF,JPG,JPEG,PNG입니다.</p>
					<p>기존에 사용하던 문서 파일이 위의 형식이 아니라면, 이미지 파일로 변환을 해야 합니다.</p>
				</div>
		
				<h4>3. 작성하기</h4>
				<div>
					<p>사인/도장, 텍스트, 체크박스를 문서 내 원하는 위치에 드래그 해서 원하는 위치에 옳깁니다. 그리고 내용을 입력합니다. </p>
					<p>모든 설정을 완료했으면, [다음] 버튼을 클릭합니다.
					</p>
					<img src="../../../assets/img/frontpage/help_03.png" class="img-help" alt="전자계약서작성 툴 이미지">
		
					<span class="help-num">1)</span>
					<p class="txt">좌측에 있는 메뉴 바에서 희망하는 필드를 <드래그>해서 원하는 위치에 옮깁니다.</p>
					<ul class="subtxt">
						<li>(1) 텍스트 : 원하는 크기와 컬러, 글씨체의 텍스트를 바로 추가할 수 있습니다.</li>
						<li>(2) 체크박스 : 문서 내 원하는 위치에 체크 표시를 삽입할 수 있습니다.</li>
						<li>(3) 숫자: 원하는 크기와 컬러, 글씨체의 숫자를 바로 추가할 수 있습니다.</li>
						<li>(4) 날짜 : 날짜를 선택해 입력할 수 있습니다. </li>
						<li>(5) 서명 : 서명 그리기에 입력하여 추가 또는 이미지 업로드를 하여 바로 추가할 수 있습니다.</li>
						<li>(6) 첨부파일 : 첨부파일 드래그 하여 원하는 위치에 두고 [파일찾기] 텍스트를 클릭하여 파일을 불러 옵니다.</li>
					</ul>
		
					<span class="help-num">2)</span>
					<p class="txt">필드의 모서리에 있는 네모를 클릭하여, 사이즈를 알맞게 수정합니다.</p>
					<span class="help-num">3)</span>
					<p class="txt">필드에 내용을 입력합니다.</p>
					<span class="help-num">4)</span>
					<p class="txt">사인 필드에는 우측 메뉴에서 <작성자 지정>을 반드시 설정합니다.</p>
		
					<h5>작성자 지정</h5>
					<img src="../../../assets/img/frontpage/help_04.png" class="img-help" alt="전자계약서작성 우측 작성지지정 이미지">
					<p>사인 위치에 지정하고 우측 메뉴 바에서 <작성자 지정> 부분의 리스트 박스를 클릭하여, 작성자를 지정합니다. <br /> 수신자(작성자)에게 받으실 서명/ 도장의 위치를 지정 후, 각
							필드마다 작성자를 지정할 수 있습니다. </p>
					<ul class="subtxt blue">
						<li>* SD : 수신자에게 서명 및 도장을 요청하지 않고 보낸이의 서명 / 도장을 넣어 보낼 경우 설정</li>
						<li>* SR : 수신자에게 서명 및 도장을 받아야 할 경우 설정</li>
					</ul>
				</div>
		
				<h4>4. 수신자 선택 및 전송 (개인)</h4>
				<div>
					<p>전송 화면에서 수신자 정보를 입력합니다. 내용을 입력하고 [전송]버튼을 클릭하여 계약서를 전송합니다.</p>
					<img src="../../../assets/img/frontpage/help_05.png" class="img-help mobileCardImg"
						alt="수신자 선택 및 전송 이미지-개인">
					<dl class="subtxt-circle">
						<dt><span class="help-num-circle">1</span>추가하기 버튼</dt>
						<dd>추가 전송하실 분은 [+추가하기] 버튼을 클릭하고 계약대상자의 정보를 입력합니다.</dd>
		
						<dt><span class="help-num-circle">2</span>엑셀 대량전송 버튼</dt>
						<dd>근로 계약과 같이 똑같은 폼을 여러 명에게 보내야 할 때나, 폼은 같지만 수신자마다 폼의 내용이 다른 경우에는 대량 발송을 이용할 수 있습니다.</dd>
						<dd>수신자 리스트.xlsx 다운받은 엑셀에 폼의 내용을 입력하여 리스트를 작성합니다.</dd>
						<dd>1) 폼을 전송할 때 [엑셀 대량전송]을 클릭합니다.</dd>
						<dd>2) [수신자 리스트.xlsx]를 클릭한 뒤 해당 엑셀 포맷에 수신자 정보를 입력합니다.</dd>
						<dd>3) 파일을 추가한 뒤 수신자가 제대로 추가되었는지 확인하고 저장합니다.</dd>
						<dd>4) 파일 업로드를 클릭하여 저장된 문서를 업로드 합니다.</dd>
						<dd><span class=" red underline">* 지콘트에서 제공하는 엑셀 포맷을 수정하지 말고 그대로 사용하세요.</span></dd>
		
						<dt><span class="help-num-circle">3</span>설정</dt>
						<dd>계약서의 인증 방법을 체크합니다. (선택사항)입니다.</dd>
						<dd>1) [비밀번호 인증] 선택 시 버튼을 클릭하고 비밀번호를 설정합니다. </dd>
						<dd>2) [휴대폰 본인인증] 선택 시 버튼을 클릭하고 설정합니다. <br />휴대전화가 입력된 수신자에게만 적용됩니다. 계약 대상자가 메일로 문서를 열때 메일에서 휴대폰 본인인증을
							합니다.</dd>
		
						<dt><span class="help-num-circle">4</span>전송 버튼</dt>
						<dd>전송된 계약서는 <span class="underline">통합계약관리 메뉴</span>에서 확인 하실 수 있습니다.</dd>
		
						<dt><span class="help-num-circle">5</span>취소 버튼</dt>
						<dd>상단의 [취소] 버튼 클릭시 저장된 계약서를 임시저장 하실 수 있습니다. 임시저장 된 계약서는 <span class="underline">계약서 관리 메뉴</span>에서 확인
							하실 수 있습니다.</dd>
					</dl>
				</div>
		
				<h4>5. 수신자 선택 및 전송 (기업)</h4>
				<div>
					<p>[직접입력] 버튼을 클릭하여 계약 대상자의 정보를 입력해 줍니다.<br />또는 좌측에 내가 만든 조직의 팀과 팀원을 선택 후 > 아이콘을 클릭하여 계약대상자를 추가합니다.</p>
					<img src="../../../assets/img/frontpage/help_06.png" class="img-help mobileCardImg"
						alt="수신자 선택 및 전송 이미지-기업">
		
					<dl class="subtxt-circle">
						<dt><span class="help-num-circle">1</span>직접입력 버튼</dt>
						<dd>[직접입력] 버튼을 클릭하여 계약 대상자의 정보를 입력해 줍니다.</dd>
		
						<dt><span class="help-num-circle">2</span>선택된 팀원 보기 버튼</dt>
						<dd>[선택된 팀원 보기] 버튼은 조직을 설정하신 ‘팀’을 클릭 후 [선택된 팀원 보기] 버튼을 클릭하여 팀원의 체크박스를 클릭하고 <br />우측의 계약 대상자의 체크박스를
							클릭 후 이동 및 추가합니다.</dd>
		
						<dt><span class="help-num-circle">3</span>조직원 전체 선택 버튼</dt>
						<dd> [조직원 전체 선택]버튼은 조직원들을 모두 선택 후 가운데 ">" 아이콘을 이동하여 계약작성자를 이동 및 추가합니다.</dd>
						<dd>
							<div class="impact">* 조직관리 설정은 어떻게 하나요?</div>
							<div>- 메뉴 마이페이지 > 조직 관리에서 조직을 추가 합니다.</div>
						</dd>
		
						<dd>
							<div class="subtxt-circle-s-box">
								<h5 class="margin-b20">조직관리</h5>
								<img src="../../../assets/img/frontpage/help_07.png" class="img-help mobileCardImg"
									alt="조직관리 이미지">
								<dl>
									<dt><span class="subtxt-circle-s">1</span>하위 부서/팀 관리 버튼</dt>
									<dd>좌측 트리구조에서 맨 상단 회사명을 클릭하고 > [하위 부서/팀 관리]버튼을 클릭합니다. 팝업이 뜨면 팀을 입력 및 추가합니다. </dd>
		
									<dt><span class="subtxt-circle-s">2</span>조직 import 버튼</dt>
									<dd>한명씩 추가하지 않고, 한번에 여러명의 조직을 불러와 입력할 수 있습니다.</dd>
									<dd>1) [조직리스트.xlsx 다운로드] 버튼을 눌러 엑셀파일을 다운받습니다.</dd>
									<dd>2) 다운 받은 [조직리스트.xlsx 다운로드] 엑셀 포맷에 맞게 수신자 정보를 입력합니다.</dd>
									<dd>3) 파일을 추가한 뒤 수신자가 제대로 추가되었는지 확인하고 저장합니다.</dd>
									<dd>4) [파일 업로드] 버튼을 클릭하여 저장된 문서를 업로드 합니다.
										<span class="subtxt-red underline">* 지콘트에서 제공하는 엑셀 포맷을 수정하지 말고 그대로 사용하세요.</span>
									</dd>
								</dl>
							</div>
						</dd>
		
						<dt><span class="help-num-circle">4</span>엑셀 대량전송 버튼</dt>
						<dd> 근로 계약과 같이 똑같은 폼을 여러 명에게 보내야 할 때나, 폼은 같지만 수신자마다 폼의 내용이 다른 경우에는 대량 발송을 이용할 수 있습니다.</dd>
						<dd>수신자 리스트.xlsx 다운받은 엑셀에 폼의 내용을 입력하여 리스트를 작성합니다.</dd>
						<dd>1) 폼을 전송할 때 [엑셀 대량전송]을 클릭합니다.</dd>
						<dd>2) [수신자 리스트.xlsx]를 클릭한 뒤 해당 엑셀 포맷에 수신자 정보를 입력합니다.</dd>
						<dd>3) 파일을 추가한 뒤 수신자가 제대로 추가되었는지 확인하고 저장합니다.</dd>
						<dd>4) 파일 업로드를 클릭하여 저장된 문서를 업로드 합니다. </dd>
						<dd><span class="underline">* 지콘트에서 제공하는 엑셀 포맷을 수정하지 말고 그대로 사용하세요.</span></dd>
		
						<dt><span class="help-num-circle">5</span>설정</dt>
						<dd> 계약서의 인증 방법을 체크합니다. (선택사항)입니다.</dd>
						<dd>1) [비밀번호 인증] 선택 시 버튼을 클릭하고 비밀번호를 설정합니다.</dd>
						<dd>2) [휴대폰 본인인증] 선택 시 버튼을 클릭하고 설정합니다.</dd>
						<dd>휴대전화가 입력된 수신자에게만 적용됩니다. 계약 대상자가 메일로 문서를 열때 메일에서 휴대폰 본인인증을 합니다.</dd>
		
						<dt><span class="help-num-circle">6</span>전송 버튼</dt>
						<dd>전송된 계약서는 <span class="underline">통합계약관리 메뉴</span>에서 확인 하실 수 있습니다.</dd>
		
						<dt><span class="help-num-circle">7</span>취소 버튼</dt>
						<dd> 상단의 [취소] 버튼 클릭시 저장된 계약서를 임시저장 하실 수 있습니다. 임시저장 된 계약서는 <span class="underline">계약서 관리 메뉴</span>에서
							확인 하실 수 있습니다.</dd>
					</dl>
				</div>
			</div>
		</div>
	</section>
	<!-- 문서 업로드해서 만들기 [e] -->

	<!-- 제공된 서식으로 만들기 [s] -->
	<section class="container-fluid " id="body" *ngIf="curTab === 'second'">
		<div class="row-hl justify-content-center d-flex">
			<div class="how-to-use">
				<h2 class="text-center centerSubject">제공된 서식으로 만들기</h2>
		
				<div>
					<img src="../../../assets/img/frontpage/help_01.png" class="img-help" alt="전자계약서 만들기 버튼">
					<p>로그인 후, 좌측 상단의 [전자계약기 만들기] 버튼을 클릭하여, 원하는 계약서를 선택합니다. </p>
					<dd><span class=" red underline">* 원활한 사이트 이용을 위해 크롬 Chrome 사용을 권장합니다.</span></dd>
				</div>
		
				<h4>1. 제공된 서식으로 만들기 선택</h4>
				<div>
					<img src="../../../assets/img/frontpage/help2_01.png" class="img-help" alt="전자계약기 만들기 버튼" />
					<p>[제공된 서식으로 만들기]를 클릭합니다. </p>
				</div>
		
		
				<h4>2. 계약서 종류 선택</h4>
				<div>
					<img src="../../../assets/img/frontpage/help2_02.png" class="img-help" alt="계약서 종류 선택">
					<p>[원하시는 계약서를 선택하고 [다음]버튼을 클릭합니다. </p>
				</div>
		
				<h4>3. 계약서 정보 입력</h4>
				<div>
					<img src="../../../assets/img/frontpage/help2_03.png" class="img-help" alt="계약서 정보 입력 취소 및 다음 버튼 위치 안내">
					<p>계약서의 정보를 모두 입력하고 [다음] 버튼을 클릭합니다. </p>
					<dl class="subtxt-circle">
						<dt><span class="help-num-circle">1</span></dt>
						<dd>상단의 [취소] 버튼 클릭시 저장된 계약서를 임시저장 하실 수 있습니다. 임시저장 된 계약서는 <span class="underline">계약서 관리 메뉴</span>에서 확인 하실 수 있습니다.</dd>
					</dl>
				</div>

				<h4>4.  수신자 지정</h4>
				<div>
					<img src="../../../assets/img/frontpage/help2_04.png" class="img-help" alt="계약서 보기 화면에서 작성자 지정 - 수신자 지정 선택 및 SD / SR 위치">

					<p>입력한 계약서를 확인 후, 계약서의 서명/ 도장 위치에 작성자 지정을 설정 후 [다음] 버튼을 클릭합니다. </p>
					<ul class="subtxt blue">
						<li>* SD : 수신자에게 서명 및 도장을 요청하지 않고 보낸이의 서명 / 도장을 넣어 보낼 경우 설정</li>
						<li>* SR : 수신자에게 서명 및 도장을 받아야 할 경우 설정</li>
					</ul>
					<p>우측 메뉴 바에서 <작성자 지정> 부분의 리스트 박스를 클릭하여, 작성자를 지정합니다. <br/>	각 서명 / 도장의 필드마다 작성자를 지정할 수 있습니다.</p>
				</div>
		
				<h4>5. 수신자 선택 및 전송 (개인)</h4>
				<div>
					<p>전송 화면에서 수신자 정보를 입력합니다. 내용을 입력하고 [전송]버튼을 클릭하여 계약서를 전송합니다.</p>
					<img src="../../../assets/img/frontpage/help_05.png" class="img-help mobileCardImg"
						alt="수신자 선택 및 전송 이미지-개인">
					<dl class="subtxt-circle">
						<dt><span class="help-num-circle">1</span>추가하기 버튼</dt>
						<dd>추가 전송하실 분은 [+추가하기] 버튼을 클릭하고 계약대상자의 정보를 입력합니다.</dd>
		
						<dt><span class="help-num-circle">2</span>엑셀 대량전송 버튼</dt>
						<dd>근로 계약과 같이 똑같은 폼을 여러 명에게 보내야 할 때나, 폼은 같지만 수신자마다 폼의 내용이 다른 경우에는 대량 발송을 이용할 수 있습니다.</dd>
						<dd>수신자 리스트.xlsx 다운받은 엑셀에 폼의 내용을 입력하여 리스트를 작성합니다.</dd>
						<dd>1) 폼을 전송할 때 [엑셀 대량전송]을 클릭합니다.</dd>
						<dd>2) [수신자 리스트.xlsx]를 클릭한 뒤 해당 엑셀 포맷에 수신자 정보를 입력합니다.</dd>
						<dd>3) 파일을 추가한 뒤 수신자가 제대로 추가되었는지 확인하고 저장합니다.</dd>
						<dd>4) 파일 업로드를 클릭하여 저장된 문서를 업로드 합니다.</dd>
						<dd><span class=" red underline">* 지콘트에서 제공하는 엑셀 포맷을 수정하지 말고 그대로 사용하세요.</span></dd>
		
						<dt><span class="help-num-circle">3</span>설정</dt>
						<dd>계약서의 인증 방법을 체크합니다. (선택사항)입니다.</dd>
						<dd>1) [비밀번호 인증] 선택 시 버튼을 클릭하고 비밀번호를 설정합니다. </dd>
						<dd>2) [휴대폰 본인인증] 선택 시 버튼을 클릭하고 설정합니다. <br />휴대전화가 입력된 수신자에게만 적용됩니다. 계약 대상자가 메일로 문서를 열때 메일에서 휴대폰 본인인증을
							합니다.</dd>
		
						<dt><span class="help-num-circle">4</span>전송 버튼</dt>
						<dd>전송된 계약서는 <span class="underline">통합계약관리 메뉴</span>에서 확인 하실 수 있습니다.</dd>
		
						<dt><span class="help-num-circle">5</span>취소 버튼</dt>
						<dd>상단의 [취소] 버튼 클릭시 저장된 계약서를 임시저장 하실 수 있습니다. 임시저장 된 계약서는 <span class="underline">계약서 관리 메뉴</span>에서 확인
							하실 수 있습니다.</dd>
					</dl>
				</div>
		
				<h4>6. 수신자 선택 및 전송 (기업)</h4>
				<div>
					<p>[직접입력] 버튼을 클릭하여 계약 대상자의 정보를 입력해 줍니다.<br />또는 좌측에 내가 만든 조직의 팀과 팀원을 선택 후 > 아이콘을 클릭하여 계약대상자를 추가합니다.</p>
					<img src="../../../assets/img/frontpage/help_06.png" class="img-help mobileCardImg"
						alt="수신자 선택 및 전송 이미지-기업">
		
					<dl class="subtxt-circle">
						<dt><span class="help-num-circle">1</span>직접입력 버튼</dt>
						<dd>[직접입력] 버튼을 클릭하여 계약 대상자의 정보를 입력해 줍니다.</dd>
		
						<dt><span class="help-num-circle">2</span>선택된 팀원 보기 버튼</dt>
						<dd>[선택된 팀원 보기] 버튼은 조직을 설정하신 ‘팀’을 클릭 후 [선택된 팀원 보기] 버튼을 클릭하여 팀원의 체크박스를 클릭하고 <br />우측의 계약 대상자의 체크박스를
							클릭 후 이동 및 추가합니다.</dd>
		
						<dt><span class="help-num-circle">3</span>조직원 전체 선택 버튼</dt>
						<dd> [조직원 전체 선택]버튼은 조직원들을 모두 선택 후 가운데 ">" 아이콘을 이동하여 계약작성자를 이동 및 추가합니다.</dd>
						<dd>
							<div class="impact">* 조직관리 설정은 어떻게 하나요?</div>
							<div>- 메뉴 마이페이지 > 조직 관리에서 조직을 추가 합니다.</div>
						</dd>
		
						<dd>
							<div class="subtxt-circle-s-box">
								<h5 class="margin-b20">조직관리</h5>
								<img src="../../../assets/img/frontpage/help_07.png" class="img-help mobileCardImg"
									alt="조직관리 이미지">
								<dl>
									<dt><span class="subtxt-circle-s">1</span>하위 부서/팀 관리 버튼</dt>
									<dd>좌측 트리구조에서 맨 상단 회사명을 클릭하고 > [하위 부서/팀 관리]버튼을 클릭합니다. 팝업이 뜨면 팀을 입력 및 추가합니다. </dd>
		
									<dt><span class="subtxt-circle-s">2</span>조직 import 버튼</dt>
									<dd>한명씩 추가하지 않고, 한번에 여러명의 조직을 불러와 입력할 수 있습니다.</dd>
									<dd>1) [조직리스트.xlsx 다운로드] 버튼을 눌러 엑셀파일을 다운받습니다.</dd>
									<dd>2) 다운 받은 [조직리스트.xlsx 다운로드] 엑셀 포맷에 맞게 수신자 정보를 입력합니다.</dd>
									<dd>3) 파일을 추가한 뒤 수신자가 제대로 추가되었는지 확인하고 저장합니다.</dd>
									<dd>4) [파일 업로드] 버튼을 클릭하여 저장된 문서를 업로드 합니다.
										<span class="subtxt-red underline">* 지콘트에서 제공하는 엑셀 포맷을 수정하지 말고 그대로 사용하세요.</span>
									</dd>
								</dl>
							</div>
						</dd>
		
						<dt><span class="help-num-circle">4</span>엑셀 대량전송 버튼</dt>
						<dd> 근로 계약과 같이 똑같은 폼을 여러 명에게 보내야 할 때나, 폼은 같지만 수신자마다 폼의 내용이 다른 경우에는 대량 발송을 이용할 수 있습니다.</dd>
						<dd>수신자 리스트.xlsx 다운받은 엑셀에 폼의 내용을 입력하여 리스트를 작성합니다.</dd>
						<dd>1) 폼을 전송할 때 [엑셀 대량전송]을 클릭합니다.</dd>
						<dd>2) [수신자 리스트.xlsx]를 클릭한 뒤 해당 엑셀 포맷에 수신자 정보를 입력합니다.</dd>
						<dd>3) 파일을 추가한 뒤 수신자가 제대로 추가되었는지 확인하고 저장합니다.</dd>
						<dd>4) 파일 업로드를 클릭하여 저장된 문서를 업로드 합니다. </dd>
						<dd><span class="underline">* 지콘트에서 제공하는 엑셀 포맷을 수정하지 말고 그대로 사용하세요.</span></dd>
		
						<dt><span class="help-num-circle">5</span>설정</dt>
						<dd> 계약서의 인증 방법을 체크합니다. (선택사항)입니다.</dd>
						<dd>1) [비밀번호 인증] 선택 시 버튼을 클릭하고 비밀번호를 설정합니다.</dd>
						<dd>2) [휴대폰 본인인증] 선택 시 버튼을 클릭하고 설정합니다.</dd>
						<dd>휴대전화가 입력된 수신자에게만 적용됩니다. 계약 대상자가 메일로 문서를 열때 메일에서 휴대폰 본인인증을 합니다.</dd>
		
						<dt><span class="help-num-circle">6</span>전송 버튼</dt>
						<dd>전송된 계약서는 <span class="underline">통합계약관리 메뉴</span>에서 확인 하실 수 있습니다.</dd>
		
						<dt><span class="help-num-circle">7</span>취소 버튼</dt>
						<dd> 상단의 [취소] 버튼 클릭시 저장된 계약서를 임시저장 하실 수 있습니다. 임시저장 된 계약서는 <span class="underline">계약서 관리 메뉴</span>에서
							확인 하실 수 있습니다.</dd>
					</dl>
				</div>
			</div>
		</div>
	</section>
	<!-- 제공된 서식으로 만들기 [e] -->	

	<!-- 계약서 인증 [s] -->
	<section class="container-fluid " id="body"  *ngIf="curTab === 'third'">
		<div class="row-hl justify-content-center d-flex">
			<div class="how-to-use">
				<h2 class="text-center centerSubject">계약서 인증</h2>
		
				<div>
					<img src="../../../assets/img/frontpage/help_01.png" class="img-help" alt="전자계약서 만들기 버튼">
					<p>로그인 후, 좌측 상단의 [전자계약기 만들기] 버튼을 클릭하여, 원하는 계약서를 선택합니다. </p>
					<dd><span class=" red underline">* 원활한 사이트 이용을 위해 크롬 Chrome 사용을 권장합니다.</span></dd>
				</div>
		
				<h4>1. 계약서 인증 선택</h4>
				<div>
					<img src="../../../assets/img/frontpage/help3_01.png" class="img-help" alt="계약서 인증 버튼" />
					<p>[계약서 인증]을 클릭합니다. </p>
				</div>
		
		
				<h4>2. 업로드</h4>
				<div>
					<img src="../../../assets/img/frontpage/help3_02.png" class="img-help" alt="문서업로드 파일찾기 버튼">
					<p>[파일찾기] 버튼을 클릭하여 파일을 불러오거나 저장되어 있는 <span class="underline">파일을 드래그하여 폼 UI 화면에 놓아도 가능</span>합니다.</p>
					<p> '폼'을 만들 수 있는 지원 파일은 PDF,JPG,JPEG,PNG입니다.</p>
					<p>기존에 사용하던 문서 파일이 위의 형식이 아니라면, 이미지 파일로 변환을 해야 합니다.</p>
				</div>
		 
				<h4>3. 블록체인 인증 할 계약서 확인 (미리보기)</h4>
				<div>          
					<img src="../../../assets/img/frontpage/help3_03.png" class="img-help" alt="블록체인 인증 할 계약서 미리보기 및 아래 제출 버튼 위치">
					<p>업로드 하신, 블록체인 인증 할 계약서를 확인하고 [제출] 버튼을 클릭합니다. </p>
				</div>
		
				<h4>4. 전자계약 인증 완료</h4>
				<div>
					<img src="../../../assets/img/frontpage/help3_04.png" class="img-help mobileCardImg"
						alt="전자계약 인증 완료">
					<p>최종 인증 체결은 시스템 특성상 일정시간(최대 30분)이 소요됩니다. </p>
					<p>[확인] 버튼 클릭 후, <span class="underline">[통합계약관리 > 계약서 인증 관리]에서 최종 결과를 확인</span> 할 수 있습니다.</p>
				</div>
		
			</div>
		</div>
	</section>
	<!-- 계약서 인증 [e] -->	

</div>


<div class="serviceFooter">
	<app-footer></app-footer>
</div>