<div style="position: sticky;" class="d-flex justify-content-between align-items-center py-2 borderlinebot ">

    <div class="d-flex justify-content-center px-2">
      <span class="">
  
      </span>
      <span class="">
  
      </span>
      <span class="">
  
      </span>
    </div>
  
    <div class="d-flex justify-content-center px-2">
  
    </div>
  
    <div class="d-flex justify-content-center px-2">
      <button (click)="goToMainPage()" class="btn btn-small-long btn-white">
        취소
      </button>
    </div>
  
  </div>
  
  
  
  <section class="container-fluid" style="background: #E9ECEE;">
  
    <div class="container-lg2 py-5">
  
  
      <div class="row">
        <div class="col-12">
          <h1 class="tit-pag-up">
            <span>제공된 서식으로 만들기</span>
            <img src="../../../../assets/img/icon/icon-arrow-right-cr.png" class="img-fluid mx-3">
            <span class="font-weight-700 barumBold">아르바이트 근로계약서 </span>
          </h1>
        </div>
      </div>
      <hr>
  
      <form [formGroup]=formContractWord id="formContractWord">
        <div class="row">
          <div class="col-12">
            <hr style="margin:30px 0px 15px 0px; border: 2px solid #f82644; width: 25px; background: #f82644;">
            <h3 class="formHeading">
              계약서 정보 입력</h3>
          </div>
        </div>
  
        <!-- -******- -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="employer">고용주 이름</label>
              <input type="text" pattern="^-?(\D+)?$" class="form-control  {{valid.employer}}" formControlName='{employer}' maxlength="20"
                id="employer" name="employer" placeholder="고용주 이름" id="employer" aria-describedby="employer">
                <small *ngIf="small.employer" class="form-text text-muted text-validators {{formContractWord.get('{employer}').status}} font-16">
                    <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                        alt="alert icon">
                        문자만 입력해 주세요.
                </small>
            </div>
          </div>
        </div>
  
        <!-- -******- -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="student">아르바이트생 이름
              </label>
              <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.student}}" formControlName='{student}' maxlength="20"
                id="student" name="student" placeholder="아르바이트생 이름
                " id="student" aria-describedby="emailHelp">
                <small *ngIf="small.student" class="form-text text-muted text-validators {{formContractWord.get('{student}').status}} font-16">
                    <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                        alt="alert icon">
                        문자만 입력해 주세요.
                </small>
            </div>
          </div>
        </div>
  
        <!-- -******- -->
        <div class="inlineselects row no-gutters">
          <div class="col-12"> <label>근로계약기간</label></div>
          <div class="">
  
            <div class="form-inline">
              <div class="form-group  ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="lcys" formControlName='{lcys}' id="lcys" class="form-control {{valid.lcys}}">
                    <option value="N">선택</option>
                    <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">년</span>
                  </div>
                </div>
              </div>
  
  
              <div class="form-group  ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="lcms" formControlName='{lcms}' id="lcms" class="form-control {{valid.lcms}}">
                    <option value="N">선택</option>
                    <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">월</span>
                  </div>
                </div>
              </div>
  
              <div class="form-group  ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="lcds" formControlName='{lcds}' id="lcds" class="form-control {{valid.lcds}}">
                    <option value="N">선택</option>
                    <option *ngFor="let d of data.lcds;let i = index" value={{d.value}}>{{d.day}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">일</span>
                  </div>
                </div>
              </div>
            </div>
  
  
  
  
          </div>
          <div class="">
            <div class="form-inline">
              <!-- <label>~</label> -->
              <div class="form-group  ">
                <div class="input-group">
                  <div class="d-none d-md-block input-group-prepend">
                    <span class="input-group-text">~</span>
                  </div>
                  <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{lcye}' id="lcye" class="form-control {{valid.lcye}}">
                    <option value="N">선택</option>
                    <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">년</span>
                  </div>
                </div>
              </div>
  
  
              <div class="form-group ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{lcme}' id="lcme" class="form-control {{valid.lcme}}">
                    <option value="N">선택</option>
                    <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">월</span>
                  </div>
                </div>
              </div>
  
  
              <div class="form-group  ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{lcde}' id="lcde" class="form-control {{valid.lcde}}">
                    <option value="N">선택</option>
                    <option *ngFor="let d of data.lcde;let i = index" value={{d.value}}>{{d.day}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">일</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
        <div class="row">
          <div class="col-12">
            <label>근무시간</label>
            <div class="form-inline">
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{whs}' id="whs" class="form-control {{valid.whs}}">
                  <option value="N">시</option>
                  <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
                </select>
                <label for="inputselect0" class="mx-1">시</label>
              </div>
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{wms}' id="wms" class="form-control {{valid.wms}}">
                  <option value="N">분</option>
                  <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
                </select>
                <label for="inputselect0" class="mx-1">분</label>
              </div>
              <div class="form-group mx-sm-3 mb-2 mx-4">
                <label>~</label>
              </div>
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{whe}' id="whe" class="form-control {{valid.whe}}">
                  <option value="N">시</option>
                  <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
                </select>
                <label for="inputselect0" class="mx-1">시</label>
              </div>
  
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{wme}' id="wme" class="form-control {{valid.wme}}">
                  <option value="N">분</option>
                  <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
                </select>
                <label for="inputselect0" class="mx-1">분</label>
              </div>
            </div>
          </div>
  
          <div class="col-12">
            <label>토요일 </label>
            <div class="form-inline">
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{sahs}' id="sahs" class="form-control {{valid.sahs}}">
                  <option value="N">시</option>
                  <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
                </select>
                <label for="inputselect0" class="mx-1">시</label>
              </div>
  
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{sams}' id="sams" class="form-control {{valid.sams}}">
                  <option value="N">분</option>
                  <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
                </select>
                <label for="inputselect0" class="mx-1">분</label>
              </div>
  
              <div class="form-group mx-sm-3 mb-2 mx-4">
                <label>~</label>
              </div>
  
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{sahe}' id="sahe" class="form-control {{valid.sahe}}">
                  <option value="N">시</option>
                  <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
                </select>
                <label for="inputselect0" class="mx-1">시</label>
              </div>
  
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{same}' id="same" class="form-control {{valid.same}}">
  
                  <option value="N">분</option>
                  <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
                </select>
                <label for="inputselect0" class="mx-1">분</label>
              </div>
            </div>
          </div>
  
          <div class="col-12">
            <label>일요일 </label>
            <div class="form-inline">
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{suhs}' id="suhs" class="form-control {{valid.suhs}}">
                  <option value="N">시</option>
                  <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
                </select>
                <label for="inputselect0" class="mx-1">시</label>
              </div>
  
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{sums}' id="sums" class="form-control {{valid.sums}}">
                  <option value="N">분</option>
                  <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
                </select>
                <label for="inputselect0" class="mx-1">분</label>
              </div>
  
              <div class="form-group mx-sm-3 mb-2 mx-4">
                <label>~</label>
              </div>
  
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{suhe}' id="suhe" class="form-control {{valid.suhe}}">
                  <option value="N">시</option>
                  <option *ngFor="let h of data.hours;let i = index" value={{h.value}}>{{h.hours}}</option>
                </select>
                <label for="inputselect0" class="mx-1">시</label>
              </div>
  
              <div class="form-group mb-2 mx-1">
                <select pattern="^-?([0-9]\d*)?$" for="inputselect0" formControlName='{sume}' id="sume" class="form-control {{valid.sume}}">
  
                  <option value="N">분</option>
                  <option *ngFor="let m of data.minutes;let i = index" value={{m.value}}>{{m.minutes}}</option>
                </select>
                <label for="inputselect0" class="mx-1">분</label>
              </div>
            </div>
          </div>
        </div>
        <!-- -******- -->
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="salay">임금</label>
              <div class="input-group {{valid.salay}}">
                <input style="border-radius: 4px !important;" type="text"
                  pattern="^[0-9]{1,3}([,.][0-9]{1,3})+(.[0-9]{1,2})?$" class="form-control border-rounded "
                  (change)='setFormaNumber()' formControlName='{salay}' name="salay" placeholder="0" id="salay"
                  aria-describedby="salay">
                <div class="input-group-append salaryinput">
                  <span class="bg-transparent text-dark border-0 input-group-text">원</span>
                </div>
              </div>
              <small *ngIf="small.salay" class="form-text text-muted text-validators {{formContractWord.get('{salay}').status}} font-16">
                  <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                      alt="alert icon">
                      숫자만 입력해 주세요.
              </small>
            </div>
          </div>
        </div>
  
  
  
        <small id="AgreementHelp" class="form-text text-muted"></small>
  
        <!-- Added Lines -->
  
        <div class="inlineselects row no-gutters">
          <div class="col-12"> <label>임금 지급일
            </label></div>
          <div class="">
  
            <div class="form-inline">
              <div class="form-group  ">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text pl-0">매월</span>
                  </div>
                  <select pattern="^-?([0-9]\d*)?$" for="pday" formControlName='{pday}' id="pday" class="form-control {{valid.pday}}">
                    <option value="N">선택</option>
                    <option *ngFor="let dpay of data.dpays;let i = index" value={{dpay.value}}>{{dpay.day}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">일
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inlineselects row no-gutters">
          <div class="col-12"> <label>계약일</label></div>
          <div class="">
            <div class="form-inline">
              <div class="form-group  ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="ys" formControlName='{cys}' id="cys" class="form-control {{valid.cys}}">
                    <option value="N">선택</option>
                    <option *ngFor="let year of data.fiveYears;let i = index" value={{year.year}}>{{year.year}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">년</span>
                  </div>
                </div>
              </div>
  
  
              <div class="form-group  ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="ms" formControlName='{cms}' id="cms" class="form-control {{valid.cms}}">
                    <option value="N">선택</option>
                    <option *ngFor="let month of data.months;let i = index" value={{month.value}}>{{month.month}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">월</span>
                  </div>
                </div>
              </div>
  
              <div class="form-group  ">
                <div class="input-group">
                  <select pattern="^-?([0-9]\d*)?$" for="ds" formControlName='{cds}' id="cds" class="form-control {{valid.cds}}">
                    <option value="N">선택</option>
                    <option *ngFor="let d of data.cds;let i = index" value={{d.value}}>{{d.day}}</option>
                  </select>
                  <div class="input-group-append">
                    <span class="input-group-text">일</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="addcone">고용주(갑) 주소
              </label>
              <input type="text" class="form-control {{valid.addcone}}" formControlName='{addcone}' maxlength="40"
                id="addcone" name="addcone" placeholder="고용주(갑)주소
                " id="addcone" aria-describedby="emailHelp">
                <small *ngIf="small.addcone" class="form-text text-muted text-validators {{formContractWord.get('{addcone}').status}} font-16">
                    <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                        alt="alert icon">
                        필수 입력값을 정확하게 입력해 주세요
                </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="mut">고용주(갑) 상호명</label>
              <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.mut}}" formControlName='{mut}' maxlength="22"
                name="mut" placeholder="고용주(갑) 상호명" id="mut" aria-describedby="emailHelp">
                <small *ngIf="small.mut" class="form-text text-muted text-validators {{formContractWord.get('{mut}').status}} font-16">
                    <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                        alt="alert icon">
                        문자만 입력해 주세요.                        
                </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="ceo">고용주(갑) 대표자명
              </label>
              <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.ceo}}" formControlName='{ceo}' maxlength="22"
                id="ceo" name="ceo" placeholder="고용주(갑) 대표자명
                " aria-describedby="emailHelp">
                <small *ngIf="small.ceo" class="form-text text-muted text-validators {{formContractWord.get('{ceo}').status}} font-16">
                    <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                        alt="alert icon">
                        문자만 입력해 주세요.                        
                </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="addctwo">피고용인(을) 주소
              </label>
              <input type="text" class="form-control {{valid.addctwo}}" formControlName='{addctwo}' maxlength="40"
                id="addctwo" name="addctwo" placeholder="피고용인(을) 주소
                " aria-describedby="emailHelp">
                <small *ngIf="small.addctwo" class="form-text text-muted text-validators {{formContractWord.get('{addctwo}').status}} font-16">
                    <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                        alt="alert icon">
                        필수 입력값을 정확하게 입력해 주세요
                </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">피고용인(을) 성명</label>
              <input type="text" pattern="^-?(\D+)?$" class="form-control {{valid.name}}" formControlName='{name}' maxlength="10"
                id="name" name="name" placeholder="피고용인(을) 성명" aria-describedby="emailHelp">
                <small *ngIf="small.name" class="form-text text-muted text-validators {{formContractWord.get('{name}').status}} font-16">
                    <img src="../../../../assets/img/icon/Vector Smart Object@3x.png" width="16" height="16"
                        alt="alert icon">
                        문자만 입력해 주세요.
                </small>
            </div>
          </div>
        </div>
      </form>
      <!-- -******- -->
      <div style="margin-top:50px;" class="row">
        <div class="col-12 d-flex justify-content-center">
          <button [disabled]="!totalValidator() || !timeValidator()" type="button" style="width:auto !important;"
            (click)="onClickBtnCreateContractWord()" class="btn btn-big btn-crimson">
            다음
          </button>
        </div>
      </div>
    </div>
  
  </section>
  
  <app-alert-shared></app-alert-shared>
  <app-loading></app-loading>