import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ContractInformationBorrowingService {

  dataFromContract: {
    '{bdy}': string,
    '{bdm}': string,
    '{bdd}': string,
    '{borra}': string,
    '{pborra}': string,
    '{pob}': string,
    '{ppdy}': string,
    '{ppdm}': string,
    '{ppdd}': string,
    '{ppdyymmd}': string,
    '{inte}': string,
    '{dpay}': string,
    '{dsb}': string,
    '{an}': string,
    '{ah}': string,
    '{lad}': string,
    '{nod}': string,
    '{cdy}': string,
    '{cdm}': string,
    '{cdd}': string,
    '{cnm}': string,
    '{caddr}': string,
    '{cssn}': string,
    '{cci}': string,
    '{dsn}': string,
    '{dadd}': string,
    '{dssn}': string,
    '{dci}': string
  } = {
      '{bdy}': 'N',
      '{bdm}': 'N',
      '{bdd}': 'N',
      '{borra}': '',
      '{pborra}': '',
      '{pob}': '',
      '{ppdy}': 'N',
      '{ppdm}': 'N',
      '{ppdd}': 'N',
      '{ppdyymmd}': '',
      '{inte}': '',
      '{dpay}': 'N',
      '{dsb}': '',
      '{an}': '',
      '{ah}': '',
      '{lad}': '',
      '{nod}': '',
      '{cdy}': 'N',
      '{cdm}': 'N',
      '{cdd}': 'N',
      '{cnm}': '',
      '{caddr}': '',
      '{cssn}': '',
      '{cci}': '',
      '{dsn}': '',
      '{dadd}': '',
      '{dssn}': '',
      '{dci}': ''
    }

  constructor() { }

  setDataFromContract(key: any, value: string) {
    this.dataFromContract[key] = value;
  }

  getDataFromContract(key: any) {
    return this.dataFromContract[key];
  }

  clearDataFromContract() {
    this.dataFromContract = {
      '{bdy}': 'N',
      '{bdm}': 'N',
      '{bdd}': 'N',
      '{borra}': '',
      '{pborra}': '',
      '{pob}': '',
      '{ppdy}': 'N',
      '{ppdm}': 'N',
      '{ppdd}': 'N',
      '{ppdyymmd}': '',
      '{inte}': '',
      '{dpay}': 'N',
      '{dsb}': '',
      '{an}': '',
      '{ah}': '',
      '{lad}': '',
      '{nod}': '',
      '{cdy}': 'N',
      '{cdm}': 'N',
      '{cdd}': 'N',
      '{cnm}': '',
      '{caddr}': '',
      '{cssn}': '',
      '{cci}': '',
      '{dsn}': '',
      '{dadd}': '',
      '{dssn}': '',
      '{dci}': ''
    }
  }
}
