<div class="contract-page-inquiry">


  <div class="largerUi">
    <div class="container-lg ml-lg-0 mr-lg-auto p-0" style="background: #e2e6eb;">


      <div class="row formColumns">

        <!-- Col-Break -->


        <div class="col-lg-12">
          <label class="flex-grow-1 bd-highlight tit-set-001">문의하기</label>
          <form [formGroup]="formInquiry">
            <!-- Form C1 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">이메일</span>
              </div>
              <input formControlName='qaaMail' style="max-width: 45%;" type="email" placeholder="이메일 입력"
                class=" form-control">
            </div>
            <!-- Form C2 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text labelInline">문의 내용</span>
              </div>
              <textarea formControlName='qaaContent' rows="3" placeholder="문의 내용을 입력해 주세요."
                class="form-control  "></textarea>
            </div>

            <div style="margin-top: 60px; " class="col-12 d-flex justify-content-center">
              <button [disabled]='disabledBtn' (click)="onSubmit()" class="btn btn-big crimsonbg">
                등록
              </button>
            </div>

          </form>
        </div>
      </div>



    </div>
  </div>

  <div class="smallerUi">
    <div class="container-lg ml-lg-0 mr-lg-auto p-0" style="background: #e2e6eb;">


      <div class="row formColumns">

        <!-- Col-Break -->


        <div class="col-lg-12">
          <label class="flex-grow-1 bd-highlight tit-set-001">문의하기</label>
          <form class="CPI-Form" [formGroup]="formInquiry">
            <!-- Form C1 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">이메일</span>
              </div>
              <input formControlName='qaaMail' style="max-width: 100%;" type="email" placeholder="이메일 입력"
                class=" form-control">
            </div>
            <!-- Form C2 -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text labelInline">문의 내용</span>
              </div>
              <textarea formControlName='qaaContent' rows="3" placeholder="문의 내용을 입력해 주세요."
                class="form-control  "></textarea>
            </div>

            <div class="submitMargin col-12 d-flex justify-content-center">
              <button [disabled]='disabledBtn' (click)="onSubmit()" class="btn btn-big crimsonbg">
                등록
              </button>
            </div>

          </form>
        </div>
      </div>



    </div>
  </div>


</div>


<!-- New area -->