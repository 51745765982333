<section class="d-flex flex-column justify-content-center align-items-center" *ngIf="loading">
    <div class="loadingProgress">
        <div class="progress">
            <div class="progress-bar bg-danger text-right" role="progressbar" [ngStyle]="{'width': progress+'%'}"
                aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">
                <span>
                </span>
            </div>
        </div>
    </div>
    <button *ngIf="progress == 100" class="btn btn-primary bg-danger my-3" style="border:#dc3545!important"
        type="button" disabled>
        <span class="mx-2 spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Converter To PDF ...
    </button>
</section>