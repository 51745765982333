import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../manage/service/user/user.service';
import { LoadingService } from '../../manage/shared/loading/loading.service';
import { AlertService } from '../../manage/component/modal/alert/alert.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  formForgotPassword: FormGroup;
  validators: any = {
    email: '',
  }
  disabledBtn: boolean = true;
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private userService: UserService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.initFormForgotPassword();
  }

  checkValidatiorsFormForgotPassword() {
    const _calss = {
      VALID: '',
      INVALID: 'invalid'
    }
    this.validators.email = _calss[this.formForgotPassword.get('email').status];
  }

  initFormForgotPassword() {
    this.formForgotPassword = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
    this.formForgotPassword.get('email').valueChanges.subscribe(selectValue => {
      this.checkValidatiorsFormForgotPassword();
    });
    this.formForgotPassword.valueChanges.subscribe(value => {
      this.checkDisabledBtn();
    });
  }

  checkDisabledBtn() {
    const valid = {
      VALID: false,
      INVALID: true
    }
    this.disabledBtn = valid[this.formForgotPassword.status];
  }

  sendForgotPassword() {
    this.loadingService.openLoading();
    this.userService.forgotPassword(this.formForgotPassword.get("email").value).subscribe((rs: any) => {
      if (rs.status) {
        this.alertService.open(
          "알림",
          rs.data,
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }else{
        this.alertService.open(
          "알림",
          rs.message,
          {
            func: () => {
              this.alertService.close();
            }
          },
          {
            func: () => {
              this.alertService.close();
            }
          }
        );
      }
      this.loadingService.closeLoading();
    });
  }
}
