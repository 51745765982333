import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServiceCenterService } from '../../service/service-center/service-center.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { NavbarService } from '../navbar/navbar.service';
@Component({
  selector: 'app-mail-management',
  templateUrl: './mail-management.component.html',
  styleUrls: ['./mail-management.component.scss']
})
export class MailManagementComponent implements OnInit {
  td: any;
  tList: any = [];
  constructor(
    private serviceCenterService: ServiceCenterService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
  ) { }

  @Output() clickItem = new EventEmitter<any>();
  @Output() getSuccess = new EventEmitter<any>();
  @Input() api;

  apiGet: boolean = true;

  ngOnInit(): void {
    this.navbarService.setAction('tempMail');
    this.td = this.inntData();
    this.getTemplateMail();
  }

  itemTotal: number = 0;

  paramsApi = {
    data: "",
    pageNo: 0,
    pageSize: 5
  }

  pagination = {
    maxPage: 1,
    currentPage: 1
  }

  ngDoCheck(): void {
    this.apiGet = this.api;
    if (this.apiGet) {
      this.apiGet = false;
      this.getTemplateMail();
    }
  }

  inntData() {
    return [{
      tempmNm: '표준근로계약서'
    }, {
      tempmNm: '아르바이트 근로계약서'
    }, {
      tempmNm: '부동산 임대 계약서   '
    }];
  }

  getTemplateMail() {
    this.loadingService.openLoading();
    this.serviceCenterService.getTemplateMail(this.paramsApi).subscribe((rs: any) => {
      if (rs.status) {
        this.getSuccess.emit(false);
        this.td = rs.data;
        this.calMaxPage(rs.total, this.paramsApi.pageSize)
        this.itemTotal = rs.total;
      }
      this.loadingService.closeLoading();
    });
  }
  calMaxPage(itemTotal, pageSize) {
    if (itemTotal % pageSize == 0) {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize));
      if (this.pagination.currentPage > this.pagination.maxPage && this.pagination.currentPage != 1) {
        this.pagination.currentPage = this.pagination.maxPage;
        this.paramsApi.pageNo = this.pagination.currentPage - 1;
      }
      this.loadingService.openLoading();
      this.serviceCenterService.getTemplateMail(this.paramsApi).subscribe((rs: any) => {
        if (rs.status) {
          this.getSuccess.emit(false);
          this.td = rs.data;
        }
        this.loadingService.closeLoading();
      });
    } else {
      this.pagination.maxPage = Math.floor((parseInt(itemTotal) / pageSize) + 1);
    }
  }
  selectItem($event,t) {
    if(!($event.target.toString().includes('Input')) && !($event.target.toString().includes('Span'))){
      this.clickItem.emit(t);
    }
  }

  setCurrentPage($event) {
    this.pagination.currentPage = $event;
    this.paramsApi.pageNo = $event - 1;
    this.getTemplateMail();
  }

  checkBoxSelect(number: number) {
    let arr = this.tList;

    let index = arr.findIndex(res => res.tempmId === number);

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push({ tempmId: number });
    }
    this.tList = arr;
  }

  deleteMailTemplate() {
    this.loadingService.openLoading();
    this.serviceCenterService.deleteTemplateMail(this.tList).subscribe((rs: any) => {
      if(rs.status){
        this.getTemplateMail();
      }
      this.loadingService.closeLoading();
    });
  }

  checkSelectTeplate(tempmId: string): string {
    let classCss = "rowChecked";

    let data = this.tList.filter((rse) => {
      return rse.tempmId == tempmId;
    });

    if (!(data.length > 0)) {
      classCss = "";
    }

    return classCss;
  }

}
