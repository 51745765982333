
<div class="bg-modal" *ngIf="show">
  <!-- Modal -->


  <div class="modal fade popUpType1 show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" (click)="closeModalBackground($event)">
    <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">
        <div class="modal-header rounded-0 border-bottom-0 pb-0 px-4 pt-4" style="background: #ffffff;color: #000000;">

          <div class="border-bottom border-dark d-flex w-100 pb-3 ">
            <h5><p id="title" [innerHTML]="title"></p></h5>
            <a type="button" style="color: #000000;" class="close" (click)="closeModal()" data-dismiss="modal"
              aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>

        </div>
        <div class="modal-body rounded-0 pb-0 px-4" style="background: #ffffff;color: #000000;">
          <p class="text-center mb-0" style="font-weight:300; font-size: 14px;" id="subtitle" [innerHTML]="subTitle">
          </p>
        </div>

        <div class="modal-footer rounded-0 d-flex justify-content-center border-top-0" style=" background: #ffffff;color:
          #000000;">
          <button type="button" class="btn btn-middle-short btn-white" (click)="onClickBtn('btnCancel')">닫기</button>
          <button *ngIf="false" type="button" class="btn btn-middle-short btn-black"
            (click)="onClickBtn('btnConfirm')">확인</button>
        </div>

      </div>
    </div>
  </div>



</div>