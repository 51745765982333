<div class="bg-modal" *ngIf="show">
  <!-- Modal -->
  <div class="modal fade show" style="display: block;padding-right: 17px;" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-0" style="background: transparent">


        <div class="modal-header border-0 rounded-0"
          style="background: #ffffff;color: #000000; padding: 20px 20px 30px 20px;">

          <div class="border-bottom border-dark d-flex w-100 pb-2 ">
            <h5 class="modal-title font-weight-bold" id="exampleModalLabel">서명/도장 추가</h5>
            <a type="button" style="color: #000000;" class="close" (click)="closeModal()" data-dismiss="modal"
              aria-label="Close">
              <img src="../../../../../assets/img/icon/exitbtn.png"></a>
          </div>

        </div>

        <div class="modal-body rounded-0 py-5 " style="background: #ffffff;color: #000000; padding: 0px 20px;">
          <section class="w-100" style="background-color: #e2e6eb;min-height: 300px">
            <div class="container-lg ml-lg-0 mr-lg-auto p-0">
              <div class="row">
                <div class="col-12">
                  <div class="bd p-0">
                    <div class="d-flex flex-column align-items-center justify-content-center bod-ico-up-loa-001"
                      id="drop-area">
                      <app-loading-progress></app-loading-progress>
                      <div class="w-100 h-100 d-flex flex-column align-items-center" *ngIf="upload">
                        <img src="../../../../assets/img//icon/upload-icon.png" class="img-fluid text-center">
                        <p class="det-up-loa-001 text-center">
                          파일을 드래그하거나 파일 찾기 버튼을 클릭하여 <br /> 업로드할 문서를 선택해주세요.
                        </p>

                        <form [formGroup]="uploadForm">
                          <input type="file" name="files"
                            accept=".pdf, .hwp, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png"
                            style="display: none;" id="files" (change)="onFileSelected($event)" #files>
                        </form>
                        <button class="btn btn-middle-long btn-white" (click)="files.click()">
                          <img src="../../../../assets/img//icon/plusShape.png">
                          파일 찾기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
        <div class="modal-footer border-0 rounded-0 d-flex justify-content-center"
          style="background: #ffffff;color: #000000; padding: 0px 20px 31px 20px ;">
          <button type="button" class="m-0 btn btn-white btn-middle-short" (click)="onClickBtn('btnCancel')">취소</button>
          <button [disabled]="btnDisabled" style="margin:0px 0px 0px 0px 10px;" type="button"
            class="m-0 btn btn-black btn-middle-short" (click)="onClickBtn('btnOk')">확인</button>
        </div>
      </div>
    </div>
  </div>
</div>