import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ServiceCenterService } from '../../service/service-center/service-center.service';

@Component({
  selector: 'app-inquiry-detail',
  templateUrl: './inquiry-detail.component.html',
  styleUrls: ['./inquiry-detail.component.scss']
})
export class InquiryDetailComponent implements OnInit {

  constructor(
    private serviceCenterService: ServiceCenterService,
  ) { }

  @Output() goBack = new EventEmitter<any>();
  @Input() selectItem: any;

  item: {
    quesId: string,
    quesCont: string,
    quesStatus: string,
    frstReginPnttm: string
  }

  answ: {
    answId: string,
    answCont: string,
    answStatus: string,
    frstReginPnttm: string
  }


  ngDoCheck(): void {
    if (this.item.quesId != this.selectItem.quesId) {
      this.answ = {
        answId: "",
        answCont: "",
        answStatus: "",
        frstReginPnttm: ""
      }
      this.item = this.selectItem;
      this.getInquiryAnswer();
    }
  }

  ngOnInit(): void {
    this.answ = {
      answId: "",
      answCont: "",
      answStatus: "",
      frstReginPnttm: ""
    }
    this.item = this.selectItem;
    this.getInquiryAnswer();
  }

  getInquiryAnswer(): void {
    this.serviceCenterService.getInquiryAnswer(this.item.quesId).subscribe((rs: any) => {
      if (rs.status) {
        if(rs.data != undefined){
          this.answ = rs.data;
        }
      }
    });
  }

  onBack(){
    this.goBack.emit(true);
  }
}
