<div class="bg-modal" *ngIf="show">
    <!-- Modal -->
    <div class="modal fade show" style="display: block;" id="exampleModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <!-- popup 사이즈 조절 클래스인(modal-lg) 대신 이미지 팝업 사이즈 조절을 위해 클래스(modal-img)를 modal-dialog 위치에 추가합니다. -->
      <div class="modal-dialog modal-img modal-dialog-centered">
        <div class="modal-content rounded-0 p-0">
          <div class="modal-body rounded-0 p-0">
            <div *ngIf="popUp === 'req'"> <!-- 원래 payment-pop 클래스 있던 곳 -->
              <section>
                <title>지콘트 오픈 기념 이벤트</title>
                <span class="close">
                  <a href="javascript:void(0)" (click)="closeModal()">
                    <img src="./assets/img/icon/close-pop-wh@2x.png" alt="닫기">
                  </a>
                </span>
                <div class="wrap_imgpopup">
                  <!-- 공지마다 다를 수 있음 PC width 420px [S]-->
                  <img src="../../../../../assets/img/frontpage/img_popup_01.png"
                    alt="지콘트 오픈 기념 1개월 무료혜택 이벤트 - 개방형 블록체인 전자계약 서비스 지콘트가 한달 무료 이벤트를 진행합니다. 많은 이용 부탁드립니다. 무료 이용기간 2021년 4월1일부터 2020년 4월 30일까지">
                  <a href=" https://www.gcont.co.kr/#/register" target="_blank"
                    style="position:absolute; left:30.4%;top:73%; width:39.2%; height:9%;"><img
                      style="display:block; width:100%; height:auto;"
                      src="../../../../../assets/img/frontpage/img_popup_01_btn.png" alt="회원가입하기"></a>
                    <!-- 공지마다 다를 수 있음 PC width 420px [E]-->
                </div>
                <div class="wrap-nopen-pop" [formGroup]="form">
                  <label for="d-n-open" title="오늘 그만 보기">
                    <input type="checkbox" id="d-n-open" formControlName="cookieCheck" />오늘 하루 이 창을 열지 않음
                  </label>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>