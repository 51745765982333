import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  noticId: string = null;
  constructor() { }

  setNoticId(noticId: string) {
    this.noticId = noticId;
  }

  getNoticId() {
    let noticId = this.noticId;
    this.noticId = null;
    return noticId;
  }
}
