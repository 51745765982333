import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {

  component: {
    display,
  } = {
      display: false
    }
  data = {
    data: "",
    pageNo: 0,
    pageSize: 5
  }
  constructor() { }


  setPageNo(pageNo: number) {
    this.data.pageNo = pageNo;
  }

  getPageNo() {
    return this.data.pageNo;
  }

  setPageSize(pageSize: number) {
    this.data.pageSize = pageSize;
  }

  getPageSize() {
    return this.data.pageSize;
  }

  getData() {
    return this.data;
  }
}
