import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo';
import { OrganizationService } from '../../service/organization/organization.service';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoginService } from '../../../frontpage/login/login.service';
import { ModalService } from '../modal/modal.service';

import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import { AlertService } from '../modal/alert/alert.service';
import { AlertConfirmService } from '../modal/alert-confirm/alert-confirm.service';
import { EditorPdfService } from '../../../manage/component/editor-pdf/editor-pdf.service';
import { TableDataService } from '../table-data/table-data.service';
import { TemplateService } from '../../../manage/service/template/template.service';
import { ContractService } from '../../../manage/service/contract/contract.service';
import { AlertSaveNameTemplateService } from './../../../manage/component/modal/alert-save-name-template/alert-save-name-template.service';
import { AlertSharedService } from '../../shared/modal/alert-shared/alert-shared.service';
import { Router } from '@angular/router';

import { LoadingService } from '../../shared/loading/loading.service';

import Regex from '../../shared/regex/regex';

@Component({
  selector: 'app-select-recipients',
  templateUrl: './select-recipients.component.html',
  styleUrls: ['./select-recipients.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SelectRecipientsComponent implements OnInit {

  selectAuto: boolean = false;

  btnEnableExpiry: boolean = false;
  btnExpiry: string = 'btn-gray';

  btnEnablePassword: boolean = false;
  btnPassword: string = 'btn-gray';

  btnEnableOtpPhone: boolean = false;
  btnOtpPhone: string = 'btn-gray';
  verifiPhoneYn: string = "N";
  verifiPwdYn: string = "N";

  faSearch = faSearch;
  formSearch: FormGroup;
  formSequence: FormGroup;
  formExpiry: FormGroup;

  treeOpen: {
    divId: string
  }[] = [];

  empDataList: {
    department: any[], tames: [], emp: [],
    division: any[]
  };

  division: any[] = [];
  user: any[] = [];
  searchDatas: any[] = [];
  groupSearch: any[] = [];

  objTable: any;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faUndo = faUndo;

  formNameTemplate: FormGroup;
  formInputPassword: FormGroup;

  classCss: {
    tree: string,
    list: string
  }
    =
    {
      tree: 'btn-white',
      list: 'btn-white'
    }

  html: {
    htmlTree: any,
    htmlList: any
  } = {
      htmlTree: '',
      htmlList: ''
    }

  treeView: {
    tree: string,
    list: string
  } = {
      tree: 'active',
      list: 'nested'
    }

  fromEmployees: FormGroup;
  arrayFromDatas: {
    contractNm: string, contractEmail: string, contractMobile: string, dataType: string, color: string, userId: string, divId: string
  }[] = [];

  arrayDatas: {
    contractNm: string, contractEmail: string, contractMobile: string, dataType: string, color: string, userId: string, divId: string
  }[] = [];

  selectMap: {
    left: {
      userId: string,
      divId: string
    },
    right: {
      index: number
    }
  } = {
      left: {
        userId: null,
        divId: null
      },
      right: {
        index: null
      }
    }

  select: {
    tree: {
      division: {
        divId: string,
        divNm: string
      }[],
      user: {
        userId: string,
        divId: string
      }[]
    },
    treeOld: {
      division: {
        divId: string,
        divNm: string
      }[],
      user: {
        userId: string,
        divId: string
      }[]
    },
    con: {
      divId: string,
      userId: string
    }[]
  } = {
      tree: {
        division: [],
        user: []
      }, treeOld: {
        division: [],
        user: []
      },
      con: []
    }

  member: {
    divId: string,
    divNm: string,
    total: number
  }[] = [];

  organ: {
    orgNm: string,
    orgId: string
  } = {
      orgNm: "",
      orgId: ""
    }

  inputs: {
    inputPassword: any
  } = {
      inputPassword: null
    };

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private organizationService: OrganizationService,
    private tableDataService: TableDataService,
    public editorPdfService: EditorPdfService,
    private router: Router,
    private alertConfirmService: AlertConfirmService,
    private alertService: AlertService,
    private tmplateService: TemplateService,
    private contractService: ContractService,
    private alertSaveNameTemplateService: AlertSaveNameTemplateService,
    private loginService: LoginService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private alertSharedService: AlertSharedService,
  ) { }

  ngOnInit(): void {
    this.initFormSequence();
    this.initFormLogin();
    this.initFormNameTemplate();
    this.initFromInputPassword();
    this.initFromInputExpiry();
    this.initFormEmployee();
    this.getCompany();
    this.getDivisionsTree();
    this.initDataFromStart();
  }

  openModal() {
    this.modalService.setHtmlBody('<app-excel-transfer></app-excel-transfer>');
    this.modalService.setState('excelTransfer');
    this.modalService.open();
  }

  ngAfterViewInit(): void {
    this.inputs.inputPassword = document.getElementById('contorPass');
    this.inputs.inputPassword.setAttribute('disabled', 'true');
  }

  ngDoCheck(): void {//Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    if (this.tableDataService.change.exportData) {
      this.setDataImport(this.tableDataService.getDataTable());
      this.tableDataService.setChange('exportData', false);
    }
  }

  initDataFromStart() {
    if (this.editorPdfService.getColorSign().length > 0) {

      let dataMarkSign = this.editorPdfService.getColorSign();
      if (dataMarkSign.length > 0) {
        for (let i = 0; i < dataMarkSign.length; i++) {
          this.pushContact('', '', '', dataMarkSign[i].color, 'input', '', '');
        }
      }

    } else {
      this.pushContact('', '', '', 'rgb(0,0,0)', 'input', '', '');
    }

  }

  initFormSequence() {
    this.formSequence = this.fb.group({
      sequenceYn: ['N', Regex.Required]
    });

    let dataMarkSign = this.editorPdfService.getColorSign();
    if (dataMarkSign.length == 1) {
      this.formSequence.get("sequenceYn").disable();
    }
  }

  setDataImport(data: any[]) {
    data.forEach(element => {
      this.pushContact(element.contractNm, element.contractEmail, element.contractMobile, "", "import", "", "");
    });
  }

  initFormEmployee(): void {
    this.fromEmployees = this.fb.group({
      employees: this.fb.array([])
    });
  }

  genStringTotal(): string {
    let total = 0;
    let divsion = '';

    for (let i = 0; i < this.member.length; i++) {
      total += this.member[i].total;

      if ((i == (this.member.length - 1))) {
        divsion += this.member[i].divNm + ' ' + this.member[i].total
      } else {
        divsion += this.member[i].divNm + ' ' + this.member[i].total + ', '
      }

    }
    return "계약 대상자 " + total + " ( " + divsion + " )";
  }

  pushContact(contractNm: string = '', contractEmail: string = '', contractMobile: string = '', colorL: string = '', dataType: string = 'input', userId: string = '', divId: string = '') {

    this.arrayFromDatas.push({ contractNm: contractNm, contractEmail: contractEmail, contractMobile: contractMobile, color: colorL, dataType: dataType, userId: userId, divId: divId });
    this.employeeDatas.push(this.createItemDataEmployeeDatas(contractNm, contractEmail, contractMobile, colorL, dataType, userId, divId, ""));


    this.clearColorUsers();
  }

  removeContact(index: any) {
    this.checkSelectContract(this.arrayDatas[index].divId, this.arrayDatas[index].userId);
    this.onClickBtnPopSelect();
  }


  clearColorUsers() {
    let dataMarkSign = this.editorPdfService.getColorSign();
    if (dataMarkSign.length > 0) {
      for (let i = 0; i < this.arrayFromDatas.length; i++) {
        let color = dataMarkSign[dataMarkSign.length - 1].color;
        if (i < dataMarkSign.length) {
          color = dataMarkSign[i].color;
        }
        this.arrayFromDatas[i].color = color;
        this.employeeDatas.get(i.toString()).get('contorType').setValue(color);
      }
    }
  }

  removeContactEmp(index: any) {
    this.arrayFromDatas.splice(index, 1);
    this.employeeDatas.removeAt(index);

    this.clearColorUsers();
  }

  get employeeDatas() {
    return this.fromEmployees.get('employees') as FormArray;
  }

  createItemDataEmployeeDatas(contractNm: string, contractEmail: string, contractMobile: string, colorL: string, dataType: string, userId: string, divId: string, password: string): FormGroup {
    return this.fb.group({
      contractNm: [contractNm, Regex.Required], contractEmail: [contractEmail, Regex.emailRequired], contractMobile: [contractMobile], contorType: colorL, dataType: dataType, userId: userId, divId: divId, password: password
    });
  }

  initFormNameTemplate() {
    this.formNameTemplate = this.fb.group({
      nameTemplate: [this.editorPdfService.getTemplNm()],
    });
  }

  initFromInputPassword() {
    this.formInputPassword = this.fb.group({
      contorPass: [{ value: "", disabled: true }, Validators.required],
    });
  }

  initFromInputExpiry() {
    this.formExpiry = this.fb.group({
      expiryDay: [{ value: "", disabled: true }, Validators.required],
      useExpiryYn: ["N"]
    });
  }

  getDivisionsTree() {
    this.loadingService.openLoading();
    this.organizationService.getDivisionsTree().subscribe((rs: any) => {
      if (rs.status) {
        // console.log(rs.data)
        this.division = rs.data.division;
        this.user = rs.data.user;

        this.initHtml();

        this.loadingService.closeLoading();
      }
    });
  }

  getCompany() {
    this.organizationService.getCompany().subscribe((rs: any) => {
      if (rs.status) {
        this.organ = rs.data;
      }
    });
  }

  initHtml() {
    document.getElementById('htmlTree').innerHTML = this.genHtmlTree();
    this.html.htmlTree = this.sanitizer.bypassSecurityTrustHtml(this.genHtmlTree());
    this.initTreeView();

    this.initCheckBoxDivision();
    this.initCheckBoxUser();
  }

  initDataEmp() {
    this.getDivisionsTree();

    this.objTable = [];
  }

  genHtmlTree(parentDivId: string = null) {
    let treeParent = this.division.filter(res => res.parentDivId == parentDivId);

    let htmlTree = '';
    if (treeParent.length > 0) {
      treeParent.forEach(parent => {
        let divShow = this.select.treeOld.division.filter(divi => {
          return divi.divId === parent.divId;
        });
        if (divShow.length == 0) {

          htmlTree += '<li _gcont_select_recipient class="cus-che-001" id="department-' + parent.divId + '">';
          let usr = this.user.filter(res => {
            return res.divId == parent.divId;
          });

          var cssIcon = "ico-img-tre-div-001";
          var cssNmType = 'nm-typ';
          if (parent.countCh == 0) {
            cssIcon = "ico-img-tre-div-002";
            cssNmType = 'nm-sel';
          }

          htmlTree += '<div _gcont_select_recipient class="lis-sub-hea-001 ' + cssIcon + ' caret ' + this.checkOpenTree(parent.divId).cssDropDown + '" attr-divNm="' + parent.divNm + '" attr-divId="' + parent.divId + '">';
          htmlTree += '<span _gcont_select_recipient style="margin-left: -15px;">';
          htmlTree += '<span _gcont_select_recipient class="px-2 ' + cssNmType + '" attr-usr="' + usr.length + '" attr-divNm="' + parent.divNm + '" attr-value="' + parent.divId + '">' + parent.divNm + '</span>';
          htmlTree += '</span>';
          htmlTree += '</div>';


          if (parent.countCh > 0) {
            htmlTree += '<ul _gcont_select_recipient class="nested ' + this.checkOpenTree(parent.divId).cssActive + '">';
            if (this.genHtmlTree(parent.divId) != '') {
              htmlTree += this.genHtmlTree(parent.divId);
            } else {
            }
            htmlTree += '</ul>';
          }

          htmlTree += '</li>';
        }
      });
    }
    return htmlTree;
  }

  checkOpenTree(divId: string): {
    cssActive: string,
    cssDropDown: string
  } {
    var cssOpen: {
      cssActive: string,
      cssDropDown: string
    } = {

      cssActive: '',
      cssDropDown: ''
    };
    let div = this.treeOpen.filter((res) => {
      return res.divId == divId;
    });

    if (div.length > 0) {
      cssOpen.cssActive = 'active';
      cssOpen.cssDropDown = 'caret-down';
    }

    return cssOpen;
  }
  genHtmlTreeUser(divId: string) {
    let user = this.user.filter(res => res.divId == divId);

    let htmlUser = '';
    if (user.length > 0) {
      user.forEach(user => {
        let userShow = this.select.treeOld.user.filter(userOld => {
          return userOld.userId === user.userId;
        });

        if (userShow.length == 0) {
          htmlUser += '<li _gcont_select_recipient class="cus-che-001 li-usr-001" id="user-' + user.userId + '">';

          htmlUser += '<div _gcont_select_recipient class="lis-sub-hea-003">';
          htmlUser += '<input _gcont_select_recipient type="checkbox" class="cheUse" attr-divId="' + divId + '" attr-value="' + user.userId + '" value="' + user.userId + '" />';
          htmlUser += '<span _gcont_select_recipient class="px-2 nm-typ">' + user.userNm + '</span>';
          htmlUser += '</div>';


          htmlUser += '</li>';
        }
      });
    }
    return htmlUser;
  }

  genHtmlList(parentDivId: string = null) {
    let treeParent = this.division.filter(res => res.countCh == 0);

    let htmlList = '';
    if (treeParent.length > 0) {
      treeParent.forEach(parent => {
        let divShow = this.select.treeOld.division.filter(divi => {
          return divi.divId === parent.divId;
        });

        if (divShow.length == 0) {
          if (this.genHtmlListUser(parent.divId) != '') {
            htmlList += '<li _gcont_select_recipient class="cus-che-001">';
            htmlList += '<div _gcont_select_recipient class="lis-sub-hea-001 tit">';
            htmlList += '<input _gcont_select_recipient type="checkbox" style="margin-right: .5rem;" class="cheDiv px-2" attr-divNm="' + parent.divNm + '" attr-value="' + parent.divId + '" value="' + parent.divId + '" />';
            // htmlList += '<span class="px-2 tit">' + parent.divNm + '</span>';
            htmlList += '' + parent.divNm + '';
            htmlList += '</div>';
            htmlList += '<ul _gcont_select_recipient class="lis-hea-001 nested">';
            if (this.genHtmlTree(parent.divId) != '') {
              htmlList += this.genHtmlList(parent.divId);
            } else {
              htmlList += this.genHtmlListUser(parent.divId);
            }
            htmlList += '</ul>';
            htmlList += '</li>';
          }
        }

      });
    }
    return htmlList;
  }

  genHtmlListUser(divId: string) {
    let user = this.user.filter(res => res.divId == divId);

    let htmlUser = '';
    if (user.length > 0) {
      user.forEach(user => {
        let userShow = this.select.treeOld.user.filter(userOld => {
          return userOld.userId === user.userId;
        });

        if (userShow.length == 0) {
          htmlUser += '<li _gcont_select_recipient class="cus-che-001" id="user-' + user.userId + '">';
          htmlUser += '<div _gcont_select_recipient class="lis-sub-hea-003">';
          htmlUser += '<input _gcont_select_recipient type="checkbox" class="cheUse" attr-divId="' + user.userId + '" attr-value="' + user.userId + '" value="' + user.userId + '" />';
          htmlUser += '<span _gcont_select_recipient class="px-2 tit">' + user.userNm + '</span>';
          htmlUser += '</div>';
          htmlUser += '</li>';
        }
      });
    }
    return htmlUser;
  }

  checkUserSelect(divId: string, userId: string) {
    let userShow = this.select.treeOld.user.filter(userOld => {
      return (userOld.userId === userId && userOld.divId === divId);
    });

    let haveusr: boolean = true;
    if (userShow.length > 0) {
      haveusr = false;
    }

    return haveusr;
  }

  checkHaveUserSelect(divId: string) {
    let usrls = this.select.treeOld.user.filter(userOld => {
      return (userOld.divId === divId);
    });

    let haveusr: boolean = true;
    if (usrls.length > 0) {
      haveusr = false;
    }

    return (haveusr && !(this.editorPdfService.getColorSign().length > 1));

  }

  checkDivisionSelect(divId: string) {
    let divShow = this.select.treeOld.division.filter(divi => {
      return divi.divId === divId;
    });

    let haveDiv: boolean = true;
    if (divShow.length > 0) {
      haveDiv = false;
    }


    let usrld = this.user.filter(user => {
      return (user.divId === divId);
    });

    let usrls = this.select.treeOld.user.filter(userOld => {
      return (userOld.divId === divId);
    });

    if (usrld.length === usrls.length) {
      haveDiv = false;
    }

    return haveDiv;
  }

  checDivisionSelectTree(divId: string, userId: string) {
    let divShow = this.select.tree.division.filter(divi => {
      return divi.divId === divId;
    });

    let usrShow = this.select.tree.user.filter(usr => {
      return usr.userId === userId && usr.divId === divId;
    });


    let haveDiv: boolean = false;
    if (divShow.length > 0 || usrShow.length > 0) {
      haveDiv = true;
    }

    return haveDiv;
  }

  initFormLogin() {
    this.formSearch = this.fb.group({
      searchText: ['']
    });
    this.formSearch.get('searchText').valueChanges.subscribe(selectValue => {
      if (selectValue !== '') {

      } else {
      }
    });
  }

  pushOpenTree(divId: string) {
    let open = this.treeOpen;

    let index = open.findIndex(res => res.divId === divId);

    if (index > -1) {
      open.splice(index, 1);
    } else {
      open.push({ divId: divId });
    }
    this.treeOpen = open;
  }

  initTreeView() {
    const toggler = document.getElementsByClassName('caret');
    for (let i = 0; i < toggler.length; i++) {
      let elClone = toggler[i].cloneNode(true);
      toggler[i].parentNode.replaceChild(elClone, toggler[i]);
    }
    for (let i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener('click', (event) => {

        if (!((<HTMLElement>event.target).className.includes('che-mar-001') || (<HTMLElement>event.target).className.includes('cheDiv')) && (!(<HTMLElement>event.target).className.includes('che-mar-002')) && (!(<HTMLElement>event.target).className.includes('nm-sel')) && (!(<HTMLElement>event.target).className.includes('nm-typ'))) {

          this.pushOpenTree((<HTMLElement>event.target).getAttribute('attr-divid'));

          toggler[i].parentElement.querySelector('.nested').classList.toggle('active');
          toggler[i].classList.toggle('caret-down');
        }
      });
    }

    const toggler_two = document.getElementsByClassName('nm-sel');
    for (let i = 0; i < toggler_two.length; i++) {
      let elClone = toggler_two[i].cloneNode(true);
      toggler_two[i].parentNode.replaceChild(elClone, toggler_two[i]);
    }
    for (let i = 0; i < toggler_two.length; i++) {
      toggler_two[i].addEventListener('click', () => {
        if (toggler_two[i].getAttribute('attr-usr') !== '0') {
          this.checkBoxSelectDivision(toggler_two[i].getAttribute('attr-value'), toggler_two[i].getAttribute('attr-divNm'));
          toggler_two[i].classList.toggle('sel-act');
          if (this.selectMap.right.index !== null) {
            this.btnShowViewTree();
          }
        } else {
          this.alertSharedService.open(
            {
              title: "팀원 선택",
              btnClose: true
            },
            {
              title: "선택한 팀에",
              subTitle: "팀원이 없습니다."
            },
            [
              {
                btnType: 'close',
                btnNm: '확인',
                btnStyle: 'btn-black',
                btnFunc: () => {
                  console.log("test");
                }
              }
            ]
          );
        }
      });
    }

    const toggler_three = document.getElementsByClassName('nm-typ');
    for (let i = 0; i < toggler_three.length; i++) {
      let elClone = toggler_three[i].cloneNode(true);
      toggler_three[i].parentNode.replaceChild(elClone, toggler_three[i]);
    }
    for (let i = 0; i < toggler_three.length; i++) {
      toggler_three[i].addEventListener('click', () => {
        this.getDivisionsChild(toggler_three[i].getAttribute('attr-value'));
        if (this.searchDatas.length !== 0) {
          this.checkBoxSelectDivision(toggler_three[i].getAttribute('attr-value'), toggler_three[i].getAttribute('attr-divNm'));
          toggler_three[i].classList.toggle('sel-act');
          if (this.selectMap.right.index !== null) {
            this.btnShowViewTree();
          }
        } else {
          this.alertSharedService.open(
            {
              title: "팀원 선택",
              btnClose: true
            },
            {
              title: "선택한 팀에",
              subTitle: "팀원이 없습니다."
            },
            [
              {
                btnType: 'close',
                btnNm: '확인',
                btnStyle: 'btn-black',
                btnFunc: () => {
                  console.log("test");
                }
              }
            ]
          );
        }
        this.searchDatas = [];
      });
    }
  }

  clearSelectActions(i: number) {
    const toggler_three = document.getElementsByClassName('nm-typ');
    for (let j = 0; j < toggler_three.length; j++) {
      if (i != j)
        toggler_three[j].classList.remove('sel-act');
    }

  }

  initListView() {

    const toggler_one = document.getElementsByClassName('tit');
    for (let i = 0; i < toggler_one.length; i++) {
      let elClone = toggler_one[i].cloneNode(true);
      toggler_one[i].parentNode.replaceChild(elClone, toggler_one[i]);
    }
    for (let i = 0; i < toggler_one.length; i++) {
      toggler_one[i].addEventListener('click', (e) => {
        if (!e.target.toString().includes('Input')) {
          toggler_one[i].parentElement.querySelector('.nested').classList.toggle('active');
        }
      });
    }
  }

  initCheckBoxDivision() {
    const toggler_one = document.getElementsByClassName('cheDiv');
    for (let i = 0; i < toggler_one.length; i++) {
      let elClone = toggler_one[i].cloneNode(true);
      toggler_one[i].parentNode.replaceChild(elClone, toggler_one[i]);
    }
    for (let i = 0; i < toggler_one.length; i++) {
      toggler_one[i].addEventListener('click', (e) => {
        this.checkBoxSelectDivision(toggler_one[i].getAttribute('attr-value'), toggler_one[i].getAttribute('attr-divNm'));
      });
    }
  }

  checkSelectContract(divId: string, userId: string) {
    let arr = this.select.con;

    let index = arr.findIndex(res => (res.divId === divId && res.userId === userId));

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push({ divId: divId, userId: userId });
    }
  }

  checkBoxSelectDivision(divId: string, divNm: string) {
    let arr = this.select.tree.division;
    let chv = null;
    console.log(arr);
    if ((<HTMLInputElement>document.getElementById("td-" + divId)) != undefined) {
      chv = (<HTMLInputElement>document.getElementById("td-" + divId)).checked;
    }
    let index = arr.findIndex(res => (res.divId === divId && res.divNm === divNm));

    console.log(chv);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      if ((chv == null || chv == true)) {
        arr.push({ divId: divId, divNm: divNm });
      }
    }

    var usr = this.user.filter(res => {
      return res.divId == divId;
    });

    if ((chv == null || chv == true)) {
      usr.forEach(item => {
        let arrUsr = this.select.tree.user;

        let indexUsr = arrUsr.findIndex(res => res.userId === item.userId);

        if (indexUsr > -1) {
          arrUsr.splice(indexUsr, 1);
        } else {
          arrUsr.push({ userId: item.userId, divId: divId });
        }

        this.select.tree.user = arrUsr;
      });
    } else {
      usr.forEach(item => {
        let arrUsr = this.select.tree.user;

        let indexUsr = arrUsr.findIndex(res => res.userId === item.userId);

        if (indexUsr > -1) {
          arrUsr.splice(indexUsr, 1);
        }

        this.select.tree.user = arrUsr;
      });
    }
    this.select.tree.division = arr;
  }


  checkBoxSelectDivisionList(divId: string, divNm: string) {
    let arr = this.select.tree.division;
    let chv = null;
    if ((<HTMLInputElement>document.getElementById("td-" + divId)) != undefined) {
      chv = (<HTMLInputElement>document.getElementById("td-" + divId)).checked;
    }
    let index = arr.findIndex(res => (res.divId === divId && res.divNm === divNm));

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      if ((chv == null || chv == true)) {
        arr.push({ divId: divId, divNm: divNm });
      }
    }

    var usr = this.user.filter(res => {
      return res.divId == divId;
    });

    if ((chv == null || chv == true)) {
      usr.forEach(item => {
        let arrUsr = this.select.tree.user;

        let indexUsr = arrUsr.findIndex(res => res.userId === item.userId);

        if (indexUsr > -1) {
          arrUsr.splice(indexUsr, 1);
        } else {
          arrUsr.push({ userId: item.userId, divId: divId });
        }

        this.select.tree.user = arrUsr;
      });
    } else {
      usr.forEach(item => {
        let arrUsr = this.select.tree.user;

        let indexUsr = arrUsr.findIndex(res => res.userId === item.userId);

        if (indexUsr > -1) {
          arrUsr.splice(indexUsr, 1);
        }

        this.select.tree.user = arrUsr;
      });
    }
    this.select.tree.division = arr;

    if (this.selectMap.right.index !== null) {
      let el = <HTMLInputElement>document.getElementById("rig-" + this.selectMap.right.index);
      el.checked = false;
      this.selectMap.right.index = null;
    }

  }

  initCheckBoxUser() {
    const toggler_one = document.getElementsByClassName('cheUse');
    for (let i = 0; i < toggler_one.length; i++) {
      let elClone = toggler_one[i].cloneNode(true);
      toggler_one[i].parentNode.replaceChild(elClone, toggler_one[i]);
    }
    for (let i = 0; i < toggler_one.length; i++) {
      toggler_one[i].addEventListener('click', (e) => {
        this.checkBoxtreeOld(toggler_one[i].getAttribute('attr-value'), toggler_one[i].getAttribute('attr-divId'));
      });
    }
  }

  checkBoxRight(Index: number) {
    if (this.selectMap.right.index === null) {
      this.selectMap.right.index = Index;
      if (this.select.tree.division.length > 0) {
        this.btnShowViewTree();
      }
    } else if (this.selectMap.right.index === Index) {
      this.selectMap.right.index = null;
      this.selectMap.left.divId = null;
    } else if (this.selectMap.right.index !== null) {
      this.alertSharedService.open(
        {
          title: "알림",
          btnClose: true
        },
        {
          title: "수신자 지정에 1 명의 수신자만 선택 가능합니다",
          subTitle: ""
        },
        [
          {
            btnType: 'close',
            btnNm: '확인',
            btnStyle: 'btn-black',
            btnFunc: () => {
              let el = <HTMLInputElement>document.getElementById("rig-" + Index);
              el.checked = false;
            }
          }
        ]
      );
    }

  }

  checkBoxtreeOld(userId: string, divId: string) {

    if (this.editorPdfService.getColorSign().length > 1) {

      if (this.selectMap.left.userId === null && this.selectMap.left.divId === null) {
        this.selectMap.left.userId = userId
        this.selectMap.left.divId = divId;
      } else if (this.selectMap.left.userId === userId && this.selectMap.left.divId === divId) {
        this.selectMap.left.userId = null
        this.selectMap.left.divId = null;
      } else if (this.selectMap.left.userId !== null && this.selectMap.left.divId !== null) {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "수신자 지정에 \"1\" 명의 수신자만 선택 가능합니다",
            subTitle: ""
          },
          [
            {
              btnType: 'close',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {
                let el = <HTMLInputElement>document.getElementById("ts-" + userId + "-" + divId);
                el.checked = false;
              }
            }
          ]
        );
      }
    } else {


      let arr = this.select.tree.user;

      let index = arr.findIndex(res => res.userId === userId && res.divId === divId);

      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push({ userId: userId, divId: divId });
      }

      let arrDiv = this.select.tree.division;
      let indexDiv = arrDiv.findIndex(res => (res.divId === divId));

      if (indexDiv > -1) {
        arrDiv.splice(indexDiv, 1);
      }


      this.select.tree.division = arrDiv;

      this.select.tree.user = arr;
    }
  }

  onClickBtnPushSelect() {


    let dataDivision = this.select.tree.division;
    let dataUser = this.select.tree.user;
    if (this.editorPdfService.getColorSign().length > 1) {

      if (this.selectMap.left.userId !== null && this.selectMap.left.divId !== null && this.selectMap.right.index !== null) {
        let user = this.user.filter(res => res.userId == this.selectMap.left.userId)[0];

        let divId = dataDivision.filter(rse => {
          return rse.divId === this.selectMap.left.divId;
        });

        if (divId.length == 0) {
          this.pushOldUser(this.selectMap.left.userId, this.selectMap.left.divId);
        }

        this.fromEmployees.get('employees').get(this.selectMap.right.index.toString()).get('contractNm').setValue(user.userNm);
        this.fromEmployees.get('employees').get(this.selectMap.right.index.toString()).get('contractEmail').setValue(user.userMail);
        this.fromEmployees.get('employees').get(this.selectMap.right.index.toString()).get('contractMobile').setValue(user.userPhone);

        let el = <HTMLInputElement>document.getElementById("rig-" + this.selectMap.right.index);
        el.checked = false;

        this.selectMap.left.divId = null;
        this.selectMap.left.userId = null;
        this.selectMap.right.index = null;
      } else {
        this.alertSharedService.open(
          {
            title: "알림",
            btnClose: true
          },
          {
            title: "수신자와 수신 입력란을 올바르게 선택해주세요.",
            subTitle: ""
          },
          [
            {
              btnType: 'close',
              btnNm: '확인',
              btnStyle: 'btn-black',
              btnFunc: () => {

              }
            }
          ]
        );
      }
    } else {
      console.log(this.selectMap);
      if (this.selectMap.right.index !== null && dataUser.length > 0) {
        let usrId = dataUser[0].userId;
        let dvId = dataUser[0].divId;

        let user = this.user.filter(res => res.userId == usrId)[0];

        let divId = dataDivision.filter(rse => {
          return rse.divId === dvId;
        });

        if (divId.length == 0) {
          this.pushOldUser(usrId, dvId);
        }

        this.fromEmployees.get('employees').get(this.selectMap.right.index.toString()).get('contractNm').setValue(user.userNm);
        this.fromEmployees.get('employees').get(this.selectMap.right.index.toString()).get('contractEmail').setValue(user.userMail);
        this.fromEmployees.get('employees').get(this.selectMap.right.index.toString()).get('contractMobile').setValue(user.userPhone);

        let el = <HTMLInputElement>document.getElementById("rig-" + this.selectMap.right.index);
        el.checked = false;

        this.selectMap.left.divId = null;
        this.selectMap.left.userId = null;
        this.selectMap.right.index = null;

        let index = dataUser.findIndex(res => (res.divId === dvId && res.userId === usrId));

        if (index > -1) {
          dataUser.splice(index, 1);
        }
      }

      dataDivision.forEach(division => {
        this.select.treeOld.division.push({ divId: division.divId, divNm: division.divNm });
        this.getChildDivision(division.divId);
      });

      dataUser.forEach(user => {
        let divId = dataDivision.filter(rse => {
          return rse.divId === user.divId;
        });
        if (divId.length == 0) {
          this.pushOldUser(user.userId, user.divId);
          this.getUserByUserId(user.userId);
        }
      });

      this.select.tree.division = [];
      this.select.tree.user = [];
    }



    this.initHtml();
  }

  pushOldUser(userId: string, divId: string) {
    let oldUser = this.select.treeOld.user;

    let index = oldUser.findIndex(res => res.userId === userId);

    if (index < 0) {
      oldUser.push({ userId: userId, divId: divId });
      this.select.treeOld.user = oldUser;
    }
  }

  clearSelect() {
    this.select.tree.division = [];
    this.select.tree.user = [];
  }

  onClickClearData() {
    this.alertSharedService.open(
      {
        title: "전체 삭제",
        btnClose: true
      },
      {
        title: "선택한 계약 대상자를 모두 삭제하시겠습니까?",
        subTitle: ""
      },
      [
        {
          btnType: 'close',
          btnNm: '취소',
          btnStyle: 'btn-white',
          btnFunc: () => {
            console.log("test");
          }
        },
        {
          btnType: 'confirm',
          btnNm: '확인',
          btnStyle: 'btn-black',
          btnFunc: () => {
            this.select = {
              tree: {
                division: [],
                user: []
              }, treeOld: {
                division: [],
                user: []
              },
              con: []
            }

            this.member = [];

            this.arrayFromDatas = [];
            this.arrayDatas = [];

            this.selectMap = {
              left: {
                userId: null,
                divId: null
              },
              right: {
                index: null
              }
            }
            this.initHtml();
            this.initFormEmployee();
            this.initDataFromStart();
          }
        }
      ]
    );
  }

  onClickBtnPopSelect() {

    this.select.con.forEach(cont => {
      this.popSelectDivision(cont.divId);
      let oldUsr = this.select.treeOld.user;

      let index = oldUsr.findIndex(res => res.userId === cont.userId);

      if (index > -1) {
        oldUsr.splice(index, 1);
      }
      this.select.treeOld.user = oldUsr;

      let arr = this.arrayDatas;
      let indexArr = arr.findIndex(res => (res.userId === cont.userId && res.divId === cont.divId));


      let inm = 0;
      if (arr[indexArr].dataType === "sel-usr") {

        inm = this.member.findIndex(res => {
          return res.divId == arr[indexArr].divId;
        });

        this.member[inm].total -= 1;

        if (this.member[inm].total == 0) {
          this.member.splice(inm, 1);
        }

      } else if (arr[indexArr].dataType === "sel-div") {

        inm = this.member.findIndex(res => {
          return res.divId == arr[indexArr].divId;
        });

        this.member.splice(inm, 1);
      }

      if (indexArr > -1) {
        arr.splice(indexArr, 1);
      }
      this.arrayDatas = arr;
    });

    this.select.con = [];
    this.initHtml();
  }

  popSelectDivision(divId: string) {
    let treeParent = this.division.filter(res => res.divId == divId);
    treeParent.forEach(divi => {
      let oldDiv = this.select.treeOld.division;

      let index = oldDiv.findIndex(res => res.divId === divi.divId);

      if (index > -1) {
        oldDiv.splice(index, 1);
      }
      this.select.treeOld.division = oldDiv;

      this.popSelectDivision(divi.parentDivId);
    });
  }

  getChildDivision(divId: string) {
    let treeParent = this.division.filter(res => res.parentDivId == divId);
    if (treeParent.length === 0) {
      let user = this.getUserBydivId(divId);
      if (user.length != 0) {
        let div = this.division.filter(res => res.divId == divId)[0];

        let data = this.arrayDatas;

        let index = data.findIndex(res => (res.divId === div.divId));
        if (index > -1) {
          let usr = data.filter(res => {
            return res.divId == divId;
          });

          usr.forEach(usr => {
            let usr_i = data.findIndex(res => (res.divId === usr.divId));
            data.splice(usr_i, 1);

            let userShow = this.select.treeOld.user.findIndex(userOld => (userOld.userId === usr.userId && userOld.divId === divId));
            this.select.treeOld.user.splice(usr_i, 1);
          });
          this.member.push({ divId: div.divId, divNm: div.divNm, total: user.length });
          data.push({ contractNm: div.divNm, contractEmail: user.length + " 명에게 발송", contractMobile: null, color: null, dataType: "sel-div", userId: div.divId, divId: div.divId });

        } else {
          this.member.push({ divId: div.divId, divNm: div.divNm, total: user.length });
          data.push({ contractNm: div.divNm, contractEmail: user.length + " 명에게 발송", contractMobile: null, color: null, dataType: "sel-div", userId: div.divId, divId: div.divId });
        }
        this.select.tree.user = data;
      }

    } else {
      treeParent.forEach(child => {
        this.getChildDivision(child.divId);
      });
    }
    return
    treeParent.forEach(child => {
      let user = this.getUserBydivId(child.divId);
      if (user.length != 0) {
        user.forEach(user => {
          let data = this.arrayDatas;
          let index = data.findIndex(res => (res.userId === user.userId && res.divId === user.divId));
          this.pushOldUser(user.userId, user.divId);
          if (index > -1) {
            // data.splice(index, 1);
          } else {
            data.push({ contractNm: user.userNm, contractEmail: user.userMail, contractMobile: user.userPhone, color: user.userColor, dataType: "input", userId: user.userId, divId: user.divId });
          }
          this.select.tree.user = data;
        });
      } else {
        this.getChildDivision(child.divId);
      }
    });
  }

  getUserByUserId(userId: string) {
    let user = this.user.filter(res => res.userId == userId);
    user.forEach(usr => {
      let data = this.arrayDatas;
      let index = data.findIndex(res => res.userId === usr.userId);
      if (index > -1) {
        // data.splice(index, 1);
      } else {
        let indexMember = this.member.findIndex(res => res.divId === usr.divId);

        if (indexMember > -1) {
          this.member[indexMember].total += user.length;
        } else {
          this.member.push({ divId: usr.divId, divNm: usr.divNm, total: user.length });
        }

        data.push({ contractNm: usr.userNm, contractEmail: usr.userMail, contractMobile: usr.userPhone, color: usr.userColor, dataType: "sel-usr", userId: usr.userId, divId: usr.divId });
      }
      this.select.tree.user = data;
    });
  }

  getUserBydivId(divId: string) {
    // console.log(this.user);
    let user = this.user.filter(res => res.divId == divId);
    return user;
  }


  btnShowViewTree() {
    if (this.treeView.tree == 'active') {
      this.searchDatas = [];
      this.groupSearch = [];
      this.select.tree.user = [];

      console.log(this.select.tree.division);
      this.select.tree.division.forEach(divs => {
        this.getDivisionsChild(divs.divId);
        console.log(divs);
      });
      this.select.tree.division = [];

      this.groupSearch = this.getDataUserGroup();

      this.treeView.list = 'active';
      this.treeView.tree = 'nested';
    } else {
      this.clearSelect();
      this.initHtml();
      this.treeView.list = 'nested';
      this.treeView.tree = 'active';

      this.classCss.list = "btn-white";
      this.classCss.tree = "btn-white";

      this.initTreeView();
      this.initCheckBoxDivision();
      this.initCheckBoxUser();
    }
  }

  getDataUserGroup(): any {
    let group = this.searchDatas.reduce(function (rv, x) {
      (rv[x.divNm] = rv[x.divNm] || []).push(x);
      return rv;
    }, {});
    return Object.values(group);
  }

  getDivisionsChild(parentDivId: string = null) {
    let treeParent = this.division.filter(res => res.parentDivId == parentDivId);
    if (treeParent.length > 0) {
      treeParent.forEach(parent => {
        let user = this.user.filter(res => res.divId == parent.divId);
        if (user.length > 0) {
          let user = this.user.filter(res => res.divId == parent.divId);

          user.forEach(usr => {
            let index = this.searchDatas.findIndex(res => (res.userId === usr.userId && res.divId === usr.divId));
            if (index > -1) {
            } else {
              this.searchDatas.push(usr);
            }
          });

        } else {
          this.getDivisionsChild(parent.divId);
        }
      });
    } else {
      let user = this.user.filter(res => res.divId == parentDivId);
      if (user.length > 0) {
        let user = this.user.filter(res => res.divId == parentDivId);
        user.forEach(usr => {
          let index = this.searchDatas.findIndex(res => (res.userId === usr.userId && res.divId === usr.divId));
          if (index > -1) {
          } else {
            this.searchDatas.push(usr);
          }
        });
      }
    }
  }

  selectUserAll() {
    this.onClickClearData();
    this.division.forEach(item => {
      if (item.countCh == 0) {
        this.checkBoxSelectDivision(item.divId, item.divNm);
      }
    });

    return
    this.alertSharedService.open(
      {
        title: "전체 선택",
        btnClose: true
      },
      {
        title: "회사 내 전체 조직원을 선택하시겠습니까?",
        subTitle: ""
      },
      [
        {
          btnType: 'close',
          btnNm: '취소',
          btnStyle: 'btn-white',
          btnFunc: () => {
            console.log("test");
          }
        },
        {
          btnType: 'confirm',
          btnNm: '확인',
          btnStyle: 'btn-black',
          btnFunc: () => {
            this.division.forEach(item => {
              if (item.countCh == 0) {
                this.checkBoxSelectDivision(item.divId, item.divNm);
              }
            });
            this.onClickBtnPushSelect();
          }
        }
      ]
    );

  }

  btnShowViewList() {

    // return;
    this.searchDatas = [];
    this.groupSearch = [];
    this.select.tree.user = [];

    this.division.forEach(divs => {
      this.getDivisionsChild(divs.divId);
    });
    this.select.tree.division = [];

    this.groupSearch = this.getDataUserGroup();

    this.treeView.list = 'active';
    this.treeView.tree = 'nested';



    this.selectAuto = true;

    this.groupSearch.forEach(divs => {
      console.log(divs,divs.length);
      if(divs.length > 0){
        this.select.tree.division.push({ divId: divs[0].divId, divNm: divs[0].divNm });
        divs.forEach(item => {
          this.select.tree.user.push({ userId:item.userId,divId: item.divId});
        });
      }
    });

    this.initCheckBoxDivision();
    this.initCheckBoxUser();
  }

  checkedDivisionSelect(divId: string) {
    let divShow = this.select.tree.division.filter(divi => {
      return divi.divId === divId;
    });



    let haveDiv: boolean = false;
    if (divShow.length > 0) {
      haveDiv = true;
    }

    return haveDiv;
  }

  autoSelectDivision() {
    console.log(this.groupSearch);
    if (this.selectAuto) {
      this.selectAuto = false;
      this.groupSearch.forEach(divs => {
        console.log(divs,divs.length);
        if(divs.length > 0){
          this.select.tree.division.push({ divId: divs[0].divId, divNm: divs[0].divNm });
          divs.forEach(item => {
            this.select.tree.user.push({ userId:item.userId,divId: item.divId});
          });
        }
      });
    }
  }

  onSearch() {
    this.treeView.list = 'active';
    this.treeView.tree = 'nested';
    this.seachUserInOrgs();
  }

  seachUserInOrgs() {
    this.loadingService.openLoading();
    this.organizationService.seachUserInOrgs(this.formSearch.get('searchText').value).subscribe((rs: any) => {
      if (rs.status) {
        this.searchDatas = rs.data;
        this.groupSearch = this.getDataUserGroup();
      }
      this.loadingService.closeLoading();
    });
  }

  openList($event, idDiv: string = null) {
    if (!$event.target.className.includes('che-mar-001')) {
      let element = document.getElementById(idDiv);
      element.parentElement.querySelector('.nested').classList.toggle('active');
    }
  }

  goBackEditor() {
    this.editorPdfService.setState("back");
    this.router.navigate(['create-template-contract']);
  }

  checkDataForm() {
    if (this.formInputPassword.get('contorPass').valid) {
      this.alertConfirmService.open("해당 계약서를 전송하시겠습니까?",
        "수신자 정보가 정확하게 입력되었는지 확인해주세요.",
        {
          func: () => {
            this.alertConfirmService.close();
          }
        },
        {
          func: () => {
            let data = {
              contactor: this.genDataFromContract(),
              templId: this.editorPdfService.getTemplateAID(),
              templNm: this.formNameTemplate.get('nameTemplate').value,
              excelListNm: this.tableDataService.getFileName(),
              contorPass: this.formInputPassword.get('contorPass').value,
              frstRegisNo: this.loginService.getProfileLogin().userIdNo,
              sequenceYn: this.formSequence.get('sequenceYn').value,
              verifiPhoneYn: this.verifiPhoneYn,
              verifiPwdYn: this.verifiPwdYn,
              useExpiryYn: this.formExpiry.get('useExpiryYn').value,
              expiryDay: this.formExpiry.get("expiryDay").value
            };
            this.tmplateService.saveMarkTemplate(this.editorPdfService.getMark()).subscribe((rs: any) => {
              if (rs.status) {
                this.alertSaveNameTemplateService.setNameTemplate("");

                this.contractService.insertContract(data).subscribe((rs: any) => {
                  if (rs.status) {
                    this.alertConfirmService.close();
                    this.router.navigate(['contract-management']);
                  }
                });
              }
            });

          }
        });
    } else {
      this.alertConfirmService.open(
        "비밀번호 설정이 되지 않았습니다.",
        "이대로 보내시겠습니까?",
        {
          func: () => {
            this.alertConfirmService.close();
          }
        }, {
        func: () => {
          this.alertConfirmService.open("작성하신 계약서를 사용하여 계약서를 전송하시겠습니까?",
            "수신자 정보가 정확하게 입력되었는지 확인해주세요.",
            {
              func: () => {
                this.alertConfirmService.close();
              }
            },
            {
              func: () => {
                let data = {
                  contactor: this.genDataFromContract(),
                  templId: this.editorPdfService.getTemplateAID(),
                  templNm: this.formNameTemplate.get('nameTemplate').value,
                  excelListNm: this.tableDataService.getFileName(),
                  contorPass: this.formInputPassword.get('contorPass').value,
                  frstRegisNo: this.loginService.getProfileLogin().userIdNo,
                  sequenceYn: this.formSequence.get('sequenceYn').value,
                  verifiPhoneYn: this.verifiPhoneYn,
                  verifiPwdYn: this.verifiPwdYn,
                  useExpiryYn: this.formExpiry.get('useExpiryYn').value,
                  expiryDay: this.formExpiry.get("expiryDay").value
                };
                this.tmplateService.saveMarkTemplate(this.editorPdfService.getMark()).subscribe((rs: any) => {
                  if (rs.status) {
                    this.alertSaveNameTemplateService.setNameTemplate("");

                    this.contractService.insertContract(data).subscribe((rs: any) => {
                      if (rs.status) {
                        this.alertConfirmService.close();
                        this.router.navigate(['contract-management']);
                      }
                    });
                  }
                });

              }
            });
        }
      }
      );

    }
  }

  genDataFromContract(): {
    contNm: string,
    contorType: string,
    contractEmail: string,
    contractMobile: string,
    contractNm: string,
    dataType: string,
    contorPass: string
  }[] {
    let contract: {
      contNm: string,
      contorType: string,
      contractEmail: string,
      contractMobile: string,
      contractNm: string,
      dataType: string,
      contorPass: string
    }[] = [];

    let contorType = this.editorPdfService.getColorSign();
    let c = 0;

    for (let i = 0; i < this.fromEmployees.get('employees').value.length; i++) {
      let pass = this.fromEmployees.get('employees').get(i.toString()).get("password").value;
      if (this.btnEnablePassword) {
        pass = this.formInputPassword.get('contorPass').value;
      }
      let color = 'rgb(0,0,0)'
      if (contorType.length > 0) {
        color = contorType[c].color
      }

      let checkContract = contract.filter(con => {
        return con.contractEmail === this.fromEmployees.get('employees').get(i.toString()).get("contractEmail").value;
      });
      console.log(contract, this.fromEmployees.get('employees').get(i.toString()).get("contractEmail").value, checkContract);
      console.log("LOG");
      if (checkContract.length === 0) {
        contract.push({
          contNm: "",
          contorType: color,
          contractEmail: this.fromEmployees.get('employees').get(i.toString()).get("contractEmail").value,
          contractMobile: this.fromEmployees.get('employees').get(i.toString()).get("contractMobile").value,
          contractNm: this.fromEmployees.get('employees').get(i.toString()).get("contractNm").value,
          dataType: this.fromEmployees.get('employees').get(i.toString()).get("dataType").value,
          contorPass: pass
        });
      }

      if ((contorType.length - 1) != c) {
        c++;
      }
    }



    for (let i = 0; i < this.arrayDatas.length; i++) {
      if (this.arrayDatas[i].dataType == 'sel-div') {
        let usr = this.user.filter(res => {
          return res.divId == this.arrayDatas[i].divId;
        });
        let pass = (<HTMLInputElement>document.getElementById("p" + i)).value;
        if (this.btnEnablePassword) {
          pass = this.formInputPassword.get('contorPass').value;
        }
        usr.forEach(usr => {


          let checkContract = contract.filter(con => {
            return con.contractEmail === usr.userMail;
          });
          console.log(contract, usr.userMail, checkContract);
          console.log("LOG");

          if (checkContract.length === 0) {
            contract.push({
              contNm: "",
              contorType: contorType[c].color,
              contractEmail: usr.userMail,
              contractMobile: usr.userPhone,
              contractNm: usr.userNm,
              dataType: this.arrayDatas[i].dataType,
              contorPass: pass
            });
          }
          if ((contorType.length - 1) != c) {
            c++;
          }

        });

      } else if (this.arrayDatas[i].dataType == "sel-usr") {
        let pass = (<HTMLInputElement>document.getElementById("p" + i)).value;
        if (this.btnEnablePassword) {
          pass = this.formInputPassword.get('contorPass').value;
        }

        let checkContract = contract.filter(con => {
          return con.contractEmail === this.arrayDatas[i].contractEmail;
        });
        console.log(contract, this.arrayDatas[i].contractEmail, checkContract);
        console.log("LOG");

        if (checkContract.length === 0) {
          contract.push({
            contNm: "",
            contorType: contorType[c].color,
            contractEmail: this.arrayDatas[i].contractEmail,
            contractMobile: this.arrayDatas[i].contractMobile,
            contractNm: this.arrayDatas[i].contractNm,
            dataType: this.arrayDatas[i].dataType,
            contorPass: pass
          });
        }
        if ((contorType.length - 1) != c) {
          c++;
        }
      }
    }
    return contract;
  }

  configPassword() {
    if (this.btnEnablePassword) {
      this.verifiPwdYn = "N";
      this.btnEnablePassword = false;
      this.btnPassword = 'btn-gray';

      this.inputs.inputPassword.setAttribute('disabled', 'true');
      this.formInputPassword.get('contorPass').disable();
      this.enablePassInpFrom();
    } else {
      this.verifiPwdYn = "Y";
      this.btnEnablePassword = true;
      this.btnPassword = 'btn-black';
      this.inputs.inputPassword.removeAttribute("disabled");
      this.formInputPassword.get('contorPass').enable();
      this.disabledPassInpFrom();
    }
  }

  configExpiry() {
    if (this.btnEnableExpiry) {
      this.formExpiry.get("useExpiryYn").setValue("N")
      this.btnEnableExpiry = false;
      this.btnExpiry = 'btn-gray';
      this.formExpiry.get('expiryDay').disable();
    } else {
      this.formExpiry.get("useExpiryYn").setValue("Y")
      this.btnEnableExpiry = true;
      this.btnExpiry = 'btn-black';
      this.formExpiry.get('expiryDay').enable();

    }
  }

  configPhoneOtp() {
    if (this.btnEnableOtpPhone) {
      this.verifiPhoneYn = "N";
      this.btnEnableOtpPhone = false;
      this.btnOtpPhone = 'btn-gray';
    } else {
      this.verifiPhoneYn = "Y";
      this.btnEnableOtpPhone = true;
      this.btnOtpPhone = 'btn-black';

    }
  }

  enablePassInpFrom() {
    for (let i = 0; i < this.arrayFromDatas.length; i++) {
      document.getElementById('pi' + i).removeAttribute('disabled');
    }
  }

  disabledPassInpFrom() {
    for (let i = 0; i < this.arrayFromDatas.length; i++) {
      document.getElementById('pi' + i).setAttribute('disabled', 'true');
    }
  }
}
