import { Injectable } from '@angular/core';
import { API_URL } from '../api.constant';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CodeBookService {

  constructor(
    private http: HttpClient
  ) { }

  pathGetCodeBookType: string = API_URL.getCodeBookType;



  getCodeBook(codeGrpId: string) {
    let params = {
      data: {
        codeGrpId: codeGrpId
      }
    }

    return this.http.post(this.pathGetCodeBookType,params);
  }
}
