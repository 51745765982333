import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { fabric } from 'fabric/dist/fabric.js';

import { faTextHeight, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faCalendarMinus, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faVine } from '@fortawesome/free-brands-svg-icons';
import { ModalAddSignatureService } from '../../../manage/component/modal/modal-add-signature/modal-add-signature.service';
import { ToolsViewPdfService } from '../tools-view-pdf/tools-view-pdf.service';

@Component({
  selector: 'app-tools-editor-pdf',
  templateUrl: './tools-editor-pdf.component.html',
  styleUrls: ['./tools-editor-pdf.component.scss']
})

export class ToolsEditorPdfComponent implements OnInit {
  // event call back on add item
  @Output() evnentAddFabric = new EventEmitter<fabric>();
  @Output() evnentRequestRender = new EventEmitter<boolean>();
  @Output() evnentSelectColor = new EventEmitter<boolean>();
  // config Componemt
  @Input() configTools;



  faTextHeight = faTextHeight;
  faLink = faLink;
  faCheckSquare = faCheckSquare;
  faCalendarMinus = faCalendarMinus;
  faSquare = faSquare;
  faVine = faVine;


  config: any;
  colors: any[];
  constructor(
    private modalAddSignatureService: ModalAddSignatureService,
    private toolsViewPdfService: ToolsViewPdfService,
  ) {
    this.config = {
      showTools: true,
    };
  }

  ngOnInit(): void {
    this.setConfig();
    this.initColor();
  }

  onDragStart($event) {
    this.toolsViewPdfService.setCreateMark(true);
    console.log(this.toolsViewPdfService.getCreateMark())
    $event.dataTransfer.setData("text", $event.target.id);
  }

  initColor() {
    this.colors = [
      {
        r: 255,
        g: 0,
        b: 0
      },
      {
        r: 255,
        g: 64,
        b: 0
      },
    ]
  }

  setConfig() {
    const key = Object.keys(this.configTools);

    for (let i = 0; i < key.length; i++) {
      this.config[key[i]] = this.configTools[key[i]];
    }
  }

  addCheckBox() {
    let img = {
      check: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACiklEQVRoge3ZS6hNURgH8J+3vEJxrwEpI4XBjZJSTEyQUjfvCVOvlIGRRDdDxcRYKZQoAxMyo5Q8SjG4jER3oO4l5XUM1trt23XuPWftc+7Zm86/dqvO/h7/f2et7/tWmy666KIV9GIAz/EFtQ4+V9slYl8J5LPnMWa1Q8R+/I5Bb2Ez5rYjcCfRixFBxImSuYzFwhTjAfk/USUcwUdsbNbhhSBk82QxKoCt+C5s9wPNOmXbqipnYhWGBE7nUhyzylEFLMYbgc9NTElxroqQGXggcHmKOakBqiLkqsDjPXqKBKiCkDORwzDWFQ1StpDd+IWf2DmOTVP9rUwhffKx6Ng4NtsFkQ1RlpDl+BBzX5nALhPaEGUImSdM2DXcx/QJbJvm12khU3E35nyt8TxVWSGXYr4hoYs3QiWFHIm5vmFTkz6VE7INP4RB8GCCX9uFTMMNHE4gkWE1Psc8ZxN92y6kP9r9xtEEIkswGH2vSxwEE/glba3j8ivxxSbsZ+KRFgbBFH6pZ+SQsNdruCyU03qYgmvR7p2Cg2AKvyKHfZdQeWoC2XoN7ax8EFybGL8Qv6JVa6tAsiY0uNmj3vULW/AndhSIXYhfK+V3g/xa+hDzsR5f428pRaFlfq32kTXy4e8JPsnPTzvQ0Ya4Em9HxWo0CKag4519GV7iFRa0IV6GUkaURVjRplgZKjdrFUVdfuM1r38OXSFVw38t5Etc53WSSJPIyvjw2Bf1hAzGtW/S6BRHxmlw7It6Qu7FtWpfq+BkXO9NaBXRI/9GcmqyGBXAafk1YGmzTnvlN7/b2KKcDz9zY+478mv1ntQge+X/TBWe4SIiMvTgAp6VJGok5j4vYTt10UUXf+MPIhRKtbWYy3gAAAAASUVORK5CYII=',
      uncheck: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABGklEQVRoge2ZUU7CQBRFjy7CghsTWQXiEhCWZ3ADLqI00S/ggzGB10qmVTr3455kPiaZl7mn8776wBjzFybABtgCDXAYeTXp7jVQDZV4LhT+t7UDZn0l5sBeIHxc+z4yk2RfOvS1l8lqs00o/AIWSXBspsBLynCe6S2n+CMULW6TsRdLLjNtc4piW5V4iUhFu70uuOsoOmScKcHVXPcjBrkpFlHDImpYRA2LqGERNSyihkXUsIgaFlHDImpYRA2LqGERNbpEmrBX+In9GPZ1PNAl8hn283+LM5yYIWbsZE170LPkNHQZmynwCnyHTKuc4grt0VsNPOR+iRm6w9CnXIlzGaWXqYdI/FBxGjy+F5LapbtX9GgnY0ybI5uKUn+T2bcKAAAAAElFTkSuQmCC'
    }
    let elIma = document.createElement('img');
    elIma.src = img.uncheck;
    elIma.width = 50;
    elIma.height = 50;



    const obj = new fabric.Image(elIma, {
      left: 0,
      top: 0,
      width: 50,
      height: 50,
      markType: 'checkbox',
      checkedValue: false,
      scaleX: 0.43609375,
      scaleY: 0.43609375,
      markNote: '',
      groupColor: 'rgb(0, 0, 0)'
    });

    obj.setControlVisible('tl', false);
    obj.setControlVisible('tr', true);
    obj.setControlVisible('bl', false);
    obj.setControlVisible('br', true);
    obj.setControlVisible('mb', false);
    obj.setControlVisible('mt', false);
    obj.setControlVisible('ml', false);
    obj.setControlVisible('mr', false);

    obj.on('mousedblclick', (e) => {
      if (obj.checkedValue) {
        obj._element.src = img.uncheck;
        obj.checkedValue = false;
        this.evnentRequestRender.emit(true);
      } else {
        obj._element.src = img.check;
        obj.checkedValue = true;
        this.evnentRequestRender.emit(true);
      }
    });
  }

  addTextToPdf(type: string, text: string) {
    // event call back on add item
    const obj = new fabric.Textbox(text, {
      left: 0,
      top: 0,
      fontSize: 20,
      width: 100,
      markType: type,
      markId: null,
      transparentCorners: true,
      hasControls: true,
      markNote: '',
      groupColor: 'rgb(0, 0, 0)'
    });

    obj.setControlVisible('tl', true);
    obj.setControlVisible('tr', true);
    obj.setControlVisible('bl', true);
    obj.setControlVisible('br', true);
    obj.setControlVisible('mb', true);
    obj.setControlVisible('mt', true);
    obj.setControlVisible('ml', true);
    obj.setControlVisible('mr', true);
  }

  addTestTypeDate(type: string) {
    const date = new Date();
    let monthdata = (date.getMonth() + 1) + '';
    if ((date.getMonth() + 1) < 10) {
      monthdata = '0' + (date.getMonth() + 1);
    }
    const str_date = monthdata + '/' + date.getDate() + '/' + date.getFullYear();
    const obj = new fabric.Textbox(str_date, {
      left: 0,
      top: 0,
      width: 150,
      fontSize: 20,
      markType: type,
      markId: null,
      markNote: '',
      groupColor: 'rgb(0, 0, 0)'
    });
    obj.on('changed', (e) => {
      const date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
      if (!date_regex.test(obj.text)) {
        obj.fill = 'rgb(255,0,0)';
        alert('format Invalid (mm/dd/yyyy) ex : 01/01/1999');
      } else {
        obj.fill = 'rgb(0,0,0)';
      }
    });
    obj.setControlVisible('tl', true);
    obj.setControlVisible('tr', true);
    obj.setControlVisible('bl', true);
    obj.setControlVisible('br', true);
    obj.setControlVisible('mb', true);
    obj.setControlVisible('mt', true);
    obj.setControlVisible('ml', true);
    obj.setControlVisible('mr', true);

  }

  addTestTypeNumber(type: string) {
    const obj = new fabric.Textbox('123', {
      left: 0,
      top: 0,
      width: 150,
      fontSize: 20,
      markType: type,
      markId: null,
      markNote: '',
      groupColor: 'rgb(0, 0, 0)'
    });
    obj.on('changed', (e) => {
      const date_regex = /^\d+$/;
      if (!date_regex.test(obj.text)) {
        obj.fill = 'rgb(255,0,0)';
        alert('format Invalid number only ex : 123');
      } else {
        obj.fill = 'rgb(0,0,0)';
      }
    });

    obj.setControlVisible('tl', false);
    obj.setControlVisible('tr', true);
    obj.setControlVisible('bl', false);
    obj.setControlVisible('br', false);
    obj.setControlVisible('mb', false);
    obj.setControlVisible('mt', false);
    obj.setControlVisible('ml', true);
    obj.setControlVisible('mr', true);
  }

  addAttachmentsType(type: string = "attachments") {
    // event call back on add item
    const obj = new fabric.Textbox("attachments", {
      left: 0,
      top: 0,
      fontSize: 20,
      width: 100,
      markType: type,
      markId: null,
      transparentCorners: true,
      hasControls: true,
      markNote: '',
      groupColor: 'rgb(0, 0, 0)'
    });

    obj.setControlVisible('tl', true);
    obj.setControlVisible('tr', true);
    obj.setControlVisible('bl', true);
    obj.setControlVisible('br', true);
    obj.setControlVisible('mb', true);
    obj.setControlVisible('mt', true);
    obj.setControlVisible('ml', true);
    obj.setControlVisible('mr', true);

  }

  addSignatureImage() {
    this.modalAddSignatureService.open();
  }
}
