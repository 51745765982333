export default class ImageManagement {

    public ImageResize = undefined;;
    public size = {
        width: 0,
        height: 0
    };
    public canvasSize = {
        width: 0,
        height: 0
    };
    public imageReturn = undefined;
    public funcCallback: any;

    public clearData() {
        this.ImageResize = undefined;
        this.size = {
            width: 0,
            height: 0
        };
    }

    public getImage() {
        return this.ImageResize;
    }

    public getSize() {
        return this.size;
    }

    public convertImpage(OriginIma: File, maxWidth: number, maxHeight: number, funcCallback: any) {
        this.funcCallback = funcCallback;
        this.canvasSize.width = maxWidth;
        this.canvasSize.height = maxHeight;
        if (OriginIma) {
            var reader = new FileReader();
            reader.onload = (event) => {
                let img = new Image();
                img.onload = () => {

                     console.log((img.width >= img.height), img.width, img.height);
                    if (img.width >= img.height) {
                         console.log((img.height <= maxHeight), img.height, maxHeight);
                        if (img.height <= maxHeight) {
                             console.log((img.width > maxWidth), img.width, maxWidth);
                            if (img.width > maxWidth) {
                                 console.log("resize case 1", "img.width : " + img.width, "img.height : " + img.height, "box.height : " + maxWidth, "box.height : " + maxHeight);
                                this.resizeImageToSpecificWidth(OriginIma, maxWidth, maxHeight);
                            }
                            else {
                                 console.log("resize case 2", "img.width : " + img.width, "img.height : " + img.height, "box.height : " + maxWidth, "box.height : " + maxHeight);
                                this.resizeImageToSpecificHeight(OriginIma, maxHeight, maxWidth);
                            }
                        } else {
                            if (img.width >= img.height) {
                                 console.log("resize case 3", "img.width : " + img.width, "img.height : " + img.height, "box.height : " + maxWidth, "box.height : " + maxHeight);
                                this.resizeImageToSpecificWidth(OriginIma, maxWidth, maxHeight);
                            } else {
                                 console.log("resize case 4", "img.width : " + img.width, "img.height : " + img.height, "box.height : " + maxWidth, "box.height : " + maxHeight);
                                this.resizeImageToSpecificHeight(OriginIma, maxHeight, maxWidth);
                            }
                        }
                    } else {
                        console.log("resize case 5", "img.width : " + img.width, "img.height : " + img.height, "box.height : " + maxWidth, "box.height : " + maxHeight);
                        this.resizeImageToSpecificHeight(OriginIma, maxHeight, maxWidth);
                    }

                };
                let image = event.target.result;
                img.setAttribute('src', image.toString());
            };
            reader.readAsDataURL(OriginIma);
        }
    }

    public calculatePositionsGeneratImage(CW: number, CH: number, IW: number, IH: number, img: any) {
        let imgage = new Image();
        imgage.onload = () => {
            // หาจุดกลางของ Canvas
            let CCx = CW / 2;
            let CCy = CH / 2;

            // หาจุดกลางของ Image
            let ICx = IW / 2;
            let ICy = IH / 2;

            // หาจุดในการ วาด Image  ใน Canvas
            let PRx = CCx - ICx;
            let PRy = CCy - ICy;

            // สร้าง canvas ในการสร้างรูปภาพ
            var oc = document.createElement('canvas'),
            octx = oc.getContext('2d');
            oc.width = CW;
            oc.height = CH;
            octx.drawImage(imgage, PRx, PRy, IW, IH);
            this.imageReturn = oc.toDataURL('image/png');
            this.funcCallback.func();
        }
        imgage.setAttribute('src', img);
    }

    public resizeImageToSpecificWidth(OriginIma: File, resizeToWidth: number, resizeToHeight: number) {
        if (OriginIma) {
            var reader = new FileReader();
            reader.onload = (event) => {
                let img = new Image();
                img.onload = () => {
                    if (img.width > resizeToWidth || img.width > resizeToHeight) {
                        var oc = document.createElement('canvas'), octx = oc.getContext('2d');
                        oc.width = img.width;
                        oc.height = img.height;
                        octx.drawImage(img, 0, 0);


                        while (oc.width * 0.9999 > resizeToWidth || oc.height * 0.9999 > resizeToHeight) {
                            oc.width *= 0.9999;
                            oc.height = oc.width * img.height / img.width;
                            octx.drawImage(oc, 0, 0, oc.width, oc.height);
                        }

                        oc.width *= 0.9999;
                        oc.height = oc.width * img.height / img.width;
                        octx.drawImage(oc, 0, 0, oc.width, oc.height);
                        oc.height = oc.width * img.height / img.width;
                        octx.drawImage(img, 0, 0, oc.width, oc.height);
                        this.size.width = oc.width;
                        this.size.width = oc.height;
                        this.ImageResize = oc.toDataURL('image/png');
                        this.calculatePositionsGeneratImage(this.canvasSize.width, this.canvasSize.height, oc.width, oc.height, this.ImageResize);
                    } else {
                        this.ImageResize = img.src;
                        this.size.width = img.width;
                        this.size.height = img.height;
                        this.calculatePositionsGeneratImage(this.canvasSize.width, this.canvasSize.height, this.size.width, this.size.height, this.ImageResize);
                    }
                };
                let image = event.target.result;
                img.setAttribute('src', image.toString());
            };
            reader.readAsDataURL(OriginIma);
        }
    }

    public resizeImageToSpecificHeight(OriginIma: File, resizeToHeight: number, resizeToWidth: number) {
        if (OriginIma) {
            var reader = new FileReader();
            reader.onload = (event) => {
                let img = new Image();
                img.onload = () => {
                    if (img.width > resizeToWidth || img.height > resizeToHeight) {
                        var oc = document.createElement('canvas'), octx = oc.getContext('2d');
                        oc.width = img.width;
                        oc.height = img.height;
                        octx.drawImage(img, 0, 0);

                        while (oc.height * 0.9999 > resizeToHeight || oc.width * 0.9999 > resizeToWidth) {
                            oc.height *= 0.9999;
                            oc.width = oc.height * img.width / img.height;
                            octx.drawImage(oc, 0, 0, oc.width, oc.height);
                        }

                        oc.height *= 0.9999;
                        oc.width = oc.height * img.width / img.height;
                        octx.drawImage(oc, 0, 0, oc.width, oc.height);
                        oc.width = oc.height * img.width / img.height;
                        octx.drawImage(img, 0, 0, oc.width, oc.height);
                        this.size.width = oc.width;
                        this.size.width = oc.height;
                        this.ImageResize = oc.toDataURL('image/png');
                        this.calculatePositionsGeneratImage(this.canvasSize.width, this.canvasSize.height, oc.width, oc.height, this.ImageResize);

                    } else {
                        this.ImageResize = img.src;
                        this.size.width = img.width;
                        this.size.height = img.height;
                        this.calculatePositionsGeneratImage(this.canvasSize.width, this.canvasSize.height, this.size.width, this.size.height, this.ImageResize);
                    }
                };
                let image = event.target.result;
                img.setAttribute('src', image.toString());
            };
            reader.readAsDataURL(OriginIma);
        }
    }
}
