import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import TokenUtils from '../../manage/shared/token/token-utils';
import {AuthGuard} from '../../guard/auth.guard';
import {NavbarService} from './navbar.service';
import {DataPassService} from 'src/app/manage/service/data-pass/data-pass.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
    faBars = faBars;
    languages: any;
    selectl: any;
    manu: any;
    showbtn: boolean = false;

    showLogo: boolean = true;
    navbarTitle: string = '';
    mobileMenu: string = '';
    iconcompany: string = '';
    Menuiconcompany: string = '';
    Menuiconcompany2: string = '';
    Menuiconcompany3 = false;
    Menuiconcompany4: string = '';

    constructor(
        private router: Router,
        private jwtHelper: JwtHelperService,
        private authGuard: AuthGuard,
        private navbarService: NavbarService,
        private dataPass: DataPassService
    ) {
    }

    action: {
        login: string,
        register: string
    }
        = {
        login: '',
        register: ''
    }


    ngOnInit(): void {
        if (this.router.url == '/login') {
            this.action.login = 'isAction';
            this.action.register = '';
        }
        if (this.router.url == '/register') {
            this.action.login = '';
            this.action.register = 'isAction';
        }
        this.languages = {
            en: 'English', kr: '한글'
        };
        this.manu = {
            en: {
                about: ' 서비스 이용 방법',
                service: '이용 요금 안내',
                features: '고객센터',
                contact: '고객센터',
                howTo: '이용방법',
                community: '자료실'
            },
            kr: {
                about: '서비스 소개',
                service: '서비스 이용 방법',
                features: '이용 요금 안내  ',
                contact: '고객센터',
                howTo: '이용방법',
                community: '자료실'
            }
        }
        this.selectl = 'en';
        this.checkShowBtn();

        this.xMenu();
    }

    myFunction() {
        var element = document.getElementsByClassName("mainNav");

        if (element[0].classList) {
            element[0].classList.toggle("mystyle");
        } else {
            var classes = element[0].className.split(" ");
            var i = classes.indexOf("mystyle");

            if (i >= 0)
                classes.splice(i, 1);
            else
                classes.push("mystyle");
            element[0].className = classes.join(" ");
        }

        this.logoFunction();
    }

    myFunction2() {
        var element = document.getElementsByClassName("mainNav");
        if (element[1].classList) {
            element[1].classList.toggle("mystyle");
        } else {
            var classes = element[1].className.split(" ");
            var i = classes.indexOf("mystyle");

            if (i >= 0)
                classes.splice(i, 1);
            else
                classes.push("mystyle");
            element[1].className = classes.join(" ");
        }

        this.logoFunction();
    }

    xMenu() {
        var hamburgers = document.querySelectorAll(".hamburger");
        if (hamburgers.length > 0) {
            hamburgers.forEach(hamburger => {
                hamburger.addEventListener("click", (e) => {
                    hamburger.classList.toggle("is-active");
                })
            })
        }
    }


    ngDoCheck(): void {
        this.navbarTitle = this.navbarService.getNavbarTitle();
        this.authGuard.canActivateNav();
        this.checkShowBtn();
        this.checkShowBtn();
        if (this.router.url == '/') {
            this.iconcompany = '';
            this.mobileMenu = '';
        } else if (this.router.url == '/service-center' || this.router.url == '/how-to-use' || this.router.url == '/community') {
            this.iconcompany = '';
            this.mobileMenu = '';
        } else {
            this.mobileMenu = 'dark';
            this.iconcompany = 'dark';
        }

        if (this.router.url.includes('/service-center')) {
            document.getElementById('myBtn').classList.add('active');
            document.getElementById('leBtn').classList.add('active');
            if (document.getElementById('goBakk') != undefined) {
                document.getElementById('goBakk').style.display = "block";
                document.getElementById('theLogos').classList.add('serveLogo');
            }
        } else if (this.router.url.includes('/how-to-use')) {
            document.getElementById('howBtn').classList.add('active');
            document.getElementById('howBtn2').classList.add('active');
            if (document.getElementById('goBakk') != undefined) {
                document.getElementById('goBakk').style.display = "block";
                document.getElementById('theLogos').classList.add('serveLogo');
            }
        } else if (this.router.url.includes('/community')) {
          document.getElementById('commuBtn').classList.add('active');
          document.getElementById('commuBtn2').classList.add('active');
          if (document.getElementById('goBakk') != undefined) {
            document.getElementById('goBakk').style.display = "block";
            document.getElementById('theLogos').classList.add('serveLogo');
          }
        } else {

            if (document.getElementById('goBakk') != undefined) {
                document.getElementById('goBakk').style.display = "none";
            }

        }

        if (this.router.url.includes('/service-center')) {
            if (document.getElementById('goBakk2') != undefined) {
                document.getElementById('goBakk2').style.display = "block";
                document.getElementById('theLogos2').classList.add('serveLogo');
            }
        } else if (this.router.url.includes('/how-to-use')) {
            if (document.getElementById('goBakk2') != undefined) {
                document.getElementById('goBakk2').style.display = "block";
                document.getElementById('theLogos2').classList.add('serveLogo');
            }
        } else if (this.router.url.includes('community')) {
          if (document.getElementById('goBakk2') != undefined) {
            document.getElementById('goBakk2').style.display = "block";
            document.getElementById('theLogos2').classList.add('serveLogo');
          }
        } else {
            if (document.getElementById('goBakk2') != undefined) {
                document.getElementById('goBakk2').style.display = "none";
            }
        }

        if (this.router.url === '/how-to-use') {
            this.Menuiconcompany3 = true
        } else {
            this.Menuiconcompany3 = false;
        }

        if (this.router.url == '/service-center') {
            this.Menuiconcompany = 'active';
        } else {
            this.Menuiconcompany = '';
        }

        if (this.router.url == '/community') {
            this.Menuiconcompany4 = 'active'
        } else {
            this.Menuiconcompany4 = '';
        }

        if (this.router.url == '/') {
            this.Menuiconcompany2 = 'active';
        } else {
            this.Menuiconcompany2 = '';
        }


    }

    selectLanguages(lg: String) {
        this.selectl = lg;
    }

    toHomepage(el: String) {
        this.router.navigate(['/' + el]);
    }

    toHowTo() {
        this.router.navigate(['/how-to-use']);
    }

    checkShowBtn() {
        const accessToken = TokenUtils.getToken();
        if (accessToken && !this.jwtHelper.isTokenExpired(accessToken)) {
            this.showbtn = true;
        } else {
            this.showbtn = false;
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        this.dataPass.setPage('main');

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.router.navigate(['/service-center'])
    }

    topFunctionCom() {
    // this.dataPass.setPage('main');

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.router.navigate(['/community'])
    }



    logoFunction() {
        this.showLogo = !this.showLogo;
    }


}
